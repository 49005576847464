<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    v-text-field(v-model="selectHint" label="Description")
    v-select(v-model="selectedFormat" label="DateFormat" :items="timeFormatItems" :hint="computedTimeFormat()" :persistent-hint="true")
    v-checkbox(v-model="selectRequired" label="Required")
    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        show-advanced-properties(:showAdvanced="showAdvanced")
      div(v-show="showAdvanced")
        v-checkbox(v-model="selectCommentOnFail" label="Must comment when no date is given")
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)

</template>
<script>
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";

import {inspectionPropertiesMixin} from "@/modules/inspections/components/formDesigner/properties/inspectionPropertiesMixin";
export default {
  mixins:[inspectionPropertiesMixin],
  data: () => ({
    timeFormatItems: ["ampm", "24hr"]
  }),

  computed: {
    selectedFormat: {
      get: function() {
        return this.properties.time_format;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "time_format",
          value: value
        });
      }
    },




  },

  methods: {
    computedTimeFormat: function() {
      return "example ";
    }
  },


};
</script>

<style scoped lang="stylus">
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}
</style>
