<template lang="pug">
  .flex.flex-column
    div()
      .d-flex.fill-width.justify-space-between.align-center()
        div()
          div() {{ schema.label }}
          .caption.grey--text() {{ schema.hint }}
        .d-flex.align-center()
          v-radio-group(
            v-model="computedValue"
            row
            :error-messages="errorMessages"
            :mandatory="false"
            dense

          )
            div(v-if="computedValue != null")
              v-btn.mr-4.ml-2(icon small @click="nullify")
                v-icon(color="grey lighten-1") close
            v-radio( :label="schema.options[0]" color="success" :value="schema.options[0]")
            v-radio( :label="schema.options[1]" color="error" :value="schema.options[1]")
          add-comment-button.mx-1(v-model="comments" @showCommentInput="onShowCommentInput")
          upload-blob.mx-1(v-model="blobs" type="image" label="Label" hint="hint" :isMultiple="true")
    .d-flex()
      v-tooltip(bottom v-for="(item,index) in comments" :key="item.time")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") message
        span() {{item.value}}
      .mx-2()
      v-tooltip(bottom v-for="(item,index) in blobs" :key="item")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") image
        div()
          image-thumbnail(:width='360', :height='200', :src="item")

    div.mt-2(v-if="showCommentInput")
      v-textarea(outlined ref="comment" label="Comment" auto-grow rows="2" v-model="comment" hide-details )
      .d-flex.my-2()
        v-spacer
        v-btn.mx-2(small color='grey darken-1', text, @click.native="cancel()") Close
        v-btn.mx-2(small color='primary', @click.native="save()") Save



</template>
<script>

import AddCommentButton from "@/modules/inspections/components/formDesigner/component/AddCommentButton";
import UploadBlob from "@/modules/global/components/forms/UploadBlob"
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";


export default {
  components: {
    AddCommentButton,
    UploadBlob,
    ImageThumbnail
  },
  props: ["schema", "dataValue", "index","disabled"],

  data: () => ({
    comment: null,
    showCommentInput: false,
    showBlobInput: false,
    theValue: null,
    errorMessages: [],
    theComments: null,
    theBlobs: null,
    theValid: null,
    thePassed: null,

  }),
  mounted() {
    this.theValue = this.dataValue.value;
    this.theComments = this.dataValue.comments;
    this.theBlobs = this.dataValue.blobs;
    this.theValid = this.dataValue.valid;
    this.thePassed = this.dataValue.passed;
  },
  computed:{

    computedValue:{
      get: function() {
        if(this.dataValue.value != null){
          return this.dataValue.value ? this.schema.options[0]:this.schema.options[1];
        }else{
          return null;
        }

      },
      set: function(value){
        this.theValue = (value === this.schema.options[0]);
        this.update();
      }
    },

    comments: {
      get: function () {
        return this._.clone(this.dataValue.comments);
      },
      set: function (theComments) {
        console.log(theComments)
        this.theComments = theComments;
        this.update();
      },
    },

    blobs: {
      get: function () {
        return this._.clone(this.dataValue.blobs);
      },
      set: function (theBlob) {
        this.theBlobs = theBlob;
        this.update();
      }
    },

  },
  methods:{
    update: function () {
      this.theValid = true;
      this.thePassed = true;

      // verify if required
      if (this.schema.required && this.theValue == null) {
        this.theValid = false;
      }

      if(this.schema.comment_on_fail && this.theValue == null && (this.comments.length == 0 || this.blobs.length == 0)){
        this.theValid = false;
      }

      this.thePassed = this.theValue? true:false;

      // check if comments have been made when 'Fail' is selected

      this.$emit("onChange", {
        id: this.schema.id,
        value: {valid: this.theValid,passed: this.thePassed, value: this.theValue, comments: this.theComments, blobs: this.theBlobs},
        index: this.index
      });

    },


    onShowCommentInput: function () {
      this.showCommentInput = true;
      this.$nextTick(()=>{
        this.$refs.comment.focus();
      })

    },

    nullify: function(){
      this.theValue = null;
      this.update();
},

    cancel: function () {
      this.comment = null;
      this.showCommentInput = false;
    },


    save: function () {
      let theComments = [];
      if (this.comments != undefined || this.comments != null) {
        theComments = this.comments;
      }
      theComments.unshift({
        value: this.comment,
        time: new Date().getTime(),
        by_id: this.$store.state.profile.userInfo.contact.id,
        by: `${this.$store.state.profile.userInfo.contact.first_name[0]}${(this.$store.state.profile.userInfo.contact.last_name != null) ? this.$store.state.profile.userInfo.contact.last_name[0] : ''}`
      })
      this.comments = theComments;
      this.cancel();
    },
  }
};
</script>
<style scoped lang="stylus">
.signPlaceholder{
  border 2px solid #CCCCCC;
  display flex;
  justify-content center;
  align-items center;
  border-radius 10px;
  background-size contain;
  background-position center;
  width 240px;
  height 120px;

}
.signPlaceholder img{
  width 130px;
  height 100px;
}
</style>
