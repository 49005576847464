<template lang="pug">
  div()
    v-row.mx-2()
      v-col.showBorder(cols="6" sm="6" md="4") Task
      v-col.showBorder(cols="6" sm="6" md="4") link
      v-col.showBorder.py-0(cols="12" sm="12" md="4")
        v-row()
          v-col.showBorder(cols="4") Labour
          v-col.showBorder(cols="4") Parts
          v-col.showBorder(cols="4") Sub-Total


    service-line-item-row(v-if="service_details" v-for="(item,index) in service_details" :key="index", :item="item", :index="index")

</template>

<script>
import ServiceLineItems from "@/modules/services/components/ui/ServiceLineItems";
import ServiceLineItemRow from "@/modules/services/components/ui/ServiceLineItemRow";

export default {
  props: {
    service_details: Array
  },
  components: {
    ServiceLineItems,
    ServiceLineItemRow
  },

  methods: {},

  data: () => ({
    headers: [
      {
        text: "Task",
        align: "left",
        sortable: false,
        value: "task"
      },
      {text: "Link", value: "link", sortable: false},
      {text: "Labour", value: "labour", sortable: false},
      {text: "Parts", value: "Parts", sortable: false},
      {text: "Sub-Total", value: "sub_total", sortable: false},
      {text: "Action", value: "action", sortable: false}
    ],
    serviceHistoryTask: true,
    scheduleTaskReminder: true
  }),

  mounted() {
  }
};
</script>

<style scoped>
.taskStyle {
  min-width: 100%;
}

.valueStyle {
  padding-top: 5px;
  padding-bottom: 10px;
}

.fromNow:hover {
  cursor: help;
}

.subtotalBorder {
  border: 1px dashed #999999;
  /* background-color: rgb(252, 252, 252); */
  padding: 15px 15px;
  border-radius: 10px;
  margin-top: 10px;
}

#subtotal tr {
  height: 46px;
}

#subtotal input {
  width: 100%;
}

.theValue {
  display: flex;
  align-items: flex-end;
  padding-left: 20px;
}

.theSubtotal {
  text-align: right;
}

.theTextArea {
  border-color: #999999;
}

.suggested.application--dark .table tbody tr[active] {
  background: #505050;
}

.suggested.application--dark .table tbody tr:hover {
  background: #616161;
}
</style>
