<template lang="pug">
  div
    v-layout.py-3(align-center)
      v-icon arrow_right
      span.subheading() Available field types

    v-layout(row wrap justify-start)
      v-flex.pa-1.myTable(xs12 sm6 v-for="element in formElementsArray" :key="element.value")
        GlobalTemplateButton(:item="element" )

</template>
<script>
import GlobalTemplateButton from "@/modules/workflow/formBuilder/components/ui/GlobalTemplateButton";
export default {
  components: {
    GlobalTemplateButton
  },
  data: () => ({
    formElementsArray: [
      { icon: "title", label: "Title/Header", value: "Header", opacity:1 },
      { icon: "email", label: "Email", value: "EmailField", opacity:1  },
      { icon: "text", label: "Text", value: "TextField", opacity:1  },
      { icon: "textArea", label: "Text Area", value: "TextArea", opacity:1  },
      { icon: "integer", label: "Integer", value: "Integer", opacity:1  },
      { icon: "float", label: "Decimal", value: "Float", opacity:1  },
      { icon: "date", label: "Date", value: "Date", opacity:1  },
      { icon: "time", label: "Time", value: "Time" , opacity:1 },
      { icon: "select", label: "Select", value: "Select", opacity:1  },
      { icon: "table", label: "Table", value: "Table", opacity:1  },
      { icon: "radio", label: "Radio Button", value: "Radio", opacity:1  },
      { icon: "checklist", label: "Checklist", value: "Checkbox", opacity:1  },
      { icon: "switch", label: "Yes/No", value: "Switch", opacity:1  },
      { icon: "attach", label: "Attach Document", value: "Attach", opacity:1  },
      { icon: "image", label: "Image", value: "Image", opacity:1 },
      { icon: "video", label: "Video", value: "Video", opacity:0.3  },
      { icon: "weblink", label: "Web Link", value: "Weblink", opacity:0.3  },
      { icon: "signature", label: "Signature", value: "Signature", opacity:1  },
      { icon: "function", label: "Calculated", value: "Calculate", opacity:0.3  },
      { icon: "sound", label: "Sound", value: "Sound", opacity:0.3  },
      { icon: "drawing", label: "Draw", value: "Draw", opacity:1  },
      { icon: "map", label: "Map", value: "Map", opacity:1  },
      { icon: "jahazi", label: "Jahazi Field", value: "Jahazi", opacity:1  },
      { icon: "readOnly", label: "Read Only Field", value: "ReadOnly", opacity:1  },
      { icon: "time", label: "Date Time", value: "DateTime" , opacity:1 },
      { icon: "approval", label: "Approval/Reject", value: "Approval" , opacity:1 },

    ]
  }),

  methods: {
    onClick: function(value) {
      console.log(value);
    }
  }
};
</script>
<style scoped lang="stylus"></style>
