<template lang="pug">
  div()
    v-container(fluid)
      v-layout.grey.lighten-2(justify-end)
        v-dialog(v-model="dialog" width="500")
          template(v-slot:activator="{on}")
            v-btn.ma-2(v-on="on" color="primary"  small) + Add Comment 
          comment-dialog(@close="onCloseDialog" refrenceType="vehicle" :refrenceID="$route.params.vehicleID")
    div(v-if="$store.state.comments.comment_data.length > 0")
      v-container(fluid)
        v-row()
          v-col(xl="4" lg="6" md="12"  v-for="(item,index) in $store.state.comments.comment_data" :key="item.id")
            comment-item(:item="item" @edit="onEditComment" @delete="onDeleteComment" refrenceType="vehicle" :refrenceID="$route.params.vehicleID")
      v-dialog(v-model="editDialog" width="500")
        v-card()
          v-card-title.px-8.grey.lighten-2() Edit Comment
          v-card-text()
            v-container.my-2()
              v-text-field(v-model='editTitle', label='Title')
              v-textarea(v-model='editComment', label='Comment')
          v-divider()
          v-card-actions()
            v-layout(justify-end)
              v-btn.ma-2(text @click="editDialog=false") Cancel
              v-btn.ma-2(color="primary" @click="saveComment()" :disabled="editComment == null") Save

</template>
<script>
import { createHelpers } from "vuex-map-fields";
import CommentItem from "@/modules/comments/components/lists/CommentItem";
import CommentDialog from "@/modules/comments/components/ui/CommentDialog";
const { mapFields } = createHelpers({
  getterType: "comments/getComment",
  mutationType: "comments/updateComment"
});
export default {
  components: {
    CommentItem,
    CommentDialog
  },
  data: () => ({
    dialog: false,
    editDialog: false,
    rowsPerPageItems: [4, 8, 12],
    pagination: {
      rowsPerPage: 4
    }
  }),
  computed: {
    ...mapFields({
      editTitle: "title",
      editComment: "comment"
    })
  },
  methods: {
    saveComment: function() {
      this.$store.dispatch("comments/saveComment").then(() => {
        console.log("yesss it has worked");
      });
      this.editDialog = false;
    },
    onEditComment: function(item) {
      this.$store.commit("comments/setCurrentIndexByID", item.id);
      this.editDialog = true;
    },

    onDeleteComment: function(item) {
      confirm("Are you sure you want to delete this item?") &&
        this.$store.dispatch("comments/deleteComment", item.id);
    },

    onCloseDialog: function() {
      this.dialog = false;
    }
  }
};
</script>
<style scoped lang="stylus"></style>
