<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    v-text-field(v-model="selectHint" label="Description")

    div(v-if="selectType==='Simple'")
      v-combobox.mt-3(v-model="selectOption" :error-messages="errorMessages"  label="Type the options and press `Enter Key`"  multiple  small-chips)
        template(v-slot:selection="{ item, parent, selected }")
          v-chip(small close @input="removeOption(item)")
            | {{item}}

    div(v-if="selectType==='Table'")
      p Showing a Table here

    div(v-if="selectType==='API'")
      v-layout.ma-0.selectApi(column)
        v-text-field(v-model="selectAPI" label="URL of API" hint="Enter the URL for the API" :persistent-hint="true")
        v-btn(small @click="onFetchAPI") Load Data
        v-divider.my-3()
        v-combobox.mt-3(v-model="columnText" :items="columnItems"  :error-messages="errorMessages"  label="Label Text"  multiple  small-chips  )
          template(v-slot:selection="{ item, parent, selected }")
            v-chip(small close @input="removeColumn(item)")
              | {{item}}
        v-divider.my-3()
        v-combobox.mt-3(v-model="columnValue" :items="columnItems"  :error-messages="errorMessages"  label="Stored Value"   )

    v-checkbox(v-model="selectRequired" label="Required")
    v-checkbox(v-model="selectMultiple" label="Multiple Select")


    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        show-advanced-properties(:showAdvanced="showAdvanced")
      div(v-show="showAdvanced")
        v-layout.pa-3.mb-0.pb-0(fluid column)
          p.my-0.py-0 Data Source
          v-combobox(v-model="selectType" :items="items" label="Select Data Source" @change="onChangeDataSource")
        v-checkbox(v-model="selectCommentOnFail" label="Must comment when there is no input")
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" diabled)


</template>
<script>
import axios from "axios";
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";
import {inspectionPropertiesMixin} from "@/modules/inspections/components/formDesigner/properties/inspectionPropertiesMixin";

export default {
  mixins: [inspectionPropertiesMixin],
  data: () => ({
    errorMessages: [],
    options: [],

    items: ["Simple", "Table", "API"],
    columnItems: ["column A", "column B"],
  }),

  computed: {


    selectType: {
      get: function () {
        return this.properties.type;
      },
      set: function (value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "type",
          value: value
        });
      }
    },

    selectOption: {
      get: function () {
        return this.properties.options;
      },
      set: function (value) {
        this.errorMessages = [];
        if (value.length <= 0) {
          this.errorMessages.push("Enter option and press `enter`");
        }
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "options",
          value: value
        });
      }
    },

    columnText: {
      get: function () {
        return this.properties.item_text;
      },
      set: function (value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "item_text",
          value: value
        });
      }
    },

    columnValue: {
      get: function () {
        return this.properties.item_value;
      },
      set: function (value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "item_value",
          value: value
        });
      }
    },

    selectAPI: {
      get: function () {
        return this.properties.api;
      },

      set: function (value) {
        this.errorMessages = [];
        if (value.length <= 0) {
          this.errorMessages.push("Enter url and press `enter`");
        }
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "api",
          value: value
        });
      }
    },


    selectMultiple: {
      get: function () {
        return this.properties.multiple;
      },

      set: function (value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "multiple",
          value: value
        });
      }
    },
  },

  methods: {
    onFetchAPI: function () {
      console.log(`the api: ${this.selectAPI}`);
      axios.get(this.selectAPI).then(response => {
        // console.log(response.data.columns);
        this.columnText = null;
        this.columnValue = null;

        let singleItem = response.data.length > 0 ? response.data[0] : null;

        this.columnItems = Object.keys(singleItem);

        if (this.columnItems.length > 0) {
          this.columnText = [this.columnItems[0]];
          this.columnValue = this.columnItems[0];
        }
      });
    },

    removeOption: function (value) {
      let tempArray = JSON.parse(JSON.stringify(this.selectRadio));
      let index = tempArray.findIndex(theValue => theValue === value);
      tempArray.splice(index, 1);

      // save to store.
      this.selectRadio = tempArray;
    },

    removeColumn: function (value) {
      let tempArray = JSON.parse(JSON.stringify(this.columnText));
      let index = tempArray.findIndex(theValue => theValue === value);
      tempArray.splice(index, 1);

      this.columnText = tempArray;

      // save to database
    },

    onChangeDataSource: function (value) {
      // Reset Values
      this.columnText = null;
      this.columnValue = null;

      console.log(`${value}`);
    }
  }
};
</script>
<style lang="stylus" scoped>
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}

.selectApi
  border 1px solid #CCCCCC
  border-radius 5px
  padding 5px;
</style>
