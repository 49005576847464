<template lang="pug">
  v-menu(ref='menu', :close-on-content-click='false', v-model='menu', :nudge-right='40', :return-value.sync='date',  transition='scale-transition', offset-y, , min-width='290px')
    template(#activator="{on}")
      v-text-field(v-on="on", v-model='formattedDate', :label='label', prepend-icon='event', readonly)
    v-date-picker(v-model='date', @input='$refs.menu.save(date);', @change="onChange"  )
</template>
<script>
import moment from "moment";
export default {
  props: ["value", "label"],

  data: () => ({
    menu: false,
    date: null,
    formattedDate: null
  }),

  methods: {
    onChange: function(value) {
      let d = new Date(value);
      // need to cnvert to current timezone
      this.$emit("input", d.toISOString());
      this.formattedDate = moment(d).format("DD MMMM YYYY");
    }
  },

  mounted() {
    if (this.value != undefined) {
      this.date = new Date(this.value).toISOString().substr(0, 10);
      this.formattedDate = moment(new Date(this.value)).format("DD MMMM YYYY");
    }
  }
};
</script>
<style scoped lang="stylus"></style>
