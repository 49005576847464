<template lang="pug">

  .fill-width()

      v-layout(row align-center)
        v-row.ma-4()
          v-col(cols="12" xs="12" md="5" xl="3")
            .d-flex.align-center()
              v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" clearable)
      v-data-table(
        v-model="selected"
        :headers="headers"
        :items="getFilteredList"
        item-key="id"
        :loading="loading"
        :single-select="false"
        show-select
        @click:row="onDblClickRow"

        :items-per-page="footerProps.pagnation.itemsPerPage",
        :footer-props="footerProps"
      )
        template(v-slot:item.vehicle_id="{item}")
          vehicle-column-by-id(v-if="item.vehicle_id" :vehicle_id="item.vehicle_id" size="small")
        template(v-slot:item.submitted_by_id="{item}")
          div(v-if="item.submitted_by_id && getContactsByID(item.submitted_by_id)")
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-avatar.caption(color="grey lighten-2" :size="30"  v-on="on") {{getContactsByID(item.submitted_by_id).first_name[0]}} {{getContactsByID(item.submitted_by_id).last_name[0]}}
              span() {{getContactsByID(item.submitted_by_id).first_name}} {{getContactsByID(item.submitted_by_id).last_name}}

        template(v-slot:item.submitted_on="{item}")
          div() {{getTime(item.submitted_on)}}
          timeago.primary--text.caption(:datetime="item.submitted_on")
        template(v-slot:item.duration="{item}")
          div() {{item.inspection_form_name}} &ndash; v{{item.inspection_form_version}}
          .d-flex()
            v-icon.mr-1(color="primary" size="18") timer
            .caption.primary--text(v-text="getTimeSpent(item.duration)")
            .caption.text-decoration-underline.grey--text.ml-4() &#35;{{item.tx_id}}
        template(v-slot:item.geotag="{item}")
          div(v-if="item.geotag")
            a.text-decoration-none(:href="`https://maps.google.com/?q=${item.geotag.lat},${item.geotag.lon}`"  target="_blank") {{item.geotag.lon.toFixed(4)}}, {{item.geotag.lat.toFixed(4)}}
        template(v-slot:item.failed_items="{item}")
          div(v-if="getFailedItems(item).length > 0")
            ul.ma-0.pa-0
              li.failedItems.caption.primary--text(v-if="getFailedItems(item).length > 0") {{getFailedItems(item)[0]}}
              li.failedItems.caption.primary--text(v-if="getFailedItems(item).length > 1") {{getFailedItems(item)[1]}}
              li.failedItems.caption.grey--text.text--darken-2.font-weight-bold(v-if="getFailedItems(item).length > 2") +{{getFailedItems(item).length-2}} failed items
        template(v-slot:item.comments="{item}")
          div(v-if="item.comments")
            div.caption(v-if="item.comments.length > 0")  {{item.comments[0]}}
            ul.ma-0.pa-0.d-flex(v-if="item.comments.length > 0")
              li(v-for="(comment,index) in item.comments" :key="index")
                template(v-if="index > 0")
                  v-tooltip(bottom)
                    template(v-slot:activator="{on, attrs}")
                      v-icon.mr-2(v-bind="attrs" v-on="on" :size="20") message
                    span() {{comment}}
        template( v-slot:item.action="{ item }")
          v-row.mr-7(justify="end")
            more-drop-down-menu(:items='menu_items', @onClick='onClick', :theObject='item')
</template>
<script>

import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import moment from "moment/moment";
export default {
  components:{
    VehicleColumnById,
    MoreDropDownMenu
  },
  data:()=>({
    footerProps: {
      "items-per-page-options": [ 10, 20, 50, 100, 1000],
      pagnation: {itemsPerPage: 20}
    },
    loading: false,
    selected: [],
    search: null,
    selectedForm:null,
    menu_items: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ],
    headers: [
      {text: "Vehicle", value: "vehicle_id", align: "left"},
      {text: "Submitted On", value: "submitted_on", align: "left"},
      {text: "Inspection Form", value: "duration", align: "left"},
      {text: "Submitted By", value: "submitted_by_id", align: "center"},
      {text: "Failed Items", value: "failed_items", align: "center"},
      {text: "Comments", value: "comments", align: "left"},
      {text: "Action", value: "action", align: "right"},
    ]
  }),

  computed: {
    getFilteredList: function () {
      return this.$store.getters["inspection/getInspectionsByFormTemplate"](this.$route.params.inspectionID);
    },
  },

  methods: {

    onRefreshTable: function(){

    },
    onChange: function(value){
      console.log(value);
    },
    getContactsByID(theContactID){
      return this.$store.getters["contacts/getContactByID"](theContactID);
    },

    onDblClickRow: function (item) {
      this.$store.commit("inspection/setCurrentIndexByID", item.id);

      // this.$router.push({name: "inspectionNewLayout", params: {inspectionID: item.id}}).catch(()=>{});
      this.$router.push({name: "inspectionRecordOverview", params: {inspectionID: item.id}}).catch(()=>{});
    },
    getTime(submitted_time) {
      return moment(submitted_time).format("ddd, Do MMM YYYY, h:mm a")
    },

    getFailedItems(item) {
      let failedItems = [];



      if (item.data != null) {
        for (const [key, element] of Object.entries(item.data)) {
          if (element.passed == false) {
            failedItems.push(key.toString());
          }
        }
      }


      return failedItems;
    },

    getTimeSpent(theDuration) {
      if (theDuration == null || theDuration.status < 2) return "";
      let microseconds = moment
        .utc(
          moment
            .duration(theDuration.Microseconds / 1000000, "seconds")
            .asMilliseconds()
        )


      let days =
        theDuration.Days > 0
          ? theDuration.Days + " Days + "
          : "";

      let months =
        theDuration.Months > 0
          ? theDuration.Months + " Months + "
          : "";

      if (theDuration.Days == 0 && theDuration.Months == 0 && microseconds.hours() == 0 && microseconds.minutes() == 0) {
        return "less than a minute"
      } else {
        if (theDuration.Days == 0 && theDuration.Months == 0 && microseconds.hours() == 0) {
          return microseconds.minutes() + " minutes"
        } else {
          return months + days + microseconds.format("H [hr]:mm [min]");
        }

      }


    },

    onClick: function (value) {
      switch (value.action) {
        case "edit":
          this.$store.commit("inspection/setCurrentIndexByID", value.theObject.id);
          this.$router.push({name: "inspectionNewLayout", params: {inspectionID: value.theObject.id}}).catch(()=>{});
          break;
        case "delete":
          confirm(
            "Are you sure you want to delete this record"
          ) && this.$store.dispatch("inspection/deleteInspection", value.theObject.id);
          break;
      }
      console.log(value)
    },

    onRefresh() {
      this.loading = true;
      this.$store.dispatch("inspection/loadFilterInspections",this.$route.params.inspectionID).then(()=>{
        this.loading = false;
      })
    }

  },


  mounted() {

    this.onRefresh();

  }
}
</script>

<style scoped lang="stylus">

</style>
<script setup>
</script>