<template lang="pug">
  div.text-center
    div()
      div.primary--text {{unitLabel}} &nbsp;
          span(:style="{'font-size':this.fontSize+'px'}" v-html="value")
      div.grey--text.text--darken-1
        | {{label}}
</template>
<script>


export default {
  props: {
    value: String``,
    label: String,
    unitLabel: String,
    fontSize: {
      type: Number,
      default: 28
    },
    isPercent: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({}),
  methods: {

  }
};
</script>
<style lang="stylus" scoped></style>
