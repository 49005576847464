<template lang="pug">
v-data-table.elevation-1(:headers="headers", :search="search" :loading="isLoading"  :items="$store.state.reminders.vehicle_renewal_type.vehicle_renewal_type_data" )
  //- template(slot="items", slot-scope="props")
  //-   tr
      
  //-     td.text-xs-left  {{props.item.name }} 
  //-     td.text-xs-left  {{props.item.description }} 

  //-     td.text-xs-center
  //-       more-drop-down-menu(:items="menuitems" @onClick="onClick" :theObject="props.item")



</template>
<script>
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
export default {
  props: {
    search: {
      type: String,
      default: null
    }
  },

  components: {
    VehicleColumn,
    MoreDropDownMenu
  },

  data: () => ({
    isLoading: true,
    headers: [
      { text: "Name", value: "name" },
      { text: "Description", value: "description" },
      { text: "Action", value: "history", align: "center" }
    ],

    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" }
    ],

    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 10, // -1 for All
      sortBy: "name"
    }
  }),

  methods: {
    onClick: function(value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          console.log(value.theObject.id);
          this.$store.commit(
            "reminders/vehicle_renewal_type/setCurrentIndexByID",
            value.theObject.id
          );
          this.$router.push({
            name: "vehicleRenewalTypeForm",
            params: { vehicleRenewalTypeID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          this.$store.dispatch(
            "reminders/vehicle_renewal_type/deleteVehicle_renewal_type",
            value.theObject.id
          );
          break;
      }
    }
  },

  mounted() {
    this.$store
      .dispatch("reminders/vehicle_renewal_type/loadAllVehicle_renewal_types")
      .then(() => {
        this.isLoading = false;
      });
  }
};
</script>
<style scoped lang="stylus">
.editButtons
  display: flex

.dottedUnderline
  border-bottom 1px dotted
  text-decoration none
</style>
