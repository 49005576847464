import { render, staticRenderFns } from "./RouteSetupMap.vue?vue&type=template&id=515b750c&scoped=true&lang=pug"
import script from "./RouteSetupMap.vue?vue&type=script&lang=js"
export * from "./RouteSetupMap.vue?vue&type=script&lang=js"
import style0 from "./RouteSetupMap.vue?vue&type=style&index=0&id=515b750c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "515b750c",
  null
  
)

export default component.exports