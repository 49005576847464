<template lang="pug">
  .page(v-if="$store.state.vehicles.currentIndex > -1")
    overview-header
    .mainBody
      overview-side-bar
      <!-- ROUTER -->
      <router-view class="contentBackground"></router-view>
</template>
<script>
import OverviewHeader from "@/modules/vehicles/components/ui/Overview/OverviewHeader";
import OverviewSideBar from "@/modules/vehicles/components/ui/Overview/OverviewSideBar";

export default {
  components: {
    OverviewHeader,
    OverviewSideBar
  },
  data: () => ({}),

  mounted() {
    console.log(this.$store.state.vehicles.currentIndex);
    console.log(this.$route.params.vehicleID);

    // load vehicle info
    if (this.$store.state.vehicles.currentIndex == -1) {
      this.$store.dispatch("vehicles/fetchVehicle", {
        id: this.$route.params.vehicleID
      });
    }

    // load comments
    this.$store
        .dispatch(
            "comments/fetchCommentListByReference",
            this.$route.params.vehicleID
        )
        .then(() => {
          console.log(" overview comments loaded");
        });

    // loading issues
    this.$store
        .dispatch("issues/loadVehicleIssues", this.$route.params.vehicleID)
        .then(() => {
          console.log("overview vehicle issues");
        });
  }
};
</script>
<style lang="stylus" scoped>
.mainBody {
  border-top: 1px solid #CCCCCC;
  margin-top: 4px;
  padding-top: 8px;
  display: flex;
}

.contentBackground {
  width: 100%;
}

.page {
  background-color: #F5F5F5;
}
</style>
