import { render, staticRenderFns } from "./NotificationTable.vue?vue&type=template&id=1eed5ff0&scoped=true&lang=pug"
import script from "./NotificationTable.vue?vue&type=script&lang=js"
export * from "./NotificationTable.vue?vue&type=script&lang=js"
import style0 from "./NotificationTable.vue?vue&type=style&index=0&id=1eed5ff0&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eed5ff0",
  null
  
)

export default component.exports