import FuelDetailsForm from "@/modules/fuel/components/forms/FuelDetailsForm";
import FuelCardForm from "@/modules/fuel/components/forms/FuelCardForm.vue";
import FuelOverview from "@/modules/fuel/layouts/FuelOverview";

export const FUEL_ROUTES = [
  {
    path: "fuelDetailsForm",
    name: "fuelDetailsForm",
    component: FuelDetailsForm
  },
  {
    path: "fuelCardForm",
    name: "fuelCardForm",
    component: FuelCardForm
  },
  {
    path: "fuelOverview",
    name: "fuelOverview",
    component: FuelOverview
  }
];
