<template lang="pug">
  attach-form(:schema="getBlobSchema" :data="{value:computedValue}" @onChange="save"  @onMenuAction="onMenuAction" :disabled="disabled" )
</template>
<script>
import AttachForm from "@/modules/global/components/forms/AttachFillForm";


export default {
  props: ["schema", "dataValue", "index","disabled"],
  components: {
    AttachForm
  },
  data: () => ({}),
  computed:{

    getBlobSchema: function(){

      let blobSchema = {};
      blobSchema.name = "ImageFillForm";
      blobSchema.id = null;
      blobSchema.value = [];
      blobSchema.label = this.schema.label;
      blobSchema.hint = this.schema.hint;
      blobSchema.type = this.schema.type;
      blobSchema.image_size = this.schema.image_size;
      blobSchema.multiple = this.schema.multiple;


      return blobSchema;
    },

    computedValue: {
      get: function() {
        return this.dataValue;
      },

      set: function(theValue) {
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });


      }
    }
  },

  methods:{
    save: function(theObject) {
      this.$emit("onChange", {
        id: this.schema.id,
        value: theObject.value,
        index: this.index
      });

    },
    onMenuAction: function(value) {
      switch (value.action) {
        case "delete":
          console.log(`delete: ${value.theObject.id}`)
          break;
      }
    },
  }
};
</script>
<style scoped lang="stylus"></style>
