<template lang="pug">
  div()
    .d-flex
      .d-flex
        .grey--text() {{schema.label}} = &nbsp;
        div() {{computedValue}}
    .caption.grey--text() {{schema.label}}

</template>

<script>
export default {
  props: ["schema", "dataValue", "index","disabled"],
  data: () => ({}),

  computed: {


    computedValue: {
      get: function() {
        return this.dataValue;
      },

      set: function(theValue) {
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    }
  },
}
</script>

<style scoped>

</style>