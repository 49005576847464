<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    v-text-field(v-model="selectHint" label="Description")
    v-select(v-model="selectedFormat" label="DateFormat" :items="timeFormatItems" :hint="computedTimeFormat()" :persistent-hint="true")
    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        show-advanced-properties(:showAdvanced="showAdvanced")
      div(v-show="showAdvanced")
        permissions-component()
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)

</template>
<script>
import SETTINGS_DEFAULT from "@/modules/workflow/formBuilder/store/formSettings";
import ShowAdvancedProperties from "@/modules/workflow/formBuilder/components/ui/ShowAdvancedProperties";
import PermissionsComponent from "@/modules/workflow/formBuilder/components/ui/PermissionsComponent.vue";

export default {
  components: {
    ShowAdvancedProperties,
    PermissionsComponent
  },

  data: () => ({
    properties: {},
    showAdvanced: false,
    timeFormatItems: ["ampm", "24hr"]
  }),

  computed: {
    selectedFieldName: {
      get: function() {
        return this.properties.label;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "label",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },

    selectedFormat: {
      get: function() {
        return this.properties.time_format;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "time_format",
          value: value
        });
      }
    },

    selectHint: {
      get: function() {
        return this.properties.hint;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "hint",
          value: value
        });
      }
    },

    selectID: {
      get: function() {
        return this.$store.getters[SETTINGS_DEFAULT.GET_PATH].id;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    }
  },

  methods: {
    computedTimeFormat: function() {
      return "example ";
    }
  },

  mounted() {
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    this.$refs.fieldName.focus();
  }
};
</script>

<style scoped lang="stylus">
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}
</style>
