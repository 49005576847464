import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const CUSTOMER_API = "/customer";

// ----------------------- STATE ------------------------- //
const state = {
    customer_data: [],
    currentIndex: -1,
    selectedCustomers: [],
    visitations: [],
    inventories: [],
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getCustomer: state => getField(state.customer_data[state.currentIndex]),

    getCurrentCustomer: state => {
        return state.customer_data[state.currentIndex];
    },

    getCustomerByID: (state) => (id) => {
        return state.customer_data.find((element) => element.id == id);
    },



    getSelectedCustomers: (state) => state.selectedCustomers,

    getFilterByGroup: (state) => (id) =>{
        console.log(state.customer_data)
        if(id != null){
            return state.customer_data.filter( item => item.group_id == id)
        }else{
            return state.customer_data
        }
    },

    getSearch: (state) => (search) => {

        if (search == null){
            return state.customer_data;
        }


        let results = [];
        state.customer_data.forEach(item => {
            if(item.name.indexOf(search) > -1){
                results.push(item.id);
            }
        })
        console.log(search)
        console.log(results)
        return results;
    },
    getVisitationsCount: state => {
        if (state.visitations == null) {
            return 0;
        }else{
            return state.visitations.length;
        }
    },

    getInventoryCount: () => {
        if (state.inventories == null) {
            return 0;
        }else{
            return state.inventories.length;
        }
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateCustomer(state, payload) {
        updateField(state.customer_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.customer_data.findIndex(
            customer => customer.id == theID
        );
    },

    loadAllCustomers(state, payload) {
        state.customer_data = payload.data;
        state.currentIndex = 0;
    },

    addCustomer(state, payload) {
        state.customer_data.push(payload.data);
        state.currentIndex = state.customer_data.length - 1;
    },

    saveCustomer(state, payload) {
        state.customer_data[state.currentIndex] = payload.data;
    },

    fetchCustomer(state, payload) {
        if (state.currentIndex == -1) {
            state.customer_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.customer_data[state.currentIndex] = payload.data;
        }
    },

    getCustomerPlace(state,payload) {
        state.customer_data[state.currentIndex].place_id = payload.data.place_id;
    },

    getVisitations(state,payload) {
        state.visitations = payload.data.visits;
    },

    getInventories(state,payload) {
        state.inventories = payload.data;
    },

    deleteCustomer(state, payload) {
        state.currentIndex = state.customer_data.findIndex(
            customer => customer.id == payload.data.id
        );
        state.customer_data.splice(state.currentIndex, 1);
    },
    reset(state) {
        state.customer_data = []
        state.currentIndex = -1
        state.selectedCustomers = []
    },

    next(state){
        if(state.currentIndex == state.customer_data.length-1){
            state.currentIndex = 0;
        }else{
            state.currentIndex += 1;
        }
    },

    previous(state){
        if(state.currentIndex == 0){
            state.currentIndex = state.customer_data.length-1;
        }else{
            state.currentIndex -= 1;
        }
    },



    deleteFromSelected(state, theID){
        let i = state.selectedCustomers.findIndex(o => o.id == theID)
        if (i>-1){
            state.selectedCustomers.splice(i,1);
        }
    },

    deleteSelectedCustomers(state, payload){
        payload.data.forEach(element => {
            let theIndex = state.customer_data.findIndex(
                (customer) => customer.id == element
            );
            state.customer_data.splice(theIndex, 1);
        })
    },

    deleteSelectedByIndex(state, index){
        state.selectedCustomers.splice(index,1)
    },
    setSelectedCustomers(state, payload) {
        state.selectedCustomers = payload;
    },
    unshiftIntoSelected(state,place){
        state.selectedCustomers.unshift(place)
    },
    pushIntoSelected(state,place){
        state.selectedCustomers.push(place)
    },

    setVisitationCount(state,count) {
        state.visitationCount = count;
    },

    setVisitations(state,payload) {
        state.visitations = payload
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllCustomers({ commit }) {
        try {
            commit("loadAllCustomers", await Repository.get(`${CUSTOMER_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addCustomer({ commit }) {
        await Repository.get(`${CUSTOMER_API}/new`)
            .then(response => {
                commit("addCustomer", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveCustomer({ state, commit }) {
        try {
            console.log(state.customer_data[state.currentIndex]);

            commit(
                "saveCustomer",
                await Repository.post(
                    `${CUSTOMER_API}`,
                    state.customer_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchCustomer({ commit }, payloadID) {
        try {
            commit(
                "fetchCustomer",
                await Repository.get(`${CUSTOMER_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async getCustomerPlace({commit,state}){
        try{
            commit("getCustomerPlace", await Repository.get(`${CUSTOMER_API}/getPlaceById?id=${state.customer_data[state.currentIndex].id}`))
        }catch(error){
            console.log(error)
        }
    },

    async getVisitations({commit},payloadID) {
        try{
            commit("getVisitations", await Repository.get(`/place/singlePlaceSummary?id=${payloadID}&from=2023-01-01`))
        }catch(error) {
            console.log(error)
        }
    },

    async getInventories({commit},payloadID){
        try{
            commit("getInventories",await Repository.get(`/inventory/loadByCustomer/${payloadID}`))
        }catch(error){
            console.log(error)
        }
    },

    async deleteCustomer({ commit }, payloadID) {
        try {
            commit(
                "deleteCustomer",
                await Repository.delete(`${CUSTOMER_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteSelectedCustomers({ commit }, deleteSelectedIDs) {
        try {
            let idString = ''
            deleteSelectedIDs.forEach(element => {
                idString += "&id="+element
            })
            commit(
                "deleteSelectedCustomers",
                await Repository.delete(`${CUSTOMER_API}/deleteSelected?${idString}`)

            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchCustomers({ commit }, searchTerm) {
        try {
            commit(
                "loadAllCustomers",
                await Repository.get(`${CUSTOMER_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
