import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const TRACK_API = "/track";

// ----------------------- STATE ------------------------- //
const state = {
    track_data: [],
    currentIndex: -1,
    avl_data: [],// for avl records streaming from mqtt in "modules/app/layouts/Main.vue"
    current_avl: null,
    map_key:0,
    mapVectorStyle:true,

};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getTrack: state => getField(state.track_data[state.currentIndex]),

    getCurrentAvlData: state => {
        if (state.avl_data.length <= 0) return null;
        return state.avl_data[state.avl_data.length - 1];
    },

    getCurrentTrack: state => {
        if (state.track_data.length == 0) return null;
        return state.track_data[state.currentIndex];
    },

    getCurrentTrackByVehicleID: state => vehicle_id => {
        if (state.track_data.length == 0) return null;
        let result = state.track_data.find(element => element.vehicle.id == vehicle_id);
        if (result == undefined) {
            return null;
        }else{
            return result;
        }
    },


    getMovingDevices: state => {
        return state.track_data;
    },

    getStoppedDevices: state => {
        return state.track_data;
    },
    getInactiveDevices: state => {
        return state.track_data;
    }



};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    pushAvl(state, payload) {
        // console.log("insertAVl");
        state.current_avl = payload;
        state.avl_data.push(payload);
        state.track_data.forEach(element => {
            if (element.vehicle.id == payload.vehicle_id) {
                element.avl_record = payload;
            }
        });

    },

    updateTrack(state, payload) {
        updateField(state.track_data[state.currentIndex], payload);
    },

    updateMQTT(state, payload) {
        state.track_data.forEach(element => {
            console.log(element.avl_record);
            console.log(
                `imei for element: ${element.avl_record.imei} and payload: ${payload.imei}`
            );
            if (element.avl_record.imei == payload.imei) {
                element.avl_record = payload;
            }
        });
        // state.track_data[0].avl_record = avl_record;
    },
    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.track_data.findIndex(track => track.id == theID);
    },

    setCurrentIndexByVehicleID(state,vehicleID){
        state.currentIndex = state.track_data.findIndex(track => track.vehicle.id == vehicleID);
    },

    setCurrentIndexByDeviceID(state,deviceID){
        state.currentIndex = state.track_data.findIndex(track => track.track_device.id == deviceID)
    },

    resetData(state) {
        state.track_data = [];
    },

    loadAllTracks(state, payload) {
        state.track_data = payload.data;
        state.currentIndex = 0;
    },

    addTrack(state, payload) {
        state.track_data.push(payload.data);
        state.currentIndex = state.track_data.length - 1;
    },

    saveTrack(state, payload) {
        state.track_data[state.currentIndex] = payload.data;
    },

    fetchTrack(state, payload) {
        if (state.currentIndex == -1) {
            state.track_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.track_data[state.currentIndex] = payload.data;
        }
    },

    deleteTrack(state, payload) {
        state.currentIndex = state.track_data.findIndex(
            track => track.id == payload.data.id
        );
        state.track_data.splice(state.currentIndex, 1);
    },
    reset(state) {
        state.track_data = []
        state.currentIndex = -1
        state.avl_data = []// for avl records streaming from mqtt in "modules/app/layouts/Main.vue"
    },

    incrementMapKey(state) {
        state.map_key += 1;
    },

    toggleMapVectorStyle(state){
        state.mapVectorStyle = !state.mapVectorStyle;
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllTracks({commit}) {
        try {
            commit("loadAllTracks", await Repository.get(`${TRACK_API}/info`));
        } catch (error) {
            console.log(error);
        }
    },

    async addTrack({commit}) {
        await Repository.get(`${TRACK_API}/new`)
            .then(response => {
                commit("addTrack", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveTrack({state, commit}) {
        try {
            commit(
                "saveTrack",
                await Repository.post(
                    `${TRACK_API}`,
                    state.track_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchTrack({commit}, payloadID) {
        try {
            commit("fetchTrack", await Repository.get(`${TRACK_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteTrack({commit}, payloadID) {
        try {
            commit(
                "deleteTrack",
                await Repository.delete(`${TRACK_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchTracks({commit}, searchTerm) {
        try {
            commit(
                "loadAllTracks",
                await Repository.get(`${TRACK_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
