import { render, staticRenderFns } from "./GeoTagColumn.vue?vue&type=template&id=c4fbd8c4&scoped=true&lang=pug"
import script from "./GeoTagColumn.vue?vue&type=script&lang=js"
export * from "./GeoTagColumn.vue?vue&type=script&lang=js"
import style0 from "./GeoTagColumn.vue?vue&type=style&index=0&id=c4fbd8c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4fbd8c4",
  null
  
)

export default component.exports