<template>
  <v-textarea
    v-model="computedValue"
    :label="schema.label"
    :hint="schema.hint"
    :persistent-hint="schema.hint != null"
    :counter="schema.maxlength != null"
    :maxlength="schema.maxlength"
    :rules="[requiredRule]"
    :auto-grow="schema.auto_grow"
    :disabled="disabled"
  />
</template>
<script>
export default {
  props: ["schema", "dataValue", "index","disabled"],
  data: () => ({}),

  computed: {
    requiredRule: function() {
      return this.schema.required ? value => !!value || "Required." : false;
    },

    computedValue: {
      get: function() {
        return this.dataValue;
      },

      set: function(theValue) {
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    }
  },

  mounted() {}
};
</script>
<style scoped lang="stylus"></style>
