<template lang="pug">
  v-data-table(
    v-if="$store.state.service.currentIndex != -1"
    :headers='headers',
    :items='$store.state.service.service_data',
    item-key="id"
    :loading='loading',
    :single-select="false"
    v-model="selectedServices"
    show-select
    )
    v-progress-linear(slot='progress', height='2', :indeterminate='loading')

    template(v-slot:item.vehicle="{item}")
      div.showPointer(@click="onClickRow(item)")
        vehicle-column(v-if="item.vehicle" :vehicle="item.vehicle" )


    template(v-slot:item.completion="{item}"  @click="onClickRow(item)")
      div.showPointer(@click="onClickRow(item)")
        div
          span.grey--text REF #:
          span.caption.primary--text {{item.reference }} &nbsp;&nbsp;
          span.caption.font-weight-bold ODO: &nbsp;&nbsp;
          span.primary--text  {{ item.odometer  }}
        .caption
          .d-flex()
            v-icon.pr-1(small) alarm
            span.grey--text() Start: {{ getDateTimeFormat(item.start_date_time)}} &nbsp;&nbsp;->>&nbsp;&nbsp;  Completed: {{ getDateTimeFormat(item.completion_date_time)}}
        .caption.d-flex.justify-start(v-if="item.merchant")
          v-icon.pr-1(small) business
          .primary--text.font-weight-bold() {{ item.merchant.name }}

    template(v-slot:item.service_details="{item}")
      div.showPointer(@click="onClickRow(item)")
        div()
          .caption(v-for="(element,index) in getConcatList(item.service_details)" :key="index") {{element.service_type.name}}
        .d-flex(v-if="item.service_details.length > 2")
          .moreService.grey.white--text.caption() +{{item.service_details.length-2}} more

    template(v-slot:item.issues="{item}")
      div.showPointer(@click="onClickRow(item)")
        div()
          .caption(v-for="(element,index) in getConcatList(item.issues)" :key="index") {{element}}
        .d-flex(v-if="item.issues.length > 2")
          .moreService.grey.white--text.caption() +{{item.issues.length-2}} more

    template(v-slot:item.photos="{item}")
      .d-flex.align-center(v-if="item.photos_id" )
        div(v-for="(itemID,index) in getPhotos(item.photos_id)" :key="index")
          v-tooltip(left)
            template(v-slot:activator="{ on, attrs }")
              .ml-2(v-bind="attrs" v-on="on")
                image-thumbnail(:width="60" :height="60" :src="itemID" )
            div()
              image-thumbnail(:width='455', :height='600', :src="itemID" :key="index")
        .ml-3.grey--text.text--darken-.font-weight-bold(v-if="item.photos_id.length > 2") +{{item.photos_id.length - 2}}

    template( v-slot:item.grand_total="{ item }")
      div.showPointer(@click="onClickRow(item)") {{item.grand_total}}
    template( v-slot:item.action="{ item }")
      v-row.mr-1(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')
    


</template>

<script>
import { mapGetters } from "vuex";
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail.vue";
import moment from "moment/moment";

export default {
  components: {
    VehicleColumn,
    MoreDropDownMenu,
    ImageThumbnail
  },

  props:["loading"],
  data: () => ({
    selectedItem: {},
    selected: [],
    headers: [
      { text: "Vehicle", value: "vehicle", align: "left" },
      { text: "Completion", value: "completion", align: "left" },
      { text: "Service Type", value: "service_details", align: "left" },
      { text: "Photos", value: "photos", align: "left" },
      { text: "Total", value: "grand_total", align: "left" },
      { text: "Action", value: "action", align: "end" }
    ],

    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" },
      { title: "View Full History", icon: "history", action: "download" }
    ]
  }),



  methods: {
    getDateTimeFormat:function(theDate){
      return moment(theDate).format("ddd, Do MMM YYYY")
    },


    getPhotos: function(list) {
      if (list.length > 2) {
        return list.slice(0, 2);
      } else {
        return list;
      }
    },

    onClickRow(item){
      console.log(item);
      this.$store.commit("service/setCurrentIndexByID", item.id);
      this.$router.push({
        name: "serviceOverview",
        params: { serviceID: item.id }
      }).catch(()=>{})
    },

    getConcatList: function(service_details){
      return service_details.length > 2 ? service_details.slice(0,2) : service_details;
    },

    onClick: function(value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("service/setCurrentIndexByID", value.theObject.id);
          this.$router.push({
            name: "serviceDetailForm",
            params: { serviceID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          confirm(`Are you sure you want to delete this service item?`) &&
          this.$store.dispatch("service/deleteService",value.theObject.id);
          break;
        case "history":
          console.log("history");
          break;
      }
    }
  },

  computed: {
    ...mapGetters({
      getVehicle: "vehicles/getVehicleByID",
      getVehicleThumbnail: "vehicles/getThumbnailByVehicleID"
    }),
    selectedServices: {
      get: function () {
        return this.$store.getters["service/getSelectedServices"];
      },
      set: function(value) {
        this.$store.commit("service/setSelectedServices", value);
      }
    }
  },

  mounted() {



  }
};
</script>

<style scoped>
.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}
.moreService{
  border-radius: 5px;
  padding: 2px 8px;
}

.showPointer{
  cursor: pointer;
}
</style>
