import { render, staticRenderFns } from "./CustomerInventories.vue?vue&type=template&id=04314898&scoped=true&lang=pug"
import script from "./CustomerInventories.vue?vue&type=script&lang=js"
export * from "./CustomerInventories.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04314898",
  null
  
)

export default component.exports