<template lang="pug">
  #blankcontent()
    .panelTitle() Donut Chart
    v-divider.mx-2()
    donut-chart(:style="{height:`${clientHeight-40}px`, width: `100%`}" :chartdata="getDataSets" :options="chartOption")

</template>
<script>
import DonutChart from "@/modules/global/components/charts/DonutChart";
export default {
  components: {
    DonutChart
  },
  props: {
    clientHeight: Number
  },
  data: () => ({
    chartOption: {
      cutoutPercentage: 30,
      responsive: true,
      maintainAspectRatio: false,

      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 10,
          bottom: 0
        }
      }
    }
  }),
  computed: {
    getDataSets: function() {
      let obj = {
        labels: ["Jan", "Feb", "Mar"],
        datasets: [
          {
            label: "Months",
            data: [40, 20, 60]
            // borderWidth: 2
          }
        ]
      };
      console.log(obj);
      obj.datasets[0].backgroundColor = ["#880E4F", "#D81B60", "#CCCCCC"];
      // obj.datasets[0].borderColor = this.$vuetify.theme.themes.light.primary;
      return obj;
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight bold
  padding: 5px 30px;
  height:30px;
  display: flex;
  width: 100%
  justify-content: space-between;
}

.lineChartPanel {
  height: calc(100% - 45px);
}
</style>
