<template lang="pug">
  v-data-table.elevation-1(
    v-model="selected" 
    :items-per-page="5" 
    :custom-filter="odoemeterFilter" 
    :search="search"  
    :headers='headers'
    :items='$store.state.odometers.odometer_data'
    item-key="id"
    :loading='loading'
    :single-select="false" 
    show-select
    )
    template(v-slot:item.vehicle="{item}")
      vehicle-column(:vehicle="item.vehicle")

    template(v-slot:item.date="{item}")
      date-column(v-model="item.date_time")


    template(v-slot:item.odometer_reference="{item}")
      | {{item.odometer_reference.toUpperCase()}}

    template( v-slot:item.action="{ item }")
      v-row.mr-1(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')
   

</template>
<script>
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import DateColumn from "@/modules/global/components/lists/DateColumn";

export default {
  props: {
    search: String
  },
  components: {
    VehicleColumn,
    MoreDropDownMenu,
    DateColumn
  },
  data: () => ({
    selected: [],
    loading: false,
    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },

      { title: "Overview", icon: "dashboard", action: "overview" },
      { title: "Download", icon: "cloud_download", action: "download" },
      { title: "Archive", icon: "archive", action: "delete" }
    ],
    headers: [
      {
        text: "Vehicle",
        value: "vehicle",
        tooltip: "Vehicle Name"
      },
      { text: "Description", value: "description" },
      { text: "Date", value: "date" },
      { text: "Meter", value: "meter" },
      { text: "Reference", value: "odometer_reference", align: "center" },
      { text: "Actions", value: "action", align: "right", sortable: false }
    ]
  }),

  methods: {
    onClick: function(value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          // select the form based on the value
          switch (value.theObject.odometer_reference) {
            case "fuel":
              this.$store.commit(
                "fuel/setCurrentIndexByID",
                value.theObject.odometer_reference_id
              );
              this.$router.push({
                name: "fuelDetailsForm",
                params: { fuelID: value.theObject.odometer_reference_id }
              }).catch(()=>{});
              break;
            case "issue":
              this.$store.commit(
                "issues/setCurrentIndexByID",
                value.theObject.odometer_reference_id
              );
              this.$router.push({
                name: "issueDetailsForm",
                params: { issueID: value.theObject.odometer_reference_id }
              }).catch(()=>{});
              break;
          }

          break;
        case "delete":
          console.log("delete");
          break;
      }
    },

    odoemeterFilter: function(value, search, item) {
      let isFound = false;
      let patt = new RegExp(search.toLowerCase(), "i");
      // Search the Object
      for (var key in item) {
        if (item[key] != null) {
          if (item[key].toString().match(patt)) {
            isFound = true;
            break;
          }
        }
      }

      if (isFound == false) {
        for (key in item.vehicle) {
          if (item.vehicle[key] != null) {
            if (item.vehicle[key].toString().match(patt)) {
              isFound = true;
              break;
            }
          }
        }
      }

      return isFound;
    }
  }
};
</script>
<style scoped lang="stylus"></style>
