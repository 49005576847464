<template lang="pug">
  div 
    panel-title-header(label="Renewal Reminders")
    .PairItems
      number-guage(:value="countRenewals" label="Renewals")
      number-guage(:value="countOverdueReminders" label="Overdue")
    v-divider

</template>
<script>
import NumberGuage from "@/modules/global/components/dashboard/NumberGuage";
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";

export default {
  components: {
    PanelTitleHeader,
    NumberGuage
  },
  data: () => ({
    countRenewals: 0,
    countOverdueReminders: 0
  }),

  methods: {
    onClick: function() {}
  }
};
</script>
<style scoped lang="stylus"></style>
