<template lang="pug">
  div
    v-autocomplete(
      v-model="selected"
      :items="items" 
      :loading="isLoading" 
      :search-input.sync="search" 
      item-text="name"
      item-value="id" 
      :label="label" 
      :placeholder="hint" 
      chips
      small-chips
      @change="onChange"

      ) 
</template>
<script>
export default {
  props: ["value", "prefix", "subModule", "label", "hint"],
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    search: null,
    items: [],
    selected: null
  }),

  methods: {
    onChange: function(theValue) {
      this.$emit("input", theValue);
    }
  },

  mounted() {
    this.selected = this.value;

    // if it is a submodule be sure to append the submodule when routing to vuex
    if (this.subModule) {
      let loadDataStore =
        this.subModule +
        "/" +
        this.prefix +
        "/loadAll" +
        this.prefix.charAt(0).toUpperCase() +
        this.prefix.slice(1) +
        "s";
      let prefixLower = this.prefix.toLowerCase();

      if (this.$store.state[this.subModule][this.prefix].currentIndex === -1) {
        this.isLoading = true;
        this.$store.dispatch(loadDataStore).then(() => {
          this.isLoading = false;
          this.items = this.$store.state[this.subModule][this.prefix][
            prefixLower + "_data"
          ];
        });
      }
    } else {
      // Ignore all subrouting to the vuex
      let loadDataStore =
        this.prefix +
        "/loadAll" +
        this.prefix.charAt(0).toUpperCase() +
        this.prefix.slice(1) +
        "s";

      if (this.$store.state[this.prefix].currentIndex === -1) {
        this.isLoading = true;
        this.$store.dispatch(loadDataStore).then(() => {
          this.isLoading = false;
          this.items = this.$store.state[this.prefix][this.prefix + "_data"];
        });
      }
    }
  }
};
</script>
<style scoped lang="stylus">
.imageCrop
  width: 60px
  height: 40px
  color red
  border-radius 5px
  background-color #FF0000
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

.miniLabel
  padding 2px 5px
  font-size 11px
  border-radius 5px
  margin-bottom 3px;
</style>
