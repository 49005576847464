<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    v-text-field(v-model="selectHint" label="Description")
    v-combobox.mt-3(v-model="selectRadio" :error-messages="errorMessages"  label="Type the options and press `Enter Key`"  multiple  small-chips)
      template(v-slot:selection="{ item, parent, selected }")
        v-chip(small close @input="removeOption(item)")
          | {{item}}
          
    v-checkbox(v-model="selectRequired" label="Required")
    
    
    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        show-advanced-properties(:showAdvanced="showAdvanced")
      div(v-show="showAdvanced")
        v-layout.pa-3.mb-0.pb-0(fluid column)
          p.my-0.py-0 Orientation of Radio Buttons
          v-radio-group.my-0.py-0(v-model="selectOrientation" column)
            v-radio(label="Column" value="column")
            v-radio(label="Row" value="row")
        v-checkbox(v-model="selectCommentOnFail" label="Must comment when there is no input")
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" diabled)


</template>
<script>
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";
import {inspectionPropertiesMixin} from "@/modules/inspections/components/formDesigner/properties/inspectionPropertiesMixin";

export default {
  mixins:[inspectionPropertiesMixin],
  data: () => ({
    errorMessages: [],
    options: [],
  }),

  computed: {
    selectRadio: {
      get: function() {
        return this.properties.options;
      },
      set: function(value) {
        this.errorMessages = [];
        if (value.length <= 0) {
          this.errorMessages.push("Enter option and press `enter`");
        }
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "options",
          value: value
        });
      }
    },

    selectOrientation: {
      get: function() {
        return this.properties.orientation;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "orientation",
          value: value
        });
      }
    },

  },

  methods: {
    removeOption: function(value) {
      let tempArray = JSON.parse(JSON.stringify(this.selectRadio));
      let index = tempArray.findIndex(theValue => theValue === value);
      tempArray.splice(index, 1);

      // save to store.
      this.selectRadio = tempArray;
    }
  }
};
</script>
<style scoped lang="stylus">
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}
</style>
