<template lang="pug">
  div.text-center
    div()
      div.primary--text {{unitLabel}} &nbsp;
        template(v-if="isPercent")
          span.display-1 {{numberWithCommas(value)}}%
        template(v-else)
          span.display-1 {{numberWithCommas(value)}}
      div.grey--text.text--darken-1
        | {{label}}
</template>
<script>
export default {
  props: {
    value: Number,
    label: String,
    unitLabel: String,
    isPercent: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({}),
  methods: {
    numberWithCommas: function (x) {
      if (x == null || isNaN(x)) return null;
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>
<style lang="stylus" scoped></style>
