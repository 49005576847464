import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const HISTORYMAP_API = "/track/history";

// ----------------------- STATE ------------------------- //
const state = {
    data: null,
    currentIndex: null,
    progress: 0, // a value between 0 and 100
    refresh: null,
    animation_properties: null // object with values that change
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getHistoryMap: state => {
        if (state.data) {
            return getField(state.data[state.currentIndex]);
        } else {
            return null;
        }
    },

    getTimlineCordinates: state => {
        if (state.data) {
            let coords = [];
            for (var i = 0; i <= state.data.length - 1; i++) {
                // console.log(
                //   `time ${new Date(state.data[i].time).getTime() / 1000}  yAxis: ${
                //     state.data[i].speed
                //   }`
                // );
                coords.push({
                    x: new Date(state.data[i].time).getTime() / 1000, // time interval
                    y: state.data[i].speed // variation interval
                });
            }

            return coords;
        }
    },

    getProgress: state => {
        return state.progress;
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    setAnimationProperties(state, payload) {
        state.progress = payload.progress;
        state.animation_properties = payload;
    },

    updateHistoryMap(state, payload) {
        updateField(state.data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.data.findIndex(
            historymap => historymap.id == theID
        );
    },

    loadHistoryMap(state, payload) {
        // console.log(payload.data);
        if (payload.data.length) {
            state.data = payload.data;
            state.currentIndex = 0;
        }
    },

    addHistoryMap(state, payload) {
        state.data.push(payload.data);
        state.currentIndex = state.data.length - 1;
    },

    saveHistoryMap(state, payload) {
        state.data[state.currentIndex] = payload.data;
    },

    fetchHistoryMap(state, payload) {
        if (state.currentIndex == -1) {
            state.data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.data[state.currentIndex] = payload.data;
        }
    },

    deleteHistoryMap(state, payload) {
        state.currentIndex = state.data.findIndex(
            historymap => historymap.id == payload.data.id
        );
        state.data.splice(state.currentIndex, 1);
    },

    reset(state) {
        state.data = null
        state.currentIndex = null
        state.progress = 0 // a value between 0 and 100
        state.refresh = null
        state.animation_properties = null // object with values that changedata = null

    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadHistoryMap({commit}, payload) {
        // console.log(from.toUTCString());
        // console.log(to.toUTCString());
        try {
            commit(
                "loadHistoryMap",
                await Repository.get(
                    `${HISTORYMAP_API}?vehicle_id=${payload.vehicle_id}&from=${payload.fromUTC}&to=${payload.toUTC}`
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async addHistoryMap({commit}) {
        await Repository.get(`${HISTORYMAP_API}/new`)
            .then(response => {
                commit("addHistoryMap", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveHistoryMap({state, commit}) {
        try {
            commit(
                "saveHistoryMap",
                await Repository.post(
                    `${HISTORYMAP_API}`,
                    state.data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchHistoryMap({commit}, payloadID) {
        try {
            commit(
                "fetchHistoryMap",
                await Repository.get(`${HISTORYMAP_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteHistoryMap({commit}, payloadID) {
        try {
            commit(
                "deleteHistoryMap",
                await Repository.delete(`${HISTORYMAP_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchHistoryMaps({commit}, searchTerm) {
        try {
            commit(
                "loadAllHistoryMaps",
                await Repository.get(`${HISTORYMAP_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
