<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    v-text-field(v-model="selectHint" label="Description")
    v-select(v-model="selectedFormat" label="Date Format" :items="dateFormatItems" :hint="computeDateFormat()" :persistent-hint="true" )
    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        showAdvancedProperties(:showAdvanced="showAdvanced")

        div(v-show="showAdvanced")
          permissions-component()
          v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)
</template>
<script>
import moment from "moment";
import SETTINGS_DEFAULT from "@/modules/workflow/formBuilder/store/formSettings";
import ShowAdvancedProperties from "@/modules/workflow/formBuilder/components/ui/ShowAdvancedProperties";
import PermissionsComponent from "@/modules/workflow/formBuilder/components/ui/PermissionsComponent.vue";
export default {
  components: {
    ShowAdvancedProperties,
    PermissionsComponent
  },

  data: () => ({
    properties: {},
    showAdvanced: false,
    dateFormatItems: [
      "D/MM/YYYY",
      "MM/D/YYYY",
      "D-MM-YYYY",
      "Do MMM YYYY",
      "Do MMMM YYYY",
      "YY/MM/DD",
      "YY-MM-DD",
      "MMMM D, YYYY",
      "MMMM Do, YYYY"
    ]
  }),

  computed: {
    selectedFieldName: {
      get: function() {
        return this.properties.label;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "label",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },

    selectHint: {
      get: function() {
        return this.properties.hint;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "hint",
          value: value
        });
      }
    },

    selectedFormat: {
      get: function() {
        return this.properties.date_format;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "date_format",
          value: value
        });
      }
    },

    selectID: {
      get: function() {
        return this.$store.getters[SETTINGS_DEFAULT.GET_PATH].id;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    }
  },

  methods: {
    computeDateFormat: function() {
      //return "haleluyah"
      return "example: " + moment(new Date()).format(this.selectedFormat);
    }
  },

  mounted() {
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    this.$refs.fieldName.focus();
  }
};
</script>
<style scoped lang="stylus">
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}
</style>
