<template lang="pug">
.vehicleText(v-if="vehicle != null")
  div {{ vehicle.name }}
  .primary--text
    | {{ vehicle.make }}
  div()
    | #
    b.grey--text.text--darken-4
      | {{ vehicle.number_plate }}
</template>
<script>
import VehicleStatus from "@/modules/global/components/others/Status";
export default {
  components: {
    VehicleStatus,
  },
  props: ["vehicle_id"],
  data: () => ({
    vehicle:null
  }),
  mounted() {
    let tempVehicle = this.$store.getters["vehicles/getVehicleByID"](this.vehicle_id);
    if(tempVehicle == undefined){
      this.$store.dispatch("vehicles/loadAllItems").then(()=>{
        tempVehicle = this.$store.getters["vehicles/getVehicleByID"](this.vehicle_id);
        this.vehicle = tempVehicle;
      })
    }else{
      this.vehicle = tempVehicle;
    }
  }
};
</script>
<style scoped lang="stylus">
.vehicleText{
  font-size 10.5px;
}
</style>
