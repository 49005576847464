<template lang="pug">
div()
  v-data-table(
    :headers="headers",
    :items="$store.state.workflow.workflowRecord.record_data",
    item-key="id",
    :loading="loading",
    :single-select="false",
    show-select
  )
    template(v-slot:item.contact="{ item }")
      div(v-if="item.contact" @click="onClickRow(item)")
        div {{ item.contact.first_name }} {{ item.contact.last_name }}
        timeago.grey--text(:datetime="item.updated_at")

    template(v-slot:item.updated_at="{ item }")
      div(v-if="item.data.length > 0") {{item.data[0].value}}

    template(v-slot:item.workflow_template_name="{ item }")
      div {{ item.workflow_template_name }} &mdash; v{{ item.workflow_template_version }}
      .caption.grey--text() &#35;{{item.tx_id}}

    template(v-slot:item.data="{ item }")
      div(v-if="item.data") {{ item.data.length }}

    template(v-slot:item.vehicle_id="{ item }")
      vehicle-column-by-id(v-if="item.vehicle_id" :vehicle_id="item.vehicle_id" size="small")


    template(v-slot:item.geotag="{ item }")
      geo-tag-column(v-if="item.geotag" :geotag="item.geotag" @showMap="onShowMap" )


    template(v-slot:item.action="{ item }")
      v-row.mr-7(justify="end")
        more-drop-down-menu(
          :items="menuitems",
          @onClick="onClick",
          :theObject="item"
        )

    template(v-slot:item.vehicle="{ item }")
      div(v-if="item.vehicle", v-text="item.vehicle.name")

  map-dialog.mapDialog(v-show="coordinates" @closeMap="onCloseMap" :coordinates="coordinates" :radius="radius" :label="mapLabel" :zoom="zoom" :lineArray="lineArray")
</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import { searchMixin } from "@/mixin/searchListMixin";
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById";
import GeoTagColumn from "@/modules/global/components/table/GeoTagColumn";
import mapDialog from "@/modules/global/components/table/MapDialog";
export default {
  components: {
    MoreDropDownMenu,
    VehicleColumnById,
    GeoTagColumn,
    mapDialog,
  },
  props: {
    search: String,
  },

  mixins: [searchMixin],

  data: () => ({
    loading: false,
    headers: [

      {
        text: "Submitted By",
        value: "contact",
      },

      {
        text: "Form",
        value: "workflow_template_name",
      },
      {
        text: "Geotag",
        value: "geotag",
      },
      {
        text: "Vehicle",
        value: "vehicle_id",
      },

      {
        text: "Action",
        value: "action",
        align: "right",
      },
    ],
    menuitems: [
      // { title: "Edit Table", icon: "table_chart", action: "table" },
      { title: "Edit Table", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" },
    ],
    coordinates: null,
    radius: null,
    mapLabel: null,
    zoom: 0,
    lineArray: null,
  }),

  computed: {
    getTitleAndSubtitle: function(value) {
      return value;
    },
  },

  methods: {
    onCloseMap() {
      this.coordinates = null;
    },
    async onShowMap(item) {
      this.coordinates = item.location;
      this.mapLabel = item.street;
      this.radius = null;
    },

    onClickRow:function(value){
      this.$store.commit("workflow/setCurrentIndexByID",this.$route.params.workflowID)
      this.$store.commit("workflow/workflowRecord/setCurrentIndexByID",value.id);



      //this.$store.commit("showRightDrawer",{title:"Record",width:"800",component:"RecordDetails"})
      this.$router.push({
        name: "workflowRecordOverview",
        params: { workflowID: this.$route.params.workflowID,workflowRecordID: value.id },
      }).catch(()=>{});
    },
    onClick: function(value) {
      switch (value.action) {
        case "edit":
          console.log(value.theObject);
          this.$store.commit("workflow/setCurrentIndexByID",this.$route.params.workflowID)
          this.$store.commit(
            "workflow/workflowRecord/setCurrentIndexByID",
            value.theObject.id
          );

          this.$store
            .dispatch("workflow/workflowRecord/fetchRecord", value.theObject.id)
            .then(() => {
              this.$router.push({
                name: "workflowRecordForm",
                params: { workflowID: value.theObject.id },
              }).catch(()=>{});
            });

          break;
        case "delete":
          confirm("Are you sure you want to delete the record?") &&
            this.$store.dispatch(
              "workflow/workflowRecord/deleteRecord",
              value.theObject.id
            );
          break;
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.underlineOver {
  height: 40px;
  display: flex;
  align-items: center;
}

.underlineOver:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
