<template lang="pug">
  div
    v-menu(
      v-model='menu'
      ref='menu'
      :close-on-content-click="false"
      :nudge-right="70"
      :return-value.sync="time"
      transition='scale-transition' 
      offset-y
      
    )
      template(v-slot:activator='{ on }')
        v-text-field(v-model='computedTime',prepend-icon="access_time", :label="schema.label" :hint="schema.hint"  :persistent-hint="schema.hint != null" readonly v-on='on')
      v-time-picker( v-if="menu" :format="schema.time_format" @input="onChange" v-model='time'  )
        v-btn(flatoutlined color="primary" @click="onCancel()") Cancel
        v-spacer()
        v-btn(color="primary" @click="onSave()") Save
</template>
<script>
import moment from "moment";
import SETTINGS from "@/modules/inspections/store/formSettings";
export default {
  props: {
    schema: Object
  },
  data: () => ({
    menu: false,
    time: null
  }),

  computed: {
    computedTime: {
      get: function() {
        let val;
        val = this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id);
        if (val == null) {
          val = "00:00";
        }
        this.onSetTime(val);

        if (this.schema.time_format === "ampm") {
          return moment(val, "HH:mm").format("LT");
        } else {
          return moment(val, "HH:mm").format("HH:mm");
        }
      },
      set: function(theValue) {
        console.log(`the time is set to: ${theValue}`);
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }
  },

  methods: {
    onSetTime: function(value) {
      this.time = value;
    },

    onChange: function(value) {
      this.time = value;
    },

    onSave: function() {
      this.computedTime = this.time;
      this.menu = false;
    },

    onCancel: function() {
      this.menu = false;
    }
  },

  mounted() {
    let theValue = this.computedTime;
    if (theValue == null) {
      if (this.schema.default != null) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: this.schema.default
        });
      }
    }
  }
};
</script>
<style scoped lang="stylus"></style>
