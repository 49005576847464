<template lang="pug">
  div(v-if="$store.state.inventory.currentIndex > -1 && item != undefined")
    v-layout.pt-2.pr-3(align-center)
      v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
      v-spacer
      v-btn.ma-2(small color="primary" outlined dark @click="cancel") cancel
      v-btn.ma-2(small color="primary" @click="save") save



    v-form(ref="form" v-model="valid")
      v-container.py-2
        v-alert(v-if="errors.length" :value="true" color="error" icon="warning")
          div(v-for="(e,index) in errors" :key="index" v-text="e")

      v-container
        v-card

          v-card-text
            v-container(grid-list-xl)
              item-column-by-id(:item_id="item.id")
              form-divider
                | {{item.name.toUpperCase()}} &nbsp;
                template(v-if="mode == 'add'")
                  span() IN [Add Items]
                template(v-else)
                  span() OUT [Subtract Items]

              v-row.mt-4()
                v-col.px-2(cols="12" xs="12" md="6"  )
                  vehicle-auto-complete(v-model="vehicle_id" clearable)
                v-col.px-2(cols="12" xs="12" md="6")
                  template(v-if="mode == 'add'")
                    v-text-field(v-model.number='credit',  :label="item.measurement_unit != null ? item.measurement_unit.name:'units'")
                  template(v-else="mode=='subtract")
                    v-text-field(v-model.number='debit',  :label="item.measurement_unit != null ? item.measurement_unit.name:'units'")
                v-col.px-2(cols="12" xs="12" md="6" )
                  .durationStyle
                    .subtitle-1() Start Time
                    auto-date-time-picker(v-model="transaction", labelDate="Date & Time", labelTime="Date & TIme")
                v-col.px-2(cols="12" xs="12" md="6" )
                  .durationStyle
                    .d-flex.align-center()
                      .subtitle-1() End Time &nbsp;
                      .caption(v-if="getDuration() != null") (i.e. Submitted Time + {{getDuration()}})
                    auto-date-time-picker(v-model="end_at", labelDate="Date & Time", labelTime="Date & TIme")
                v-col.px-2(cols="12" xs="12" md="6" )
                  template(v-if="mode == 'add'")
                    auto-combo-box(v-model='merchant_id' label="Select Supplier"  module="merchant" actionPrefix="Merchant" field="name")
                  template(v-else)
                    auto-combo-box(v-model='customer_id' label="Select Customer"  module="customer" actionPrefix="Customer" field="full_name")

                v-col.px-2(cols="12" xs="12" md="6"  )
                  v-text-field(v-model='comment',  label='Comment')


              form-divider(v-if="item.item_details!=null && item.item_details.custom_fields != null") Custom Fields
              v-row.mt-4(v-if="item.item_details!=null && item.item_details.custom_fields != null")
                v-col.px-2(cols="12" xs="12" md="6"  v-for="(element,index) in item.item_details.custom_fields" :key="index")
                  v-text-field(v-model="details[`${element.label_id}`]" :label="element.label" @change="customChange")



</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import {createHelpers} from "vuex-map-fields";
import ItemColumnById from "@/modules/global/components/table/ItemColumnById";
import AutoDateTimePicker from "@/modules/global/components/forms/AutoDateTimePicker";
import AutoComboBox from "@/modules/global/components/forms/AutoComboBox";
import VehicleAutoComplete from "@/modules/global/components/forms/VehicleAutoComplete";
import moment from "moment"

const {mapFields} = createHelpers({
  getterType: "inventory/getInventory",
  mutationType: "inventory/updateInventory"
});
export default {
  components: {
    FormDivider,
    ItemColumnById,
    AutoDateTimePicker,
    AutoComboBox,
    VehicleAutoComplete
  },

  data: () => ({
    error: false,
    errors: [],
    valid: true,

  }),

  computed: {
    inventory: function () {
      return this.$store.getters["inventory/getCurrentInventory"];
    },
    item: function () {
      return this.$store.getters["inventory/item/getCurrentItem"];
    },

    transaction: {
      get: function () {
        return this.start_at;
      },
      set: function (value) {
        this.start_at = value;

        if (this.item.duration != null) {
          if (this.item.duration.Microseconds > 0) {
            this.end_at = moment(value).add(Math.round(this.item.duration.Microseconds / 3600000000), 'hours').toISOString();
          } else if (this.item.duration.Days > 0) {
            this.end_at = moment(value).add(this.item.duration.Days, 'days').toISOString();
          } else if (this.item.duration.Months > 0) {
            this.end_at = moment(value).add(this.item.duration.Months, 'months').toISOString();
          }

        }
      }
    },

    mode: function () {
      return this.$store.getters["inventory/getMode"];
    },
    ...mapFields([
      "start_at",
      "end_at",
      "credit",
      "debit",
      "submitted",
      "submitted_id",
      "merchant",
      "merchant_id",
      "customer",
      "customer_id",
      "comment",
      "comment_id",
      "vehicle",
      "vehicle_id",
      "account",
      "account_id",
    ]),

    details: {
      get: function(){
        if(this.inventory.details == null){
          let temp = {};
          if(this.item.item_details != null && this.item.item_details.custom_fields != null && this.item.item_details.custom_fields.length > 0){
            this.item.item_details.custom_fields.forEach(element => {
              temp[`${element.label_id}`] = null
            })
          }
          return temp;
        }
        return this._.cloneDeep(this.inventory.details);
      },
      set: function(value){
        console.log(value)
      }
    },

  },

  mounted() {
    if (this.item == undefined) {
      this.$router.push({
        name: "inventorys",
        params: {tabID: 0}
      }).catch(()=>{});
    }

    // for trashhandlers
    if (this.$store.state.inventory.currentIndex == -1) {
      this.$store.dispatch("inventory/fetchInventory", this.$route.params.itemID)
    }
  },

  methods: {
    customChange: function(){
      this.$store.commit("inventory/updateInventory",{path:"details",value:this.details})
      if(this.details['weeks']){
        this.end_at = moment(this.start_at).add(this.details['weeks'],'week').toDate();
      }
    },


    getDuration() {
      if (this.item.duration == null) return null;
      if (this.item.duration.Microseconds > 0) {
        return `${Math.round(this.item.duration.Microseconds / 3.6e+9)} Hours`
      }
      if (this.item.duration.Days > 0) {
        return `${this.item.duration.Days} Days`
      }
      if (this.item.duration.Months > 0) {
        return `${this.item.duration.Months} Months`
      }
      return null;
    },
    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Item Info",
        disabled: false,
        to: {name: "inventoryOverviewLayout"},
      });
      theArray.push({
        text: "Item Entry",
        disabled: true,
      });
      return theArray;
    },

    cancel: async function () {
      await this.$store.dispatch("inventory/loadAllInventorys");
      this.$router.push({
        name: "inventoryOverviewLayout",
        params: {itemID: this.item.id, tabID: 0}
      }).catch(()=>{});
    },

    save: async function () {
      this.$store.dispatch("inventory/saveInventory").then(() => {
        this.$router.push({
          name: "inventoryOverviewLayout",
          params: {itemID: this.item.id, tabID: 0}
        }).catch(()=>{});
      })

    }
  }
}
</script>

<style lang="stylus" scoped>
.durationStyle {
  border 1px solid grey
  border-radius 20px;
  padding 10px 10px;
  background-color: #f5f5f5
}
</style>