<template lang="pug">
  .signatureRow(v-if="image_id" :style="{'background-image': `url('${image_id}')`}")
</template>

<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import axios from "axios";
export default {
 props:["id"],
  data:()=>({
    image_id:null,
  }),
  watch:{
   id:function(){
     this.getImageID();
   }
  },
  components:{
    ImageThumbnail
  },
  methods:{
   getImageID(){
     axios.get(`/api/upload/blob/${this.id}`).then(response => {
       console.log(`/media/${response.data.account_id}/${response.data.thumbnail}`)
       this.image_id = `/media/${response.data.account_id}/${response.data.thumbnail}`;
     })
   }
  },
  mounted() {
    this.getImageID();
  }
}
</script>

<style scoped>
.signatureRow{
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #CCCCCC;
  width: 80px;
  height: 60px;
  border-radius: 5px;
  margin: 5px 3px;
}
</style>