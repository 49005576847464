<template lang="pug">
  div()
    .py-2(v-if="!isLoading" )
      .grey.lighten-4.px-2.d-flex.flex-column.rounded-lg()
        .d-flex
          v-icon() visibility
          roles-auto-complete.mt-2.ml-2(v-model="read_roles_id"  :multiple="true" :label="read_roles_id.length > 0 ? `Read Permissions` : `Read Permissions: All Roles`" mode="read" style="width:100%" )
        .d-flex
          v-icon() edit
          roles-auto-complete.mt-4.ml-2(v-model="write_roles_id"  :multiple="true" :label="write_roles_id.length > 0 ? `Edit Permissions` : `Edit Permissions: All Roles`" mode="write" style="width:100%")


</template>

<script>

import RolesAutoComplete from "@/modules/global/components/forms/RolesAutoComplete.vue";
import SETTINGS_DEFAULT from "@/modules/workflow/formBuilder/store/formSettings";
export default {
  components:{
    RolesAutoComplete
  },
  data: ()=>({

    isLoading:true,
    properties: {},
  }),
  computed:{
    read_roles_id:{
      get: function(){
        if (this.properties.permissions == null || this.properties.permissions.read == null ||  this.properties.permissions.read.length == 0) {
          return [];
        }else{
          return this.properties.permissions.read;
        }
      },
      set: function(value){
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "permissions",
          value: {read:value,write:this.write_roles_id}
        });
      }
    },

    write_roles_id:{
      get: function (){
        if (this.properties.permissions == null || this.properties.permissions.write == null || this.properties.permissions.write.length == 0) {
          return [];
        }else{
          return this.properties.permissions.write;
        }
      },
      set: function(value){
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "permissions",
          value: {read:this.read_roles_id,write:value}
        });
      }
    }
  },
  methods:{
    init:function() {
      //
      // if(this.properties.permissions != null){
      //   this.read_roles_id =  this.properties.permissions
      //   this.write_roles_id = this.properties.permissions;
      // }
      this.isLoading = false;
      // console.log(this.properties)
    },

    onChange:function(){
      this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
        field: "permissions",
        value: {read:this.read_roles_id,write:this.write_roles_id}
      });
    }
  },
  mounted() {
    this.isLoading = true;
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    if(this.properties.permissions == undefined ||  this.properties.permissions == null){
      this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
        field: "permissions",
        value: {read:[],write:[]}
      });
      this.read_roles_id = [];
      this.write_roles_id = [];

    }else{
      this.read_roles_id = this.properties.permissions.read;
      this.write_roles_id = this.properties.permissions.write;
    }


    if (this.$store.state.settings.roleSettings.currentIndex == -1) {
      this.$store.dispatch("settings/roleSettings/loadAllRoles").then(() => {
        this.init();
      });
    }else{
      this.init();
    }
  }
}
</script>

<style scoped lang="stylus">

</style>