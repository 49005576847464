<template lang="pug">
  #Overview()
    .OverviewHeader.lighten-4(v-if="getCurrentInspectionForm")
      v-toolbar(dense flat)
        generic-bread-crumbs.pl-2.py-1(:items="getBreadCrumbItems()")
        v-spacer
        v-menu(offset-y)
          template( #activator="{on}")
            v-btn.mx-3(v-on="on" color="primary" small outlined )
              | Actions
              v-icon.pl-1(small) arrow_drop_down
          v-list
            v-list-item(v-for="(item, index) in items2" :key="index" )
              v-list-item-title {{ item.title }}
        v-btn.px-3(color="primary"  small @click="onRefresh")
          v-icon.pr-2(small) refresh
          | Refresh
        
      .HeaderBody.px-6
        v-layout(align-center)
          image-thumbnail.mr-3(v-if="getCurrentInspectionForm.thumbnail_id" :width="80" :height="80"  :src="getCurrentInspectionForm.thumbnail_id")
          .HeaderText
            h2.headline.font-weight-bold.grey--text.text--darken-4 {{getCurrentInspectionForm.name}}
            p.ma-0.pa-0.caption.font-weight-regular.grey--text.text--darken-2 {{getCurrentInspectionForm.description}}  &ndash; Latest Version · {{ getCurrentInspectionForm.version }}
            v-divider.mt-1
            v-layout.caption()
              .HorizontalAlignDiv2(v-if="getCurrentInspectionForm.last_record_update_at")
                span.grey--text.text--darken-2 {{getUpdatedAt}}
                v-icon.pl-1(small) edit

              div
                v-menu(offset-y)
                  template( #activator="{ on }")
                    .HorizontalAlignDiv(v-on="on" color="primary" small outlined )
                      | {{getCurrentInspectionForm.status}}
                      span.ml-2
                      v-icon.pl-1(small) arrow_drop_down
                  v-list(dense)
                    v-list-item(v-for="(item, index) in items1" :key="index" @click="onAction(item.title)")
                      v-list-item-title {{ item.title }}

              .HorizontalAlignDiv(@click="onAction('group')")
                p.ma-0.pa-0 {{getCurrentInspectionForm.author.group ? getCurrentInspectionForm.author.group.name  : "No Group"}}
                  v-icon.pl-1(small) edit

              .HorizontalAlignDiv(@click="onAction('assignments')")
                p.ma-0.pa-0 {{getCurrentInspectionForm.author ? getCurrentInspectionForm.author.first_name+' '+getCurrentInspectionForm.author.last_name  : "Unassigned"}}
                  v-icon.pl-1(small) add_circle

        v-spacer
        .ServiceReminders.caption
          .Reminders.grey--text.text--darken-3.pl-2 Total Records
          .ReminderUnits
            span.subheading.font-weight-bold {{getCurrentInspectionForm.record_count}}


      //- Body
    .mainWorkflowBody
      //- OverviewSideBar
      .OverviewSideBar
        v-btn.ma-3(small color="primary" style="width:90%" @click="onAddRecord")
          v-icon.mr-2(small) add
          | Quick Add
        vehicle-overview-side-bar-item.menuSpacing.mr-2.pl-4(v-for="(item,index) in overviewItems1" :key="'overview1'+index"   :label="item.label" :icon="item.icon" :value="item.value" :url="item.url" @onclick="onSideBar(item)")
        v-divider.mb-3.mt-3

      <!-- ROUTER -->

      v-layout(fill-height)
        router-view(v-if="getCurrentInspectionForm" :key="key")

      //- dialog
    v-dialog(v-if="modal" ref='modal' v-model='modal' @close='hideModal' max-width="1200px")
      v-card()
        v-card-title.primary.white--text() {{getCurrentInspectionForm.name}}
        v-progress-linear(:indeterminate="showProgress" color="primary")
        v-container.grid-list-md.py-6(v-if="$store.state.inspection.currentIndex > -1")
          v-form(ref="form")
             record-renderer(v-if="getRecordSchema()" )
        v-divider
        v-card-actions()
          v-spacer()
          v-btn(color="grey" outlined @click="hideModal") Cancel
          v-btn(color="primary" type="submit"  @click="saveRecord") Submit
</template>

<script>
import moment from "moment";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import VehicleOverviewSideBarItem from "@/modules/global/components/others/OverviewSideBarItem";
import RecordRenderer from "@/modules/inspections/components/formDesigner/RecordRenderer";

export default {
  components:{
    RecordRenderer,
    GenericBreadCrumbs,
    ImageThumbnail,
    VehicleOverviewSideBarItem
  },
  data: ()=>({
    key :0,
    modal: false,
    showProgress: true,
    items1: [
      { title: "Add Record" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" }
    ],
    items2: [
      { title: "To Do" },
    ]
  }),
  computed:{
    getCurrentInspectionForm: function() {
      return this.$store.getters["inspection/inspectionForm/getCurrentInspectionForm"];
    },

    getUpdatedAt: function () {
      return moment(this.getCurrentInspectionForm.last_record_update_at).format("ddd, Do MMM YYYY, h:mm a");
    },

    overviewItems1: function() {
      let obj = [];

      obj.push({
        label: "Dashboard",
        icon: "dashboard",
        url: "inspectionFormDashboard"
      });



      obj.push({
        label: "Inspection Forms",
        icon: "dashboard",
        url: "inspectionRecords"
      });

      // obj.push({
      //   label: "Forms with Failure",
      //   icon: "dashboard",
      //   url: "workflowBoardOverview"
      // });

      // obj.push({
      //   label: "Records",
      //   icon: "dashboard",
      //   url: "workflowRecords"
      // });





      obj.push({
        label: "Permissions",
        icon: "dashboard",
        url: "inspectionPermissions"
      });

      obj.push({
        label: "Settings",
        icon: "dashboard",
        url: "inspectionSettings"
      });

      return obj;

    },


  },

  methods:{
    saveRecord: function() {
      this.showProgress = true;
      this.$store.commit("inspection/updateInspection", {path: "submitted_on", value: new Date().toISOString()})
      this.$store.dispatch("inspection/saveInspection").then(()=>{
        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Form ${this.getCurrentInspectionForm.name} is saved`,
        });
        this.hideModal();
        this.$store.dispatch("inspection/inspectionForm/fetchInspectionForm", this.$route.params.inspectionID)
        this.key +=1;
      })
    },

    getRecordSchema: function() {
      return this.getCurrentInspectionForm.data[this.getCurrentInspectionForm.version-1].schema;

    },
    onSideBar:function(item){
      this.$router.push({
        name: item.url
      }).catch(()=>{});
      this.$store.commit("hideRightDrawer");
    },
    onAction:function(theAction){
      this.$router.push({name:"inspectionFormDashboard"}).catch(()=>{});
      console.log(theAction)
    },
    onRefresh:function(){
      this.$store.dispatch("inspection/inspectionForm/fetchInspectionForm", this.$route.params.inspectionID)
      this.key +=1;
    },
    getBreadCrumbItems: function() {
      var theArray = [];
      theArray.push({
        text: "Inspection Form List",
        disabled: false,
        path:  "inspections",
        tabID: 1
      });
      theArray.push({
        text: this.getCurrentInspectionForm.name,
        disabled: true
      });
      return theArray;
    },
    onAddRecord() {
      this.$store.dispatch("inspection/addInspection",this.$route.params.inspectionID).then(()=>{
        this.modal = true;
        this.showProgress = false;
      })
    },

    hideModal() {
      this.modal = false;
      this.showProgress = false;
      // this.$store.commit("inspection/inspectionForm/clearall");
    },
  },
  mounted() {
    if (this.$store.state.inspection.inspectionForm.currentIndex == -1) {
      this.$store
          .dispatch("inspection/inspectionForm/fetchInspectionForm", this.$route.params.inspectionID)
          .then(() => {
            // this.$store.commit("workflow/formBuilder/setSchema",this.$store.getters["workflow/getLatestSchema"])
            console.log(this.getCurrentInspectionForm);
          });
    }
  }
}
</script>

<style scoped lang="stylus">
#Overview{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.OverviewSideBar {
  width: 250px;
  height: 100%;
  background-color: #F5F5F5;
}

.mainWorkflowBody {
  border-top: 1px solid #CCCCCC;
  display: flex;
  position absolute;
  top 150px;
  bottom 0px;
  left 0px;
  right 0px;
}
</style>