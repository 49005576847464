<template lang="pug">
  #blankcontent()
</template>
<script>
export default {
  data: () => ({})
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}
</style>
