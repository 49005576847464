<template lang="pug">
div
  v-divider
  v-container(fluid)
    v-layout.my-2(row, wrap)
      div
        h2.mx-6 Vehicle Assignments Summery
        generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer
      .text-xs-center
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color="primary", dark, v-on="on")
              | Export
              v-icon(dark) keyboard_arrow_down
          v-list-item-group
            v-list-item(
              v-for="item in items",
              :key="item.title",
              dense,
              @click="onExport(item.value)"
            )
              v-list-item-title
                v-layout(align-center)
                  v-icon.mr-2(:size="20") {{ item.icon }}
                  .subtitle-2.mx-2 {{ item.title }}
      v-btn.ma-2.mr-5(small, color="primary", dark, @click="print")
        v-icon.mr-2 print
        | Print
  v-divider
  .mx-4.my-2
    vehicle-filter-dialog
    v-menu(:close-on-content-click="false")
      template(v-slot:activator="{ on }")
        v-btn.mx-2(outlined, color="primary", v-on="on")
          | Columns
          v-icon(dark) keyboard_arrow_down
      .reportHeaderList
        v-list-item-group
          v-list(dense)
            v-list-item(v-for="(item, index) in headers", :key="index")
              template(v-slot:default="{ active }")
                v-list-item-action
                  v-checkbox(v-model="item.visible", color="primary")
                v-list-item-content(@click="item.visible = !item.visible")
                  v-list-item-title.body-2 {{ item.text }}

  v-divider
  .grey.lighten-4.py-3
  v-divider
  div
    v-data-table.elevation-1(
      :headers="getHeaders",
      :items="dataItems",
      item-key="id"
    )
      template(v-slot:item.first_name="{ item }")
        div {{ item.first_name }} {{ item.last_name }}

      template(v-slot:item.during.start="{ item }")
        div {{ item.during.start | moment('ddd, Do MMM YYYY, h:mm a') }}

      template(v-slot:item.during.end="{ item }")
        div {{ item.during.end | moment('ddd, Do MMM YYYY, h:mm a') }}

      template(v-slot:item.during="{ item }")
        div {{ getDuration(item.during.tz_start, item.during.tz_end) }}

      template(v-slot:item.distance="{ item }")
        div {{ getDistance(item) }}

  export-data-dialog(
    :fileExport="fileExport",
    :isFinished="isDownloadFinished",
    v-bind:show="showExportDialog",
    @closeExportDialog="onCloseExportDialog",
    @download="onDownload"
  )
</template>

<script>
import VehicleFilterDialog from "@/modules/global/components/dialog/VehicleFilterDialog";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import Repository from "@/repository";
import moment from "moment";

export default {
  components: {
    GenericBreadCrumbs,
    VehicleFilterDialog,
    ExportDataDialog
  },
  data: () => ({
    isDownloadFinished: false,
    fileLink: null,
    showExportDialog: false,
    fileExport: "",
    breadCrumbsItems: [
      {
        text: "Report",
        disabled: false,
        path: "reports"
      },
      {
        text: "Vehicle Assignments Summery",
        disabled: true,
        path: "vehicleAssignmentLog"
      }
    ],
    dialog: true,
    items: [
      {
        title: "Export CSV",
        icon: "cloud_download",
        value: "export_csv"
      },
      {
        title: "Export Excel",
        icon: "cloud_download",
        value: "export_excel"
      }
    ],
    search: "",

    headers: [
      { text: "Vehicle", align: "left", value: "vehicle_name", visible: true },
      { text: "Operator", align: "left", value: "first_name", visible: true },
      {
        text: "Start Date/Time",
        align: "left",
        value: "during.start",
        visible: true
      },
      {
        text: "End Date/Time",
        align: "left",
        value: "during.end",
        visible: true
      },
      { text: "Duration", align: "left", value: "during", visible: true }
    ],

    dataItems: []
  }),

  computed: {
    getHeaders: function() {
      let h = [];
      this.headers.forEach(item => {
        if (item.visible == true) {
          h.push(item);
        }
      });
      return h;
    }
  },

  methods: {
    getDuration: function(StartTime, EndTime) {
      console.log(
        `start time: ${StartTime} and EndTime: ${EndTime} and Duration : ${EndTime -
          StartTime}`
      );
      let diff = EndTime - StartTime;
      // if (diff > 57600000) {
      //   return "> 1 Day";
      // }
      let microseconds = moment
        .utc(moment.duration(diff / 1000, "seconds").asMilliseconds())
        .format("HH:mm");

      let days = moment
        .utc(moment.duration(diff / 1000, "seconds").asMilliseconds())
        .format("D");
      days = days > 1 ? `${days} Days - ` : "";
      return days + microseconds;
    },

    getDistance: function(item) {
      if (
        item.start_odometer != null &&
        item.end_odometer != null &&
        item.start_odometer < item.end_odometer
      ) {
        return item.end_odometer - item.start_odometer;
      }
      return item.start_odometer;
    },

    onExport: function(value) {
      switch (value) {
        case "export_csv":
          this.fileExport = "CSV";
          this.downloadFile("/report/CSV/assigment_summery");
          break;
        case "export_excel":
          this.fileExport = "Excel";
          this.downloadFile("/report/Excel/assigment_summery");
          break;
      }
      this.showExportDialog = !this.showExportDialog;
    },

    downloadFile: function(url) {
      this.isDownloadFinished = false;

      Repository({
        method: "POST",
        url: url,
        data: this.headers.filter(item => item.visible == true),
        responseType: "blob"
      }).then(response => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function() {
      // download the file
      this.fileLink.click();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function() {
      this.showExportDialog = false;
    },
    print: function() {
      console.log("print");
    },
    groupStyle: function(value) {
      let obj = {};
      obj["border-bottom"] = "3px solid";
      obj["border-bottom-color"] = value;
      return obj;
    },

    operatorStyle: function(operator) {
      let obj = {};
      obj["border-radius"] = "50%";
      obj["color"] = "#FFFFFF";
      obj["padding"] = "3px";
      obj["margin-right"] = "5px";

      if (operator.group != null) {
        obj["background-color"] = operator.group.color;
      } else {
        obj["background-color"] = "#333333";
      }
      console.log(operator.group);
      return obj;
    }
  },

  mounted() {
    Repository.get("/report/assignmentSummery").then(response => {
      this.dataItems = response.data;
    });
  }
};
</script>

<style scoped>
.reportHeaderList {
  overflow: auto;
  max-height: 400px;
  min-width: 300px;
}
</style>
