<template lang="pug">

  v-data-table(
    v-model="selected"
    :headers="headers"
    :search="search"
    :custom-filter="inspectionModuleFilter"
    :items="getFilteredList"
    item-key="id"
    :loading="loading"
    :single-select="false"
    show-select
  )
    template(v-slot:item.vehicle_id="{item}")
      vehicle-column-by-id-text-only(:vehicle_id="item.vehicle_id")

    template(v-slot:item.created_at="{item}")
      div(v-text="formatTime(item.created_at)")
      timeago.grey--text.caption(:datetime="item.created_at")
    template(v-slot:item.failed_items="{item}")
      div() Failed item (Fail)
      .caption.grey--text() The Name of the Form
    template(v-slot:item.comments="{item}")
      div.caption(v-if="item.comments.length > 0")  {{item.comments[0]}}
      ul.ma-0.pa-0.d-flex(v-if="item.comments.length > 0")
        li(v-for="(comment,index) in item.comments" :key="index")
          template(v-if="index > 0")
            v-tooltip(bottom)
              template(v-slot:activator="{on, attrs}")
                v-icon.mr-2(v-bind="attrs" v-on="on" :size="20") message
              span() {{comment}}

</template>

<script>
import { searchMixin } from "@/mixin/searchListMixin";
import VehicleColumnByIdTextOnly from "@/modules/global/components/table/VehicleColumnByIdTextOnly";
import moment from "moment";
export default {
  components:{
    VehicleColumnByIdTextOnly,
  },
  props: {
    search: String,
    status: {
      type: String,
      default: "open"
    }
  },
  mixins: [searchMixin],
  data:()=>({
    loading: false,
    selected: [],
    headers: [
      { text: "Submitted On", value: "created_at", align: "left" },
        { text: "Vehicle", value: "vehicle_id", align: "left" },
        { text: "Failed Items", value: "failed_items", align: "left" },
        { text: "Comments", value: "comments", align: "left" },
        { text: "Stage", value: "stage", align: "left" },
    ]
  }),

  computed:{
    getFilteredList: function(){
      return this.$store.getters["inspection/getFailures"];
    },
  },
  methods:{
    inspectionModuleFilter: function(value, search, item) {
      let isFound = false;
      let patt = new RegExp(search.toLowerCase(), "i");
      // Search the Object
      isFound = this.searchObject(item, patt, isFound);

      // Search Vehicle
      isFound = this.searchObject(item.vehicle, patt, isFound);

      // Search Tags
      isFound = this.searchList(item.tags, patt, isFound);

      // Search Reported_By
      isFound = this.searchObject(item.reported_by, patt, isFound);

      // Search Assigned To
      isFound = this.searchObject(item.assigned_to, patt, isFound);

      return isFound;
    },

    formatTime(value){
      return moment(value).format("ddd, Do MMM YYYY");
    }
  }
}
</script>

<style scoped>

</style>