<template lang="pug">
  .d-flex.align-center
    //-.statusCircle(:style="{'background-color':'green'}")
    div.statusCircle(:style="{'background-color': getColor, height: `${radius}px`,  width: `${radius}px`}")
    .caption.pl-1() {{status}}

</template>
<script>
export default {
  props: {
    status: String,
    radius: {
      type: Number,
      default: 9
    }
  },
  data: () => ({}),
  mounted() {
    // console.log("status color")
    // console.log(this.$vuetify.theme.currentTheme.success);
  },
  computed: {
    getColor: function () {
      let color;
      switch (this.status) {
        case "online":
          color = "#32CD32";
          break;
        case "active":
          color = this.$vuetify.theme.currentTheme.success;
          break;
        case "info":
          color = this.$vuetify.theme.currentTheme.info;
          break;
        case "warning":
          color = this.$vuetify.theme.currentTheme.warning;
          break;
        case "error":
          color = this.$vuetify.theme.currentTheme.error;
          break;
        default:
          color = "grey";
      }
      return color;
    }
  }
};
</script>
<style lang="stylus" scoped>
.circle
  border-radius 50%
  margin-right 5px;
  margin-top 2px;

.statusCircle {
  margin-top 2px;
  border-radius 50%
}
</style>
