import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const ITEM_API = "/item";

// ----------------------- STATE ------------------------- //
const state = {
    item_data: [],
    currentIndex: -1,
    selectedItems: []
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getItem: state => getField(state.item_data[state.currentIndex]),

    getCurrentItem: state => {
        return state.item_data[state.currentIndex];
    },

    getItemById: state => theID => {
        return state.item_data.find(function (element) {
            return element.id == theID;
        });
    },
    getFilterByGroup: (state) => (id) => {
        if (id != null) {
            return state.item_data.filter(item => item.group_id == id)
        } else {
            return state.item_data
        }
    },

    getSelectedItems: (state) => state.selectedItems,
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateItem(state, payload) {
        console.log(payload);
        updateField(state.item_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.item_data.findIndex(item => item.id == theID);
    },

    loadAllItems(state, payload) {
        state.item_data = payload.data;
        state.currentIndex = 0;
    },

    setSelectedItems(state, payload) {
        state.selectedItems = payload;
    },

    addItem(state, payload) {
        payload.data.itemDetail = {aisle: null, row: null, bin: null};
        state.item_data.push(payload.data);
        state.currentIndex = state.item_data.length - 1;
    },

    saveItem(state, payload) {
        state.item_data[state.currentIndex] = payload.data;
    },

    fetchItem(state, payload) {
        if (state.currentIndex == -1) {
            state.item_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.item_data[state.currentIndex] = payload.data;
        }
    },

    deleteItem(state, payload) {
        state.currentIndex = state.item_data.findIndex(
            item => item.id == payload.data.id
        );
        state.item_data.splice(state.currentIndex, 1);
    },
    reset(state) {
        state.item_data = []
        state.currentIndex = -1
    },

    deleteImage(state, payload) {
        let index = state.item_data[state.currentIndex].photos_id.findIndex(
            item => item === payload
        );
        state.item_data[state.currentIndex].photos_id.splice(index, 1);
    },

    deleteDocument(state, payload) {
        let index = state.item_data[state.currentIndex].documents_id.findIndex(
            item => item === payload
        );
        state.item_data[state.currentIndex].documents_id.splice(index, 1);
    },

};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllItems({commit}) {
        try {
            commit("loadAllItems", await Repository.get(`${ITEM_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addItem({commit}) {
        await Repository.get(`${ITEM_API}/new`)
            .then(response => {
                commit("addItem", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveItem({state, commit}) {
        try {
            let theItem = state.item_data[state.currentIndex];
            theItem.unitCost = parseFloat(theItem.unitCost);
            theItem.restockPoint = parseInt(theItem.restockPoint);

            commit("saveItem", await Repository.post(`${ITEM_API}`, theItem));
        } catch (error) {
            console.log(error);
        }
    },

    async fetchItem({commit}, payloadID) {
        try {
            commit("fetchItem", await Repository.get(`${ITEM_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteItem({commit}, payloadID) {
        try {
            commit("deleteItem", await Repository.delete(`${ITEM_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async searchItems({commit}, searchTerm) {
        try {
            commit(
                "loadAllItems",
                await Repository.get(`${ITEM_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
