import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";
import moment from "moment";

const TRIP_API = "/trip";
// ----------------------- STATE ------------------------- //
const state = {

    timeline_bucket_data: [],
    timeline_max_distance:0,
    avl_data:[],
    currentIndex: -1,
    isLoading: false,
    currentVehicleID: null,
    dateTimelineRange: { start: "2020-01-23 12:00 am", end: "2020-01-27" },
    percentage:0,
    avlRecord:null,
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getBucket: state => getField(state.timeline_bucket_data[state.currentIndex]),
    getDateTimelineRange: state => {
        return state.dateTimelineRange;
    },

    getCurrentTimeline: state => {
        if (state.timeline_bucket_data.length == 0) return null;
        return state.timeline_bucket_data[state.currentIndex];
    },

    getAvlTimeline: state => {
        let result = [];

        if(state.avl_data.length < 2){
            return result;
        }

        for(var i=0; i<200; i++){
            result.push(state.avl_data[Math.round((i/200.0)*state.avl_data.length)])
        }

        return result;
        //return state.avl_data;
    }

}

// ------------------------ MUTATIONS -------------------------- //
const mutations = {
    updateBucket(state, payload) {
        updateField(state.timeline_bucket_data[state.currentIndex], payload);
    },

    setDateTimelineRange(state,payload) {
        state.isLoading = true;
        state.dateTimelineRange = payload
    },

    setCurrentIndex(state, theIndex) {
        state.isLoading = true;
        state.currentIndex = theIndex;
    },

    setCurrentVehicleID(state, payload) {
        state.currentVehicleID = payload;
    },

    loadTimelineBuckets(state, payload) {
        state.timeline_bucket_data = payload.data;
        state.timeline_max_distance = Math.max(...state.timeline_bucket_data.map(o => o.distance))
    },

    loadTimelineAvl(state,payload) {
        state.avl_data = payload.data;
        if(state.avl_data.length > 1){
            state.avlRecord =  state.avl_data[0]
            state.percentage = 0
        }
        state.isLoading = false;
    },


    loadSectionTimelineAvl(state,payload) {
        state.avl_data = payload.data;
        if(state.avl_data.length > 1){
          state.avlRecord =  state.avl_data[0]
          state.percentage = 0
        }

        state.isLoading = false;
    },


    loading(state,payload){
        state.isLoading = payload
    },

    setPercentage(state,payload){
        state.percentage = payload.percentage
        state.avlRecord = payload.avlRecord

        // Select the current AvlBucket Trip based on the position of the pointer
        if(state.timeline_bucket_data.length > 1){
            for(var i=0; i<state.timeline_bucket_data.length; i++ ){
                if(state.avlRecord.t < state.timeline_bucket_data[i].min_time ){
                    // console.log(`${i} == ${state.timeline_bucket_data.length-1}`)
                    if(i == state.timeline_bucket_data.length-1 && state.avlRecord.t > state.timeline_bucket_data[state.timeline_bucket_data.length-2].max_time){
                        state.currentIndex = state.timeline_bucket_data.length-1;
                    }else{
                        state.currentIndex = i-1;
                    }

                    break;
                }
            }
        }


    },




    reset(state){
        state.avl_data = []
        state.timeline_bucket_data = []
        state.currentIndex= -1
        state.isLoading= false
        state.currentVehicleID= null
        state.dateTimelineRange =  { start: "2020-01-23 12:00 am", end: "2020-01-27" }
        state.percentage = 0
        state.avlRecord = null
    }
}

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadTimelineBuckets({state,commit}) {

        try {
            let fromUTC = moment(
                state.dateTimelineRange.start,
                "YYYY-MM-DD h:mm a"
            ).toISOString();

            let toUTC;

            if (state.dateTimelineRange.end == null) {
                toUTC = moment(state.dateTimelineRange.start, "YYYY-MM-DD h:mm a")
                    .add(23, "hours")
                    .add(59, "minutes")
                    .toISOString();
            } else {
                toUTC = moment(state.dateTimelineRange.end, "YYYY-MM-DD h:mm a").toISOString();
            }

            console.log(`trips.js>> LOADING TIMELINE TRIPS........ fromUTC: ${fromUTC} toUTC: ${toUTC}`)
            await commit(
                "loadTimelineBuckets",
                await Repository.get(
                    `${TRIP_API}/vehicleTimelineIgnition?vehicle_id=${state.currentVehicleID}&from=${fromUTC}&to=${toUTC}`
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async loadTimelineAvl({state,commit}) {

        try {
            let fromUTC = moment(
                state.dateTimelineRange.start,
                "YYYY-MM-DD h:mm a"
            ).toISOString();

            let toUTC;

            if (state.dateTimelineRange.end == null) {
                toUTC = moment(state.dateTimelineRange.start, "YYYY-MM-DD h:mm a")
                    .add(23, "hours")
                    .add(59, "minutes")
                    .toISOString();
            } else {
                toUTC = moment(state.dateTimelineRange.end, "YYYY-MM-DD h:mm a").toISOString();
            }

            console.log(`trips.js>> LOADING TIMELINE TRIPS........ fromUTC: ${fromUTC} toUTC: ${toUTC}`)
            await commit(
                "loadTimelineAvl",
                await Repository.get(
                    `${TRIP_API}/vehicleTimelineAvl?vehicle_id=${state.currentVehicleID}&from=${fromUTC}&to=${toUTC}`
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async loadSectionTimelineAvl({state,commit}){
        try{

            let item = state.timeline_bucket_data[state.currentIndex];
            let fromUTC = moment(item.min_time).toISOString()
            let toUTC = moment(item.max_time).toISOString()
            if(item.max_time == null){
                toUTC = moment().toISOString()
            }

            await commit("loadSectionTimelineAvl",
                 await Repository.get(`${TRIP_API}/vehicleTimelineAvl?vehicle_id=${state.currentVehicleID}&from=${fromUTC}&to=${toUTC}`))
        }catch(error){
            console.error(error)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};