<template lang="pug">
  #blankcontent()
    .panelTitle()
      .d-flex()
        | Place Visited Today
        .span(v-if="this.items.length > 0" v-html="`&nbsp;&nbsp; (${this.items.length})`")
      template(v-if="isLoaded")
        v-icon.refreshBtn( @click="onRefresh") refresh
      template(v-else)
        v-progress-circular(color="primary" indeterminate :size="20" :width="2")
    v-divider.mx-2()
    .panelContent()
      v-list(v-if="isLoaded && $store.state.profile.userInfo")
        v-list-item-group(v-model="selected" active-class="pink--text")
          template(v-for="(item,index) in $store.state.place.placeSummary")
            v-list-item(:key="item.title")
              template(v-slot:default="{ active }")
                v-list-item-content()
                  v-list-item-title(v-text="item.place.name")
                  v-list-item-subtitle(v-html="`${getVehicleName(item.vehicle_id)} &mdash; ${formatStartDate(item.start_time)}`")
                v-list-item-action()
                  v-list-item-action-text(v-html="getDuration(item.duration)")
            v-divider(v-if="index < item.length -1" :key="index")


</template>
<script>
import  Repository from "@/repository" ;
import moment from "moment";

export default {
  props: {
    clientHeight: Number
  },
  mounted() {
    this.init();
  },

  methods:{
    init:function(){
      if (this.$store.state.vehicles.currentIndex == -1) {
        this.$store.dispatch("vehicles/loadAllItems").then(() => {
          console.log("PRINT HELLO")
          // load the Summary after loading all the vehicles
          this.loadPlaceSummery();
        });
      }else{
        this.loadPlaceSummery();
      }
    },

    loadPlaceSummery:function(){
      if(this.$store.getters["place/getPlaceSummary"].length == 0){
        Repository.get("/place/placeSummary").then(response => {
          console.log(response.data)
          this.items = response.data
          this.items.sort( (a,b) => {
            let aStart = new Date(a.start_time).getTime()
            let bStart = new Date(b.start_time).getTime()
            return aStart-bStart;
          })
          this.items.reverse();
          this.$store.commit("place/placeSummary",this.items);
          this.isLoaded = true;
        })
      }else{
        this.isLoaded = true;
      }

    },

    onRefresh:function(){

      this.isLoaded = false;
      this.$store.commit("place/placeSummary",[])
      this.init();
    },

    formatStartDate:function(value){
      return moment(value).format("h:mm a")
    },

    getDuration:function(value){
      let theDuration = moment
          .utc(
              moment
                  .duration(value / 1000000000, "seconds")
                  .asMilliseconds()
          )


       if(theDuration.hours() > 0){
        return theDuration.format("[<b>]H[</b>] [Hrs] [<b>]mm[</b>] [min]")
      }else{
        return theDuration.format("[<b>]mm[</b>] [min]")
      }

    },

    getVehicleName:function(vehicleID){

      return this.$store.getters["vehicles/getVehicleByID"](vehicleID).name;
    }

  },

  data: () => ({
    selected: [2],
    items: [],
    isLoaded:false,
  })
};
</script>
<style scoped lang="stylus">
.lineVehicleStatus {
  border-bottom: 1px solid #f9f9f9;
  height: 50px;
}

#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 30px;
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.panelContent {
  height: calc(100% - 45px);
  overflow: auto;
}

.refreshBtn{
  cursor pointer;
}
</style>
