<template lang="pug">
  v-card()
    .d-flex.pa-3()
      image-thumbnail.mr-2(:width='60', :height='60', :src="item.posted_by.thumbnail_id")
      .d-flex.d-flex.justify-space-between.mx-3(style="width:100%")
        .d-flex-column.body-2()
          .primary--text() {{item.posted_by.first_name}} {{item.posted_by.last_name}}
          .body-1.font-weight-bold() {{item.title}}
          div() {{item.comment}}
        .d-flex-column()
          .d-flex.align-end.justify-end()
            v-btn(fab text small @click="$emit('edit',item)")
              v-icon(small color="primary" :size="18") edit
            v-btn.ml-2(fab text small @click="$emit('delete',item)")
              v-icon(fabsmall color="primary" :size="18") delete
          .d-flex.align-end.justify-end(style="min-width:130px; height:calc(100% - 40px)")
            timeago.caption.grey--text(:datetime="item.date_time" style="text-align:right")
  
</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components: {
    ImageThumbnail
  },
  props: {
    item: Object
  },
  data: () => ({}),
  methods: {
    titleStyle: function() {
      let styleObject = {};
      switch (this.item.status) {
        case "open":
          styleObject.color = this.$vuetify.theme.primary;
          break;
        case "resolved":
          styleObject.color = this.$vuetify.theme.info;
          break;
        case "overdue":
          styleObject.color = this.$vuetify.theme.error;
          break;
      }

      return styleObject;
    },

    onDelete: function() {
      console.log("onDelete");
    },

    onEdit: function() {
      console.log("onEdit");
    }
  }
};
</script>
<style scoped lang="stylus">
.smallLetters {
  font-size: 14px;
}

.ItemComment {
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  width: 100%;
}

.nameText {
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
}

.btnStyle:hover {
  border-bottom: 1px dotted;
  cursor: pointer;
}
</style>
