<template lang="pug">
  div(v-if="$store.state.reminders.vehicle_renewal_type.currentIndex > -1")
    .btnArrangement
      v-btn.mb-2(small color='primary' outlined @click.prevent="cancel") Cancel
      v-btn.mb-2(small color='primary' @click.prevent="save") Save
    form-sub-header.white Edit Vehicle Renewal Type
    v-container.white(grid-list-xs)
      v-layout(row wrap)
        v-flex(xs12)
          v-text-field(v-model="name" hint="Vehicle Renwal Type" :persistent-hint="true" label="Vehicle Renewal type" required)
        v-flex(xs12)
          v-textarea(v-model="description" hint="Vehicle DRenewal Description" :persistent-hint="true" label="Vehicle Renewal Description")
        

</template>
<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "reminders/vehicle_renewal_type/getVehicle_renewal_type",
  mutationType: "reminders/vehicle_renewal_type/updateVehicle_renewal_type"
});

export default {
  components: {
    FormSubHeader
  },
  data: () => ({}),
  computed: {
    ...mapFields({
      name: "name",
      description: "description"
    })
  },

  methods: {
    save: function() {
      if (this.name.length > 0) {
        this.$store
          .dispatch("reminders/vehicle_renewal_type/saveVehicle_renewal_type")
          .then(() => {
            this.$router.push({ name: "vehicleRenewalTypes" }).catch(()=>{});
          });
      }
    },

    cancel: function() {
      this.$router.push({ name: "vehicleRenewalTypes" }).catch(()=>{});
    }
  },

  mounted() {
    if (this.$store.state.reminders.vehicle_renewal_type.currentIndex == -1) {
      // Load
      this.$store.dispatch(
        "reminders/vehicle_renewal_type/fetchVehicle_renewal_type",
        this.$route.params.vehicleRenewalTypeID
      );
    }
  }
};
</script>
<style scoped lang="stylus"></style>
