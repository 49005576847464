<template lang="pug">
  .OverviewSideBar 
    v-btn.ma-3(small color="primary" style="width:90%") 
      v-icon.mr-2(small) add
      | Quick Add
    overview-side-bar-item.menuSpacing(v-for="item in overviewItems1" :key="item.url"   :label="item.label" :icon="item.icon" :value="item.value" :url="item.url" @onclick="onAction")
    v-divider.mb-3.mt-3
    overview-side-bar-item.menuSpacing(v-for="item in overviewItems2" :key="item.url"   :label="item.label" :icon="item.icon" :value="item.value" :url="item.url" @onclick="onAction")
    v-divider.mb-3.mt-3
    overview-side-bar-item.menuSpacing(v-for="item in overviewItems3" :key="item.url"   :label="item.label" :icon="item.icon" :value="item.value" :url="item.url" @onclick="onAction")
</template>
<script>
import OverviewSideBarItem from "@/modules/global/components/others/OverviewSideBarItem";
export default {
  components: {
    OverviewSideBarItem
  },
  computed: {
    currentContact: {
      get: function() {
        return this.$store.getters["contacts/getCurrentContact"];
      }
    },
    overviewItems1: function() {
      let obj = [];
      obj.push({
        label: "Contact Overview",
        icon: "person",
        url: "contactDashboard"
      });
      obj.push({
        label: "Photos",
        icon: "image",
        value: this.currentContact.images
          ? this.currentContact.images.length
          : 0,
        url: "contactPhotos"
      });
      obj.push({
        label: "Documents",
        icon: "insert_drive_file",
        value: this.currentContact.documents
          ? this.currentContact.documents.length
          : 0,
        url: "contactDocuments"
      });
      obj.push({
        label: "Comments",
        icon: "comment",
        value: this.$store.state.comments.comment_data.length,
        url: "contactComments"
      });
      return obj;
    },
    overviewItems2: function() {
      let obj = [];
      obj.push({
        label: "Inspections",
        icon: "check_box",
        value: 1,
        url: "contactInspection"
      });
      obj.push({
        label: "Issues",
        icon: "warning",
        value: 1,
        url: "contactIssues"
      });
      obj.push({
        label: "Renewal Reminders",
        icon: "notifications",
        value: 0,
        url: "contactRenewal"
      });
      obj.push({
        label: "Assignment_History",
        icon: "history",
        value: 0,
        url: "contactAssignment"
      });
      obj.push({
        label: "Location History",
        icon: "location_on",
        value: 0,
        url: "contactLocation"
      });

      return obj;
    },
    overviewItems3: function() {
      let obj = [];
      obj.push({
        label: "Notification Settings",
        icon: "settings",
        value: null,
        url: "contactNotification"
      });
      obj.push({
        label: "User Management",
        icon: "person",
        value: null,
        url: "contactUserInfo"
      });
      return obj;
    }
  },
  data: () => ({}),

  methods: {
    onAction: function(url) {
      this.$router.push({
        name: url,
        params: { contactID: this.$route.params.contactID }
      }).catch(()=>{});
    }
  }
};
</script>
<style scoped lang="stylus">
.OverviewSideBar {
  width: 250px;
  height: 100%;
  background-color: #F5F5F5;
}

.menuSpacing {
  margin-bottom: 8px;
}
</style>
