<template lang="pug">
div
  .grey.lighten-3.px-2.py-2
    .d-flex.justify-space-between(style="width:100%")
      div(style="width:150px;") Total Distance in Km
      div(style="width:130px")
        v-select.font-weight-regular(v-model="interval" :items="intervalItems" item-value="value" item-text="label" hide-details height="20" style="margin-top:-10px; width:130px" @change="onChange" )


  v-container()
    bar-chart(v-if="loaded" :style="{height:`300px`, width: `100%`}" :chartdata="getDataSets" :options="chartOption")

</template>
<script>
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";
import ContactColumn from "@/modules/global/components/table/ContactColumn";
import BarChart from "@/modules/global/components/charts/BarChart";
import axios from "axios";
import moment from "moment";


export default {
  components: {
    PanelTitleHeader,
    ContactColumn,
    BarChart,

  },

  data: () => ({
    interval: "6 days",
    intervalItems: [
      {label:"Last 7 Days", value: "6 days"},
      {label:"Last 14 Days", value: "13 days"},
    ],
    xData: [],
    yData: [],
    loaded: false,
    chartOption: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              maxTicksLimit: 5,
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ]
      },

      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 20,
          bottom: 0
        }
      }
    }
  }),

  computed: {
    getCurrentVehicle() {
      return this.$store.getters["vehicles/getCurrentItem"];
    },
    getDataSets: function() {
      let obj = {
        labels: this.xData,
        datasets: [
          {
            label: "Day",
            data: this.yData
            // borderWidth: 2
          }
        ]
      };
      obj.datasets[0].backgroundColor = this.$vuetify.theme.themes.light.primary;
      // obj.datasets[0].borderColor = this.$vuetify.theme.themes.light.primary;
      return obj;
    }

  },

  methods: {
    loadData() {
      this.xData = [];
      this.yData = [];
      console.log(this.$route.params.vehicleID)
      axios.post(`/api/dashboard/dailyAccountDistances/${this.$route.params.vehicleID}`, {interval: this.interval}).then(response => {
        console.log("THERE IS A RESPONSE")
        console.log(response.data)
        this.yData = [];
        response.data.forEach( element => {
          if(this.interval === '6 days'){
            this.xData.push(moment(element.the_date).format("ddd"))
          }else{
            this.xData.push(moment(element.the_date).format("DD MMM"))
          }

          this.yData.push(element.the_value);
        })
        this.loaded  = true;
      });

    },

    onChange:function(){
      this.loaded = false;
      this.loadData();
    }
  },



  mounted() {
    this.loaded = false;
    this.loadData();
  }
};
</script>
<style scoped lang="stylus">
.PairItems {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}
.panelTitle {
  font-size: 14px;
  font-weight bold
  padding: 5px 30px;
  height:30px;
  display: flex;
  width: 100%
  justify-content: space-between;
}

</style>
