<template lang="pug">
  .page 
    contact-overview-header
    .mainBody
      contact-overview-side-bar
      <!-- ROUTER -->
      <router-view class="contentBackground"></router-view>
</template>
<script>
import ContactOverviewHeader from "@/modules/contacts/components/ui/overview/ContactOverviewHeader";
import ContactOverviewSideBar from "@/modules/contacts/layouts/overviews/ContactOverviewSideBar";

export default {
  components: {
    ContactOverviewHeader,
    ContactOverviewSideBar
  },
  data: () => ({}),

  mounted() {
    // fetchContact
    console.log(`current Index ${this.$store.state.contacts.currentIndex}`);
    console.log(`contactID: ${this.$route.params.contactID}`);

    // load comments
    this.$store
      .dispatch(
        "comments/fetchCommentListByReference",
        this.$route.params.contactID
      )
      .then(() => {
        console.log("contact overview comments loaded");
      });

    // loadIssues
    this.$store
      .dispatch("issues/loadContactIssues", this.$route.params.contactID)
      .then(() => {
        console.log("overview Contact issues");
      });
  }
};
</script>
<style scoped lang="stylus">
.mainBody {
  border-top: 1px solid #CCCCCC;
  margin-top: 4px;
  padding-top: 8px;
  display: flex;
}

.contentBackground {
  width: 100%;
}

.page {
  background-color: #F5F5F5;
}
</style>
