import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import Repository from "@/repository";
import VueTimeago from "vue-timeago";
import UUID from "vue-uuid";
import "vuetify/dist/vuetify.min.css"; // Ensure you are using css-loader
import VueLodash from "vue-lodash";
import lodash from "lodash";
import vuetify from "@/plugins/vuetify";
import VueSignaturePad from "vue-signature-pad";

Vue.use(VueSignaturePad);
Vue.use(VueLodash, {lodash: lodash});

Vue.use(require("vue-moment"));
Vue.use(VueTimeago, {
    name: "Timeago",
    locale: "en"
});
Vue.use(UUID);

Vue.prototype.$http = Repository;

Vue.prototype.$media_path = "/media";

Vue.config.productionTip = false;
// const debug = process.env.NODE_ENV !== "development";


new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount("#app");
