<template lang="pug">
  #PanelMapContainer
    panel-title-header(:label="title")
    div()
    #mapPanel
    v-btn.zoomExtends(fab color="primary" @click="zoomExtends")
      v-icon() center_focus_weak

</template>

<script>
import mapboxgl from "mapbox-gl";
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";

export default {
  props: {
    coordinates: {
      type: Object,
      default: function () {
        return {lat: -1.28113150214347, lon: 36.818166813140046}
      }
    },
    vehicleCoordinates: {
      type: Object,
      default: function () {
        return null
      }
    },
    title:{
      type:String,
      default: "Location"
    }
  },
  components: {
    PanelTitleHeader,
  },

  watch:{
    coordinates:function(newValue) {

      if (newValue != null && this.mapPanel != null) {
        this.markerPanel.setLngLat([newValue.lon, newValue.lat]);
        this.mapPanel.flyTo({
          center:[newValue.lon, newValue.lat],
          essential: true
        })
      }
    }
  },

  methods: {
    createMapPanel: function () {
      mapboxgl.accessToken =
          "pk.eyJ1IjoibXVnYW1iaWtpbWF0aGkiLCJhIjoiY2p0OWNjaGZxMDdsdjQ0cXZjZTRhZnA0aSJ9.3b3qa7uHHInxWL3TBiMsSQ";
      this.mapPanel = new mapboxgl.Map({
        container: "mapPanel",
        style: "mapbox://styles/mapbox/streets-v10",
        minzoom: 1.3,
        center: [this.coordinates.lon, this.coordinates.lat], // Nairobi
        zoom: 16
      });

      this.markerPanel = new mapboxgl.Marker({
        color: this.$vuetify.theme.themes.light.primary,
        draggable: false
      })

      this.markerPanel.setLngLat([this.coordinates.lon, this.coordinates.lat]);
      this.markerPanel.addTo(this.mapPanel);

      if(this.vehicleCoordinates != null){
        //Marker for Vehicle
        let el = document.createElement("div");
        el.className = "myMarker";
        el.setAttribute(
            "style",
            `background-image:url("/static/web/markers/marker_green_translucent.png")`
        );

        this.vehicleMarker = new mapboxgl.Marker(el);
        this.vehiclePin = new mapboxgl.Marker({
          color: this.$vuetify.theme.themes.light.accent,
          draggable: false
        })

        this.vehiclePin.setLngLat([ this.vehicleCoordinates.lon,this.vehicleCoordinates.lat]);
        this.vehiclePin.addTo(this.mapPanel);
      }


    },


    zoomExtends:function(){
      this.mapPanel.flyTo({
        center:[this.coordinates.lon, this.coordinates.lat],
        essential: true
      })
    }

  },
  mounted() {
    this.mapPanel = null;
    this.mapPanelLoaded = false;

    this.$nextTick(() => {
      this.createMapPanel();
    })
  },


}
</script>

<style scoped lang="stylus">
#mapPanel {
  width: 100%;
  height: 350px;
}

#PanelMapContainer {
  position relative
}
.zoomExtends{
  position absolute;
  top 60px;
  right 20px;
}
</style>