<template>
  <div class="upload" fluid row wrap align-center>
    <v-progress-linear
      v-show="progressVisible"
      v-model="percentageComplete"
    ></v-progress-linear>
    <input
      hidden
      type="file"
      id="file"
      ref="file"
      accept="image/*, *.jpg, *.png"
      @change="handleFileupload()"
    />
    <v-btn @click="selectFile()">Select Image</v-btn>
    <!--
      <v-chip v-for="(item) in photoArray" :key="item.UUID" close @input="onDeleteHandler(item)">{{item.name}}</v-chip>
    -->

    <upload-thumbnail-item
      v-for="i in photoArray"
      :key="i.UUID"
      :data="i"
      @onDelete="onDeleteHandler(i)"
      >{{ i.name }}</upload-thumbnail-item
    >
  </div>
</template>

<script>
import UploadThumbnailItem from "@/modules/global/components/forms/UploadThumbnailItem";
export default {
  components: {
    UploadThumbnailItem
  },

  props: {
    photoArray: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    // items: [
    // {
    //   ID: 8,
    //   name: "Mercedes Benz.jpg",
    //   thumbnail: "honda_crv.jpg",
    //   UUID: "0123-1234-1231-1557"
    // },
    // {
    //   ID: 9,
    //   name: "Noah.jpg",
    //   thumbnail: "car.jpg",
    //   UUID: "0123-1234-1231-1557"
    // }
    // ],
    file: "",
    progressVisible: false,
    percentageComplete: 50
  }),

  methods: {
    onDeleteHandler: function(item) {
      console.log(item.ID);
      this.$http
        .delete("/api/upload/" + item.ID)
        .then(payload => {
          console.log(payload);
          console.log("success deleted");
          //this.items.splice(index,1)
          var index = this.photoArray.findIndex(obj => {
            obj.ID == item.ID;
          });
          this.photoArray.splice(index, 1);
        })
        .catch(payload => {
          console.log(payload);
          console.log("failure to delete");
        });
    },

    handleFileupload: function() {
      console.log("Handle File");
      this.file = this.$refs.file.files[0];

      this.submitFile();
    },

    selectFile: function() {
      this.$refs.file.click();
    },

    resetUpload: function() {
      this.file = "";
      this.progressVisible = false;
    },

    progress: function(progressEvent) {
      this.percentageComplete = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },

    submitFile: function() {
      let formData = new FormData();
      formData.append("file", this.file);

      this.progressVisible = true;

      this.$http
        .post("/api/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: this.progress
        })
        .then(payload => {
          console.log("success");
          console.log(payload.data);
          this.photoArray.unshift(payload.data);
          this.$emit("imageItems", this.photoArray);
          this.resetUpload();
        })
        .catch(payload => {
          console.log("failure!!");
          console.log(payload);
          this.resetUpload();
        });
    }
  }
};
</script>

<style scoped>
.upload {
  border: 4px dashed #e8e8e8;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}
</style>
