import { render, staticRenderFns } from "./CustomerColumnById.vue?vue&type=template&id=95ab7424&scoped=true&lang=pug"
import script from "./CustomerColumnById.vue?vue&type=script&lang=js"
export * from "./CustomerColumnById.vue?vue&type=script&lang=js"
import style0 from "./CustomerColumnById.vue?vue&type=style&index=0&id=95ab7424&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95ab7424",
  null
  
)

export default component.exports