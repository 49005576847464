import ServiceReminderForm from "@/modules/reminders/components/forms/ServiceReminderForm";
import VehicleRenewalForm from "@/modules/reminders/components/forms/VehicleRenewalForm";
import ContactRenewalForm from "@/modules/reminders/components/forms/ContactRenewalForm";

export const REMINDER_ROUTES = [
  {
    path: "serviceReminderForm",
    name: "serviceReminderForm",
    component: ServiceReminderForm
  },
  {
    path: "vehicleRenewalForm",
    name: "vehicleRenewalForm",
    component: VehicleRenewalForm
  },

  {
    path: "contactRenewalForm",
    name: "contactRenewalForm",
    component: ContactRenewalForm
  }
];
