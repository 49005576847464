<template>
  <v-textarea
    v-model="computedValue"
    :label="schema.label"
    :hint="schema.hint"
    :persistent-hint="schema.hint != null"
    :counter="schema.maxlength != null"
    :maxlength="schema.maxlength"
    :rules="[requiredRule]"
    :disabled="$store.state.inspection.formDesigner.disableAllComponents"
    :auto-grow="schema.auto_grow"
  />
</template>
<script>
import SETTINGS from "@/modules/inspections/store/formSettings";

export default {
  props: {
    schema: Object
  },
  data: () => ({}),
  computed: {
    requiredRule: function() {
      return this.schema.required ? value => !!value || "Required." : false;
    },

    computedValue: {
      get: function() {
        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id);
      },

      set: function(theValue) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }
  },

  mounted() {
    // console.log(`schema is: ${this.schema}`)
    let theValue = this.computedValue;

    if (theValue == null) {
      if (this.schema.default != null) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: this.schema.default
        });
      }
    }
  }
};
</script>
<style scoped lang="stylus"></style>
