<template lang="pug">
  .d-flex.justify-space-between.boardInput()

    input(v-model.trim='input' :placeholder='placeholder' @keydown.enter='onEnter')
    div(v-if='icon')
      v-btn(color="primary" type="submit" @click='onClick' :disabled='input.length === 0')
        v-icon {{icon}}




</template>

<script>

/**
 * @emits {id, text, more}   enter
 */
export default {
  props: {
    listId: [String, Number],
    placeholder: String,
    icon: {
      type: String,
      default: 'navigate_next'
    }
  },

  data () {
    return {
      input: ''
    }
  },

  methods: {
    onEnter () {
      this.emit()
    },

    onClick () {
      this.emit()
    },

    emit () {
      if (this.input) {
        this.$emit('enter', {
          id: this.listId,
          text: this.input,
        })
        this.input = ''
      }
    }
  }
}

</script>

<style lang="stylus">
.boardInput{
  margin 0px;
  border 1px solid #dbdbdb;
  border-radius 5px;
  padding-left 10px;
}
</style>
