<template lang="pug">
  .editFieldButtons
    field-component-button(:item="{icon:'edit'}" @click="onClick")
    field-component-button(:item="{icon:'file_copy'}" @click="onClick" )
    field-component-button(:item="{icon:'delete' }"  @click="onClick")


</template>
<script>
import fieldComponentButton from "@/modules/inspections/components/formDesigner/component/FieldComponentButton";
export default {
  components: {
    fieldComponentButton
  },

  data: () => ({}),

  methods: {
    onClick: function(value) {
      this.$emit("onClick", value);
    }
  }
};
</script>
<style scoped lang="stylus">
.editFieldButtons{
  height 30px;
  display flex;
  border-radius 3px;
}
</style>
