<template lang="pug">
  div(:style="getStyle"  @mouseover.prevent="menuVisible=true" @mouseleave.prevent="menuVisible=false")
    field-component-menu(v-show="menuVisible && !$store.state.inspection.formDesigner.disableAllComponents" style="float:right;" @onClick="onClick")
    .rowContainer
      .dragHolder()
        v-icon(:size="20" color="white" style="margin:0px; padding:0px") unfold_more
      component( :is="getComponent(item.name)" v-bind="{schema:item}")



</template>
<script>
import FieldComponentMenu from "@/modules/inspections/components/formDesigner/component/FieldComponentMenu";
import TextFieldRenderer from "@/modules/inspections/components/formDesigner/render/TextFieldRenderer";
import CheckboxRenderer from "@/modules/inspections/components/formDesigner/render/CheckboxRenderer";
import HeaderRenderer from "@/modules/inspections/components/formDesigner/render/HeaderRenderer";
import TextAreaRenderer from "@/modules/inspections/components/formDesigner/render/TextAreaRenderer";
import DateRenderer from "@/modules/inspections/components/formDesigner/render/DateRenderer";
import TimeRenderer from "@/modules/inspections/components/formDesigner/render/TimeRenderer";
import BlankRenderer from "@/modules/inspections/components/formDesigner/render/BlankRenderer";
import RadioRenderer from "@/modules/inspections/components/formDesigner/render/RadioRenderer";
import SelectRenderer from "@/modules/inspections/components/formDesigner/render/SelectRenderer";
import TableRenderer from "@/modules/inspections/components/formDesigner/render/TableRenderer";
import AttachRenderer from "@/modules/inspections/components/formDesigner/render/AttachRenderer";
import ImageRenderer from "@/modules/inspections/components/formDesigner/render/ImageRenderer";
import SignatureRenderer from "@/modules/inspections/components/formDesigner/render/SignatureRenderer";
import MapRenderer from "@/modules/inspections/components/formDesigner/render/MapRenderer";
import SwitchRenderer from "@/modules/inspections/components/formDesigner/render/SwitchRenderer";
import DrawRenderer from "@/modules/inspections/components/formDesigner/render/DrawRenderer";
import DateTimeRenderer from "@/modules/inspections/components/formDesigner/render/DateTimeRenderer";
export default {
  components: {
    FieldComponentMenu,
    TextFieldRenderer,
    CheckboxRenderer,
    HeaderRenderer,
    TextAreaRenderer,
    DateRenderer,
    TimeRenderer,
    DateTimeRenderer,
    RadioRenderer,
    SelectRenderer,
    TableRenderer,
    AttachRenderer,
    ImageRenderer,
    SignatureRenderer,
    DrawRenderer,
    MapRenderer,
    SwitchRenderer,
    BlankRenderer
  },
  props: {
    item: Object
  },
  data: () => ({
    menuVisible: false
  }),

  computed: {
    getStyle: function() {
      let theStyle = {
        width: "100%",
        "border-radius": "5px",
        padding: "5px 8px",
        margin: "10px 0px 10px 0px"
      };

      if (
        this.$store.getters["inspection/formDesigner/getCurrentID"] == this.item.id
      ) {
        theStyle["border-color"] = "#CCC";
        theStyle["border-style"] = "solid";
        theStyle["border-width"] = "8px";
        theStyle["background-color"] = "#FAFAFA";
      } else {
        theStyle["border-color"] = "#CCC";
        theStyle["border-style"] = "dotted";
        theStyle["border-width"] = "1px";
        theStyle["background-color"] = "#FFF";
      }

      return theStyle;
    }
  },

  methods: {
    // call the editor
    onClick: function(value) {
      switch (value) {
        case "edit":
          this.$store.commit("inspection/formDesigner/editFormByID", this.item.id);
          break;
        case "file_copy":
          this.$store.commit("inspection/formDesigner/copyFormByID", this.item.id);
          break;
        case "delete":
          this.$store.commit(
            "inspection/formDesigner/deleteFormByID",
            this.item.id
          );
          break;
      }
    },

    getComponent: function(componentName) {
      // Ensure the Renderer is Registered by appending the String `Renderer` to the compent name
      if(componentName === 'RouteID' || componentName === 'RouteName' || componentName === 'PlaceID' || componentName === 'PlaceName'){
        return "BlankRenderer"
      }else{
        return `${componentName}Renderer`;
      }

    }
  },

  mounted() {
    // console.log(this.getComponent('ChecklistProperties'))
  }
};
</script>
<style scoped lang="stylus">
.rowContainer{
  display flex;
  align-items center;

}

.dragHolder{
  display flex
  flex-direction column
  width 20px
  height 20px;
  background-color #cccccc
  border-radius 50%
  margin-right 10px;
}

.dragHolder:hover{
  cursor grab
}
</style>
