import VehicleListReport from "@/modules/reports/components/tables/VehicleListReport";
import ContactListReport from "@/modules/reports/components/tables/ContactListReport";
import IssueReportLayout from "@/modules/reports/layouts/IssueReportLayout";
import ContactRenewalLayout from "@/modules/reports/layouts/ContactRenewalLayout";
import VehicleDetailsReport from "@/modules/reports/layouts/VehicleDetailsReport";
import OperatingCostSummary from "@/modules/reports/layouts/OperatingCostSummary";
import TotalCostTrend from "@/modules/reports/layouts/TotalCostTrend";
import UtilizationSummary from "@/modules/reports/layouts/UtilizationSummary";
import StatusChanges from "@/modules/reports/layouts/StatusChanges";
import StatusSummary from "@/modules/reports/layouts/StatusSummary";
import VehicleAssignmentLog from "@/modules/reports/layouts/VehicleAssignmentLog";
import VehicleAssignmentSummary from "@/modules/reports/layouts/VehicleAssignmentSummary";
import InspectionSubmissionList from "@/modules/reports/layouts/InspectionSubmissionList";
import InspectionSubmissionSummary from "@/modules/reports/layouts/InspectionSubmissionSummary";
import InspectionFailuresList from "@/modules/reports/layouts/InspectionFailuresList";
import ServiceSummary from "@/modules/reports/layouts/ServiceSummary";
import ServiceHistorybyVehicle from "@/modules/reports/layouts/ServiceHistorybyVehicle";
import ServiceTaskSummary from "@/modules/reports/layouts/ServiceTaskSummary";
import ServiceReminders from "@/modules/reports/layouts/ServiceReminders";
import VehiclesWithoutService from "@/modules/reports/layouts/VehiclesWithoutService";
import FuelSummary from "@/modules/reports/layouts/FuelSummary";
import FuelEntriesbyVehicle from "@/modules/reports/layouts/FuelEntriesbyVehicle";
import FuelSummarybyLocation from "@/modules/reports/layouts/FuelSummarybyLocation";
import FuelByMerchant from "@/modules/reports/layouts/FuelByMerchant"
import VehicleRenewalLayout from "@/modules/reports/layouts/VehicleRenewalLayout"
import VehicleAlertReport from "@/modules/reports/layouts/VehicleAlertReport"

export const REPORT_ROUTES = [{
    path: "vehicleListReport",
    name: "vehicleListReport",
    component: VehicleListReport,
},
{
    path: "vehicleDetailsReport",
    name: "vehicleDetailsReport",
    component: VehicleDetailsReport,
},
    {
        path: "vehiclealertReport",
        name: "vehiclealertReport",
        component: VehicleAlertReport,
    },
{
    path: "operatingCostSummary",
    name: "operatingCostSummary",
    component: OperatingCostSummary,
},
{
    path: "totalCostTrend",
    name: "totalCostTrend",
    component: TotalCostTrend,
},
{
    path: "utilizationSummary",
    name: "utilizationSummary",
    component: UtilizationSummary,
},
{
    path: "statusChanges",
    name: "statusChanges",
    component: StatusChanges,
},
{
    path: "statusSummary",
    name: "statusSummary",
    component: StatusSummary,
},
{
    path: "vehicleAssignmentLog",
    name: "vehicleAssignmentLog",
    component: VehicleAssignmentLog,
},
{
    path: "vehicleAssignmentSummary",
    name: "vehicleAssignmentSummary",
    component: VehicleAssignmentSummary,
},
{
    path: "contactListReport",
    name: "contactListReport",
    component: ContactListReport,
},
{
    path: "issueReport",
    name: "issueReport",
    component: IssueReportLayout,
},
{
    path: "contactRenewalReport",
    name: "contactRenewalReport",
    component: ContactRenewalLayout,
},
{
    path: "vehicleRenewalReport",
    name: "vehicleRenewalReport",
    component: VehicleRenewalLayout,
},
{
    path: "inspectionSubmissionList",
    name: "inspectionSubmissionList",
    component: InspectionSubmissionList,
},
{
    path: "inspectionSubmissionSummary",
    name: "inspectionSubmissionSummary",
    component: InspectionSubmissionSummary,
},
{
    path: "inspectionFailuresList",
    name: "inspectionFailuresList",
    component: InspectionFailuresList,
},
{
    path: "serviceSummary",
    name: "serviceSummary",
    component: ServiceSummary,
},

{
    path: "serviceHistorybyVehicle",
    name: "serviceHistorybyVehicle",
    component: ServiceHistorybyVehicle,
},
{
    path: "serviceTaskSummary",
    name: "serviceTaskSummary",
    component: ServiceTaskSummary,
},
{
    path: "serviceReminders",
    name: "serviceReminders",
    component: ServiceReminders,
},
{
    path: "vehiclesWithoutService",
    name: "vehiclesWithoutService",
    component: VehiclesWithoutService,
},
{
    path: "fuelSummary",
    name: "fuelSummary",
    component: FuelSummary,
},
{
    path: "fuelEntriesbyVehicle",
    name: "fuelEntriesbyVehicle",
    component: FuelEntriesbyVehicle,
},
{
    path: "fuelSummarybyMerchant",
    name: "fuelSummarybyMerchant",
    component: FuelSummarybyLocation,
},
{
    path: "fuelByMerchant",
    name: "fuelByMerchant",
    component: FuelByMerchant,
},
];