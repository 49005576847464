<template lang="pug">
  .OverviewHeader.grey.lighten-4
    v-toolbar(dense flat)
      v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
      v-spacer
      v-menu(offset-y)
        template(v-slot:activator="{ on }")
          v-btn.mx-3(v-on="on" color="primary" small outlined )
            | Actions
            v-icon.pl-1(small) arrow_drop_down
        v-list
          v-list-item(v-for="(item, index) in items2" :key="index" @click="")
            v-list-item-title {{ item.title }}
      v-btn(color="primary" small @click="onEdit")
        v-icon.pr-2(small)
        | Edit Contact

    .HeaderBody.px-3.py-2
      .HeaderInfo
        image-thumbnail.mr-3(:width="80" :height="80"  :src="currentContact.thumbnail_id" :url="'/main/contacts'")
        .HeaderText
          h2.headline.font-weight-bold.grey--text.text--darken-4 {{currentContact.first_name}} {{currentContact.last_name}}
          p.ma-0.pa-0.caption.font-weight-regular {{currentContact.email}} · {{currentContact.mobile}}
          v-divider.mt-1
          .HeaderInfoButtons
            .HorizontalAlignDiv
              v-icon.pr-2(small) group
              p.ma-0.pa-0(v-html="currentContact.group != null ? currentContact.group.name+' . ' : ''")
            .HorizontalAlignDiv
              status(:status="'active'")
            div
            .HorizontalAlignDiv(@click="onAction('assignments')")
              p.ma-0.pa-0 {{currentContact.assigned ? currentContact.assigned  : "Unassigned"}}
                v-icon.pl-1(small) add_circle


      v-spacer
      .ServiceReminders.caption
        .Reminders.grey--text.text--darken-3 Contact Reminders
        .ReminderUnits
          span.subheading.font-weight-bold 0 
          .VerticalDivider
          span.subheading.font-weight-bold 0

</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import Status from "@/modules/global/components/others/Status";

export default {
  components: {
    ImageThumbnail,
    Status
  },
  data: () => ({
    items: [
      {
        text: "Contact List",
        disabled: false,
        href: "contacts"
      },
      {
        text: "John Matendechere",
        disabled: true,
        href: "breadcrumbs_link_1"
      }
    ],
    items2: [
      {title: "Click Me"},
      {title: "Click Me"},
      {title: "Click Me"},
      {title: "Click Me 2"}
    ]
  }),

  computed: {
    currentContact: {
      get() {
        return this.$store.getters["contacts/getCurrentContact"];
      }
    }
  },

  methods: {
    onAction: function (actionType) {
      switch (actionType) {
        case "editOdometer":
          console.log("editOdometer");
          break;
        case "group":
          console.log("group");
          break;
        case "assignments":
          console.log("assignments");
          break;
        default:
          console.log(actionType);
      }
    },

    onEdit: function () {
      this.$router.push({
        name: "contactNewBasic",
        params: {contactID: this.$route.params.contactID}
      }).catch(()=>{});
    },

    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Contact List",
        disabled: false,
        to: {name: "contacts"}
      });
      theArray.push({
        text:
            this.currentContact.first_name + " " + this.currentContact.last_name,
        disabled: true
      });
      return theArray;
    }
  }
};
</script>
<style lang="stylus" scoped>
.OverviewHeader {
  width: 100%;
  height: 150px;
}

.HeaderBody {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.HeaderInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.HeaderText {
  display: flex;
  flex-direction: column;
}

.HeaderInfoButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}

.HorizontalAlignDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  // border 1px solid grey
  padding: 0px 0px 0px 6px;
  height: 30px;
  margin-right: 12px;
}

.HorizontalAlignDiv:hover {
  cursor: pointer;
}

.ServiceReminders {
  width: 140px;
  height: 70px;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid #e5e5e5;
}

.Reminders {
  margin-bottom: 5px;
}

.VerticalDivider {
  border-left: 2px dotted #999999;
  margin: 0px 5px;
}

.ReminderUnits {
  display: flex;
}
</style>
