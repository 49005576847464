import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const CAMERA_API = "/camera";

// ----------------------- STATE ------------------------- //
const state = {
  camera_data: [],
  currentIndex: -1,
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getCamera: state => getField(state.camera_data[state.currentIndex]),

  getCurrentCamera: state => {
    return state.camera_data[state.currentIndex];
  },

  getActiveCameras: state => {
    return state.camera_data.filter(element => element.active==true)
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updateCamera(state, payload) {
    updateField(state.camera_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.camera_data.findIndex(
      camera => camera.id == theID
    );
  },

  setCurrentIndexByVehicleID(state,vehicleID){
    state.currentIndex = state.camera_data.findIndex(
      camera => camera.vehicle_id == vehicleID
    );
  },

  loadAllCameras(state, payload) {
    state.camera_data = payload.data;
    state.currentIndex = 0;
  },

  addCamera(state, payload) {
    state.camera_data.push(payload.data);
    state.currentIndex = state.camera_data.length - 1;
  },

  saveCamera(state, payload) {
    state.camera_data[state.currentIndex] = payload.data;
  },

  fetchCamera(state, payload) {
    if (state.currentIndex == -1) {
      state.camera_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.camera_data[state.currentIndex] = payload.data;
    }
  },

  deleteCamera(state, payload) {
    state.currentIndex = state.camera_data.findIndex(
      camera => camera.id == payload.data.id
    );
    state.camera_data.splice(state.currentIndex, 1);
  },

  enableCamera(state, payload) {
    state.currentIndex = state.camera_data.findIndex(
      camera => camera.id == payload.data.id
    );
    state.camera_data[state.currentIndex].active = true;
  },

  disableCamera(state, payload) {
    state.currentIndex = state.camera_data.findIndex(
      camera => camera.id == payload.data.id
    );
    state.camera_data[state.currentIndex].active = false;
  },
  reset(state) {
    state.camera_data = []
    state.currentIndex = -1
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllCameras({ commit }) {
    try {
      commit("loadAllCameras", await Repository.get(`${CAMERA_API}`));
    } catch (error) {
      console.log(error);
    }
  },

  async addCamera({ commit }) {
    await Repository.get(`${CAMERA_API}/new`)
      .then(response => {
        commit("addCamera", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async saveCamera({ state, commit }) {
    try {
      commit(
        "saveCamera",
        await Repository.post(
          `${CAMERA_API}`,
          state.camera_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchCamera({ commit }, payloadID) {
    try {
      commit("fetchCamera", await Repository.get(`${CAMERA_API}/${payloadID}`));
    } catch (error) {
      console.log(error);
    }
  },

  async fetchCameraByVehicleID({ commit }, payload) {
    try {
      commit("loadAllCameras", await Repository.get(`${CAMERA_API}/vehicle/${payload.vehicle_id}?from=${payload.from}`));
    } catch (error) {
      console.log(error);
    }
  },


  async deleteCamera({ commit }, payloadID) {
    try {
      commit(
        "deleteCamera",
        await Repository.delete(`${CAMERA_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async enableCamera({ commit }, payloadID) {
    try {
      commit(
        "enableCamera",
        await Repository.get(`${CAMERA_API}/enable/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },
  async disableCamera({ commit }, payloadID) {
    try {
      commit(
        "disableCamera",
        await Repository.get(`${CAMERA_API}/disable/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchCameras({ commit }, searchTerm) {
    try {
      commit(
        "loadAllCameras",
        await Repository.get(`${CAMERA_API}/search?q=${searchTerm}`)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
