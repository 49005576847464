<template lang="pug">
  .dashboardWorkflow()
    grid-layout(
      v-if="this.layout.length >0"
      :layout.sync="layout"
      :col-num="12"
      :row-height="rowHeight"
      :is-draggable="true"
      :is-resizable="true"
      :vertical-compact="true"
      :use-css-transforms="true"
      :responsive="true"
    )
      grid-item(
        v-for="(item,index) in layout"
        :key="index"
        :static="item.static"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        drag-allow-from=".vue-draggable-handle"
        drag-ignore-from=".no-drag"
        @resize="resize"
        @move="move"
        @resized="resized"
        @moved="moved"
      )

        inspection-drag-element(:text="item.i" :clientHeight="rowHeight * item.h + 10 * (item.h - 1)" :resize="resizeFlag" :item="item" @close="onClose")

</template>

<script>

import { GridLayout, GridItem } from "vue-grid-layout";
import InspectionDragElement from "@/modules/inspections/components/overview/components/InspectionDragElement";
import { panelSchema } from "@/modules/dashboard/store/data";


export default {
  components: {
    GridLayout,
    GridItem,
    InspectionDragElement,
  },


  data:()=>({
    dialog: false,
    isModified: false,

    draggable: true,
    resizable: true,
    index: 0,
    rowHeight: 150,
    checkbox1: false,

    layout: [

      {
        "h": 1,
        "i": "TaskPanel",
        "w": 12,
        "x": 0,
        "y": 0,
        "static": false
      },

      {
        "h": 2,
        "i": "UserRecordsPanelStat",
        "w": 6,
        "x": 0,
        "y": 2,
        "static": false
      },
      {
        "h": 2,
        "i": "DailyRecordsPanel",
        "w": 6,
        "x": 6,
        "y": 2,
        "static": false
      },
    ],
    resizeFlag: false,
  }),
  computed: {
    panelSchema: function() {
      return panelSchema.sort((a, b) => a.title.localeCompare(b.title));
    },
  },
  methods: {


    switchVisibility: function(panel) {
      console.log(panel);
      panel.visible = !panel.visible;
      let index = this.layout.findIndex((item) => item.i === panel.name);
      if (panel.visible == true && index == -1) {
        // add the panel
        this.addPanel(panel.w, panel.h, panel.name);
      } else if (panel.visible == false && index > -1) {
        // remove panel
        this.layout.splice(index, 1); // delete
      }
    },
    move: function(i, newX, newY) {
      console.log("MOVE i=" + i + ", X=" + newX + ", Y=" + newY);
    },

    resize: function(i, newH, newW) {
      console.log("RESIZE i=" + i + ", H=" + newH + ", W=" + newW);
      this.resizeFlag = !this.resizeFlag;
    },

    moved: function(i, newX, newY) {
      console.log("### MOVED i=" + i + ", X=" + newX + ", Y=" + newY);
      this.isModified = true;
      // this.$store.commit(
      //     "profile/setDashboard",
      //     JSON.parse(JSON.stringify(this.layout))
      // );
      console.log(this.layou);
    },

    resized: function(i, newH, newW, newHPx, newWPx) {
      console.log(
          "### RESIZED i=" +
          i +
          ", H=" +
          newH +
          ", W=" +
          newW +
          ", H(px)=" +
          newHPx +
          ", W(px)=" +
          newWPx
      );
      this.isModified = true;
      // this.$store.commit(
      //     "profile/setDashboard",
      //     JSON.parse(JSON.stringify(this.layout))
      // );
    },

    refresh: function(i, newWPx, newHPx) {
      this.layout.find((panel) => panel.i == i).clientWidth = newWPx;
      this.layout.find((panel) => panel.i == i).clientHeight = newHPx;
    },

    saveLayout: function() {
      // if (this.isModified) {
      //   console.log("Saving the layout");
      //   Repository.post(
      //       "/user/settings",
      //       { dashboard: this.layout },
      //       {
      //         headers: { "Content-Type": "multipart/form-data" },
      //       }
      //   ).then((response) => {
      //     console.log("Saved Dashboard Layout");
      //     this.layout = response.data.dashboard;
      //   });
      // }
    },

    onSave: function() {
      this.isModified = true;
      this.saveLayout();
    },

    addPanel: function(width, height, name) {
      let xPos = 0;
      let yPos = 0;
      if (this.layout.length > 0) {
        let lastPanel = this.layout[this.layout.length - 1];
        xPos = lastPanel.x + lastPanel.w;
        yPos = lastPanel.y + lastPanel.h;

        console.log(`xPos:${xPos}, yPos:${yPos}`);

        // if more than 6 columns
        if (xPos <= 12 - width) {
          yPos = lastPanel.y + lastPanel.h;
        } else {
          xPos = 0;
          yPos = lastPanel.y;
        }
      }

      this.layout.push({
        x: xPos,
        y: yPos,
        w: width,
        h: height,
        i: name,
        static: false,
      });

      // this.$store.commit(
      //     "profile/setDashboard",
      //     JSON.parse(JSON.stringify(this.layout))
      // );
    },

    onClose: function(item) {
      let index = this.layout.findIndex((element) => element.i === item.i); // get index
      this.layout.splice(index, 1); // delete

      // this.$store.commit(
      //     "profile/setDashboard",
      //     JSON.parse(JSON.stringify(this.layout))
      // );
    },

    resetDialogLayout: function() {
      this.dialog = false;
      this.isModified = true;

      // this.$store.commit(
      //     "profile/setDashboard",
      //     JSON.parse(JSON.stringify(this.layout))
      // );
    },

    saveDialogLayout: function(event) {
      console.log(event);
      this.isModified = true;
      this.dialog = false;
      this.saveLayout();
    },
  },

  mounted: function() {

    // this.layout = JSON.parse(
    //     JSON.stringify(this.$store.getters["profile/getDashboard"])
    // );


    // console.log(this.$store.state.profile.userInfo);
    this.index = this.layout.length;
  },

}
</script>
<style scoped lang="stylus">
.dashboardWorkflow{
  width 100%;
  height 100%;
}
</style>