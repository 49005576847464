<template>
  <v-combobox
    v-model="selectedVehicles"
    :items="getVehicles"
    chips
    multiple
    clearable
    label="Filter Vehicles"
  >
    <template slot="item" slot-scope="data">
      <v-list-tile class="listTile">
        <div
          v-if="data.item.thumbnail"
          class="listAvatar"
          :style="{
            'background-image':
              'url(/media/images/thumbnails/' + data.item.thumbnail
          }"
        ></div>

        <v-list-tile-content>
          <v-list-tile-title>{{ data.item.name }}</v-list-tile-title>
          <v-list-tile-sub-title>{{
            data.item.numberplate
          }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
    <template slot="selection" slot-scope="data">
      <v-chip
        :selected="data.selected"
        :disabled="data.disabled"
        close
        :key="JSON.stringify(data.item.name)"
        class="v-chip--select-multi "
        @input="selectItem(data.item.name)"
      >
        <v-avatar class="accent white--text" size="36px">
          <img
            v-if="data.item.thumbnail"
            :src="'/media/images/thumbnails/' + data.item.thumbnail"
            style="object-fit: cover"
            alt="Avatar"
          />
          <v-icon
            v-else
            v-text="data.item.name.slice(0, 1).toUpperCase()"
            class="title font-weight-black"
          ></v-icon>
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  data: () => ({
    selectedVehicles: null
  }),

  methods: {
    selectItem: function(value) {
      console.log(value);
    }
  },

  computed: {
    getVehicles: function() {
      return [
        {
          name: "Honda",
          thumbnail: "honda_crv.jpg",
          numberplate: "KCA 234J"
        },
        {
          name: "Subaru",
          thumbnail: "subaru_outback.jpg",
          numberplate: "KAA 343J"
        },
        {
          name: "Toyota",
          numberplate: "KCR 555M",
          thumbnail: null
        }
      ];
      //   return this.$store.getters["vehicles/getVehicles"];
    }
  }
};
</script>

<style scoped>
.listAvatar {
  height: 40px;
  width: 65px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 10px;
}
</style>
