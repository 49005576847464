<template lang="pug">
  .pt-2.h-full
    v-tabs.tabs(left='', height='32', slider-color='primary' )
      v-tab()  Time Reminders
      v-tab-item()
        .mx-4.my-4()
          .d-flex.grey.py-4.lighten-4.align-center()
            v-btn.mx-2( color="primary" icon @click="$refs.calendar.prev()")
              v-icon() arrow_back
            v-btn.mx-2( color="primary" icon @click="$refs.calendar.next()")
              v-icon() arrow_forward
            .font-weight-bold.title.mx-4() {{getCurrentMonth}}
            v-spacer()

            v-btn(text small @click="setToday") Today
            v-btn(text small  @click="setThisWeek") This Week
            v-btn-toggle.mx-4(v-model="type"  @change="updateCalendarMode")
              v-btn(text small value="day") day
              v-btn(text small value="4day") 4 day
              v-btn(text small value="week") week
              v-btn(text small value="month") month
          v-row()
            v-col(xl="6" lg="12" md="12" sm="12")
              v-calendar(
                v-model="value"
                ref="calendar"
                :weekdays="weekday"
                :type="type"
                :events="events"
                event-overlap-mode="column"

                :style="{height:`${windowHeight-320}px`}"
                :event-color="getEventColor"
                @click:date="viewDay"
                @change="getEvents"
              )
            v-col(xl="6"  lg="12" md="12" sm="12")
              v-data-table(
                :headers="headers"
                :items="vehicle_renewal_data"
                item-key="id"
                :loading="isLoading"
              )
                template(v-slot:item.vehicle_renewal_types="{ item }")
                  div(v-for="(vehicle_renewal_type,index) in item.vehicle_renewal_types" :key="index") {{vehicle_renewal_type.name}}

                template(v-slot:item.start_date="{item}")
                  div(v-if="item.start_date") {{getDueTime(item)}}

                template(v-slot:item.contacts="{item}")
                  div(v-if="item.contacts.length > 0 && item.contacts"  v-for="(contact,index) in item.contacts" :key="index") {{contact.first_name}} {{contact.last_name}}

                template( v-slot:item.action="{ item }")
                  v-row.mr-5(justify="end")
                    more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')
      v-tab()  Millage Reminders
        v-tab-item()
          div() Tab Body LIst

</template>
<script>
import axios from "axios";
import moment from "moment";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
export default {
  components:{
    MoreDropDownMenu,
  },
  data: () => ({
    vehicle_renewal_data:[],
    isLoading: true,
    value: '',
    weekday: [0, 1, 2, 3, 4, 5, 6],

    type: 'month',
    types: ['month', 'week', 'day', '4day'],
    events: [],
    colors: ['green', 'primary'],
    names: ['Add', 'Subtract'],
    mode: 'column',


    windowHeight:null,

    headers:[
      { text: "Renewal", value: "vehicle_renewal_types",sortable: false,align:'start' },
      { text: "Next Due (Time)", value: "start_date" },
      { text: "Subscribers", value: "contacts" },
      { text: "Comments", value: "comments", align: "left" },
      { text: "Action", value: "action", align: "right" }
    ],
    menuitems: [
      {
        title: "Send Notification",
        icon: "notifications",
        action: "notification"
      },
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" },
      { title: "View Full History", icon: "history", action: "history" }
    ]

  }),

  created() {
    window.addEventListener("resize",this.onResize)
  },

  destroyed() {
    window.removeEventListener('scroll',this.onResize)
  },
  mounted() {
    this.$nextTick(()=>{
      this.windowHeight = window.innerHeight;
      this.isLoading = true;
      this.events = [];
      axios.get(`/api/vehicleRenewal/vehicle/${this.$route.params.vehicleID}`).then(response =>{
        this.isLoading = false;
        this.vehicle_renewal_data =[];
        response.data.forEach(element => {
          element.calendar = this.processItem(element);
        })

      })

    })
  },

  methods:{
    getDueTime(item){
      return moment(item.start_date).format("h:mm a ddd, Do MMM YYYY")
    },
    processItem(item){
      let isVisible = false

      let tempDate = moment(item.start_date);
      console.log("interval "+ item.interval)
      console.log("due date " + item.due_date_amount)
      let startTime = moment().startOf("month")
      let endTime = moment().endOf("month")
      let intervalDuration = moment.duration(item.interval);
      let dueDuration = moment.duration(item.due_date_amount)
      let intervalList = [];
      let dueList = [];

      // Add calendar to intervalList
      intervalList.push(moment(item.start_date));
      if (moment(item.start_date) > startTime && moment(item.start_date) < endTime){
        isVisible = true;
      }

      let newTime = null;

      while (newTime < endTime){
        newTime = tempDate.add(intervalDuration)
        intervalList.push(newTime.clone());
        if (newTime > startTime && newTime < endTime){
          isVisible = true;
        }
      }

      intervalList.forEach(element => {
        this.events.push({"name":this.getRenewalTypes(item),"start": new Date(element.toDate()),"color":"primary","timed":true})
      })

      // Add events to due time
      if (item.due_date_amount != null ) {
        let dueTime = moment(item.start_date).subtract(moment.duration(item.due_date_amount))
          dueList.push(dueTime)
        if (dueTime > startTime && dueTime < endTime){
          isVisible = true;
        }
      }


      tempDate = moment(item.start_date);
      let dueTime = null

      if (item.due_date_amount != null ) {
        while (dueTime < endTime) {
          dueTime = tempDate.add(intervalDuration).clone().subtract(dueDuration)
          dueList.push(dueTime.clone())
          if (dueTime > startTime && dueTime < endTime){
            isVisible = true;
          }
        }
      }

      dueList.forEach(element => {
        this.events.push({"name":this.getRenewalTypes(item),"start": new Date(element.toDate()),"color":"grey lighten-1","timed":true})
        console.log(element.format("h:mm a, ddd, Do MMM YYYY"))
      })



      if(isVisible){
        this.vehicle_renewal_data.push(item);
      }

      return intervalList;
    },

    getRenewalTypes(item){
      let resultString = "";
      item.vehicle_renewal_types.forEach(element => {
        resultString += element.name + "\n";
      })
      return resultString;
    },

    onResize:function(){
      this.windowHeight = window.innerHeight;
    },
    getEvents: function(){
      return this.events;
    },
    viewDay ({ date }) {

      this.value = date
      this.type = 'day'

      this.$nextTick(() => {
        this.$refs.calendar.scrollToTime('08:00')
      })
    },
    getEventColor(event) {
      return event.color
    },

    onClick: function(value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          this.$store.commit(
              "reminders/vehicle_renewal/setCurrentIndexByID",
              value.theObject.id
          );
          this.$router.push({
            name: "vehicleRenewalForm",
            params: { reminderID: value.theObject.id }
          }).catch(()=>{});

          break;
        case "delete":
          this.$store.dispatch(
              "reminders/vehicle_renewal/deleteVehicle_renewal",
              value.theObject.id
          );
          break;
        case "notification":
          console.log("notification");
          break;
        case "history":
          console.log("history");
          break;
      }
    }
  }
};
</script>
<style scoped lang="stylus"></style>
