<template lang="pug">
  div(style="width:100%")
    v-layout(row wrap)
      v-flex.stepper(xs12 md3 :style="steperStyle(0)" @click.prevent="onSelect(0)")
        v-layout(align-center justify-center fill-height column)
          | Open
          div.headline 1

      v-flex.stepper(xs12 md3 :style="steperStyle(1)" @click.prevent="onSelect(1)")
        div.arrowLeft.hidden-sm-and-down
          v-layout(align-center justify-center fill-height)
            v-icon.white--text keyboard_arrow_right
        v-layout.hidden-md-and-up(align-center justify-center)
          .arrowTop
            v-layout(align-center justify-center fill-height)
              v-icon.white--text keyboard_arrow_down
          
        v-layout(align-center justify-center fill-height column)
          | Approve
          div.headline 1

      v-flex.stepper(xs12 md3 :style="steperStyle(2)" @click.prevent="onSelect(2)")
        div.arrowLeft.hidden-sm-and-down
          v-layout(align-center justify-center fill-height)
            v-icon.white--text keyboard_arrow_right
        v-layout.hidden-md-and-up(align-center justify-center)
          .arrowTop
            v-layout(align-center justify-center fill-height)
              v-icon.white--text keyboard_arrow_down
        v-layout(align-center justify-center fill-height column)
          | Received
          div.headline 1

      v-flex.stepper(xs12 md3 :style="steperStyle(3)" @click.prevent="onSelect(3)")
        div.arrowLeft.hidden-sm-and-down
          v-layout(align-center justify-center fill-height)
            v-icon.white--text keyboard_arrow_right
        v-layout.hidden-md-and-up(align-center justify-center)
          .arrowTop
            v-layout(align-center justify-center fill-height)
              v-icon.white--text keyboard_arrow_down
        v-layout(align-center justify-center fill-height column)
          | Closed
          div.headline 1
</template>
<script>
export default {
  data: () => ({
    selected: 0
  }),

  methods: {
    steperStyle: function(value) {
      var styleObj = {};

      if (value == this.selected) {
        styleObj["background-color"] = "#EEEEEE";
        styleObj["color"] = this.$vuetify.theme.primary;
      } else {
        styleObj["background-color"] = "#FAFAFA";
        styleObj["color"] = "#000000";
      }
      return styleObj;
    },

    onSelect: function(value) {
      this.selected = value;
    }
  }
};
</script>
<style scoped lang="stylus">


.adjustIcon
  padding-top 2px

.stepper
  border 1px solid #E0E0E0
  height 80px;


.stepper:hover
  cursor: pointer;
  border: 1px solid #BBBBBB
  font-weight: bold

.arrowLeft
  float left
  margin 25px 0px 0px -15px
  z-index 99
  width 30px
  height 30px
  background-color #757575
  border-radius 50%




.arrowTop
  width 20px
  height 20px
  background-color #757575
  margin -10px 0px -10px 0px
  border-radius 50%
</style>
