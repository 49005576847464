<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    v-text-field(v-model="selectHint" label="Description")
    contacts-auto-complete(v-if="selectAuthorizeIDs" v-model="selectAuthorizeIDs"  :multiple="true")
    
    
    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        show-advanced-properties(:showAdvanced="showAdvanced")
      div(v-show="showAdvanced")
        permissions-component()
        v-text-field(v-model="selectAuthorizeIDs" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" diabled)


</template>
<script>
import SETTINGS_DEFAULT from "@/modules/workflow/formBuilder/store/formSettings";
import ShowAdvancedProperties from "@/modules/workflow/formBuilder/components/ui/ShowAdvancedProperties";
import PermissionsComponent from "@/modules/workflow/formBuilder/components/ui/PermissionsComponent.vue";
import ContactsAutoComplete from "@/modules/global/components/forms/ContactsAutoComplete.vue";

export default {
  components: {
    ShowAdvancedProperties,
    PermissionsComponent,
    ContactsAutoComplete
  },

  data: () => ({
    errorMessages: [],
    properties: {},
    showAdvanced: false,

  }),

  computed: {
    selectedFieldName: {
      get: function() {
        return this.properties.label;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "label",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },


    selectAuthorizeIDs: {
      get: function() {
        console.log("-------------------");
        console.log(this.properties.authorize_ids)
        return this.properties.authorize_ids;
      },
      set: function(value) {
        console.log("++++++++++++++++++++++++");
        console.log(value)
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "authorize_ids",
          value: value
        });

        console.log(`Entering new Authrorize ids: ${value}`)
      }
    },

    selectRequired: {
      get: function() {
        return this.properties.required;
      },

      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "required",
          value: value
        });
      }
    },

    selectHint: {
      get: function() {
        return this.properties.hint;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "hint",
          value: value
        });
      }
    },

    selectID: {
      get: function() {
        return this.$store.getters[SETTINGS_DEFAULT.GET_PATH].id;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    }
  },

  mounted() {
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    console.log("++++++++ PROPERTIES ++++++++++++")
    console.log(this.properties.authorize_ids)
    this.$refs.fieldName.focus();
  },

  methods: {
    removeOption: function(value) {
      let tempArray = JSON.parse(JSON.stringify(this.selectRadio));
      let index = tempArray.findIndex(theValue => theValue === value);
      tempArray.splice(index, 1);

      // save to store.
      this.selectRadio = tempArray;
    }
  }
};
</script>
<style scoped lang="stylus">
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}
</style>
