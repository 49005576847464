<template lang="pug">
div(:style="{height:'25px', opacity:disabled?0.4:1.0}")
    v-menu(offset-y left :disabled="disabled")
      template(v-slot:activator="{on}")
        .theBtn(v-on="on", color="primary" outlined)
          v-icon(small) {{defaultIcon}}
      v-list( dense)
        v-list-item(v-for="(item, index) in items" :key="index" @click="onClick(item.action)")
          v-list-item-title
            v-icon.mr-2(small)
              | {{item.icon}}
            | {{ item.title }}
</template>
<script>
export default {
  props: {
    defaultIcon: {
      type: String,
      default: "more_vert"
    },
    items: Array,
    disabled:{
      type: Boolean,
      default: false
    },
    theObject: Object
  },
  data: () => ({}),
  methods: {
    onClick: function(actionType) {
      this.$emit("onClick", {
        action: actionType,
        theObject: this.theObject
      });
    }
  }
};
</script>
<style scoped lang="stylus">
.theBtn
  width 25px;
  border 2px solid #666666
  background-color #E5E5E5
  text-align center
  border-radius 5px
</style>
