<template lang="pug">
  div()
    v-autocomplete(
      v-model="question" 
      :items="items"
      :search-input.sync="search"
      prepend-icon="search"
      item-text="Item"
      item-value="id"
      return-object
      )
      template(v-slot:selection="{item, index}")
        v-chip(v-if="index === 0")
          span() {{item.Item}}

      template (v-slot:prepend-item)
        v-list-item(ripple )
          v-list-item-action()
            v-icon() search
          v-list-item-content
            v-list-item-title() Select All
        v-divider.mt-2()

    
      
</template>
<script>
import Repository from "@/repository";
export default {
  data: () => ({
    question: null,
    search: null,

    items: [],
    isLoading: false
  }),
  created: function() {
    this.debouncedGetAnswer = this._.debounce(this.getAnswer, 1000);
  },
  watch: {
    search: function(value) {
      this.answer = "Waiting for you to stop typeing...";
      this.debouncedGetAnswer();
      console.log(value);
    }
  },
  methods: {
    getAnswer: function() {
      this.answer = "Thinking....";
      if (this.search.length < 1) {
        return;
      }
      Repository.get(`/workflowDataTable/searchElastic?q=${this.search}`).then(
        response => {
          if (response.data.hits.total.value > 0) {
            this.items = [];
            response.data.hits.hits.forEach(element => {
              this.items.push(element._source);
            });
            console.log(response.data.hits.hits);
          }
        }
      );
    }
  }
};
</script>
<style scoped lang="stylus"></style>
