import InspectionDetailsForm from "@/modules/inspections/components/forms/InspectionDetailsForm";
import InspectionFormDesigner from "@/modules/inspections/components/formDesigner/InpectionFormDesigner";
import InspectionNewLayout from "@/modules/inspections/layouts/InspectionNewLayout";
import InspectionRecordOverview from "@/modules/inspections/layouts/InspectionRecordOverview";
import InspectionOverview from "@/modules/inspections/components/overview/InspectionOverview";
import InspectionFormOverview from "@/modules/inspections/layouts/InspectionFormOverview";

import InspectionFormDashboard from "@/modules/inspections/components/overview/layouts/InspectionFormDashboard";
import InspectionSettings from "@/modules/inspections/components/overview/layouts/inpsectionSettings";
import InspectionPermissions from "@/modules/inspections/components/overview/layouts/InspectionPermissions";
import InspectionRecords from "@/modules/inspections/components/overview/layouts/InspectionRecords";




export const INSPECTION_ROUTES = [
    {
        path: "inspectionRecordOverview",
        name: "inspectionRecordOverview",
        component: InspectionRecordOverview
    },
    {
        path: "inspectionNewLayout",
        name: "inspectionNewLayout",
        component: InspectionNewLayout
    },
    {
        path: "inspectionFormDesigner",
        name: "inspectionFormDesigner",
        component: InspectionFormDesigner
    },
    {
        path: "inspectionDetailsForm",
        name: "inspectionDetailsForm",
        component: InspectionDetailsForm
    },
    {
        path: "inspectionOverview",
        name: "inspectionOverview",
        component: InspectionOverview
    },
    {
        path: "inspectionFormOverview",
        name: "inspectionFormOverview",
        component: InspectionFormOverview,
        children:[
            {
                path: "inspectionFormDashboard",
                name: "inspectionFormDashboard",
                component: InspectionFormDashboard
            },
            {
                path: "inspectionRecords",
                name: "inspectionRecords",
                component: InspectionRecords
            },
            {
                path: "inspectionPermissions",
                name: "inspectionPermissions",
                component: InspectionPermissions
            },
            {
                path: "inspectionSettings",
                name: "inspectionSettings",
                component: InspectionSettings
            },
        ]
    },

];
