<template lang="pug">
  div.fill-view
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Form Builder Templates
        v-spacer
        .text-xs-center
          v-btn.mx-2(outlined small color="primary" @click="onRefresh" )
            v-icon.mr-2(small) sync
            | Refresh
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item(v-for='item in items', :key='item.title')
                v-list-item-title {{ item.title }}
        v-btn.ma-2.mr-5(v-if="isAdministrator" small, color='primary', dark, @click='addForm') New Template
    v-divider
    .grey.lighten-4.py-3
    v-divider
    .max-height.d-flex()
      div.navBar
        v-list.fill-height(dense shaped)
          v-subheader() WORKFLOWS
          v-list-item-group(color="primary")
            v-list-item()
              v-list-item-icon()
                v-icon() mdi-cog-outline
              v-list-item-content()
                v-list-item-title My Templates


            v-list-group(prepend-icon="mdi-account-circle")
              template(v-slot:activator)
                v-list-item-title Templates
              v-list-item(v-for="(n, i) in 3"  :key="i")
                v-list-item-content()
                  .leftListPadding()
                    v-list-item-title yup anoter {{n}}
                v-list-item-icon()
                  v-icon() mdi-cog-outline


            v-list-item()
              v-list-item-icon()
                v-icon() mdi-delete
              v-list-item-content()
                v-list-item-title Others

      #templateGridView.grey.lighten-3()
        div(:style="getContainerStyle()")
          v-container()
            v-layout(align-center)
              .title.ml-3.grey--text() WORKFLOW BOARDS
              v-spacer()
              v-layout.px-3(row, wrap, align-center)
                v-text-field.pr-6(v-model='searchFilter', label='Search Filter', :persistent-hint='true', append-icon="search")

            .formTemplateGrid(v-if="$store.getters['workflow/getCurrentWorkflow']")
              div(v-for="(item,index) in $store.state.workflow.workflow_data" :key="index" style="height: 190px; width:320px;")
                v-card(elevation="2" shaped
                  style="height: 160px; width:280px;")
                  v-card-title.titleCard.py-0.pr-1( )
                    .subtitle-1.font-weight-bold.boardTitle(@click="showDashboard(item)") {{item.name}}
                      span.font-weight-light.ml-2.grey--text() &ndash; v{{item.version}}

                    v-spacer()
                    v-menu()
                      template( v-slot:activator="{ on, attrs }")
                        v-btn(icon v-bind="attrs" v-on="on" )
                          v-icon() expand_more
                      v-list(dense)
                        v-list-item-group()
                          v-list-item(v-if="isAdministrator" @click="onCreateForm(item)")
                            v-list-item-icon()
                              v-icon(:size="20")  note_add
                            v-list-item-title() Add New Record
                          v-list-item(@click="showBoardLayout(item)")
                            v-list-item-icon()
                              v-icon(:size="20") dashboard
                            v-list-item-title() Dashboard
                          v-list-item(v-if="isAdministrator" @click="editTemplate(item)")
                            v-list-item-icon()
                              v-icon(:size="20") edit
                            v-list-item-title() Edit Template
                          v-list-item(v-if="isAdministrator" @click="settingTemplate(item)")
                            v-list-item-icon()
                              v-icon(:size="20")  settings
                            v-list-item-title() Settings

                  .d-flex()
                    template(v-if="item.thumbnail_id")
                      .ml-4(style="min-width:70px;min-height:70px")
                        image-thumbnail(:src="item.thumbnail_id" :width="70" :height="70")
                    .descriptionText.caption.mx-4.grey--text.text--darken-1(style="max-height:78px;" v-text="item.description")



                  .cartActions()
                    v-layout()
                      v-card-actions()
                        v-tooltip(bottom)
                          template(v-slot:activator="{ on, attrs }")
                            v-btn(icon @click="onCreateForm(item)" v-on="on" )
                              v-icon() note_add
                          span() Add New Record
                        v-tooltip(bottom)
                          template(v-slot:activator="{ on, attrs }")
                            v-btn(icon @click="showDashboardLayout(item)"  v-on="on")
                              v-icon() dashboard
                          span() Show Dashboard
                        v-tooltip(bottom)
                          template(v-slot:activator="{ on, attrs }")
                            v-btn(icon @click="showListLayout(item)"  v-on="on")
                              v-icon() list
                          span() Show Records


                        //- v-btn(icon @click="showBoardLayout(item)")
                          v-icon() dashboard
                    .subtitle-1() {{item.record_count}}




</template>

<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components:{
    ImageThumbnail
  },
  name: "FormTemplateGridLayout",
  data: () => ({
    searchFilter:null,
    height: null,
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ],
  }),

  created() {
    // stretch the elements to fill the height
    this.height = window.innerHeight - 115;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      // this.width = this.$refs.contentArea.getBoundingClientRect().width;
    });
  },

  destroyed() {
    window.removeEventListener('resize',this.onResize)
  },
  mounted() {
    this.$store.dispatch("workflow/loadAllWorkflows").then(() => {
      console.log(this.$store.state.workflow_data);
    });
    this.onResize();
  },

  computed:{
    isAdministrator: function(){
      return this.$store.state.profile.userInfo.is_administrator;
    },
  },
  methods: {
    onRefresh: function(){
      this.$store.dispatch("workflow/loadAllWorkflows");
    },

    onResize:function(){
      this.height = window.innerHeight - 240;
    },
    getContainerStyle: function() {
       let  obj = {height:`${this.height}px`,"overflow-y":"scroll"}
       return obj
    },
    addForm: function() {
      this.$store.commit("workflow/formBuilder/resetFormBuilder");
      this.$store.dispatch("workflow/addWorkflow").then(() => {
        let currentObject = this.$store.getters["workflow/getCurrentWorkflow"];
        this.$router.push({
          name: "templateBuilderForm",
          params: { workflowID: currentObject.id }
        }).catch(()=>{});
      });
    },

    onCreateForm: function(item) {
      this.$store.commit("workflow/formBuilder/resetFormBuilder");
      this.$store.commit("workflow/setCurrentIndexByID",item.id);

      // this.$store.dispatch("workflow/workflowRecord/filterRecords",{version:item.version,workflowTemplateId:item.id}).then(()=>{
      // add the record
      this.$store
          .dispatch("workflow/workflowRecord/addRecord", item.id)
          .then(() => {
            let theRecord = this.$store.getters[
                "workflow/workflowRecord/getCurrentRecord"
                ];
            this.$store.commit("workflow/setCurrentIndexByID", item.id);
            this.$store.commit(
                "workflow/formBuilder/setSchema",
                this._.clone(this.$store.getters["workflow/getLatestSchema"])
            );
            this.$store.commit("workflow/workflowRecord/toggleShowForm",true);
            this.$router.push({
              name: "workflowRecordForm",
              params: { workflowID: theRecord.workflow_template_id,workflowRecordID: theRecord.id }
            }).catch(()=>{});
          });
      // });

    },

    showDashboard: function(item){
      console.log(`show dashobard ${item.id}`)
      this.$store.commit("workflow/board/clearAll");
      this.$store.commit("workflow/setCurrentIndexByID",item.id);
      this.$router.push({
        name: "workflowDashboard",
        params: { workflowID: item.id }
      }).catch(()=>{});
    },

    showListLayout: function(item){
      this.$store.commit("workflow/setCurrentIndexByID",item.id);
      this.$router.push({
        name: "workflowRecordsTable",
        params: { workflowID: item.id }
      }).catch(()=>{});
    },

    showDashboardLayout: function(item){
      this.$store.commit("workflow/setCurrentIndexByID",item.id);
      this.$router.push({
        name: "workflowDashboard",
        params: { workflowID: item.id }
      }).catch(()=>{});
    },


    showOverview: function(item){
      this.$store.commit("workflow/formBuilder/resetFormBuilder");
      this.$store.commit("workflow/board/clearAll");
      this.$store.commit("workflow/setCurrentIndexByID",item.id);
      this.$router.push({
        name: "workflowOverview",
        params: { workflowID: item.id }
      }).catch(()=>{});
    },

    showBoardLayout: function(item){
      this.$store.commit("workflow/board/clearAll");
      this.$store.commit("workflow/setCurrentIndexByID",item.id);
      this.$router.push({
        name: "workflowBoard",
        params: { workflowID: item.id }
      }).catch(()=>{});
    },

    editTemplate: function(item){
      this.$store.commit("workflow/formBuilder/resetFormBuilder");
      this.$store.commit(
          "workflow/setCurrentIndexByID",
          item.id
      );
      // save the latest scheme
      this.$store.commit(
          "workflow/formBuilder/setSchema",
          this._.clone(
              item.data[item.data.length - 1].schema
          )
      );
      this.$router.push({
        name: "templateBuilderForm",
        params: { workflowID: item.id }
      }).catch(()=>{});
    },

    settingTemplate:function(item){
      this.$store.commit("workflow/setCurrentIndexByID",item.id);
      this.$router.push({
        name: "workflowSettings",
        params: { workflowID: item.id }
      }).catch(()=>{});
    },

    deleteTemplate:function(item){
      confirm(
          "Are you sure you want to delete the template? " +
          item.name
      ) &&
      this.$store.dispatch("workflow/deleteWorkflow", item.id);
    },

    showDialog: function() {},

    closeDialog: function(value) {
      console.log(value);
    }
  },
}
</script>

<style scoped lang="stylus">

.heightWorkflowContent{
  height: calc(100% - 120px);


}
#templateGridView{
  width 100%
  height 100%


}


.navBar{
  width: 300px;
}
.zlistContent{
  padding-left 150px;
}

.leftListPadding{
  padding-left 74px;
}

.formTemplateGrid{
  display: flex;
  flex-wrap: wrap;
  padding: 12px 12px;
}

.cartActions{
  position absolute;
  bottom 0px;
  width 100%;
  padding-right 16px;

  display flex;
  align-items center;
}

.boardTitle{
  text-decoration none;
  width 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.boardTitle:hover{
  text-decoration underline;
  cursor:pointer;
}

.workflowColumn{
  height: 100%;
}
.descriptionText{
  overflow hidden;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleCard{

  height 40px;
}


</style>