<template lang="pug">
  v-autocomplete(
    ref='theCombo'
    v-model='group'
    :items='$store.state.settings.groupSettings.group_data'
    label="Group"
    item-text='name'
    item-value='id'
    filled
    @keyup.enter="onAddGroupComboBox"
    :hide-no-data="!search"
    :search-input.sync="search"
    clearable
    :menu-props="{closeOnContentClick: true}"
    @change="onChange"
    :disabled="disabled"
    )


    template(slot='selection', slot-scope='data')
      v-chip.chip--select-multi(:input-value='data.selected', small close @click:close="onRemoveItem()")
        | {{ data.item.name }}

    template(slot="no-data")
      v-list-item
        span.subheading Create
        v-chip.ml-2(:color="'primary white--text'" label small) {{search}}
</template>

<script>
export default {
  name: "GroupAutoComplete",
  props: {
    value:String,
    disabled:{
      type:Boolean,
      default: false
    }
  },
  data: () => ({
    group:[],
    search: null,
  }),

  created() {
    this.group = this.value;
  },

  mounted() {
    if(this.$store.state.settings.groupSettings.currentIndex == -1){
      this.$store.dispatch("settings/groupSettings/loadAllGroups")
    }
  },
  methods: {

    onChange: function(value){
      console.log(`has changed: ${value}`)
      this.$emit('input',value)
    },
    onAddGroupComboBox: function() {
      if (this.search == null || this.search.length == 0){
        return
      }
      this.$store.dispatch("settings/groupSettings/addGroup").then(()=>{
        // commit the name
        this.$store.commit("settings/groupSettings/setName",this.search)

        // save the name
        this.$store.dispatch("settings/groupSettings/saveGroup").then(()=>{
          this.group = this.$store.getters["settings/groupSettings/getCurrentGroup"].id
          this.search = null
          this.$emit('input',this.group)
        });
      })
    },

    onRemoveItem: function(){
      this.group = null;
      this.$emit('input',this.group)
    },


  },
}
</script>

<style scoped>

</style>