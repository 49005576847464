<template lang="pug">
  div(v-if="$store.state.settings.purchaseOrderStatusSettings.currentIndex > -1")
    .theForm.pb-2
      v-btn(small color="primary" outlined @click="cancel") Cancel
      v-btn(small color="primary" @click="save") Save
    v-card
      v-container
        v-layout(row wrap)
          v-flex(xs12)
            v-text-field(v-model="name"  hint="Enter name of the Purchase Order Status" :persistent-hint="true"  label="Name*"  required)
          v-flex(xs12)
            v-textarea(v-model="description"  hint="Enter Description" :persistent-hint="true"  label="Description"  required)
   
</template>
<script>
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "settings/purchaseOrderStatusSettings/getPurchaseOrderStatus",
  mutationType: "settings/purchaseOrderStatusSettings/updatePurchaseOrderStatus"
});

export default {
  computed: {
    ...mapFields({
      name: "name",
      description: "description"
    })
  },

  methods: {
    cancel: function() {
      this.$router.push({ name: "purchaseOrderStatusSettings" }).catch(()=>{});
    },

    save: function() {
      this.$store.dispatch(
        "settings/purchaseOrderStatusSettings/savePurchaseOrderStatus"
      );
      this.$router.push({ name: "purchaseOrderStatusSettings" }).catch(()=>{});
    }
  },

  mounted() {
    if (
      this.$store.state.settings.purchaseOrderStatusSettings.currentIndex == -1
    ) {
      this.$store.dispatch(
        "settings/purchaseOrderStatusSettings/fetchPurchaseOrderStatus",
        this.$route.params.purchaseOrderStatusID
      );
    }
  }
};
</script>
<style scoped lang="stylus"></style>
