<template lang="pug">
  v-dialog(v-model="dialogShow" persistent max-width="800px")
    input( hidden type="file" id="file" ref="file" accept=".xlsx,.xltx,.csv"  @change="handleFileupload()" required)
    v-card
      v-toolbar(color="primary")
        v-layout(justify-space-between, align-center)
          .UploadToolbar.primary.white--text()
            .title {{title}}
            .caption {{hint}}
          v-btn(color="white" text @click="closeDialog")
            | Close
            v-icon.ml-2() close
      v-stepper(v-model="stage")
        v-stepper-header
          v-stepper-step(:complete="stage > 1" step="1") Upload
          v-divider
          v-stepper-step(:complete="stage >2" step="2") Verify Columns


        v-stepper-items()
          v-stepper-content(step="1")
            v-progress-linear.ma-0(v-show="progressVisible", v-model="percentageComplete")
            v-form(v-model="valid"  ref="form")
              v-container(grid-list-sm)
                v-layout(row, wrap, justify-end)
                  v-flex(xs12)
                    template(v-if="!isEdit")
                      .uploadDetails
                        v-btn(color="primary" @click="selectFile()") Select File
                        v-text-field.pl-2(v-if="file"  v-model="file.name" disabled  :hint="`File Size: ${originalFileSize} Kb `" :persistent-hint="true" )
                    template(v-else)
                      v-text-field.mb-3(v-model="originalFileName" disabled hint="File Name" :persistent-hint="true" readonly)
                  v-flex(xs12)
                    v-text-field(v-model="fileName" ref="fileName" tabindex="2"  label="Table Name" hint="Name of File" :persistent-hint="true" :rules="nameRules" required)
                  v-flex(xs12)
                    v-textarea(v-model="description" tabindex="3"  label="Table Description" hint="Table Description" :persistent-hint="true")
                  v-flex(xs12 v-if="errorString")
                    v-layout(justify-end)
                      .caption.error--text {{errorString}}
            v-layout(justify-end)
              v-btn(text color="grey" @click="closeDialog" tabindex="5" ) Cancel
              v-btn(color="primary" @click="submit" tabindex="4" ) {{isEdit?"Save":"Upload"}}

          v-stepper-content(step="2")
            v-container(v-if="responseData" fluid)
              .fillWidth()
                v-data-table(
                  :headers="headers",
                  :items="getColumnNamesWithoutIDorActive",
                )
                  template(v-slot:item.type="{item}")
                    v-select(v-model="item.type" dense :items="columnTypes" :hide-details="true" item-text="text" item-value="value")


              v-layout.mt-3(justify-space-between)
                v-btn(text color="grey" @click="stage=1" tabindex="5" ) Previous
                v-btn(color="primary" @click="submitColumns"  tabindex="4" ) Save and Preview

</template>
<script>
import Repository from "@/repository";
export default {
  props: {
    dialogShow: Boolean
  },
  data: () => ({
    stage: 0,
    title: "Upload Excel File",
    hint: "here is the hint",
    progressVisible: false,
    percentageComplete: 30,
    valid: null, // check if the
    isEdit: false,
    file: {},
    originalFileSize: null,
    originalFileName: null,
    fileName: "",
    fileType: null,
    description: "",
    nameRules: [
      v => !!v || "Name is required",
      v => (v && v.length >= 3) || "Name must be more than 3 characters"
    ],
    errorString: null,

    itemArray: [],
    currentItemLength: null,
    responseData: null,
    columnTypes: [
      { text: "Text", value: "text" },
      { text: "Number", value: "integer" },
      { text: "Decimal", value: "float" },
      { text: "Keyword", value: "keyword" },
      { text: "Boolean", value: "boolean" },
      { text: "Date", value: "date" }
    ],
    headers: [
      { text: "Column Name", value: "name" },
      { text: "Column Type", value: "type" }
    ]
  }),

  computed: {
    getColumnNamesWithoutIDorActive: function() {
      let list = [];
      for (let i = 0; i < this.responseData.header.length; i++) {
        // Jump incase the column is of ID or active field
        if (
          this.responseData.header[i].name == "id" ||
          this.responseData.header[i].name == "active"
        ) {
          continue;
        }
        list.push(this.responseData.header[i]);
      }
      return list;
    }
  },

  methods: {
    // Step 1
    selectFile: function() {
      this.$refs.file.click();
    },

    // Step 2
    handleFileupload: function() {
      console.log(this.$refs.file.files[0]);
      this.file = this.$refs.file.files[0];
      this.originalFileSize = (this.file.size / 1024).toFixed(2);
      this.fileType = this.file.type;
      this.errorString = null;
      this.fileName = this.file.name.replace(/\.[^/.]+$/, "");
      this.$refs.fileName.focus();
      console.log(`fileType: ${this.fileType}`);
    },

    resetUpload: function() {
      this.file = null;
      this.progressVisible = false;
      this.fileName = "";
      this.description = null;
    },

    // Step 3
    submit: function() {
      // validation
      if (this.file == null) {
        this.errorString = "*Select a File and Give it a Name";
        return;
      }

      // preparation of Data
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("name", this.fileName);
      formData.append("type", this.fileType);
      formData.append("description", this.description);

      this.progressVisible = true;

      Repository.post(`/workflowDataTable/uploadFile`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: this.progress
      })
        .then(payload => {
          console.log("success");
          console.log(payload.data);
          this.responseData = payload.data;
          // this.$emit("onSave", payload.data);
          this.resetUpload();
          this.stage = 2;
        })
        .catch(payload => {
          console.log("failure!!");
          console.log(payload);
          this.resetUpload();
        });
    },

    progress: function(progressEvent) {
      this.percentageComplete = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },

    closeDialog: function() {
      this.$emit("closeDialog");
    },

    submitColumns: function() {
      this.responseData.data = null;
      this.$store
        .dispatch("workflow/dataTable/updateTableColumns", this.responseData)
        .then(() => {
          this.stage = 1;
          this.resetUpload();

          this.closeDialog();
        });
      console.log(this.responseData.header);
    }
  }
};
</script>
<style scoped lang="stylus">
.fillWidth{
  width 100%
}
</style>
