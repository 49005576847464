<template lang="pug">
  form-divider(style="width:100%") {{this.schema.label}}
</template>
<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";

export default {
  props: ["schema", "dataValue", "index","disabled"],
  components: {
    FormDivider
  },
  data: () => ({})
};
</script>
<style scoped lang="stylus"></style>
