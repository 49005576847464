import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const INSPECTION_FORM_API = "/inspectionForm";

// ----------------------- STATE ------------------------- //
const state = {
    inspectionForm_data: [],
    currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getInspectionForm: state => getField(state.inspectionForm_data[state.currentIndex]),

    getInspectionFormByID: state => theID => {
        return state.inspectionForm_data.find(item => item.id == theID)
    },

    getCurrentInspectionForm: state => {
        return state.inspectionForm_data[state.currentIndex];
    },

    getInspectionForms: state => {
        return state.inspectionForm_data;
    },

    getLatestSchema: state => {
        return state.inspectionForm_data[state.currentIndex].data[
        state.inspectionForm_data[state.currentIndex].data.length - 1
            ].schema;
    },

    getPreviousSchema: state => {
        return state.inspectionForm_data[state.currentIndex].data[
        state.inspectionForm_data[state.currentIndex].data.length - 2
            ].schema;
    },

    getInspectionFormByStatus: state => status => {
        let tempList = [];
        switch (status) {
            case "open":
                tempList = state.inspectionForm_data.filter(
                    item =>
                        item.closed_date_time == null && item.service_date_time == null
                );
                break;
            case "overdue":
                tempList = state.inspectionForm_data.filter(item => {
                    if (item.due_date_time != null) {
                        return (
                            new Date(item.due_date_time).getTime() < new Date().getTime()
                        );
                    } else {
                        return false;
                    }
                });
                break;
            case "resolved":
                tempList = state.inspectionForm_data.filter(
                    item => item.service_date_time != null
                );
                break;
            case "closed":
                tempList = state.inspectionForm_data.filter(
                    item => item.closed_date_time != null
                );
                break;
        }
        return tempList;
    },

    getVehicleFaults: state => vehicleID => {
        return state.inspectionForm_data.filter(item => item.vehicle_id == vehicleID);
    },

    getVehiclePendingFaults: state => vehicleID => {
        // todo: getVehiclePeningFaults
        return state.inspectionForm_data.filter(item => item.vehicle_id === vehicleID)
            .length;
    },

    getReportedInspectionFormsByUser: state => contactID => {
        return state.inspectionForm_data.filter(item => item.reported_by_id == contactID)
            .length;
    },
    getReportedAssignedByUser: state => contactID => {
        return state.inspectionForm_data.filter(item => item.assigned_to_id == contactID)
            .length;
    },

    getVehicleOpenFaults: state => vehicleID => {
        // todo: get Open Faults
        return state.inspectionForm_data.filter(item => item.vehicle_id === vehicleID)
            .length;
    },

    getOverdueInspectionForms: state => {
        return state.inspectionForm_data.filter(item => {
            if (item.due_date_time) {
                let theDate = new Date(item.due_date_time).getTime();
                let currentDate = new Date().getTime();
                return theDate < currentDate;
            } else {
                return false;
            }
        });
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateInspectionForm(state, payload) {
        updateField(state.inspectionForm_data[state.currentIndex], payload);
    },

    // called when form is saved
    setSchema(state, payload) {
        if (state.inspectionForm_data[state.currentIndex].data == null){
            state.inspectionForm_data[state.currentIndex].data = [];
        }
        state.inspectionForm_data[state.currentIndex].data.push(payload);
        state.inspectionForm_data[state.currentIndex].version = payload.version;
    },

    deleteImage(state, payload) {
        let index = state.inspectionForm_data[state.currentIndex].photos_id.findIndex(
            item => item === payload
        );
        state.inspectionForm_data[state.currentIndex].photos_id.splice(index, 1);
    },

    deleteDocument(state, payload) {
        let index = state.inspectionForm_data[state.currentIndex].documents_id.findIndex(
            item => item === payload
        );
        state.inspectionForm_data[state.currentIndex].documents_id.splice(index, 1);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.inspectionForm_data.findIndex(inspectionForm => inspectionForm.id == theID);
    },

    loadAllInspectionForms(state, payload) {
        state.inspectionForm_data = payload.data;
        state.currentIndex = 0;
    },

    loadVehicleInspectionForms(state, payload) {
        if (payload.data.length > 0) {
            state.currentIndex = 0;
        }
        state.inspectionForm_data = payload.data;
    },

    loadContactInspectionForms(state, payload) {
        if (payload.data.length > 0) {
            state.currentIndex = 0;
        }
        state.inspectionForm_data = payload.data;
    },

    addInspectionForm(state, payload) {
        state.inspectionForm_data.push(payload.data);
        state.currentIndex = state.inspectionForm_data.length - 1;
    },

    saveInspectionForm(state, payload) {
        state.inspectionForm_data[state.currentIndex] = payload.data;
    },

    fetchInspectionForm(state, payload) {
        if (state.currentIndex == -1) {
            state.inspectionForm_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.inspectionForm_data[state.currentIndex] = payload.data;
        }
    },

    deleteInspectionForm(state, payload) {
        state.currentIndex = state.inspectionForm_data.findIndex(
            inspectionForm => inspectionForm.id == payload.data.id
        );
        state.inspectionForm_data.splice(state.currentIndex, 1);
    },

    openInspectionForm(state, payload) {
        state.currentIndex = state.inspectionForm_data.findIndex(
            inspectionForm => inspectionForm.id == payload.data.id
        );
        state.inspectionForm_data[state.currentIndex] = payload.data;
    },

    closeInspectionForm(state, payload) {
        state.currentIndex = state.inspectionForm_data.findIndex(
            inspectionForm => inspectionForm.id == payload.data.id
        );
        state.inspectionForm_data[state.currentIndex] = payload.data;
    },

    cleanDataBeforeSaving(state) {
        state.inspectionForm_data[state.currentIndex].odometer = parseInt(
            state.inspectionForm_data[state.currentIndex].odometer
        );
        state.inspectionForm_data[state.currentIndex].vehicle = null;
        state.inspectionForm_data[state.currentIndex].reported_by = null;
        state.inspectionForm_data[state.currentIndex].assigned_to = null;
        state.inspectionForm_data[state.currentIndex].tags = null;
    },

    reset(state) {
        state.inspectionForm_data = []
        state.currentIndex = -1
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllInspectionForms({commit}) {
        try {
            commit("loadAllInspectionForms", await Repository.get(`${INSPECTION_FORM_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async loadVehicleInspectionForms({commit}, vehicle_id) {
        try {
            console.log(vehicle_id);
            commit(
                "loadVehicleInspectionForms",
                await Repository.get(`${INSPECTION_FORM_API}/vehicle/${vehicle_id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async loadContactInspectionForms({commit}, contact_id) {
        try {
            commit(
                "loadContactInspectionForms",
                await Repository.get(`${INSPECTION_FORM_API}/contact/${contact_id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async addInspectionForm({commit}) {
        await Repository.get(`${INSPECTION_FORM_API}/new`)
            .then(response => {
                commit("addInspectionForm", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveInspectionForm({state, commit}) {
        try {
            console.log(state.inspectionForm_data[state.currentIndex]);
            commit("cleanDataBeforeSaving");

            commit(
                "saveInspectionForm",
                await Repository.post(
                    `${INSPECTION_FORM_API}`,
                    state.inspectionForm_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchInspectionForm({commit}, payloadID) {
        try {
            commit("fetchInspectionForm", await Repository.get(`${INSPECTION_FORM_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteInspectionForm({commit}, payloadID) {
        try {
            commit(
                "deleteInspectionForm",
                await Repository.delete(`${INSPECTION_FORM_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async closeInspectionForm({commit}, payloadID) {
        try {
            commit(
                "closeInspectionForm",
                await Repository.get(`${INSPECTION_FORM_API}/close/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async openInspectionForm({commit}, payloadID) {
        try {
            commit(
                "openInspectionForm",
                await Repository.get(`${INSPECTION_FORM_API}/open/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchInspectionForms({commit}, searchTerm) {
        try {
            commit(
                "loadAllInspectionForms",
                await Repository.get(`${INSPECTION_FORM_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,

};
