<template lang="pug">
  v-data-table(
    :headers='headers',
    :items="getFilteredByVehicle",
    item-key="id",
    :loading='loading',
    :single-select="false"
    show-select
    @click:row="onDblClickRow"
  )
    v-progress-linear(slot='progress', height='2', :indeterminate='loading')

    template(v-slot:item.info="{item}")
      .SmallText
        div
          v-icon(small) access_time
          | &nbsp;&nbsp;{{item.date_time | moment("ddd, Do MMM YYYY, h:mm a") }}
        v-icon(small) business
        span(v-if="item.merchant") &nbsp;&nbsp;{{item.merchant.name}} -
        span(v-if="item.fuel_type") ({{item.fuel_type.name}})
        div
          span.grey--text by
          span.primary--text(v-if="item.reported_by") {{item.reported_by.first_name}} {{item.reported_by.last_name}}
          span.grey--text.text--darken-2 &nbsp;&nbsp;# {{item.reference}}

    template(v-slot:item.usage="{item}")
      div {{item.usage}}
      div
        span.DottedUnderline.grey--text {{(item.odometer/1000).toFixed(2)}} Km

    template(v-slot:item.total="{item}")
      div
        strong {{$store.state.profile.userInfo.account.region_settings.currency}} {{item.price}}
      div
        span.DottedUnderline.grey--text {{(item.price/item.volume).toFixed(2)}}/km

    //template(v-slot:item.fuelEconomy="{item}")
    //  div 88.8/mi

    template(v-slot:item.cost="{item}")
      div {{item.price}}/km

    template( v-slot:item.action="{ item }")
      v-row.mr-7(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";

export default {
  components: {
    MoreDropDownMenu
  },

  computed: {
    getFilteredByVehicle: function () {
      return this.$store.getters["fuel/getFuelByVehicleID"](this.$route.params.vehicleID)
    }
  },

  data: () => ({
    loading: false,
    selected: [],
    pagination: {
      sortBy: "time"
    },
    headers: [
      {
        text: "Info",
        value: "info",
        tooltip: "Fuel Information",
        align: "left"
      },
      {text: "Usage", value: "usage", tooltip: "Usage", align: "left"},
      {
        text: "Volume (L)",
        value: "volume",
        tooltip: "Volume of Fuel",
        align: "left"
      },
      {text: "Total (Ksh)", value: "total", tooltip: "Total", align: "left"},
      {
        text: "FE",
        value: "fuelEconomy",
        tooltip: "Fuel Economy",
        align: "left"
      },
      // { text: "Cost", value: "cost", tooltip: "Cost", align: "left" },
      {text: "Action", value: "action", tooltip: "Edit", align: "right"}
    ],

    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ]
  }),
  methods: {
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("fuel/setCurrentIndexByID", value.theObject.id);
          this.$router.push({
            name: "fuelDetailsForm",
            params: {fuelID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          confirm("Are you sure you want to delete this item?") &&
          this.$store.dispatch("fuel/deleteFuel", value.theObject.id);
          break;
      }
    },

    onDblClickRow: function (value) {
      this.$store.commit("fuel/setCurrentIndexByID", value.id);
      this.$router.push({
        name: "fuelOverview",
        params: {fuelID: value.id}
      }).catch(()=>{});
    }
  },

  mounted() {
    if (this.$store.state.fuel.fuel_data.length < 5) {
      // load Data
      this.$store.dispatch("fuel/loadAllFuels").then(() => {
      });
    }
  }
};
</script>

<style scoped>
.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;

  padding: 0px;
}

.SmallText {
  font-size: 11px;
}

.DottedUnderline {
  border-bottom: 1px dotted #999;
}
</style>
