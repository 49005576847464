<template lang="pug">
  #contentDashboard(v-if="showDashboard")
    v-layout.ma-2(justify-end)
      v-btn(text color="grey" small @click="onReset")
        v-icon.mr-2(:size="20") history
        | Reset
      v-btn(text color="grey" small @click="onSave")
        v-icon.mr-2(:size="20") save
        | Save
      v-dialog(v-model="dialog" scrollable max-width="400px" )
        template(v-slot:activator="{on}")
          v-btn(text color="grey" small v-on="on" @click="initPanelsVisibility")
            v-icon.mr-2(:size="20") add_box
            | Add
        v-card
          v-card-title() Select Chart
          v-divider()
          v-card-text(style="height: 300px")
            v-list-item-group()
              v-list-item.px-0(v-for="(panel,index) in panelSchema" :key="index" @click="switchVisibility(panel)" dense)
                v-layout.dashboardLine()
                  v-checkbox.mt-3(v-model="panel.visible" @click="switchVisibility(panel)")
                  v-list-item-content
                    v-list-item-title() {{panel.title}}
                    v-list-item-subtitle() {{panel.description}}
          v-divider()
          v-card-actions()
            v-btn(color="grey" text @click="resetDialogLayout") Reset
            v-spacer()
            v-btn(color="grey" text @click="dialog=false") Close
            v-btn(color="primary" @click="saveDialogLayout") Save
    grid-layout(
      v-if="this.layout.length >0"
      :layout.sync="layout"
      :col-num="12"
      :row-height="rowHeight"
      :is-draggable="true"
      :is-resizable="true"
      :vertical-compact="true"
      :use-css-transforms="true"
      :responsive="true"
    )
      grid-item(
        v-for="(item,index) in layout"
        :key="index"
        :static="item.static"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        drag-allow-from=".vue-draggable-handle"
        drag-ignore-from=".no-drag"
        @resize="resize"
        @move="move"
        @resized="resized"
        @moved="moved"
      )
        custom-drag-element(:text="item.i" :clientHeight="rowHeight * item.h + 10 * (item.h - 1)" :resize="resizeFlag" :item="item" @close="onClose")



</template>

<script>
import Repository from "@/repository";
import { GridLayout, GridItem } from "vue-grid-layout";
import CustomDragElement from "@/modules/dashboard/components/CustomDragElement.vue";
import { panelSchema } from "@/modules/dashboard/store/data";

export default {
  components: {
    GridLayout,
    GridItem,
    CustomDragElement,
  },
  data: () => ({
    dialog: false,
    isModified: false,

    draggable: true,
    resizable: true,
    index: 0,
    rowHeight: 150,
    checkbox1: false,

    layout: [],
    resizeFlag: false,
    showDashboard: false,
  }),

  mounted: function() {

    if (this.$store.state.profile.userInfo) {
      let dasboard_data = this.$store.getters["profile/getDashboard"];
      if (dasboard_data == undefined) {
        this.$store.dispatch("profile/getDefaultSettings").then(()=>{
          this.layout = JSON.parse(
              JSON.stringify(this.$store.state.profile.defaultSettings)
          );

          this.showDashboard = true;
          this.isModified = true;
          this.dialog = false;
          this.saveLayout();

        })
      }else{
        this.layout = JSON.parse(
            JSON.stringify(dasboard_data)
        );
        this.showDashboard = true;
      }


    }

    console.log(this.$store.state.profile.userInfo);
    this.initPanelsVisibility();
    this.index = this.layout.length;
  },

  computed: {
    panelSchema: function() {
      return panelSchema.sort((a, b) => a.title.localeCompare(b.title));
    },
  },

  methods: {
    initPanelsVisibility: function() {
      this.layout.forEach((layoutItem) => {
        let index = this.panelSchema.findIndex(
          (element) => element.name == layoutItem.i
        );
        if (index > -1) {
          this.panelSchema[index].visible = true;
        }
      });
    },

    switchVisibility: function(panel) {
      console.log(panel);
      panel.visible = !panel.visible;
      let index = this.layout.findIndex((item) => item.i === panel.name);
      if (panel.visible == true && index == -1) {
        // add the panel
        this.addPanel(panel.w, panel.h, panel.name);
      } else if (panel.visible == false && index > -1) {
        // remove panel
        this.layout.splice(index, 1); // delete
      }
    },
    move: function(i, newX, newY) {
      console.log("MOVE i=" + i + ", X=" + newX + ", Y=" + newY);
    },

    resize: function(i, newH, newW) {
      console.log("RESIZE i=" + i + ", H=" + newH + ", W=" + newW);
      this.resizeFlag = !this.resizeFlag;
    },

    moved: function(i, newX, newY) {
      console.log("### MOVED i=" + i + ", X=" + newX + ", Y=" + newY);
      this.isModified = true;
      this.$store.commit(
        "profile/setDashboard",
        JSON.parse(JSON.stringify(this.layout))
      );
    },

    resized: function(i, newH, newW, newHPx, newWPx) {
      console.log(
        "### RESIZED i=" +
          i +
          ", H=" +
          newH +
          ", W=" +
          newW +
          ", H(px)=" +
          newHPx +
          ", W(px)=" +
          newWPx
      );
      this.isModified = true;
      this.$store.commit(
        "profile/setDashboard",
        JSON.parse(JSON.stringify(this.layout))
      );
    },

    refresh: function(i, newWPx, newHPx) {
      this.layout.find((panel) => panel.i == i).clientWidth = newWPx;
      this.layout.find((panel) => panel.i == i).clientHeight = newHPx;
    },

    saveLayout: function() {
      if (this.isModified) {
        console.log("Saving the layout");
        Repository.post(
          "/user/settings",
          { dashboard: this.layout, custom_dashboard: this.$store.getters['profile/getCustomDashboards'] },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        ).then((response) => {
          console.log("Saved Dashboard Layout");
          this.layout = response.data.dashboard;
        });
      }
    },

    onSave: function() {
      this.isModified = true;
      this.saveLayout();
    },

    onReset: function() {
      confirm("Are you sure you want to reset the layout") &&
        this.$store.dispatch("profile/resetLayout").then(()=>{
          this.layout = this.$store.state.profile.userInfo.user_settings.dashboard;
        });
    },

    addPanel: function(width, height, name) {
      let xPos = 0;
      let yPos = 0;
      if (this.layout.length > 0) {
        let lastPanel = this.layout[this.layout.length - 1];
        xPos = lastPanel.x + lastPanel.w;
        yPos = lastPanel.y + lastPanel.h;

        console.log(`xPos:${xPos}, yPos:${yPos}`);

        // if more than 6 columns
        if (xPos <= 12 - width) {
          yPos = lastPanel.y + lastPanel.h;
        } else {
          xPos = 0;
          yPos = lastPanel.y;
        }
      }

      this.layout.push({
        x: xPos,
        y: yPos,
        w: width,
        h: height,
        i: name,
        static: false,
      });

      this.$store.commit(
        "profile/setDashboard",
        JSON.parse(JSON.stringify(this.layout))
      );
    },

    onClose: function(item) {
      let index = this.layout.findIndex((element) => element.i === item.i); // get index
      this.layout.splice(index, 1); // delete

      this.$store.commit(
        "profile/setDashboard",
        JSON.parse(JSON.stringify(this.layout))
      );
    },

    resetDialogLayout: function() {
      this.dialog = false;
      this.isModified = true;

      this.$store.commit(
        "profile/setDashboard",
        JSON.parse(JSON.stringify(this.layout))
      );
    },

    saveDialogLayout: function(event) {
      console.log(event);
      this.isModified = true;
      this.dialog = false;
      this.saveLayout();
    },
  },

  beforeDestroy: function() {
    this.saveLayout();
  },
};
</script>

<style scoped>
.dashboardLine {
  border-bottom: 1px solid #e8e8e8;
  height: 60px;
}

#contentDashboard {
  width: 100%;
}
.fillWindows {
  width: 100%;
  height: 100%;
}

.text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item.static {
  background: #cce;
  height: 100%;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
