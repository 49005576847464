<template lang="pug">
  .d-flex.flex-column(style="width:100%")
    table.tableDesigner()
      tr()
        th()

        th(v-for="(item,index) in schema.columns" :key="index")
          div(:style="getHeaderStyle(item)") {{item.label}}
      tr()
        td(vertical-align="top")
          v-checkbox(dense hide-details="true")
        td(v-for="(item,index) in schema.columns" :key="index")
          div(:style="getHeaderStyle(item)")
            template(v-if="item.type == 'Checkbox'" )
              v-checkbox(v-model="item.default" disabled)
            template(v-else-if="item.type == 'Select' && item.options")
              | {{item.options[0]}}
            template(v-else)
              | {{item.default}}
    .d-flex.justify-end.mt-3()
      v-btn.ml-2(outlined x-small color="primary" disabled) add
      v-btn.ml-2(outlined x-small color="primary" disabled) delete


</template>
<script>
export default {
  props: {
    schema: Object
  },
  data: () => ({}),

  mounted() {
    console.log(this.schema.columns);
  },
  methods:{
    getHeaderStyle:function(item){
      let theStyle = {'display':'flex','padding-left':'0px'};
      switch (item.align){
        case "left": theStyle["justify-content"] = "flex-start"; break;
        case "center": theStyle["justify-content"] = "center"; break;
        case "right": theStyle["justify-content"] = "flex-end"; break;
        default: theStyle["justify-content"] = "flex-start";
      }

      return theStyle;
    }
  }
};
</script>
<style scoped lang="stylus">
.tableDesigner{
  border-collapse: collapse;
  borde: none;
  border-bottom: 2px solid #999999;
  //border-radius 10px;
}

.tableDesigner td{
  border-bottom: 1px solid #E8E8E8;
  //border-left : 1px solid #E8E8E8;
  font-size 12px;
  padding: 0px 5px;
}
.tableDesigner th{
  //border: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  //border-left : 1px solid #E8E8E8;
  padding: 0px 5px;
  font-size 11px;

}

</style>
