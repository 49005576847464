<template lang="pug">
  form-divider(style="width:100%") {{schema.label}}
</template>
<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
export default {
  props: {
    schema: Object
  },

  components: {
    FormDivider
  }
};
</script>
<style scoped lang="stylus"></style>
