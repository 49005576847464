<template lang="pug">
  div
    panel-title-header(label="Record Details")
    div.mt-2.mb-2.pb-2
      table.DetailTable.body-2.fill-width
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3 Vehicle
          td
            vehicle-column-by-id(v-if="record.vehicle_id" :vehicle_id="record.vehicle_id")
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3 Workflow Form
          td
            div() {{workflowTemplate.name}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3 Workflow Description
          td
            div() {{workflowTemplate.description}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3 Transaction ID
          td
            div() {{record.tx_id}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3 Workflow Version
          td
            div() {{record.workflow_template_version}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3 Submitted On
          td {{getTime(record.submitted_at)}}
        //- tr
          td.goRight.grey--text.text--darken-1
            .mr-3 Updated On
          td {{getTime(record.updated_at)}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3 Transaction ID
          td {{record.tx_id}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3 Submitted By
          td
            contact-column-by-id(v-if="record.contact_id" :contact_id="record.contact_id")

</template>

<script>
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById";
import moment from "moment";
export default {
  props: {
    record: Object,
    workflowTemplate: Object,
  },
  components:{
    PanelTitleHeader,
    VehicleColumnById,
    ContactColumnById
  },
  methods:{
    getTime(submitted_time) {
      return moment(submitted_time).format("ddd, Do MMM YYYY, h:mm a")
    },
  }
}
</script>

<style scoped lang="stylus">
.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0
    padding 0

    padding-top 6px
    padding-bottom 8px
</style>