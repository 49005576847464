<template lang="pug">
  div() Cotntact Renewal Reminder

</template>
<script>
export default {
  data: () => ({})
};
</script>
<style scoped lang="stylus"></style>
