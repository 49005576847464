/*
  Default Setup Properties for the workflow module.

*/
import { uuid } from "vue-uuid";

const SET_PATH = "workflow/formBuilder/setCurrentProperty";
const GET_PATH = "workflow/formBuilder/getCurrentProperty";
const SET_ARRAY_ITEM = "workflow/formBuilder/setCurrentPropertyArrayItem";

const GET_SCHEMA_VALUE = "workflow/formBuilder/getSchemaValue";
const SET_SCHEMA_VALUE = "workflow/formBuilder/setSchemaValue";

let defaultTextProperties = function() {
  return {
    name: "TextField",
    id: uuid.v4(),
    value: null,
    label: null,
    hint: null,
    default: null,
    required: false,
    max_length: null,
    permissions:{read:[],write:[]},
    type: "text" // can either be "text" | "decimal" | "number" | "email" | "weblink"
  };
};

let defaultHeaderProperties = function() {
  return {
    name: "Header",
    id: "$_" + uuid.v4(),
    value: true,
    label: null,
    default: null,
    is_full_width: true,
    permissions:{read:[],write:[]}
  };
};

let defaultTextAreaProperties = function() {
  return {
    name: "TextArea",
    id: uuid.v4(),
    value: null,
    label: null,
    hint: null,
    default: null,
    required: false,
    max_length: null,
    auto_grow: false,
    is_full_width: false,
    permissions:{read:[],write:[]}
  };
};

let defaultDateProperties = function() {
  return {
    name: "Date",
    id: uuid.v4(),
    value: null,
    label: null,
    hint: null,
    date_format: "D/MM/YYYY",
    default: null,
    permissions:{read:[],write:[]}
  };
};

let defaultTimeProperties = function() {
  return {
    name: "Time",
    id: uuid.v4(),
    value: null,
    label: null,
    hint: null,
    time_format: "ampm", // either 'ampm' or '24hr'
    default: null,
    permissions:{read:[],write:[]}
  };
};

let defaultRadioProperties = function() {
  return {
    name: "Radio",
    id: uuid.v4(),
    value: null,
    label: null,
    hint: null,
    orientation: "column",
    options: ["option 1", "option 2"],
    required: true,
    permissions:{read:[],write:[]}
  };
};

let defaultCheckboxProperties = function() {
  return {
    name: "Checkbox",
    id: uuid.v4(),
    value: [],
    label: null,
    hint: null,
    orientation: "column",
    options: ["check A", "check B"],
    required: true,
    permissions:{read:[],write:[]}
  };
};
let defaultSelectProperties = function() {
  return {
    name: "Select",
    id: uuid.v4(),
    value: null,
    label: null,
    api: "http://localhost:4000/api/vehicle/",
    hint: null,
    options: ["rock", "paper", "scissors"], // other options [{text:"option A", value:4},{text:"option B", value:24}]
    item_text: ["name", "id"], // set to: "text"
    item_value: "id", // set to: "value"
    required: true,
    type: "Simple", // type are: Simple or Table or API
    multiple: false,
    permissions:{read:[],write:[]}
  };
};

let defaultTableProperties = function() {
  return {
    name: "Table",
    id: uuid.v4(),
    value: [],
    label: null,
    hint: null,
    default: null,
    max_rows: 20,
    columns: [
      { id: "first_column", label: "First Column", type: "TextField",align:"left",default:"sample data 1",details:{}},
      { id: "second_column", label: "Second Column", type: "TextField",align:"left",default:"sample data 2",details:{} },
      { id: "third_column", label: "Third Column", type: "TextField",align:"left",default:"sample data 3",details:{} },
    ],
    is_full_width: true,
    permissions:{read:[],write:[]}
  };
};

let defaultAttachProperties = function() {
  return {
    name: "Attach",
    id: uuid.v4(),
    value: null,
    label: null,
    hint: "Upload Attachment",
    type: "any",  //FileType.custom, FileType.any, FileType.audio, FileType.video, FileType.media, FileType.image, document
    preview_size: "medium", //small, medium, large
    multiple: false,
    is_full_width: false,
    permissions:{read:[],write:[]}
  };
};


let defaultImageProperties = function() {
  return {
    name: "Image",
    id: uuid.v4(),
    value: null,
    label: null,
    hint: "Image",
    type: "image",
    image_size: "small", //small, medium, large
    multiple: false,
    is_full_width: false,
    permissions:{read:[],write:[]}
  };
};

let defaultSignatureProperties = function() {
  return {
    name: "Signature",
    id: uuid.v4(),
    value: null,
    label: null,
    hint: "Draw your signature",
    default: null,
    permissions:{read:[],write:[]}
  };
};

let defaultDrawProperties = function() {
  return {
    name: "Draw",
    id: uuid.v4(),
    value: null,
    label: null,
    hint: "Draw",
    image_size: "medium",
    default: null,
    permissions:{read:[],write:[]}
  };
};

let defaultMapProperties = function() {
  return {
    name: "Map",
    id: uuid.v4(),
    value: null,
    label: null,
    hint: "Select Location",
    default: null,
    permissions:{read:[],write:[]}
  };
};

let defaultSwitchProperties = function() {
  return {
    name: "Switch",
    id: uuid.v4(),
    default: false,
    value: null,
    label: null,
    hint: null,
    type: "true_false",
    permissions:{read:[],write:[]}
  };
};

let defaultJahaziProperties = function() {
  return {
    name: "Jahazi",
    id: uuid.v4(),
    default: null,
    value: null,
    label: null,
    hint: null,
    multiple: false,
    type: "vehicle",
    permissions:{read:[],write:[]}
  };
};

let defaultRouteIDProperties = function() {
  return {
    name: "RouteID",
    id: "_route_id",
    value: null,
    default: null,
    permissions:{read:[],write:[]}
  };
};
let defaultRouteNameProperties = function() {
  return {
    name: "RouteName",
    id: "route_name",
    value: null,
    default: null,
    permissions:{read:[],write:[]}
  };
};

let defaultPlaceIDProperties = function(){
  return {
    name: "PlaceID",
    id: "_place_id",
    value: null,
    default: null,
    permissions:{read:[],write:[]}
  };
};

let defaultPlaceNameProperties = function(){
  return {
    name: "PlaceName",
    id: "place_name",
    value: null,
    default: null,
    label:null,
    permissions:{read:[],write:[]}
  };
};

let defaultReadOnlyProperties = function() {
  return {
    name: "ReadOnly",
    id: uuid.v4(),
    type:null,
    default: null,
    hint: null,
    permissions:{read:[],write:[]}
  }
}

let defaultDateTimeProperties = function() {
  return {
    name: "DateTime",
    id: uuid.v4(),
    value: null,
    label: null,
    hint: null,
    time_format: "ampm", // either 'ampm' or '24hr'
    default: null,
    permissions:{read:[],write:[]}
  }
}

let defaultApprovalProperties = function() {
  return {
    name: "Approval",
    id: uuid.v4(),
    default: null,
    value: null,
    label: null,
    hint: null,
    authorize_ids:[],
    permissions:{read:[],write:[]}
  };
};


export default {
  SET_PATH: SET_PATH,
  GET_PATH: GET_PATH,
  GET_SCHEMA_VALUE,
  SET_SCHEMA_VALUE,
  SET_ARRAY_ITEM,

  default_text_properties: defaultTextProperties,
  default_header_properties: defaultHeaderProperties,
  default_textarea_properties: defaultTextAreaProperties,
  default_date_properties: defaultDateProperties,
  default_time_properties: defaultTimeProperties,
  default_radio_properties: defaultRadioProperties,
  default_checkbox_properties: defaultCheckboxProperties,
  default_select_properties: defaultSelectProperties,
  default_table_properties: defaultTableProperties,
  default_attach_properties: defaultAttachProperties,
  default_image_properties: defaultImageProperties,
  default_signature_properties: defaultSignatureProperties,
  default_map_properties: defaultMapProperties,
  default_switch_properties: defaultSwitchProperties,
  default_jahazi_properties: defaultJahaziProperties,
  default_route_id_properties: defaultRouteIDProperties,
  default_route_name_properties: defaultRouteNameProperties,
  default_place_id_properties: defaultPlaceIDProperties,
  default_place_name_properties:defaultPlaceNameProperties,
  default_readonly_properties:defaultReadOnlyProperties,
  default_datetime_properties:defaultDateTimeProperties,
  default_approval_properties:defaultApprovalProperties,
  default_draw_properties:defaultDrawProperties,

};
