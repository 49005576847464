<template lang="pug">
  .columnSlot.pointer.d-flex.align-center()
    slot()
</template>

<script>
export default {
  name: "ColumnSlot"
}
</script>

<style scoped lang="stylus">
  .columnSlot {

  }
 .columnSlot:hover{
    background-color #dedede
  }
</style>