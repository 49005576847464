<template lang="pug">
  div(v-if="isLoaded" )
    pie-chart(style="height:140px; width:200px" :chartdata="chartData" :options="chartOption")
    .d-flex.caption.justify-center()
      .mx-3(:class="['pointer grey px-2 rounded white--text']" @click="onClick") {{isVolume ? 'volume':'price'}}

</template>

<script>

import PieChart from "@/modules/global/components/charts/PieChart";
import Repository from "@/repository";

export default {
  components:{
    PieChart
  },
  data:()=>({
    isLoaded: false,
    keyCounter: 0,
    isVolume: true,
    chartOption : {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 10,
          bottom: 0
        }
      }
    },
    chartData: {
      labels: [

      ],

      datasets: [
        {

        }
      ]
    },
  }),
  computed:{
    getMetrics() {
      return this.$store.getters["fuel/getMetrics"];
    }
  },
  methods:{
    loadData: async function() {
      this.isLoaded = false;
      let keys = [];
      let values = [];
      let bgColor = [];

      this.getMetrics.fuel_pies.forEach(item => {
        keys.push(item.vehicle)
        if(this.isVolume){
          values.push(item.volume)
        }else{
          values.push(item.price)
        }
        bgColor.push("#"+item.id.substr(1,6))
        console.log("#"+item.id.substr(1,6))
      })

      this.chartData.datasets[0].backgroundColor = bgColor;
      this.chartData.labels = keys;
      this.chartData.datasets[0].data = values;

      this.isLoaded = true;
    },
    onClick: function(){
      this.isVolume = !this.isVolume;
      this.loadData();
      this.keyCounter += 1;
    },
  },
  mounted() {
    this.loadData();
    this.keyCounter += 1;
  }

}
</script>


<style scoped lang="stylus">

</style>