<template lang="pug">
  .OverviewHeader.grey.lighten-4
    v-toolbar(dense flat)
      v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
      v-spacer
      v-menu(offset-y)
        template( #activator="{on}")
          v-btn.mx-3(v-on="on" color="primary" small outlined )
            | Actions
            v-icon.pl-1(small) arrow_drop_down
        v-list
          v-list-item(v-for="(item, index) in items2" :key="index" @click="")
            v-list-item-title {{ item.title }}
      v-btn.mx-3(color="primary" small @click="onEdit")
        v-icon.pr-2(small)
        | Edit Vehicle

    .HeaderBody.px-6.pt-4.pb-4
      v-layout(align-center)
        image-thumbnail.mr-3(:width="80" :height="80"  :src="currentVehicle.thumbnail_id" :url="getUrl")
        .HeaderText
          h2.headline.font-weight-bold.grey--text.text--darken-4 {{currentVehicle.name}}
          p.ma-0.pa-0.caption.font-weight-regular {{currentVehicle.type}} · {{ currentVehicle.make }} · {{currentVehicle.data.vin}} · {{currentVehicle.numberplate}}
          v-divider.mt-1
          .d-flex.align-center.caption(v-if="currentVehicle.odometer")
            p.ma-0.pa-0 {{(currentVehicle.odometer.meter/1000).toFixed(2)}}
              span.grey--text.text--darken-2 &nbsp;Km


            vehicle-status.px-4(:status="currentVehicle.status")

            .HorizontalAlignDiv()
              p.ma-0.pa-0 {{currentVehicle.group ? currentVehicle.group.name  : "No Group"}}


            .HorizontalAlignDiv()
              p.ma-0.pa-0 {{currentVehicle.operator ? currentVehicle.operator.first_name+' '+currentVehicle.operator.last_name  : "Unassigned"}}
                v-icon.pl-1(small) add_circle


      v-spacer
      .ServiceReminders.caption
        .Reminders.grey--text.text--darken-3 Service Reminders
        .ReminderUnits
          span.subheading.font-weight-bold 0 
          .VerticalDivider
          span.subheading.font-weight-bold 0

</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import VehicleStatus from "@/modules/global/components/others/Status";

export default {
  components: {
    ImageThumbnail,
    VehicleStatus
  },
  data: () => ({
    items: [
      {
        text: "Vehicle List",
        disabled: false,
        href: "vehicles"
      },
      {
        text: "Ford Ranger Truck",
        disabled: true,
        href: "breadcrumbs_link_1"
      }
    ],
    items2: [
      {title: "Click Me"},
      {title: "Click Me"},
      {title: "Click Me"},
      {title: "Click Me 2"}
    ]
  }),

  computed: {
    currentVehicle: {
      get() {
        return this.$store.getters["vehicles/getCurrentItem"];
      }
    },

    getUrl: function () {
      return `/main/vehicle/${this.currentVehicle.id}/new`;
    }
  },

  methods: {
    onAction: function (actionType) {
      switch (actionType) {
        case "editOdometer":
          console.log("editOdometer");
          break;
        case "group":
          console.log("group");
          break;
        case "assignments":
          console.log("assignments");
          break;
        default:
          console.log(actionType);
      }
    },

    onEdit: function () {
      console.log("heeelooo");
      this.$router.push({
        name: "vehicleNew",
        params: {vehicleID: this.currentVehicle.id}
      }).catch(()=>{});
    },

    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Vehicle List",
        disabled: false,
        to: {name: "vehicles"}
      });
      theArray.push({
        text: this.currentVehicle.name,
        disabled: true
      });
      return theArray;
    }
  }
};
</script>
<style lang="stylus" scoped>
.OverviewHeader {
  width: 100%;
  height: 150px;
}

.HeaderBody {
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeaderInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.HeaderText {
  display: flex;
  flex-direction: column;
}

.HeaderInfoButtons {
  font-size: 13px;
}

.HorizontalAlignDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  // border 1px solid grey
  padding: 0px 2px 0px 6px;
  height: 30px;
  margin-right: 12px;
}

.HorizontalAlignDiv:hover {
  cursor: pointer;
}

.ServiceReminders {
  width: 140px;
  height: 70px;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid #e5e5e5;
}

.Reminders {
  margin-bottom: 5px;
}

.VerticalDivider {
  border-left: 2px dotted #999999;
  margin: 0px 5px;
}

.ReminderUnits {
  display: flex;
}
</style>
