import { v4 as uuidv4 } from 'uuid';

export function makeItem(title, description, date, id = null) {
  id = id || uuidv4();
  return { id, title, description, date };
}

export function makeList(name, items = []) {
  const id = uuidv4();
  return { id, name, items };
}

export function makeData() {
  return [
    makeList("One", [makeItem("1 1"), makeItem("1 2"), makeItem("1 3")]),
    makeList("Two", [makeItem("2 1"), makeItem("2 2"), makeItem("2 3")]),
    makeList("Three", [makeItem("3 1"), makeItem("3 2"), makeItem("3 3")])
  ];
}
