import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

// renamed to purchaseOrderLevel on backend
const PURCHASEORDERSTATUS_API = "/purchaseOrderLevel";

// ----------------------- STATE ------------------------- //
const state = {
  purchaseorderstatus_data: [],
  currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getPurchaseOrderStatus: state =>
    getField(state.purchaseorderstatus_data[state.currentIndex]),

  getCurrentPurchaseOrderStatus: state => {
    return state.purchaseorderstatus_data[state.currentIndex];
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updatePurchaseOrderStatus(state, payload) {
    updateField(state.purchaseorderstatus_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.purchaseorderstatus_data.findIndex(
      purchaseorderstatus => purchaseorderstatus.id == theID
    );
  },

  loadAllPurchaseOrderStatus(state, payload) {
    state.purchaseorderstatus_data = payload.data;
    state.currentIndex = 0;
  },

  addPurchaseOrderStatus(state, payload) {
    state.purchaseorderstatus_data.push(payload.data);
    state.currentIndex = state.purchaseorderstatus_data.length - 1;
  },

  savePurchaseOrderStatus(state, payload) {
    state.purchaseorderstatus_data[state.currentIndex] = payload.data;
  },

  fetchPurchaseOrderStatus(state, payload) {
    if (state.currentIndex == -1) {
      state.purchaseorderstatus_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.purchaseorderstatus_data[state.currentIndex] = payload.data;
    }
  },

  deletePurchaseOrderStatus(state, payload) {
    state.currentIndex = state.purchaseorderstatus_data.findIndex(
      purchaseorderstatus => purchaseorderstatus.id == payload.data.id
    );
    state.purchaseorderstatus_data.splice(state.currentIndex, 1);
  },
  reset(state) {
    state.purchaseorderstatus_data = []
    state.currentIndex = -1
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllPurchaseOrderStatus({ commit }) {
    try {
      commit(
        "loadAllPurchaseOrderStatus",
        await Repository.get(`${PURCHASEORDERSTATUS_API}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async addPurchaseOrderStatus({ commit }) {
    await Repository.get(`${PURCHASEORDERSTATUS_API}/new`)
      .then(response => {
        commit("addPurchaseOrderStatus", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async savePurchaseOrderStatus({ state, commit }) {
    try {
      commit(
        "savePurchaseOrderStatus",
        await Repository.post(
          `${PURCHASEORDERSTATUS_API}`,
          state.purchaseorderstatus_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchPurchaseOrderStatus({ commit }, payloadID) {
    try {
      commit(
        "fetchPurchaseOrderStatus",
        await Repository.get(`${PURCHASEORDERSTATUS_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deletePurchaseOrderStatus({ commit }, payloadID) {
    try {
      commit(
        "deletePurchaseOrderStatus",
        await Repository.delete(`${PURCHASEORDERSTATUS_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchPurchaseOrderStatuss({ commit }, searchTerm) {
    try {
      commit(
        "loadAllPurchaseOrderStatus",
        await Repository.get(
          `${PURCHASEORDERSTATUS_API}/search?q=${searchTerm}`
        )
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
