import { render, staticRenderFns } from "./AlertBox.vue?vue&type=template&id=7e0845b7&scoped=true&lang=pug"
import script from "./AlertBox.vue?vue&type=script&lang=js"
export * from "./AlertBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e0845b7",
  null
  
)

export default component.exports