<template lang="pug">
  v-card(:height="clientHeight")
    keep-alive
      component(
            :is="item.i"
            :clientHeight="clientHeight"
            :resize="resize"
      )
    .vue-draggable-handle()
      .ml-1
        v-icon(color="grey lighten-2" :size="20") drag_indicator
    .close()
      v-icon(color="grey lighten-2" @click="onClose") close
</template>
<style>
#panel-bg {
  background-color: green;
}
.close {
  position: absolute;
  right: 5px;
  top: 0px;
  height: 20px;
  width: 20px;
  z-index: 998;
}
.vue-draggable-handle {
  position: absolute;
  z-index: 999;
  width: 10px;
  height: 10px;

  background-color: red;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
<script>
import BlankPanel from "@/modules/dashboard/components/panels/BlankPanel";
import VehicleStatusPanel from "@/modules/dashboard/components/panels/VehicleStatusPanel";
import BarChartPanel from "@/modules/dashboard/components/panels/BarChartPanel";
import DonutChartPanel from "@/modules/dashboard/components/panels/DonutChartPanel";
import LineChartPanel from "@/modules/dashboard/components/panels/LineChartPanel";
import VehicleAssignmentPanel from "@/modules/dashboard/components/panels/VehicleAssignmentPanel";
import ServiceReminderPanel from "@/modules/dashboard/components/panels/ServiceReminderPanel";
// import CostPerKmPanel from "@/modules/dashboard/components/panels/CostPerKmPanel";
import FuelCostsPanel from "@/modules/dashboard/components/panels/FuelCostsPanel";
import TotalCostTrendPanel from "@/modules/dashboard/components/panels/TotalCostTrendPanel";
import RecentCommentsPanel from "@/modules/dashboard/components/panels/RecentCommentsPanel";
import RecentNotificationsPanel from "@/modules/dashboard/components/panels/RecentNotificationsPanel";
import MapPanel from "@/modules/dashboard/components/panels/MapPanel";
import TotalCostPanel from "@/modules/dashboard/components/panels/TotalCostPanel"
import OpenIssuesPanel from "@/modules/dashboard/components/panels/OpenIssuesPanel"
import VehicleRenewalReminderPanel from "@/modules/dashboard/components/panels/VehicleRenewalReminderPanel"
import ContactRenewalReminderPanel from "@/modules/dashboard/components/panels/ContactRenewalReminderPanel"
import VehicleFaultsPanel from "@/modules/dashboard/components/panels/VehicleFaultsPanel"
import InspectionItemFailureRatePanel from "@/modules/dashboard/components/panels/InspectionItemFailureRatePanel"
import ServiceCostPanel from "@/modules/dashboard/components/panels/ServiceCostPanel"
import InspectionSubmissionPanel from "@/modules/dashboard/components/panels/InspectionSubmissionPanel"
import CriticalFaultsPanel from "@/modules/dashboard/components/panels/CriticalFaultsPanel"
import OverdueInspectionsPanel from "@/modules/dashboard/components/panels/OverdueInspectionsPanel"
import InspectionSummaryPanel from "@/modules/dashboard/components/panels/InspectionSummaryPanel"
import PieChartPanel from "@/modules/dashboard/components/panels/PieChartPanel";
import HorizontalBarChartPanel from "@/modules/dashboard/components/panels/HorizonalBarChartPanel"
import PlacesPanel from "@/modules/dashboard/components/panels/PlacesPanel"
import DailyDistanceTraveled from "@/modules/dashboard/components/panels/DailyDistanceTraveled";
import FormSubmissions from "@/modules/dashboard/components/panels/FormSubmissions";
import KeyElementsPanel from "@/modules/dashboard/components/panels/KeyElementsPanel";
import WorkflowsPanel from "@/modules/dashboard/components/panels/WorkflowsPanel";
import PlaceAuthorsPanel from "@/modules/dashboard/components/panels/PlaceAuthorsPanel";
import UsersActivityPanel from "@/modules/dashboard/components/panels/UsersActivityPanel.vue";
import WeatherPanel from "@/modules/dashboard/components/panels/WeatherPanel.vue";
import ActivityEventsPanel from "@/modules/dashboard/components/panels/ActivityEventsPanel.vue";


// import EfficiencyChart from "@/modules/dashboard/components/panels/EfficiencyChart";
// import DriverLeaderBoard from "@/modules/dashboard/components/panels/DriverLeaderBoard";
// import HeatMap from "@/modules/dashboard/components/panels/HeatMap";
// import DonutCharts from "@/modules/dashboard/components/panels/DonutCharts";
// import VehicleLife from "@/modules/dashboard/components/panels/VehicleLife";
// import ReminderAlert from "@/modules/dashboard/components/panels/ReminderAlert";

export default {
  components: {
    BlankPanel,
    VehicleStatusPanel,
    BarChartPanel,
    HorizontalBarChartPanel,
    DonutChartPanel,
    LineChartPanel,
    VehicleAssignmentPanel,
    DailyDistanceTraveled,
    FormSubmissions,
    FuelCostsPanel,
    TotalCostTrendPanel,
    ServiceReminderPanel,
    RecentCommentsPanel,
    RecentNotificationsPanel,
    MapPanel,
    TotalCostPanel,
    OpenIssuesPanel,
    VehicleRenewalReminderPanel,
    ContactRenewalReminderPanel,
    VehicleFaultsPanel,
    InspectionItemFailureRatePanel,
    ServiceCostPanel,
    InspectionSubmissionPanel,
    CriticalFaultsPanel,
    OverdueInspectionsPanel,
    InspectionSummaryPanel,
    PieChartPanel,
    PlacesPanel,
    KeyElementsPanel,
    WorkflowsPanel,
    PlaceAuthorsPanel,
    UsersActivityPanel,
    WeatherPanel,
    ActivityEventsPanel,
  },

  name: "CustomDragElement",

  props: {
    text: {
      type: String,
      default: "x"
    },
    clientHeight: {
      type: Number,
      required: true
    },
    item: Object,
    resize: Boolean
  },
  data: function() {
    return {};
  },
  mounted: function() {
    console.log("### " + this.item.i + " ready!");
  },

  methods: {
    onClose: function() {
      this.$emit("close", this.item);
    }
  }
};
</script>
