<template lang="pug">
  div
    div
      v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
      v-text-field(v-model="selectHint" label="Description")
      .subheading.mb-0() Select Upload Type
      v-radio-group.mt-0(v-model="selectType")
        v-radio(label="Any" value="any")
        v-radio(label="Document" value="document")
        v-radio(label="Image Type" value="image")
        v-radio(label="Video Type" value="video")
        v-radio(label="Audio Type" value="audio")
        v-radio(label="Media Type" value="media")

      v-checkbox(v-model="selectRequired" label="Required")
    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        showAdvancedProperties(:showAdvanced="showAdvanced" )
      

      div(v-show="showAdvanced")
        v-checkbox(v-model="selectMultiple" label="Allow Multiple Attachements")

        .subheading.mb-0() Preview Size
          v-radio-group.mt-0(v-model="selectPreviewSize")
            v-radio(label="Small" value="small")
            v-radio(label="Medium" value="medium")
            v-radio(label="Large" value="large")
        v-checkbox(v-model="selectCommentOnFail" label="Must comment when there is no attachment")
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)
</template>
<script>
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";
import {inspectionPropertiesMixin} from "@/modules/inspections/components/formDesigner/properties/inspectionPropertiesMixin";
export default {
  mixins:[inspectionPropertiesMixin],

  computed: {
    selectMultiple: {
      get: function() {
        return this.properties.multiple;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "multiple",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "is_full_width",
          value: value
        });
      }
    },

    selectPreviewSize: {
      get: function() {
        return this.properties.preview_size;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "preview_size",
          value: value
        });
      }
    }
  },
};
</script>
<style scoped lang="stylus"></style>
