<template lang="pug">
  #blankcontent()
    .panelTitle() Inspection Submission
    v-divider.mx-2()
    line-chart(:style="{height:`${clientHeight-40}px`, width: `100%`}" :chartdata="getDataSets" :options="chartOption")

</template>
<script>
import LineChart from "@/modules/global/components/charts/LineChart";
export default {
  components: {
    LineChart
  },
  props: {
    clientHeight: Number
  },
  mounted() {},
  data: () => ({
    chartOption: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              maxTicksLimit: 5,
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ]
      },

      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 20,
          bottom: 0
        }
      }
    }
  }),
  computed: {
    getDataSets: function() {
      
      let obj = {
        labels: ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"],
        datasets: [
          {
            label: "Days",
            data: [50, 80, 40, 30, 90, 40, 20]
            
          }
        ]
      };
      obj.datasets[0].borderColor = this.$vuetify.theme.themes.light.primary;

      return obj;
    }
  },

};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight bold
  padding: 5px 30px;
  height:30px;
  display: flex;
  width: 100%
  justify-content: space-between;
}

.lineChartPanel {
  height: calc(100% - 45px);
}
</style>
