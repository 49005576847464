<template lang="pug">
div
  v-container.pt-4(grid-list-xl)
    v-layout(row wrap)
      v-flex(xs12)
        v-card.my-3(color="white")
          overview-information-panel(:items="weight.items", :title="weight.title", @edit="onEdit")

</template>
<script>
import OverviewInformationPanel from "@/modules/global/components/others/OverviewInformationPanel";
export default {
  components: {
    OverviewInformationPanel
  },
  data: () => ({
    weight: {
      title: "Loan/Lease Details",
      items: [
        { label: "Loan/Lease Monthly Payment", value: "" },
        { label: "Loan/Lease Start Date", value: "" },
        { label: "Loan/Lease End Date", value: "" },
        { label: "Loan/Lease Amount Financed", value: "" },
        { label: "Residual Value", value: "" },
        { label: "Vendor/Customer", value: "" },
        { label: "Loan/Lease Account Number", value: "" },
        { label: "Loan/Lease Notes	", value: "" }
      ]
    }
  }),

  methods: {
    onEdit: function(titlename) {
      console.log(titlename);
    }
  }
};
</script>
<style lang="stylus" scoped></style>
