import Repository from "@/repository";
import moment from "moment";

const SCHEDULE_API = "/assignment";

// ----------------------- STATE ------------------------- //
const state = {
    schedule_data: [],
    loading: false,
    tooltip: {
        visible: false,
        xPos: 0,
        yPos: 0
    },
    layerIndex: -1,


    startHourDate: new Date(
        moment()
            .startOf("hour")
            .format("HH:mm YYYY, M, D")
    ),

    startDayDate: new Date(
        moment()
            .startOf("day")
            .format("YYYY, M, D")
    ), // create the start date based on the week

    startWeekDate: new Date( // create the start date based on the week
        moment()
            .startOf("week")
            .format("YYYY, M, D")
    ),

    startMonthDate: new Date( // create the start date based on the week
        moment()
            .startOf("month") //.startOf("week")
            .format("YYYY, M, D")
    ),

    startYearDate: new Date( // create the start date based on the week
        moment()
            .startOf("month") //.startOf("week")
            .subtract(1, "month")
            .format("YYYY, M, D")
    ),

    //                                     DAY                        WEEK                    WEEK HOURLY        |    Month           |    Year     |
    timeSplit: 3600000, //    |  900000                |   : 900000                |       3600000          |  3600000 * 6      | 3600000 * 24
    steps: 7, //                 |  24                    |   : days  = 7             |        7               |    30              |     365
    subSteps: 24, //               |  4                     |   : quarter Hours = 96    |        24              |    4               |      1
    mode: "week", //                |  "day"                 |   : "week"                |        "week"          |   "month"          |    "year"

    // temporary values for storage
    selectedAssignment: {}
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getData: state => {
        return state.schedule_data;
    },

    getStartDate: state => {
        let startDate = new Date();
        switch (state.mode) {
            case "year":
                startDate = state.startYearDate;
                break;
            case "month":
                startDate = state.startMonthDate;
                break;
            case "week":
                startDate = state.startWeekDate;
                break;
            case "day":
                startDate = state.startDayDate;
                break;
            case "hour":
                startDate = state.startHourDate;
                break;
        }
        return startDate;
    },

    getSelectedLayer: state => {
        return state.schedule_data[state.layerIndex];
    },

    getTimeSplit: state => {
        return state.timeSplit;
    },

    getSteps: state => {
        return state.steps;
    },

    getSubSteps: state => {
        return state.subSteps;
    },

    getMode: state => {
        return state.mode;
    },

    getCurrentSchedule: state => {
        return state.schedule_data[state.currentIndex];
    },

    getAssignmentById: state => id => {
        let foundIndex;
        let tempAssignment = null;
        for (var i = 0; i < state.schedule_data; i++) {
            let temp = state.schedule_data[i];
            foundIndex = temp.assignments.findIndex(item => {
                item.id == id;
            });
            tempAssignment = temp[foundIndex];
        }
        return tempAssignment;
    },

    getSelectedAssignment: state => {
        return state.selectedAssignment;
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {

    setMode(state, payload) {
        // console.log(payload);
        switch (payload) {
            case "year":
                state.mode = "year";
                state.subSteps = 24;
                state.steps = 365;
                state.timeSplit = 3600000;
                break;
            case "month":
                state.mode = "month";
                state.subSteps = 24;
                state.steps = 30;
                state.timeSplit = 3600000;
                break;
            case "week":
                state.mode = "week";
                state.subSteps = 24;
                state.steps = 7;
                state.timeSplit = 3600000;
                break;
            case "day":
                state.mode = "day";
                state.subSteps = 4;
                state.steps = 24;
                state.timeSplit = 900000;
                break;
            case "hour":
                state.mode = "hour";
                state.subSteps = 5;
                state.steps = 12;
                state.timeSplit = 60000;
                break;
        }
    },

    repositionAssignment(state, payload) {
        console.log(payload.assignment.id);

        let tempAssignment = null;
        // copy assignment and remove
        for (var i = 0; i < state.schedule_data.length; i++) {
            for (var j = 0; j < state.schedule_data[i].assignments.length; j++) {
                if (state.schedule_data[i].assignments[j].id == payload.assignment.id) {
                    tempAssignment = state.schedule_data[i].assignments[j];
                    state.schedule_data[i].assignments.splice(j, 1);
                    break;
                }
            }
        }

        if (tempAssignment) {
            // add asignment to layer
            tempAssignment.unit_start = payload.unitStart;
            tempAssignment.unit_end = payload.unitEnd;
            tempAssignment.during.tz_start =
                payload.startDate.getTime() +
                tempAssignment.unit_start * state.timeSplit; //timesplit = 3600000 = 1 Hours unit seconds * 60 Minutes
            tempAssignment.during.tz_end =
                payload.startDate.getTime() + tempAssignment.unit_end * state.timeSplit; //timesplit = 3600000 = 1 Hours unit seconds * 60 Minutes
            // console.log(tempAssignment.unit_start);
            // console.log(tempAssignment.unit_end);
            state.schedule_data[payload.layerIndex].assignments.push(tempAssignment);
        }
    },

    next(state, payload) {
        switch (state.mode) {
            case "year":
                state.startYearDate = new Date(
                    moment(payload)
                        .add(1, "year")
                        .format("YYYY, M, D")
                );
                break;
            case "month":
                state.startMonthDate = new Date(
                    moment(payload)
                        .add(1, "month")
                        .format("YYYY, M, D")
                );
                break;
            case "week":
                state.startWeekDate = new Date(
                    moment(payload)
                        .add(1, "week")
                        .format("YYYY, M, D")
                );
                break;
            case "day":
                state.startDayDate = new Date(
                    moment(payload)
                        .add(1, "day")
                        .format("YYYY, M, D")
                );
                break;
            case "hour":
                state.startHourDate = new Date(
                    moment(payload)
                        .add(1, "hour")
                        .format("HH:mm  YYYY, M, D")
                );
                break;
        }
    },

    before(state, payload) {
        switch (state.mode) {
            case "year":
                state.startYearDate = new Date(
                    moment(payload)
                        .subtract(1, "year")
                        .format("YYYY, M, D")
                );
                break;
            case "month":
                state.startMonthDate = new Date(
                    moment(payload)
                        .subtract(1, "month")
                        .format("YYYY, M, D")
                );
                break;
            case "week":
                state.startWeekDate = new Date(
                    moment(payload)
                        .subtract(1, "week")
                        .format("YYYY, M, D")
                );
                break;
            case "day":
                state.startDayDate = new Date(
                    moment(payload)
                        .subtract(1, "day")
                        .format("YYYY, M, D")
                );
                break;
            case "hour":
                console.log(payload)
                state.startHourDate = new Date(
                    moment(payload)
                        .subtract(1, "hour")
                        .format("HH:mm  YYYY, M, D")
                );
                break;
        }
    },

    resetDate(state) {
        state.startHourDate = new Date(
            moment()
                .startOf("hour")
                .format("YYYY, M, D HH:mm")
        ); // create the start date based on the week

        state.startDayDate = new Date(
            moment()
                .startOf("day")
                .format("YYYY, M, D")
        ); // create the start date based on the week

        state.startWeekDate = new Date( // create the start date based on the week
            moment()
                .startOf("week")
                .format("YYYY, M, D")
        );

        state.startMonthDate = new Date( // create the start date based on the week
            moment()
                .startOf("month") //.startOf("week")
                .format("YYYY, M, D")
        );

        state.startYearDate = new Date( // create the start date based on the week
            moment()
                .startOf("month") //.startOf("week")
                .subtract(1, "month")
                .format("YYYY, M, D")
        );
    },

    setDate(state, payload) {
        let theDate = new Date(payload);
        switch (state.mode) {
            case "hour":
                state.startHourDate = new Date(
                    moment(theDate)
                        .startOf("hour")
                        .format("HH:mm YYYY, M, D")
                );
                break;
            case "day":
                state.startDayDate = theDate;
                break;
            case "week":
                state.startWeekDate = new Date(
                    moment(theDate)
                        .startOf("week")
                        .format("YYYY, M, D")
                );
                break;
            case "month":
                state.startMonthDate = new Date(
                    moment(theDate)
                        .startOf("month")
                        .format("YYYY, M, D")
                );
                break;
            case "year":
                state.startYearDate = new Date(
                    moment(theDate)
                        .startOf("month")
                        .format("YYYY, M, D")
                );
                break;
        }
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.schedule_data.findIndex(
            schedule => schedule.id == theID
        );
    },

    loadAllSchedules(state, payload) {
        state.loading = false;
        let vehicleList = JSON.parse(JSON.stringify(payload.vehicles));
        for (var k = 0; k < vehicleList.length; k++) {
            vehicleList[k].assignments = [];
        }

        for (var i = 0; i < payload.response.data.length; i++) {
            for (var j = 0; j < vehicleList.length; j++) {
                if (vehicleList[j].id === payload.response.data[i].vehicle_id) {
                    vehicleList[j].assignments.push(payload.response.data[i]);
                }
            }
        }

        state.schedule_data = vehicleList;
    },

    addSchedule(state, payload) {
        state.schedule_data.push(payload.data);
        state.currentIndex = state.schedule_data.length - 1;
    },

    saveSchedule(state, payload) {
        state.loading = false;
        // console.log(payload.data);
        // remove the assignement object
        let vehicleLayerIndex = -1;
        for (var i = 0; i < state.schedule_data.length; i++) {
            // geting the layer

            if (state.schedule_data[i].id == payload.data.vehicle_id) {
                vehicleLayerIndex = i;
            }

            // looping to remove  the assigned object
            for (var j = 0; j < state.schedule_data[i].assignments.length; j++) {
                if (state.schedule_data[i].assignments[j].id == payload.data.id) {
                    state.schedule_data[i].assignments.splice(j, 1);
                    break;
                }
            }
        }

        // add the object to the found layer
        if (vehicleLayerIndex > -1) {
            state.schedule_data[vehicleLayerIndex].assignments.push(payload.data);
        }
    },

    fetchSchedule(state, payload) {
        if (state.currentIndex == -1) {
            state.schedule_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.schedule_data[state.currentIndex] = payload.data;
        }
    },

    deleteSchedule(state, payload) {
        state.loading = false;
        // looping to remove  the assigned object
        for (var i = 0; i < state.schedule_data.length; i++) {
            for (var j = 0; j < state.schedule_data[i].assignments.length; j++) {
                if (state.schedule_data[i].assignments[j].id == payload.data.id) {
                    state.schedule_data[i].assignments.splice(j, 1);
                    break;
                }
            }
        }
    },

    // -------------------- Functions for Saving Dialog Window Information ---------------------//
    setLayerIndex(state, payload) {
        state.layerIndex = payload;
    },
    setAssignment(state, payload) {
        state.selectedAssignment = payload;
        state.selectedAssignment.during.start = new Date(
            state.selectedAssignment.during.tz_start
        );
        state.selectedAssignment.during.end = new Date(
            state.selectedAssignment.during.tz_end
        );
    },

    setContact(state, payload) {
        state.selectedAssignment.contact_id = payload;
        state.selectedAssignment.thumbnail = null;
    },

    setTzStart(state, payload) {
        state.selectedAssignment.during.tz_start = payload;
        state.selectedAssignment.during.start = new Date(
            state.selectedAssignment.during.tz_start
        );
    },

    setTzEnd(state, payload) {
        state.selectedAssignment.during.tz_end = payload;
        state.selectedAssignment.during.end = new Date(
            state.selectedAssignment.during.tz_end
        );
    },

    setDescription(state, payload) {
        state.selectedAssignment.description = payload;
    },

    setEmailNotification(state, payload) {
        state.selectedAssignment.email_notification = payload;
    },
    setSmsNotification(state, payload) {
        state.selectedAssignment.sms_notification = payload;
    },
    setStartOdometer(state, payload) {
        state.selectedAssignment.start_odometer =
            Number(payload) != 0 ? Number(payload) : null;
    },

    setEndOdometer(state, payload) {
        state.selectedAssignment.end_odometer =
            Number(payload) != 0 ? Number(payload) : null;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setTooltip(state, payload) {
        state.tooltip = payload;
    },
    reset(state) {
        state.schedule_data = []
        state.loading = false
        state.tooltip = {
            visible: false,
            xPos: 0,
            yPos: 0
        }
        state.layerIndex = -1

        state.startDayDate = new Date(
            moment()
                .startOf("day")
                .format("YYYY, M, D")
        ) // create the start date based on the week

        state.startWeekDate = new Date( // create the start date based on the week
            moment()
                .startOf("week")
                .format("YYYY, M, D")
        )

        state.startMonthDate = new Date( // create the start date based on the week
            moment()
                .startOf("month") //.startOf("week")
                .format("YYYY, M, D")
        )

        state.startYearDate = new Date( // create the start date based on the week
            moment()
                .startOf("month") //.startOf("week")
                .subtract(1, "month")
                .format("YYYY, M, D")
        )

        //                                     DAY                        WEEK                    WEEK HOURLY        |    Month           |    Year     |
        state.timeSplit = 3600000 //    |  900000                |   : 900000                |       3600000          |  3600000 * 6      | 3600000 * 24
        state.steps = 7 //                 |  24                    |   : days  = 7             |        7               |    30              |     365
        state.subSteps = 24 //               |  4                     |   : quarter Hours = 96    |        24              |    4               |      1
        state.mode = "week" //                |  "day"                 |   : "week"                |        "week"          |   "month"          |    "year"

        // temporary values for storage
        state.selectedAssignment = {}

    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllSchedules({commit, rootState}) {
        commit("setLoading", true);
        try {
            await Repository.get(`${SCHEDULE_API}`).then(response => {
                commit("loadAllSchedules", {
                    response: response,
                    vehicles: rootState.vehicles.all
                });
            });
        } catch (error) {
            commit("setLoading", false);
            console.log(error);
        }
    },

    async addSchedule({commit}, payload) {
        commit("setLoading", true);
        await Repository.post(`${SCHEDULE_API}`, payload)
            .then(response => {
                commit("saveSchedule", response);
            })
            .catch(error => {
                commit("setLoading", false);
                console.log(error.response.data);
            });
    },

    async updateSchedule({state, commit}) {
        commit("setLoading", true);
        // insert start and end dates
        await Repository.post(`${SCHEDULE_API}`, state.selectedAssignment)
            .then(response => {
                commit("saveSchedule", response);
            })
            .catch(error => {
                commit("setLoading", false);
                console.log(error.response.data);
                throw new Error(
                    "Assignment start time or end time conflicting with another assignment"
                );
            });
    },

    async moveSchedule({state, commit}, payload) {
        commit("setLoading", true);
        let tempAssignment = JSON.parse(JSON.stringify(payload.assignment));
        tempAssignment.during.tz_start =
            payload.startDate.getTime() + payload.unitStart * state.timeSplit; //timesplit = 3600000 = 1 Hours unit seconds * 60 Minutes
        tempAssignment.during.tz_end =
            payload.startDate.getTime() + payload.unitEnd * state.timeSplit; //timesplit = 3600000 = 1 Hours unit seconds * 60 Minutes
        tempAssignment.during.start = new Date(tempAssignment.during.tz_start);
        tempAssignment.during.end = new Date(tempAssignment.during.tz_end);
        tempAssignment.vehicle_id = payload.vehicleID;

        if (payload.isCopy) {
            tempAssignment.id = null;
        }

        await Repository.post(`${SCHEDULE_API}`, tempAssignment)
            .then(response => {
                commit("saveSchedule", response);
            })
            .catch(error => {
                commit("setLoading", false);
                console.log(error.response.data);
                throw new Error(
                    "Assignment start time or end time conflicting with another assignment"
                );
            });
    },

    async fetchSchedule({commit}, payloadID) {
        try {
            commit(
                "fetchSchedule",
                await Repository.get(`${SCHEDULE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteSchedule({state, commit}) {
        commit("setLoading", true);
        try {
            commit(
                "deleteSchedule",
                await Repository.delete(
                    `${SCHEDULE_API}/${state.selectedAssignment.id}`
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchSchedules({commit}, searchTerm) {
        try {
            commit(
                "loadAllSchedules",
                await Repository.get(`${SCHEDULE_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
