<template lang="pug">
  #blankcontent()
    .panelTitle() Overdue Inspections
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-space-around.align-center.fill-height()
          .d-flex.flex-column.align-center()
            .tickerTitle.secondary--text() {{OverDueInspections}}
            .tickerCaption.grey--text.text--darken-1()  OverDue
          .d-flex.flex-column.align-center()
            .tickerTitle.primary--text() {{OfTotalScheduled}} %
            .tickerCaption.grey--text.text--darken-1() Of Total Inspections


</template>
<script>
export default {
  data: () => ({}),
  mounted() {},

  computed: {
    OverDueInspections() {
      return this.$store.getters["dashboard/getOverDueInspections"];
    },
    OfTotalScheduled() {
      return this.$store.getters["dashboard/getOfTotalScheduled"];
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.tickerTitle {
  font-size: 45px;
}

.tickerCaption {
  margin-top: -10px;
  font-size: 12px;
}
</style>
