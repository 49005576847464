<template lang="pug">
  #blankcontent()
    .panelTitle() Workflows
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-space-around.align-center.fill-height()
          .d-flex.flex-column.align-center(v-for="(item,index) in items" :key="index")
            .tickerTitle.primary--text() {{item.value}}
            .tickerCaption.grey--text.text--darken-1() {{item.key}}

</template>
<script>
import axios from "axios"

export default {
  data: () => ({
    items : []

  }),
  mounted() {
    axios.get("/api/dashboard/totalWorkflows").then(response =>{
      this.items = response.data;
    })
  },

};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.tickerTitle {
  font-size: 45px;
}

.tickerCaption {
  margin-top: -10px;
  font-size: 12px;
}
</style>
