<template lang="pug">
div
  v-divider
  v-container(fluid)
    v-layout.my-2(row, wrap)
      div
        h2.mx-6 Contact Renewal Report
        generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer
      .text-xs-center
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color="primary", dark, v-on="on")
              | Export
              v-icon(dark) keyboard_arrow_down
          v-list-item-group
            v-list-item(
              v-for="item in items",
              :key="item.title",
              dense,
              @click="onExport(item.value)"
            )
              v-list-item-title
                v-layout(align-center)
                  v-icon.mr-2(:size="20") {{ item.icon }}
                  .subtitle-2.mx-2 {{ item.title }}
      v-btn.ma-2.mr-5(small, color="primary", dark, @click="print")
        v-icon.mr-2 print
        | Print
  v-divider
  .mx-4.my-2
    vehicle-filter-dialog
    v-menu(:close-on-content-click="false")
      template(v-slot:activator="{ on }")
        v-btn.mx-2(outlined, color="primary", v-on="on")
          | Columns
          v-icon(dark) keyboard_arrow_down
      .reportHeaderList
        v-list-item-group
          v-list(dense)
            v-list-item(v-for="(item, index) in headers", :key="index")
              template(v-slot:default="{ active }")
                v-list-item-action
                  v-checkbox(v-model="item.visible", color="primary")
                v-list-item-content(@click="item.visible = !item.visible")
                  v-list-item-title.body-2 {{ item.text }}

  v-divider
  .grey.lighten-4.py-3
  v-divider
  div(v-if="dataItems.length > 0")
    v-data-table.elevation-1(
      :headers="getHeaders",
      :items="dataItems",
      item-key="id"
    )
      template(v-slot:item.contacts="{ item }")
        div(v-for="(item, index) in item.contacts", :key="index") {{ item.first_name }} {{ item.last_name }}
      template(v-slot:item.job="{ item }")
        div {{ formatdate(item.job.next_run_at) }}
        timeago.caption.grey--text(:datetime="item.job.next_run_at")

      template(v-slot:item.contact_renewal_types="{ item }")
        span.mr-2(
          v-for="(item, index) in item.contact_renewal_types",
          :key="index",
          v-text="`${item.name},`"
        )

      template(v-slot:item.comments="{ item }")
        div {{ item.comments }}

      template(v-slot:item.start_date="{ item }")
        div {{ formatdate(item.start_date) }}

  export-data-dialog(
    :fileExport="fileExport",
    :isFinished="isDownloadFinished",
    v-bind:show="showExportDialog",
    @closeExportDialog="onCloseExportDialog",
    @download="onDownload"
  )
</template>
<script>
import VehicleFilterDialog from "@/modules/global/components/dialog/VehicleFilterDialog";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import Repository from "@/repository";
import moment from "moment";

export default {
  components: {
    GenericBreadCrumbs,
    VehicleFilterDialog,
    ExportDataDialog
  },
  data: () => ({
    isDownloadFinished: false,
    fileLink: null,
    showExportDialog: false,
    fileExport: "",
    breadCrumbsItems: [
      {
        text: "Report",
        disabled: false,
        path: "reports"
      },
      {
        text: "Contact Renewal Report",
        disabled: true,
        path: "contactRenewalReport"
      }
    ],
    dialog: true,
    items: [
      {
        title: "Export CSV",
        icon: "cloud_download",
        value: "export_csv"
      },
      {
        title: "Export Excel",
        icon: "cloud_download",
        value: "export_excel"
      }
    ],
    search: "",

    headers: [
      { text: "Contact", align: "left", value: "contacts", visible: true },
      { text: "Start Date", align: "left", value: "start_date", visible: true },
      { text: "Next run", align: "left", value: "job", visible: true },
      {
        text: "Renewal Type",
        align: "left",
        value: "contact_renewal_types",
        visible: true
      },
      { text: "Comment", align: "left", value: "comments", visible: true }
    ]
  }),

  computed: {
    dataItems: function() {
      return this.$store.state.reminders.contact_renewal.contact_renewal_data;
    },

    getHeaders: function() {
      let h = [];
      this.headers.forEach(item => {
        if (item.visible == true) {
          h.push(item);
        }
      });
      return h;
    }
  },
  methods: {
    formatdate: function(value) {
      return moment(value).format("h:mm a Do MMM YYYY");
    },
    onExport: function(value) {
      switch (value) {
        case "export_csv":
          this.fileExport = "CSV";
          this.downloadFile("/report/CSV/vehicle_list");
          break;
        case "export_excel":
          this.fileExport = "Excel";
          this.downloadFile("/report/Excel/vehicle_list");
          break;
      }
      this.showExportDialog = !this.showExportDialog;
    },

    downloadFile: function(url) {
      this.isDownloadFinished = false;

      Repository({
        method: "POST",
        url: url,
        data: this.headers.filter(item => item.visible == true),
        responseType: "blob"
      }).then(response => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function() {
      // download the file
      this.fileLink.click();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function() {
      this.showExportDialog = false;
    },
    print: function() {
      console.log("print");
    },
    groupStyle: function(value) {
      let obj = {};
      obj["border-bottom"] = "3px solid";
      obj["border-bottom-color"] = value;
      return obj;
    },

    operatorStyle: function(operator) {
      let obj = {};
      obj["border-radius"] = "50%";
      obj["color"] = "#FFFFFF";
      obj["padding"] = "3px";
      obj["margin-right"] = "5px";

      if (operator.group != null) {
        obj["background-color"] = operator.group.color;
      } else {
        obj["background-color"] = "#333333";
      }
      console.log(operator.group);
      return obj;
    }
  },

  mounted() {
    if (this.dataItems.length == 0) {
      this.$store.dispatch("reminders/contact_renewal/loadAllContact_renewals");
    }
  }
};
</script>

<style scoped>
.reportHeaderList {
  overflow: auto;
  max-height: 400px;
  min-width: 300px;
}
</style>
