import Repository from "@/repository";
import userSettings from "@/modules/user/store/userSettings";

const REGISTER_API = "/register";
const PASSWORD_API = "/password";

// initial state
const state = {
    passwordData: {
        showResetPasswordForm: false
    },
    userInfo: null,
    registrationResponse: null,
    emailResetResponse: null,
    passwordResetResponse: null,
    accountCheckResponse: null,
    accountCheckValid: false,
    defaultSettings: null,
    token:null,
    selectedCustomDashboardIndex: -1,
};

const getters = {
    getDashboard: state => state.userInfo.user_settings.dashboard,
    getCustomDashboards: state => {
        return state.userInfo.user_settings.custom_dashboard.map(
            (items, index) => ({
                ...items,
                index: index
            }))
    },

    getCustomFields: state => (reference) => {
        if (state.userInfo == null || state.userInfo.account.custom_fields == null) {
            return [];
        } else {
            return state.userInfo.account.custom_fields.filter(element => element.reference == reference).sort((a, b) => a.order_no - b.order_no);
        }
    },

    getViewPermission: state => (id) => {

        // if (state.userInfo.is_administrator){
        //     return true;
        // }
        const found = state.userInfo.role.permissions.find(element => element.id === id)
        // console.log(`id: ${id} : ${found.view}`)

        if (found === undefined) {
            return true;
        }else{
            if(found.id == "place"){
                console.log(found.view)
            }
            return found.view;
        }
    },

    getSelectedCustomDashboard: state => {
        return state.userInfo.user_settings.custom_dashboard[state.selectedCustomDashboardIndex]
    },

    getEditPermission: state => (id) => {
        if (state.userInfo.is_administrator){
            return true;
        }
        const found = state.userInfo.role.permissions.find(element => element.id === id)
        // console.log(`id: ${id} : ${found.view}`)

        if (found === undefined) {
            return true;
        }else{
            return found.edit;
        }
    }
};

const mutations = {
        setDashboard: (state, payload) => {
            console.log("setDashboard");
            console.log(payload);
            state.userInfo.user_settings.dashboard = payload;
        },

        setCustomDashboards: (state, payload) => {
            console.log("setCustomDashboard");
            state.userInfo.user_settings.custom_dashboard = payload;
        },




        registerEvent: (state, payload) => {
            console.log(payload.data);
            state.registrationResponse = payload.data;
        },

        forgotPasswordEvent: (state, payload) => {
            state.emailResetResponse = payload.data.message;
        },

        forgotPasswordEventBad: state => {
            state.emailResetResponse = `Invalid Email Address`;
        },

        newPasswordEvent: (state, payload) => {
            state.passwordResetResponse = payload.data;
        },

        newPasswordEventBad: state => {
            state.passwordResetResponse = "Could not reset password";
        },


        updateUserToken: (state,payload) => {
            state.token = payload;
        },

        updateUserInfoFromLogin: (state, payload) => {
            state.userInfo = payload.user;
        },

        setAccountCheckResponse: (state, payload) => {
            state.accountCheckResponse = payload.data.msg;
            state.accountCheckValid = payload.data.valid;
        },

        saveUserSettings: () => {
        },

        setDefaultSettings: (state, payload) => {
            state.defaultSettings = payload.dashboard;
        },

        resetLayout(state,payload) {
            state.userInfo.user_settings = payload.data;
        },

        deleteCustomDashboard(state, payload) {
            state.userInfo.user_settings.custom_dashboard.splice(payload.data.index,1);
        },

        addCustomDashboard: (state,payload) => {
            if (state.userInfo.user_settings.custom_dashboard == undefined){
                state.userInfo.user_settings.custom_dashboard = []
            }

            console.log(payload)
            state.userInfo.user_settings.custom_dashboard.push(payload.data)
        },

        saveCustomDashboard: (state,payload) => {
            console.log(payload.data)
            console.log(state.selectedCustomDashboardIndex)
            state.userInfo.user_settings.custom_dashboard.splice(state.selectedCustomDashboardIndex,1,payload.data)

        },

        setSelectedCustomDashboardIndex(state,payload) {
            state.selectedCustomDashboardIndex = payload
        },


    reset() {
            state.passwordData.showResetPasswordForm = false
            state.userInf = null
            state.registrationResponse = null
            state.emailResetResponse = null
            state.passwordResetResponse = null
            state.accountCheckResponse = null
            state.accountCheckVal = false
            state.token = null
        }
    }
;

const actions = {
    async registerUser({commit}, payload) {
        try {
            commit(
                "registerEvent",
                await Repository.post(`${REGISTER_API}/signup`, payload)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async forgotPassword({commit}, email) {
        await Repository.get(`${REGISTER_API}/resetPasswordRequest/${email}`)
            .then(response => {
                commit("forgotPasswordEvent", response);
            })
            .catch(error => {
                console.log(error);
                commit("forgotPasswordEventBad");
            });
    },

    async newPassword({commit}, payload) {
        await Repository.post(`${PASSWORD_API}/newPassword`, payload)
            .then(response => {
                commit("newPasswordEvent", response);
            })
            .catch(() => {
                commit("newPasswordEventBad");
            });
    },

    async validateAccount({commit}, payload) {
        await Repository.get(`${REGISTER_API}/validateAccount?a=${payload}`)
            .then(response => {
                commit("setAccountCheckResponse", response);
            })
            .catch(error => {
                commit("setAccountCheckResponse", error);
            });
    },

    async getDefaultSettings({commit}) {
        await Repository.get(`/dashboard/getDefaultUserSettings`).then(response => {
                commit("setDefaultSettings", response.data)
            }
        )
    },

    async resetLayout({commit}) {
        try {
            commit("resetLayout",await Repository.get(`/dashboard/resetLayout`));
        }catch (error) {
            console.log(error)
        }
    },

    async deleteCustomDashboard({  commit },index) {
        try {
            commit(
                "deleteCustomDashboard",
                await Repository.delete(`/user/settings/customDashboard/${index}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async addCustomDashboard({commit}, payload) {
        try {
            commit(
                "addCustomDashboard",
                await Repository.post(`/user/settings/customDashboard/new`,payload)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async saveCustomDashboard({state,commit},payload){
        try {
            commit(
                "saveCustomDashboard",
                await Repository.post(`/user/settings/customDashboard/${state.selectedCustomDashboardIndex}`,payload)
            );
        } catch (error) {
            console.log(error);
        }
    }



};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
        userSettings
    }
};
