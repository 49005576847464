<template lang="pug">
  div()
    .showAdvancedProperties(@click="$emit('toggle')")
      v-icon(:size="32") {{showAdvanced?'arrow_drop_down':'arrow_right'}}
      .caption.showAdvancedText() Advanced Properties
    div.pl-4(v-if="showAdvanced")
      slot()
</template>

<script>
export default {
  props:['showAdvanced'],
  name: "ShowAdvanced"
}
</script>

<style scoped lang="stylus">
.showAdvancedProperties {
  margin-left -14px;
  display flex;
  align-items center;
  cursor pointer
}
</style>