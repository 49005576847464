var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('panel-title-header',{attrs:{"label":"Record Details"}}),_c('div',{staticClass:"mt-2 mb-2 pb-2"},[_c('table',{staticClass:"DetailTable body-2 fill-width"},[_c('tr',[_vm._m(0),_c('td',[(_vm.record.vehicle_id)?_c('vehicle-column-by-id',{attrs:{"vehicle_id":_vm.record.vehicle_id}}):_vm._e()],1)]),_c('tr',[_vm._m(1),_c('td',[_c('div',[_vm._v(_vm._s(_vm.workflowTemplate.name))])])]),_c('tr',[_vm._m(2),_c('td',[_c('div',[_vm._v(_vm._s(_vm.workflowTemplate.description))])])]),_c('tr',[_vm._m(3),_c('td',[_c('div',[_vm._v(_vm._s(_vm.record.tx_id))])])]),_c('tr',[_vm._m(4),_c('td',[_c('div',[_vm._v(_vm._s(_vm.record.workflow_template_version))])])]),_c('tr',[_vm._m(5),_c('td',[_vm._v(_vm._s(_vm.getTime(_vm.record.submitted_at)))])]),_c('tr',[_vm._m(6),_c('td',[_vm._v(_vm._s(_vm.record.tx_id))])]),_c('tr',[_vm._m(7),_c('td',[(_vm.record.contact_id)?_c('contact-column-by-id',{attrs:{"contact_id":_vm.record.contact_id}}):_vm._e()],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Vehicle")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Workflow Form")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Workflow Description")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Transaction ID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Workflow Version")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Submitted On")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Transaction ID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Submitted By")])])
}]

export { render, staticRenderFns }