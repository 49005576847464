 <template lang="pug">
  v-card(v-if="schema"  elevation="4" )
    v-card-title.primary.ma-0.pa-2.white--text.subtitle-1( ) Records - {{getDataItems.length}}
    v-data-table(
      v-model=selected
      :headers="headers"
      :items="getDataItems"
      :single-select="false"
      item-key="id"
      show-select
      :items-per-page="20"
      :search="search"
    )
      template(v-slot:header.data-table-select="{on, selected}")
        v-simple-checkbox(:value="headerSelected()" :indeterminate="checkboxHeaderIndeterminate" @input="checkboxHeaderSelect($event)" :key="checkboxKey")


      template(v-slot:body="{items}")
        tbody()
          tr(v-for="item in items" :key="item.id" @click.prevent="onClickRow(item)" )
            td()
              v-simple-checkbox( :value="isSelected(item.id)" @input="checkboxSelect($event,item.id)" )
            td(v-for="(headerItem,index) in columns" :key="index")
              template(v-if="headerItem.name == 'TextField'")
                div() {{item[headerItem.value]}}
              template(v-else-if="headerItem.name == 'Date'")
                div(v-if="item[headerItem.value]")
                  | {{item[headerItem.value] | moment("ddd, Do MMM YYYY") }}

              template(v-else-if="headerItem.name == 'Jahazi'" )
                jahazi-record-renderer(v-if="getSchemaField(headerItem.value)" :schema-item="getSchemaField(headerItem.value)" :id="item[headerItem.value]")

              template(v-else-if="headerItem.name == 'DateTime'" )
                div(v-if="item[headerItem.value]")
                  | {{item[headerItem.value] | moment("ddd, Do MMM YYYY, h:mm:ss a") }}


              template(v-else-if="headerItem.name == 'Signature'")
                signature-record-renderer(v-if="item[headerItem.value] && item[headerItem.value].length>4" :id="item[headerItem.value]")

              template(v-else-if="headerItem.name == 'Draw'")
                draw-record-renderer(v-if="item[headerItem.value] && item[headerItem.value].length>4" :id="item[headerItem.value]")

              template(v-else-if="headerItem.name == 'Image'")
                .d-flex.flex-wrap.align-center(v-if="item[headerItem.value]")
                  image-thumbnail.mr-2.my-2( v-for="(itemID,index) in getPhotos(item[headerItem.value])" :key="index" :width='60', :height='60', :src="itemID")
                  a.caption(v-if="item[headerItem.value].length > 2") + {{item[headerItem.value].length-2}} more...

              template(v-else-if="headerItem.name == 'Attach'")
                .d-flex.flex-wrap.align-center(v-if="item[headerItem.value]")
                  attach-thumbnail.mr-2.my-2( v-for="(itemID,index) in getAttach(item[headerItem.value])" :key="index" :src="itemID")
                  a.caption(v-if="item[headerItem.value].length > 2") + {{item[headerItem.value].length-2}} more...

              template(v-else-if="headerItem.name == 'Switch'")
                div(v-text="getItemSwitchLabel(headerItem.value,item[headerItem.value])")

              template(v-else-if="headerItem.name == 'Checkbox'")
                div() {{getItemCheckboxLabel(headerItem.value,item[headerItem.value][0])}}

              template(v-else-if="headerItem.name == 'Map' && typeof item[headerItem.value] =='object'")
                .pr-6(v-if="item[headerItem.value]")
                  .locationItem(@click="onShowMap(item[headerItem.value])")
                    v-icon.mr-2() place
                    | {{item[headerItem.value].lat.toFixed(5)}}, {{item[headerItem.value].lon.toFixed(5)}}

              template(v-else-if="headerItem.name == 'ReadOnly'")
                div(v-if="item[headerItem.value]")
                  template(v-if="getSchemaField(headerItem.value).type == 'timestamp'" )
                    | {{getTimeFromTimestamp(item[headerItem.value])}}
                  template(v-else-if="getSchemaField(headerItem.value).type == 'location'")
                    .locationItem(@click="onShowMap(item[headerItem.value])")
                      v-icon.mr-2() place
                      | {{item[headerItem.value].lat.toFixed(5)}}, {{item[headerItem.value].lon.toFixed(5)}}
                  template(v-else)
                    div() {{item[headerItem.value]}}

              template(v-else-if="headerItem.name == 'Table'")
                table.tableRender(v-if="item[headerItem.value]")
                  // Header Rows
                  tr()
                    th(v-for="(itemSchema,index) in getSchemaField(headerItem.value).columns" :key="index" :style="getTableHeaderStyle(itemSchema)") {{itemSchema.label}}
                  // Data rows
                  tr(v-for="(row,rowIndex) in item[headerItem.value]")
                    td(v-for="(itemSchema,index) in getSchemaField(headerItem.value).columns" :style="getTableRowStyle(itemSchema,rowIndex)" )
                      template(v-if="itemSchema.type == 'Checkbox'" )
                        v-checkbox.ma-0(v-model="row[itemSchema.id]" hide-details dense  disabled)
                      template(v-else)
                        div() {{row[itemSchema.id]}}

              template(v-else-if="headerItem.name == 'Metadata'")
                  .d-flex.flex-column.align-end()
                    .caption() {{item['submitted_at']}}
                    .caption.grey--text()
                      .d-flex(v-if="item['contact_id']")
                        v-icon.grey--text.mr-2(:size="18") person
                        contact-column-by-id( :contact_id="item['contact_id']" mode="textOnly")
                    .caption.grey--text()
                      .d-flex(v-if="item['vehicle_id']")
                        v-icon.grey--text.mr-2(:size="18") local_shipping
                        vehicle-column-by-id(:vehicle_id="item['vehicle_id']" mode="textOnly" )




              template(v-else)
                div() {{item[headerItem.value]}}



            td()
              v-layout.mr-6(justify-end)
                more-drop-down-menu(:items="menuitems", @onClick="onClick", :theObject="item")

    //- div(v-for="(headerItem,index) in headers" :key="index") {{headerItem}}




</template>

<script>
// v-row(v-if="item.photos_id")
//   image-thumbnail.ml-2(v-for="(itemID,index) in getPhotos(item.photos_id)" :key="index" :width='60', :height='60', :src="itemID")

import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import { searchMixin } from "@/mixin/searchListMixin";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import AttachThumbnail from "@/modules/global/components/forms/AttachThumbnail";
import JahaziRecordRenderer from "@/modules/workflow/records/components/ui/JahaziRecordRenderer";
import SignatureRecordRenderer from "@/modules/workflow/records/components/ui/SignatureRecordRenderer";
import DrawRecordRenderer from "@/modules/workflow/records/components/ui/DrawRecordRenderer.vue";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById.vue";
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById.vue";
import moment from "moment";

export default {
  components:{
    VehicleColumnById,
    ContactColumnById,
    AttachThumbnail,
    ImageThumbnail,
    MoreDropDownMenu,
    JahaziRecordRenderer,
    SignatureRecordRenderer,
    DrawRecordRenderer,
  },

  props: {
    search:String,
    headers:Array,
    schema:Array,
  },

  mixins: [searchMixin],

  mounted() {
  },

  data:()=>({
    selected: [],
    checkboxHeaderIndeterminate: false,
    items: [],
    checkboxKey:1,
    footerProps: {
      "items-per-page-options": [5, 10, 20, 50, 100, 1000],
      pagnation: {itemsPerPage: 20}
    },


  }),

  computed: {



    menuitems: function () {

    let menuitems = [
      // { title: "Edit Table", icon: "table_chart", action: "table" },
      {title: "View Record", icon: "visibility", action: "view"},
      {title: "Download Record", icon: "download", action: "download"},
      {title: "Edit Record", icon: "edit", action: "edit"},

    ];
    if (this.isAdministrator) {
      menuitems.push({title: "Delete Record", icon: "delete", action: "delete"})
    }
    return menuitems;
  },
    isAdministrator: function(){
      return this.$store.state.profile.userInfo.is_administrator;
    },

    columns:function(){
      let columns2 = this.headers;
      columns2.pop();
      return columns2
    },



    getDataItems:function(){
      let tempList= [];


        this.$store.state.workflow.workflowRecord.record_data.forEach((item)=>{
          // console.log("--------- RECORD DATA --------------")
          // console.log(item)
            if(item.data != null){
              item.data["id"] = item.id;
              item.data["submitted_at"] = moment(item.submitted_at).format("ddd, DD MMM YYYY, h:mm a");
              item.data["contact_id"] = item.contact_id;
              item.data["vehicle_id"] = item.vehicle_id;



              tempList.push(item.data);
            }



        })




      console.log("THE TEMP LIST ===")
      console.log(tempList)
      return tempList;
    },





  },
  methods:{

    getContactFromID: function(contact_id) {
      var theContact = this.$store.getters["contacts/getContactByID"](contact_id)
      if (theContact != undefined) {
        return theContact.full_name
      } else {
        return "unknown"
      }
    },

    getSchemaField: function(theID){
      if(this.schema == null) return null;
      let result = null;
      result = this.schema.find(element => element.id == theID)
      if(typeof result  == "undefined"){
        return null;
      }
      return result;
    },


    headerSelected:function(){
      if (this.selected.length == 0){
        this.checkboxHeaderIndeterminate = false;
        return false;
      }else{
        if(this.getDataItems.length == this.selected.length){
          this.checkboxHeaderIndeterminate = false;
          return true;
        }else{
          this.checkboxHeaderIndeterminate = true;
          return false;
        }
      }

    },

    checkboxHeaderSelect:function(event){

      if(event){
        this.selected = [];
        this.getDataItems.forEach((item)=>{
              this.selected.push(item.id)
            })
      }else{
        this.selected = [];
      }


    },

    checkboxSelect: function(event,theID){
      console.log(event)

      if (event == true){
        this.selected.push(theID)
      }else{
        const index = this.selected.indexOf(theID);
        if (index > -1) {
          this.selected.splice(index)
        }

      }
    },

    isSelected:function(id){
      return this.selected.indexOf(id) > -1;
    },

      onClickRow2:function(item){
          console.log(item);
      },

    onClickRow: function(item){


      console.log("++++++++++++++++++")
      console.log(item.id)
      this.$store.commit("workflow/workflowRecord/setCurrentIndexByID",item.id);

      this.$router.push({
        name: "workflowRecordOverview",
        params: { workflowID: this.$route.params.workflowID,workflowRecordID: item.id  },
      }).catch(()=>{});
      // this.$store.commit("workflow/workflowRecord/toggleShowForm",true);

      // this.$store.commit("showRightDrawer",{title:"Record",width:"800",component:"RecordDetails"})

    },

    onEditRow: function(obj){
      this.$store.commit("workflow/setCurrentIndexByID",this.$route.params.workflowID)
      this.$store.commit("workflow/workflowRecord/setCurrentIndexByID",obj.id);
      console.log(this.$route.params.workflowID)
      // show the OVerview Add Edit modal
      this.$router.push({
        name: "workflowRecordForm",
        params: { workflowRecordID: obj.id  },
      }).catch(()=>{})

    },

    onClick: function(value) {
      console.log(value)
      switch (value.action) {
        case "view":

          this.onClickRow(value.theObject)
          break;
        case "download":
          window.open(`/api/renderhtml?type=workflowRecordPDF&id=${value.theObject.id}`);
          break;
        case "edit":
          this.onEditRow(value.theObject)
          break;
        case "delete":
          confirm("Are you sure you want to delete the record?") &&
          this.$store.dispatch(
              "workflow/workflowRecord/deleteRecord",
              value.theObject.id
          );
          break;
      }
    },

    getPhotos: function(list) {
      if (list.length > 2) {
        return list.slice(0, 2);
      } else {
        return list;
      }
    },
    getAttach: function(list) {
      if (list.length > 2) {
        return list.slice(0, 2);
      } else {
        return list;
      }
    },

    getItemCheckboxLabel: function(id,data){
      if (data==null)
        return null
      let theSchemaItem = this.schema.find(obj => obj.id == id);
      let result = theSchemaItem.options.find(val => val == data)
      return result
    },

    getItemSwitchLabel: function (id,data) {
      if(data == null){return null}
      let theSchemaItem = this.schema.find(obj => obj.id == id)
      let result = null;
      switch (theSchemaItem.type){
        case "true_false":result = data?"true":"false"; break;
        case "yes_no":result = data?"Yes":"No"; break;
        case "yes_na":result = data?"Yes":"N/A"; break;
        case "ok_na":result = data?"OK":"N/A"; break;
        case "pass_fail":result = data?"Pass":"Fail"; break;
        case "success_failed":result = data?"Success":"Failed"; break;
        default: return data.toString();
      }
      return result
    },

    getTimeFromTimestamp: function(val) {
      return moment(val).format("h:mm a, ddd, Do MMM YYYY")
    },

    onShowMap(geoLocation) {

     this.$store.commit("showMap", geoLocation)
    },

    getTableHeaderStyle: function(itemSchema){
      let theStyle = {'text-align':itemSchema.align};
      return theStyle;
    },

    getTableRowStyle:function(itemSchema,index){
      let theStyle = {'text-align':itemSchema.align};
      if(index%2 == 0){
        theStyle["background-color"] = "#f2f2f2"
      }else{
        theStyle["background-color"] = "none"
      }

      return theStyle;
    },


  }

}
</script>

<style scoped lang="stylus">
.locationItem {
  display flex;
  background-color #E8E8E8;
  border-radius 8px;
  align-items center
  padding 5px 8px;
  cursor pointer;
  max-width 200px;
}

.locationItem:hover {
  background-color #d3d3d3;
}

.tableRender{
  //table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 2px solid #6a6a6a;
}
.tableRender th{
  //border-bottom: 1px solid #E8E8E8;
  //border-left : 1px solid #E8E8E8;
  padding: 2px 10px 4px 10px;
  font-size: 11px;
  color: #afafaf;
}
.tableRender td{
  //border-bottom: 1px solid #E8E8E8;
  //border-left : 1px solid #E8E8E8;
  padding: 2px 10px;
  border-radius 3px;
  font-size: 12px;
  color: #4c4b4b;
}

</style>