<template lang="pug">
  .d-flex.flex-row
    .pointer(@click="toggleLock")
      template(v-if="lock")
        v-icon() lock
      template(v-else)
        v-icon() lock_open

    .ml-4() Dist: &nbsp;
      span.primary--text.font-weight-bold(v-text="`${getTotalDistance().toFixed(2)} Km`")
    .ml-4() Est. time: &nbsp;
      span.primary--text.font-weight-bold(v-text="`${getTotalTime()}`")
    .ml-4(v-if="estimate_cost") Est. Cost: &nbsp;
      span.primary--text.font-weight-bold() {{estimate_cost.toFixed(2)}} &nbsp;
      span.grey--text() {{$store.state.profile.userInfo.account.region_settings.currency}}

    v-spacer()
    v-btn.mr-4(small color="primary" outlined @click="$emit('toggleSelectPlacesDialog')")
      v-icon(style="margin-left:-8px;margin-right:4px"  ) place
      | Add/Remove
    template(v-if="expand")
      v-btn.mr-2(small color="primary" outlined @click="$emit('toggleDialog')")
        v-icon.pr-2(small) close
        | Close
    template(v-else)
      v-btn.mr-2(small color="primary" outlined @click="$emit('toggleDialog')")
        v-icon.pr-2(small) open_in_new
        | Expand
</template>

<script>
import moment from "moment";
import Repository from "@/repository";


export default {
  props:{
    expand:Boolean,
    vehicle_id:String,
  },
  data: ()=> ({
    estimate_cost:null,

  }),
  computed: {
    currentRoute:function(){
      return this.$store.getters["routing/getCurrentRouting"]
    },
    lock:function(){
        return this.currentRoute.locked
    }
  },

  mounted() {
    if(this.vehicle_id != null){
      this.getCost();
    }
  },

  watch:{
    vehicle_id: function(newValue){
      console.log(newValue);
      if(newValue != null){
        this.getCost();
      }
    }
  },

  methods: {
    toggleLock() {
      this.$emit("toggleLock");
    },
    getTotalDistance: function () {

     if(this.lock){
       return this.currentRoute.tsp_details.trips[0].distance / 1000.0
     }else{
       return this.currentRoute.tsp_details.routes[0].distance / 1000.0
     }
    },

    getTotalTime: function(){
      var theDuration;
      if(this.lock){
        theDuration = this.currentRoute.tsp_details.trips[0].duration
      }else{
        theDuration = this.currentRoute.tsp_details.routes[0].duration
      }
      let theSeconds = moment
          .utc(moment.duration(theDuration, "seconds").asMilliseconds())
          .format("HH:mm:ss");

      return theSeconds
    },

    // api to get the cost
    // /api/fuel/cost?vehicle_id=f6344534-525f-4e09-9e6d-d8b40f91fb90&distance_in_km=20
    getCost: async function(){
      await Repository.get(`/fuel/cost?vehicle_id=${this.vehicle_id}&distance_in_km=${this.getTotalDistance()}`).then((response) => {
        console.log(response.data)
        this.estimate_cost = response.data.cost;
      })
    }
  },
}
</script>

<style scoped>

</style>