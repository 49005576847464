<template lang="pug">
  v-data-table.elevation-1(
    :loading="loading",
    :headers='headers',
    :items='$store.state.routing.routing_data'
    item-key="id"
    :single-select="false"
    show-select
    @click:row="onClickRow"
  )

    template(v-slot:progress)
      v-progress-linear(slot='progress', height='2', :indeterminate='loading')

    template(v-slot:item.vehicle_id="{item}")
      vehicle-column-by-id(v-if="item.vehicle_id" :vehicle_id="item.vehicle_id")

    template(v-slot:item.operator_id="{item}")
      contact-column-by-id(v-if="item.operator_id" :contact_id="item.operator_id" )


    template(v-slot:item.name="{item}")
      div()
        .flex()
          span()
            template(v-if="item.locked")
              v-icon() lock
            template(v-else)
              v-icon() lock_open
          span.ml-2.font-weight-bold(v-html="item.name")
      .caption.grey--text(v-html="item.description")

    template(v-slot:item.start_date_time="{item}")
      div(v-html="getStartDate(item.start_date_time)")
      .caption.grey--text.text--darken-2(v-if="item.workflow")
        v-icon.mr-2(small) assignment
        | {{item.workflow.name}}

    template(v-slot:item.waypoints="{item}")
      div(v-if="item.waypoints") {{item.waypoints}} Waypoints

    template(v-slot:item.start_place="{item}")
      div(v-if="item.tsp_details")
        template(v-if="item.locked")
          div() {{(item.tsp_details.trips[0].distance/1000.0).toFixed(2)}} Km
        template(v-else)
          div() {{(item.tsp_details.routes[0].distance/1000.0).toFixed(2)}} Km


    template(v-slot:item.order_details="{item}")
      div.font-weight-bold(v-if="item.order_details") {{getPlaceById(item.order_details[0].place_id)}} &mdash;  {{getPlaceById(item.order_details[item.order_details.length-1].place_id)}}
      //--.caption.grey--text()
        div(v-if="item.tsp_details")
          template(v-if="item.locked")
            span() {{(item.tsp_details.trips[0].distance/1000.0).toFixed(2)}} Km
            span.ml-6() {{getTotalTime(item.tsp_details.trips[0].duration)}}
          template(v-else)
            span() {{(item.tsp_details.routes[0].distance/1000.0).toFixed(2)}} Km
            span.ml-6() {{getTotalTime(item.tsp_details.routes[0].duration)}}

    template(v-slot:item.end_place="{item}")
      div(v-if="item.end_place") {{item.end_place.name}}

    template( v-slot:item.action="{ item }")
      v-row.mr-7(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')



</template>

<script>
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById.vue";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import moment from "moment";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById.vue";
export default {
  components: {
    ContactColumnById,
    MoreDropDownMenu,
    ImageThumbnail,
    VehicleColumnById,
  },
  data: () => ({
    loading: true,
    headers: [
      { text: "Vehicle", value: "vehicle_id" },
      { text: "Name", value: "name" },
      { text: "Start Date", value: "start_date_time" },
      { text: "Waypoints", value: "waypoints" },
      { text: "Start Location - End Location", value: "order_details" },
      { text: "Operator", value: "operator_id" },
      { text: "Action", value: "action", align: "right" }
    ],

    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Duplicate", icon: "content_copy", action: "duplicate" },
      { title: "Delete", icon: "delete", action: "delete" },
      { title: "OpenLink", icon: "place", action: "open_link" },
      { title: "Download PDF", icon: "place", action: "downloadPDF" },
      { title: "Download PDF No Map", icon: "place", action: "downloadPDFNoMap" },
      { title: "Download Excel", icon: "place", action: "downloadExcel" }
    ]
  }),

  mounted() {
    this.$store.commit("routing/clearSelectedPlaces");
    if(this.$store.state.place.place_data.length < 2){
      this.$store.dispatch("place/loadAllPlaces").then(() => {
          this.$store.dispatch("routing/loadAllRoutings").then(() => {
            this.loading = false;
          });
      });
    }else{
      if (this.$store.state.routing.routing_data.length < 2) {
        // load Data
        this.$store.dispatch("routing/loadAllRoutings").then(() => {
          this.loading = false;
        });
      }else{
        this.$store.commit("routing/sortRoutingData")
        this.loading = false;
      }
    }

  },



  methods: {

    onClickRow:function(item){
      this.$store.commit("routing/setCurrentIndexByID",item.id);
      if (item.tsp_details == null){
        this.$store.dispatch("routing/getTSPByID",item.id).then(() => {
          this.$store.commit("showRightDrawer",{title:this.$store.getters["routing/getCurrentRouting"].name,width:800,component:"RightRouteMap"})
        })
      }else{
        this.$store.commit("showRightDrawer",{title:this.$store.getters["routing/getCurrentRouting"].name,width:800,component:"RightRouteMap"})
      }




    },

    getTotalTime: function(theDuration){
      let theSeconds = moment
          .utc(moment.duration(theDuration, "seconds").asMilliseconds())
          .format("HH:mm:ss");

      return theSeconds
    },

    getStartDate:function(value){
      return moment(value).format("ddd, Do MMM YYYY, h:mm a")
    },



    getPlaceById: function(place_id){
      if (place_id == null){
        return ""
      }

      let place = this.$store.getters["place/getPlaceByID"](place_id)
      if (typeof(place) != "undefined"){
        return place.name;
      }

      return ""
      // let place = this.$store.getters["place/getPlaceByID"](place_id)
      // return place.name;
    },

    onClick: function(value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("routing/setCurrentIndexByID", value.theObject.id);
          if (value.theObject.tsp_details == null) {
            this.$store.dispatch("routing/getTSPByID",value.theObject.id).then(() => {
              this.$router.push({
                name: "routeDetailsForm",
                params: { placeID: value.theObject.id }
              }).catch(()=>{});
            });
          }else{
            this.$router.push({
              name: "routeDetailsForm",
              params: { placeID: value.theObject.id }
            }).catch(()=>{});
          }



          break;
        case "delete":
          confirm("Are you sure you want to delete this item?") &&
            this.$store.dispatch("routing/deleteRouting", value.theObject.id);
          break;
        case "duplicate":
          this.$store.commit("routing/setCurrentIndexByID",value.theObject.id)
          this.$store.dispatch("routing/duplicateRouting", value.theObject.id);
          break;
        case "open_link":
          window.open(`/api/renderhtml?type=osrmRoute&osrm_id=${value.theObject.id}&account_id=${this.$store.state.profile.userInfo.account.id}&showMap=`);
          break;
        case "downloadPDF":
          window.open(`/api/renderhtml?type=osrmRoutePDF&osrm_id=${value.theObject.id}&account_id=${this.$store.state.profile.userInfo.account.id}&showMap=`);
          break;
        case "downloadPDFNoMap":
          window.open(`/api/renderhtml?type=osrmRoutePDF&osrm_id=${value.theObject.id}&account_id=${this.$store.state.profile.userInfo.account.id}`);
          break;
        case "downloadExcel":
          const theFileName = value.theObject.name.replace(/\//g, "-");

          window.open(`/api/osrm/downloadExcel/${value.theObject.id}/${theFileName}_${this.getStartDate(value.theObject.start_date_time)}.xls`);
          break;
      }
    }
  }
};
</script>

<style scoped>
</style>