<template lang="pug">
.white.pt-2
	v-tabs(left,height='32',slider-color='primary', v-model="active")
		v-tab(@click="setTabID(0)")
			|	Service Reminders
		v-tab-item
			service-reminder-list-layout
            
		v-tab(@click="setTabID(1)")
			|	Vehicle Renewals
		v-tab-item
			vehicle-renewal-list-layout

		v-tab(@click="setTabID(2)")
			|	Contact Renewals
		v-tab-item
			contact-renewal-list-layout


</template>
<script>
import ServiceReminderListLayout from "@/modules/reminders/layouts/serviceReminder/ServiceReminderListLayout";
import VehicleRenewalListLayout from "@/modules/reminders/layouts/vehicleRenewal/VehicleRenewalListLayout";
import ContactRenewalListLayout from "@/modules/reminders/layouts/contactRenewal/ContactRenewalListLayout";

export default {
  components: {
    ServiceReminderListLayout,
    VehicleRenewalListLayout,
    ContactRenewalListLayout
  },

  computed: {
    active: {
      get: function() {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          tabIndex = tabIndex > 2 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function(value) {
        this.$route.params.tabID = value;
      }
    }
  },

  methods: {
    setTabID(value) {
      console.log(value);
      this.active = parseInt(value);
      this.$route.params.tabID = parseInt(value);
      // this.$route.push({name:""})
    }
  }
};
</script>
<style scoped></style>
