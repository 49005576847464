<template lang="pug">
div(v-if="group" )
  .ml-0(v-if="group != null" @click="openGroupDetail(group.id)")
      .underline() {{ group.name }}


</template>
<script>
import axios from "axios";

export default {
  components: {
  },
  props: {
    group_id: String,
    clickToDetail:{
      type:Boolean,
      default: false,
    }
  },
  data: () => ({
    group: null
  }),
  watch:{
    group_id:function(oldVal,newVal){
      if(oldVal !== newVal){
        this.getGroup();
      }
    }
  },
  methods: {
    getGroup: function(){
      let tempGroup = this.$store.getters["settings/groupSettings/getGroupByID"](this.group_id);
      if (tempGroup == undefined) {
        axios.get(`/api/group/${this.group_id}`).then((response)=>{
          this.group = response.data;
        });
      } else {
        this.group = tempGroup;
      }
    },
    openGroupDetail: function (groupID) {
      if(this.clickToDetail){
        this.$store.commit("settings/groupSettings/setCurrentIndexByID", groupID);
        // this.$router.push({
        //   name: "groupDashboard",
        //   params: {contactID: groupID}
        // }).catch(()=>{});
      }

    }
  },
  mounted() {
    this.getGroup();
  }
};
</script>
<style lang="stylus" scoped>
.nameColumn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn, .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn, .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;
  padding: 0px;
}

.underline {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}
</style>
