<template>
  <div
    class="grey lighten-3 grey--text text--darken-2 formSubHeader font-weight-bold"
  >
    <slot />
  </div>
</template>
<script>
export default {
  data: () => ({})
};
</script>
<style scoped>
.formSubHeader {
  font-size: 18px;
  padding: 5px 10px;
}
</style>
