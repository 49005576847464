import Repository from "@/repository";

const LOGIN_API = "";
let theTimer = null;
const state = {
    errorLoginMessage: null,
    refreshTimeout: 60000 * 15, // refresh every 14 Minutes
    isLoggedIn: false // performs the same as "isUserLoggedIn" local storage but gives faster feedback as it is not reading from disk
};

const getters = {
    // useful for getting the default route. Called by App.vue
    hasRoute: () => {
        return localStorage.getItem("route");
    },

    isUserLoggedIn: () => {
        return localStorage.getItem("isUserLoggedIn") === "userLoggedIn";
    }
};
const mutations = {
    loginUser: () => {
        // called by action
    },

    setUserLoggedIn: state => {
        localStorage.setItem("isUserLoggedIn", "userLoggedIn");
        state.isLoggedIn = true;
    },

    deleteUserLoggedIn: state => {
        localStorage.removeItem("isUserLoggedIn");
        localStorage.removeItem("route");
        state.isLoggedIn = false;

    },

    logOutUser: state => {
        state.isLoggedIn = false;
        clearInterval(theTimer);
        localStorage.removeItem("isUserLoggedIn");
        localStorage.removeItem("route");
        console.log("logged out the users");
        // window.location = "";

    },

    setErrorMessage: (state, payload) => {
        state.errorLoginMessage = payload;
    },

    clearLoginError: state => {
        state.errorLoginMessage = null;
    },
    // timer started when user has logged in. called by the loginUser ACTION
    startTimer: (state, payload) => {
        console.log(payload.expiration_seconds);
        // let timeout = (payload.expiration_seconds - 10) * 1000;
        // state.theTimer = setInterval(function() {
        //   console.log("the Timer");
        // }, timeout);
    },
    stopTimer() {
        clearInterval(state.theTimer);
    },

    reset(state) {
        state.errorLoginMessage = null
        state.refreshTimeout = 60000 * 15 // refresh every 14 Minutes
        state.isLoggedIn = false
    }
};

const actions = {
    // Login the User
    async loginUser({dispatch, commit, state}, payload) {
        await Repository.post(`${LOGIN_API}/login`, payload)
            .then(response => {
                // commit to loging
                commit("loginUser", response.data);
                commit("setUserLoggedIn");
                theTimer = setInterval(function () {
                    dispatch("renewToken");
                }, state.refreshTimeout);

                // commit to User
                commit("profile/updateUserInfoFromLogin", response.data, {
                    root: true
                });

                // updaet User Token
                commit("profile/updateUserToken", response.data.token, {
                    root: true
                });

                // // commit to Settings
                commit(
                    "settings/accountSettings/saveAccountSetting",
                    {data: response.data.user.account},
                    {
                        root: true
                    }
                );
            })
            .catch(error => {

                console.log(
                    `error message: ${error.message} code: ${error.statusCode}`
                );
                console.log(`cant login and now resolving: ${error}`);
                commit("logOutUser");
                commit("deleteUserLoggedIn");
                commit("setErrorMessage", error.response.data);
                // updaet User Token
                commit("profile/updateUserToken", null, {
                    root: true
                });
                localStorage.removeItem("route");
            });
    },

    async renewToken() {
        console.log("renew Token");
        await Repository.get(`${LOGIN_API}/refresh-token-cookie`)
            .then((response) => {
                console.log("refresshed");
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async autoLoginUser({dispatch, commit, state}, payload) {
        console.log("autoLogin");
        await Repository.get(`${LOGIN_API}/autoLogin`, payload)
            .then(response => {
                // commit to loging
                commit("loginUser", response.data);
                commit("setUserLoggedIn");

                // create a timer to refresh the token
                theTimer = setInterval(function () {
                    dispatch("renewToken");
                }, state.refreshTimeout);

                // commit to User
                commit("profile/updateUserInfoFromLogin", response.data, {
                    root: true
                });

                // update token
                commit("profile/updateUserToken", response.data.token, {
                    root: true
                });

                // // commit to Settings
                commit(
                    "settings/accountSettings/saveAccountSetting",
                    {data: response.data.user.account},
                    {
                        root: true
                    }
                );
            })
            .catch(error => {
                // Log the user out if an error is found
                // console.log(error);
                console.log(
                    `error Message: ${error.message} and error Code: ${error.statusCode}`
                );

                switch (error.statusCode) {
                    case 401:
                        console.log("Unauthorized");
                        break;
                    case 500:
                        console.log("Error connecting to server");
                        break;
                }

                commit("logOutUser");
                commit("deleteUserLoggedIn");
                // update token
                commit("profile/updateUserToken", null, {
                    root: true
                });
                localStorage.removeItem("route");
            });
    },

    async logOut({commit}) {
        try {
            console.log("logging OUT....");
            commit("logOutUser", await Repository.get(`${LOGIN_API}/logoutc`));
        } catch (error) {
            console.log(error);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
