<template lang="pug">
    #blankcontent()
        .panelTitle()
            | Weather
            v-spacer()
            div(@click="onRefresh()")
              v-icon.pointer() refresh
        v-divider.mx-2()
        .panelContent()
            .d-flex.align-end.justify-center.pl-4.weatherContent()
              .d-flex.flex-column.align-start()
                v-select.mx-6(:items='locations', item-text='name', item-value="id" prepend-icon='place', v-model='selectedLocation', label='Current Weather' @change="onRefresh" )
                .d-flex.hourlyContainer(v-if="hourly")
                  .hourlySymbolList.grey.lighten-3( v-for="(weatherCode,index) in hourly.time.slice(0,hourly.time.length > 6 ?6:hourly.time.length)")
                    img(:src="`/static/web/weather_symbols/${getHourlyWeatherSymbol(index)}.png`" style="width:40px; height:40px;")
                    .d-flex.flex-column.align-center()
                      //- .caption() {{getTheDay(hourly.time[index])}}
                      .caption.grey--text() {{hourly.temperature_2m[index]}} &#8451;
                      .caption.font-weight-bold() {{getTheTime(hourly.time[index])}}
              .d-flex.justify-center(style="width:100%")
                .d-flex.flex-column.align-center.currentWeather(v-if="current_weather")
                  img(:src="`/static/web/weather_symbols/${getCurrentWeatherSymbol()}.png`" style="width:80px; height:80px;")
                  .subtitle-1() {{current_weather.temperature}} &#8451;
                  .caption.grey--text() current time

            v-list-item-group.mt-3(v-if="days")
              v-list-item.lineVehicleStatus3.pr-6(v-for="(item,index) in days.time" :key="index")
                  v-list-item-content()
                    .d-flex.align-center.justify-content-between()
                      img.mr-8.ml-2(:src="`/static/web/weather_symbols/${days.weathercode[index]}.png`" style="width:30px; height:30px")
                      .d-flex.justify-space-between(style="width:100%")
                        .body-2() {{getTheDate(item)}}
                        .body-2.grey--text.text--lighten-1() {{getWeatherInterpretation(days.weathercode[index])}}



</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
    props: {
        clientHeight: Number
    },

    methods: {
        showHourWeather:function (index){
            const currentTime = new Date().getTime()
            const weatherTime = moment(this.hourly.time[index]).toDate().getTime()
            // console.log(weatherTime +" - "+  currentTime + " = "+ (currentTime > weatherTime))

            return weatherTime > currentTime
        },

        getCurrentWeatherSymbol:function(){
            const currentHour = moment(this.current_weather.time).hour()
            if(currentHour < 6 || currentHour > 17){
                if(this.current_weather.weathercode == 0 || this.current_weather.weathercode ==1){
                    return "1_night";
                }else{
                    return "2_night";
                }

            }
            return this.current_weather.weathercode;
        },
        getHourlyWeatherSymbol:function(index) {
            const weatherIndex = this.hourly.weathercode[index];

             if((weatherIndex == 0 || weatherIndex == 1 || weatherIndex == 2) && (moment(this.hourly.time[index]).hour() < 6 ||  moment(this.hourly.time[index]).hour() > 17) ) {
                if (weatherIndex == 0|| weatherIndex ==1){
                    return "1_night";
                }else{
                    return "2_night";
                }

            }else{
                return weatherIndex;
            }

        },

        getTheDay:function(theTime) {
            return moment(theTime).format("ddd")
        },

        getTheTime: function(theTime) {
            return moment(theTime).format("h a")
        },
        getTheDate: function(theTime) {
          return moment(theTime).format("dddd - Do MMMM YYYY")
        },
        getWeatherInterpretation: function(weatherCode){
            let description = ""
            switch(weatherCode){
                case 0: description = "Clear Sky"; break;
                case 1: description = "Mainly Clear"; break;
                case 2: description = "Partly Cloudy"; break;
                case 3: description = "Overcast"; break;
                case 45: description = "Fog"; break;
                case 48: description = "Rime Fog"; break;
                case 51: description = "Light Drizzle"; break;
                case 53: description = "Moderate Drizzle"; break;
                case 55: description = "Dense Drizzle"; break;
                case 56: description = "Freezing Light Drizzle"; break;
                case 61: description = "Slight Rain"; break;
                case 63: description = "Moderate Rain"; break;
                case 65: description = "Heavy Rain"; break;
                case 66: description = "Freezing Light Rain"; break;
                case 67: description = "Freezing Heavy Rain"; break;
                case 71: description = "Slight Snow"; break;
                case 73: description = "Moderate Snow"; break;
                case 75: description = "Heavy Snow"; break;
                case 77: description = "Snow grains"; break;
                case 80: description = "Light Rain Showers"; break;
                case 81: description = "Moderate Rian Showers"; break;
                case 82: description = "Violent Rain Showers"; break;
                case 85: description = "Slight Snow Showers"; break;
                case 86: description = "Heavy Snow Showers"; break;
                case 95: description = "Thunderstorms"; break;
                case 96: description = "Thunderstorms & Slight Hail"; break;
                case 99: description = "Thunderstorms & Heavy Hail"; break;
            }
            return description;
        },
        onRefresh: function () {
            console.log(`selected location : ${this.selectedLocation}`)
            axios.get("/api/dashboard/weatherLocations").then(weatherResponse => {
                this.locations = weatherResponse.data;

                let lat = 36.7168949014469;
                let lon = -1.3213709298035923;

                if (this.locations.length > 0) {
                    lat = this.locations[this.selectedLocation].location.lon;
                    lon = this.locations[this.selectedLocation].location.lat;
                }

                this.hourlyCounter = 0;

                const day_forcast_url = `https://api.open-meteo.com/v1/forecast?latitude=${lat}2&longitude=${lon}&hourly=temperature_2m,weathercode&daily=sunrise,sunset&current_weather=true&past_days=1&forecast_days=2&timezone=Europe%2FMoscow`
                axios.get(day_forcast_url).then(response1 => {
                    this.current_weather = response1.data.current_weather;
                    this.hourly = response1.data.hourly;
                    this.sunrise = response1.data.daily.sunrise[0];
                    this.sunset = response1.data.daily.sunset[0];
                    const cutoffIndex = this.hourly.time.findIndex(element => moment(element) > moment() )
                    if(cutoffIndex > -1){
                        this.hourly.time.splice(0,cutoffIndex)
                        this.hourly.temperature_2m.splice(0,cutoffIndex)
                        this.hourly.weathercode.splice(0,cutoffIndex)

                    }
                    const seven_day_forecast_url = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lon}&daily=weathercode&timezone=Europe%2FMoscow`
                    axios.get(seven_day_forecast_url).then(response2 =>{

                        this.days = response2.data.daily;
                    })
                })
            })



        }
    },


    mounted() {
      this.onRefresh();
    },
    data: () => ({
        selectedLocation:0,
        locations:[],
        current_weather:null,
        days: null,
        hourly: null,
        hourlyCounter:0,
        sunrise:null,
        sunset:null,

    })
};
</script>
<style scoped lang="stylus">
.lineVehicleStatus3 {
  border-bottom: 1px solid #CCC;
  height: 50px;
}

#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 30px;
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.panelContent {
  height: calc(100% - 45px);
  overflow: auto;
}
  .activityCounter{
    border-radius 50%;
    width 36px;
    height 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold
  }

    .hourlySymbolList{
      padding 3px 3px;
      margin 0px 5px;
      min-width:52px;
      border-radius 6px;
    }

      .currentWeather{
        border: 1px solid #CCCCCC;
        border-radius: 15px;
        padding 5px 5px;

      }
        .weatherContent{
          //max-width: 550px;
        }
    .hourlyContainer{
      overflow-y hidden;
    }
</style>
