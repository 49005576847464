<template lang="pug">
  div(v-if="$store.state.inspection.inspection_data.length > 0")
    v-toolbar(dense flat)
      generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer()
      v-btn(color="primary" small) Some Button
    v-divider()
    v-container.mt-8.pa-8.white(fluid)

      v-row()
        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:body)
              div.mt-2.mb-2.pb-2
                table.DetailTable.body-2.fill-width
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Vehicle
                    td
                      vehicle-column(v-if="getInspection.vehicle" :vehicle="getInspection.vehicle")
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Odometer
                    td {{getInspection.odometer}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Name
                    td {{getInspection.name}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Description
                    td {{getInspection.description}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Report Date
                    td() {{getInspection.report_date_time | moment("ddd, Do MMM YYYY &ndash; h:mm a")}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Report By
                    td() {{getInspection.reported_by.first_name}} {{getInspection.reported_by.last_name}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Assigned
                    td()
                      div(v-if="getInspection.assigned_to") {{getInspection.assigned_to.first_name}} {{getInspection.assigned_to.last_name}}
                  tr
                    td.grey.lighten-1.text--darken-1.pl-4.white--text.subtitle-1(colspan="2") Overdue Setting
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Due Date
                    td()
                      div(v-if="getInspection.assigned_to") {{getInspection.due_date_time | moment("Do MMM YYYY") }}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Odometer
                    td()
                      div(v-if="getInspection.odometer") {{getInspection.odometer}}

        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:title)
              | Comments


            template(v-slot:body)
              div() wassup man

          base-panel()
            template(v-slot:title)
              | Photos


            template(v-slot:body)
              attach-fill-form(:schema="imagesSchama" :data="{value:getInspection.photos_id}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" )

          base-panel()
            template(v-slot:title)
              | Documents

            template(v-slot:body)
              attach-fill-form(:schema="documentsSchama" :data="{value:getInspection.documents_id}" @onChange="saveDocuments"  @onMenuAction="onMenuActionImages")


</template>
<script>
import BasePanel from "@/modules/global/components/panel/basePanel";
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";

export default {
  components: {
    BasePanel,
    VehicleColumn,
    AttachFillForm,
    GenericBreadCrumbs
  },
  data: () => ({
    title: "header",
    answer: "Sengenge ni Ngombe",
    breadCrumbsItems: [
      {
        text: "Inspections",
        disabled: false,
        path: "inspections"
      },
      {
        text: "Inspection Detail",
        disabled: true
      }
    ],
    imagesSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Inspection Images",
      hint: null,
      type: "image",
      image_size: "small",
      multiple: true
    },
    documentsSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Inspection Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true
    }
  }),
  computed: {
    getInspection: function () {
      return this.$store.getters["inspections/getCurrentInspection"];
    }
  },
  methods: {
    onCloseDialog: function () {
    },
    onMenuActionImages: function (value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("inspections/deleteImage", value.theObject.id);
          break;
      }
    },
    saveImages: function (theObject) {
      this.$store.commit("inspections/updateInspection", {
        path: "photos_id",
        value: theObject.value
      });
    },
    saveDocuments: function (theObject) {
      this.$store.commit("inspections/updateInspection", {
        path: "documents_id",
        value: theObject.value
      });
    },
    onMenuActionDocuments: function (value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("inspections/deleteDocument", value.theObject.id);
          break;
      }
    }
  },
  mounted() {
    if (this.$store.state.inspection.inspection_data.length < 2) {
      this.$store
          .dispatch("inspections/fetchInspection", this.$route.params.inspectionID)
          .then(() => {
          });
    }
  }
};
</script>
<style lang="stylus" scoped>
.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0
    padding 0

    padding-top 6px
    padding-bottom 8px

</style>
