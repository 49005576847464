<template lang="pug">
div
  v-toolbar(color="primary" dark dense flat)
    div.subheading.font-weight-bold {{title}}
    v-spacer
    v-btn(v-if="showEditButton" small outlined @click="$emit('edit',title)")
      v-icon.mr-2(small) edit
      | Edit
  div.ma-2.pb-2
    table.DetailTable.body-2.fill-width
      tr(v-for="(item,index) in items" :key="index")
        td.goRight.grey--text.text--darken-1
          .mr-3 {{item.label}}
        td {{item.value}}

 
</template>
<script>
export default {
  props: {
    items: Array,
    title: String,
    showEditButton: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick: function(value) {
      console.log(value);
    }
  }
};
</script>
<style scoped lang="stylus">
.DetailTable {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
}

.DetailTable {
  tr {
    margin: 0;
    padding: 0;
  }

  td {
    border-bottom: 1px solid #EEEEEE;
    margin: 0;
    padding: 0;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 8px;
  }
}

.goRight {
  text-align: right;
  width: 40%;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.hFlex {
  display: flex;
}

.handCursor {
  cursor: pointer;
}
.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0
    padding 0

    padding-top 6px
    padding-bottom 8px
</style>
