<template lang="pug">
  div()
    .title.pa-3(v-show='titleVisible', :class="['grey lighten-4']")
      | Basic Information
    v-divider()
    .px-3
      div
        v-form(ref="basicForm" v-model="valid")
          v-flex(xl12)
            v-container(grid-list-sm)
              v-layout(row, wrap, justify-end)
                v-flex(md6, xs12, xl4)
                  v-text-field(v-model='first_name',:rules='requiredRules', label='First Name')
                v-flex(md6, xs12, xl4)
                  v-text-field(v-model='middle_name',   label='Middle Name', )
                v-flex(md6, xs12, xl4)
                  v-text-field(v-model='last_name',  :rules='requiredRules',  label='Last Name', required)
                v-flex(md6, xs12, xl4)
                  v-select(:items='$store.state.settings.groupSettings.group_data', item-text='name', item-value='id', @focus='onGroupSelectFocus', prepend-icon='group', v-model='group_id', label='Group', data-vv-name='select')
                v-flex(md6, xs12, xl4)
                  v-text-field(v-model='email', :rules='emailRules', label='E-mail')
                v-flex(md6, xs12, xl4)
                  v-text-field(v-model='mobile', label='Mobile', :rules='mobileRules', required)


              form-divider PHOTOS
              v-layout(row, wrap, justify-start)
                v-flex(xs12)
                  attach-fill-form( :schema="imagesSchama" :data="{value:images}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" :moreMenu="additionalImageMenuItems")
              form-divider DOCUMENTS
              v-layout(row, wrap, justify-start)
                v-flex(xs12)
                  attach-fill-form( :schema="documentsSchama" :data="{value:documents}" @onChange="saveDocuments"  @onMenuAction="onMenuActionDocuments")

              form-divider(v-if="getJourneyApprovalCustomFields.length > 0") JOURNEY APPROVALS
              v-row(v-if="getJourneyApprovalCustomFields.length > 0")
                v-col(v-for="(element,index) in getJourneyApprovalCustomFields" :key="index" cols="12" xs="12" md="6" xl="4")
                  custom-field-auto-contact-auto-complete.mt-4(v-bind:value="getCustomField(element.label_id)" :element="element" mutationType="contacts/updateContact")
                  //
                  //custom-field-auto-combo-box.mt-4(
                  //  v-bind:value="getCustomField(element.label_id)"
                  //  :element="element"
                  //  mutationType="contacts/updateContact"
                  //  module="contacts"
                  //  actionPrefix="Contact"
                  //  field="full_name")
                  //
                  //- custom-field-text-field(v-bind:value="getCustomField(element.label_id)" mutationType="contacts/updateContact"  :element="element")

</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import {createHelpers} from "vuex-map-fields";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
import AutoComboBox from "@/modules/global/components/forms/AutoComboBox";
import CustomFieldTextField from "@/modules/global/components/forms/CustomFieldTextField";
import CustomFieldAutoComboBox from "@/modules/global/components/forms/CustomFieldAutoComboBox";
import CustomFieldAutoContactAutoComplete from "@/modules/global/components/forms/CustomFieldAutoContactAutoComplete";


const {mapFields} = createHelpers({
  getterType: "contacts/getContact",
  mutationType: "contacts/updateContact"
});

export default {
  components: {
    AttachFillForm,
    FormDivider,
    AutoComboBox,
    CustomFieldTextField,
    CustomFieldAutoComboBox,
    CustomFieldAutoContactAutoComplete
  },
  name: "BasicInformation",
  props: {
    titleVisible: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    imagesSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Contact Images",
      hint: null,
      type: "image",
      image_size: "small",
      multiple: true
    },
    additionalImageMenuItems: [
      {title: "Make Thumbnail", icon: "edit", action: "make_thumbnail"}
    ],

    documentsSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Contact Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true
    },
    category: [],
    id: 0,
    valid: false,
    select: null,
    checkbox: null,
    items: [],

    requiredRules: [v => !!v || "Name is required"],
    mobileRules: [v => !!v || "Mobile phone is required"],

    emailRules: [
      v => !!v || "E-mail is required",
      v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
    ],
  }),

  mounted() {
    this.$refs.basicForm.validate();

  },

  computed: {
    ...mapFields([
      "first_name",
      "middle_name",
      "last_name",
      "email",
      "mobile",
      "technician",
      "operator",
      "employee",
      "group_id",
      "thumbnail",
      "images",
      "documents",
      "thumbnail_id",

    ]),


    getJourneyApprovalCustomFields: function () {
      return this.$store.getters["profile/getCustomFields"]("journey_approval");
    }
  },

  methods: {

    getCustomField: function (labelID) {
      let temp = {}


      // initiazlize details
      if (this.$store.getters["contacts/getCurrentContact"].details == null) {
        this.$store.commit("contacts/updateContact", {path: `details`, value: temp})
      }

      // check if the key exists
      if (labelID in this.$store.getters["contacts/getCurrentContact"].details) {
        return this.$store.getters["contacts/getCurrentContact"].details[labelID]
      } else {
        return null;
      }
    },

    saveImages: function (theObject) {
      // set the thumnail if there is none to the first image
      if (
          this.thumbnail_id == null &&
          this.images == null &&
          theObject.value.length > 0
      ) {
        this.$store.commit("contacts/updateContact", {
          path: "thumbnail_id",
          value: theObject.value[0]
        });
      }

      // save the images
      this.$store.commit("contacts/updateContact", {
        path: "images",
        value: theObject.value
      });
    },

    saveDocuments: function (theObject) {
      this.$store.commit("contacts/updateContact", {
        path: "documents",
        value: theObject.value
      });
    },
    onMenuActionImages: function (value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("contacts/deleteImage", value.theObject.id);
          break;
        case "make_thumbnail":
          this.$store.commit("contacts/updateContact", {
            path: "thumbnail_id",
            value: value.theObject.id
          });
          break;
      }
    },
    onMenuActionDocuments: function (value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("contacts/deleteDocument", value.theObject.id);
          break;
      }
    },
    onClick: function () {
      console.log(this.category);
    },

    onGroupSelectFocus: function () {
      // Load The group List
      if (this.$store.state.settings.groupSettings.currentIndex == -1) {
        this.$store.dispatch("settings/groupSettings/loadAllGroups");
      }
    },

    saveThumbnail: function (theObject) {
      console.log(theObject.value[0]);
      this.$store.commit("contacts/updateContact", {
        path: "thumbnail_id",
        value: theObject.value[0]
      });
    }
  }
};
</script>

<style>
.formContainer {
  display: flex;
  justify-content: right;
  align-items: center;
}
</style>
