<template lang="pug">
  div()
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    
    v-text-field(v-model="selectHint" label="Description")
    
    v-checkbox(v-model="selectRequired" label="Required")
    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        showAdvancedProperties(:showAdvanced="showAdvanced" )
      div(v-show="showAdvanced")
        v-text-field(v-if="properties.default"  v-model="selectDefault"  label="Default Value")
        v-select(v-model="selectedType" label="Data Type" :items="['text','password','number','email']")
        v-text-field(v-model="selectMaxlength" label="Maximum Characters" mask="###")
        v-checkbox(v-model="selectCommentOnFail" label="Must comment when there is no input")
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)

</template>
<script>

import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";
import {inspectionPropertiesMixin} from "@/modules/inspections/components/formDesigner/properties/inspectionPropertiesMixin";

export default {
  mixins:[inspectionPropertiesMixin],




  computed: {
    selectedType: {
      get: function() {
        return this.properties.type;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "type",
          value: value
        });
      }
    },

    selectMaxlength: {
      get: function() {
        return this.properties.maxlength;
      },

      set: function(value) {
        if (value != null) {
          let i = parseInt(value);
          // only execute is an integer and greater than 0
          if (i > 0) {
            this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
              field: "maxlength",
              value: value
            });
          } else {
            this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
              field: "maxlength",
              value: null
            });
          }
        }
      }
    },

  },

};
</script>
<style scoped lang="stylus">
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}
</style>
