<template lang="pug">
  div
    v-toolbar(dense flat color='white')
      v-toolbar-title Edit Service Type
      v-spacer
      v-toolbar-items.hidden-sm-and-down
        v-breadcrumbs(:items="breadcrumbs" divider=">")
    v-container
      v-layout.pb-3(align-center)
        v-spacer
        v-btn.ma-2(small color='primary' outlined @click.prevent="cancel") Cancel
        v-btn.ma-2(small color='primary' @click.prevent="save") Save
      v-card
        v-container.white(grid-list-xs)
          v-layout(row wrap)
            v-flex(xs12)
              v-text-field(v-model="name" hint="Service Type" :persistent-hint="true" label="Service type" required)
            v-flex(xs12)
              v-textarea(v-model="description" hint="Service Description" :persistent-hint="true" label="Service Description")
        

</template>
<script>
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "service/serviceType/getServiceType",
  mutationType: "service/serviceType/updateServiceType"
});

export default {
  components: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Service Type",
        disabled: false,
        href: "/main/services/1"
      },
      {
        text: "Edit Service Type",
        disabled: true
      }
    ]
  }),
  computed: {
    ...mapFields({
      name: "name",
      description: "description"
    })
  },

  methods: {
    save: function() {
      if (this.name.length > 0) {
        this.$store.dispatch("service/serviceType/saveServiceType").then(() => {
          this.$router.push({ name: "services", params: { tabID: 1 } }).catch(()=>{});
        });
      }
    },

    cancel: function() {
      this.$router.push({ name: "services", params: { tabID: 1 } }).catch(()=>{});
    }
  },

  mounted() {
    if (this.$store.state.service.serviceType.currentIndex == -1) {
      // Load
      this.$store.dispatch(
        "service/serviceType/fetchServiceType",
        this.$route.params.serviceID
      );
    }
  }
};
</script>
<style scoped lang="stylus"></style>
