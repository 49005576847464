import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const ACCOUNTSETTING_API = "/account";

// ----------------------- STATE ------------------------- //
const state = {
  accountsetting_data: {},
  currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getAccountSetting: state => getField(state.accountsetting_data)
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updateAccountSetting(state, payload) {
    updateField(state.accountsetting_data, payload);
  },

  loadAllAccountSettings(state, payload) {
    state.accountsetting_data = payload.data;
    state.currentIndex = 0;
  },

  saveAccountSetting(state, payload) {
    state.accountsetting_data = payload.data;
  },

  reset(state) {
    state.accountsetting_data = {}
    state.currentIndex = -1
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllAccountSettings({ commit }) {
    try {
      commit(
        "loadAllAccountSettings",
        await Repository.get(`${ACCOUNTSETTING_API}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async saveAccountSetting({ state, commit }) {
    try {
      commit(
        "saveAccountSetting",
        await Repository.post(
          `${ACCOUNTSETTING_API}`,
          state.accountsetting_data
        )
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
