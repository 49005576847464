<template lang="pug">
  div(v-if="getCurrentWorkflow")
    v-container.mx-4(fluid)
      v-toolbar(dense, flat)
        v-toolbar-title
          .title.grey--text() Preview
        v-spacer
      v-container.pa-2
        v-layout()
          .align-center()
            v-btn(color="primary" @click="design")
              v-icon.mr-2(:size="20") exit_to_app
              | Design Form
            v-btn.ma-2(small text color="grey" @click="cancel") cancel
          v-spacer()
          .align-center()
            v-btn.ma-2(small outlined text color="grey" @click="validate") validate
            v-btn.ma-2(small outlined text  color="grey" @click="reset") reset

    v-container
      v-card
        v-container.grid-list-md
          v-form(ref="form")
            div()
              .d-flex.justify-space-between.align-center()
                div.pa-3()
                  .headline() {{getCurrentWorkflow.name}}
                  .subtitle-2() Here is the description
                div()
                  roles-auto-complete(v-if="$store.state.settings.roleSettings.role_data.length" v-model="selectedRoles" @remove="onRemoveRole" label="Permission Roles")

            v-divider()
            v-layout(row wrap justify-start)
              //- xs12 sm12 md6 lg6 xl4
              v-flex.px-3(v-if="canRead(item)" v-for="(item,index) in schemeData" :key="index" v-bind="item.is_full_width?fullWidth:autoWidth"  )
                computed( :is="getComponent(item.name)" v-bind="{schema:item, data:form_data[index], index:index}"  @onChange='onChange'  :disabled="isDisabled(item)")


</template>
<script>
import RolesAutoComplete from "@/modules/global/components/forms/RolesAutoComplete.vue";
import TextFieldFillForm from "@/modules/workflow/inspections/components/forms/TextFieldFillForm";
import CheckboxFillForm from "@/modules/workflow/inspections/components/forms/CheckboxFillForm";
import HeaderFillForm from "@/modules/workflow/inspections/components/forms/HeaderFillForm";
import TextAreaFillForm from "@/modules/workflow/inspections/components/forms/TextAreaFillForm";
import DateFillForm from "@/modules/workflow/inspections/components/forms/DateFillForm";
import TimeFillForm from "@/modules/workflow/inspections/components/forms/TimeFillForm";
import RadioFillForm from "@/modules/workflow/inspections/components/forms/RadioFillForm";
import SelectFillForm from "@/modules/workflow/inspections/components/forms/SelectFillForm";
import TableFillForm from "@/modules/workflow/inspections/components/forms/TableFillForm";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
import ImageFillForm from "@/modules/workflow/inspections/components/forms/ImageFillForm";
import SignatureFillForm from "@/modules/workflow/inspections/components/forms/SignatureFillForm";
import MapFillForm from "@/modules/workflow/inspections/components/forms/MapFillForm";
import SwitchFillForm from "@/modules/workflow/inspections/components/forms/SwitchFillForm";
import JahaziFillForm from "@/modules/inspections/components/formDesigner/fill/JahaziFillForm";
import BlankFillForm from "@/modules/workflow/inspections/components/forms/BlankFillForm";
import ReadOnlyFillForm from "@/modules/workflow/inspections/components/forms/ReadOnlyFillForm.vue";
import DateTimeFillForm from "@/modules/workflow/inspections/components/forms/DateTimeFillForm.vue";
export default {
  components: {
    RolesAutoComplete,
    TextFieldFillForm,
    CheckboxFillForm,
    HeaderFillForm,
    TextAreaFillForm,
    DateFillForm,
    TimeFillForm,
    RadioFillForm,
    SelectFillForm,
    TableFillForm,
    AttachFillForm,
    ImageFillForm,
    SignatureFillForm,
    MapFillForm,
    SwitchFillForm,
    BlankFillForm,
    JahaziFillForm,
    ReadOnlyFillForm,
    DateTimeFillForm
  },

  data: () => ({
    snackbar: false,
    snackbarColor: "primary",
    snackBarMessage: "",
    form_data: [],
    autoWidth: { ["xs12 sm12 md6 lg6 xl4"]: "" },
    fullWidth: { ["xs12"]: "" },
    is_full_width: true,
    selectedRoles:[],
  }),

  computed: {
    schemeData: {
      get: function() {
        // step 1: get the schema from DB
        let schema_data = this.$store.state.workflow.formBuilder.schema_data;


        // step 2: get the form data from DB
        return this.populateFormData(schema_data);
      },
      set: function(value) {
        console.log(value);
        // this.$store.commit("formBuilder/setSchema",value)
      }
    },

    getCurrentWorkflow: function() {
      return this.$store.getters["workflow/getCurrentWorkflow"];
    },

    flexSize: function(gridSize) {
      return gridSize;
    }
  },

  methods: {
    onRemoveRole:function(roleID){
      const searchIndex = this.selectedRoles.findIndex(element => element == roleID)
      if (searchIndex > -1) {
        this.selectedRoles.splice(searchIndex,1)
      }
    },

    onChange: function(item) {
      console.log("changed");
      console.log(item);
      this.form_data[item.index].value = item.value;
    },

    populateFormData: function(schema_data) {
      schema_data.forEach(item => {
        this.form_data.push({ id: item.id, value: item.default });
      });
      return schema_data;
    },

    cancel: function() {
      this.$store.dispatch("workflow/loadAllWorkflows");
      this.$router.push({
        name: "workflows"
      }).catch(()=>{})
    },
    design: function() {
      this.$router.push({ name: "templateBuilderForm" }).catch(()=>{});
    },

    save: function() {
      console.log("save");
    },

    validate: function() {
      if (this.$refs.form.validate()) {
        this.$store.commit("showSnackBar", {
          color: "primary",
          message: "Well Done you filled correctly"
        });
      } else {
        this.$store.commit("showSnackBar", {
          color: "error",
          message: "Error found in Form"
        });
      }
    },

    reset: function() {
      console.log("RRRRRRRRRRRRRRRRRRRRReeset");
      console.log(this.form_data);
      // this.$refs.form.reset();
    },

    getComponent: function(propertyName) {
      // Ensure the Renderer is Registered. the Registered Component has an appended `FillForm`
      if(propertyName === 'RouteID' || propertyName === 'RouteName' || propertyName === 'PlaceID' || propertyName === 'PlaceName'){
        return "BlankFillForm"
      }else{
        return `${propertyName}FillForm`;
      }

    },

    canRead: function(item){

      if(item.permissions == undefined || item.permissions.read.length == 0 ){
        return true;
      }

      // Get if Role has permision to read
      let isFound = false;
      for (let i=0; i<this.selectedRoles.length; i++){
        const searchIndex = item.permissions.read.findIndex(element => element == this.selectedRoles[i])
        if (searchIndex > -1){
          isFound = true;
          break;
        }
      }


      return isFound
    },

    isDisabled: function(item){


      if(item.permissions == undefined || item.permissions.write.length == 0 ){
        return false;
      }

      // Get if Role has permsion to write
      let isFound = true;
      for (let i=0; i<this.selectedRoles.length; i++){
        const searchIndex = item.permissions.write.findIndex(element => element == this.selectedRoles[i])
        if (searchIndex > -1){
          isFound = false;
          break;
        }
      }


      return isFound

    }
  },

  mounted() {
    if (this.$store.state.workflow.currentIndex == -1) {
      this.$store
        .dispatch("workflow/fetchWorkflow", this.$route.params.workflowID)
        .then(() => {
          this.$store.commit("workflow/formBuilder/setSchema",this.$store.getters["workflow/getLatestSchema"])
        });
    }

    if (this.$store.state.settings.roleSettings.currentIndex == -1){
      this.$store.dispatch("settings/roleSettings/loadAllRoles");
    }
  }


};
</script>
<style scoped lang="stylus">
.basicFormComponent {
  height: 90px;
  background-color: #CCCCCC;
}
</style>
