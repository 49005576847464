<template lang="pug">
  div() blank page
</template>

<script>
export default {
  name: "BlankSettings"
}
</script>

<style scoped lang="stylus">

</style>