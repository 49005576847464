<template lang="pug">
  #workflowOverview()
    .OverviewHeader.lighten-4(v-if="getCurrentWorkflow")
      v-toolbar(dense flat)
        generic-bread-crumbs.pl-2.py-1(:items="getBreadCrumbItems()")
        v-spacer
        v-menu(offset-y)
          template( #activator="{on}")
            v-btn.mx-3(v-on="on" color="primary" small outlined )
              | Export
              v-icon.pl-1(small) arrow_drop_down
          v-list
            v-list-item(v-for="(item, index) in items2" :key="index" dense @click="onAction(item.value)" )
              v-list-item-title {{ item.title }}
        v-btn.px-3(color="primary"  small @click="onRefresh")
          v-icon.pr-2(small) refresh
          | Refresh


      .HeaderBody.px-6
        v-layout(align-center)
          image-thumbnail.mr-3(v-if="getCurrentWorkflow.thumbnail_id" :width="80" :height="80"  :src="getCurrentWorkflow.thumbnail_id")
          .HeaderText
            h2.headline.font-weight-bold.grey--text.text--darken-4 {{getCurrentWorkflow.name}}
            p.ma-0.pa-0.caption.font-weight-regular.grey--text.text--darken-2 {{getCurrentWorkflow.description}}  &ndash; Latest Version · {{ getCurrentWorkflow.version }}
            v-divider.mt-1
            v-layout.caption()
              .HorizontalAlignDiv2()
                  span.grey--text.text--darken-2 {{getUpdatedAt}}
                  v-icon.pl-1(small) edit

              div
                v-menu(offset-y)
                  template( #activator="{ on }")
                    .HorizontalAlignDiv(v-on="on" color="primary" small outlined )
                      | {{getCurrentWorkflow.status}}
                      span.ml-2
                      v-icon.pl-1(small) arrow_drop_down
                  v-list(dense)
                    v-list-item(v-for="(item, index) in items1" :key="index" @click="onAction(item.title)")
                      v-list-item-title {{ item.title }}

              .HorizontalAlignDiv(@click="onAction('group')")
                p.ma-0.pa-0(v-if="getCurrentWorkflow.author") {{getCurrentWorkflow.author.group ? getCurrentWorkflow.author.group.name  : "No Group"}}
                  v-icon.pl-1(small) edit

              .HorizontalAlignDiv(@click="onAction('assignments')")
                p.ma-0.pa-0(v-if="getCurrentWorkflow.author") {{getCurrentWorkflow.author ? getCurrentWorkflow.author.first_name+' '+getCurrentWorkflow.author.last_name  : "Unassigned"}}
                  v-icon.pl-1(small) add_circle


        v-spacer
        .ServiceReminders.caption
          .Reminders.grey--text.text--darken-3.pl-2 Total Records
          .ReminderUnits
            span.subheading.font-weight-bold {{getCurrentWorkflow.record_count}}

    //- Body
    .mainWorkflowBody
      //- OverviewSideBar
      .OverviewSideBar
        v-btn.ma-3(small color="primary" style="width:90%" @click="onAddRecord")
          v-icon.mr-2(small) add
          | Quick Add
        vehicle-overview-side-bar-item.menuSpacing.mr-2.pl-4(v-for="(item,index) in overviewItems1" :key="index"   :label="item.label" :icon="item.icon" :value="item.value" :url="item.url" @onclick="onSideBar(item)")
        v-divider.mb-3.mt-3
        //- vehicle-overview-side-bar-item.menuSpacing.mr-2.pl-4(v-for="(item,index) in overviewItems2" :key="index"   :label="item.label" :icon="item.icon" :value="item.value" :url="item.url" @onclick="onSideBar(item)")
      <!-- ROUTER -->
      v-layout(fill-height)
        router-view(v-if="getCurrentWorkflow" :key="key")

      //- dialog
    v-dialog(v-if="modal" ref='modal' v-model='modal' @close='hideModal' max-width="1200px")
      v-card()
        v-card-title.primary.white--text() {{getCurrentWorkflow.name}}
        v-progress-linear(:indeterminate="showProgress" color="primary")
        v-container.grid-list-md.py-6(v-if="$store.state.workflow.workflowRecord.currentIndex > -1")
          v-form(ref="form")
            record-renderer(v-if="getRecordSchemaz()" )
        v-divider
        v-card-actions()
          v-spacer()
          v-btn(color="grey" outlined @click="hideModal") Cancel
          v-btn(color="primary" type="submit"  @click="saveRecord") Submit
    export-data-dialog(:fileExport="fileExport" :isFinished="isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="onCloseExportDialog" @download="onDownload" )
</template>

<script>
import moment from "moment";
import VehicleOverviewSideBarItem from "@/modules/global/components/others/OverviewSideBarItem";
import RecordRenderer from "@/modules/workflow/records/components/forms/RecordRenderer";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import Repository from "@/repository";
export default {
  components: {
    GenericBreadCrumbs,
    ImageThumbnail,
    VehicleOverviewSideBarItem,
    RecordRenderer,
    ExportDataDialog
  },
  data: ()=>({
    key:0,
    isDownloadFinished: false,
    fileLink: null,
    showExportDialog: false,
    fileExport: "",
    modal: false,
    showProgress: true,
    items1: [
      { title: "Add Record" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" }
    ],
    items2: [
      {
        title: "Export Excel",
        icon: "cloud_download",
        value: "export_excel",
      },
      // {
      //   title: "Export PDF",
      //   icon: "cloud_download",
      //   value: "export_pdf",
      // },
    ],
  }),

  created() {
    // stretch the elements to fill the height
    this.height = window.innerHeight - 115;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      // this.width = this.$refs.contentArea.getBoundingClientRect().width;
    });
  },


  computed: {
    getCurrentWorkflow: function() {
      return this.$store.getters["workflow/getCurrentWorkflow"];
    },

    getUpdatedAt: function () {
      return moment(this.getCurrentWorkflow.last_record_update_at).format("ddd, Do MMM YYYY, h:mm a");
    },

    overviewItems1: function() {
      let obj = [];

      obj.push({
        label: "Dashboard",
        icon: "dashboard",
        url: "workflowDashboard"
      });



      obj.push({
        label: "Records Table",
        icon: "dashboard",
        url: "workflowRecordsTable"
      });

      obj.push({
        label: "Metadata",
        icon: "dashboard",
        url: "workflowRecords"
      });

      obj.push({
        label: "Kanban Board",
        icon: "dashboard",
        url: "workflowBoardOverview"
      });

      obj.push({
        label: "Permissions & Approvals",
        icon: "dashboard",
        url: "workflowPermissions"
      });

      obj.push({
        label: "Automation",
        icon: "dashboard",
        url: "workflowProcess"
      });


      obj.push({
        label: "Settings",
        icon: "dashboard",
        url: "workflowSettings"
      });

      return obj;

    },

    overviewItems2: function() {
      let obj = [];

      obj.push({
        label: "Comments",
        icon: "dashboard",
        url: "workflowDashboard"
      });

      obj.push({
        label: "History",
        icon: "dashboard",
        url: "workflowDashboard"
      });

      obj.push({
        label: "Photos",
        icon: "photo",
        url: "workflowDashboard"
      });

      obj.push({
        label: "Videos",
        icon: "photo",
        url: "workflowDashboard"
      });
      return obj;
    }

  },
  methods: {
    saveRecord: function() {
      this.showProgress = true;
      this.$store.dispatch("workflow/workflowRecord/saveRecord").then(()=>{
        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Form ${this.$store.getters["workflow/workflowRecord/getCurrentRecord"].workflow_template_name} is saved`,
        });
        this.hideModal();
        this.onRefresh();
      })
    },

    getRecordSchemaz: function() {
      return this.$store.getters["workflow/getCurrentWorkflow"].data[this.$store.getters["workflow/workflowRecord/getCurrentRecord"].workflow_template_version-1].schema;
    },

    onSideBar:function(item){
      this.$router.push({
        name: item.url
      }).catch(()=>{});
      this.$store.commit("hideRightDrawer");
    },


    onRefresh:function(){
      if(this.$route.name == 'workflowBoard'){
        this.$store.dispatch("workflow/board/fetchboard",this.$route.params.workflowID)
      }else{
        this.$store.dispatch("workflow/workflowRecord/loadAllRecordsByID",this.$route.params.workflowID)
      }
      this.key += 1;
    },

    getBreadCrumbItems: function() {
      var theArray = [];
      theArray.push({
        text: "Workflow List",
        disabled: false,
        path: "workflows"
      });
      theArray.push({
        text: this.getCurrentWorkflow.name,
        disabled: true
      });
      return theArray;
    },

    onAddRecord() {
      this.$store.dispatch("workflow/workflowRecord/addRecord",this.$route.params.workflowID).then(()=>{
        this.modal = true;
        this.showProgress = false;
      })
    },
    hideModal() {
      this.modal = false;
      this.showProgress = false;
    },

    onAction: function(value) {
      switch (value) {
        case "export_csv":
          this.fileExport = "CSV";
          console.log(`/report/workflowRecords/CSV/${this.getCurrentWorkflow.id}`)
          this.downloadFile(`/report/workflowRecords/CSV/${this.getCurrentWorkflow.id}`);
          break;
        case "export_excel":
          this.fileExport = "Excel";
          console.log(`/report/workflowRecords/Excel/${this.getCurrentWorkflow.id}`)
          this.downloadFile(`/report/workflowRecords/Excel/${this.getCurrentWorkflow.id}`);
          break;
      }
      this.showExportDialog = !this.showExportDialog;
    },

    downloadFile: function(url) {
      this.isDownloadFinished = false;

      Repository({
        method: "GET",
        url: url,
        responseType: "blob",
      }).then((response) => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function() {
      // download the file
      this.fileLink.click();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function() {
      this.showExportDialog = false;
    },
  },

  mounted() {
    if (this.$store.state.workflow.currentIndex == -1) {
      this.$store
          .dispatch("workflow/fetchWorkflow", this.$route.params.workflowID)
          .then(() => {
            this.$store.commit("workflow/formBuilder/setSchema",JSON.parse(JSON.stringify(this.$store.getters["workflow/getLatestSchema"])))
            console.log(this.getCurrentWorkflow);
          });
    }else{
      this.$store.commit("workflow/setCurrentIndexByID",this.$route.params.workflowID)
    }
  }
}
</script>

<style scoped lang="stylus">
#workflowOverview{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.OverviewSideBar {
  width: 250px;
  height: 100%;
  background-color: #F5F5F5;
}
.contentBackground {

}


.mainWorkflowBody {
  border-top: 1px solid #CCCCCC;
  display: flex;
  position absolute;
  top 150px;
  bottom 0px;
  left 0px;
  right 0px;
}

.OverviewHeader {
  width: 100%;
  height: 150px;
}

.HeaderBody {
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeaderInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.HeaderText {
  display: flex;
  flex-direction: column;
}

.HeaderInfoButtons {
  font-size: 13px;
}

.HorizontalAlignDiv2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  // border 1px solid grey
  padding: 0px 2px 0px 0px;
  height: 30px;
  margin-right 10px;

}

.HorizontalAlignDiv:hover {
  cursor: pointer;
}

.ServiceReminders {
  width: 140px;
  height: 70px;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid #e5e5e5;
}

.Reminders {
  margin-bottom: 5px;
}

.VerticalDivider {
  border-left: 2px dotted #999999;
  margin: 0px 5px;
}

.ReminderUnits {
  display: flex;
}
</style>