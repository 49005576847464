<template lang="pug">
  div() 
    div() {{value | moment("dddd, Do MMM YYYY")}}
    timeago.grey--text(:datetime="value")

</template>
<script>
export default {
  props: ["value"],
  data: () => ({})
};
</script>
<style scoped lang="stylus"></style>
