import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";
import * as turf from "@turf/turf";

const PLACE_API = "/place";

// ----------------------- STATE ------------------------- //
const state = {
  place_data: [],
  currentIndex: -1,
  showAdvanced: null,
  refresh: false,
  placeSummary:[],                                                
  stops:[],
  stopsLoaded:true,
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getPlace: (state) => getField(state.place_data[state.currentIndex]),

  getCurrentPlace: (state) => {
    return state.place_data[state.currentIndex];
  },

  getPlaceSummary: (state) => {
    return state.placeSummary;
  },

      getPlaceByID: (state) => (id) => {
    return state.place_data.find((element) => element.id == id);
  },

  getPlacesHubs: (state) => {
    return state.place_data.filter( place => place.is_hub == true);
  },

  getFilterByGroup: (state) => (id) =>{
    if(id != null){
      return state.place_data.filter( place => place.group_id == id)
    }else{
      return state.place_data
    }
  },

  getPlacesInProximity: (state) => (proximityObj) => {
    let result = [];
    let distance = proximityObj.distance;
    let points = proximityObj.points;
    points.forEach(point => {
      let from = turf.point([point.lon,point.lat])

      state.place_data.forEach(place => {
        let to = turf.point([place.location.lon,place.location.lat])

        if(turf.distance(from,to) < distance){
          console.log(`distance: ${turf.distance(from,to)}`)
          result.push(place);
        }
      })
    })

    return result

  },

  getShowAdvanced: (state) => {
    console.log(`show advanced is: ${state.showAdvanced}`)
    console.log(`localstorage ${localStorage.getItem("placeShowAdvanced")}`)
    if (state.showAdvanced == null){
      let result = localStorage.getItem("placeShowAdvanced");
      console.log(result)
      console.log(state.showAdvanced);
      if(result == null){
        return false;
      }else{
        return (result === 'true')
      }
    }
    return state.showAdvanced;
  }




};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  showAdvanced(state,payload){
    localStorage.setItem("placeShowAdvanced",payload)
    state.showAdvanced = payload
  },

  updatePlace(state, payload) {
    updateField(state.place_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.place_data.findIndex(
      (place) => place.id == theID
    );
  },

  loadAllPlaces(state, payload) {
    state.place_data = payload.data;
    state.currentIndex = 0;
    state.refresh = false;
  },

  addPlace(state, payload) {
    state.place_data.push(payload.data);
    state.currentIndex = state.place_data.length - 1;
    console.log(state.place_data)
    state.refresh = true;
  },

  savePlace(state, payload) {
    state.place_data[state.currentIndex] = payload.data;

  },

  fetchPlace(state, payload) {
    state.currentIndex = state.place_data.findIndex((item) => item.id == payload.data.id)
    if (state.currentIndex == -1) {
      state.place_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.place_data[state.currentIndex] = payload.data;
    }
  },

  getStops(state,payload) {
    state.stops = payload.data.visits;
    state.stopsLoaded = false;
  },

  deletePlace(state, payload) {
    state.currentIndex = state.place_data.findIndex(
      (place) => place.id == payload.data.id
    );
    state.place_data.splice(state.currentIndex, 1);
  },

  deleteSelectedPlace(state, payload){
    payload.data.forEach(element => {
      let theIndex = state.place_data.findIndex(
          (place) => place.id == element
      );
      state.place_data.splice(theIndex, 1);
    })
  },

  duplicatePlace(state,payload){
    state.place_data.splice(state.currentIndex, 0,payload.data)
  },

  cleanUpDataBeforeSave(state) {
    state.place_data[state.currentIndex]["radius"] = parseInt(
      state.place_data[state.currentIndex]["radius"]
    );
  },
  reset(state) {
    state.place_data = []
    state.currentIndex = -1
    state.placeSummary = []
  },

  next(state){
    if(state.currentIndex == state.place_data.length-1){
      state.currentIndex = 0;
    }else{
      state.currentIndex += 1;
    }
  },

  previous(state){
    if(state.currentIndex == 0){
      state.currentIndex = state.place_data.length-1;
    }else{
      state.currentIndex -= 1;
    }
  },

  setRefresh(state,payload){
    state.refresh = payload;
  },

  placeSummary(state,payload){
    state.placeSummary = payload
  },

  setStopLoaded(state,payload) {
    state.stopsLoaded = payload
  },

  mapCustomers(state,customerList) {
    state.place_data.forEach(element => {
      if(element.customers_id != null){
        for (var i=0; i<element.customers_id.length;i++){
          var result  = customerList.find(item => item.id === element.customers_id[i]);
          if (typeof result !== 'undefined' && typeof result.name !== 'undefined'){
              if (typeof element.keywords === 'undefined'){
                element.keywords = result.name+" ";
              }else{
                element.keywords += result.name+" ";
              }
          }
        }
      }
    })
  },

  mapMerchants(state,merchantList) {
    state.place_data.forEach(element => {
      if(element.merchants_id != null){
        for (var i=0; i<element.merchants_id.length;i++){
          var result  = merchantList.find(item => item.id === element.merchants_id[i]);
          if (typeof result !== 'undefined' && typeof result.name !== 'undefined'){
            if (typeof element.keywords === 'undefined'){
              element.keywords = result.name+" ";
            }else{
              element.keywords += result.name+" ";
            }
          }
        }
      }
    })
  },

  mapAuthor(state,contactList) {
    state.place_data.forEach(element => {
      if(element.author_id  != null){
          var result  = contactList.find(item => item.id === element.author_id);
          if (typeof result !== 'undefined' && typeof result.first_name !== 'undefined'){
            if (typeof element.keywords === 'undefined'){
              element.keywords = result.first_name+" "+result.last_name;
            }else{
              element.keywords += result.first_name+" "+result.last_name;
            }
          }

      }
    })
  },



};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllPlaces({ commit }) {
    try {
      commit("loadAllPlaces", await Repository.get(`${PLACE_API}`));
    } catch (error) {
      console.log(error);
    }
  },



  async addPlace({ commit }) {
    await Repository.get(`${PLACE_API}/new`)
      .then((response) => {
        commit("addPlace", response);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async addPlacePost({ commit },payload) {
    await Repository.post(`${PLACE_API}/new`,payload)
        .then((response) => {
          commit("addPlace", response);
        })
        .catch((error) => {
          console.log(error);
        });
  },


  async savePlace({ state, commit }) {
    commit("cleanUpDataBeforeSave");
    try {
      commit(
        "savePlace",
        await Repository.post(
          `${PLACE_API}`,
          state.place_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchPlace({ commit }, payloadID) {
    try {
      commit("fetchPlace", await Repository.get(`${PLACE_API}/${payloadID}`));
    } catch (error) {
      console.log(error);
    }
  },

  async getStops({commit,state}) {
    commit("setStopLoaded",true);
    try{
      commit("getStops", await Repository.get(`/place/singlePlaceSummary?id=${state.place_data[state.currentIndex].id}&from=2023-01-01`))
    }catch(error) {
      console.log(error)
    }
  },

  async deletePlace({ commit }, payloadID) {
    try {
      commit(
        "deletePlace",
        await Repository.delete(`${PLACE_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deleteSelectedPlaces({ commit }, deleteSelectedIDs) {
    try {
      let idString = ''
      deleteSelectedIDs.forEach(element => {
        idString += "&id="+element
      })
      commit(
          "deleteSelectedPlace",
          await Repository.delete(`${PLACE_API}/deleteSelected?${idString}`)

      );
    } catch (error) {
      console.log(error);
    }
  },

  async duplicatePlace({state, commit}) {
    commit("cleanUpDataBeforeSave");
    try {
      commit(
          "duplicatePlace",
          await Repository.post(
              `${PLACE_API}/duplicate`,
              state.place_data[state.currentIndex]
          )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchPlaces({ commit }, searchTerm) {
    try {
      commit(
        "loadAllPlaces",
        await Repository.get(`${PLACE_API}/search?q=${searchTerm}`)
      );
    } catch (error) {
      console.log(error);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
