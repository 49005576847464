<template lang="pug">
  div
    v-container.my-0.pa-0(fluid)
      p.my-0 {{ schema.label }}
      v-radio-group.my-0(
        v-model="column" 
        v-bind="bindValues" 
        :error-messages="errorMessages" 
        :hint="schema.hint" 
        :persistent-hint="schema.hint != null"
        :mandatory="schema.required"
        )
        v-radio(v-for="(item,index) in schema.options" :key="index" :label="item" :value="item")
    
    

</template>
<script>
export default {
  props: {
    schema: Object
  },
  data: () => ({
    column: null,
    errorMessages: []
  }),

  computed: {
    bindValues: function() {
      let p = {};
      if (this.schema.orientation === "row") {
        p["row"] = true;
      }

      if (this.schema.orientation === "column") {
        p["column"] = true;
      }
      return p;
    }
  },

  mounted() {}
};
</script>
<style scoped lang="stylus"></style>
