<template lang="pug">
  table.reportTable.grey--text.text--darken-2(cellspacing="0" cellpadding="0")
    tr
      td.firstRow.firstColumn Type
      td.firstRow {{vehicle.type}}
      td.firstRow Status
      td.firstRow {{vehicle.status}}
    tr
      td.firstColumn Year
      td {{vehicle.year}}
      td Fuel Type
      td {{vehicle.fuel_type}}
    tr
      td.firstColumn Device
      td {{vehicle.device_id}}
      td Group
      td
        div(v-if="vehicle.group") {{vehicle.group.name}}
    tr
      td.firstColumn Current Meter
      td 
      td Current Meter Date
      td {{vehicle.odometer_date}}
    tr
      td.firstColumn Make
      td {{vehicle.make}}
      td Total Cost Per Mile
      td 
    tr
      td.firstColumn Model
      td {{vehicle.model}}
      td VIN/SN
      td {{vehicle.vin}}
    tr
      td.firstColumn Color
      td {{vehicle.color}}
      td License Plate
      td {{vehicle.number_plate}}

    tr
      td.firstColumn Current Operator
      td
        div(v-if="vehicle.operator") {{vehicle.operator.first_name}} {{vehicle.operator.last_name}}
      td Ownership
      td {{vehicle.ownership}}
    tr
      td.firstColumn Series
      td {{vehicle.series}}
      td Meter Usage per Day
      td 
    tr
      td.firstColumn Fuel Tank Capacity
      td {{vehicle.fuel_tank_capacity}}
      td Fuel Type
      td {{vehicle.fuel_type}}
    tr
      td.firstColumn Fuel MPG
      td {{vehicle.fuel_mpg}}
      td Manufacturer
      td {{vehicle.manufacturer}}
    tr
      td.firstColumn Body
      td {{vehicle.body}}
      td Body Sub Type
      td {{vehicle.body_sub_type}}
    tr
      td.firstColumn Length
      td {{vehicle.length}}
      td Wheel Base
      td 
    tr
      td.firstColumn Width
      td {{vehicle.width}}
      td Gross Weight
      td 
    tr
      td.firstColumn Height
      td 
      td Maximum Payload
      td 

    tr
      td.firstColumn Engine
      td 
      td Engine Cylinders
      td 
    tr
      td.firstColumn Engine Displacement (cc)
      td 
      td Engine Horsepower
      td 
    tr
      td.firstColumn Engine Aspiration
      td 
      td Transmission Drivetrain
      td 
    tr
      td.firstColumn Transmission Type
      td 
      td Transmission Gears
      td 

    tr
      td.firstColumn Front Suspension
      td 
      td Rear Suspension
      td 
    tr
      td.firstColumn Front Brakes
      td 
      td Rear Brakes
      td 
    tr
      td.firstColumn Tire Size
      td 
      td Tire Pressure
      td 
    tr
      td.firstColumn Fuel Tank Capacity
      td 
      td Fuel Mpg
      td 
    tr
      td.firstColumn Purchase Vendor
      td 
      td Purchase Odometer
      td 
    tr
      td.firstColumn Purchase Price
      td 
      td Purchase Comments
      td 
    tr
      td.firstColumn Warranty Expiration
      td 
      td Waranty Millage
      td 

  
</template>

<script>
export default {
  props: ["vehicle"],
};
</script>

<style lang="stylus" scoped>
.reportTable {
  width: 100%;
  font-size: 12px;
}



.reportTable .firstRow {
  border-top: 1px solid #cccccc;
}

.reportTable .firstColumn {
  border-left: 1px solid #cccccc;
}

.reportTable td {
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding: 3px 5px;
  width: 25%;
}
</style>
