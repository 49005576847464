<template>
  <div>
    <!-- Tabs Selector -->
    <div class="white pt-2">
      <v-tabs left height="32" class="tabs" slider-color="primary">
        <v-tab class="tabs"> Issues </v-tab>
        <v-tab-item> <issues-list-layout></issues-list-layout> </v-tab-item>

        <v-tab class="tabs"> Faults </v-tab>
        <v-tab-item> <issue-faults></issue-faults> </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import IssuesListLayout from "@/modules/issues/layouts/IssuesListLayout";
import IssueFaults from "@/modules/issues/layouts/IssuesFaults";
export default {
  components: {
    IssuesListLayout,
    IssueFaults
  }
};
</script>

<style scoped></style>
