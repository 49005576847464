<template lang="pug">
  div(v-if="singleLoaded")
    v-autocomplete(
      v-model="contact2"
      :items="theItems"
      :search-input.sync="search"
      item-text="first_name"
      item-value="id"
      :label="label"
      :placeholder= "placeholder"
      chips

      @focus="onFocus"
      @change="onChange"
      :loading="isLoading"
      :filter="onFilter"
      clearable
      :rules="rules"
      :disabled="disabled"
    )
      template(v-slot:selection="data")
        v-chip.chip--select-multi(:input-value="data.selected")
          image-thumbnail.mr-1(:width="35" :height="26"  :src="data.item.thumbnail_id")
          | {{data.item.first_name}} {{data.item.last_name}}

      template(v-slot:item="data")
        template(v-if="typeof data.item !== 'object'")
          v-list-item-content(v-text="data.item")
        template(v-else)
          image-thumbnail.mr-1(:width="60" :height="50"  :src="data.item.thumbnail_id")
          v-list-item-content
            v-list-item-title
              div {{data.item.first_name}} {{data.item.last_name}}
            v-list-item-title
              v-layout()
                div(v-if="data.item.group") {{data.item.group.name}}
                v-spacer
                .miniLabel.mx-1.grey.white--text(v-if="data.item.technician") Technician
                .miniLabel.mx-1.grey.white--text(v-if="data.item.operator") Operator   
                .miniLabel.mx-1.grey.white--text(v-if="data.item.employee") Employee   
</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import Repository from "@/repository";

export default {
  components: {
    ImageThumbnail
  },
  props: {
    value: String,
    label: {
      type: String,
      default: "Select Contact"
    },
    placeholder: {
      type: String,
      default: "Start typing a Contact Name"
    },
    rules: {
      type: Array,
      default: () => []
    },
    disabled:{
      type:Boolean,
      default:false,
    }
  },

  // watch: {
  //   value: function(oldVal,newVal){
  //     if(oldVal !== newVal){
  //       console.log("value has changed!!!!!")
  //       // this.loadContact();
  //     }
  //   }
  // },

  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    singleLoaded: false,
    allLoaded: false,
    contact2: null,
    search: null,
    theItems: [],
  }),


  methods: {
    jointName: function (theItem) {
      return theItem.first_name + " " + theItem.last_name;
    },

    setData: function (theData) {
      this.$emit("input", theData);
    },

    onChange: function (theValue) {
      this.$emit("input", theValue);
    },

    onFilter: function (item, queryText) {
      const hasValue = val => (val != null ? val : "");
      const query = hasValue(queryText);
      const toSearchText =
          item.first_name + " " + item.middle_name + " " + item.last_name;
      return (
          toSearchText
              .toString()
              .toLowerCase()
              .indexOf(query.toString().toLowerCase()) > -1
      );
    },


    onFocus: function () {
      this.isLoading = true;
      if (this.allLoaded == false) {
        Repository.get("/contact").then((response) => {

          this.theItems = response.data;
          this.isLoading = false;
          this.allLoaded = true;
        });
      } else {
        this.isLoading = false;
      }


    },

    loadContact: function () {
      if (this.value != null) {
        Repository.get(`/contact/${this.value}`).then((response) => {
          this.theItems = [response.data];
          this.contact2 = this.value;
          this.singleLoaded = true;


        });

      } else {
        this.singleLoaded = true;
      }
    },

    loadContacts: function () {
      this.contact2 = this.value;
      if (this.$store.state.contacts.currentIndex === -1) {
        this.isLoading = true;
        Repository.get("/contact").then((response) => {

          this.layout = response.data.dashboard;
        });
        // this.$store.dispatch("contacts/loadAllContacts").then(() => {
        //   this.isLoading = false;
        //   this.singleLoaded = true;
        //
        // });
      } else {
        this.singleLoaded = true;
      }
    }
  }
  ,

  mounted() {
    this.$nextTick(() => {
      this.loadContact();
    })
  }
}
;
</script>
<style lang="stylus" scoped>
.imageCrop
  width: 60px
  height: 40px
  color red
  border-radius 5px
  background-color #FF0000
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

.miniLabel
  padding 2px 5px
  font-size 11px
  border-radius 5px
  margin-bottom 3px;
</style>
