<template lang="pug">
div()
  div(v-if="theURL")
    //-- div.caption() id={{index}}_frame and src={{theURL}}
    iframe(:id="`${index}_frame`" :src="theURL  " frameBorder="0" :style="flowStyle" )
  div(v-else)
    div() {{theURL}}
</template>
<script>
import Repository from "@/repository";
import {nextTick} from "vue";

export default {

  props: {
    item:{
      type: Object,
    },
    index:{
      type:Number,
    }
  },

  data:()=>({
    isLoaded:false,
    theURL:null,
    flowStyle: {
      width: "100%",
      height: window.innerHeight - 90 + "px",
      "margin-top": "5px",
      "background-color":"#efefef"
    }
  }),

  methods:{
    parseURL:async function(itemURL){
      this.theURL = itemURL

      const regex = /grafana_token/g;
      const found = itemURL.match(regex);

      if (found != null && found.length > 0){
        await Repository.get("/user/getGrafanaToken").then((response)=>{
          this.theURL =  itemURL.replace("{{grafana_token}}",response.data.token)
          return this.theURL
        })
      }

      return this.theURL;
    }
  },


  mounted() {

    this.$nextTick(() => {
      this.parseURL(this.item.url)
    });

    // this.theURL = "https://grafana.jahazi.com/d/ddn55bv54i7swb/zoataka?orgId=1&from=1702238511305&to=1718049711306&auth_token="+ this.parseURL(this.item.url);

    // console.log(this.theURL)
  }
}
</script>
<style scoped lang="stylus">

</style>