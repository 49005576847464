<template lang="pug">
v-data-table(
  :headers="headers",
  :items="$store.state.workflow.workflow_data",
  item-key="id",
  :loading="loading",
  :single-select="false",
  show-select
)
  template(v-slot:progress)
    v-progress-linear(height="2", :indeterminate="loading")

  template(v-slot:header.name="{ header }")
    v-tooltip(bottom)
      template(#activator="{on}")
        span(v-on="on") {{ header.text }}
      span {{ header.tooltip }}

  template(v-slot:item.author="{ item }")
    div(v-if="item.author")
      div {{ item.author.first_name }} {{ item.author.last_name }}
      timeago.grey--text(:datetime="item.updated_at")

  template(v-slot:item.action="{ item }")
    v-row.mr-7(justify="end")
      more-drop-down-menu(
        :items="menuitems",
        @onClick="onClick",
        :theObject="item"
      )

  template(v-slot:item.name="{ item }")
    .rowColumn(@click="onCreateForm(item)") {{ item.name }}

  template(v-slot:item.version="{ item }")
    div
      div &mdash; {{ item.version }}
      .caption.grey--text {{ item.updated_at | moment('Do MMM YYYY') }}
</template>
<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import { searchMixin } from "@/mixin/searchListMixin";
export default {
  components: {
    MoreDropDownMenu
  },

  props: {
    search: String
  },

  mixins: [searchMixin],

  data: () => ({
    selected: null,
    loading: false,
    headers: [
      { text: "Form Name", value: "name", align: "left" },
      { text: "Latest Version", value: "version", align: "left" },
      {
        text: "Form Description",
        value: "description",
        align: "left"
      },
      { text: "Author", value: "author", align: "left" },
      { text: "Forms Filled", value: "record_count", align: "center" },
      { text: "Status", value: "status", align: "left" },
      { text: "Action", value: "action", align: "right" }
    ],

    menuitems: [
      { title: "Edit", icon: "table_chart", action: "edit" },
      { title: "Preview", icon: "remove_red_eye", action: "preview" },
      { title: "Duplicate", icon: "file_copy", action: "duplicate" },
      { title: "Delete", icon: "delete", action: "delete" }
    ],

    theItems: [
      {
        id: 0,
        name: "Invoice",
        description: "Invoice for Clients",
        status: "active"
      }
    ]
  }),

  methods: {
    onCreateForm: function(item) {
      this.$store.commit("workflow/formBuilder/resetFormBuilder");
        this.$store.commit("workflow/setCurrentIndexByID",item.id);
      // this.$store.dispatch("workflow/workflowRecord/filterRecords",{version:item.version,workflowTemplateId:item.id}).then(()=>{
        // add the record
        this.$store
            .dispatch("workflow/workflowRecord/addRecord", item.id)
            .then(() => {
              let theRecord = this.$store.getters[
                  "workflow/workflowRecord/getCurrentRecord"
                  ];
              this.$store.commit("workflow/setCurrentIndexByID", item.id);
              this.$store.commit(
                  "workflow/formBuilder/setSchema",
                  this._.clone(this.$store.getters["workflow/getLatestSchema"])
              );
              this.$router.push({
                name: "workflowRecordForm",
                params: { workflowRecordID: theRecord.id}
              }).catch(()=>{});
            });
      // });

    },
    onClick: function(value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("workflow/formBuilder/resetFormBuilder");
          this.$store.commit(
            "workflow/setCurrentIndexByID",
            value.theObject.id
          );
          // save the latest scheme
          this.$store.commit(
            "workflow/formBuilder/setSchema",
            this._.clone(
              value.theObject.data[value.theObject.data.length - 1].schema
            )
          );
          this.$router.push({
            name: "templateBuilderForm",
            params: { workflowID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          confirm(
            "Are you sure you want to delete the template?" +
              value.theObject.name
          ) &&
            this.$store.dispatch("workflow/deleteWorkflow", value.theObject.id);
          break;

        case "duplicate":
          console.log("duplicate the template");
          break;
        case "preview":
          this.$store.commit(
            "workflow/setCurrentIndexByID",
            value.theObject.id
          );
          // console.log(value.theObject.schema);
          this.$store.commit(
            "workflow/formBuilder/setSchema",
            value.theObject.schemac
          );
          this.$router.push({
            name: "templateFillForm",
            params: { workflowID: value.theObject.id }
          }).catch(()=>{});
          break;
      }
    }
  },

  mounted() {
    if (this.$store.state.workflow.currentIndex == -1) {
      this.$store.dispatch("workflow/loadAllWorkflows").then(() => {
        console.log("downloaded worflow designs");
      });
    }
  }
};
</script>
<style scoped lang="stylus">
.rowColumn {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.rowColumn:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
