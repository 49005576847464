<template lang="pug">
  .ma-2
    v-tabs.tabs(v-model="tabSelected" slider-color='primary',  background-color="grey lighten-3" :height="35" )
      v-tab.tabs
        | Open
        .badge(v-if="$store.getters['issues/getIssueByStatus']('open').length > 0") {{$store.getters["issues/getIssueByStatus"]('open').length}}
      v-tab.tabs 
        | Overdue
        .badge(v-if="$store.getters['issues/getIssueByStatus']('overdue').length > 0") {{$store.getters["issues/getIssueByStatus"]('overdue').length}}
      v-tab.tabs 
        | Resolved
        .badge(v-if="$store.getters['issues/getIssueByStatus']('resolved').length > 0") {{$store.getters["issues/getIssueByStatus"]('resolved').length}}
      v-tab.tabs 
        | Closed
        .badge(v-if="$store.getters['issues/getIssueByStatus']('closed').length > 0") {{$store.getters["issues/getIssueByStatus"]('closed').length}}
    
    v-container(fluid)
      v-layout(row, wrap)
        v-spacer()
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on" :disabled="!selected.length > 0")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list-item-group()
              v-list()
                v-list-item(v-for='item in actionItems', :key='item.title')
                  v-list-item-title {{ item.title }}

    
    v-data-table(
      v-model="selected"
      :headers="headers" 
      :items="getFilteredList" 
      item-key="id" 
      :loading="loading"  
      :single-select="false" 
      show-select
      )
      template(v-slot:progress)
        v-progress-linear(slot="progress"  height="2" :indeterminate="loading")

      template(v-slot:item.name="{item}")
        issue-column(:issue="item")

      template(v-slot:item.closed="{item}")
        div(v-if="item.closed_by")
          .caption() {{item.closed_date_time | moment("ddd, Do MMM YYYY, h:mm a") }}
          .IssueSmallText() Closed by: 
            span.primary--text() {{item.closed_by.first_name}} {{item.closed_by.last_name}}
          timeago.primary--text.caption(:datetime="item.closed_date_time")

      template(v-slot:item.resolved="{item}")
        div(v-if="item.service_date_time")
          .IssueSmallText() Resolved on: 
            span.underline.primary--text(@click="onSelectServiceResolve(item.service_id)") {{item.service_date_time | moment("ddd, Do MMM YYYY, h:mm a") }}
          timeago.primary--text.caption(:datetime="item.service_date_time")

      template(v-slot:item.due="{item}")
        div(v-if="item.due_date_time")
          .IssueSmallText() {{item.due_date_time | moment("ddd, Do MMM YYYY, h:mm a") }}
          timeago.primary--text.caption(:datetime="item.due_date_time")

      template(v-slot:item.reported_by="{item}")
        div(v-if="item.reported_by")
          .caption.grey--text() Reported By: &nbsp;
            span.primary--text.font-weight-bold.underline() {{item.reported_by.first_name}} {{item.reported_by.last_name}}
        div(v-if="item.assigned_to")
          .caption.grey--text() Assigned To: &nbsp;
            span.font-weight-bold.underline() {{item.assigned_to.first_name}} {{item.assigned_to.last_name}}
        .d-flex.mt-2()
          IconChip(icon="photo" :list="item.photos_id")
          IconChip.ml-4(icon="insert_drive_file" :list="item.documents_id")
          //- IconChip.ml-4(icon="comment" :list="77")

      template(v-slot:item.photos="{item}")
        v-row(v-if="item.photos_id")
          image-thumbnail.ml-2(v-for="(itemID,index) in item.photos_id" :key="index" :width='60', :height='60', :src="itemID")
          

      template( v-slot:item.action="{ item }")
        v-row.mr-7(justify="end")
          more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

    


</template>
<script>
import ContactColumn from "@/modules/global/components/table/ContactColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import IconChip from "@/modules/global/components/table/IconChip";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import IssueColumn from "@/modules/global/components/table/IssueColumn";
export default {
  components: {
    MoreDropDownMenu,
    IssueColumn,
    ContactColumn,
    ImageThumbnail,
    IconChip
  },

  watch: {
    tabSelected: function(value) {
      this.status = this.toggleItems[value];
    }
  },
  data: () => ({
    selected: [],
    status: "open",
    loading: false,
    tabSelected: 0,
    toggleItems: ["open", "overdue", "resolved", "closed"],
    actionItems: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ]
  }),
  computed: {
    headers: function() {
      let tempHeaders = [
        { text: "Issue", value: "name", tooltip: "Issue", align: "left" }
      ];

      switch (this.status) {
        case "closed":
          tempHeaders.push({
            text: "Closed",
            value: "closed",
            align: "left"
          });
          break;
        case "resolved":
          tempHeaders.push({
            text: "Resolved",
            value: "resolved",
            align: "left"
          });
          break;
        case "overdue":
          tempHeaders.push({
            text: "Due Date",
            value: "due",
            align: "left"
          });
          break;
      }

      tempHeaders.push({
        text: "Reported / Assigned",
        value: "reported_by",
        tooltip: "Reported By",
        align: "left"
      });

      tempHeaders.push({
        text: "Photos",
        value: "photos",
        align: "left"
      });

      tempHeaders.push({
        text: "Action",
        value: "action",
        align: "right"
      });
      return tempHeaders;
    },

    getFilteredList: function() {
      return this.$store.getters["issues/getIssueByStatus"](this.status);
    },

    menuitems: function() {
      let tempMenuItems = [
        { title: "Edit", icon: "edit", action: "edit" },
        { title: "Delete", icon: "delete", action: "delete" }
      ];

      switch (this.status) {
        case "open":
          tempMenuItems.unshift({
            title: "Close Issue",
            icon: "close",
            action: "closeIssue"
          });
          tempMenuItems.unshift({
            title: "Add to Work order",
            icon: "assignment",
            action: "workorder"
          });
          tempMenuItems.unshift({
            title: "Resolve via Service Entry",
            icon: "build",
            action: "service"
          });

          break;
        case "overdue":
          tempMenuItems.unshift({
            title: "Close Issue",
            icon: "close",
            action: "closeIssue"
          });
          tempMenuItems.unshift({
            title: "Add to Work order",
            icon: "assignment",
            action: "workorder"
          });
          tempMenuItems.unshift({
            title: "Resolve via Service Entry",
            icon: "build",
            action: "service"
          });

          break;
        case "closed":
          tempMenuItems.unshift({
            title: "Re-Open Issue",
            icon: "lock_open",
            action: "openIssue"
          });

          break;
      }
      return tempMenuItems;
    }
  },
  methods: {
    getPhotos: function(list) {
      if (list.length > 2) {
        return list.slice(0, 2);
      } else {
        return list;
      }
    },
    onClick: function(value) {
      // console.log(value.theObject);
      switch (value.action) {
        case "edit":
          this.$store.commit("issues/setCurrentIndexByID", value.theObject.id);
          this.$router.push({
            name: "issueDetailsForm",
            params: { issueID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          confirm(
            "Are you sure you want to delete issue: " + value.theObject.name
          ) && this.$store.dispatch("issues/deleteIssue", value.theObject.id);
          break;
        case "history":
          console.log("history");
          break;
        case "closeIssue":
          this.$store
            .dispatch("issues/closeIssue", value.theObject.id)
            .then(() => {
              // or this.$emit("changeState", "closed");
              this.$store.dispatch("issues/loadAllIssues");
            });
          break;
        case "openIssue":
          this.$store
            .dispatch("issues/openIssue", value.theObject.id)
            .then(() => {
              // or  this.$emit("changeState", "open");
              this.$store.dispatch("issues/loadAllIssues");
            });
          break;
        case "service":
          console.log("resolve by service");
          break;
      }
    },

    onSelectServiceResolve: function(serviceID) {
      this.$router.push({
        name: "serviceDetailForm",
        params: { serviceID: serviceID }
      }).catch(()=>{});
    }
  }
};
</script>
<style scoped lang="stylus">
.badge {
  background-color: #666666;
  padding: 2px 2px 2px 3px;
  width: 17px;
  height: 17px;
  color: white;
  border-radius: 50%;
  font-size: 11px;
  margin-left: 5px;
  font-weight: bold;
}
</style>
