<template lang="pug">
  div
    v-divider
    v-container.pa-2(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Items
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item-group(color="primary")
                v-list-item(v-for='item in items', :key='item.title')
                  v-list-item-title {{ item.title }}
        v-btn.ma-2(small, color='primary')

          v-icon.pr-2(dark) attachment
        v-btn.ma-2(small, color='primary', dark, @click='openEdit') Add Items
      v-divider
      v-layout.ma-4(row align-center)
        v-row.fill-width()
          .d-flex.mx-4.fill-width()
            .d-flex.align-center.fill-width()
              v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" style="max-width:300px")
              v-icon(v-if="search.length > 0" color="primary" outlined small @click="search=''") close
              v-select.ml-6(:items='$store.state.settings.groupSettings.group_data', item-text='name', item-value='id', @focus='onGroupSelectFocus', prepend-icon='group', v-model='group', label='Group' clearable style="max-width:350px")
              v-spacer()
              v-btn.ml-4(v-if="this.$store.getters['inventory/item/getSelectedItems'].length" color="primary" outlined @click="clearAllSelection") Clear All {{this.$store.getters['inventory/item/getSelectedItems'].length}} Selected Suppliers

      v-divider
      .grey.lighten-4.py-3
      v-divider
      items-table(:search="search" :group="group")
      v-divider
</template>

<script>
import ItemsTable from "@/modules/inventory/items/components/tables/ItemsTable";
import ImportDataDialog from "@/modules/global/components/dialog/ImportDataDialog";

export default {
  components: {
    ItemsTable,
    ImportDataDialog
  },

  data: () => ({
    dialog: false,
    active: true,
    search: "",
    group: null,
    tags: ["Vehicle Parts", "Equipment", "Consumables"],
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ],

    searchFilter: ""
  }),

  methods: {
    openEdit: function () {
      this.$store.dispatch("inventory/item/addItem").then(() => {
        let currentObject = this.$store.state.inventory.item.item_data[
            this.$store.state.inventory.item.currentIndex
            ];
        this.$router.push({
          name: "itemDetailsForm",
          params: {itemID: currentObject.id}
        }).catch(()=>{});
      });
    },

    showDialog: function () {
    },

    closeDialog: function (value) {
      console.log(value);
    },
    onAction: function (value) {
      console.log(value)
      switch (value) {
        case "Import CSV":
          this.dialog = true;
          break;
        case "Export CSV":
          console.log("Exportinggggg");
          break;
      }
    },
    clearAllSelection: function () {
      this.$store.commit("inventory/item/setSelectedItems", []);
    },
    onGroupSelectFocus: function () {
      // Load The group List
      if (this.$store.state.settings.groupSettings.currentIndex == -1) {
        this.$store.dispatch("settings/groupSettings/loadAllGroups");
      }
    },
  },

  mounted() {
    // console.log(this.$vuetify.theme.primary);
    this.$store.commit("inventory/reset")
    this.toggle;
  }
};
</script>

<style scoped></style>
