<template lang="pug">
  div(v-if="this.$store.state.purchaseOrder.currentIndex > -1")
    v-data-table.elevation-1(:headers='headers', :items='$store.state.purchaseOrder.purchaseorder_data')
      template(slot='items', slot-scope='props')
        td.text-xs-left
          div
            div ItemLocation
            div.caption.grey--text sub item Location
        td.text-xs-left
          div Vendor
        td.text-xs-left
          div
            div Details
            .caption.grey--text moreDetails
        td.text-xs-left Statis

        td.text-xs-left Total
        td.text-xs-right
          more-drop-down-menu(:items="menuitems" @onClick="onClick" :theObject="props.item")
      template(slot='no-data')
        v-alert(:value='true', color='error', icon='warning')
          | Sorry, nothing to display here :(

</template>
<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";

export default {
  components: {
    MoreDropDownMenu
  },

  data: () => ({
    headers: [
      {
        text: "Item Location",
        value: "itemLocation"
      },
      {text: "Vendor", value: "vendor"},
      {text: "Details", value: "details"},
      {text: "Status", value: "status"},
      {text: "Total", value: "total"},
      {text: "Action", value: "action", align: "right"}
    ],

    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ]
  }),

  methods: {
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit(
              "purchaseOrder/setCurrentIndexByID",
              value.theObject.id
          );
          this.$router.push({
            name: "purchaseOrderDetailsForm",
            params: {itemID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          console.log("delete");
          this.$store.dispatch(
              "purchaseOrder/deletePurchaseOrder",
              value.theObject.id
          );
          break;
      }
    }
  },

  mounted() {
    if (this.$store.state.purchaseOrder.currentIndex == -1) {
      // load Data
      this.$store
          .dispatch("purchaseOrder/loadAllPurchaseOrders")
          .then(() => {
          });
    }
  }
};
</script>
<style lang="stylus" scoped></style>
