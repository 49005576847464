<template lang="pug">
  .SideBarItem.pa-1(@click="$emit('onclick',url)" :style="styleObject")
    v-icon.pr-2.ml-1(small :style="styleObject") {{icon}}
    div.text--darken-3 {{label}}
    v-spacer
    div.mr-2() {{value}}
</template>
<script>
import colors from "vuetify/lib/util/colors";
export default {
  props: {
    label: String,
    value: Number,
    icon: String,
    url: String
  },
  computed: {
    styleObject: function() {
      let obj = {};
      if (this.$route.name == this.url) {
        obj.color = this.$vuetify.theme.themes.light.primary;
        obj["background-color"] = colors.grey.lighten3;
      } else {
        obj.color = colors.grey.darken1;
      }
      return obj;
    }
  }
};
</script>
<style scoped lang="stylus">
.SideBarItem {
  display: flex;
  align-items: center;
  font-size: 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.SideBarItem:hover {
  background-color: #CCCCCC;
  cursor: pointer;
}
</style>
