<template lang="pug">
  v-card.pb-3
    v-form(ref="form", v-model="valid" lazy-validation)
      v-row.ma-0.grey.lighten-2()
        vehicle-header(:vehicle="getSelectedLayer")
        v-btn.mr-3.mt-3(text @click="$emit('close')")
          v-icon() close
      v-card-text.py-0()
        v-container.py-0()
          v-row()
            v-col(cols='12')
              div() --{{assignment.contact_id}}--
              contact-auto-complete(v-model='contact_id')
              //contact-auto-complete(v-model="contact" :rules="[v => !!v || 'Operators Contact is Required']")
              v-row(justify="space-between")
                v-checkbox.ml-2(v-model="email_notification" label='Send email notification' :hide-details="true")
                v-checkbox.mr-2(v-model="sms_notification" label='Send sms notification' :hide-details="true")
            v-col.py-0.mt-4(cols='12')
              v-row()
                v-col()
                  .roundedRect
                    date-time-picker(v-model="tz_start", labelDate="Start Date", labelTime="Start Time")
                v-col()
                  .roundedRect
                    date-time-picker(v-model="tz_end", labelDate="End Date", labelTime="End Time")

            v-col.py-0.mt-4(cols='12')
              v-textarea(v-model="description" outlined label="comments", :rows="3")
      v-card-actions.mx-4()
        v-btn(color='primary', outlined text, @click="$emit('delete')") Delete
        v-spacer
        v-btn(color='grey', text, @click="$emit('close')") Close
        v-btn(color='primary',  @click="validate") Save

</template>
<script>
import DateTimePicker from "@/modules/global/components/forms/DateTimePicker";
import VehicleHeader from "@/modules/vehicles/components/forms/VehicleHeader";
import ContactAutoComplete from "@/modules/global/components/forms/ContactAutoComplete";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById";

export default {
  components: {
    ContactColumnById,
    DateTimePicker,
    VehicleHeader,
    ContactAutoComplete,

  },
  props: {},
  data: () => ({
    valid: true
  }),
  computed: {
    getSelectedLayer: function () {
      return this.$store.getters["vehicles/assignments/getSelectedLayer"];
    },

    assignment: {
      get: function () {
        return this.$store.getters[
            "vehicles/assignments/getSelectedAssignment"
            ];
      },
      set: function (value) {
        this.$store.commit("vehicles/assignments/setAssignment", value);
      }
    },

    contact_id: {
      get: function () {

        console.log(this.assignment.contact_id);
        return this.assignment.contact_id;
      },
      set: function (value) {
        this.$store.commit("vehicles/assignments/setContact", value);
      }
    },

    tz_start: {
      get: function () {
        return this.assignment.during.tz_start;
      },
      set: function (value) {
        this.$store.commit("vehicles/assignments/setTzStart", value);
      }
    },

    tz_end: {
      get: function () {
        return this.assignment.during.tz_end;
      },
      set: function (value) {
        // console.log(`the for time is: ${value}`);
        this.$store.commit("vehicles/assignments/setTzEnd", value);
      }
    },
    description: {
      get: function () {
        return this.assignment.description;
      },
      set: function (value) {
        this.$store.commit("vehicles/assignments/setDescription", value);
      }
    },

    email_notification: {
      get: function () {
        return this.assignment.email_notification;
      },
      set: function (value) {
        this.$store.commit("vehicles/assignments/setEmailNotification", value);
      }
    },
    sms_notification: {
      get: function () {
        return this.assignment.sms_notification;
      },
      set: function (value) {
        this.$store.commit("vehicles/assignments/setSmsNotification", value);
      }
    },
    start_odometer: {
      get: function () {
        return this.assignment.start_odometer;
      },
      set: function (value) {
        this.$store.commit("vehicles/assignments/setStartOdometer", value);
      }
    },

    end_odometer: {
      get: function () {
        return this.assignment.end_odometer;
      },
      set: function (value) {
        this.$store.commit("vehicles/assignments/setEndOdometer", value);
      }
    }
  },

  methods: {
    validate: function () {
      if (this.$refs.form.validate()) {
        this.$store
            .dispatch("vehicles/assignments/updateSchedule")
            .then(() => {
              this.$emit("save");
            })
            .catch(error => {
              this.$store.commit("showSnackBar", {
                color: "error",
                message: error
              });

              this.$store.dispatch("vehicles/assignments/loadAllSchedules");
              this.$emit("close");
            });
      }
    }
  },

  mounted() {
  }
};
</script>
<style lang="stylus" scoped>
.roundedRect {
  background-color #EEEEEE;
  border-radius: 10px;
  padding 0px 10px;
}
</style>
