<template lang="pug">
div
  v-container.pt-4(grid-list-xl)
    v-layout(row wrap)
      v-flex(xs12)
        v-card.my-3(color="white")
          overview-information-panel(:items="weight.items", :title="weight.title", @edit="onEdit")

</template>
<script>
import OverviewInformationPanel from "@/modules/global/components/others/OverviewInformationPanel";
export default {
  components: {
    OverviewInformationPanel
  },
  data: () => ({
    weight: {
      title: "Purchase Details",
      items: [
        { label: "Purchase Vendor", value: "" },
        { label: "Purchase Price", value: "" },
        { label: "Purchase Date", value: "" },
        { label: "Purchase Odometer", value: "" },
        { label: "Waranty Expiration Date", value: "" },
        { label: "Waranty Expiration Meter", value: "" },
        { label: "Purchase Comments", value: "" }
      ]
    }
  }),

  methods: {
    onEdit: function(titlename) {
      console.log(titlename);
    }
  }
};
</script>
<style lang="stylus" scoped></style>
