<template lang="pug">
  v-combobox(
      v-model="computedValue"
      :label="schema.label"
      :hint="schema.hint"
      :persistent-hint="schema.hint != null"
      :items="items"
      v-bind="bindValues"
      :disabled="disabled"
    )
    template(v-if="schema.multiple" v-slot:selection="{ item, parent, selected }")
      v-chip(small close @click:close="removeItem(item)")
        | {{item}}
</template>
<script>
export default {
  props: ["schema", "dataValue", "index","disabled"],
  data: () => ({}),
  computed: {
    computedValue: {
      get: function() {
        return this.dataValue;
      },
      set: function(theValue) {
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    },

    items: function() {
      console.log(typeof this.schema.type);
      return this.schema.options;
    },

    bindValues: function() {
      let p = {};

      if (this.schema.item_text != null) {
        p["item-text"] = this.schema.item_text;
      }

      if (this.schema.item_value != null) {
        p["item-value"] = this.schema.item_value;
      }

      if (this.schema.multiple == true) {
        p['multiple'] = '';
        p['small-chips'] = '';
      }

      return p;
    }
  },

  methods: {
    removeItem: function (item) {

      let array = this._.clone(this.computedValue);
      if (this.schema.type == "Simple") {
        // for Simple
        const index = array.indexOf(item);
        if (index > -1) {
          array.splice(index, 1);
          this.computedValue = array;
        }
      } else {

        // for APIS
        const index = array.findIndex(e => e.id == item.id);
        if (index > -1) {
          array.splice(index, 1);
          this.computedValue = array;
        }
      }

    }
  },
};
</script>
<style scoped lang="stylus"></style>
