<template lang="pug">

  v-layout.moneyBorder(row justify-end style="margin-left:-10px;"  )
    v-select(:items="items" v-model="selected" hide-details style="top:-18px; width:0px; left:9px;" item-text="symbol" item-value="name" @change="onChange" )
        template( v-slot:item="{item, index}")
          | {{item.label}}
        template( v-slot:selection="{item, index}")
          div.grey--text.body-1 {{item.symbol}}

      
 

    template(v-if="selected=='fixed'")
      input.baseStyle3(ref='theInput' :type="type", :value='value.value', @input="onInput"   :disabled="disabled" :style="[disabled ?  disabledStyle: isFocused ? focusStyle : ''  ]" @focus="isFocused=true" @blur="isFocused=false")
    template(v-else)
     
      span.thePercentAmount.grey--text.text--lighten-1(style="left:50px; position: relative") (14568)
      input.baseStyle3(ref='theInput' :type="type", :value="value.value", @input="onInput"   :disabled="disabled" :style="[disabled ?  disabledStyle: isFocused ? focusStyle : ''  ]" @focus="isFocused=true" @blur="isFocused=false") 
      span(style="margin-top:5px;") %
</template>
<script>
export default {
  props: ["value", "items", "prefix", "type", "disabled"],

  data: () => ({
    isFocused: false,
    selected: null
  }),

  methods: {
    onChange: function() {
      // console.log(this.selected)
      // console.log("hello")
      this.$emit("input", { mode: this.selected, value: this.value.value });
    },

    onInput: function(item) {
      this.$emit("input", { mode: this.selected, value: item.target.value });
    }
  },

  computed: {
    focusStyle: function() {
      return {
        "border-color": this.$vuetify.theme.primary
      };
    },

    disabledStyle: function() {
      return {
        "border-color": "#CCCCCC",
        "background-color": "#DEDEDE",
        color: "#444444"
      };
    }
  },

  mounted() {
    this.selected = this.value.mode;
  }
};
</script>
<style scoped lang="stylus">

.baseStyle3 {

  min-width: 80px;
  width: 100%;

  text-align: right;

  background-color: none;
  float left

}

.prefixSymbol2 {


    border-radius: 5px;
    border 1px solid #BBBBBB;



}
.moneyBorder {
    border-radius: 5px;
    border 1px solid #BBBBBB;
    height 32px;
    padding 0px 5px;


}
.smallerMoneyText {
    /*font-size 12px;*/
}

.borderTextInput {
  display flex;
}

.thePercentAmount{
  // margin-left 50px;
  margin-top 5px;
}
</style>
