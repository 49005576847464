<template lang="pug">
  div 
    panel-title-header(label="Assigned Vehicles" :btnVisible="false" btnLabel="Edit" btnIcon="edit")
    .snippetHolder
      //- contact-snippet.pa-2(:contact="$store.getters['contacts/getCurrentContact']")
      vehicle-column(v-if="getContact.assignment_vehicle" :vehicle="getContact.assignment_vehicle")

</template>
<script>
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
export default {
  components: {
    PanelTitleHeader,
    VehicleColumn
  },
  computed: {
    getContact: function() {
      let contact = this.$store.getters["contacts/getCurrentContact"];
      return contact;
    }
  },
  data: () => ({})
};
</script>
<style scoped lang="stylus">
.snippetHolder {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
