<template lang="pug">
  div()
    panel-title-header(label="Captured Data")
    div.mb-2.pb-2
      table.DetailTable.body-2.fill-width
        tr(v-for="(item,index) in schema", :key="index" )
          template(v-if="item.name == 'TextField' || item.name == 'TextArea' ")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td()
              .pr-6 {{getItem(item.id)}}
          template(v-if="item.name == 'Header'")
            td.grey.white--text.font-weight-bold.subtitle-2.py-1.text--darken-1(colspan="2")
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
          template(v-if="item.name == 'ReadOnly'")
            td.grey--text.text--darken-1
              .d-flex
                .ml-3 {{item.label}}
            td()
              div(v-if="getItem(item.id)")
                template(v-if="getSchemaItem(item.id).type=='timestamp'")
                  .pr-6 {{getItemDateTime(item.id)}}
                template(v-else-if="getSchemaItem(item.id).type=='location'")
                  .locationItem(@click="onShowMap(item.id)")
                    v-icon.mr-2() place
                    | {{getItem(item.id).lat.toFixed(5)}}, {{getItem(item.id).lon.toFixed(5)}}
                template(v-else)
                  .pr-6 {{getItem(item.id)}}
          template(v-if="item.name == 'DateTime'")
            td.grey--text.text--darken-1
              .d-flex
                .ml-3 {{item.label}}
            td()
              .pr-6 {{getItemDateTime(item.id)}}
          template(v-if="item.name == 'Jahazi'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td()
              .pr-6
                template(v-if="item.type == 'customer'")
                  customer-column-by-id(:customer_id="getItem(item.id)")
                template(v-else-if="item.type == 'vehicle'")
                  vehicle-column-by-id(:vehicle_id="getItem(item.id)")
                template(v-else-if="item.type == 'contact'")
                  contact-column-by-id(:contact_id="getItem(item.id)")
                template(v-else-if="item.type == 'merchant'")
                  merchant-column-by-id(:merchant_id="getItem(item.id)")
                template(v-else-if="item.type == 'group'")
                  group-column-by-id(:group_id="getItem(item.id)")
                template(v-else)
                  div() {{getItem(item.id)}}
          template(v-if="item.name == 'Date'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td()
              .pr-6 {{getItemDate(item.id)}}
          template(v-if="item.name == 'Switch'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td()
              .pr-6 {{getItemSwitchLabel(item.id)}}
          template(v-if="item.name == 'Time'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td()
              .pr-6 {{getItemTime(item.id)}}
          template(v-if="item.name == 'Checkbox'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td()
              .pr-6 {{getItemCheckboxLabel(item.id)}}
          template(v-if="item.name == 'Select' || item.name == 'Radio'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td()
              .pr-6 {{getItem(item.id)}}
          template(v-if="item.name == 'Image' || item.name == 'Attach'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td()
              .d-flex()
                div(v-for="(blob,index) in getItem(item.id)" :key="index")
                  blob-item-by-id(:id="blob" size="small" :showDownloadMenuItem="true" @onMenuAction="onBlobAction" )
          template(v-if="item.name == 'Signature'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td(style="max-width:150px;")
              .d-flex()
                blob-item-by-id(v-if="getItem(item.id)" :id="getItem(item.id)" :isSignature="true" size="small" :showDownloadMenuItem="true" @onMenuAction="onBlobAction" )
          template(v-if="item.name == 'Draw'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td(style="max-width:150px;")
              .d-flex()
                blob-item-by-id(v-if="getItem(item.id)" :id="getItem(item.id)" :isSignature="true" size="medium" :showDownloadMenuItem="true" @onMenuAction="onBlobAction" )

          template(v-if="item.name == 'Map' && typeof getItem(item.id) =='object'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td()
              .pr-6(v-if="getItem(item.id)")
                .locationItem(@click="onShowMap(item.id)")
                  v-icon.mr-2() place
                  | {{getItem(item.id).lat.toFixed(5)}}, {{getItem(item.id).lon.toFixed(5)}}
          template(v-if="item.name == 'Table'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3 {{item.label}}
            td.pb-0()
              table.tableViewRender(v-if="getItem(item.id)")
                // Header Rows
                tr()
                  th(v-for="(itemSchema,index) in getSchemaItem(item.id).columns" :key="index" :style="getTableHeaderStyle(itemSchema)") {{itemSchema.label}}
                // Data rows
                tr(v-for="(row,rowIndex) in getItem(item.id)")
                  td(v-for="(itemSchema,index) in getSchemaItem(item.id).columns" :style="getTableRowStyle(itemSchema,rowIndex)" )
                    template(v-if="itemSchema.type == 'Checkbox'")
                      v-checkbox.ma-0(v-model="row[itemSchema.id]" hide-details  disabled dense)
                    template(v-else)
                      div() {{row[itemSchema.id]}}




</template>

<script>
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import BlobItemById from "@/modules/uploads/components/BlobItemById";
import MapDialog from "@/modules/global/components/table/MapDialog";
import moment from "moment/moment";
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById.vue";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById.vue";
import CustomerColumnById from "@/modules/global/components/table/CustomerColumnById.vue";
import MerchantColumnById from "@/modules/global/components/table/MerchantColumnById.vue";
import GroupColumnById from "@/modules/global/components/table/GroupColumnById.vue";
// import moment from "moment";
export default {
  name: "RecordItemsPanel",
  components:{
    PanelTitleHeader,
    ImageThumbnail,
    BlobItemById,
    MapDialog,
    VehicleColumnById,
    ContactColumnById,
    CustomerColumnById,
    MerchantColumnById,
    GroupColumnById
  },
  props: {
    schema: Array,
    record: Object,
    workflowTemplate: Object
  },

  methods: {
    getItem: function (id) {
      return this.record.data[id];
    },
    getItemSwitchLabel: function (id) {
      if(this.record.data[id] == null){return null}
      let theSchemaItem = this.schema.find(obj => obj.id == id)
      let result = null;
      switch (theSchemaItem.type){
        case "true_false":result = this.record.data[id]?"true":"false"; break;
        case "yes_no":result = this.record.data[id]?"Yes":"No"; break;
        case "yes_na":result = this.record.data[id]?"Yes":"N/A"; break;
        case "ok_no":result = this.record.data[id]?"Yes":"N/A"; break;
        case "pass_fail":result = this.record.data[id]?"Pass":"Fail"; break;
        case "success_failed":result = this.record.data[id]?"Success":"Failed"; break;
        default: return this.record.data[id].toString();
      }
      return result
    },

    getItemCheckboxLabel: function(id){
      if(this.record.data[id] == null){return null}
      let theSchemaItem = this.schema.find(obj => obj.id == id);
      let result = theSchemaItem.options.find(val => val == this.record.data[id])
      return result
    },
    getItemDate: function (id) {
      if (this.record.data[id] == null) {
        return false
      }
      let theSchemaItem = this.schema.find(obj => obj.id == id);
      return moment(this.record.data[id]).format(theSchemaItem.date_format)
    },

    getItemDateTime: function (id) {
      if (this.record.data[id] == null) {
        return false
      }
      return moment(this.record.data[id]).format("ddd, Do MMM YYYY, h:mm:ss a")
    },
    getItemTime: function (id) {
      if (this.record.data[id] == null) {
        return false
      }
      let theSchemaItem = this.schema.find(obj => obj.id == id);
      if (theSchemaItem.time_format === "ampm") {
        return moment(this.record.data[id], "HH:mm").format("LT")
      } else {
        return moment(this.record.data[id], "HH:mm").format("HH:mm")
      }
    },
    getSchemaItem: function (id) {
      let theSchemaItem = this.schema.find(obj => obj.id == id);
      return theSchemaItem
    },



    onBlobAction: function (value) {
      console.log(value)
    },
    onShowMap(id) {
      let location = this.record.data[id];
      this.$store.commit("showMap", location)

    },
    getTableHeaderStyle: function(itemSchema){
      console.log(itemSchema.align)
      let theStyle = {'text-align':itemSchema.align};
      return theStyle;
    },

    getTableRowStyle:function(itemSchema,index){
      let theStyle = {'text-align':itemSchema.align};
      if(index%2 == 0){
        theStyle["background-color"] = "#f2f2f2"
      }else{
        theStyle["background-color"] = "none"
      }

      return theStyle;
    },


  }
}
</script>

<style scoped lang="stylus">
.checklistText {
  max-width: 40%;
}

.locationItem {
  display flex;
  background-color #E8E8E8;
  border-radius 8px;
  align-items center
  padding 5px 8px;
  cursor pointer;
  max-width 200px;
}

.locationItem:hover {
  background-color #d3d3d3;
}

.red-dot {
  background-color #FF0000;
  width 4px;
  height 4px;
  border-radius 50%;
  margin-right -8px;
  margin-left 5px;
  margin-top 5px;
}

.tableViewRender{
  //table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 2px solid #6a6a6a;
}
.tableViewRender th{
  //border-bottom: 1px solid #E8E8E8;
  //border-left : 1px solid #E8E8E8;
  padding: 2px 10px 4px 10px;
  font-size: 11px;
  color: #afafaf;
}
.tableViewRender td{
  //border-bottom: 1px solid #E8E8E8;
  //border-left : 1px solid #E8E8E8;
  padding: 2px 10px;
  border-radius 3px;
  font-size: 12px;
  color: #4c4b4b;
}
.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0
    padding 0

    padding-top 6px
    padding-bottom 8px
</style>