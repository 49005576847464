<template lang="pug">
  v-layout.ma-0.uploadDecoration(column)
    //- | {{data}}
    v-flex(xs12)

      v-dialog(v-if="!hideButton"  v-model="dialogShow" persistent max-width="500px")
        input( hidden type="file" id="file" ref="file" :accept="getMimeType"  @change="handleFileupload()" required)
        template(v-slot:activator="{ on }")
          v-btn.mb-2(tabindex="1" v-on="on", color="primary" :disabled="disabled" )
            v-icon(left) cloud_upload
            | {{schema.label}}


        v-card
          v-toolbar(color="primary")
            .UploadToolbar.primary.white--text()
              .title {{schema.label}}
              .caption.mt-1(v-if="schema.hint") {{schema.hint}}

          v-progress-linear.ma-0(v-show="progressVisible", v-model="percentageComplete")
          v-form(v-model="valid"  ref="form")
            v-container(grid-list-sm)
              v-layout(row, wrap, justify-end)
                v-flex(xs12)
                  template(v-if="!isEdit")
                    .uploadDetails
                      v-btn(@click="selectFile()") {{schema.hint || 'Select File'}}
                      v-text-field.pl-2(v-if="file"  v-model="file.name" disabled  :hint="`File Size: ${originalFileSize} Mb `" :persistent-hint="true" )
                  template(v-else)
                    v-text-field.mb-3(v-model="originalFileName" disabled hint="File Name" :persistent-hint="true" readonly)
                v-flex(xs12)
                  v-text-field(v-model="fileName" ref="fileName" tabindex="2"  label="Name of File" hint="Name of File" :persistent-hint="true" :rules="nameRules" required)
                v-flex(xs12)
                  v-textarea(v-model="description" tabindex="3"  label="Description" hint="Description of the File" :persistent-hint="true")
                v-flex(xs12 v-if="errorString")
                  v-layout(justify-end)
                    .caption.error--text {{errorString}}
          v-card-actions
            v-layout(justify-end)
              v-btn(text color="grey" @click="closeDialog" tabindex="5" ) Cancel
              v-btn(color="primary" @click="submit()" tabindex="4" ) {{isEdit?"Save":"Upload"}}

    v-flex(xs12 v-if="data.value")
      v-layout(align-center justify-start row wrap)
        blob-item.mb-2.mr-2.pt-4.pl-4(v-for="(item,index) in itemArray" :key="index"
          :item="item"
          :size="schema.image_size"
          @onMenuAction="onMenuAction"
          :moreMenu="moreMenu"
          :hideButton="hideButton"
        )

</template>
<script>
// import SETTINGS from "@/modules/workflow/formBuilder/store/formSettings";
import Repository from "@/repository";
import BlobItem from "@/modules/uploads/components/BlobItem";

export default {
  components: {
    BlobItem
  },
  // props: ["schema", "data", "index", "moreMenu","hideButton"],
  props: {
    schema: Object,
    data: Object,
    index: Number,
    moreMenu: Array,
    hideButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialogShow: false, // toggle to show the dialog window
    isEdit: false, // togle if on "edit Mode" or "new Mode"
    selectedEditID: null, // Usefull when the user clicks the Update Button
    progressVisible: true, // toggle to show the progress bar
    percentageComplete: 0, // percentage when complete
    valid: null, // check if the
    file: {},
    originalFileSize: null,
    originalFileName: null,
    fileName: "",
    fileType: null,
    description: "",
    rowsPerPageItems: [5, 10, 15],
    pagination: {rowsPerPage: 5},

    nameRules: [
      v => !!v || "Name is required",
      v => (v && v.length >= 3) || "Name must be more than 3 characters"
    ],
    errorString: null,

    itemArray: [],
    currentItemLength: null
  }),

  watch: {
    computedValue: function (newValue) {
      // console.log(newValue);
      // console.log(oldValue);
      // when loading from server this.computedvalue is null after load it is when it has the arrayString of blobIDs

      if (newValue.length != this.currentItemLength) {
        console.log("RELOADING ATTACHMENTS");
        this.currentItemLength = newValue.length;
        this.loadImages();
      }
    }
  },

  mounted() {
    if (this.data.value == null) {
      this.data.value = [];
    }

    // when loading from server this.computedvalue is null after load it is when it has the arrayString of blobIDs
    if (this.computedValue) {
      this.loadImages();
    }
  },

  computed: {
    computedValue: {
      get: function () {
        if (this.data.value == null) {
          return [];
        }
        // check if value is an array if not make it an array
        if (Array.isArray(this.data.value) == false) {
          let tempArray = [];
          // tempArray.push(this.data.value);
          return tempArray;
        } else {
          return this.data.value;
        }
      },

      set: function (theValue) {
        console.log(`setting theValue: ${theValue}`);
      }
    },

    getMimeType: function () {
      let mime_type = "";
      switch (this.schema.type) {
        case "image":
          mime_type = "image/bmp, image/jpeg, image/jpg, image/png, image/ttf, image/tiff";
          break;
        case "any":
          mime_type = "";
          break;
        case "custom":
          mime_type = "";
          break;
        case "audio":
          mime_type = "audio/mpeg, audio/aac, audio/wav, audio/webm,  audio/ogg,  audio/mpeg";
          break;
        case "video":
          mime_type = "video/x-msvideo, video/mpeg, video/ogg,  video/webm,  video/3gpp";
          break;
        case "media":
          mime_type = "video/x-msvideo, video/mpeg, video/ogg,  video/webm,  video/3gpp, audio/mpeg, audio/aac, audio/wav, audio/webm,  audio/ogg,  audio/mpeg, image/bmp, image/jpeg, image/jpg, image/png, image/ttf, image/tif";
          break;
        case "document":
          mime_type =
              "text/html, text/plain, application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          break;
      }

      return mime_type;
    }
  },

  methods: {
    loadImages: function () {
      if (this.data) {
        // get from server
        Repository.post(`/upload/blob/getBlobs`, this.computedValue) // /blob/
            .then(payload => {
              if (this.schema.multiple) {
                this.itemArray = payload.data;
              } else {
                // insert the first Item if it is not multiple
                this.itemArray = [];
                this.itemArray.push(payload.data[0]);
              }
            })
            .catch(payload => {
              console.log("failure");
              console.log(payload.data);
            });
      }
    },

    // Step 1
    selectFile: function () {
      this.$refs.file.click();
    },

    // Step 2
    handleFileupload: function () {
      console.log(this.$refs.file.files[0]);
      this.file = this.$refs.file.files[0];
      this.originalFileSize = (this.file.size / 1048576).toFixed(2);
      this.fileType = this.file.type;
      this.errorString = null;
      this.fileName = this.file.name.replace(/\.[^/.]+$/, "");
      this.$refs.fileName.focus();
      console.log(`fileType: ${this.fileType}`);
    },

    resetUpload: function () {
      this.file = null;
      this.progressVisible = false;
      this.fileName = null;
      this.description = null;
    },

    // Step 3
    submit: function () {
      if (this.isEdit) {
        this.submitEditMode();
      } else {
        this.progressVisible = true;
        this.submitCreateMode();
      }
    },

    // other functions
    closeDialog: function () {
      this.isEdit = false;
      this.dialogShow = false;
    },
    // on upload btn clicked
    submitCreateMode: function () {
      // validation
      if (this.file == null) {
        this.errorString = "*Select a File and Give it a Name";
        return;
      }

      // preparation of Data
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("name", this.fileName);
      formData.append("type", this.fileType);
      formData.append("description", this.description);

      this.progressVisible = true;

      Repository.post(`/upload/blob`, formData, {
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress: this.progress
      })
          .then(payload => {
            console.log("success");
            console.log(payload.data.id);
            // this.$emit("onSave", payload.data);

            // resolving the updating
            var tempArray = [];

            // if muliple documents are allowed then push into array else replace the array by inserting 1 item
            if (this.schema.multiple) {
              this.itemArray.push(payload.data);
              // insert arrays
              this.itemArray.forEach(item => {
                console.log(item.id);
                tempArray.push(item.id);
              });
            } else {
              // for a single item
              this.itemArray = [];
              this.itemArray.push(payload.data);

              tempArray.push(payload.data.id);
            }

            // emit to parent form the arrayIDs array
            this.$emit("onChange", {
              id: this.data.id,
              value: tempArray,
              index: this.index
            });

            this.resetUpload();
            this.closeDialog();
          })
          .catch(payload => {
            console.log("failure!!");
            console.log(payload);
            this.resetUpload();
          });
    },

    // called when the edit button is clicked
    submitEditMode: function () {
      var sendObject = {
        name: this.fileName,
        description: this.description
      };

      // Update the Changes
      Repository.put(`/upload/blob/${this.selectedEditID}`, sendObject).then(
          payload => {
            this.isEdit = false;
            this.dialogShow = false;

            let theIndex = this.itemArray.findIndex(
                item => item.id === payload.data.id
            );

            this.itemArray[theIndex].description = payload.data.description;
            this.itemArray[theIndex].name = payload.data.name;
          }
      );
    },

    onMenuAction: function (value) {

      switch (value.action) {
        case "edit":
          this.isEdit = true;
          this.fileName = value.theObject.name;
          this.description = value.theObject.description;
          this.originalFileName = value.theObject.original_name;
          this.selectedEditID = value.theObject.id; // Usefull when the user clicks the Update Button
          this.progressVisible = false;
          this.dialogShow = true;

          break;
        case "delete":
          Repository.delete(`/upload/blob/${value.theObject.id}`)
              .then(payload => {
                console.log(`deleted the blob id: ${payload.data}`);
                let tempIndex = this.itemArray.findIndex(item => item.id == payload.data.id);
                this.itemArray.splice(tempIndex, 1);
              })
              .catch(payload => {
                console.log("failure!!");
                console.log(payload.data);
              });
          break;
      }
      this.$emit("onMenuAction", value);
    },

    // progress event
    progress: function (progressEvent) {
      this.percentageComplete = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
      );
    }
  }
};
</script>
<style lang="stylus" scoped>
.blobItemStyle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.uploadDetails {
  display: flex;
}

.UploadToolbar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.uploadDecoration {
  border: 4px dashed #e8e8e8;
  padding: 10px 15px;
  width: 100%;
}
</style>
