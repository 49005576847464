<template lang="pug">
  div
    v-divider
    v-container.white.pa-2(fluid)
      v-layout.my-2.pr-4(row, wrap, align-center)
        h2.mx-4  User List
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small,outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item-group(color="primary")
                v-list-item(v-for="(item,index) in items", :key="index")
                  v-list-tile-title {{ item.title }}
        v-btn.ma-2(small, color='primary')
          v-icon.pr-2(dark) search
          | Search
        v-btn.mb-2.ma-2(small, color='primary', dark, @click='openNew') Add New Contact
    v-divider
      v-container.white.ma-4(d-inline-flex, fluid)
        v-layout(row, wrap)
          v-text-field(flat, solo, prepend-icon='search', label='Search names, email and usernames')
          v-text-field(flat, solo, prepend-icon='search', label='Filter Groups')
          v-spacer
    v-layout.pa-4(row, wrap, align-center)
      v-layout.filterStyles(align-center)
        v-text-field.pl-2( v-model="searchFilter", label="Search Filter", :persistent-hint="true" required)
        v-text-field.pl-2( v-model="groupFilter", label="Group Filter", :persistent-hint="true" required)
        v-btn.ma-2(small color="primary") Search
        div
          span.primary--text 1 filter
          span.pl-1.grey--text.text--darken-1 apply
      v-spacer
    v-divider
    user-table
</template>
<script>
import UserTable from "@/modules/contacts/components/tables/UserTable";
export default {
  components: {
    UserTable
  },

  data: () => ({
    searchFilter: null,
    groupFilter: null,
    items: [
      { title: "Click Me" },
      { title: "Click Me 1" },
      { title: "Click Me 2" },
      { title: "Click Me 3" }
    ]
  }),

  methods: {
    openNew: function () {
      this.$store.dispatch("contacts/addContact").then(() => {
        let contactID = this.$store.getters["contacts/getCurrentContact"].id;

        this.$router.push({
          name: "contactNewBasic",
          params: {contactID: contactID}
        }).catch(()=>{});
      });
    }
  }
};
</script>
<style scoped lang="stylus">
.filterStyles{
  max-width 900px;
}
</style>
