import {getField, updateField} from "vuex-map-fields";
// import Repository from "@/repository";

// const CHAT_API = "/chat";

// ----------------------- STATE ------------------------- //
const state = {
    chat_data: [
        // {
        //     user:"mugambi@localhost",
        //     thread:[
        //         {from:"mugambi@localhost",to:"demo@localhost",message:"Hello",timestampz:"05:35", type:"out"},
        //         { timestampz:"2 March 2022", type:"time"},
        //         {from:"demo@localhost",to:"mugambi@localhost",message:"Hello Mugambi",timestampz:"06:00", type:"in"},
        //         {from:"mugambi@localhost",to:"demo@localhost",message:"How are you doing this afternoon",timestampz:"06:10", type:"out"},
        //         {from:"demo@localhost",to:"mugambi@localhost",message:"am verly fine and how do you do. I hope you managed to finish up the demo that i sent your yesterday and is ready for presenations",timestampz:"06:15", type:"in"},
        //         {from:"demo@mugambi",to:"mugambi@localhost",message:"Yes I did manage fine and how do you do. I hope you managed to finish up the demo that i sent your yesterday and is ready for presenations",timestampz:"06:16", type:"in"},
        //         {from:"mugambi@localhost",to:"demo@localhost",message:"How are you doing this afternoon",timestampz:"06:23", type:"out"},
        //         {from: "time", timestampz:"MONDAY", type:"time"},
        //         {from:"demo@localhost",to:"mugambi@localhost",message:"am verly fine and how do you do. I hope you managed to finish up the demo that i sent your yesterday and is ready for presenations",timestampz:"06:32", type:"in"},
        //         {from:"demo@mugambi",to:"mugambi@localhost",message:"Yes I did manage fine and how do you do. I hope you managed to finish up the demo that i sent your yesterday and is ready for presenations",timestampz:"07:55", type:"in"},
        //     ],
        //     unread:0,
        //     lastTimestampz:
        // },

    ],
    currentJID:null,
    userJID:"demo@localhost",
    rooster_data: [],
    currentIndex: -1,


};

const getters = {
    getChat: state => getField(state.chat_data[state.currentIndex]),
    getCurrentChat: state => {
        return state.chat_data[state.currentIndex];
    },
    getThread: state => {
        let result = state.chat_data.find(element => element.user == state.currentJID);
        if (typeof result === 'undefined') return [];
        return result.thread;
    },
    getUnreadMessageCount: state => jid => {
        let result = state.chat_data.find(item => item.user === jid)
        if (typeof result === 'undefined') return 0;
        return result.unread;
    },
    getLastTimeRead: state => jid => {
        let result = state.chat_data.find(item => item.user === jid)
        if (typeof result === 'undefined') return null;
        return result.lastTimestampz;
    },
    getRosterData: state => {
        return state.rooster_data;
    },
    getCurrentNameJID: state => {
        let rosterIndex = state.rooster_data.findIndex(element => element.jid == state.currentJID)
        if (rosterIndex > -1) {
            return state.rooster_data[rosterIndex].name
        }else{
            return null;
        }
    },

    getCurrentJIDLocal: state => {

        if (state.currentJID != null && state.currentJID.split("@").length > 0) {
            console.log(state.currentJID.split("@")[0])
            return state.currentJID.split("@")[0]
        }else{
            return null;
        }
    },


    getLastThreadJID: state => jid => {
        let result = state.chat_data.find(item => item.user === jid)
        if (typeof result === 'undefined' || result.thread.length == 0) return null;
        return result.thread[result.thread.length-1];
    }
}

const mutations = {
    updateChat(state, payload) {
        updateField(state.chat_data[state.currentIndex], payload);
    },

    setRosterData(state,payload){
        state.rooster_data = payload;
        state.rooster_data.forEach(element => {
          element.lastTimestampz = new Date().getTime();
        })


        // order alphabetically by jid
        state.rooster_data = state.rooster_data.sort((a,b) => {
            return a.jid.local > b.jid.local ? 1 : (b.jid.local > a.jid.local) ? -1 : 0;
        });
    },

    setCurrentJID(state,jid){
      state.currentJID = jid;
        let result = state.chat_data.find(element => element.user == state.currentJID);
        if (typeof result !== 'undefined'){
            result.unread = 0;
        }else{
            state.chat_data.push(
                {
                    user:jid,
                    thread:[],
                    unread:0,
                    lastTimestampz: null,
                }
            );
        }
    },

    appendCurrentThread(state,payload){
        console.log(`currentJID: ${state.currentJID}`)
        console.log(`payloadFrom: ${payload.from}`)
        let result = state.chat_data.find(element => element.user == state.currentJID);
        if (typeof result === 'undefined'){
            state.chat_data.push(
                {
                    user:state.currentJID,
                    thread:[payload],
                    unread:0,
                    lastTimestampz: payload.timestampz,
                }
            );
        }else{
            // insert to approprioate thread
            result.thread.push(payload)
            result.lastTimestampz = payload.timestampz;
        }

        // update the roster
        let rosterIndex = state.rooster_data.findIndex(element => element.jid == state.currentJID)
        if (rosterIndex > -1){
            state.rooster_data[rosterIndex].lastTimestampz = new Date(payload.timestampz).getTime();
        }
        // order rooster_data
        state.rooster_data = state.rooster_data.sort((a,b) => {
            return a.lastTimestampz < b.lastTimestampz ? 1 : (b.lastTimestampz < a.lastTimestampz) ? -1 : 0;
        });
    },

    insertThread(state,payload){
        let result = state.chat_data.find(element => element.user == payload.from);
        // creat empty data if it does not exist
        if (typeof result === 'undefined'){
            console.log("here is undefined")
            state.chat_data.push(
                    {
                        user:payload.from,
                        thread:[payload],
                        unread:payload.from != state.currentJID ? 1 :0,
                        lastTimestampz: payload.timestampz
                    }
                );
        }else{
            // insert to approprioate thread
            result.thread.push(payload)
            result.lastTimestampz = payload.timestampz;
            if(payload.from != state.currentJID){
                result.unread += 1;
            }

        }

        // update the roster
        let rosterIndex = state.rooster_data.findIndex(element => element.jid == payload.from)
        if (rosterIndex > -1){
            state.rooster_data[rosterIndex].lastTimestampz = new Date(payload.timestampz).getTime();
        }
        // order rooster_data
        state.rooster_data = state.rooster_data.sort((a,b) => {
            return a.lastTimestampz < b.lastTimestampz ? 1 : (b.lastTimestampz < a.lastTimestampz) ? -1 : 0;
        });

    }


}

const actions = {

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
