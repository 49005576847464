<template lang="pug">
  v-data-table(:headers="headers" :items="$store.state.workflow.dataTable.datatable_data" item-key="id" :loading="loading" :single-select="false" show-select)
    template(v-slot:item.author="{item}")
      div(v-if="item.author")
        div() {{item.author.first_name}} {{item.author.last_name}}
        timeago.grey--text(:datetime="item.updated_at")

    template( v-slot:item.action="{ item }")
      v-row.mr-7(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')



</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import { searchMixin } from "@/mixin/searchListMixin";
export default {
  components: {
    MoreDropDownMenu
  },

  props: {
    search: String
  },

  mixins: [searchMixin],

  data: () => ({
    loading: false,
    headers: [
      {
        text: "Name",
        value: "name"
      },
      {
        text: "Description",
        value: "description"
      },
      {
        text: "Description",
        value: "description"
      },
      { text: "Author", value: "author" },
      { text: "Action", value: "action", align: "right" }
    ],
    menuitems: [
      { title: "Edit Table", icon: "table_chart", action: "table" },
      { title: "Delete", icon: "delete", action: "delete" }
    ]
  }),

  methods: {
    onClick: function(value) {
      console.log(value.action);
      switch (value.action) {
        case "table":
          this.$store.commit(
            "workflow/dataTable/setCurrentIndexByID",
            value.theObject.id
          );
          this.$router.push({
            name: "workflowTableEditor",
            params: { workflowID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "edit":
          console.log("edit");
          break;
        case "delete":
          this.$store.dispatch(
            "workflow/dataTable/deleteDataTable",
            value.theObject.id
          );
          break;
      }
    },

    onRowClick: function(item) {
      console.log(item);
      this.$store.commit("workflow/dataTable/setCurrentIndexByID", item.id);
      this.$router.push({
        name: "workflowTableEditor",
        params: { workflowID: item.id }
      }).catch(()=>{});
    }
  },

  mounted() {
    if (this.$store.state.workflow.dataTable.currentIndex == -1) {
      this.$store.dispatch("workflow/dataTable/loadAllDataTables").then(() => {
        console.log("loaded Data Tables");
      });
    }
  }
};
</script>

<style scoped></style>
