<template lang="pug">
  .boardCard(v-if="$store.state.workflow.workflow_data")
    div()
    v-card(@click="onClickCard")
      v-card-title.pr-1.pl-2.pt-2.pb-0
        v-layout(justify-space-between align-center)
          .body-1.font-weight-bold(v-text="getTheInfo(settings.title)")
          div()
            v-icon.zIcon(@click="edit") notifications
            v-menu()
              template( v-slot:activator="{ on, attrs }")
                v-btn(icon v-bind="attrs" v-on="on")
                  v-icon() expand_more
              v-list(dense)
                v-list-item-group()
                  v-list-item(@click="view")
                    v-list-item-icon()
                      v-icon(:size="20") visibility
                    v-list-item-title() View Record
                  v-list-item(@click="edit")
                    v-list-item-icon()
                      v-icon(:size="20") edit
                    v-list-item-title() Edit Record
                  v-list-item(v-if="isAdministrator" @click="remove")
                    v-list-item-icon()
                      v-icon(:size="20")  delete
                    v-list-item-title() Delete Record
      v-layout.mx-1.my-2()
        .boardAvatar.ml-2.mt-1(v-if="settings.avatar")
          image-thumbnail.mr-3(v-if="item.data[settings.avatar]" :width="80" :height="80"  :src="item.data[settings.avatar][0]")
        v-card-subtitle.py-0.pl-0()
          .body-2.font-weight-medium(v-if="settings.sub_title1" v-text="getTheInfo(settings.sub_title1)")
          .body-2.mt-1(v-if="settings.sub_title2" v-text="getTheInfo(settings.sub_title2)")

      div(v-if="settings.show_author || settings.show_date")
      v-divider()
      v-card-actions.my-0.py-1()
        //- .caption(v-if="settings.show_author" v-text="getContactInitials")
        v-spacer()
        .caption.grey--text(v-if="settings.show_date" v-text="getFormatDate")


</template>

<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import moment from "moment";
export default {
  components: {ImageThumbnail},
  props: {
    item: Object,
  },

  data: ()=>({
    customersLoaded : false,
  }),

  computed: {
    isAdministrator: function(){
      return this.$store.state.profile.userInfo.is_administrator;
    },
    settings:function(){
      return this.$store.getters['workflow/getCurrentWorkflowSettings'];
    },
    
    getFormatDate:function(){
      return moment(this.item.updated_at).format("ddd, Do MMM YY - h:mm a")
    },
    getContactInitials:function(){
      let initials = ""
      if (this.item == undefined || this.item == null){
        return initials
      }

      if(this.item.contact.first_name.length > 0 && this.item.contact.last_name.length > 0){
        initials = this.item.contact.first_name[0]+this.item.contact.last_name[0];
      }else if (this.item.contact.first_name.length > 0){
        initials = this.item.contact.first_name[0];
      }
      return initials
    },
    classes() {
      return {
        "is-due": this.isDue,
        "is-overdue": this.isOverdue,
      };
    },


  },

  methods: {
    onClickCard() {
      // the workflowRecords are empty so insert one record from the board
      // this.$store.commit("workflow/setCurrentIndexByID",this.item.workflow_template_id)
      // this.$store.commit("workflow/workflowRecord/setCurrentIndexByID",this.item.id);

      this.$store.commit("workflow/workflowRecord/setOneRecordFromBoard",this.item);
      this.$store.commit("showRightDrawer",{title:"Record",width:"800",component:"RecordDetails"})


    },

    view() {
      this.$emit("view", this.item);
    },

    edit() {
      this.$emit("edit", this.item);
    },

    remove() {
      this.$emit("remove", this.item);
    },

    getTitle() {
      return "";
    },

    getSchema(){
      return this.$store.getters["workflow/getSchemaByVersion"](this.item.workflow_template_version)
    },

    // can either be TextField or Jahazi
    getTheInfo(theName) {
      let theSchema = this.getSchema()
      if (theSchema == null || theSchema.length == 0) {
        return this.item.data[theName]
      }else{
        let theSchemaItem = theSchema.find(element => element.id == theName)
        if (theSchemaItem == null){
          return this.item.data[theName]
        }else{
          switch(theSchemaItem.name){
            case "Jahazi": return this.getJahaziContent(theSchemaItem);
            case "TextField": return this.item.data[theName];
          }

        }

      }
    },


     getJahaziContent(theSchemaItem){

      switch(theSchemaItem.type){
        case "customer":
          var theCustomer =  this.$store.getters["customer/getCustomerByID"](this.item.data[this.settings.title])
          if (theCustomer != null) {
            return `${theCustomer.reference_id??""} ${theCustomer.name}`;
          }
          break;
        case "contact":
          var theContact = this.$store.getters["contacts/getContactByID"](this.item.data[this.settings.title])
          if (theContact != null) {
            return `${theContact.first_name} ${theContact.last_name}`
          }
          break;

        case "vehicle":
          var theVehicle = this.$store.getters["vehicles/getVehicleByID"](this.item.data[this.settings.title])
          if (theVehicle != null) {
            return `${theVehicle.name}`
          }


      }

      return this.item.data[this.settings.title];
    }


  },

  mounted() {

    //console.log(this.$store.getters["workflow/getSchemaByVersion"](this.item.workflow_template_version))
    // console.log(this.item.workflow_template_version)
  }
};
</script>

<style lang="stylus" scoped>
.boardCard{
  margin 10px 0px;
  cursor: default;
}

.zIcon:hover{
  cursor: pointer
  color #E91E63
}
</style>
