<template lang="pug">
  #blankcontent()
    .panelTitle() Horizonal Bar Chart
    v-divider.mx-2()
    horizontal-bar-chart(:style="{height:`${clientHeight-40}px`, width: `100%`}" :chartdata="getDataSets" :options="chartOption")
</template>
<script>

import HorizontalBarChart from "@/modules/global/components/charts/HorizontalBarChart";
export default {
  components: {
    HorizontalBarChart
  },
  props: {
    clientHeight: Number
  },
  data: () => ({
    chartOption: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {

            // gridLines: {
            //   color: "rgba(0, 0, 0, 0)"
            // }
          }
        ],
        xAxes: [
          {
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ]
      },
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 20,
          bottom: 0
        }
      }
    }
  }),
  computed: {
    getDataSets: function() {
      let obj = {
        labels: ["Jan", "Feb", "Mar"],
        datasets: [
          {
            label: "Months",
            data: [40, 30, 60]
            // borderWidth: 2
          }
        ]
      };
      obj.datasets[0].backgroundColor = this.$vuetify.theme.themes.light.primary;
      // obj.datasets[0].borderColor = this.$vuetify.theme.themes.light.primary;
      return obj;
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight bold
  padding: 5px 30px;
  height:30px;
  display: flex;
  width: 100%
  justify-content: space-between;
}

.lineChartPanel {
  height: calc(100% - 45px);
}
</style>
