<template lang="pug">
  div()
    .d-flex.pointer(@click="onClick" :class="disabled?'grey--text':'primary--text'")
      v-icon() schedule
      div.px-2() {{renderDate}}
    .caption.grey--text() {{schema.label}}
    v-dialog(v-model="showDialog" width="600" @click:outside="onClose"  persistent)
      div.primary.py-2.px-3()
        .d-flex.justify-space-between.align-center()
          div.white--text() {{renderDate}}
          v-btn(icon @click="onClose")
            v-icon(color="white" ) close
      vue-ctk-date-time-picker(
        id="somID"
        v-model="computedValue",
        inline

        outputFormat="YYYY-MM-DDTHH:mm:ssZ"
        :no-header="true"
        :noLabel="true"
        :button-color="$vuetify.theme.themes.light.primary"
        :color="$vuetify.theme.themes.light.primary"

      )


</template>

<script>
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import moment from "moment";
export default {
  props: ["schema", "dataValue", "index","disabled"],
  data: () => ({

    showDialog: false,
  }),
  components:{
    VueCtkDateTimePicker
  },

  methods:{
    onClick: function(){
      if(this.disabled){
        return;
      }
      this.showDialog=true;
      console.log(new Date().toISOString())
      console.log("has clicked")
    },
    onChange: function(){
      console.log("has changed")
    },
    onClose:function(){
      console.log("onClose")
      this.showDialog=false;
    }
  },

  computed: {
    requiredRule: function() {
      return this.schema.required ? value => !!value || "Required." : false;
    },

    renderDate: function(){
      return  moment(new Date(this.dataValue)).format("ddd, Do MMM YYYY, h:mm a")
    },


    computedValue: {
      get: function() {
        if (this.dataValue != null){
          return moment(new Date(this.dataValue)).format("YYYY-MM-DD h:mm a")
        }else{
          return this.dataValue;
        }

      },

      set: function(theValue) {
        console.log(theValue)
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    }
  },

  mounted() {
    this.theValue = this.computed;
  }
}
</script>

<style scoped>

</style>