<template lang="pug">
  .fill-width.px-4(v-if="getData.length" style="position:relative")
    v-layout.my-4(ref="assignmentContainer"  align-center)
      v-flex(xs4)
        v-btn-toggle(v-model="clendarSpan")
          v-btn(text small value="hour") hour
          v-btn(text small value="day") day
          v-btn(text small value="week") week
          v-btn(text small value="month") month
          v-btn(text small value="year") year

      v-flex(xs4)
        v-layout(justify-center)
          v-menu(ref=menu v-model="calendar" offset-y  min-width="290px" )
            template(#activator="{ on }")
              v-btn(text outlined v-on="on" @click="incrementTimeKey()")
                div(v-html="getHeader")
                div(style="color:#333333")
                  v-icon() arrow_drop_down
            template(v-if="clendarSpan == 'hour'")
              v-time-picker(:value="getTime" @click:hour="onTimeChange"  @change="onTimeChange" format="24hr" :key="theShowTime")
            template(v-else)
              v-date-picker(v-model="theDate"  no-title scrollable color="primary" :type="getCalendarType" @change="onDateChange")


      v-flex(xs4)
        v-layout(justify-end  align-center)
          v-layout.mr-4(justify-end align-center)
            .body-2.grey--text() {{zoomLevel*100}}%
            v-btn(fab text small @click="zoomOut")
              v-icon.grey--text() remove_circle
            v-btn(fab text small @click="zoomIn")
              v-icon.grey--text() add_circle

          v-btn( small color="primary" @click="onBeforeClick")
            v-icon() navigate_before
          v-btn.ml-2(outlined text  small  @click="onReset") Current

          v-btn.ml-2(   small color="primary" @click="onNextClick")
            v-icon() navigate_next


    scheduler-container(:zoomLevel="zoomLevel" @openDialog="dialog=true" @tooltip="onTooltip" :key="key")
    v-dialog(v-model="dialog" persistent max-width="800px")
      scheduler-dialog(v-if="dialog" @close="onClose", @save="onSave", @delete="onDelete")
    .tooltip(ref="tooltip" v-if="$store.state.vehicles.assignments.tooltip.visible" :style="getTooltipStyle()")
      scheduler-mouse-tooltip()


</template>
<script>
import SchedulerContainer from "@/modules/vehicles/components/scheduler/SchedulerContainer";
import SchedulerDialog from "@/modules/vehicles/components/scheduler/SchedulerDialog";
import SchedulerMouseTooltip from "@/modules/vehicles/components/scheduler/SchedulerMouseTooltip";
import moment from "moment";

export default {
  components: {
    SchedulerContainer,
    SchedulerDialog,
    SchedulerMouseTooltip
  },
  data: () => ({
    key:0,
    zoomLevel: 1.0,
    calendar: null,
    theDate: null,
    theTime: null,
    theShowTime: 0,
    dialog: false,
    showTooltip: false,
    tooltipX: 0,
    tooltipY: 0,
    tooltipXOffset: 0,
    tooltipYOffset: 0
  }),

  mounted() {

    this.$store.dispatch("vehicles/loadAllItems").then(() => {
      if (!this.getData.length) {
        this.$store
            .dispatch("vehicles/assignments/loadAllSchedules")
            .then(() => {
              console.log("loaded data");
            });
      }
    });
  },


  beforeDestroy() {
    window.removeEventListener("resize");
  },

  created() {
    this.$nextTick(()=>{
      window.addEventListener("resize", this.onResize);
    })
  },

  computed: {

    getTime: function () {
      if (this.theDate == null) {
        return moment(new Date()).format("HH:mm")
      } else {
        return moment(this.theDate).format("HH:mm")
      }
    },
    getData: function () {
      return this.$store.getters["vehicles/assignments/getData"];
    },

    clendarSpan: {
      get: function () {
        return this.$store.getters["vehicles/assignments/getMode"];
      },
      set: function (value) {
        this.$store.commit("vehicles/assignments/setMode", value);

      }
    },

    getCalendarType: function () {
      let value = "date";
      switch (this.$store.getters["vehicles/assignments/getMode"]) {
        case "hour":
          value = "hour";
          break;
        case "day":
          value = "date";
          break;
        case "month":
          value = "month";
          break;
        case "year":
          value = "month";
          break;
      }

      return value;
    },

    getHeader: function () {
      let theDate = this.$store.getters["vehicles/assignments/getStartDate"];
      let value = "";
      switch (this.$store.getters["vehicles/assignments/getMode"]) {
        case "hour":
          value =
              "<strong class='primary--text'>HOUR</strong> &nbsp;| " +
              moment(theDate).format("HH:mm, dddd - Do MMM YYYY");
          break;
        case "day":
          value =
              "<strong class='primary--text'>DAY</strong> &nbsp;| " +
              moment(theDate).format("dddd - Do MMM YYYY");
          break;
        case "week":
          value =
              "<strong class='primary--text'>WEEK</strong> &nbsp;| " +
              moment(theDate).format("Do") +
              " - " +
              moment(theDate)
                  .add(6, "days")
                  .format("Do MMMM YYYY");
          break;
        case "month":
          value =
              "<strong class='primary--text'>MONTH</strong> &nbsp; | " +
              moment(theDate).format("MMMM YYYY");
          break;
        case "year":
          value =
              "<strong class='primary--text'>YEAR</strong>  &nbsp;| " +
              moment(theDate).format("YYYY");
          break;
      }
      return value;
    }
  },

  methods: {

    onResize:function(){
      this.key += 1;
    },

    getTooltipStyle: function () {
      let obj = {position: "absolute", width: "430px"};

      if (this.$refs.assignmentContainer == undefined) {
        return obj;
      }

      obj["left"] =
          this.$store.state.vehicles.assignments.tooltip.xPos -
          this.$refs.assignmentContainer.getBoundingClientRect().left +
          30 +
          "px";
      obj["top"] =
          this.$store.state.vehicles.assignments.tooltip.yPos -
          this.$refs.assignmentContainer.getBoundingClientRect().top +
          40 +
          "px";

      return obj;
    },
    onNextClick: function () {
      let startDate = this.$store.getters["vehicles/assignments/getStartDate"];
      this.$store.commit("vehicles/assignments/next", startDate);
    },

    onBeforeClick: function () {
      let startDate = this.$store.getters["vehicles/assignments/getStartDate"];
      this.$store.commit("vehicles/assignments/before", startDate);
    },

    onReset: function () {
      this.$store.commit("vehicles/assignments/resetDate");
    },

    zoomIn: function () {
      this.zoomLevel += 0.25;
    },

    zoomOut: function () {
      this.zoomLevel -= 0.25;
    },

    onDateChange: function (value) {
      this.$store.commit("vehicles/assignments/setDate", value);
    },

    onTimeChange: function (value) {
    
      if (value.length > 2) {
        value = value.split(':')[0]
      }
      value = parseInt(value)

      let tempDate = moment(this.$store.getters["vehicles/assignments/getStartDate"]).toDate()
      tempDate.setHours(value)
      this.$store.commit("vehicles/assignments/setDate", tempDate);
    },

    incrementTimeKey: function () {
      this.theShowTime++;
    },


    onClose: function () {
      this.dialog = false;
    },

    onSave: function () {
      this.onClose();
    },
    onDelete: function () {
      this.$store
          .dispatch("vehicles/assignments/deleteSchedule")
          .then(() => {
            this.dialog = false;
          })
          .catch(() => {
            console.log("problem deleting file");
          });
    },

    onTooltip: function (payload) {
      console.log(payload);
      // this.showTooltip = payload.visible;
      // this.tooltipX = payload.xPos;
      // this.tooltipY = payload.yPos;
    }
  }
};
</script>
<style lang="stylus" scoped></style>
