import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";
const MESSAGE_API = "/report";
// ----------------------- STATE ------------------------- //

const state = {
  report_data: [],
  currentIndex: -1,
  isDownloadFinished: false,
  viewData: {
    vehicles: [
      {
        name: "Vehicle Alerts",
        desc: "Vehicle Alerts and Violations",
        url: "vehiclealertReport",
      },
      {
        name: "Vehicle List",
        desc: "Listing of all basic vehicle information",
        url: "vehicleListReport",
      },
      {
        name: "Vehicle Details",
        desc: "Listing of full vehicle profiles & details",
        url: "vehicleDetailsReport",
      },
      {
        name: "Operating Cost Summary",
        desc: "Summary of costs associated with vehicles",
        url: "operatingCostSummary",
      },
      {
        name: "Total Cost Trend",
        desc: "Analysis of total vehicle cost per meter over time",
        url: "totalCostTrend",
      },
      {
        name: "Utilization Summery",
        desc:
          "Shows usage (e.g. distance travelled) per vehicle based on meter entries",
        url: "utilizationSummary",
      },
      {
        name: "Status Changes",
        desc: "List updates to every vehicle's status",
        url: "statusChanges",
      },
      {
        name: "Status Summary",
        desc: "Lists the time vehicles have spent in different statuses",
        url: "statusSummary",
      },
      {
        name: "Vehicle Renewal Reminders",
        desc: "Listing of all date based reminders of vehicle",
        url: "vehicleRenewalReport",
      },
    ],
    vehicleAssignments: [
      {
        name: "Vehicle Assignment Log",
        desc: "Listing of all vehicle-to-contact assignment details",
        url: "vehicleAssignmentLog",
      },
      {
        name: "Vehicle Assignment Summary",
        desc:
          "Aggregate vehicle assignment data grouped by operator or vehicle",
        url: "vehicleAssignmentSummary",
      },
    ],

    contacts: [
      {
        name: "Contact List",
        desc: "Listing of all basic contacts information",
        url: "contactListReport",
      },
      {
        name: "Contact Renewal Reminders",
        desc: "Listing of all date based reminders of contacts",
        url: "contactRenewalReport",
      },
    ],

    insepctions: [
      {
        name: "Inspection Submission List",
        desc: "Listing of all inspection submissions",
        url: "inspectionSubmissionList",
      },
      {
        name: "Inspection Submission Summary",
        desc: "Aggregate oms[ectopm data grouped by users or vehicles",
        url: "inspectionSubmissionSummary",
      },
      {
        name: "Inspection Failures List",
        desc: "Listing of all failed inspection items",
        url: "inspectionFailuresList",
      },
    ],

    services: [
      {
        name: "Service Summary",
        desc: "Listing of summarized service history for vehicles",
        url: "serviceSummary",
      },
      {
        name: "Service History by Vehicle",
        desc: "Listing of all service by vehicle grouped by entry or task",
        url: "serviceHistorybyVehicle",
      },
      {
        name: "Service Task Summary",
        desc: "Aggregate service data groped by Service Task",
        url: "serviceTaskSummary",
      },
      {
        name: "Service Reminders",
        desc: "List all service reminders",
        url: "serviceReminders",
      },
      {
        name: "Vehicles Without Service",
        desc: "List all vehicles that haven't had a service task performed",
        url: "vehiclesWithoutService",
      },
    ],

    issues: [
      {
        name: "Issues List",
        desc: "List basic details of all vehicles-related issues",
        url: "issueReport",
      },
    ],

    fuel: [
      {
        name: "Fuel Summary",
        desc: "Listing of summarized fuel metrics for vehicles",
        url: "fuelSummary",
      },
      {
        name: "Fuel Entries by Vehicle",
        desc: "Listing of fuel entries by vehicle",
        url: "fuelEntriesbyVehicle",
      },
      {
        name: "Fuel Summary by Merchant",
        desc:
          "Aggregate fuel volume and price data grouped by location and fuel type",
        url: "fuelSummarybyMerchant",
      },
      {
        name: "Fuel  by Merchant",
        desc:
          "Monthly Detail view of Fuel by Merchant",
        url: "fuelByMerchant",
      },
    ],
  },
};

const gettters = {
  getReport: state => getField(state.report_data[state.currentIndex]),
  getCurrentReport: state => state.report_data[state.currentIndex],
}



const mutations = {
  setDownloadState(state, payload){
    state.isDownloadFinished = payload;
  },
  updateReport(state,payload) {
    updateField(state.report_data[state.currentIndex],payload);
  },

  setCurrentIndexByID(state, theID){
    state.currentIndex = state.report_data.findIndex(element => element.id == theID);
  },

  loadAllReports(state, payload) {
    state.report_data = payload.data;
    state.currentIndex = 0;
  },

  addReport(state, payload) {
    state.report_data.push(payload.data);
    state.currentIndex = state.report_data.length -1;
  },

  saveReport(state,payload) {
    state.report_data[state.currentIndex] = payload.data;
  },

  fetchReport(state,payload) {
    if(state.currentIndex == -1){
      state.report_data.push(payload.data);
      state.currentIndex =  0;
    }else{
      state.report_data[state.currentIndex] = payload.data;
    }
  },

  deleteReport(state,payload) {
    state.currentIndex = state.report_data.findIndex(element => element.id == payload.data.id);
    state.report_data.splice(state.currentIndex, 1);
  },

  reset(state) {
    state.report_data = [];
    state.currentIndex = -1;
  }

};


const actions = {
  async loadAllReports({commit}){
    try {
      commit("loadAllReports",await Repository.get(`${MESSAGE_API}`));
    } catch (error){
      console.log(error)
    }
  },

  async addReport({commit}) {
    try {
      commit("addReport",await Repository.get(`${MESSAGE_API}/new`));
    } catch (error){
      console.log(error)
    }
  },

  async saveReport({state,commit}) {
    try {
      commit("saveReport",await Repository.post(`${MESSAGE_API}`,state.report_data[state.currentIndex]));
    } catch (error){
      console.log(error)
    }
  },

  async fetchReport({commit},payloadID) {
    try {
      commit("fetchReport",await Repository.get(`${MESSAGE_API}/${payloadID}`));
    } catch (error){
      console.log(error)
    }
  },

  async deleteReport({commit},payloadID) {
    try {
      commit("deleteReport",await Repository.delete(`${MESSAGE_API}/${payloadID}`));
    } catch (error){
      console.log(error)
    }
  },

  async searchReport({commit},searchTerm) {
    try {
      commit("loadAllReports",await Repository.get(`${MESSAGE_API}/search?q=${searchTerm}`));
    } catch (error){
      console.log(error)
    }
  },



};

export default {
  namespaced: true,
  state,
  gettters,
  mutations,
  actions,
};
