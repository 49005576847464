<template lang="pug">
  div
    v-text-field(
      v-model.number="computedValue"
      :label="schema.label"
      :hint="schema.label"
    )
</template>

<script>
export default {
  name: "TextFieldTableField",
  props:["dataValue","schema"],
  data:()=>({
    result:null,
  }),
  computed:{
    computedValue: {
      get: function() {
        return this.dataValue;
      },

      set: function(theValue) {
        console.log(theValue)
        this.$emit("change", {
          id: this.schema.id,
          value: theValue,
        });
      }
    }
  }
}
</script>

<style scoped lang="stylus">

</style>