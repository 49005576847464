import Repository from "@/repository";

const UPLOADS_API = "upload";

// ----------------------- STATE ------------------------- //
const state = {
    documentFiles: [],
    documentDialogShow: false,
    documentDialogMode: "edit", // options are "create" for new and "edit" for Editing,
    currentDocument: {
        fileName: null,
        description: "",
        file: null,
        id: null
    },

    // Vehicles
    imageFiles: [],
    imageDialogShow: false,
    imageDialogMode: "create", // options are "create" for new and "edit" for Editing,
    currentImage: {
        fileName: null,
        description: "",
        file: null,
        id: null
    }
};

// ------------------------- GETTERS ------------------------- //
const getters = {
    getCurrentDocument: state => {
        return state.currentDocument;
    },

    getDocumentByID: state => documentID => {
        return state.documentFiles.find(function (item) {
            return item.id == documentID;
        });
    }
};

// ------------------------- MUTATIONS ------------------------- //
const mutations = {
    getImagesByVehicle: (state, payload) => {
        state.imageFiles = payload.data;
    },

    getDocumentsByVehicle: (state, payload) => {
        state.documentFiles = payload.data;
    },

    setDocumentDialogShow: (state, payload) => {
        state.documentDialogShow = payload.value;
        state.documentDialogMode = payload.mode;
    },

    setCurrentDocument: (state, payload) => {
        state.currentDocument = payload;
    },

    setDocumentFileName: (state, payload) => {
        state.currentDocument.fileName = payload;
    },

    setDocumentDescription: (state, payload) => {
        state.currentDocument.description = payload;
    },

    setDocumentFile: (state, payload) => {
        state.currentDocument.file = payload;
    },

    deleteDocument: (state, payload) => {
        // find index and delete File
        for (var i = 0; i < state.documentFiles.length; i++) {
            if (state.documentFiles[i].id === payload.data.id) {
                state.documentFiles.splice(i, 1);
            }
        }
    },

    // Images
    setCurrentImage: (state, payload) => {
        state.currentImage = payload;
    },

    setImageDialogShow: (state, payload) => {
        state.imageDialogShow = payload.value;
        state.imageDialogMode = payload.mode;
    },

    setImageFileName: (state, payload) => {
        state.currentImage.fileName = payload;
    },

    setImageDescription: (state, payload) => {
        state.currentImage.description = payload;
    },

    setImageFile: (state, payload) => {
        state.currentImage.file = payload;
    },

    deleteImage: (state, payload) => {
        // find index and delete File
        for (var i = 0; i < state.imageFiles.length; i++) {
            if (state.imageFiles[i].id === payload.data.id) {
                state.imageFiles.splice(i, 1);
            }
        }
    },

    reset(state) {
        state.documentFiles = []
        state.documentDialogShow = false
        state.documentDialogMode = "edit"
        // options are "create" for new and "edit" for Editing,
        state.currentDocument = {
            fileName: null,
            description: "",
            file: null,
            id: null
        }

        // Vehicles
        state.imageFiles = []
        state.imageDialogShow = false
        state.imageDialogMode = "create" // options are "create" for new and "edit" for Editing,
        state.currentImage = {
            fileName: null,
            description: "",
            file: null,
            id: null
        }
    }
};

// ------------------------- ACTIONS ------------------------- //
const actions = {
    // IMAGES SECTION
    async getImagesByVehicle({commit}, payload) {
        try {
            commit(
                "getImagesByVehicle",
                await Repository.get(`${UPLOADS_API}/image/${payload.id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async updateImageByVehicle({state, commit}) {
        await Repository.post(`${UPLOADS_API}/image`, state.currentImage)
            .then(response => {
                commit("setCurrentImage", response.data);
                commit("setImageDialogShow", {value: false, mode: "edit"});
            })
            .catch(error => {
                console.log(error);
            });
    },

    async deleteImage({commit}, payload) {
        try {
            commit(
                "deleteImage",
                await Repository.delete(`${UPLOADS_API}/image/${payload.id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    // DOCUMENTS SECTION
    async getDocumentsByVehicle({commit}, payload) {
        try {
            commit(
                "getDocumentsByVehicle",
                await Repository.get(`${UPLOADS_API}/document/${payload.id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async updateDocumentByVehicle({state, commit}) {
        await Repository.post(`${UPLOADS_API}/document`, state.currentDocument)
            .then(response => {
                commit("setCurrentDocument", response.data);
                commit("setDocumentDialogShow", {value: false, mode: "edit"});
            })
            .catch(error => {
                console.log(error);
            });
    },

    async deleteDocument({commit}, payload) {
        try {
            commit(
                "deleteDocument",
                await Repository.delete(`${UPLOADS_API}/document/${payload.id}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
