<template lang="pug">
  div
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Issues
        v-spacer

        .text-xs-center
          v-btn.ml-2(small, outlined color='primary' @click="oRefresh")
            v-icon(dark) refresh
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list(small)
              v-list-item-group(color="primary")
                v-list-item(v-for='item in actionItems', :key='item.title' @click='onAction(item.value)')
                  v-list-item-title {{ item.title }}
        v-btn.ma-2(small, color='primary', dark)
          v-icon.mr-2() attachment
          | Add Issue
        v-btn.ma-2.mr-5(small, color='primary', dark, @click='openEdit') Add Issue
    v-divider
    v-layout.mx-4.my-2(column)
      v-chip-group(v-model="statusIndex" column  active-class="primary--text")
        v-chip(v-for='toggleItem in toggleItems', :key='toggleItem' small @click="onStatusChange(toggleItem)" v-html="toggleItem + getStatusQty(toggleItem)")
    v-layout.ma-4(row align-center)
      v-row()
        v-col(cols="12" xs="12" md="5" xl="3")
          .d-flex.align-center()
            v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" )
            v-btn(v-if="search.length > 0" text outlined small @click="search=''") clear all


    v-divider
    .grey.lighten-4.py-3
    v-divider
    issues-table(:search="search" :status="status" @changeState="onStatusChange")
    v-divider
</template>

<script>
import IssuesTable from "@/modules/issues/components/tables/IssuesTable";
import VehiclesAutoComplete from "@/modules/global/components/forms/VehiclesAutoComplete";

export default {
  components: {
    IssuesTable,
    VehiclesAutoComplete
  },

  data: () => ({
    search: "",
    vehiclesID: [],
    toggleItems: ["open", "overdue", "resolved", "closed"],
    statusIndex: 0,
    status: "open",
    actionItems: [
      {
        title:"Delete Selected",
        value: "delete_selected"
      }
    ]
  }),

  methods: {
    oRefresh: function() {
      this.$store.dispatch("issues/loadAllIssues");
    },
    openEdit: function() {
      this.$store.dispatch("issues/addIssue").then(() => {
        let currentObject = this.$store.state.issues.issue_data[
          this.$store.state.issues.currentIndex
        ];
        this.$router.push({
          name: "issueDetailsForm",
          params: { issueID: currentObject.id }
        }).catch(()=>{});
      });
    },


    onStatusChange: function(value) {
      this.status = value;
      this.statusIndex = this.toggleItems.findIndex(item => item === value);
    },

    getStatusQty: function(value) {
      if (this.$store.getters["issues/getIssueByStatus"](value).length > 0) {
        return (
          "<span style='font-weight:bold'>&nbsp; &ndash; &nbsp;" +
          this.$store.getters["issues/getIssueByStatus"](value).length +
          "</span>"
        );
      } else {
        return "";
      }
    },

    showDialog: function() {},

    closeDialog: function(value) {
      console.log(value);
    },
    onAction: function(value) {
      switch (value) {
        case "delete_selected":
          var deletedIds = [];
          this.$store.getters["issues/getSelectedIssues"].forEach(element => {
            deletedIds.push(element.id);
          });
          confirm(`Are you sure you want to delete this ${deletedIds.length} item?`) &&
          this.$store.dispatch("issues/deleteSelectedIssues", deletedIds);
          break;
      }
    }
  },

  mounted() {
    this.toggle;
  }
};
</script>

<style scoped></style>
