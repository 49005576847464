<template lang="pug">
  div
    v-layout.ma-2(justify-end)
      v-btn.mb-2.mx-2(small, color='primary',outlined, dark, @click='cancel') Cancel
      v-btn.mb-2.mx-2(small, color='primary', dark, @click='save') Save
    v-form(v-model="valid" ref="form")  
      form-sub-header Account Settings
      v-container.white(grid-list-xs)
        v-layout.pl-2.pt-3(row align-center justify-start)
          img-thumbnail.mr-4(:height="100"  :src="thumbnail")
          .HeaderText()
            .title.font-weight-bold.grey--text.text--darken-3 
              | {{organization_name}}
            .caption.font-weight-regular 
              | {{address}} - {{code}}
            .caption.font-weight-regular 
              | {{city}}, {{country}}
            .caption.font-weight-regular 
              | {{phone}}
            

        form-divider ACCOUNT DETAILS
        v-layout(row, wrap)
          v-flex(xs12)
            attach-fill-form(:schema="accountImageSchemas" :data="{ value:thumbnail}" @onChange="saveAccountImages"  @onMenuAction="onMenuAction" )
          v-flex(xs12, md6, xl6)
            v-text-field(v-model='account_name' label='Account Name', disabled)
          v-flex(xs12, md6, xl6)
            v-text-field(v-model='organization_name', hint='Legal name of your Organization or Entity', :persistent-hint='true', label='Organization Name*' :rules='[rules.required]')
          v-flex(xs12, md6, xl6)
            v-text-field(v-model='address', hint='Postal Address', :persistent-hint='true', label='Postal Address', required)
          v-flex(xs12, md6, xl6)
            v-text-field(v-model='code', hint='Postal Code', :persistent-hint='true', label='Zip Code', required)
          v-flex(xs12, md6, xl6)
            v-text-field(v-model='city', hint='City', :persistent-hint='true', label='City', required)
          v-flex(xs12, md6, xl6)
            v-text-field(v-model='region', hint='County/Region', :persistent-hint='true', label='Region', required)
          v-flex(xs12, md6, xl6)
            v-select(v-model='country', :items='$store.state.settings.country_types', hint='Country', :persistent-hint='true', label='Country')
          v-flex(xs12, md6, xl6)
            v-text-field(v-model='locationAddress', hint='Street Address', :persistent-hint='true', label='Location Address', required)
          v-flex(xs12, md6, xl6)
            v-text-field(v-model='phone', hint='Phone', :persistent-hint='true', label='Phone', required)
          v-flex(xs12, md6, xl6)
            v-select(v-model='industry', :items='$store.state.settings.industry_types', :item-text="'name'", :item-value="'ID'", hint='Industry', :persistent-hint='true', label='Industry')
        form-divider REGIONAL SETTINGS
        v-layout(row, wrap)
          v-flex(xs12, md6, xl6)
            v-select(v-model='currency', :items='$store.state.settings.currency_types', :item-text="'name'", :item-value="'type'", hint='Currency', :persistent-hint='true', label='Currency' )
          v-flex(xs12, md6, xl6)
            v-select(v-model='shortDateFormat', :items='$store.state.settings.date_format_types', hint='Date Format', :persistent-hint='true', label='Date Format')
          v-flex(xs12, md6, xl6)
            v-select(v-model='timeZone', :items='$store.state.settings.time_zone_types', :item-text="'city'", :item-value="'value'", hint='Time Zone', :persistent-hint='true', label='Time Zone' )
          v-flex(xs12, md6, xl6)
            v-select(v-model='timeFormat', :items='$store.state.settings.time_format_types', :item-text="'name'", :item-value="'value'", hint='Time Format', :persistent-hint='true', label='Time Format' )
          v-flex(xs6, md3, xl3)
            .caption.grey--text.text--darken-1 Usage
            v-radio-group.mt-0(v-model='usage')
              v-radio(v-for='(usage, index) in $store.state.settings.usage_types', :key='index', :label='usage.name', :value='usage' )
          v-flex(xs6, md3, xl3)
            .caption.grey--text.text--darken-1 Measurment
            v-radio-group.mt-0(v-model='systemMeasurent')
              v-radio(v-for='(usage, index) in $store.state.settings\
              .system_measurment_types', :key='index', :label='usage', :value='usage' )
          v-flex(xs6, md3, xl3)
            .caption.grey--text.text--darken-1 Fuel Volume
            v-radio-group.mt-0(v-model='fuelVolume')
              v-radio(v-for='(item, index) in $store.state.settings.fuel_volume_types', :key='index', :label='item.name', :value='item' )
        form-divider TAX SETTINGS
        v-layout(row, wrap)
          v-flex(xs12, md6, xl6)
            v-switch(label='Tax Free Labour', v-model='taxFreeLabour', hint='If on, tax calculations will not include labor subtotals', :persistent-hint='true', l)
          v-flex(xs12, md6, xl6)
            v-switch(label='Secondary Tax', v-model='secondaryTax', hint='Enables a second tax field for Work Orders and Service Entries', :persistent-hint='true', l)
          v-flex(xs12, md6, xl6)
            v-text-field(v-model='defaultTax1', hint="Value entered will be used to automatically prefill any 'Tax 1' fields across TSV System. Users will still be able to edit the tax value on the form.", :persistent-hint='true', label='Default Tax 1', type='number', )
          v-flex(xs12, md6, xl6)
            v-text-field(v-model='defaultTax2', hint="Value entered will be used to automatically prefill any 'Tax 2' fields across TSV System. Users will still be able to edit the tax value on the form.", :persistent-hint='true', label='Default Tax 2', )
          v-flex(xs12, md6, xl6)
            v-select(v-model='defaultTaxType', :items='$store.state.settings.tax_types', hint='Default Tax Mode', :persistent-hint='true', label='Tax Mode',)
        form-divider COMMUNICATIONS
        v-layout(row, wrap)
          v-flex(x12, md6, x16)
            v-btn(outlined color="primary" ) Link to Whatsapp Phone
          v-flex(x12, md6, x16)
            div() Whatsapp Image
    v-layout.ma-2(justify-end)
      v-btn.mb-2.mx-2(small, color='primary',outlined, dark, @click='cancel') Cancel
      v-btn.mb-2.mx-2(small, color='primary', dark, @click='save') Save

</template>
<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import { createHelpers } from "vuex-map-fields";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
// import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import ImgThumbnail from "@/modules/global/components/forms/ImgThumbnail.vue";

const { mapFields } = createHelpers({
  getterType: "settings/accountSettings/getAccountSetting",
  mutationType: "settings/accountSettings/updateAccountSetting"
});

export default {
  components: {
    FormSubHeader,
    FormDivider,
    AttachFillForm,
    // ImageThumbnail,
    ImgThumbnail
  },
  data: () => ({
    accountImageSchemas: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "upload Organization Logo",
      hint: "jpeg or png",
      type: "image",
      image_size: "small",
      multiple: false
    },

    additionalMenuItems: [
      { title: "Make Thumbnail", icon: "edit", action: "make_default" }
    ],

    valid: false,
    settings: null,
    rules: {
      required: value => !!value || "Required.",
      checkIfNumber: value => !isNaN(parseInt(value)) || "Enter a number value"
    }
  }),

  methods: {
    save: function() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("settings/accountSettings/saveAccountSetting");
      }
    },

    cancel: function() {
      console.log("Cancel");
    },

    saveAccountImages(theObject) {
      this.$store.commit("settings/accountSettings/updateAccountSetting", {
        path: "thumbnail",
        value: theObject.value[0]
      });
    },

    onMenuAction: function(value) {
      // console.log(value);
      switch (value.action) {
        case "delete":
          this.$store.commit("settings/accountSettings/updateAccountSetting", {
            path: "thumbnail",
            value: null
          });

          break;
        case "edit":
          console.log("edit");
          break;
        case "make_thumbnail":
          console.log("make_default");
          break;
      }
    },

    deleteAccountImages(value) {
      console.log(`delete account image ${value.id}`);
      let index = this.blob_ids.findIndex(item => item == value.id);
      this.blob_ids.splice(index, 1);
    },

    editAccountImages(value) {
      console.log(`the edit image ${value}`);
    }
  },

  mounted() {},
  computed: {
    ...mapFields({
      account_name: "account_name",
      organization_name: "organization_name",
      address: "account_details.address",
      locationAddress: "account_details.location_address",
      code: "account_details.code",
      city: "account_details.city",
      region: "account_details.region",
      country: "account_details.country",
      phone: "account_details.phone",
      industry: "account_details.industry",
      currency: "region_settings.currency",
      shortDateFormat: "region_settings.short_date_format",
      timeZone: "region_settings.time_zone",
      timeFormat: "region_settings.time_format",
      usage: "default_units.usage",
      systemMeasurent: "default_units.system_measurent",
      fuelVolume: "default_units.fuel_volume",
      taxFreeLabour: "tax_settings.tax_free_labour",
      secondaryTax: "tax_settings.secondary_tax",
      defaultTax1: "tax_settings.default_tax1",
      defaultTax2: "tax_settings.default_tax2",
      defaultTaxType: "tax_settings.default_tax_type",
      thumbnail: "thumbnail"
    })
  }
};
</script>
<style scoped>
.btnArrangement {
  display: flex;
  justify-content: flex-end;
}

.HeaderText {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 10px;
}
</style>
