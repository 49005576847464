<template lang="pug">
  #blankcontent(v-if="fueldata.length > 0" )
    .panelTitle() Fuel Costs
    v-divider.mx-2()
    bar-chart(:style="{height:`${clientHeight-40}px`, width: `100%`}" :chartdata="getDataSets" :options="chartOption")

</template>
<script>
import BarChart from "@/modules/global/components/charts/BarChart";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    BarChart
  },
  props: {
    clientHeight: Number
  },
  data: () => ({
    fueldata: [],
    monthdata: [],
    chartOption: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              maxTicksLimit: 5,
              beginAtZero: true
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0.05)"
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ]
      },
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 20,
          bottom: 0
        }
      }
    }
  }),
  mounted() {
    axios.get("/api/dashboard/fuelmonthtotal").then(response => {
      // console.log(response.data)
      this.fueldata = [];
      this.monthdata = [];
      let monthindexes = []
      let currentmonth = moment().format("M")
      for (var i = 6; i > 0; i--) {
        let index = currentmonth - i 
        index = index < 1 ? 12 + index : index
        console.log(index)
        monthindexes.push(index)
        let d = new Date(`2020-${index}-01`)
        this.monthdata.push(moment(d).format("MMM"))

      }
      monthindexes.forEach(element => {
        let a = response.data.find(item => moment(item.month).format("M") == element.toString()) 
      
     
        if(typeof a == 'undefined'){
          this.fueldata.push(0)
        }
        else{
          this.fueldata.push(a.sum)
        }

      })
      
        
     
    });
  },

  computed: {
    getDataSets: function() {
      let obj = {
        labels: this.monthdata,
        datasets: [
          {
            label: "Months",
            data: this.fueldata
            // borderWidth: 2
          }
        ]
      };
      obj.datasets[0].backgroundColor = this.$vuetify.theme.themes.light.primary;
      // obj.datasets[0].borderColor = this.$vuetify.theme.themes.light.primary;
      return obj;
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 30px;
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.lineChartPanel {
  height: calc(100% - 45px);
}
</style>
