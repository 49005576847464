<template lang="pug">
  div() BlankRenderer computedvalue {{computedValue}} schema_id: {{this.schema.id}} schema_name {{this.schema.name}}
</template>
<script>

import SETTINGS from "@/modules/inspections/store/formSettings";


export default {

  props: {
    schema: Object
  },
  data: ()=>({
  }),
  computed:{



    computedValue: {
      get: function() {

        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id);
      },

      set: function(theValue) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }
  },

  methods:{

  }
};
</script>
<style scoped lang="stylus">

</style>
