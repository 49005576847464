<template lang="pug">
  div() Hellos
</template>

<script>
export default {
  name: "MessagePage"
}
</script>

<style scoped lang="stylus">

</style>