<template lang="pug">
  v-row.mx-2.body-2.black--text(v-if="item")
    v-col.showBorder(cols="6" sm="6" md="4") {{$store.getters['service/serviceType/getServiceTypeFromID'](item.service_type_id).name}}
    v-col.showBorder(cols="6" sm="6" md="4") item
    v-col.showBorder.py-0(cols="12" sm="12" md="4")
      v-row()
        v-col.showBorder(cols="4")
          service-input2(v-model.number="labour" :isDisabled="item.labour_disabled" :style="item.labour_disabled?{'opacity':0.3}:{'opacity':1.0}")
        v-col.showBorder(cols="4")
          service-input2(v-model.number="parts" :isDisabled="item.parts_disabled"  :style="item.parts_disabled?{'opacity':0.3}:{'opacity':1.0}")
        v-col.showBorder(cols="4")
          service-input2(v-model.number="total" :isDisabled="item.total_disabled" )


</template>
<script>
import ServiceInput2 from "@/modules/services/components/ui/ServiceInput2";

export default {
  components: {
    ServiceInput2
  },
  props: {
    item: Object,
    index: Number
  },

  data: () => ({}),
  mounted() {
    console.log(this.$store.getters["service/serviceType/getServiceTypeFromID"]("1bda6963-aa51-48d7-bfe0-b0596f73ba6a"))
  },
  computed: {
    labour: {
      get: function () {
        return this.item.labour;
      },
      set: function (value) {
        this.$store.commit("service/fieldUpdateIndex", {
          path: "labour",
          value: parseFloat(value),
          id: this.item.id
        });
        this.$store.commit("service/calculateSubTotals");
      }
    },

    parts: {
      get: function () {
        return this.item.parts;
      },
      set: function (value) {
        this.$store.commit("service/fieldUpdateIndex", {
          path: "parts",
          value: parseFloat(value),
          id: this.item.id
        });
        this.$store.commit("service/calculateSubTotals");
      }
    },

    total: {
      get: function () {
        return this.item.total;
      },
      set: function (value) {
        this.$store.commit("service/fieldUpdateIndex", {
          path: "total",
          value: parseFloat(value),
          id: this.item.id
        });
        this.$store.commit("service/calculateSubTotals");
      }
    }
  },


};
</script>
<style lang="stylus" scoped></style>
