var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('panel-title-header',{attrs:{"label":"Inspection Details"}}),_c('div',{staticClass:"mt-2 mb-2 pb-2"},[_c('table',{staticClass:"DetailTable body-2 fill-width"},[_c('tr',[_vm._m(0),_c('td',[(_vm.record.vehicle_id)?_c('vehicle-column-by-id',{attrs:{"vehicle_id":_vm.record.vehicle_id}}):_vm._e()],1)]),_c('tr',[_vm._m(1),_c('td',[_c('div',[_vm._v(_vm._s(_vm.record.odometer))])])]),_c('tr',[_vm._m(2),_c('td',[_c('div',[_vm._v(_vm._s(_vm.record.tx_id))])])]),_c('tr',[_vm._m(3),_c('td',[_c('div',{staticClass:"d-flex align-center"},[(_vm.inspectionForm.thumbnail_id)?_c('div',[_c('image-thumbnail',{staticClass:"mr-2",attrs:{"src":_vm.inspectionForm.thumbnail_id,"height":60,"width":80}})],1):_vm._e(),_c('div',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.inspectionForm.name))])])])]),_c('tr',[_vm._m(4),_c('td',[_c('div',[_vm._v(_vm._s(_vm.inspectionForm.description))])])]),_c('tr',[_vm._m(5),_c('td',[_c('div',[_vm._v(_vm._s(_vm.record.inspection_form_version))])])]),_c('tr',[_vm._m(6),_c('td',[_vm._v(_vm._s(_vm.getTime(_vm.record.submitted_on)))])]),_c('tr',[_vm._m(7),_c('td',[_vm._v(_vm._s(_vm.record.geotag.lon)+", "+_vm._s(_vm.record.geotag.lat))])]),_c('tr',[_vm._m(8),_c('td',[_vm._v(_vm._s(_vm.getTimeSpent(_vm.record.duration)))])]),_c('tr',[_vm._m(9),_c('td',[(_vm.record.submitted_by_id)?_c('contact-column-by-id',{attrs:{"contact_id":_vm.record.submitted_by_id}}):_vm._e()],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3 pl-2"},[_vm._v("Vehicle")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3 pl-2"},[_vm._v("Odometer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3 pl-2"},[_vm._v("Transaction ID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3 pl-2"},[_vm._v("Inspection Form")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3 pl-2"},[_vm._v("Inspection Form Description")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3 pl-2"},[_vm._v("Inspection Form Version")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3 pl-2"},[_vm._v("Submitted On")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3 pl-2"},[_vm._v("Location Cordinates")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3 pl-2"},[_vm._v("Time taken")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3 pl-2"},[_vm._v("Submitted By")])])
}]

export { render, staticRenderFns }