<template lang="pug">
  div
    v-container.pt-0(grid-list-sm)
      form-divider NOTIFICATIONS
      v-layout(row, wrap, justify-start)
        v-flex(xs12, md6, xl4)
          contacts-auto-complete(v-model="violation_contacts_id" :multiple="true" label="Violation Notifications")
        v-flex(xs12, md6, xl4)
          contacts-auto-complete(v-model="fuel_sms_contacts_id" :multiple="true" label="Fuel SMS Notifications")
        v-flex(xs12, md6, xl4)
          contacts-auto-complete(v-model="fuel_email_contacts_id" :multiple="true" label="Fuel Email Notifications")


</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import { createHelpers } from "vuex-map-fields";
import ContactsAutoComplete from "@/modules/global/components/forms/ContactsAutoComplete";

const { mapFields } = createHelpers({
  getterType: "vehicles/getItem",
  mutationType: "vehicles/updateItem"
});

export default {
  components: {
    FormDivider,
    ContactsAutoComplete
  },

  data: () => ({
    // green, blue, orange, red, gray
  }),

  computed: {
    ...mapFields(["violation_contacts_id","fuel_sms_contacts_id","fuel_email_contacts_id"])
  }
};
</script>

<style scoped>
.topiDivider {
  color: #aa00cc;
  border-bottom: 2px solid #aa00cc;
  margin-top: 20px;
  font-size: 18px;
}
</style>
