<template lang="pug">
  v-dialog(v-model="dialog" max-width="290")
    v-card
      v-card-title.headline {{headline}}
      v-card-text
        | {{message}}
      v-card-actions
        v-spacer
        v-btn(color="grey darken-3" text @click="$emit('close')") {{cancelLabel}}
        v-btn(color="primary" @click="$emit('action',item)") {{okLablel}}
</template>
<script>
export default {
  props: {
    item: Object,
    headline: String,
    message: String,
    dialog: {
      type: Boolean,
      default: false
    },
    okLablel: {
      type: String,
      default: "Ok"
    },
    cancelLabel: {
      type: String,
      default: "Cancel"
    }
  },
  data: () => ({})
};
</script>
<style scoped lang="stylus"></style>
