<template lang="pug">
  div
    v-container.my-0.pa-0(fluid)
      p.my-0 {{ schema.label }}
      v-layout.ml-0(v-bind="bindValues")
        v-checkbox.mr-3.pa-0.mt-0.mb-2(v-for="(item,index) in schema.options" :key="index" v-model="selected" :hide-details="true" :value="item" :label="item" )
      p.caption(v-show="schema.hint != null") {{ schema.hint }}
</template>
<script>
export default {
  props: {
    schema: Object
  },
  data: () => ({
    selected: []
  }),

  computed: {
    bindValues: function() {
      let p = {};
      if (this.schema.orientation === "row") {
        p["row"] = true;
      }

      if (this.schema.orientation === "column") {
        p["column"] = true;
      }
      return p;
    }
  }
};
</script>
<style scoped lang="stylus"></style>
