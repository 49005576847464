<template lang="pug">
div
  v-divider
  v-container.pa-0(fluid)
    v-layout.pa-4(row, wrap)
      h2.mx-4 Routes
      v-spacer
      .text-xs-center
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color='primary', dark, v-on="on", )
              | Actions
              v-icon(dark) keyboard_arrow_down
          v-list(small)
            v-list-item-group(color="primary")
              v-list-item(v-for='item in items', :key='item.title')
                v-list-item-title {{ item.title }}
    v-divider
    v-layout.ma-4(row align-center)
      v-row()
        v-col(cols="12" xs="12" md="5" xl="3")
          .d-flex.align-center()
            v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" )
            v-btn(v-if="search.length > 0" text outlined small @click="search=''") clear all
    v-divider
    .grey.lighten-4.py-3
    v-divider
    router-table()
</template>

<script>
  import RouterTable from "@/modules/places/components/tables/RouterTable";
  export default {
    name: "RouterListLayout",
    components:{
      RouterTable,
    },
    data: ()=>({
      search: "",
      selectedItems: [],
      tags: ["open", "approved", "resolved", "closed"],
      items: [
        {
          title: "Download Template",
          value: "download"
        },
        {
          title: "Import CSV",
          value: "import_csv"
        }
      ],

      vehiclesFilter: "",
      searchFilter: ""
    })
  }
</script>

<style scoped>

</style>