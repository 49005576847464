<template lang="pug">
  div(v-if="$store.state.settings.customFieldSettings.currentIndex > -1")
    .d-flex.justify-end()

      v-btn.ma-2.mb-6(small='', color='primary', dark='', @click='addNew') Add New
    form-sub-header Custom Field Settings
    v-data-table(
      :headers='headers',
      :items="getCustomFields",
      :loading='loading'
    )

      template(v-slot:progress)
        v-progress-linear(slot="progress"  height="2" :indeterminate="loading")
      template(v-slot:item.reference="{item}")
        .font-weight-bold() {{$store.getters['settings/customFieldSettings/getReferenceLabel'] (item.reference)}}

      template(v-slot:item.reference_id="{item}")
        div() {{getReferenceName(item)}}
      template(v-slot:item.id="{item}")
        div(v-html="item.id")
      template(v-slot:item.label="{item}")
        div(v-html="item.label")
      template(v-slot:item.order_no="{item}")
        div(v-html="item.order_no")
      template(v-slot:item.action="{item}")
        v-row.mr-7(justify="end")
          more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

</template>
<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
export default {
  components: {
    FormSubHeader,
    MoreDropDownMenu
  },

  data: () => ({
    loading: true,
    headers: [
      {text: "Label", value: "label", align: "left"},
      {text: "Label ID", value: "label_id", align: "left"},
      {text: "Type", value: "type", align: "left"},
      {text: "Order", value: "order_no", align: "left"},
      {text: "Reference", value: "reference", align: "left"},
      {text: "Reference ID", value: "reference_id", align: "left"},
      {text: "Actions", value: "action", align: "right"}
    ],

    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ]
  }),

  computed: {

    getCustomFields: function () {
      return this.$store.state.settings.customFieldSettings.customfield_data;
    }

  },

  methods: {
    addNew: function () {
      this.$store.dispatch("settings/customFieldSettings/addCustomField").then(() => {
        this.$router.push({
          name: "customFieldsForm",
          params: {customFieldID: this.$store.getters["settings/customFieldSettings/getCurrentCustomField"].id}
        }).catch(()=>{})
      })
    },


    getReferenceName: function(item) {
      let name = "";
      switch(item.reference){
        case "part": name = this.$store.getters["inventory/item/getItemById"](item.reference_id).name
      }

      return name;
    },



    onClick: function (value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          this.$store.commit("settings/customFieldSettings/setCurrentIndexByID", value.theObject.id)
          this.$router.push({
            name: "customFieldsForm",
            params: {customFieldID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          this.$store.dispatch(
              "settings/customFieldSettings/deleteCustomField",
              value.theObject.id
          );
          break;
      }
    }
  },

  mounted() {


    if (this.$store.state.settings.customFieldSettings.currentIndex == -1 && this.$store.state.inventory.item.item_data.length < 1) {
      Promise.all([this.$store.dispatch("settings/customFieldSettings/loadAllCustomFields"),this.$store.dispatch("inventory/item/loadAllItems")]).then(()=>{
        this.loading = false;
      })

    }else{
      this.loading = false;
    }
  }
};
</script>
<style lang="stylus" scoped>
.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;

  padding: 0px;
}
</style>
