<template lang="pug">
  div(v-if="$store.state.inventory.purchaseOrder.currentIndex > -1")
    v-toolbar(dense, flat)
      v-toolbar-title Item Record Form
      v-spacer

    v-container.py-2
      v-alert(v-if="error" :value="true" color="error" icon="warning")
        div(v-for="e,index in errors" :key="index" v-text="e")

    v-layout(justify-end)
      v-btn(small flat color="grey" @click="cancel") cancel
      v-btn(small outlined color="primary" @click="saveAdd") Save &amp; Add another
      v-btn(small color="primary" @click="save") Save

    v-container
      v-card


        v-container(grid-list-xl)
          form-divider PARTS DETAILS
          v-container(grid-list-xl)
            v-layout(row, wrap)
              v-flex.pb-4(xs12 md12)
                v-layout(row, wrap)
                  v-flex(xs12 md4)
                    general-auto-complete(v-model='merchantID' prefix='merchant' label='Select Vendor'  hint='Vendor')
                  v-flex(xs12 md4)
                    general-auto-complete(v-model='itemLocationID'  prefix='itemLocation'  subModule="item" label='Select Item Location' hint='Item Location')
                  v-flex(xs12 md4)
                    auto-combo-box(v-model='tagIDs' label="Select Tag"  submodule="tagSettings" actionPrefix="Tag")

              v-flex(md12 lg9 )
                purchase-order-items-input()
              v-flex(md12 lg3 )
                .costPurchaseSection
                  table(style="width: 100%" cellspacing="0")
                    tr
                      td.purchaseCostLeft Subtotal
                      td.purchaseCostRight 123456
                    tr
                      td.purchaseCostLeft Discount
                      td.purchaseCostRight
                        fixed-Percentage-text-input.fixedPecentageText.mt-0.mb-1.mx-0(v-model="discount"  :items="taxDiscountItems"  :prefix="$store.state.profile.userInfo.account.region_settings.currency" type="number" style="max-width:160px;")
                    tr
                      td.purchaseCostLeft Shipping
                      td.purchaseCostRight
                        border-text-input(v-model="shipping" prefix="Ksh." type="number" style="width:150px" )
                    tr
                      td.purchaseCostLeft Tax
                      td.purchaseCostRight
                        fixed-Percentage-text-input.fixedPecentageText.mt-0.mb-2.mx-0(v-model="tax" :items="taxDiscountItems" :prefix="$store.state.profile.userInfo.account.region_settings.currency" type="number" style="max-width:160px;")
                    tr
                      td.purchaseCostLeft.purchaseTotalCost Total
                      td.purchaseCostRight.purchaseTotalCost
                        .headline 12345


              v-flex(xs12 md12)
                .additionalPurchaseDetails
                  v-layout.pa-1
                    v-flex(xs12 md8)
                      | Upload files
                    v-flex(xs12 md4)
                      v-textarea(v-model="description" outlined  label="Description"  hide-details)

              //- v-text-field(v-model='name', hint='Internal item identifier. Must be unique per item', :persistent-hint='true', label='Item Number')


    v-layout(justify-end)
      v-btn(small flat color="grey" @click="cancel") cancel
      v-btn(small outlined color="primary" @click="saveAdd") Save &amp; Add another
      v-btn(small color="primary" @click="save") Save
</template>
<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import PurchaseOrderItemsInput from "@/modules/inventory/items/components/ui/PurchaseOrderItemsInput";
import BorderTextInput from "@/modules/global/components/forms/BorderTextInput";
import FixedPercentageTextInput from "@/modules/global/components/forms/FixedPercentageTextInput";
import GeneralAutoComplete from "@/modules/global/components/forms/GeneralAutoComplete";
import AutoComboBox from "@/modules/global/components/forms/AutoComboBox";

import {createHelpers} from "vuex-map-fields";
import NumberGuage from "../../../global/components/dashboard/NumberGuage";
import RoleForm from "../../../settings/components/forms/RoleForm";

const {mapFields} = createHelpers({
  getterType: "inventory/purchaseOrder/getPurchaseOrder",
  mutationType: "inventory/purchaseOrder/updatePurchaseOrder"
});

export default {
  components: {
    RoleForm,
    NumberGuage,
    FormDivider,
    PurchaseOrderItemsInput,
    BorderTextInput,
    FixedPercentageTextInput,
    GeneralAutoComplete,
    AutoComboBox
  },

  data: () => ({
    error: false,
    errors: [],
    shipping: "2000",
    description: "The quic brown fox jumpes over the lazy dog.",
    mode: "percentage",
    taxamount: 88,
    discountMode: "fixed",
    discountAmount: 2335,
    merchantID: "ee1547e9-3b3d-4a6e-bb17-449db79b8bd7",
    itemLocationID: "098a84c1-08a6-425c-90d0-d5099d98a696",
    tagIDs: "7f8369bf-be14-4b12-beb6-6f123f0d07ca",

    taxDiscountItems: [
      {label: "Percentage Tax", symbol: "%", name: "percentage"},
      {label: "Fixed Cost", symbol: "Ksh.", name: "fixed"}
    ]
  }),

  computed: {
    ...mapFields({
      vendor: "vendor"
    }),

    tax: {
      get: function () {
        return {mode: this.mode, value: this.taxamount};
      },
      set: function (value) {
        console.log(`mode is: ${value.mode} and value is: ${value.value}`);
        this.mode = value.mode;
        this.taxamount = value.value;
      }
    },

    discount: {
      get: function () {
        return {mode: this.discountMode, value: this.discountAmount};
      },

      set: function (value) {
        this.discountMode = value.mode;
        this.discountAmount = value.value;
      }
    }
  },

  methods: {
    cancel: function () {
      this.$router.push({name: "inventorys", params: {tabID: 2}}).catch(()=>{});
    },

    save: function () {
      this.validate();

      if (this.error == false) {
        this.$store.dispatch("inventory/item/saveItem").then(() => {
          this.$router.push({name: "inventorys"}).catch(()=>{});
          this.$store.dispatch("inventory/item/loadAllItems");
        });
      }
    },

    saveAdd: function () {
      console.log("saveAdd");
    },

    validate: function () {
      console.log(this.itemID);
      this.error = false;
      this.errors = [];
    }
  },

  mounted() {
    if (this.$store.state.inventory.purchaseOrder.currentIndex == -1) {
      this.$store
          .dispatch("inventory/purchaseOrder/fetchPurchaseOrder", this.$route.params.itemID)
          .then(() => {
          });
    }
  }
};
</script>
<style lang="stylus" scoped>
.costPurchaseSection
  border 1px solid #CCCCCC
  border-radius 5px
  padding 8px
  background-color #F5F5F5

// font-size 12px


.purchaseCostLeft
  width 75%
  padding 6px 0px;


.purchaseCostRight
  width 100%
  padding 6px 0px;
  text-align right;
  display flex;
  justify-content flex-end


.purchaseTotalCost
  // background-color #FF0000;
  border-top 1px solid #CCCCCC;
  padding 8px 0px;

.additionalPurchaseDetails
  border 1px solid #CCCCCC
  border-radius 5px
  padding 8px
  background-color #FAFAFA
  width 100%
</style>
