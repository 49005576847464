<template lang="pug">
  auto-combo-box(v-model='result' :label="element.label_id"  :module="module" :actionPrefix="actionPrefix" :field="field")
</template>

<script>
import AutoComboBox from "@/modules/global/components/forms/AutoComboBox";

export default {
  components: {
    AutoComboBox,
  },
  props: ["value", "element", "mutationType", "module", "submodule", "actionPrefix", "field"],
  watch: {
    result: function (newVal) {
      this.$store.commit(this.mutationType, {path: `details.${this.element.label_id}`, value: newVal})
    }
  },
  data: () => ({
    result: null,
  }),
  methods: {},
  mounted() {
    this.result = this.value;
  }
}
</script>

<style scoped>

</style>