import ServiceTypeForm from "@/modules/services/components/forms/ServiceTypeForm";
import ServiceDetailForm from "@/modules/services/components/forms/ServiceDetailForm";
import ServiceOverview from "@/modules/services/layouts/ServiceOverview";

export const SERVICE_ROUTES = [
  {
    path: "serviceTypeForm",
    name: "serviceTypeForm",
    component: ServiceTypeForm
  },

  {
    path: "serviceDetailForm",
    name: "serviceDetailForm",
    component: ServiceDetailForm
  },
  {
    path: "serviceOverview",
    name: "serviceOverview",
    component: ServiceOverview
  }
];
