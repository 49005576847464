<template lang="pug">
  v-card()
    v-card-title.grey.lighten-2(primary-title='')
      div()
        | Mobile Registration
        div.caption() Scan Code on Mobile App
    v-divider
    v-card-text()
      v-layout(align-center justify-center fill-height)
        img(:src="'/api/register/generateQRCode?id='+theID+'&rand='+Math.random()" width="200" height="200")
    v-card-actions
      v-spacer
      v-btn(color='primary', text='',)
</template>

<script>
export default {
  props: {
    selectedContactId: String
  },
  watch: {
    selectedContactId: function (newVal) {
      this.theID = newVal;
    }
  },

  mounted() {
    this.theID = this.selectedContactId;
  },

  data: () => ({
    theID: null
  }),

  methods: {}
};
</script>

<style></style>
