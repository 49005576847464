<template lang="pug">
  div()
    router-view()
</template>
<script>
export default {
  data: () => ({})

};
</script>
<style scoped lang="stylus"></style>
