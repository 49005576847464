import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";
import * as turf from "@turf/turf";

const GEOFENCE_API = "/geofence";

// ----------------------- STATE ------------------------- //
const state = {
    geofence_data: [],
    currentIndex: -1,
    updateFlag: false, // activated when updating polygon on point. i.e. moving or draging
    createFlag: false,  // activated when creating polygon/point when selecting a tool
    showDialog: false,
    geofenceTableKey: 0,
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getGeofence: state => getField(state.geofence_data[state.currentIndex]),

    getCurrentGeofence: state => {
        return state.geofence_data[state.currentIndex];
    },

    getCurrentObjectId: state => {
        return state.geofence_data[state.currentIndex].object.id
    },

    getGeofenceByObjectIndexByObjectId: state => objectID => {
        return state.geofence_data.findIndex(element => objectID == element.object.id)
    },

    getGeofenceDataBySearch: state => term => {
        if (term == null || term.length == 0){
            return state.geofence_data
        }

        let result = state.geofence_data.filter(element => {
            let isFound = false;
            // console.log(element.name.toLowerCase().search(term.toLowerCase()))
            if (element.name != null && element.name.toLowerCase().indexOf(term.toLowerCase()) > -1){
                isFound = true;
            }
            if (element.description != null && element.description.toLowerCase().indexOf(term.toLowerCase()) > -1){
                isFound = true;
            }
            return isFound
        })
        return result
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateGeofence(state, payload) {
        updateField(state.geofence_data[state.currentIndex], payload);
    },

    updateObjectByGeofenceID(state, payload) {
        let theIndex = state.geofence_data.findIndex(geofence => geofence.id == payload.id)
        if (theIndex > -1) {
            state.geofence_data[theIndex].object = payload.feature
        }
    },

    updateObjectByObjectID(state, payload) {
        console.log("*******************")
        console.log(payload)

        state.currentIndex = state.geofence_data.findIndex(
            geofence => geofence.object.id == payload.id
        );
        if (state.currentIndex > -1) {
            state.geofence_data[state.currentIndex].object = payload;
            if (payload.properties.isCircle != null && payload.properties.isCircle) {
                const point = turf.point(payload.properties.center);
                state.geofence_data[state.currentIndex].geometry = point.geometry
                state.geofence_data[state.currentIndex].radius = payload.properties.radiusInKm
            } else {
                state.geofence_data[state.currentIndex].geometry = payload.geometry
            }
        }
        console.log("updatedByID ************")
        console.log(state.geofence_data[state.currentIndex])
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.geofence_data.findIndex(
            geofence => geofence.id == theID
        );
    },

    setCurrentIndexByObjectID(state, theID) {
        state.currentIndex = state.geofence_data.findIndex(
            geofence => geofence.object.id == theID
        );
    },


    loadAllGeofences(state, payload) {
        state.geofence_data = payload.data;
        state.currentIndex = 0;
    },

    addGeofence(state, payload) {
        state.geofence_data.push(payload.data);
        state.currentIndex = state.geofence_data.length - 1;
    },

    saveGeofence(state, payload) {
        state.geofence_data[state.currentIndex] = payload.data;
    },

    saveItemState(state, payload){
        let theIndex = state.geofence_data.findIndex(
            geofence => geofence.id == payload.item.id
        );
        state.geofence_data[theIndex][payload.property] = payload.data;
    },

    fetchGeofence(state, payload) {
        if (state.currentIndex == -1) {
            state.geofence_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.geofence_data[state.currentIndex] = payload.data;
        }
    },

    deleteGeofence(state, payload) {
        state.currentIndex = state.geofence_data.findIndex(
            geofence => geofence.id == payload.data.id
        );
        state.geofence_data.splice(state.currentIndex, 1);
    },

    deleteLast(state) {
        state.geofence_data.splice(state.geofence_data.length - 1,1)
        if (state.geofence_data.length > 0) {
            state.currentIndex = 0
        }

    },

    reset(state) {
        state.geofence_data = []
        state.currentIndex = -1
        state.updateFlag=false // activated when updating polygon on point. i.e. moving or draging
        state.createFlag=false
        state.showDialog = false
        state.geofenceTableKey = 0
    },
    setUpdateFlag(state, payload) {
        state.updateFlag = payload
    },
    setCreateFlag(state, payload) {
        state.createFlag = payload
    },

    showDialog(state,payload) {
        state.showDialog = payload
    },

    incrementGeofenceTableKey() {
        state.geofenceTableKey+=1;
    }



};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllGeofences({commit}) {
        try {
            commit("loadAllGeofences", await Repository.get(`${GEOFENCE_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addGeofence({commit}) {
        await Repository.get(`${GEOFENCE_API}/new`)
            .then(response => {
                commit("addGeofence", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveGeofence({state, commit}) {
        try {
            commit(
                "saveGeofence",
                await Repository.post(
                    `${GEOFENCE_API}`,
                    state.geofence_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchGeofence({commit}, payloadID) {
        try {
            commit(
                "fetchGeofence",
                await Repository.get(`${GEOFENCE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteGeofence({commit}, payloadID) {
        try {
            commit(
                "deleteGeofence",
                await Repository.delete(`${GEOFENCE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchGeofences({commit}, searchTerm) {
        try {
            commit(
                "loadAllGeofences",
                await Repository.get(`${GEOFENCE_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
