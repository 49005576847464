<template lang="pug">
div
  v-timeline(:dense="dense")
    v-timeline-item(v-for="(item,index) in activityEvents" :key="index" color="primary" small right :icon="getIcon(item.action)")
      v-card()
        .d-flex.flex-row.align-end.grey.lighten-2.pa-2()
          div(v-if="item.vehicle_id" )
            image-thumbnail.mr-5.ml-0(v-if="getVehicle(item.vehicle_id) && getVehicle(item.vehicle_id).thumbnail" :width='80', :height='60', :src="getVehicle(item.vehicle_id).thumbnail_id")
          div(v-if="item.contact_id" )
            image-thumbnail.mr-5.ml-0(v-if="getContact(item.contact_id) && getContact(item.contact_id).thumbnail" :width='80', :height='60', :src="getContact(item.contact_id).thumbnail_id")
          v-spacer()
          .d-flex.flex-column.align-end()
            .title.primary--text(v-if="dense") {{formatDate(item.timestamp)}}
            .caption.grey--text.text--darken-2(v-if="item.vehicle_id && getVehicle(item.vehicle_id)" style="margin-bottom:-3px") {{getVehicle(item.vehicle_id).name}}
            .caption.grey--text.text--darken-2(v-if="item.contact_id && getContact(item.contact_id)" style="margin-bottom:-3px") {{getContact(item.contact_id).full_name}}
            .subtitle-1.font-weight-bold.my-0()  {{getTitle(item)}}
            .caption.grey--text.text--darken-1(v-if="item.action == 'trip_start' || item.action == 'trip_end'") {{getDistance(item)}} Km
        .caption.grey--text.text--darken-2.pa-2(v-html="getMessage(item)")
        //- div.my-2(v-if="item.contact_id" style="width=100;border-top:1px solid #999999")
        //- .d-flex.flex-wrap.pa-2(v-if="item.contact_id" )
          .contactRound2.grey.darken-2.white--text.pointer(v-if="getContact(item.contact_id) != null")  {{getContact(item.contact_id).first_name}} {{getContact(item.contact_id).last_name}}


      template(v-slot:opposite)
          div() {{formatDate(item.timestamp)}}



</template>

<script>
import moment from "moment";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail.vue";
import Repository from "@/repository";
export default {
    components: {ImageThumbnail},
    props:{
      url:{
          type:String,
          default: "/activityEvent"
      },
        dense:{
          type:Boolean,
            default: false,
        }
    },
    data: ()=> ({
       activityEvents: [],
        isLoading: true,
    }),
    async mounted(){

        await Repository.get(this.url)
            .then(response => {
                this.activityEvents = response.data;
                this.$emit("loadData")
            })
            .catch(error => {
                console.log(error);
            });
    },
    methods: {
        formatDate: function(theDate) {
           return moment(theDate).format("h:mm a")
        },

        getIcon: function(action){
            let result = null
            switch (action){
                case "alert": result = "notification"; break;
                case "message": result = "mail"; break;

            }
            return result;
        },
        getTitle: function(item){
            switch(item.action){
                case "trip_init": return "Started at Location";
                case "trip_start": return "Visited Locations";
                case "trip_end": return "Visited Locations";
                case "log_in": return "User Logged In";
                case "log_out": return "User Logged Out";
                case "message": return "Sent Message";
                case "alert": return "Alert";
                default: return item.action;
            }
        },

        getMessage: function(item){
            let result = ""
            switch(item.action){
                case "trip_init": result=this.renderTrips(item); break;
                case "trip_start": result=this.renderTrips(item); break;
                case "trip_end": result=this.renderTrips(item); break;
                case "message": result='<strong style="color: #000000">'+item.data.title + '</strong><br/><span style="font-size: 12px; color:#888888;">'+item.data.message+'</span>'; break;
                case "log_in" || "log_out":  break;
                case "alert": result=this.processAlert(item.data); break;
                default: return item.data;
            }



            return result;
        },

      getDistance: function(item) {
          return (item.data.distance/1000).toFixed(2)
      },

        renderTrips: function(item){
            let tripString = item.data.trips
            let result = "";
            let element = "";
            const tripArray = tripString.split(", ");
            for (let i=0; i<tripArray.length; i++){
                element = tripArray[i];
                if(tripString[0] === '%' && i==0 ){
                    result +=  element.substring(1)
                }else{
                    result +=   element;
                }
                if (i < tripArray.length) {
                    result += "<br/>"
                }
            }

            if(item.data.lag_time){
                result += `<hr style="border-top:1px solid #999999; border-bottom:none"/><span style="font-weight:bold; font-size:12px;color:#000000">${item.data.lag_time}</span>`
            }



          return result;
        },
        getContact(contact_id){
            let result =  this.$store.getters["contacts/getContactByID"](contact_id)
            if(typeof result == "undefined"){
                return null
            }else{
                return result;
            }
        },
        getVehicle(vehicle_id){
            let result = this.$store.getters["vehicles/getVehicleByID"](vehicle_id)
            if(typeof result == "undefined"){
                return null
            }else{
                return result;
            }
        },
        processAlert(data){
            if (data.over_speeding != null){
                return `<div style="display: flex; align-items: center"><img src="/static/web/vehicle_overspeed.png" width="50" height="50"><div style="padding-left: 15px"><strong > Max Speed: ${data.max_speed}</strong> <br/> ${data.street}</div></div>`
            }
            if(data.unplug != null){
                return `<div style="display: flex; align-items: center"><img src="/static/web/vehicle_tracker.png" width="50" height="50"><div style="padding-left: 15px"><strong > Tracker: ${data.unplug? 'Unplugged':'Plugged'}</strong><br/> ${data.street}</div></div>`
            }
            if(data.green_driving_type != null){
                switch(data.green_driving_type){
                    case 1: return `<div style="display: flex; align-items: center"><img src="/static/web/vehicle_accelerate.png" width="50" height="50"><div style="padding-left: 15px"><strong > Hard Acceleration: ${(data.green_driving_value/100).toFixed(2)}g</strong> <br/> ${data.street}</div></div>`;
                    case 2: return `<div style="display: flex; align-items: center"><img src="/static/web/vehicle_brake.png" width="50" height="50"><div style="padding-left: 15px"><strong > Hard Braking: ${(data.green_driving_value/100).toFixed(2)}g</strong> <br/> ${data.street}</div></div>`;
                    case 3: return `<div style="display: flex; align-items: center"><img src="/static/web/vehicle_conering.png" width="50" height="50"><div style="padding-left: 15px"><strong > Hard Cornering: ${(data.green_driving_value/100).toFixed(2)}g</strong> <br/> ${data.street}</div></div>`;
                }
            }
            if(data.crash != null){
                return `<div style="display: flex; align-items: center"><img src="/static/web/vehicle_crash.png" width="50" height="50"><div style="padding-left: 15px"><strong > Crash Detected</strong> <br/> ${data.street}</div></div>`
            }
            if(data.towing != null){
                return `<div style="display: flex; align-items: center"><img src="/static/web/vehicle_towing.png" width="50" height="50"><div style="padding-left: 15px"><strong > Towing Detected</strong> <br/> ${data.street}</div></div>`
            }
            if(data.idling != null){
                return `<div style="display: flex; align-items: center"><img src="/static/web/vehicle_idling.png" width="50" height="50"><div style="padding-left: 15px"><strong > Idling Detected</strong> <br/> ${data.street}</div></div>`
            }
            return data;
        }
    },

}
</script>

<style scoped lang="stylus" >
.contactRound2{
  padding 2px 6px;
  margin 2px 2px;
  border-radius 3px;
  font-size 13px;
}
  .v-timeline-item__divider {
    min-width 40px !important
  }



</style>