<template lang="pug">
  .d-flex.align-center()
    input.baseStyle(v-model.number="result" type='number' :style="getStyle" :disabled="isDisabled")
    template(v-if="isDisabled")
      .inputCancelBlank
    template(v-else)
      v-icon.serviceInputCancel(@click="onCancel" tabIndex="-1" placeholder="NoTabIndex" :size="18") cancel
</template>
<script>
export default {
  props: ["value", "isDisabled"],
  data: () => ({
    baseStyle: {
      "border-width": "1px",
      "border-style": "solid",
      "border-color": "#999999",
      "border-radius": "5px",
      height: "34px",
      width: "100%",
      "text-align": "right",
      padding: "6px 4px 6px 12px",
      "background-color": "none",
      outline: "none"
    }
  }),
  computed: {
    result: {
      get: function () {
        if (this.value == 0 && this.isDisabled) {
          return null;
        } else {
          return this.value;
        }

      },
      set: function (value) {
        console.log(typeof value != "number")
        if (typeof value == "number") {
          this.$emit("input", parseFloat(value));
        }

      }
    },

    getStyle: function () {
      this.setDisabledStyle();
      return this.baseStyle;
    }
  },
  methods: {
    onCancel: function () {
        this.$emit("input", 0);
    },
    setDisabledStyle: function () {
      if (this.isDisabled) {
        this.baseStyle["border-color"] = "#CCCCCC";
        this.baseStyle["background-color"] = "#DEDEDE";
        this.baseStyle["color"] = "#777777";
      } else {
        this.baseStyle["border-color"] = "#999999";
        this.baseStyle["background-color"] = "#FFFFFF";
        this.baseStyle["color"] = "#000000";
      }
    }
  }
};
</script>
<style lang="stylus" scoped>

.baseStyle:focus {
  box-shadow: 0 0 5px #E91E63;
}

.inputCancelBlank {
  width 24px;
  height 24px;

}

.serviceInputCancel {
  padding-left: 2px;
  opacity: 0.5;
}

.serviceInputCancel:hover {
  opacity: 1.0;
}
</style>
