<template lang="pug">
  div
    v-autocomplete(
      v-model="customerID"
      :items="$store.state.customer.customer_data"
      :filter="customFilter"
      :search-input.sync="search"
      item-value="id"
      label="Select Customer"
      placeholder="Start typing to Search"
      chips
      @change="onChange",
      :disabled="disabled"
    )
      template(v-slot:selection="data")
        v-chip.chip--select-multi(:input-value="data.selected" small )
          template(v-if="data.item.reference_id != null")
            div() {{data.item.reference_id}} - {{data.item.name}}
          template(v-else)
            div() {{data.item.name}}

      template( v-slot:item="data")
        template(v-if="data.item.reference_id != null")
          v-list-item-content() {{data.item.reference_id}} - {{data.item.name}}
        template(v-else)
          v-list-item-content() {{data.item.name}}
</template>
<script>
export default {
  props:{
    value:String,
    disabled:{
      type:Boolean,
      default:false,
    }
  },
  data: () => ({
    isLoading: false,
    customerID: null,
    search: null
  }),

  methods: {
    onChange: function(theValue) {
      this.$emit("input", theValue);
    },
    customFilter: function(item,queryText){
      if(item.reference_id != null){
        return item.reference_id.toLowerCase().indexOf(queryText.toLowerCase()) > -1 ||  item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1
      }else{
        return item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1
      }


    }
  },

  mounted() {
    this.customerID = this.value;
    if (this.$store.state.customer.currentIndex === -1) {
      this.isLoading = true;
      this.$store.dispatch("customer/loadAllCustomers").then(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>
<style scoped lang="stylus"></style>
