import {getField, updateField} from "vuex-map-fields";
import serviceType from "@/modules/services/store/serviceType";
import serviceDetail from "@/modules/services/store/serviceDetail";
import Repository from "@/repository";

const SERVICE_API = "/service";

// ----------------------- STATE ------------------------- //
const state = {
    service_data: [],
    currentIndex: -1,

    subLabourInputDisabled: false,
    subPartsInputDisabled: false,
    totalInputDisabled: false,
    subTotalInputsDisabled: false,
    mode: "edit",
    metrics: null,
    selectedServices: [],

};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getService: state => getField(state.service_data[state.currentIndex]),

    getCurrentService: state => {
        return state.service_data[state.currentIndex];
    },

    getCurrentserviceDetailItem: state => theID => {
        let tempService = state.service_data[state.currentIndex];

        let tempIndex = tempService.service_details.findIndex(
            item => item.id == theID
        );
        return tempService.service_details[tempIndex];
    },

    getMetrics: state => {
        return state.metrics;
    },

    // get serviceTasks.. called by the AutoComplete
    getServiceTypesIDs: state => {
        let serviceTypes = [];

        if (state.currentIndex > -1) {
            state.service_data[state.currentIndex].service_details.forEach(
                serviceDetail => {
                    serviceTypes.push(serviceDetail.service_type_id); //serviceType.ID
                }
            );
        }

        return serviceTypes;
    },

    //seletedServices
    getSelectedServices: state => {
        return state.selectedServices;
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    setMode(state, payload) {
        state.mode = payload;
    },

    newService(state, payload) {
        console.log(payload);
        state.service_data.push(payload);
        state.currentIndex = state.service_data.length - 1;
        state.mode = "create";
    },

    setSelectedServices(state,payload) {
      state.selectedServices = payload;
    },

    addServiceDetail(state, payload) {
        state.service_data[state.currentIndex].service_details.push(payload);
    },

    removeServiceDetail(state, tmpDeleteServiceDetail) {
        // remove element from service_details
        var theIndex = state.service_data[
            state.currentIndex
            ].service_details.findIndex(tmp => tmp.id === tmpDeleteServiceDetail.id);
        state.service_data[state.currentIndex].service_details.splice(theIndex, 1);
    },

    removeServiceDetailByServiceTypeID(state, itemID) {
        var theIndex = state.service_data[
            state.currentIndex
            ].service_details.findIndex(tmp => tmp.service_type_id === itemID);
        state.service_data[state.currentIndex].service_details.splice(theIndex, 1);
    },

    // calculates the subtotals based on the service Types inputs
    calculateSubTotals(state) {
        let tempService = state.service_data[state.currentIndex];
        console.log(`TempService: ${tempService}`)
        let hasLabourItem = false;
        let hasPartItem = false;
        let hasTotalItem = false;

        let l_total = 0;
        let p_total = 0;
        let t_total = 0;
        //
        // if (!tempService) {
        //   return;
        // }
        //
        tempService.service_details.forEach(item => {
            item.labour = parseFloat(item.labour);
            item.parts = parseFloat(item.parts);
            item.total = parseFloat(item.total);

            if (item.labour > 0) {
                hasLabourItem = true;
            }
            if (item.parts > 0) {
                hasPartItem = true;
            }

            if (item.total > 0 || item.parts > 0 || item.labour > 0) {
                hasTotalItem = true;
            }


            let l = item.labour;
            let p = item.parts;
            let t = item.total;

            if (l != 0 || p != 0 || t != 0) {
                if (l != 0 || p != 0) {
                    item.labour_disabled = false;
                    item.parts_disabled = false;
                    item.total_disabled = true;

                    item.total = parseFloat(l) + parseFloat(p);
                } else {
                    item.labour_disabled = true;
                    item.parts_disabled = true;
                    item.total_disabled = false;
                }
            } else {
                item.labour_disabled = false;
                item.parts_disabled = false;
                item.total_disabled = false;
            }

            // add the totals
            l_total += l;
            p_total += p;
            t_total += parseFloat(item.total || 0);
        });

        console.log(`hasLabourItem: ${hasLabourItem}  === hasPartItem: ${hasPartItem}  ==== hasTotal: ${hasTotalItem}`)
        //
        //disable labour fields when subtotalLabour has input
        if (hasLabourItem == true) {
            state.subLabourInputDisabled = true;
            tempService.sub_labour = l_total;
        } else {
            state.subLabourInputDisabled = false;
            // diable all labours
            if (tempService.sub_labour > 0) {
                tempService.service_details.forEach(item => {
                    item.labour_disabled = true;
                    item.labour = 0;
                });
            }
        }

        //diable items fields when subtotalParts has input
        if (hasPartItem == true) {
            state.subPartsInputDisabled = true;
            tempService.sub_parts = p_total;
        } else {
            state.subPartsInputDisabled = false;

            // diable all items
            if (tempService.sub_parts > 0) {
                tempService.service_details.forEach(item => {
                    item.parts_disabled = true;
                    item.parts = 0;
                });
            }
        }

        if (hasTotalItem == true) {
            state.subTotalInputsDisabled = true;
            tempService.sub_total = 0;
        } else {
            state.subTotalInputsDisabled = false;
            if (tempService.sub_total > 0) {
                tempService.service_details.forEach(item => {
                    item.parts_disabled = true;
                    item.parts = 0;
                    item.labour_disabled = true;
                    item.labour = 0;
                    item.total_disabled = true;
                    item.total = 0;
                });
            }

        }


        //
        // // Subtotal calculations
        // console.log(t_total);
        // tempService.sub_total = t_total;
        //
        // // calculating Discount
        // let tempHolderAfterDiscount;
        // if (tempService.discount_type === "fixed") {
        //   tempHolderAfterDiscount =
        //     parseFloat(tempService.sub_total) - parseFloat(tempService.discount);
        // } else {
        //   if (tempService.discount_type === "percentage") {
        //     tempHolderAfterDiscount =
        //       parseFloat(tempService.sub_total) *
        //       (1 - parseFloat(tempService.discount) / 100);
        //   }
        // }
        //
        // // calculate Tax
        // let tempHolderAfterTax;
        // if (tempService.tax_type === "fixed") {
        //   tempHolderAfterTax =
        //     tempHolderAfterDiscount + parseFloat(tempService.tax);
        // } else {
        //   if (tempService.tax_type === "percentage") {
        //     tempHolderAfterTax =
        //       tempHolderAfterDiscount +
        //       tempHolderAfterDiscount * (parseFloat(tempService.tax) / 100);
        //   }
        // }
        //

        if (hasTotalItem == false) {
            let gt = parseFloat(tempService.sub_total || 0) + parseFloat(tempService.sub_parts || 0) + parseFloat(tempService.sub_labour || 0);
            if (gt > 0) {
                state.totalInputDisabled = true;
                tempService.grand_total = gt
            } else {
                state.totalInputDisabled = false;
                if (tempService.grand_total > 0) {
                    tempService.service_details.forEach(item => {
                        item.labour = 0;
                        item.parts = 0;
                        item.total = 0;
                        item.parts_disabled = true;
                        item.labour_disabled = true;
                        item.total_disabled = true;
                    });
                    tempService.sub_total = 0;
                    tempService.sub_labour = 0;
                    tempService.sub_parts = 0;
                    state.subTotalInputsDisabled = true;
                    state.subLabourInputDisabled = true;
                    state.subPartsInputDisabled = true;
                }

            }

        } else {
            state.totalInputDisabled = true;
            tempService.grand_total = t_total;
            if (!hasLabourItem) {
                tempService.grand_total += tempService.sub_labour
            }
            if (!hasPartItem) {
                tempService.grand_total += tempService.sub_parts
            }

        }


        // // Total
        // tempService.total = tempHolderAfterTax;
        //
        // console.log("calculateSubtotals.....");
    },

    clearAll(state) {
        var tempService = state.service_data[state.currentIndex];
        tempService.service_details.forEach(item => {
            item.labour = 0;
            item.parts = 0;
            item.total = 0;
            item.parts_disabled = false;
            item.labour_disabled = false;
            item.total_disabled = false;
        });

        tempService.sub_total = 0;
        tempService.sub_labour = 0;
        tempService.sub_parts = 0;
        tempService.grand_total = 0;

        state.subTotalInputsDisabled = false;
        state.subLabourInputDisabled = false;
        state.subPartsInputDisabled = false;
        state.totalInputDisabled = false;

    },

    updateService(state, payload) {
        console.log(payload);
        updateField(state.service_data[state.currentIndex], payload);
    },

    deleteImage(state, payload) {
        let index = state.service_data[state.currentIndex].photos_id.findIndex(
            item => item === payload
        );
        state.service_data[state.currentIndex].photos_id.splice(index, 1);
    },

    deleteDocument(state, payload) {
        let index = state.service_data[state.currentIndex].documents_id.findIndex(
            item => item === payload
        );
        state.service_data[state.currentIndex].documents_id.splice(index, 1);
    },

    fieldUpdate(state, payload) {
        updateField(state.service_data[state.currentIndex], payload);
    },

    // update the lineItem of the data by getting the index and manipulating it
    fieldUpdateIndex(state, payload) {
        let tempService = state.service_data[state.currentIndex];
        console.log(payload);
        let tempIndex = tempService.service_details.findIndex(
            item => item.id == payload.id
        );
        updateField(tempService.service_details[tempIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.service_data.findIndex(
            service => service.id == theID
        );
    },

    loadMetrics(state,payload) {
        state.metrics = payload.data;
    },

    loadAllServices(state, payload) {
        state.service_data = payload.data;
        if (state.service_data.length > 0) {
            state.currentIndex = 0;
        }
    },

    addService(state, payload) {
        state.service_data.push(payload.data);
        state.currentIndex = state.service_data.length - 1;
    },

    saveService(state, payload) {
        console.log(payload);
        //state.service_data[state.currentIndex] = payload.data
    },

    fetchService(state, payload) {
        if (state.currentIndex == -1) {
            state.service_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.service_data[state.currentIndex] = payload.data;
        }
    },

    deleteService(state, payload) {
        state.currentIndex = state.service_data.findIndex(
            service => service.id == payload.data.id
        );
        state.service_data.splice(state.currentIndex, 1);
    },

    deleteSelectedServices(state,payload){
        payload.data.forEach(element => {
            let theIndex = state.service_data.findIndex(
                (service) => service.id == element
            );
            state.service_data.splice(theIndex, 1);

        })
        state.selectedServices = [];
    },

    reset(state) {
        state.service_data = []
        state.currentIndex = -1
        state.subLabourInputDisabled = false
        state.subPartsInputDisabled = false
        state.totalInputDisabled = false
        state.subTotalInputsDisabled = false
        state.mode = "edit"
        state.metrics = null
        state.selectedServices =[]
    },
    next(state){
        if(state.currentIndex == state.service_data.length-1){
            state.currentIndex = 0;
        }else{
            state.currentIndex += 1;
        }
    },

    previous(state){
        if(state.currentIndex == 0){
            state.currentIndex = state.service_data.length-1;
        }else{
            state.currentIndex -= 1;
        }
    },
};

// ----------------------- ACTIONS -------------------- //
const actions = {


    async loadAllServices({commit}) {
        try {
            commit("loadAllServices", await Repository.get(`${SERVICE_API}`));
        } catch (error) {
            console.log(error);
        }
        try {
            commit("loadMetrics", await Repository.get(`${SERVICE_API}/metrics`));
        } catch (error) {
            console.log(error);
        }
    },

    async addService({commit}) {
        await Repository.get(`${SERVICE_API}/new`)
            .then(response => {
                commit("addService", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveService({state, commit}) {
        state.service_data[state.currentIndex].service_details.forEach(
            item => {
                console.log(`labour: ${item.labour} parts: ${item.parts} total: ${item.total}`)
            }
        );
        console.log("++++++++++++++++++++++++++")
        console.log(state.service_data[state.currentIndex])
        console.log("++++++++++++++++++++++++++")
        commit(
            "saveService",
            await Repository.post(
                `${SERVICE_API}`,
                state.service_data[state.currentIndex]
            )
        );
    },

    async fetchService({commit}, payloadID) {
        await Repository.get(`${SERVICE_API}/${payloadID}`)
            .then(response => {
                commit("fetchService", response);
                commit("calculateSubTotals");
            })
            .catch(error => {
                console.log(error);
            });
    },

    async deleteService({commit}, payloadID) {
        try {
            commit(
                "deleteService",
                await Repository.delete(`${SERVICE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchServices({commit}, searchTerm) {
        try {
            commit(
                "loadAllServices",
                await Repository.get(`${SERVICE_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteSelectedServices({ state,commit }) {
        try {
            let idString = ''
            state.selectedServices.forEach(element => {
                idString += "&id="+element.id
            })
            commit(
                "deleteSelectedServices",
                await Repository.delete(`${SERVICE_API}/deleteSelected?${idString}`)

            );
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
        serviceType,
        serviceDetail
    }
};
