<template lang="pug">
div( )
  v-divider
  v-container.pa-0(fluid)
    v-layout.pa-4(row wrap)
      h2.mx-4 Archive Repository
      v-spacer
      v-btn.ma-2(small outlined color="primary" :disabled="!$store.state.repository.repository_data.length > 0" @click="onRefresh")
        v-icon(small) sync
      .text-xs-center
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color='primary', dark, v-on="on", )
              | Actions
              v-icon(dark) keyboard_arrow_down
          v-list(small)
            v-list-item-group(color="primary")
              v-list-item(v-for='item in items', :key='item.title' @click='onAction(item.value)')
                v-list-item-title() {{ item.title }}
      v-btn.ma-2(small, color='primary', dark, @click="addNewDialog") Add Repository Item

      input(ref="selectFile" type="file" id="myfile" name="myfile" style="display:none")
  v-divider
  v-layout.ma-4(row align-center)
    v-row.fill-width()
      .d-flex.mx-4.fill-width()
        .d-flex.align-center.fill-width()
          v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" style="max-width:300px" clearable)
          v-select.ml-6(:items='fileTypes', item-text='title', item-value='title',  prepend-icon='group', v-model='fileFilterType', label='File Type Filter' clearable style="max-width:350px")
          .ml-4.grey--text(v-if="$store.state.repository.selectedRepositories.length > 0" ) +{{ $store.state.repository.selectedRepositories.length }} repositories selected
  v-divider

  repository-table(:search="search" :fileType="fileFilterType" @openDialog="onOpenDialog" @editDialog="onEditDialog")
  v-dialog(v-if="dialog" v-model="dialog" width="800" persistent )
    v-card
      v-card-title()
        | {{$store.state.repository.currentIndex== -1 ? 'Add' : 'Edit'}} Repository
        v-spacer()
        v-btn(outlined small color="grey" @click="dialog=false")
          | close
          v-icon.ml-2(small ) close
      v-divider()
      v-progress-linear(:indeterminate="dialogLoading")

      .d-flex.my-4.justify-center(v-if="$store.state.repository.currentIndex== -1")
        v-btn.mx-3( color="primary" @click="clickedSingleFile"  )
          v-icon.pr-2(:size="16" ) attach_file
          | Attach File
        //v-btn.mx-3(outlined color="primary" small )
        //  v-icon.pr-2(:size="16" ) folder
        //  | Folder

      v-divider(v-if="$store.state.repository.currentIndex== -1" )
      v-layout.ma-4(row align-center)
        v-row()
          v-col(cols="6" xs="12" md="6" xl="6")
              v-text-field.mr-3(v-model='name', label='Name', persistent-hint clearable )
              v-textarea(v-model="description" rows="4"  label="Description" persistent-hint clearable )
              .d-flex.align-center.grey.lighten-3.pb-1.mb-3.px-2.rounded-xl()
                v-text-field.mr-3.mb-4(v-model='link', prepend-icon="add_link" hint="Paste link e.g. https://youtu.be/0s_1edp-St4" label='Link', persistent-hint clearable )
                v-btn(fab small outlined color="grey")
                  v-icon() content_paste
              v-menu(ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition"  offset-y min-width="auto")
                template(#activator="{on, attrs}")
                  v-text-field(v-model='publishDate', label='Publish Date' prepend-icon="calendar_month"  readonly  v-bind="attrs"  v-on="on" )
                v-date-picker(v-model="date" no-title scrollable  @input='$refs.menu.save(date)')
          v-col(cols="6" xs="12" md="6" xl="6")
            .div.grey.fill-height.rounded-xl()
              .thumbnailAttach2(v-if="thumbnail" :style="{ 'background-image': 'url(' + '/media/' + $store.state.profile.userInfo.account_id +'/'+ thumbnail + ')'}")
      v-divider()
      v-card-actions()
        .d-flex.justify-space-between.align-center.fill-width
          .primary--text.caption() {{status}}
          .div()
            v-btn.mx-2(text color="grey" @click='dialog=false') Cancel
            v-btn(color="primary" @click='onSave') {{$store.state.repository.currentIndex== -1 ? 'Save':'Update'}}
</template>
<script>
import RepositoryTable from "@/modules/repository/components/tables/RepositoryTable";
import Repository from "@/repository";
import moment from "moment"
export default {
  components:{
    RepositoryTable
  },


 data: () => ({
   thumbnail: null,
   date: moment().format("YYYY-MM-DD"),
   menu: false,
   status: null,
   name:null,
   link: null,
   description: null,
   details: null,
   step:1,
   dialogLoading:false,
   dialog:false,
   search: "",
   fileFilterType:null,
   fileTypes: [
     {
       title: "Documents",
       value: ["text/text","text/html"]
     },
     {
       title: "Images",
       value: ["img/jpeg","img/gif","img/png"]
     }
   ],
   items: [
     // {
     //   title: "Export Excel",
     //   value: "export_excel"
     // },
     // {
     //   title: "Export CSV",
     //   value: "export_csv"
     // },
     {
       title:"Delete Selected",
       value: "delete_selected"
     }
   ],
   searchFilter: "",
   uploadFiles: null,

 }),

  mounted() {




    this.$refs.selectFile.addEventListener("change",this.onSelectedSingleFile);


  },

  computed: {
   currentRepository() {
      return this.$store.getters["repository/getCurrentRepository"]
    },

   publishDate(){
      return moment(this.date, "YYYY-MM-DD").format("Do MMMM YYYY")
    },
  },
  methods: {
    onOpenDialog: function(){
      this.name = this.currentRepository.name;
      this.description = this.currentRepository.description;
      this.link = this.currentRepository.link;
      this.published_at = this.currentRepository.published_at;
      this.$refs.selectFile.value = null;
      this.uploadFiles = null;
      console.log(this.$refs.selectFile.files)
      this.status = null;
      if(this.currentRepository.blob && this.currentRepository.blob.thumbnail){
        this.thumbnail = this.currentRepository.blob.thumbnail
      }


      this.dialog = true;
    },


    onSelectedSingleFile: function(e){
      let name =  e.target.files[0].name;
      this.name = name.replace(/\.[^/.]+$/, "")
      this.status = e.target.files[0].name

    },
    onRefresh: function() {
      this.$store.dispatch("repository/loadAllRepositories").then(()=>{
        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Refreshed Repository Files`
        });
      });
    },


    onSave: function() {
      console.log("this is save dialog")

      if(this.$store.state.repository.currentIndex== -1){
        if (this.$refs.selectFile.files.length == 0 && this.link == null){
          this.status = "Select File or Paste Link";
        }else{
          this.dialogLoading = true;
          this.uploadFiles = this.$refs.selectFile.files[0]
          const formData = new FormData();
          formData.append('file',this.uploadFiles)
          formData.append('name',this.name)
          if(this.$refs.selectFile.files.length > 0){
            formData.append('mime_type', this.$refs.selectFile.files[0].type)
          }
          if (this.description != null){
            formData.append('description',this.description)
          }
          if(this.link != null){
            formData.append('link',this.link)
          }

          formData.append('published_at',this.date)
          const headers = { 'Content-Type': 'multipart/form-data' };
          Repository.post('/repository/uploadFile', formData, { headers }).then((res) => {
            console.log(res)
            this.$store.commit("repository/uploadRepository",res);
            this.dialogLoading = false;
            if(res.data.blob && res.data.blob.thumbnail){
              this.thumbnail = res.data.blob.thumbnail;
            }
            this.dialog=false;
          });
        }
      }else{
        this.dialogLoading = true;
        this.$store.commit("repository/updateCurrentRepository",{name:this.name,description:this.description,link:this.link,published_at:moment(this.date, "YYYY-MM-DD").toISOString()})
        this.$store.dispatch("repository/saveRepository").then(()=>{
          this.dialogLoading = false;
          this.dialog=false;
          this.$store.commit("showSnackBar", {
            color: "accent",
            message: `Saved - ${this.name}`
          });
        })
      }

    },

    addNewDialog: function() {
      this.name = null
      this.description = null
      this.thumbnail = null
      this.published_at = null
      this.link = null
      this.$store.commit("repository/setCurrentIndex", -1);

      this.$refs.selectFile.value = null;
      this.uploadFiles = null;
      this.status = null;
      console.log(this.$refs.selectFile.files)

      this.dialog = !this.dialog;

    },

    onAction: function(value){


      switch (value) {
        case "export_excel": break;
        case "export_csv": break;
        case "delete_selected":
          var deletedIds = [];
          this.$store.getters["repository/getSelectedRepositories"].forEach(element => {
            deletedIds.push(element.id);
          });
          confirm(`Are you sure you want to delete this ${deletedIds.length} item?`) &&
          this.$store.dispatch("repository/deleteSelectedRepositories", deletedIds);
          break;
      }
    },

    clickedSingleFile:  function() {
      this.$refs.selectFile.click();
    },

    uploadFile:function(){

    },


    onEditDialog: function() {

      this.date = moment(this.currentRepository.published_at).format("YYYY-MM-DD");
      this.onOpenDialog();
      // console.log(this.$refs.selectFile.files[0])
      // this.$store.dispatch("repository/addRepository").then(()=>{
      //   this.$router.push({
      //     name: "repositoryDetailForm",
      //     params: {reportID: this.$store.getters["repository/getCurrentRepository"].id}
      //   })
      // })
    }
  }
}
</script>
<style scoped lang="stylus">
.thumbnailAttach2{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  border: 1px solid #6a6a6a;
}
</style>