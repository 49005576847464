<template lang="pug">
  .OverviewSideBar
    v-btn.ma-3(small color="primary" style="width:90%")
      v-icon.mr-2(small) add
      | Quick Add
    vehicle-overview-side-bar-item.menuSpacing(v-for="item in overviewItems1" :key="item.url"   :label="item.label" :icon="item.icon" :value="item.value" :url="item.url" @onclick="onAction")
    v-divider.mb-3.mt-3
    vehicle-overview-side-bar-item.menuSpacing(v-for="item in overviewItems2" :key="item.url"   :label="item.label" :icon="item.icon" :value="item.value" :url="item.url" @onclick="onAction")
</template>
<script>
import VehicleOverviewSideBarItem from "@/modules/global/components/others/OverviewSideBarItem";
import moment from "moment/moment";

export default {
  components: {
    VehicleOverviewSideBarItem
  },
  data: () => ({
    inspections: null,
    issues: null,
    faults: null,
    reminders: null,
    service: null,
    fuel: null,
    assignments: null,
    odometer: null,
    location: null,
    cameraCapture:false,
  }),
  computed: {
    currentVehicle: {
      get: function () {
        return this.$store.getters["vehicles/getCurrentItem"];
      }
    },
    overviewItems1: function () {
      let obj = [];
      obj.push({
        label: "Vehicle Overview",
        icon: "dashboard",
        url: "vehicleDashboard"
      });
      if (this.cameraCapture){
        obj.push({
          label: "AutoEye Camera",
          icon: "photo_camera",
          value: this.$store.state.cameraCaptures.camera_data.length,
          url: "autoEye"
        });
      }
      obj.push({
        label: "Photos",
        icon: "image",
        value: this.currentVehicle.images
            ? this.currentVehicle.images.length
            : 0,
        url: "vehiclePhotos"
      });
      obj.push({
        label: "Documents",
        icon: "insert_drive_file",
        value: this.currentVehicle.documents
            ? this.currentVehicle.documents.length
            : 0,
        url: "vehicleDocuments"
      });
      obj.push({
        label: "Comments",
        icon: "comment",
        value: this.$store.state.comments.comment_data.length,
        url: "vehicleComments"
      });

      return obj;
    },

    overviewItems2: function () {
      let obj = [];
      obj.push({
        label: "Inspections",
        icon: "check_box",
        value: this.inspections,
        url: "vehicleInspections"
      });

      obj.push({
        label: "Issues",
        icon: "warning",
        value: this.issues,
        url: "vehicleIssues"
      });
      obj.push({
        label: "Faults",
        icon: "error",
        value: this.faults,
        url: "vehicleFaults"
      });
      obj.push({
        label: "Reminders",
        icon: "notifications",
        value: this.reminders,
        url: "vehicleReminders"
      });
      obj.push({
        label: "Service History",
        icon: "build",
        value: this.service,
        url: "vehicleServiceHistory"
      });
      obj.push({
        label: "Fuel Log",
        icon: "local_gas_station",
        value: this.fuel,
        url: "vehicleFuelLog"
      });
      obj.push({
        label: "Assignment History",
        icon: "history",
        value: this.assignments,
        url: "vehicleAssignmentHistory"
      });
      obj.push({
        label: "Odometer History",
        icon: "replay_10",
        value: this.odometer,
        url: "vehicleOdometerHistory"
      });
      obj.push({
        label: "Location History",
        icon: "location_on",
        value: this.location,
        url: "vehicleLocationHistory"
      });

      return obj;
    }
  },
  methods: {
    onAction: function (url) {
      this.$router.push({
        name: url,
        params: {vehicleID: this.$route.params.vehicleID}
      }).catch(()=>{});
    }
  },

  mounted() {
    let from = moment().subtract(14, 'days').toDate().toISOString();
    this.$store.dispatch("cameraCaptures/fetchCameraByVehicleID",{vehicle_id:this.$route.params.vehicleID, from:from }).then(()=>{
      if (this.$store.state.cameraCaptures.camera_data.length > 0 ){
        this.cameraCapture = true;
      }
    })
  }
};
</script>
<style lang="stylus" scoped>
.OverviewSideBar {
  width: 250px;
  height: 100%;
  background-color: #F5F5F5;
}

.menuSpacing {
  margin-bottom: 8px;
}
</style>
