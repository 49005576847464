<template lang="pug">
  .ma-2()
    v-tabs.tabs(slider-color='primary'  background-color="grey lighten-3" :height="35")
      v-tab.tabs
        | Customer Details
      v-tab-item.px-6.py-6
          v-layout(row wrap)
            v-flex.px-3(xs12 md6 xl6)
              v-card.my-3(color="white")
                .PairItems
                  number-guage(:value="visitationCounter" label="Location Visits" unitLabel="")
                  text-guage(v-if="lastVisit" :value="formatTheDate(lastVisit)" label="Last Location Visit" unitLabel="" :fontSize="18")
              v-card.my-3(color="white")
                .PairItems
                  number-guage(:value="inventoryCounter" label="Inventory Records" unitLabel="")
                  text-guage(v-if="lastRecord" :value="formatTheDate(lastRecord)" label="Last Inventory Record" unitLabel=""  :fontSize="18")
              v-card.my-3(colors="white")
                panel-title-header(:label="basicInfo.title" btnVisible btnLabel="Edit" :routeObj="{name:'customerDetailsForm',params:{customerID:$route.params.customerID}}")
                div.ma-2.pb-2
                  table.DetailTable
                    tr(v-for="(item,index) in basicInfo.items" :key="index")
                      td.goRight.grey--text.text--darken-1
                        .mr-3 {{item.label}}
                      td {{item.value}}
                    tr(v-if="getCustomerCustomFields.length > 0" v-for="(element,index) in getCustomerCustomFields" :key="index")
                      td.goRight.grey--text.text--darken-1
                        .mr-3 {{element.label}}
                      td {{customer.details[element.label_id]}}

            v-flex.px-3(xs12 md6 xl6)
              v-card.my-3(colors="white")
                panel-title-header(:label="placeLabel")
                template(v-if="location")
                  place-map(v-model="location" style="height:510px" )
                template(v-else)
                  .d-flex.grey.lighten-3.justify-center.align-center(style="height:510px")
                    div()
                      v-icon.pr-3() place
                      | No Location
</template>
<script>

import NumberGuage from "@/modules/global/components/dashboard/NumberGuage";
import TextGuage from "@/modules/global/components/dashboard/TextGuage.vue";
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";
import PlaceMap from "@/modules/places/components/ui/PlaceMap";
import Repository from "@/repository";
import moment from "moment";
export default {
  components: {
    PlaceMap,
    PanelTitleHeader,
    NumberGuage,
    TextGuage
  },
  data: () => ({
    location: null,
    placeLabel: "Place",
  }),
  computed: {
    getCustomerCustomFields(){
      return this.$store.getters["profile/getCustomFields"]("customer");
    },
    visitationCounter: function(){
      return this.$store.getters["customer/getVisitationsCount"]
    },
    inventoryCounter:function(){
      return this.$store.getters["customer/getInventoryCount"]
    },

    lastVisit: function(){
      if (this.visitationCounter > 0){
        return this.$store.state.customer.visitations[0].min_time;
      }else{
        return null
      }
    },

    lastRecord: function() {
      if(this.inventoryCounter > 0){
        return this.$store.state.customer.inventories[0].start_at;
      }else{
        return null;
      }
    },

    customer: function() {
      return this.$store.getters["customer/getCurrentCustomer"]
    },
    basicInfo: function () {
      return {
        title: "Basic Information",
        items: [
          {label: "Reference ID", value: this.customer.reference_id},
          {label: "Organization Name", value: this.customer.name},
          {label: "Description", value: this.customer.description},
          {label: "Group", value: this.customer.group != null ? this.customer.group.name : ''},
          {label: "Email", value: this.customer.email},
          {label: "Phone", value: this.customer.phone},
          {label: "Contact First Name", value: this.customer.first_name},
          {label: "Contact Last Name", value: this.customer.last_name},
          {label: "Street Address", value: this.customer.street_address},
          {label: "Area", value: this.customer.area},
          {label: "Postal Address", value: `${this.customer.address??''}, ${this.customer.postal_code??''}, ${this.customer.city??''}, ${this.customer.country??''}`},
          {label: "Website", value: this.customer.website},

        ]
      };
    },
  },

  methods:{
    getLocation:function(){
      if(this.customer.places_id == null || this.customer.places_id.length == 0){
        return;
      }
      Repository.get(`/place/${this.customer.places_id[0]}`).then((response)=>{
        this.location = response.data.location;
        this.placeLabel = `${response.data.name} | ${response.data.street}`
      });
    },

    formatTheDate:function(theDate){
      return moment(theDate).format("h:mm a — ddd, Do MMM YYYY")
    },
  },





  mounted() {
    this.getLocation();
  }
};
</script>
<style scoped lang="stylus">
.PairItems {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}
.DetailTable {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
}

.DetailTable {
  tr {
    margin: 0;
    padding: 0;
  }

  td {
    border-bottom: 1px solid #EEEEEE;
    margin: 0;
    padding: 0;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 8px;
  }
}

.goRight {
  text-align: right;
  width: 40%;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.hFlex {
  display: flex;
}

.handCursor {
  cursor: pointer;
}
</style>
