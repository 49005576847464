<template>
  <div class="theLabel" :class="getLevelColor()">
    <slot>High Priority</slot>
  </div>
</template>

<script>
export default {
  props: {
    level: {
      type: Number,
      default: 0
    }
  },

  methods: {
    getLevelColor: function() {
      let theColor = "Primary";
      switch (this.level) {
        case 5:
          theColor = "red darken-4";
          break;
        case 4:
          theColor = "orange darken-4";
          break;
        case 3:
          theColor = "amber darken-2";
          break;
        case 2:
          theColor = "lime darken-3";
          break;
        case 1:
          theColor = "light-green darken-3";
          break;
        default:
          theColor = "grey darken-3";
      }

      return theColor;
    }
  }
};
</script>

<style scoped>
.theLabel {
  font-size: 11px;
  padding: 2px 10px;
  height: 20px;
  margin: 5px 10px 5px 0px;
  border-radius: 5px;
  color: white;
}
</style>
