<template lang="pug">
  v-layout.ma-2(fill-height)
    v-flex(xs12)
      attach-fill-form( :schema="imagesSchama" :data="{value:images}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" :moreMenu="additionalImageMenuItems")
</template>
<script>
import { createHelpers } from "vuex-map-fields";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
const { mapFields } = createHelpers({
  getterType: "contacts/getContact",
  mutationType: "contacts/updateContact"
});
export default {
  components: {
    AttachFillForm
  },
  data: () => ({
    imagesSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Contact Images",
      hint: null,
      type: "image",
      image_size: "medium",
      multiple: true
    },

    additionalImageMenuItems: [
      { title: "Make Thumbnail", icon: "edit", action: "make_thumbnail" }
    ]
  }),

  computed: {
    ...mapFields({
      images: "images"
    })
  },
  methods: {
    saveImages: function(theObject) {
      // update the store
      this.$store.commit("contacts/updateContact", {
        path: "images",
        value: theObject.value
      });
      this.saveToServer();
    },
    onMenuActionImages: function(value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("contacts/deleteImage", value.theObject.id);
          break;
        case "make_thumbnail":
          this.$store.commit("contacts/updateContact", {
            path: "thumbnail_id",
            value: value.theObject.id
          });
          break;
      }
      this.saveToServer();
    },

    saveToServer: function() {
      this.$store.dispatch("contacts/saveCurrentAndDoNothing");
    }
  }
};
</script>
<style scoped lang="stylus"></style>
