import {uuid} from "vue-uuid";
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";

const state = {
    schema_data: [],
    title: "Untiled Document 2",
    currentPropertyName: "Global",
    currentIndex: -1,
    disableAllComponents: false,
    modalWindow: false //modal window to show componetnts
}
const getters = {

    getCurrentIndex: state => theID => {
        return state.schema_data.findIndex(item => item.id == theID);
    },

    getCurrentID: state => {
        let theIndex = null;
        if (state.currentIndex > -1) {
            theIndex = state.schema_data[state.currentIndex].id;
        }
        return theIndex;
    },
    // get the property
    getCurrentProperty: state => {
        if (state.currentIndex > -1) {
            return state.schema_data.find(
                item => item.id == state.schema_data[state.currentIndex].id
            );
        } else {
            return null;
        }
    },
    getSchemaValue: state => searchID => {
        // somehow i get errors when a field is droped in the dropbox
        let result = state.schema_data.find(data => data.id === searchID);
        return result.default.value;
    },
    // get the Schema
    getSchema: state => {
        return state.schema_data;
    },

    // get the Title
    getTitle: state => {
        return state.title;
    },

}

const mutations = {
    resetFormBuilder(state) {
        state.schema_data = [];
        state.workflow_data = [];
        state.title = "Untiled Document";
        state.currentPropertyName = "Global";
        state.disableAllComponents = false;
        state.modalWindow = false;
        state.currentIndex = -1;
    },


    setTitle(state, value) {
        state.title = value;
    },

    setOrderNumbers(state) {
        for (var i = 0; i < state.schema_data.length; i++) {
            state.schema_data[i]["order_no"] = i;
        }
    },


    setSchemaValue(state, dataObj) {

        // set value from the field
        let schemaItem;
        schemaItem = state.schema_data.find(data => data.id == dataObj.schema_id);

        let theResult = JSON.parse(JSON.stringify(schemaItem.default));
        theResult.value = dataObj.value
        schemaItem.value = theResult
        console.log("SCHEMA ITEM GET")
        console.log(schemaItem)

    },

    setCurrentProperty(state, property) {
        // property is an object that consists of a field and a value e.g. {field:'label',value:value}
        state.schema_data[state.currentIndex][property.field] = property.value;
    },


    setValueCurrentProperty(state, property) {
        state.schema_data[state.currentIndex].default[property.field] = property.value;
    },

    setCurrentPropertyArrayItem(state, property) {
        // get the array by field e.g table["columns"]
        let theArray = state.schema_data[state.currentIndex][property.field];

        // find the index based on the match field e.g. table["columns"]["id"] == value.id
        let theIndex = theArray.findIndex(
            item => item[property.matchField] == property.item[property.matchField]
        );
        
        // replace the item in the state
        theArray[theIndex] = property.item;
        // state.schema_data[state.currentIndex][property.field] = theArray;
    },

    setSchema(state, schema) {
        state.schema_data = schema;
    },

    setModalWindow(state, value) {
        state.modalWindow = value;
    },

    setProperties(state, name) {
        state.currentPropertyName = name;
    },

    editFormByID(state, theIndex) {
        state.currentIndex = state.schema_data.findIndex(
            item => item.id == theIndex
        );
        state.currentPropertyName = state.schema_data[state.currentIndex].name;
        state.disableAllComponents = true;

        // reset all fields
        state.schema_data.forEach(item => (item.value = item.default));
    },

    copyFormByID(state, theIndex) {
        let originIndex;
        // get the Index
        originIndex = state.schema_data.findIndex(item => item.id == theIndex);
        let clone_schema = JSON.parse(
            JSON.stringify(state.schema_data[originIndex])
        );
        clone_schema.id = uuid.v4(); // get a new ID

        // add into array
        state.schema_data.splice(originIndex + 1, 0, clone_schema);
    },

    deleteFormByID(state, theIndex) {
        let tempIndex = state.schema_data.findIndex(item => item.id == theIndex);
        state.schema_data.splice(tempIndex, 1);
    },

    removeField(state, type) {
        let tempIndex = state.schema_data.findIndex(item => item.name === type);
        state.schema_data.splice(tempIndex, 1);
    },

    addNewField(state, type) {
        console.log(`addNewField: ${type}`);
        let schemaModified = false;
        switch (type) {
            case "TextField": {
                let dp = SETTINGS_DEFAULT.default_text_properties();
                console.log(dp)
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }
            case "Integer": {
                let dp = SETTINGS_DEFAULT.default_text_properties();
                dp.type = "number";
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }
            case "Float": {
                let dp = SETTINGS_DEFAULT.default_text_properties();
                dp.type = "decimal";
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }
            case "EmailField": {
                let dp = SETTINGS_DEFAULT.default_text_properties();
                dp.type = "email";
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }
            case "WebField": {
                let dp = SETTINGS_DEFAULT.default_text_properties();
                dp.type = "weblink";
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }
            case "Header": {
                let dp = SETTINGS_DEFAULT.default_header_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }
            case "TextArea": {
                let dp = SETTINGS_DEFAULT.default_textarea_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }
            case "Date": {
                let dp = SETTINGS_DEFAULT.default_date_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "Time": {
                let dp = SETTINGS_DEFAULT.default_time_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "DateTime": {
                let dp = SETTINGS_DEFAULT.default_datetime_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "Radio": {
                let dp = SETTINGS_DEFAULT.default_radio_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "Checkbox": {
                let dp = SETTINGS_DEFAULT.default_checkbox_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "Select": {
                let dp = SETTINGS_DEFAULT.default_select_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "Table": {
                let dp = SETTINGS_DEFAULT.default_table_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "Attach": {
                let dp = SETTINGS_DEFAULT.default_attach_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "Image": {
                let dp = SETTINGS_DEFAULT.default_image_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "Signature": {
                let dp = SETTINGS_DEFAULT.default_signature_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "Draw": {
                let dp = SETTINGS_DEFAULT.default_draw_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "Map": {
                let dp = SETTINGS_DEFAULT.default_map_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "Switch": {
                let dp = SETTINGS_DEFAULT.default_switch_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "RouteID": {
                let dp = SETTINGS_DEFAULT.default_route_id_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

            case "RouteName": {
                let dp = SETTINGS_DEFAULT.default_route_name_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }
            case "PlaceID": {
                let dp = SETTINGS_DEFAULT.default_place_id_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }
            case "PlaceName": {
                let dp = SETTINGS_DEFAULT.default_place_name_properties();
                state.schema_data.push(dp);
                schemaModified = true;
                break;
            }

        }

        // Set the Properties on the Panel
        if (schemaModified) {
            state.currentIndex = state.schema_data.length - 1;
            state.currentPropertyName = state.schema_data[state.currentIndex].name;
            state.disableAllComponents = true;
        }

        console.log(console.log(state.schema_data));
    },

    loadAllFieldProperties(state, payload) {
        state.schema_data = payload.data;
        state.currentPropertyName = "GlobalTemplateComponents";
        state.disableAllComponents = false;
        state.currentIndex = -1;
    },

    saveFieldProperties(state) {
        console.log("---------------------");
        state.currentPropertyName = "GlobalTemplateComponents";
        state.disableAllComponents = false;
        state.currentIndex = -1;

        // reset all fields
        state.schema_data.forEach(item => (item.value = item.default));
    },


    loadFormBuilder(state, payload) {
        console.log("***** LOADED FORM BUILDER **********");
        console.log(payload.data.form_builders[0].label);
        state.schema_data = payload.data.form_builders;
    },
    saveFormBuilder(state, payload) {
        state.schema_data = payload.data.form_builders;
    },
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,

};
