<template lang="pug">
  .fill-width(v-if="$store.state.workflow.currentIndex > -1")
    div(v-if="this.$route.name == 'workflowBoard' && jahaziItemLoaded == false")
      v-divider
      v-container(fluid)
        v-layout.my-2(row, wrap)
          .d-flex.flex-column()
            .d-flex.align-baseline()
              h2.mx-4 {{$store.getters['workflow/getCurrentWorkflow'].name}}
              .subtitle-1.mx-4.grey--text() {{$store.getters['workflow/getCurrentWorkflow'].description}}
            v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
          v-spacer
          .text-xs-center
            v-menu(offset-y)
              template(#activator="{on}")
                v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                  | Actions
                  v-icon(dark) keyboard_arrow_down
              v-list
                v-list-item(v-for='item in dropDownMenu', :key='item.title')
                  v-list-item-title {{ item.title }}
          v-btn.ma-2(outlined small, color="grey"  :disabled='lists.length === 0' ) Add
          v-btn.ma-2( small, color='primary', :disabled='lists.length === 0'  @click='reset') Reset
          v-btn.ma-2.mr-5( small, color='primary', :disabled='lists.length === 0'  @click='save') Save
    v-divider
    div.mt-6
      .d-flex()
        .board
          .lists-container
            container(lock-axis='x' orientation='horizontal' drag-handle-selector='.list-drag-handle' @drop='onListDrop' :drop-placeholder="upperDropPlaceholderOptions")
              draggable(v-for='(list, listIndex) in lists' :key='list.id')
                section.list-container(ref='list' :data-id='list.id')
                  .list-header.d-flex.align-center.justify-space-between
                    span.list-drag-handle &#x2630;
                      span  {{ list.name }}
                      //- .caption {{list.id}}
                    div
                      v-menu()
                        template( v-slot:activator="{ on, attrs }")
                          v-btn(icon v-bind="attrs" v-on="on" :disabled="isAdministrator")
                            v-icon() expand_more
                        v-list(dense)
                          v-list-item-group()
                            v-list-item()
                              v-list-item-icon()
                                v-icon(:size="20") edit
                              v-list-item-title() Edit Template
                            v-list-item(@click="deleteColumn(list)")
                              v-list-item-icon()
                                v-icon(:size="20")  delete
                              v-list-item-title() Delete Template
                  container(group-name='list' drag-class='card-ghost' drop-class='card-ghost-drop' non-drag-area-selector='.icon' :animation-duration='100' @drop='e => onCardDrop(e, list, listIndex)' :drop-placeholder="dropPlaceholderOptions")
                    draggable(v-for='item in list.items' :key='item.id')
                      board-card(v-if="item.data" :item='item' @edit='editItem' @view='viewItem' @remove="deleteItem")
                  .item-entry
                    board-item-entry(:list-id='list.id' placeholder='Add an item' icon='more_horiz' @enter='onAddItem')
              .new-list
                board-item-entry(placeholder='Add a list' @enter='onAddList')
        v-dialog(ref='modal' v-model='modal' @close='hideModal' max-width="1200px")
          v-card()
            v-card-title.primary.white--text() Some title Here
            v-progress-linear(:indeterminate="showProgress" color="primary")
            v-container.grid-list-md.py-6(v-if="$store.state.workflow.workflowRecord.currentIndex > -1")
              v-form(ref="form")
                record-renderer(v-if="getSchema()" )
            v-divider
            v-card-actions()
              v-spacer()
              v-btn(color="grey" outlined @click="hideModal") Canecel
              v-btn(color="primary" type="submit"  @click="saveRecord") Submit

        //- form(@submit='onAddFullItem' @cancel='hideModal')  Form is over here

</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";
import BoardCard from "@/modules/workflow/board/components/ui/BoardCard";
import { makeDropHandler } from "@/modules/workflow/board/utils/plugins";
import BoardItemEntry from "@/modules/workflow/board/components/ui/BoardItemEntry";
import RecordRenderer from "@/modules/workflow/records/components/forms/RecordRenderer";
export default {
  components: {
    Container,
    Draggable,
    BoardCard,
    BoardItemEntry,
    RecordRenderer,
  },
  data: () => ({
    jahaziItemLoaded: true,
    currentColumnID: null,
    showProgress: true,
    modal: false,
    activeListId: null,
    upperDropPlaceholderOptions: {
      className: "cards-drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    dropDownMenu: [
      {
        title: "Action 1",
      },
      {
        title: "Action 2",
      },
    ],
  }),

  mounted() {

    this.$store.commit("workflow/workflowRecord/clearAll");
    if (this.$store.state.workflow.currentIndex == -1) {
      this.$store
        .dispatch("workflow/fetchWorkflow", this.$route.params.workflowID)
        .then(() => {
          this.$store.dispatch(
            "workflow/board/fetchboard",
            this.$route.params.workflowID
          ).then(()=>{
            this.loadCustomJahaziTypes()
          });

        });
    } else {
      this.$store.dispatch(
        "workflow/board/fetchboard",
        this.$route.params.workflowID
      ).then(()=>{
        this.loadCustomJahaziTypes()
      });
    }





  },
  computed: {
    lists() {
      return this.$store.state.workflow.board.lists;
    },
    isAdministrator: function(){
      return !this.$store.state.profile.userInfo.is_administrator;
    },
  },

  methods: {


    loadCustomJahaziTypes:function(){
      for (let i=0; i<this.$store.getters["workflow/getLatestSchema"].length; i++){
        if(this.$store.getters["workflow/getLatestSchema"][i].name == "Jahazi"){
          this.jahaziItemLoaded = ""

          switch(this.$store.getters["workflow/getLatestSchema"][i].type){
            case "customer":
              if(this.$store.state.customer.customer_data.length < 2){
                this.$store.dispatch("customer/loadAllCustomers").then(()=>{
                  this.jahaziItemLoaded = false;
                })
              } break;
            case "contact":
              if(this.$store.state.contacts.contact_data.length < 2) {
                this.$store.dispatch("contacts/loadAllContacts").then(()=>{
                  this.jahaziItemLoaded = false;
                })
              }
              break;
            case "vehicle":
              if(this.$store.state.vehicles.all.length < 2) {
                this.$store.dispatch("vehicles/loadAllItems").then(()=>{
                  this.jahaziItemLoaded = false;
                })
              }
          }

        }
      }
    },

    onAddList({ text }) {
      this.$store
        .dispatch("workflow/board/addBoardColumn", {
          workflow_template_id: this.$store.getters[
            "workflow/getCurrentWorkflow"
          ].id,
          name: text,
        })
        .then(() => {
          this.$nextTick(() => {
            const lists = this.$refs.list;
            lists[lists.length - 1].querySelector("input").focus();
          });
        });
    },

    onAddItem({ id, text, more }) {
      if (more) {
        this.activeListId = id;
        this.modal = true;
        this.showModal({ title: text });
        return;
      }
      this.addItem(id, text);
    },

    onAddFullItem(item) {
      item.id
        ? this.$store.commit("workflow/board/updateItem", {
            itemId: item.id,
            ...item,
          })
        : this.addItem(
            this.activeListId,
            item.title,
            item.description,
            item.date
          );
      this.hideModal();
    },

    addItem(listId, title) {
      console.log(listId);
      console.log(title);
      this.currentColumnID = listId;
      this.showProgress = true;
      this.showModal(true);
      this.$store.commit("workflow/workflowRecord/clearAll");
      this.$store
        .dispatch(
          "workflow/workflowRecord/addRecord",
          this.$store.getters["workflow/getCurrentWorkflow"].id
        )
        .then(() => {
          this.showProgress = false;
        });
      //this.$store.commit('workflow/board/addItem', { listId, title, description, date })
    },

    editItem(item) {
      this.currentColumnID = item.board_column_id;
      this.showProgress = true;
      this.showModal(true);
      this.$store.commit("workflow/workflowRecord/clearAll");
      this.$store
        .dispatch("workflow/workflowRecord/fetchRecord", item.id)
        .then(() => {
          this.showProgress = false;
        });
    },

    viewItem(item){


      this.$store.commit("workflow/setCurrentIndexByID",item.workflow_template_id)
      this.$store.commit("workflow/workflowRecord/setCurrentIndexByID",item.id);

      this.$router.push({
        name: "workflowRecordOverview",
        params: { workflowID: item.workflow_template_id,workflowRecordID: item.id },
      }).catch(()=>{});
    },

    deleteItem(item) {
      this.$store.dispatch("workflow/board/deleteRecord", item);
    },

    onListDrop: makeDropHandler("onListDropComplete"),

    onListDropComplete: function(src, trg) {
      this.$store.commit("workflow/board/moveList", [src.index, trg.index]);
      this.$store.dispatch(
        "workflow/board/orderColumns",
        this.$store.getters["workflow/getCurrentWorkflow"]
      );
    },

    onCardDrop: makeDropHandler("onCardDropComplete"),

    onCardDropComplete(src, trg) {
      this.$store.commit("workflow/board/moveItem", [
        src.params[1],
        src.index,
        trg.params[1],
        trg.index,
      ]);

      console.log("++++++++++++++++");
      console.log({
        recordIndex: trg.index,
        columnIndex: trg.params[1],
        columnID: trg.params[0].id,
      });
      this.$store.commit("workflow/board/setBoardColumn", {
        columnIndex: trg.params[1],
        recordIndex: trg.index,
        columnID: trg.params[0].id,
      });
      //save
      this.save();
    },

    showModal(item) {
      this.modal = true;
      console.log(item);
      // this.$nextTick(() => {
      //   this.$refs.form.show(item)
      // })
    },

    hideModal() {
      this.focusInput(this.activeListId);
      this.modal = false;
      this.$store.commit("workflow/workflowRecord/clearAll");
    },

    focusInput(listId) {
      const index = this.lists.findIndex((list) => list.id === listId);
      if (index > -1) {
        this.$refs.list[index].querySelector("input").focus();
      }
    },

    reset() {
      if (confirm("Are you sure you want to reset the board?")) {
        this.$store.commit("workfow/board/reset");
      }
    },

    save() {
      this.$store.dispatch("workflow/board/saveLayout");
    },

    getBreadCrumbItems: function() {
      var theArray = [];
      theArray.push({
        text: "Workflows",
        disabled: false,
        to: { name: "workflows", params: { tabID: 0 } },
      });
      theArray.push({
        text: "Board",
        disabled: true,
      });
      return theArray;
    },

    //- forms
    validate: function() {
      if (this.$refs.form.validate()) {
        this.$store.commit("showSnackBar", {
          color: "primary",
          message: "Well Done you filled correctly",
        });
      } else {
        this.$store.commit("showSnackBar", {
          color: "error",
          message: "Error found in Form",
        });
      }
    },

    getSchema(){
      return this.$store.getters["workflow/getCurrentWorkflow"].data[this.$store.getters["workflow/workflowRecord/getCurrentRecord"].workflow_template_version-1].schema;
    },


    saveRecord: function() {
      this.showProgress = true;
      this.$store.commit("workflow/workflowRecord/updateRecord", {
        path: "board_column_id",
        value: this.currentColumnID,
      });
      this.$store.dispatch("workflow/workflowRecord/saveRecord").then(() => {
        this.$store.commit("workflow/board/pushRecord", {
          columnID: this.currentColumnID,
          record: this.$store.getters[
            "workflow/workflowRecord/getCurrentRecord"
          ],
        });

        this.showProgress = false;
        this.hideModal();
        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Form ${this.$store.getters["workflow/workflowRecord/getCurrentRecord"].workflow_template_name} is saved`,
        });
        this.$store.commit("workflow/workflowRecord/clearAll");
        // this.$store.commit("workflow/workflowRecord/toggleShowForm",false);
      });
    },

    deleteColumn: function(list) {
      confirm(
        "Are you sure you want to delete the column + records" + list.name
      ) &&
        this.$store
          .dispatch("workflow/board/deleteColumn", list.id)
          .catch((e) => {
            this.$store.commit("showSnackBar", {
              show: true,
              color: "warning",
              message: `Error ${e}`,
            });
          });
    },
  },
};
</script>
<style lang="stylus">
.board {
  overflow auto;
  position relative;
  width 100%;
  height: 100%;
}




$column-width = 320px
.list-container
  width: $column-width
  padding: 10px
  margin: 5px
  margin-right: 20px
  background-color: #f3f3f3
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24)
.lists-container
  > *
    display: inline-block
    vertical-align: top
.list-header
  margin-bottom: 5px
.card
  margin: 5px
  background-color: white
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24)
  padding: 10px
//.card-ghost
//  transition: 0.25s all
//  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12)
//  transform: scale(1.1)
//.card-ghost-drop
//  transform: scale(1)
.list-header
  font-size: 18px
.list-drag-handle
  cursor: move
  padding: 5px
.item-entry
  padding-top: 10px
  margin-top: 10px
  border-top: 1px solid #DDD
.new-list
  width: $column-width
  margin-left: 10px
  margin-top: 5px
.clear-button
  position: absolute
  top: 5px
  right: 20px

.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg)
}

.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg)
}

.opacity-ghost {
  transition: all .18s ease;
  opacity: 0.8;
  /* transform: rotateZ(5deg); */
  background-color: cornflowerblue;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.opacity-ghost-drop {
  opacity: 1;
  /* transform: rotateZ(0deg); */
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.0);
}

.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  border-radius 5px;
  margin: 5px;
}

.cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border-radius 5px;
  border: 1px dashed #abc;
  margin: 5px 45px 5px 5px;
}
</style>
