<template lang="pug">
  div(v-if="singleLoad")
    v-combobox(
      ref='theCombo'
      v-model="select"
      :hide-no-data="!search"
      :search-input.sync="search"
      hide-selected
      :items="items"
      :label="label"
      :item-text="field"
      item-value="id"
      @keyup.enter="onEdit"
      @change="onChange"
      :loading="isLoading"
      @click="onOpen"
      clearable

    )
      template(slot="selection" slot-scope="data")
        v-chip.chip--select-multi(:input-value="data.selected" :color="isNewLabel?'primary white--text':'grey lighten-2'" small close @click:close="onRemove(data.item.id)" )
          | {{data.item[`${field}`]}}

      template(slot="no-data")
        v-list-item
          span.subheading Create
          v-chip.ml-2(:color="'primary white--text'" label small) {{search}}


</template>
<script>
export default {
  props: {
    value: String,
    label: {
      type: String,
      default: "Select Item"
    },

    field: {
      type: String,
      default: "name"
    },

    module: {
      type: String,
      required: true
    },

    submodule: {
      type: String,
      default: null,
      required: false
    },

    actionPrefix: {
      type: String,
      required: true
    }
  },
  data: () => ({
    modulePath: null,
    select: null,

    isLoading: false,
    loadAll: false,
    singleLoad: false,

    search: null,
    editing: null,
    isNewLabel: false
  }),

  computed: {
    items: {
      get: function () {
        if (this.submodule) {
          return this.$store.state[this.module][this.submodule][
          this.actionPrefix.toLowerCase() + "_data"
              ];
        } else {
          return this.$store.state[this.module][
          this.actionPrefix.toLowerCase() + "_data"
              ];
        }
      },
      set: function (value) {
        console.log(value);
      }
    }
  },

  methods: {

    onChange: function (theValue) {
      console.log(`on changed clicked ${theValue}`)
      this.isLoading = false;

      if (theValue != null) {
        this.$emit("input", theValue.id);
        this.isNewLabel = false;
      } else {
        this.$emit("input", null);
      }
    },

    onOpen: function () {
      if (this.loadAll == false) {
        this.isLoading = true;
        this.$store
            .dispatch(`${this.modulePath}/loadAll${this.actionPrefix}s`)
            .then(() => {
              if (this.submodule) {
                this.items = this.$store.state[this.module][this.submodule][
                this.actionPrefix.toLowerCase() + "_data"
                    ];
              } else {
                this.items = this.$store.state[this.module][
                this.actionPrefix.toLowerCase() + "_data"
                    ];
              }

              this.isLoading = false;
              this.loadAll = true;
            });
      }
    },

    onRemove: function (item) {
      console.log(item);
      this.select = null;
      this.$emit("input", this.select);
    },

    onEdit: function () {
      if (typeof this.$refs.theCombo.$data.selectedItems[0] == "string") {
        this.isLoading = true;
        let newValue = this.$refs.theCombo.$data.selectedItems[0];
        // add
        this.$store
            .dispatch(`${this.modulePath}/add${this.actionPrefix}`)
            .then(() => {
              // get Item
              let currentItem = this.$store.getters[
                  `${this.modulePath}/getCurrent${this.actionPrefix}`
                  ];

              // mutate store
              this.$store.commit(
                  `${this.modulePath}/update${this.actionPrefix}`,
                  {path: this.field, value: newValue}
              );

              //console.log(this.$store.state["settings"][this.submodule][this.actionPrefix.toLowerCase()+"_data"].length)

              // // save to database
              this.$store
                  .dispatch(`${this.modulePath}/save${this.actionPrefix}`)
                  .then(() => {
                    this.select = currentItem;
                    this.$emit("input", this.select.id);
                    this.isLoading = false;
                    this.isNewLabel = true;
                    // need to close the menu
                  });
            });
      }
    }
  },

  mounted() {
    this.singleLoad = false
    this.$nextTick(() => {
      this.loadAll = false;
      if (this.submodule) {
        this.modulePath = `${this.module}/${this.submodule}`;
      } else {
        this.modulePath = `${this.module}`;
      }

      if (this.value) {
        this.$store
            .dispatch(`${this.modulePath}/fetch${this.actionPrefix}`, this.value)
            .then(() => {
              this.select = this.$store.getters[
                  `${this.modulePath}/getCurrent${this.actionPrefix}`
                  ];
              this.singleLoad = true;
            });
      } else {
        this.select = null;
        this.singleLoad = true;
      }

    })

  }
};
</script>
<style lang="stylus" scoped></style>
