<template lang="pug">
  div
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Notifications
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item(v-for='item in getMenuItems', :key='item.title' @click="onClick(item)" :disabled="$store.state.messages.selectedItems.length == 0")
                v-list-item-icon()
                  v-icon() {{item.icon}}
                v-list-item-title {{ item.title }}
          v-btn.ma-2(small,color="primary",@click="sendNotification")
            v-icon.pr-2(dark) alarm
            | Send Notification

    v-divider
    v-layout.mx-4.my-2(column)
      v-chip-group(column  active-class="primary--text" v-model="filterIndex" @change="onChangeFilter")
        v-chip(v-for='toggleItem in toggleItems', :key='toggleItem' small ) {{ toggleItem }}
    v-layout.ma-4(row align-center)
      v-row()
        v-col(cols="12" xs="12" md="5" xl="3")
          .d-flex.align-center()
            v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" )
            v-btn(v-if="search.length > 0" text outlined small @click="search=''") clear all

    v-divider
    .grey.lighten-4.py-3
    v-divider
    notification-table(:search="search", :group="group")



</template>
<script>
import ToggleChips from "@/modules/global/components/lists/ToggleChips";
import NotificationTable from "@/modules/messaging/components/tables/NotificationTable";

export default {
  components: {
    ToggleChips,
    NotificationTable
  },
  data: () => ({
    select1: "All Reminders",

    selectOptions1: [
      "All Reminders",
      "Overdue Only",
      "Overdue + Due Soon",
      "Due Soon Only"
    ],

    search: "",
    filterIndex:0,
    group:"all",
    toggleItems: ["All","Unread", "Read"]
  }),

  computed:{
    isAdministrator: function(){
      return this.$store.state.profile.userInfo.is_administrator;
    },

    getMenuItems: function() {
      let menuList = [];
      if(!this.isAdministrator){
        menuList.push({
          title: "Mark as Read",
          icon: "drafts",
          value: "read"
        })
      }

      menuList.push({
        title: "Delete Selected",
        icon: "delete",
        value: "deleted"
      })

      return menuList;
    }

  },
  methods: {
    sendNotification: function() {

    },

    onChangeFilter:function(value){
      this.group = this.toggleItems[value]
    },

    showDialog: function() {},

    closeDialog: function(value) {
      console.log(value);
    },

    onClick: function(item){
      var theIDs = [];
      this.$store.state.messages.selectedItems.forEach(element => {
        theIDs.push(element.id);
      })
      let theType = this.isAdministrator?'admin':'contact'
      switch(item.value){
        case "deleted":
          confirm(this.isAdministrator?"Are you sure you want to delete selected messages for all users?":"Are you sure you want to delete selected messages?") &&
            this.$store.dispatch("messages/deleteManyMessages",{ids:theIDs,type:theType})
          break;
        case "read": this.$store.dispatch("messages/markManyAsRead",theIDs); break;
      }
    }
  }
};
</script>
<style scoped lang="stylus">
.filterStyles{
  max-width 900px;
}
</style>
