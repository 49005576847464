<template lang="pug">
  .ma-2(v-if="$store.state.profile.userInfo")
    v-tabs.tabs(slider-color='primary'  background-color="grey lighten-3" :height="35")
      v-tab.tabs
        | Contact Details
      v-tab-item
        overview-contact-details
      v-tab.tabs(v-if="isUser && $store.state.profile.userInfo.is_administrator")
        | User Details
      v-tab-item(v-if="isUser  && $store.state.profile.userInfo.is_administrator")
        overview-user-details
</template>
<script>
import OverviewContactDetails from "@/modules/contacts/layouts/overviews/OverviewContactDetails";
import OverviewUserDetails from "@/modules/contacts/layouts/overviews/OverviewUserDetails";
export default {
  components: {
    OverviewContactDetails,
    OverviewUserDetails
  },

  computed: {},

  data: () => ({
    isUser: false
  }),

  mounted() {
    if(this.$store.state.profile.userInfo.is_administrator){
      this.$store
          .dispatch("users/fetchUserByContact", this.$route.params.contactID)
          .then(() => {
            this.isUser = true;
            console.log("user_data");
            console.log(this.$store.state.users.user_data);
          });
    }

  }

  // async mounted()  {
  //   await axios.get(`/api/contact/isUser/${this.$route.params.contactID}`)
  //       .then(response => {
  //         console.log(response.data)
  //         this.isUser = response.data;
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
  //
  // }
};
</script>
<style scoped lang="stylus"></style>
