<template lang="pug">
  div
    v-container.pt-0(grid-list-sm)
      form-divider DIMENSIONS &amp; WEIGHT
      v-layout(row, wrap, justify-start)
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='length',hint="Length of Vehicle", persistent-hint, label='Length', type='number')
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='width',hint="Width of Vehicle", persistent-hint, label='Width', type='number')
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='height',hint="Height of Vehicle", persistent-hint, label='Height', type='number')
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='wheel_base',hint="Length between center front wheels and center rear wheels", persistent-hint, label='Wheel base', type='number')
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='gross_weight',hint="The combination of the Gross Weight of the Vehicle and the towing  capacity", persistent-hint, label='Gross Weight', type='number')
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='max_payload',hint="The maximum allowable weight that the vehicle can hold. This includes cargo and any addtional options but excludes the driver and fuel", persistent-hint, label='Max Payload', type='number')
      


      //- form-divider PERFORMANCE
      //- v-layout(row, wrap, justify-start)
      //-   v-flex(xs12, md6, xl4)
      //-     v-text-field(v-model='towingCapacity',hint="The towing ability of the vehicle as it comes standard without the addition of optional suspensions, engines and transmissions", persistent-hint, label='Towing Capcity', type='number')
      

      //- form-divider FUEL ECONOMY
      //- v-layout(row, wrap, justify-start)
      //-   v-flex(xs12, md6, xl4)
      //-     v-text-field(v-model='epaCity',hint="EPA City", persistent-hint, label='EPA City', type='number')
      //-   v-flex(xs12, md6, xl4)
      //-     v-text-field(v-model='epaHighway',hint="EPA Highway", persistent-hint, label='EPA Highway', type='number')
      //-   v-flex(xs12, md6, xl4)
      //-     v-text-field(v-model='epaCombined',hint="EPA Combined", persistent-hint, label='EPA Combined', type='number')


</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "vehicles/getItem",
  mutationType: "vehicles/updateItem"
});

export default {
  components: {
    FormDivider
  },

  data: () => ({}),

  computed: {
    ...mapFields([
      "length",
      "width",
      "height",
      "wheel_base",
      "gross_weight",
      "max_payload"
    ])
  }
};
</script>

<style scoped>
.topiDivider {
  color: #aa00cc;
  border-bottom: 2px solid #aa00cc;
  margin-top: 20px;
  font-size: 18px;
}
</style>
