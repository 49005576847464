<template lang="pug">
  div
    v-divider
    v-container.white.pa-2(fluid)
      v-layout.my-2.pr-4(row, wrap, align-center)
        h2.mx-4 Supplier Organizations
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small,outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item-group(color="primary")
                v-list-item(v-for='item in items', :key='item.title')
                  v-list-item-title {{ item.title }}
          v-btn.ma-2(small, color='primary')
            v-icon.pr-2(dark) search
            | Search
          v-btn.ma-2(small color="primary", @click="openEdit") Add New Merchant
    v-divider
    v-layout.ma-4(row align-center)
      v-row.fill-width()
        .d-flex.mx-4.fill-width()
          .d-flex.align-center.fill-width()
            v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" style="max-width:300px")
            v-icon(v-if="search.length > 0" color="primary" outlined small @click="search=''") close
            v-select.ml-6(:items='$store.state.settings.groupSettings.group_data', item-text='name', item-value='id', @focus='onGroupSelectFocus', prepend-icon='group', v-model='group', label='Group' clearable style="max-width:350px")
            v-spacer()
            v-btn.ml-4(v-if="this.$store.getters['merchant/getSelectedMerchants'].length" color="primary" outlined @click="clearAllSelection") Clear All {{this.$store.getters['merchant/getSelectedMerchants'].length}} Selected Suppliers

    v-divider
    .grey.lighten-4.py-3
    v-divider
    merchants-table(:search="search" :group="group")
    v-dialog(v-model="dialog" width="800" :persistent="true")
      import-data-dialog(@closeImportDialog="dialog=false" :hasGroup="true")


</template>
<script>
import MerchantsTable from "@/modules/contacts/merchants/components/tables/MerchantsTable";
import ImportDataDialog from "@/modules/global/components/dialog/ImportDataDialog";

export default {
  components: {
    MerchantsTable,
    ImportDataDialog
  },
  data: () => ({
    dialog: false,
    active: true,
    search: "",
    group: null,
    items: [
      {
        title: "Import CSV"
      },
      {
        title: "Export CSV"
      }
    ],

    searchFilter: ""
  }),

  methods: {
    openEdit: function () {
      this.$store.dispatch("merchant/addMerchant").then(() => {
        let currentObject = this.$store.getters["merchant/getCurrentMerchant"]
        console.log(currentObject.id);
        this.$router.push({
          name: "merchantDetailsForm",
          params: {contactID: currentObject.id}
        }).catch(()=>{});
      });
    },


    showDialog: function () {
    },

    closeDialog: function (value) {
      console.log(value);
    },
    onAction: function (value) {
      console.log(value)
      switch (value) {
        case "Import CSV":
          this.dialog = true;
          break;
        case "Export CSV":
          console.log("Exportinggggg");
          break;
      }
    },
    clearAllSelection: function () {
      this.$store.commit("merchant/setSelectedMerchants", []);
    },
    onGroupSelectFocus: function () {
      // Load The group List
      if (this.$store.state.settings.groupSettings.currentIndex == -1) {
        this.$store.dispatch("settings/groupSettings/loadAllGroups");
      }
    },
  }
};
</script>
<style lang="stylus" scoped></style>
