<template lang="pug">
  div
    v-autocomplete(
      v-model="roles"
      :items="$store.state.settings.roleSettings.role_data"
      :search-input.sync="search" 
      item-text="name" 
      item-value="id" 
      :label="label"
      placeholder="Start typing to Search"
      multiple
      chips
      dense
      @change="onChange"
      clearable
      )
      template(v-slot:selection="data")
        v-chip.mt-2.mb-1(v-bind="data.attrs" :input-value="data.selected" small close @click="data.select" @click:close="onRemove(data.item.id)")
          | {{ data.item.name }}

</template>
<script>

export default {
  components: {

  },
  props: {
    value:{
      type:Array,
    },
    multiple:{
      type:Boolean,
      default: false,
    },
    label:{
      type:String,
      default: "Select Roles"
    },
    mode:String,
  },
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    roles: null,
    search: null,
  }),

  methods: {
    setData: function(theData) {
      this.$emit("input", theData);
    },

    onChange: function(theValue) {
      this.$emit("input", theValue);
    },

    onRemove: function(item) {
      if(this.mode != null){
        this.$store.commit("workflow/formBuilder/removePermissionRole",{mode:this.mode,id:item});
      }
      this.$emit('remove',item)
    },


  },

  mounted() {
    this.roles = this.value;

    if(this.$store.state.settings.roleSettings.role_data.length <= 1){
      this.$store.dispatch("settings/roleSettings/loadAllRoles").then(()=>{

      })
    }

  }
};
</script>
<style scoped lang="stylus">
.imageCrop
  width: 60px
  height: 40px
  color red
  border-radius 5px
  background-color #CCCCCC
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
</style>
