import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";
const BILL_SETTINGS_API = "/Billing";

const state = {
  data: {
    location: {}
  }
};

const getters = {
  getBillingSettings: state => getField(state.data)
};

const mutations = {
  updateBillingSettings(state, payload) {
    updateField(state.data, payload);
  },

  savedBillingSettings(state, payload) {
    state.data = payload.data;
  },

  fetchBillingSettings(state, payload) {
    console.log(`biing data ${payload.data}`);
    state.data = payload.data;
  },
  reset(state) {
    state.data = {}
  }

};

const actions = {
  async saveBillingSettings({ state, commit }) {
    try {
      console.log(state.data);
      commit(
        "savedBillingSettings",
        await Repository.post(`${BILL_SETTINGS_API}`, state.data)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchBillingSettings({ commit }) {
    try {
      commit(
        "fetchBillingSettings",
        await Repository.get(`${BILL_SETTINGS_API}`)
      );
    } catch (error) {
      console.log(error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
