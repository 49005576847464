<template lang="pug">
  div
    v-menu(
      v-model='menu' 
      :close-on-content-click='false'
      :nudge-right='40'
      transition='scale-transition' 
      offset-y
      
      min-width='290px'
      :disabled="disabled"
      )
      template(v-slot:activator='{ on }')
        v-text-field( v-model='computedDate',prepend-icon="event",  :label="schema.label" :hint="schema.hint"  :persistent-hint="schema.hint != null" readonly v-on='on' :disabled="disabled")
      v-date-picker(v-model='date', @input='onDateChange' )

</template>
<script>
import moment from "moment";
// import SETTINGS from "@/modules/workflow/formBuilder/store/formSettings";
export default {
  props: ["schema", "dataValue", "index","disabled"],
  data: () => ({
    menu: false,
    date: new Date().toISOString().substr(0, 10),
    theDate:null
  }),
  mounted() {
    this.theDate = JSON.parse(JSON.stringify(this.dataValue));
  },
  computed: {
    computedDate: {
      get: function() {
        let val;
        val = this.theDate;
        if (val == null) {
          this.onSetDate(new Date().toISOString().substr(0, 10));
        } else {
          this.onSetDate(new Date(val).toISOString().substr(0, 10));
        }
        return moment(this.date).format(this.schema.date_format);
      },

      set: function(theValue) {


        this.theDate = theValue;
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    }
  },

  methods: {
    onSetDate: function(value) {
      this.date = value;
    },

    onDateChange: function(value) {
      this.computedDate = new Date(value).toISOString();
      this.menu = false;
    }
  }
};
</script>
<style scoped lang="stylus"></style>
