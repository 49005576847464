<template lang="pug">
  #blankcontent()
    .panelTitle()
      .d-flex.fill-width.justify-space-between()
        div() Map Panel
        .primary--text.pointer(v-if="this.$store.getters['profile/getViewPermission']('map')" @click="openMap")
          v-icon(color="primary") open_in_new
          | Open Live View
    template(v-if="this.$store.getters['profile/getViewPermission']('map')")
      v-divider.mx-2()
      v-progress-linear(v-if="isLoading" :indeterminate="isLoading")
      .panelContent(ref="panel" )
        #mapDashboard(:key="mapKey"  ref="mapDashboard" )
          v-btn#fullScreenButton(fab small color="accent" @click="toggleFullscreen")
            v-icon() {{(fullScreen)?'fullscreen_exit':'fullscreen'}}
          v-btn#placeButton( fab small color="primary" @click="togglePlaceMarkers")
            v-icon() {{(placeShowMarkers)? 'location_on':'location_off'}}
          v-btn#labelButton(fab small color="primary" @click="toggleMarkers")
            v-icon() {{(showLabel)?'label':'label_off'}}
          //- v-btn#refreshButton(fab small color="primary" @click="refreshPlaceMarkers(1.0)")
            v-icon() settings
        v-btn#extendsButton(fab small color="primary" @click="(placeShowMarkers)?zoomExtendMarkers(placeList):zoomExtendMarkers(markerList)")
          v-icon() zoom_out_map
    template(v-else)
      .d-flex.fill-height.justify-center.align-center()
        .grey--text() No Permission to view Map.
</template>
<script>
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
export default {
  props: {
    clientHeight: Number,
    resize:Boolean,
  },
  data: () => ({
    mapKey: 0,
    panelHeight:null,
    panelWidth:null,
    placeShowMarkers:false,
    showLabel:false,
    fullScreen: false,
    markerList:[],
    refreshCounter:0,
    isLoading:true,
      geofencePopup:null,
  }),


  mounted() {
    if (this.$store.getters['profile/getViewPermission']('map')){
      this.init();
    }

  },

  watch: {
    resize:function(){
      // if(this.panelWidth != this.$refs.panel.clientWidth && this.panelHeight != this.$refs.panel.clientHeight){
      //   this.init();
      // }

    },
    avlDataList: function(avlArray) {

      this.refreshCounter += 1;
      if(this.refreshCounter >20 && this.placeShowMarkers) {
        this.refreshPlaceMarkers(1.0);
        this.refreshCounter = 0;
      }


      if (avlArray.length > 0) {
        let avlData = avlArray[avlArray.length - 1];
        let tempMarker = this.markerList.find(e => e.vehicle_id == avlData.vehicle_id);
        if (tempMarker != null) {
          tempMarker.popUp.setHTML(
              `${tempMarker.popUpText}<span>Speed: ${avlData.speed}</span>`
          );
          tempMarker.setRotation(avlData.angle);
          tempMarker.setLngLat([avlData.location.lon, avlData.location.lat]);
          tempMarker.lineArray.push([
            avlData.location.lon,
            avlData.location.lat
          ]);
          // console.log(tempMarker.lineArray);
        }

        // draw Line
        this.multiLineArray = [];
        let lineCollection = turf.featureCollection([]);
        for(let i=0; i<this.markerList.length; i++){
          this.multiLineArray.push(this.markerList[i].lineArray);
          // console.log(this.markerList[i].lineArray.length);
          if(this.markerList[i].lineArray.length > 1){
            lineCollection.features.push(turf.lineString(this.markerList[i].lineArray))
          }


        }






        let multiLine = turf.multiLineString(this.multiLineArray);

        if(this.mapReady){
          this.mapDashboard.getSource('theLines').setData(multiLine);
          this.mapDashboard.getSource('arrow_source').setData(lineCollection);
        }


      }
      if(this.fullScreen){
        this.zoomExtendsVehicle();
      }

    }
  },

  computed: {


    avlDataList: function() {
      return this.$store.state.tracks.avl_data;
    }
  },

  methods: {

    openMap: function() {
      this.$router.push({ name: "maps", params: { tabID: 1 } });
    },
    zoomExtendsVehicle: function() {
      let tempArrayCoordinates = [];


      this.markerList.forEach(element => {
        tempArrayCoordinates.push([element.getLngLat().lng,element.getLngLat().lat])
      })

      // console.log("---------------------")
      // console.log(tempArrayCoordinates);
      // console.log("---------------------")
      if(tempArrayCoordinates.length > 1){
        let line = turf.lineString(tempArrayCoordinates,{name:"lineFromMarkers"});
        let bbox = turf.bbox(line);
        this.mapDashboard.fitBounds(bbox, {
          padding: 180
        })
      }
    },

    init: function() {

      this.mapKey += 1;
      this.mapReady = false;
      this.mapDashboard = null;
      this.mapLoaded = false;
      if(this.$store.state.tracks.currentIndex != -1 && this.$store.state.place.currentIndex != -1){
        this.$nextTick(()=>{
          this.createMap();
        })
      }else{
        Promise.all([this.$store.dispatch("tracks/loadAllTracks"),this.$store.dispatch("settings/groupSettings/loadAllGroups"),this.$store.dispatch("place/loadAllPlaces")]).then(()=>{
          // hide markers if more than 100
          if(this.$store.state.place.place_data.length > 100){
            this.placeShowMarkers = false;
          }
          this.refreshCounter = 0

          // create map
          this.$nextTick(() => {
            this.createMap();
          });
        })

      }
    },
    createMap: function() {
      mapboxgl.accessToken =
          "pk.eyJ1IjoibXVnYW1iaWtpbWF0aGkiLCJhIjoiY2p0OWNjaGZxMDdsdjQ0cXZjZTRhZnA0aSJ9.3b3qa7uHHInxWL3TBiMsSQ";
      this.mapDashboard = new mapboxgl.Map({
        container: "mapDashboard",
        style: "mapbox://styles/mapbox/streets-v10",
        minzoom: 1.3,
        center: [36.76132069660187, -1.2985446867349744], // Nairobi
        zoom: 16
      });

      this.markerList = [];

      let trackPoints = []
      // loop for markerList moving Devices
      for (
          let i = 0;
          i < this.$store.getters["tracks/getMovingDevices"].length;
          i++
      ) {
        let trackDevice = this.$store.getters["tracks/getMovingDevices"][i];

        let el2 = document.createElement("div");
        el2.className = "markerDashboard";
        el2.setAttribute(
            "style",
            `background-image:url("/static/web/markers/marker_green_translucent.png")`
        );



        // create popup
        let tempPopUp = new mapboxgl.Popup(el2);
        let operator_name =
            trackDevice.contact != null
                ? `<br/>${trackDevice.contact.first_name} ${trackDevice.contact.last_name}`
                : ``;

        tempPopUp.setHTML(
            `<span style="font-weight: bold"> ${trackDevice.vehicle.name} </span>${operator_name}<br/><span>Speed: ${trackDevice.avl_record.speed}</span>`
        );
        tempPopUp.addTo(this.mapDashboard);

        // add marker
        let tempMarker = new mapboxgl.Marker(el2);
        tempMarker.setLngLat([
          trackDevice.avl_record.location.lon,
          trackDevice.avl_record.location.lat
        ]);
        tempMarker.popUp = tempPopUp;
        tempMarker.popUpText = `<span style="font-weight: bold"> ${trackDevice.vehicle.name} </span>${operator_name}<br/>`;
        tempMarker.setPopup(tempPopUp);
        // tempMarker.addTo(this.mapDashboard);
        tempMarker.id = trackDevice.track_device.id;
        tempMarker.vehicle_id = trackDevice.vehicle.id;
        tempMarker.track_device = trackDevice.track_device;
        tempMarker.imei = trackDevice.track_device.imei;
        tempMarker.setRotation(trackDevice.track_device.angle);
        // add to cordinates path
        tempMarker.lineArray = [];
        // tempMarker.lineArray.push([
        //   trackDevice.avl_record.location.lon,
        //   trackDevice.avl_record.location.lat
        // ]);

        trackPoints.push({lon:trackDevice.avl_record.location.lon,lat:trackDevice.avl_record.location.lat})
        this.markerList.push(tempMarker);
      }

      // create place list
      this.placeList = [];



      this.markerList.forEach(element => {
        element.addTo(this.mapDashboard);
        element.togglePopup();
      })


      this.mapDashboard.on("load", this.onLoadMap);
    },

    onLoadMap: function() {
      let multiLine = turf.multiLineString([[[0,0],[10,10]], [[-24, 63], [-23, 60], [-25, 65], [-20, 69]]]);

      console.log(multiLine)

      this.geoMultiLineSource = {
        id: "theLines",
        source: {
          type: "geojson",
          data: multiLine
        },
        type:"line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": "#ed6498",
          "line-width": 4,
          "line-opacity": 1,

        }
      }



        this.mapDashboard.addSource('geofence', {
            'type': 'geojson',
            'data': '/api/geofence/geoJSONCollection'
        });

      this.mapDashboard.addLayer({
          'id': 'geofence_fill',
          'type': 'fill',
          'source': 'geofence', // reference the data source
          'layout': {},
          'paint': {
              'fill-color': '#0d97f7', // blue color fill
              'fill-opacity': 0.3,

          }
      });

        this.mapDashboard.addLayer({
            'id': 'geofence_line',
            'type': 'line',
            'source': 'geofence', // reference the data source
            'layout': {},
            'paint': {
                "line-color": "#000f4e",
                "line-width": 2,

            }
        });

        this.mapDashboard.on('mouseenter', 'geofence_fill', (e) => {

            // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            // }

          console.log(`mouse enter ${e.features[0].properties.name} at loc ${e.features[0].properties}`)
          let centroidCoord = [36.76132069660187, -1.2985446867349744]

            let theGeometry = JSON.parse(e.features[0].properties.geometry)
            if(theGeometry.type == "Point"){
                centroidCoord = theGeometry.coordinates;
            }else{
                let centroid = turf.centroid(e.features[0]);
                centroidCoord = centroid.geometry.coordinates;
            }


            this.geofencePopup = new mapboxgl.Popup({closeButton: false})
                .setLngLat(centroidCoord)
                .setHTML(`<i>Geofence:</i><br/><b>${e.features[0].properties.name}</b><br/>${e.features[0].properties.description??""}`)
            this.geofencePopup.addTo(this.mapDashboard)
        });

        this.mapDashboard.on('mouseleave', 'geofence_fill', () => {
            this.geofencePopup.remove()
        });



      this.mapDashboard.addLayer(this.geoMultiLineSource)

      let locationA = turf.lineString([
        [0,0],
        [20,20]
      ])

      let lineCollection = turf.featureCollection([
        locationA

      ]);

      let zMap = this.mapDashboard;
      zMap.addSource('arrow_source',{
        type: 'geojson',
        data: lineCollection
      })
      zMap.loadImage("/static/web/arrow.png", function (err, image) {
        // Throw an error if something went wrong
        if (err) {
          // console.log(err)
          throw err;
        }
        //   // console.log(err)
        console.log(image)

        // Declare the image
        zMap.addImage("pattern", image);

        // Use it
        zMap.addLayer({
          id: "pattern-layer",
          type: "line",
          source: "arrow_source",
          paint: {
            "line-pattern": "pattern",
            "line-width": 20,
          },
        });
      });

      this.mapDashboard.resize();

      if(this.$store.getters["tracks/getMovingDevices"].length > 0){
        this.zoomExtendMarkers(this.markerList);
      }else{
        this.zoomExtendMarkers(this.placeList);
      }




      // set the dimensions of the div
      if (this.$refs.panel) {
        this.panelHeight = this.$refs.panel.clientHeight;
        this.panelWidth = this.$refs.panel.clientWidth;
      }


      this.mapReady = true;
      this.isLoading = false;
    },
    zoomExtendVehicles: function() {

    },

    zoomExtendMarkers: function(theArray) {
      if (theArray == undefined) return;
      let bbox;
      let tempArrayCoordinates = [];

      theArray.forEach(e => {
        tempArrayCoordinates.push([e.getLngLat().lng,e.getLngLat().lat])
      })

      if(tempArrayCoordinates.length == 0){
        return;
      }

      if(tempArrayCoordinates.length > 1){
        let line = turf.lineString(tempArrayCoordinates,{name:"lineFromMarkers"});
        bbox = turf.bbox(line);
        this.mapDashboard.fitBounds(bbox, {
          padding: 40
        })
      }else{
        this.mapDashboard.flyTo({
          center: tempArrayCoordinates[0],
          essential:true,
        })
      }
    },



    toggleMarkers: function() {
      this.showLabel = !this.showLabel;
      this.markerList.forEach((vehicleMarker) => {
        vehicleMarker.togglePopup();
      })
    },





    togglePlaceMarkers: function() {
      this.placeShowMarkers = !this.placeShowMarkers;

      if (this.placeList.length == 0){

        let temp_place_data = this.$store.state.place.place_data;
        temp_place_data.forEach((place) => {
          let group = this.$store.getters["settings/groupSettings/getGroupByID"](place.group_id) || {color:'#007900'};

          // create place marker
          let tempPlaceMarker = new mapboxgl.Marker({ color: group.color });
          tempPlaceMarker.setLngLat([place.location.lon, place.location.lat])
          // create popup
          let tempPopUp = new mapboxgl.Popup();
          tempPopUp.setHTML( `<span style="font-weight: bold"> ${place.name} </span>${(place.description != null)?'<br/><span>'+place.description+'</span>':''}  ${(place.street != null)?'<br/><span style="color:#999999">'+place.street+'</span>':''} ${(place.group_id != null)?'<br/><div style="color:#FFFFFF;background-color: #999999; padding: 0px 8px; border-radius: 6px">'+group.name+'</div>':''}`);

          // attach to popup
          tempPlaceMarker.setPopup(tempPopUp);



          tempPlaceMarker.visible = true;
          tempPlaceMarker._element.style.visibility = "visible";

          // add marker and element to popup
          tempPlaceMarker.addTo(this.mapDashboard);
          this.placeList.push(tempPlaceMarker)
        });
      }

      if(this.placeShowMarkers == true && this.placeList.length > 100){
        this.refreshPlaceMarkers(1.0);
      }

      if(this.placeShowMarkers == false){
        this.placeList.forEach((placeMarker) => {
          placeMarker._element.style.visibility = "hidden";
          placeMarker.visible = false
          placeMarker.remove();
        })
        this.placeList = [];
      }


    },

    toggleFullscreen: function(){
      console.log("FULL SCREEN")
      // document.fullscreenEnabled
      // if this.$refs.mapDashboard
      let mapTrip = this.$refs.mapDashboard
      if(this.fullScreen){
        document.exitFullscreen();
        this.fullScreen = false;

      }else{
        mapTrip.requestFullscreen()
        this.fullScreen = true;
        // set timer for 2 minutes
      }
    },

    refreshPlaceMarkers:function(distanceThreshold){
      // let distanceThreshold = 8.0;
      console.log(`refresshing ${distanceThreshold}`)
      let vehiclePoint = turf.point;
      let markerPoint = turf.point;

      this.placeList.forEach((placeMarker) => {
        placeMarker.visible = false;
      });

      this.markerList.forEach((vehicleMarker) => {
        vehiclePoint =  turf.point([vehicleMarker.getLngLat().lng,vehicleMarker.getLngLat().lat])


        this.placeList.forEach((placeMarker) => {
          markerPoint = turf.point([placeMarker.getLngLat().lng,placeMarker.getLngLat().lat])

          let dist = turf.distance(vehiclePoint,markerPoint)
          // console.log(dist)
          if(parseFloat(dist) < distanceThreshold){
            // placeMarker._element.style.display
            placeMarker.visible = true;
            // placeMarker._element.style.display = "none";
            //placeMarker.remove();
          }//else{
          //   // placeMarker._element.style.visibility = "visible";
          //   placeMarker._element.style.display = "block"
          // }

        });

      })

      this.placeList.forEach((placeMarker) => {
        if(placeMarker.visible){
          placeMarker._element.style.display = "block"
        }else{
          placeMarker._element.style.display = "none";
        }
      });



    },


  }
};
</script>
<style>
#mapDashboard{
  height: 100%;
  width: 100%;
  background-color:#e6e4e0;
}

.markerDashboard{
  background-image: url('/static/web/markers/marker_green_translucent.png');
  background-size: cover;
  width: 75px;
  height: 75px;
  cursor: pointer;
}


#blankcontent {
  width: 100%;
  height: 100%;
  position: relative;
}

.panelTitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 30px;
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.panelContent {
  height: calc(100% - 45px);
  overflow: auto;
}

#fullScreenButton{
  position: absolute;
  top:40px;
  right:20px;
}

#placeButton {
  position: absolute;
  top:90px;
  right:20px;
}

#labelButton{
  position: absolute;
  top: 140px;
  right: 20px;
}

#extendsButton {
  position: absolute;
  top:220px;
  right:20px;
}

#refreshButton{
  position: absolute;
  top:280px;
  right:20px;
}





</style>
