<template lang="pug">
  div 
    panel-title-header(label="Issues" btnVisible btnLabel="Issues" :routeObj="{name:'issues'}")
    .PairItems
      number-guage(:value="countReportedIssues" label="Reported" )
      number-guage(:value="countAssignedIssues" label="Assigned")
      number-guage(:value="countOverdueIssues" label="Overdue")
    v-divider

</template>
<script>
import NumberGuage from "@/modules/global/components/dashboard/NumberGuage";
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";

export default {
  components: {
    PanelTitleHeader,
    NumberGuage
  },
  data: () => ({}),
  computed: {
    countReportedIssues: function() {
      return this.$store.getters["issues/getReportedIssuesByUser"](
        this.$route.params.contactID
      );
    },

    countAssignedIssues: function() {
      return this.$store.getters["issues/getReportedAssignedByUser"](
        this.$route.params.contactID
      );
    },

    countOverdueIssues: function() {
      return this.$store.getters["issues/getOverdueIssues"].length;
    }
  },

  methods: {
    onClick: function() {}
  }
};
</script>
<style scoped lang="stylus"></style>
