<template lang="pug">
  div(:style="fieldBtn" @mouseover.prevent="onMouseOver" @mouseleave.prevent="onMouseLeave" @click="$emit('click',item.icon)" )
      v-icon() {{item.icon}}
</template>
<script>
import colors from "vuetify/es5/util/colors";
export default {
  props: {
    item: Object
  },
  data: () => ({
    fieldBtn: {
      width: "30px",
      height: "30px",
      border: "1px solid #CCCCCC",
      margin: "3px 4px",
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      "background-color": "#ffffff",
      cursor: "pointer",
      color: "#CCC"
    }
  }),

  methods: {
    onMouseOver: function() {
      this.fieldBtn["background-color"] = colors.grey.lighten2;
      this.fieldBtn["color"] = this.$vuetify.theme.primary;
      this.fieldBtn["border-color"] = this.$vuetify.theme.primary;
    },

    onMouseLeave: function() {
      this.fieldBtn["background-color"] = colors.grey.lighten5;
      this.fieldBtn["color"] = "#ffffff";
      this.fieldBtn["border-color"] = "#CCCCCC";
    }
  }
};
</script>
<style scoped lang="stylus"></style>
