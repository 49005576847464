<template lang="pug">
  .pa-3()
    v-layout(row wrap)
      v-flex.px-3()
        v-card.my-3(color="white")
          .d-flex.px-4.py-4.justify-space-between()
            .title.font-weight-bold.primary--text() {{currentPlace.name}}
            div()
              v-chip( v-for="(customer_id,index) in currentPlace.customers_id" :key="index" @click="onClickCustomer(customer_id)")
                v-icon(left small) contacts
                div(v-html="getCustomerName(customer_id)")

          v-progress-linear(v-if="$store.state.place.stopsLoaded" indeterminate)
          v-data-table.elevation-1(
            :headers="headers"
            :items="$store.state.place.stops == null ? []:$store.state.place.stops",
          )
            template(v-slot:item.vehicle_id="{item}")
              vehicle-column-by-id(v-if="item.vehicle_id" :vehicle_id="item.vehicle_id")
            template( v-slot:item.min_time="{ item }")
              .caption() {{formatTheDate(item.min_time)}}

            template( v-slot:item.max_time="{ item }")
              .caption() {{formatTheDate(item.max_time)}}

            template( v-slot:item.duration="{ item }")
              .caption() {{getTimeSpent(item.duration)}}


</template>
<script>
import moment from "moment/moment";
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById";
// import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
export default ({
  components: {
    VehicleColumnById,
  },
  data:()=>({
    isLoaded: true,
    headers: [
      {
        text: "Vehicle",
        value: "vehicle_id",
      },


      {
        text: "Stop Time",
        value: "min_time",
      },
      {
        text: "Start Time",
        value: "max_time",
      },
      {
        text: "Duration",
        value: "duration",
      },



    ],

  }),
  computed:{
    currentPlace: function (){
      return this.$store.getters["place/getCurrentPlace"]
    }
  },
  methods:{
    onClick: function() {
      this.$router.push({
        name: "customerDetailsForm",
        params: {contactID: this.currentCustomer.id}
      }).catch(()=>{});
    },

    formatTheDate:function(theDate){
      return moment(theDate).format("ddd, Do MMM YYYY,  h:mm.ss a")
    },

    getCustomerName: function(customer_id){
      let customer = this.$store.getters["customer/getCustomerByID"](customer_id)
      return `${customer.reference_id == null?'':customer.reference_id+' &mdash; '}${customer.name}`
    },

    onClickCustomer: function(customer_id){
      this.$store.commit("customer/setCurrentIndexByID",customer_id)
      this.$router.push({
        name: "customerDashboard",
        params: {contactID: customer_id}
      }).catch(()=>{});
    },

    getTimeSpent:function(theMicroseconds) {
      let microseconds = moment
        .utc(
          moment
            .duration(theMicroseconds / 1000000000, "seconds")
            .asMilliseconds()
        )


      let hours = microseconds.hours() > 0 ? " "+microseconds.hours()+"h" : ""
      let minutes = microseconds.minutes() > 0 || microseconds.hours() > 0 ? " " + microseconds.minute()+ "m":"";
      let seconds = microseconds.seconds() > 0 || microseconds.minutes() >0 || microseconds.hour() > 0 ? " " + microseconds.seconds()+"s":"";

      return  hours + minutes + seconds;
    },



  },
  mounted(){


  }
})
</script>


<style scoped lang="stylus">

</style>