<template lang="pug">
  v-text-field(
    v-model="computedValue" 
    :label="schema.label" 
    :type="getType"
    :hint="schema.hint" 
    :persistent-hint="schema.hint != null"
    :counter="schema.maxlength != null"
    :maxlength="schema.maxlength"
    :rules="[requiredRule]"
    :disabled="$store.state.workflow.formBuilder.disableAllCompoents"
    @keypress="integerNumbers($event)"

    )

</template>
<script>
import SETTINGS from "@/modules/workflow/formBuilder/store/formSettings";

export default {
  props: {
    schema: Object
  },
  data: () => ({}),
  computed: {
    requiredRule: function() {
      return this.schema.required ? value => !!value || "Required." : false;
    },

    emailRule: function() {
      return this.schema.required ?  v =>  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid" : false;
    },


    numberRule: function() {
      return this.schema.isNumber ? value => /^-?[1-9]\d{0,1}(\.[1-9]{1})?$/.test(value) || "Number is Required": false;
    },

    getType:function(){
      if(this.schema.type == 'decimal'){
        return 'number'
      }
      return this.schema.type;
    },

    computedValue: {
      get: function() {
        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id);
      },

      set: function(theValue) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }
  },

  methods: {
    integerNumbers:function(event){
      // ignore the key "e" and "." from numbers
      if(this.schema.type == 'number'){
        if (/e/.test(event.key) || /\./.test(event.key) ) {
          event.preventDefault();
        } else {
          return true

        }
      }
    }
  },

  mounted() {
    // console.log(`schema is: ${this.schema}`)
    let theValue = this.computedValue;

    if (theValue == null) {
      if (this.schema.default != null) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: this.schema.default
        });
      }
    }
  }
};
</script>
<style scoped lang="stylus"></style>
