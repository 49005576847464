<template>
  <div>Blank</div>
</template>

<script>
export default {
  name: "BlankPage"
}
</script>

<style scoped>

</style>