<template lang="pug">
  div
    // Tabs Selector
    .white.pt-2
      v-tabs.tabs(v-model='active' height='32' left='' slider-color='primary')
        v-tab.tabs Items List
        v-tab-item
          items-list-layout
        //- v-tab.tabs Manage Items
        //- v-tab-item Todo: Bulk Manage Items
        //- v-tab.tabs Purchase Orders
        //- v-tab-item
          purchase-order-layout
</template>

<script>
import ItemsListLayout from "@/modules/inventory/items/layouts/ItemsListLayout";
import PurchaseOrderLayout from "@/modules/inventory/purchase_orders/layouts/PurchaseOrderLayout";

export default {
  components: {
    ItemsListLayout,
    PurchaseOrderLayout
  },

  computed: {
    active: {
      get: function () {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          tabIndex = tabIndex > 2 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function (value) {
        this.$route.params.tabID = value;
      }
    }
  }
};
</script>

<style scoped></style>
