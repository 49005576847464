<template lang="pug">
.Item
  .smallLetters(:style="titleStyle()")
    | {{item.name}}
  .caption.grey--text.text--darken-1
    | Reported on
    span.grey--text.text--darken-4.font-weight-bold 
      | {{item.reportDate | moment("Do MMM YYYY") }} &nbsp;
    | by &nbsp;
    span.grey--text.text--darken-1
      | {{item.reportedBy}}
  .caption.grey--text.text--darken-2
    | {{item.description}}
  .caption.grey--text
    | status: 
    span.font-weight-bold(:style="titleStyle()") {{item.status}}
  div
    v-chip(v-for="(tag,index) in item.labels" :key="index" small) {{tag}}
    

  
</template>
<script>
export default {
  props: {
    item: Object
  },
  data: () => ({}),

  methods: {
    titleStyle: function() {
      let styleObject = {};
      switch (this.item.status) {
        case "open":
          styleObject.color = this.$vuetify.theme.primary;
          break;
        case "resolved":
          styleObject.color = this.$vuetify.theme.info;
          break;
        case "overdue":
          styleObject.color = this.$vuetify.theme.error;
          break;
      }

      return styleObject;
    }
  }
};
</script>
<style scoped lang="stylus">
.smallLetters {
  font-size 14px;
}

.Item {
  border-bottom 1px solid #CCCCCC
  padding-bottom 10px
  margin-bottom 10px
  padding-left 20px
  padding-right 20px
}
</style>
