<template lang="pug">
div
  .white.pt-2
    v-tabs.tabs(left='', height='32', slider-color='primary', v-model='active')
      v-tab.tabs(@click="setTabID(0)")  Fuel Log
      v-tab-item
        fuel-list-layout
      v-tab.tabs(@click="setTabID(1)")   Expenses
      v-tab-item
        expense-layout()
      v-tab.tabs(@click="setTabID(2)")   Fuel Cards
      v-tab-item
        fuel-manage-cards

</template>

<script>
import FuelListLayout from "@/modules/fuel/layouts/FuelListLayout";
import FuelManageCards from "@/modules/fuel/layouts/FuelManageCards";
import ExpenseLayout from "@/modules/expense/layouts/ExpenseLayout.vue";
export default {
  components: {
    ExpenseLayout,
    FuelListLayout,
    FuelManageCards
  },
  computed: {
    active: {
      get: function() {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          tabIndex = tabIndex > 2 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function(value) {
        this.$route.params.tabID = value;
      }
    }
  },

  methods: {
    setTabID(value) {
      this.$router.push({name: "fuels", params: {tabID: value}}).catch(()=>{});
    }
  }
};
</script>

<style scoped></style>
