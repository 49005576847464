<template lang="pug">
div(v-if="isLoaded")
  .btnArrangement 
    v-btn(small color="primary" outlined dark class="mb-2" @click="cancel") cancel
    v-btn(small color="primary" dark class="mb-2" @click="save") Save
  form-sub-header Billing Settings
  v-container.white(grid-list-xs)
    form-divider BILLING DETAILS
    v-layout(row, wrap)
      
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='organizationName', hint='Organization Name', :persistent-hint='false', label='Organization Name', required)
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='contactPersonfirst_name', hint='First Name', :persistent-hint='false', label='Fist Name', required)
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='contactPersonlast_name', hint='Last Name', :persistent-hint='false', label='Last Name', required,)
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='mobileNo', label='Mobile Number', hint='Mobile Number', :persistent-hint='true', required)
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='billingEmail', label='Email', hint='Email', :persistent-hint='true', required)
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='copyEmails', label='Copy Emails', hint="All billing emails will be CC'd to the following addresses. Separate multiple emails with a comma.", :persistent-hint='true', required)
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='phoneNumber', label='Office Number', hint='Office Number', :persistent-hint='true', required)
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='streetAddress', hint='Street Address', :persistent-hint='true', label='Street Address', required)
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='address', hint='Postal Address', :persistent-hint='true', label='Postal Address', required)
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='code', hint='Postal Code', :persistent-hint='true', label='Zip Code', required)
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='city', hint='City', :persistent-hint='true', label='City', required)
      v-flex(xs12, md6, xl6)
        v-text-field(v-model='region', hint='County/Region', :persistent-hint='true', label='Region', required)
      v-flex(xs12, md6, xl6)
        v-select(v-model='country', :items='$store.state.settings.countryTypes', hint='Country', :persistent-hint='true', label='Country')
      v-flex(xs12, md6, xl6)
          LocationSelect(v-model='location' :width="400" :height="200" label="Location" description="Select you location")
  v-layout.ma-2(justify-end)
    v-btn.mb-2(small, color='primary',outlined, dark, @click='cancel') Cancel
    v-btn.mb-2(small, color='primary', dark, @click='save') Save
</template>
<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import { createHelpers } from "vuex-map-fields";
import LocationSelect from "@/modules/global/components/forms/LocationSelect";
const { mapFields } = createHelpers({
  getterType: "settings/billingSettings/getBillingSettings",
  mutationType: "settings/billingSettings/updateBillingSettings"
});

export default {
  components: {
    FormSubHeader,
    FormDivider,
    LocationSelect
  },
  data: () => ({
    isLoaded: false
  }),

  computed: {
    ...mapFields({
      organizationName: "organization_name",
      contactPersonfirst_name: "contact_person_first_name",
      contactPersonlast_name: "contact_person_last_name",
      mobileNo: "mobile_no",
      billingEmail: "billing_email",
      copyEmails: "copy_emails",
      phoneNumber: "phone_number",
      streetAddress: "street_address",
      address: "address",
      code: "code",
      city: "city",
      region: "region",
      country: "country",
      location: "location",
      account: "account",
      accountId: "account_id"
    })
  },
  mounted() {
    this.$store
      .dispatch("settings/billingSettings/fetchBillingSettings")
      .then(() => {
        this.isLoaded = true;
      });
  },

  methods: {
    save: function() {
      this.$store
        .dispatch("settings/billingSettings/saveBillingSettings")
        .then(data => {
          console.log(data);
        });
    },

    cancel: function() {
      console.log("Cancel");
    }
  }
};
</script>

<style scoped lang="stylus">
.btnArrangement {
  display: flex;
  justify-content: flex-end;
}
</style>
