<template lang="pug">
  div(v-if="$store.state.vehicles.devices.device_data")
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Devices
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item-group( color="primary")
                v-list-item(v-for='item in items', :key='item.title')
                  v-list-item-title {{ item.title }}
        v-btn.ma-2.mr-5(small, color='primary', dark, @click='openEdit') Add Device
    v-divider
    v-layout.ma-4(row align-center)
      v-layout.mx-3(align-end fill-width row)
        v-text-field.mr-3(v-model='searchFilter', label='Search Filter', persistent-hint, required, :prepend-icon="'search'" )
      v-layout()
        span.primary--text 1 filter
        span.pl-1.grey--text.text--darken-1 applied
        a().px-3.grey--text.text--darken-1 clear all
    
    v-divider
    .grey.lighten-4.py-3
    v-divider
    device-table()
    v-divider
</template>
<script>
import DeviceTable from "@/modules/vehicles/components/tables/DevicesTable";
export default {
  components: {
    DeviceTable
  },

  data: () => ({
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ],
    searchFilter: ""
  }),

  methods: {
    openEdit: function() {
      this.$store.dispatch("vehicles/devices/addDevice").then(() => {
        let currentObject = this.$store.getters[
          "vehicles/devices/getCurrentDevice"
        ];

        this.$router.push({
          name: "deviceForm",
          params: { vehicleID: currentObject.id }
        }).catch(()=>{});
      });
    },

    showDialog: function() {},

    closeDialog: function(value) {
      console.log(value);
    }
  },

  mounted() {
    this.$store.dispatch("vehicles/devices/loadAllDevices").then(() => {
      console.log("loaded devices");

      console.log(this.$store.state.vehicles.devices.device_data);
    });
  }
};
</script>
<style scoped lang="stylus"></style>
