import { render, staticRenderFns } from "./FieldComponentMenu.vue?vue&type=template&id=58ad3335&scoped=true&lang=pug"
import script from "./FieldComponentMenu.vue?vue&type=script&lang=js"
export * from "./FieldComponentMenu.vue?vue&type=script&lang=js"
import style0 from "./FieldComponentMenu.vue?vue&type=style&index=0&id=58ad3335&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58ad3335",
  null
  
)

export default component.exports