<template lang="pug">
.topiDivider(:style="{ 'border-bottom-color': this.$vuetify.theme.primary }" :class="disabled?'grey--text':'primary--text'")
  slot

</template>

<script>
export default {
  props:{
    disabled:{
      type:Boolean,
      default: false
    }
  },
  data: () => ({})
};
</script>

<style scoped>
.topiDivider {
  border-bottom: 2px solid;

  margin-top: 20px;
  font-size: 18px;
}
</style>
