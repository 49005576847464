<template lang="pug">
  #blankcontent()
    .panelTitle() Tasks
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-space-around.align-center.fill-height()
        .d-flex.flex-column.align-center()
          .tickerTitle.secondary--text() {{complete}}
          .tickerCaption.grey--text.text--darken-1() Completed Tasks
        .d-flex.flex-column.align-center()
          .tickerTitle.primary--text() {{incomplete}}
          .tickerCaption.grey--text.text--darken-1() Incomplete tasks
        .d-flex.flex-column.align-center()
          .tickerTitle.secondary--text() {{overdue}}
          .tickerCaption.grey--text.text--darken-1() Overdue Task
        .d-flex.flex-column.align-center()
          .tickerTitle.primary--text() {{total}}
          .tickerCaption.grey--text.text--darken-1() Total Task


</template>
<script>


export default {
  data: () => ({
    complete:0,
    incomplete:0,
    overdue:0,
    total:0

  }),
  mounted() {


    

    },

  computed: {

  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.tickerTitle {
  font-size: 45px;
}

.tickerCaption {
  margin-top: -10px;
  font-size: 12px;
}
</style>
