<template lang="pug">
  v-toolbar(:color="this.mode=='light' ? 'primary':'grey lighten-3'" :dark="this.mode=='light'" dense flat)
    div.subheading(:class="this.mode=='light'?'font-weight-bold':'font-weight-normal'") {{label}}
    v-spacer
    v-btn(v-if="btnVisible" outlined small  @click="onClick")
      v-icon.mr-2(small) {{btnIcon}}
      | {{btnLabel}}
</template>
<script>
export default {
  props: {
    label: String,
    btnVisible: {
      type: Boolean,
      default: false
    },
    btnLabel: {
      type: String,
      default: "edit"
    },
    btnIcon: {
      type: String,
      default: "edit"
    },
    routeObj: {
      type: Object,
      default: null
    },
    mode:{
      type: String,
      default: 'light'
    }
  },

  data: () => ({}),
  methods: {
    onClick: function() {
      if (this.routeObj != null) {
        this.$router.push(this.routeObj).catch(()=>{});
      }
    }
  }
};
</script>
<style scoped lang="stylus"></style>
