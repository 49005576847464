<template lang="pug">
  .ma-2
    v-tabs.tabs(slider-color='primary',  background-color="grey lighten-3" :height="35" )
      v-tab.tabs
        | Details
      v-tab-item
        overview-vehicle-details
      v-tab.tabs 
        | Purchase
      v-tab-item
        overview-vehicle-purchase
      v-tab.tabs 
        | Lease
      v-tab-item
        overview-vehicle-lease
    
</template>
<script>
import OverviewVehicleDetails from "@/modules/vehicles/layouts/overview/OverviewVehicleDetails";
import OverviewVehiclePurchase from "@/modules/vehicles/layouts/overview/OverviewVehiclePurchase.vue";
import OverviewVehicleLease from "@/modules/vehicles/layouts/overview/OverviewVehicleLease";

export default {
  components: {
    OverviewVehicleDetails,
    OverviewVehiclePurchase,
    OverviewVehicleLease
  },
  data: () => ({})
};
</script>
<style scoped lang="stylus"></style>
