<template lang="pug">
  div()
    .d-flex
      .grey--text() {{schema.label}} =  &nbsp;
      div() {{computedValue}}
    .caption.grey--text() {{schema.hint}} [{{schema.type}}]


</template>

<script>
import SETTINGS from "@/modules/workflow/formBuilder/store/formSettings";

export default {
  props: {
    schema: Object
  },
  data: () => ({

  }),
  computed: {
    computedValue: {
      get: function() {
        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id);
      },

      set: function(theValue) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }

  }
}
</script>

<style scoped>

</style>