<template lang="pug">
  div
    v-container.pt-0(grid-list-sm)
      v-layout(row, wrap, justify-start)
        v-flex.pl-5(xs12, md6, xl6)
          v-radio-group(v-model='radioGroup')
            | Primary Meter
            v-radio(label='Miles', value='miles')
            v-radio(label='Kilometers', value='kilometers')
            v-radio(label='Hours', value='hours')
          v-radio-group(v-model='radioGroup')
            | Fuel Unit
            v-radio(label='Litres', value='miles')
            v-radio(label='Galons', value='kilometers')
        v-flex(xs12, md6, xl6)
          v-text-field(v-model='vehicleData.currentReading', label='Current Readings', hint='Current  Readings', persistent-hint)
          v-text-field(v-model='vehicleData.averageUsage', label='Average Usage', hint='Average Usage', persistent-hint)

</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";

export default {
  components: {
    FormDivider
  },

  data: () => ({
    radioGroup: 1,
    vehicleData: {},
    drivetype: ["Rear Wheel Drive", "Front Wheel Drive"],
    brakeSystemType: ["Disk Brakes", "Drum Brakes"],

    // green, blue, orange, red, gray
    fuelType: ["Diesel", "Petrol", "Propane", "Compressed Natural Gas (CNG)"]
  })
};
</script>

<style scoped>
.topiDivider {
  color: #aa00cc;
  border-bottom: 2px solid #aa00cc;
  margin-top: 20px;
  font-size: 18px;
}
</style>
