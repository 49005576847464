<template lang="pug">
div
  panel-title-header(label="Vehicle Issues" mode="dark")
  .PairItems
    number-guage(:value="countOpenIssues" :label="'Open'" )
    number-guage(:value="countOverdueIssues" :label="'Overdue'")
  v-divider
  div.my-2
    issue-item(v-for="(item,index) in getVehicleIssues" :key="index" :item="item")

</template>
<script>
import NumberGuage from "@/modules/global/components/dashboard/NumberGuage";
import IssueItem from "@/modules/issues/components/ui/IssuesItem";
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";
export default {
  components: {
    PanelTitleHeader,
    NumberGuage,
    IssueItem
  },

  data: () => ({}),

  methods: {
    getVehicleID: function() {
      return this.$route.params.vehicleID;
    }
  },

  computed: {
    getVehicleIssues: function() {
      // return this.$store.getters["issues/getVehiceIssues"](this.getVehicleID());
      return 0;
    },

    countOpenIssues: function() {
      return (
        this.$store.state.issues.issue_data.length - this.countOverdueIssues
      );
    },

    countOverdueIssues: function() {
      return this.$store.getters["issues/getOverdueIssues"].length;
    }
  },

  mounted() {
    // console.log(this.getVehicleID())
    // console.log(this.$store.getters["issues/getVehiceIssues"](0))
  }
};
</script>
<style scoped lang="stylus">
.PairItems {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
