<template lang="pug">
div
  v-divider
  v-container.ma-2(fluid)
    v-layout.mx-1(row, wrap)
      h2 Fuel Cards
      v-spacer
      .text-xs-center
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
              v-icon(dark) keyboard_arrow_down
          v-list
            v-list-item-group(v-model="action" color="primary")
              v-list-item(v-for='item in items', :key='item.title')
                v-list-item-title {{ item.title }}
      v-btn.ma-2(small, color='primary', dark, @click='openEdit') Add Fuel Card
  v-divider
  v-container.mx-3(fluid)
    v-row()
      v-col(cols="12" xs="12" md="5" xl="3")
        .d-flex.align-center()
          v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" )
          v-btn(v-if="search.length > 0" text outlined small @click="search=''") clear all


  v-divider
  .grey.lighten-4.py-3
  v-divider
  fuel-card-table()

  v-divider
</template>

<script>
import FormHeaderTotal from "@/modules/global/components/forms/FormHeaderTotal";
import FuelCardTable from "@/modules/fuel/components/tables/FuelCardTable";
export default {
  components: {
    FormHeaderTotal,
    FuelCardTable
  },
  data: () => ({
    search: "",
    action: null,
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      },
      {
        title: "Action 3"
      },
      {
        title: "Action 4"
      }
    ],

    vehiclesFilter: "",

    date: null,
    menu: false,
    modal: false,
    menu2: false
  }),

  mounted() {
    this.$store.dispatch("fuelCard/loadAllFuelCards");
  },

  methods: {
    openEdit: function() {
      this.$store.dispatch("fuelCard/addFuelCard").then(() => {
        this.$router.push({
          name: "fuelCardForm",
          params: {
            fuelID: this.$store.getters["fuelCard/getCurrentFuelCard"].id
          }
        }).catch(()=>{});
      });
    }
  }
};
</script>

<style scoped></style>
