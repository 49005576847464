<template lang="pug">
  #blankcontent()
    .panelTitle() Submissions
    v-divider.mx-2()
    line-chart(v-if="isLoaded" :style="{height:`${clientHeight-40}px`, width: `100%`}" :chartdata="getDataSets" :options="chartOption")

</template>
<script>
import LineChart from "@/modules/global/components/charts/LineChart";
import axios from "axios";
export default {
  components: {
    LineChart
  },
  props: {
    clientHeight: Number
  },
  mounted() {
    axios.get(`/api/inspectionForm/dashboard/day/${this.$route.params.inspectionID}`).then(response => {

      response.data.forEach(element =>{
        this.theLabels.push(element.day);
        this.theData.push(element.count);
      })
      this.isLoaded = true;
    });
  },
  data: () => ({
    theLabels:[],
    theData:[],
    isLoaded:false,
    chartOption: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize:1
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ]
      },

      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 20,
          bottom: 0
        }
      },

    }
  }),


  computed: {
    getDataSets: function() {
      let data = { x: this.theLabels, y: this.theData };
      let obj = {
        labels: data.x,
        datasets: [
          {
            label: "Records ",
            data: data.y,
            backgroundColor: `rgba(0, 0, 0, 0.05)`
          }
        ]
      };
      obj.datasets[0].borderColor = this.$vuetify.theme.themes.light.primary;
      obj.datasets[0].lineTension = 0;

      return obj;
    }
  },
  methods: {
    generator() {
      let dayList = [];
      for (let i = 0; i < 7; i++) {
        dayList.push(
          this.$moment(new Date())
            .subtract(i, "day")
            .format("ddd")
        );
      }

      let valueList = [];
      for (let i = 0; i < 7; i++) {
        valueList.push(Math.round(Math.random() * 100));
      }

      return { x: dayList, y: valueList };
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight bold
  padding: 5px 30px;
  height:30px;
  display: flex;
  width: 100%
  justify-content: space-between;
}

.lineChartPanel {
  height: calc(100% - 45px);
}
</style>
