import { render, staticRenderFns } from "./FormSubHeader.vue?vue&type=template&id=04336340&scoped=true"
import script from "./FormSubHeader.vue?vue&type=script&lang=js"
export * from "./FormSubHeader.vue?vue&type=script&lang=js"
import style0 from "./FormSubHeader.vue?vue&type=style&index=0&id=04336340&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04336340",
  null
  
)

export default component.exports