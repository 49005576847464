import AddCommentButton from "@/modules/inspections/components/formDesigner/component/AddCommentButton";
import UploadBlob from "@/modules/global/components/forms/UploadBlob";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";


export const inspectionFillMixin = {
    components: {
        AddCommentButton,
        UploadBlob,
        ImageThumbnail
    },

    props: ["schema", "dataValue", "index","disabled"],

    data: () => ({
        comment: null,
        showCommentInput: false,
        showBlobInput: false,
        theValue: null,
        errorMessages: [],

        theComments: null,
        theBlobs: null,
        theValid: null,
        thePassed: null,
    }),
    mounted() {
        this.theValue = this.dataValue.value;
        this.theComments = this.dataValue.comments;
        this.theBlobs = this.dataValue.blobs;
        this.theValid = this.dataValue.valid;
        this.thePassed = this.dataValue.passed;
    },
    computed: {
        requiredRule: function() {
            return this.schema.required ? value => !!value || "Required." : false;
        },

        comments: {
            get: function () {
                return this._.clone(this.dataValue.comments);
            },
            set: function (theComments) {
                console.log(theComments)
                this.theComments = theComments;
                this.update();
            },
        },

        blobs: {
            get: function () {
                return this._.clone(this.dataValue.blobs);
            },
            set: function (theBlob) {
                this.theBlobs = theBlob;
                this.update();
            }
        },
    },
    methods: {


        update:function(){
            this.theValid = true;
            this.thePassed = true;
            if (this.schema.required && this.theValue == null) {
                this.theValid = false;
                this.thePassed = false;
            }



            this.$emit("onChange",{
                id: this.schema.id,
                value: {valid: this.theValid,passed: this.thePassed, value: this.theValue, comments: this.theComments, blobs: this.theBlobs},
                index: this.index
            })
        },

        onShowCommentInput: function () {
            this.showCommentInput = true;
            this.$nextTick(()=>{
                this.$refs.comment.focus();
            })

        },

        nullify: function(){
            this.theValue = null;
            this.update();
        },

        cancel: function () {
            this.comment = null;
            this.showCommentInput = false;
        },


        save: function () {
            let theComments = [];
            if (this.comments != undefined || this.comments != null) {
                theComments = this.comments;
            }
            theComments.unshift({
                value: this.comment,
                time: new Date().getTime(),
                by_id: this.$store.state.profile.userInfo.contact.id,
                by: `${this.$store.state.profile.userInfo.contact.first_name[0]}${(this.$store.state.profile.userInfo.contact.last_name != null) ? this.$store.state.profile.userInfo.contact.last_name[0] : ''}`
            })
            this.comments = theComments;
            this.cancel();
        },
    }
}