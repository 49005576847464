import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const ISSUE_API = "/issue";

// ----------------------- STATE ------------------------- //
const state = {
    issue_data: [],
    currentIndex: -1,
    selectedIssues: [],
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getIssue: state => getField(state.issue_data[state.currentIndex]),

    getCurrentIssue: state => {
        return state.issue_data[state.currentIndex];
    },

    getSelectedIssues: state => {
        return state.selectedIssues;
    },
    getIssueByStatus: state => status => {
        let tempList = [];
        switch (status) {
            case "open":
                tempList = state.issue_data.filter(
                    item =>
                        item.closed_date_time == null && item.service_date_time == null
                );
                break;
            case "overdue":
                tempList = state.issue_data.filter(item => {
                    if (item.due_date_time != null) {
                        return (
                            new Date(item.due_date_time).getTime() < new Date().getTime()
                        );
                    } else {
                        return false;
                    }
                });
                break;
            case "resolved":
                tempList = state.issue_data.filter(
                    item => item.service_date_time != null
                );
                break;
            case "closed":
                tempList = state.issue_data.filter(
                    item => item.closed_date_time != null
                );
                break;
        }
        return tempList;
    },

    getVehicleFaults: state => vehicleID => {
        return state.issue_data.filter(item => item.vehicle_id == vehicleID);
    },

    getVehiclePendingFaults: state => vehicleID => {
        // todo: getVehiclePeningFaults
        return state.issue_data.filter(item => item.vehicle_id === vehicleID)
            .length;
    },

    getReportedIssuesByUser: state => contactID => {
        return state.issue_data.filter(item => item.reported_by_id == contactID)
            .length;
    },
    getReportedAssignedByUser: state => contactID => {
        return state.issue_data.filter(item => item.assigned_to_id == contactID)
            .length;
    },

    getVehicleOpenFaults: state => vehicleID => {
        // todo: get Open Faults
        return state.issue_data.filter(item => item.vehicle_id === vehicleID)
            .length;
    },

    getOverdueIssues: state => {
        return state.issue_data.filter(item => {
            if (item.due_date_time) {
                let theDate = new Date(item.due_date_time).getTime();
                let currentDate = new Date().getTime();
                return theDate < currentDate;
            } else {
                return false;
            }
        });
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateIssue(state, payload) {
        updateField(state.issue_data[state.currentIndex], payload);
    },

    deleteImage(state, payload) {
        let index = state.issue_data[state.currentIndex].photos_id.findIndex(
            item => item === payload
        );
        state.issue_data[state.currentIndex].photos_id.splice(index, 1);
    },

    deleteDocument(state, payload) {
        let index = state.issue_data[state.currentIndex].documents_id.findIndex(
            item => item === payload
        );
        state.issue_data[state.currentIndex].documents_id.splice(index, 1);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.issue_data.findIndex(issue => issue.id == theID);
    },

    loadAllIssues(state, payload) {
        state.issue_data = payload.data;
        state.currentIndex = 0;
    },

    loadVehicleIssues(state, payload) {
        if (payload.data.length > 0) {
            state.currentIndex = 0;
        }
        state.issue_data = payload.data;
    },

    loadContactIssues(state, payload) {
        if (payload.data.length > 0) {
            state.currentIndex = 0;
        }
        state.issue_data = payload.data;
    },

    addIssue(state, payload) {
        state.issue_data.push(payload.data);
        state.currentIndex = state.issue_data.length - 1;
    },

    saveIssue(state, payload) {
        state.issue_data[state.currentIndex] = payload.data;
    },

    fetchIssue(state, payload) {
        if (state.currentIndex == -1) {
            state.issue_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.issue_data[state.currentIndex] = payload.data;
        }
    },

    deleteIssue(state, payload) {
        state.currentIndex = state.issue_data.findIndex(
            issue => issue.id == payload.data.id
        );
        state.issue_data.splice(state.currentIndex, 1);
    },

    openIssue(state, payload) {
        state.currentIndex = state.issue_data.findIndex(
            issue => issue.id == payload.data.id
        );
        state.issue_data[state.currentIndex] = payload.data;
    },

    closeIssue(state, payload) {
        state.currentIndex = state.issue_data.findIndex(
            issue => issue.id == payload.data.id
        );
        state.issue_data[state.currentIndex] = payload.data;
    },

    cleanDataBeforeSaving(state) {
        state.issue_data[state.currentIndex].odometer = parseInt(
            state.issue_data[state.currentIndex].odometer
        );
        state.issue_data[state.currentIndex].vehicle = null;
        state.issue_data[state.currentIndex].reported_by = null;
        state.issue_data[state.currentIndex].assigned_to = null;
        state.issue_data[state.currentIndex].tags = null;
    },

    reset(state) {
        state.issue_data = []
        state.currentIndex = -1
    },

    setSelectedIssues(state, payload) {
        state.selectedIssues = payload;
    },

    deleteSelectedIssues(state, payload) {
        payload.data.forEach(element => {
            let theIndex = state.issue_data.findIndex(
                (issue) => issue.id == element
            );
            state.issue_data.splice(theIndex, 1);
        })
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllIssues({commit}) {
        try {
            commit("loadAllIssues", await Repository.get(`${ISSUE_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async loadVehicleIssues({commit}, vehicle_id) {
        try {
            console.log(vehicle_id);
            commit(
                "loadVehicleIssues",
                await Repository.get(`${ISSUE_API}/vehicle/${vehicle_id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async loadContactIssues({commit}, contact_id) {
        try {
            commit(
                "loadContactIssues",
                await Repository.get(`${ISSUE_API}/contact/${contact_id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async addIssue({commit}) {
        await Repository.get(`${ISSUE_API}/new`)
            .then(response => {
                commit("addIssue", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveIssue({state, commit}) {
        try {
            console.log(state.issue_data[state.currentIndex]);
            commit("cleanDataBeforeSaving");

            commit(
                "saveIssue",
                await Repository.post(
                    `${ISSUE_API}`,
                    state.issue_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchIssue({commit}, payloadID) {
        try {
            commit("fetchIssue", await Repository.get(`${ISSUE_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteIssue({commit}, payloadID) {
        try {
            commit(
                "deleteIssue",
                await Repository.delete(`${ISSUE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async closeIssue({commit}, payloadID) {
        try {
            commit(
                "closeIssue",
                await Repository.get(`${ISSUE_API}/close/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async openIssue({commit}, payloadID) {
        try {
            commit(
                "openIssue",
                await Repository.get(`${ISSUE_API}/open/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteSelectedIssues({commit}, deleteSelectedIDs) {
        try {
            let idString = ''
            deleteSelectedIDs.forEach(element => {
                idString += "&id="+element
            })
            commit(
                "deleteSelectedIssues",
                await Repository.delete(`${ISSUE_API}/deleteSelected?${idString}`)
            );
        } catch (error) {
            console.log(error);
        }

    },

    async searchIssues({commit}, searchTerm) {
        try {
            commit(
                "loadAllIssues",
                await Repository.get(`${ISSUE_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
