<template lang="pug">
  attach-form(:schema="getBlobSchema" :data="{value:computedValue}" @onChange="save"  @onMenuAction="onMenuAction" )
</template>
<script>

import SETTINGS from "@/modules/workflow/formBuilder/store/formSettings";
import AttachForm from "@/modules/global/components/forms/AttachFillForm";

export default {
  components:{
    AttachForm
  },

  props: {
    schema: Object
  },
  data: ()=>({

    blob_ids:[]

  }),
  computed:{

    getBlobSchema: function(){
      let blobSchema = {};
      blobSchema.name = "Attach";
      blobSchema.id = null;
      blobSchema.value = [];
      blobSchema.label = this.schema.label;
      blobSchema.hint = this.schema.hint;
      blobSchema.type = this.schema.type;
      blobSchema.preview_size = this.schema.preview_size;
      blobSchema.multiple = this.schema.multiple;


      return blobSchema;
    },

    computedValue: {
      get: function() {

        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id)
      },

      set: function(theValue) {
        console.log("-----------------------------");
        console.log(theValue);
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }
  },

  methods:{
    save: function(theObject) {
      console.log(theObject)
      this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
        schema_id: this.schema.id,
        value: theObject.value
      });
    },
    onMenuAction: function(value) {
      switch (value.action) {
        case "delete":
          console.log(`delete: ${value.theObject.id}`)
          break;
      }
    },
  }
};
</script>
<style scoped lang="stylus">

</style>
