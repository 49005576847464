import { render, staticRenderFns } from "./CameraThumbnail.vue?vue&type=template&id=fc4bab06&scoped=true&lang=pug"
import script from "./CameraThumbnail.vue?vue&type=script&lang=js"
export * from "./CameraThumbnail.vue?vue&type=script&lang=js"
import style0 from "./CameraThumbnail.vue?vue&type=style&index=0&id=fc4bab06&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc4bab06",
  null
  
)

export default component.exports