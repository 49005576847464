import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const CONTACT_API = "/contact";

// ----------------------- STATE ------------------------- //
const state = {
    contact_data: [],
    currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getContact: state => getField(state.contact_data[state.currentIndex]),

    getCurrentContact: state => {
        return state.contact_data[state.currentIndex];
    },

    getContactByID: state => contactID => {
        let result = state.contact_data.find(item => item.id == contactID);
        return result
    },


};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateContact(state, payload) {
        updateField(state.contact_data[state.currentIndex], payload);
    },

    deleteImage(state, payload) {
        let index = state.contact_data[state.currentIndex].images.findIndex(
            item => item === payload
        );
        state.contact_data[state.currentIndex].images.splice(index, 1);
    },

    deleteDocument(state, payload) {
        let index = state.contact_data[state.currentIndex].documents.findIndex(
            item => item === payload
        );
        state.contact_data[state.currentIndex].documents.splice(index, 1);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.contact_data.findIndex(
            contact => contact.id == theID
        );
    },

    loadAllContacts(state, payload) {
        state.contact_data = payload.data;
        state.currentIndex = 0;
    },

    addContact(state, payload) {
        state.contact_data.push(payload.data);
        state.currentIndex = state.contact_data.length - 1;
    },

    saveContact(state, payload) {
        state.contact_data[state.currentIndex] = payload.data;
    },

    fetchContact(state, payload) {
        if (state.currentIndex == -1) {
            state.contact_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.contact_data[state.currentIndex] = payload.data;
        }
    },

    deleteContact(state, payload) {
        state.currentIndex = state.contact_data.findIndex(
            contact => contact.id == payload.data.id
        );
        state.contact_data.splice(state.currentIndex, 1);
    },
    cleanDataBeforeSaving(state) {
        state.contact_data[state.currentIndex].labour_rate = parseFloat(
            state.contact_data[state.currentIndex].labour_rate
        );
    },
    reset(state) {
        state.contact_data = [];
        state.currentIndex = -1
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllContacts({commit}) {
        try {
            commit("loadAllContacts", await Repository.get(`${CONTACT_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addContact({commit}) {
        console.log("addContact Called");
        await Repository.get(`${CONTACT_API}/new`)
            .then(response => {
                console.log(response.data);
                commit("addContact", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveContact({state, commit}) {
        try {
            commit("cleanDataBeforeSaving");
            let contact = state.contact_data[state.currentIndex];
            commit("saveContact", await Repository.post(`${CONTACT_API}`, contact));
        } catch (error) {
            console.log(error);
        }
    },

    async fetchContact({commit}, payloadID) {
        try {
            commit(
                "fetchContact",
                await Repository.get(`${CONTACT_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteContact({commit}, payloadID) {
        try {
            commit(
                "deleteContact",
                await Repository.delete(`${CONTACT_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    // save and do nothing
    async saveCurrentAndDoNothing({getters}) {
        Repository.post(`${CONTACT_API}`, getters.getCurrentContact);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
