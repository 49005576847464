<template lang="pug">
    v-data-table(
      :headers="headers"
      :search="search"
      :custom-filter="reportModuleFilter"
      :items="getData"
      item-key="id"
      :loading="isLoading"
      :single-select="false"
      show-select
    )
      template(v-slot:progress)
        v-progress-linear(v-slot="progress"  height="2" :indeterminate="isLoading")

      template(v-slot:item.name="{item}")
          .d-flex.align-center.pointer(@mouseover="zColor = $vuetify.theme.themes.light.primary" @mouseleave="zColor = theBase" @click="onDownload(`/media/${item.account_id}/${item.blob.file_name}`)")
            v-icon(:color="zColor") cloud_download
            .ml-2()
              div(:style="{color:zColor}") {{item.name}}
              .caption.grey--text() {{item.description}}

      template(v-slot:item.created_at="{item}")
        .caption(v-text="getDate(item.created_at)")
      template( v-slot:item.action="{ item }")
        v-row.mr-5(justify="end")
          more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import { searchMixin } from "@/mixin/searchListMixin";
import colors from 'vuetify/lib/util/colors'
import moment from "moment";
export default {
  name: "GeneratedListReport",
  mixins: [searchMixin],
  props: ["search"],
  components: {
    MoreDropDownMenu
  },
  data: ()=>({

    isLoading: true,
    zColor:  colors.grey.darken2,
    theBase: colors.grey.darken2,
    headers:[
      { text: "Name", align: "left", value: "name"},
      { text: "Created At", align: "left", value: "created_at", },
      { text: "Job ID", align: "left", value: "job_id", },
      { text: "Action", align: "right", value: "action", },
    ],
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ],
    menuitems: [
      { title: "Download", icon: "cloud_download", action: "download" },
      { title: "Delete", icon: "delete", action: "delete" },
    ]
  }),
  computed:{
    getData: function () {

      return this.$store.state.reports.report_data;
    },


  },
  methods:{
    getDate: function(theDate){
      return moment(theDate).format("h:mm a, ddd, Do MMM YYYY")
    },
    reportModuleFilter: function(value,search,item){
      let isFound = false;
      let patt = new RegExp(search.toLowerCase(), "i")

      // Search the Object
      isFound = this.searchObject(item,patt,isFound)

      return isFound;
    },
    onDownload:function(path){
      var a = document.createElement("a");
      a.href=path;
      a.target='_blank';
      a.click();
    },
    onClick:function(value){
      switch(value.action){
        case "delete":
          confirm(
              `Are you sure you want to delete the report ${value.theObject.name}`
          ) && this.$store.dispatch("reports/deleteReport",value.theObject.id)
      }
    },
  },
  mounted() {
    this.$store.dispatch("reports/loadAllReports").then(()=>{
      this.isLoading = false;
    })
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>