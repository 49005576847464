<template lang="pug">
    #blankcontent()
        .panelTitle()
            | Last Users Activity
            v-spacer()
            div(@click="onRefresh()")
              v-icon.pointer() refresh
        v-divider.mx-2()
        .panelContent()
            v-list-item-group(v-if="items")
                v-list-item.lineVehicleStatus2(v-for="(item,index) in items" :key="index")
                    v-list-item-content()
                      .d-flex.align-center()
                        .activityCounter.grey.darken-1.caption.white--text.mr-2(v-if="totalCount" ) {{Math.round((item.count/totalCount)*100)}}%
                        .d-flex.justify-space-between(style="width:100%")
                          .body-2() {{item.first_name}} {{item.last_name}}
                          .body-2.grey--text.text--darken-2() {{getTheDate(item.last_activity)}}


</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
    props: {
        clientHeight: Number
    },

    methods: {
        getTheDate: function(theTime) {
          return moment(theTime).format("ddd, Do MMM YYYY h:mm a")
        },
        onRefresh: function () {
            axios.get("/api/dashboard/usersActivity").then(response =>{
                    this.items = response.data;
                    this.totalCount = 0;
                    this.items.forEach(element =>{
                        this.totalCount += element.count
                    })
                })
        }
    },


    mounted() {
      this.onRefresh();
    },
    data: () => ({
        items: [],
        totalCount:0,
    })
};
</script>
<style scoped lang="stylus">
.lineVehicleStatus2 {
  border-bottom: 1px solid #CCC;
  height: 50px;
}

#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 30px;
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.panelContent {
  height: calc(100% - 45px);
  overflow: auto;
}
  .activityCounter{
    border-radius 50%;
    width 36px;
    height 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold
  }
</style>
