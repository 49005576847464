<template lang="pug">
  div
    v-toolbar(color="primary" dark dense flat)
      div.subheading.font-weight-bold Critical Faults -{{countPendingFaults}}
    div(v-if="showContent")
      .PairItems
        number-guage(:value="countOpenFaults" label="Open" )
        number-guage(:value="countPendingFaults" label="Pending")
      v-divider
      div.my-2
        faults-item(v-for="(item, index) in vehicleFaults" :key="index" :item="item")
    .title.grey--text.text-xs-center.pa-3(style="height:60px" v-else )
      | No Service Reminders  
</template>
<script>
import NumberGuage from "@/modules/global/components/dashboard/NumberGuage";
import FaultsItem from "@/modules/issues/components/ui/FaultsItem";
export default {
  components: {
    NumberGuage,
    FaultsItem
  },
  data: () => ({}),

  methods: {
    getVehicleID: function() {
      return this.$route.params.vehicleID;
    }
  },

  computed: {
    countOpenFaults: function() {
      return this.$store.getters["issues/getVehicleOpenFaults"](
        this.getVehicleID()
      );
    },

    countPendingFaults: function() {
      return this.$store.getters["issues/getVehiclePendingFaults"](
        this.getVehicleID()
      );
    },

    vehicleFaults: function() {
      return this.$store.getters["issues/getVehicleFaults"](
        this.getVehicleID()
      );
    },

    showContent: function() {
      if (this.vehicleFaults == null || this.vehicleFaults == 0) {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.$store.dispatch(
      "issues/loadVehicleIssues",
      this.$route.params.vehicleID
    );
  }
};
</script>
<style scoped lang="stylus">
.PairItems {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
