<template lang="pug">
  div
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Generated Reports
        v-spacer
        .text-xs-center
          //- v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down

            v-list
              v-list-item(v-for='item in actionItems', :key='item.title' @click="onClick(item)" :disabled="$store.state.messages.selectedItems.length == 0")
                v-list-item-icon()
                  v-icon() {{item.icon}}
                v-list-item-title {{ item.title }}
          v-btn.ma-2(small,color="primary",@click="generateReport" outlined)
            v-icon.pr-2(dark) insert_chart
            | Generate Report
          v-btn.ma-2(small,color="primary",@click="createSchedule")
            v-icon.pr-2(dark) alarm
            | Create Report Schedule

    v-divider
    v-progress-linear(v-if="isGeneratingReport" :indeterminate="isGeneratingReport")
    v-layout.ma-4(row align-center)
      v-row()
        v-col(cols="12" xs="12" md="5" xl="3")
          .d-flex.align-center()
            v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" )
            v-btn(v-if="search.length > 0" text outlined small @click="search=''") clear all

    v-divider
    .grey.lighten-4.py-3
    v-divider
    generated-list-report(:search="search")


</template>

<script>

import GeneratedListReport from "@/modules/reports/components/tables/GeneratedListReport";
import Repository from "@/repository";
import moment from 'moment';
export default {

  components:{
    GeneratedListReport

  },
  data: ()=> ({
    isGeneratingReport:false,
    search: "",
    filterIndex:0,
    group:"all",
    actionItems:[
      {title:"action 1",icon:"place",value:"action1"},
      {title:"action 2",icon:"place",value:"action2"},
    ],
    toggleItems: ["All","Unread", "Read"]
  }),
  methods:{
    onClick:function(item){
      console.log(item)
    },
    createSchedule:function(){
      console.log("Create a New Schedule")
    },

    generateReport:function(){
      let from = moment().startOf('month').subtract(1, 'month').format("YYYY-MM-DD");
      let to = moment().startOf('month').format("YYYY-MM-DD")
      console.log(from)
      console.log(to)
      this.isGeneratingReport = true;
      Repository.get(`/report/monthlyReport/PDF?from=${from}&to=${to}`).then(()=>{
        this.$store.dispatch("reports/loadAllReports").then(()=>{
          this.isGeneratingReport = false;
        })
      })
    }
  }
}
</script>

<style scoped lang="stylus">

</style>