<template lang="pug">
  div
    v-select(v-model="computedValue" :label="schema.label" :items="schema.options" clearable)
</template>

<script>
export default {
  props:["dataValue","schema"],
  data:()=>({
    result:null,
  }),
  computed:{
    computedValue: {
      get: function() {
          return this.dataValue;
      },

      set: function(theValue) {
        console.log(theValue)
        this.$emit("change", {
          id: this.schema.id,
          value: theValue,
        });
      }
    }
  },
  methods:{
    saveDefault:function(){
      this.$emit("change", {
        id: this.schema.id,
        value: this.schema.options[0],
      });
    }
  },
  mounted() {
    if (this.dataValue == null && this.schema.options && this.schema.options.length > 0) {
      this.$emit("change", {
        id: this.schema.id,
        value: this.schema.options[0],
      });
    }
  }
}
</script>

<style scoped lang="stylus">

</style>