<template>
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title>Settings</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <!-- <v-btn flat>Cancel</v-btn> -->
      </v-toolbar-items>
    </v-toolbar>
    <v-container grid-list-xl>
      <v-layout row wrap>
        <v-flex xs12 md3 xl3>
          <settings-list
            :items="$store.state.settings.menuList"
            :selectedTo="selectedTo"
            @selectItem="onSelectItem"
          ></settings-list>
        </v-flex>
        <v-flex xs12 md9 xl9>
          <!-- ROUTER -->
          <router-view class="settingsBackground"></router-view>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import SettingsList from "@/modules/global/components/lists/BodyNavigationList";
// import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";

export default {
  components: {
    SettingsList
    // FormSubHeader
  },
  data: () => ({
    selectedTo: "General Settings",
    selectedRoute: "accountsettings",
    subTitle: null
  }),

  methods: {
    onSelectItem: function(item) {
      console.log("------------")
      console.log(item)
      this.$router.push({ name: item.to }).catch(()=>{});
      (this.selectedRoute = item.to), (this.subTitle = item.text);
    }
  },
  mounted() {
    // this.subTitle = this.$store.state.settings.menuList[1].children[0].text;
    //this.$router.push({name: this.$store.state.settings.menuList[1].children[1].to}).catch(()=>{})
  }
};
</script>
<style scoped>
.headerButtons {
  display: flex;
  justify-content: flex-end;
}

.settingsBackground {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>
