<template lang="pug">
  div
    v-autocomplete(
      v-model="workflowTemplates"
      :items="$store.state.workflow.workflow_data"
      :loading="isLoading" 
      :search-input.sync="search" 
      item-text="name" 
      item-value="id" 
      :label="label" 
      :placeholder= "placeholder"
      chips
      @change="onChange"
      :filter="onFilter"
      ) 
      template(slot="selection" slot-scope="data")
        v-chip.chip--select-multi(:input-value="data.selected" small)
          image-thumbnail.ml-n2.mr-2(v-if="data.item.thumbnail_id" :width='20', :height='20', :radius='10' :src="data.item.thumbnail_id")
          | {{data.item.name}}
      
      template(slot="item" slot-scope="data")
        template(v-if="typeof data.item !== 'object'")
          v-list-item-content(v-text="data.item")
        template(v-else)
          v-list-item-content
            v-layout(align-center)
              image-thumbnail.mr-3(v-if="data.item.thumbnail_id" :width="60" :height="40"  :src="data.item.thumbnail_id")
              v-layout.ml-2(column)
                .body-2.font-weight-bold {{data.item.name}}
                .caption {{data.item.description}}
</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components: {
    ImageThumbnail
  },

  props: {
    value: String,
    label: {
      type: String,
      default: "Attach Workflow"
    },
    placeholder: {
      type: String,
      default: "Start typing a Workflow"
    }
  },

  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    workflowTemplates: null,
    search: null
  }),

  methods: {
    setData: function(theData) {
      this.$emit("input", theData);
    },

    onChange: function(theValue) {
      this.$emit("input", theValue);
    },

    onFilter: function(item, queryText) {
      const hasValue = val => (val != null ? val : "");
      const query = hasValue(queryText);
      const toSearchText =
        item.name +
        " " +
        item.description;
      return (
        toSearchText
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    }
  },

  mounted() {
    this.workflowTemplates = this.value;
    // if (this.$store.state.vehicles.length < 2) {
    this.isLoading = true;
    this.$store.dispatch("workflow/loadAllWorkflows").then(() => {
      this.isLoading = false;
    });
  }
};
</script>
<style scoped lang="stylus">
.imageCrop {
  width: 60px;
  height: 40px;
  color: red;
  border-radius: 5px;
  background-color: #CCCCCC;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.emptyThumbnail {
  width: 60px;
  height: 40px;
  border: 2px dashed #CCCCCC;
  border-radius: 5px;
}
</style>
