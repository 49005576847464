<template lang="pug">
  #blankcontent()
    .panelTitle() Messages
    v-divider.mx-2()
    .panelContent()
      template(v-if="!isLoaded")
        .d-flex.justify-center.align-center.fill-height()
          v-progress-circular(color="primary" indeterminate)
      template(v-else)
        v-list(two-line)
          div(v-for='(item,index) in $store.state.messages.message_data' :key="index" )
            v-list-item.recentCommentsPanelItem(:key='item.title' @click='onClickNotification(item)' )
              template(v-if="item.contact_from_id")
                v-list-item-avatar(v-if="item.contact_from")
                  v-img(:src='"/media/"+item.account_id+"/"+item.contact_from.thumbnail.thumbnail')
              template(v-else)
                v-list-item-avatar(v-if="item.vehicle_from")
                  v-img(:src='"/media/"+item.account_id+"/"+item.vehicle_from.thumbnail.thumbnail')
              v-list-item-title()
                v-list-item-subtitle.text-subtitle-1(:class="item.status == 'Seen'?'font-weight-light':'font-weight-bold primary--text'"  v-html="item.title")
                v-list-item-subtitle.text-body-2.pt-1.pb-2(:class="item.status == 'Seen'?'text--gray':'text--primary'" v-html="item.message")

              .recentCommentsPanelCaption()
                timeago.caption(:class="item.status == 'Seen'?'font-weight-light grey--text':'font-weight-bold'"  :datetime="item.created_at")

</template>
<script>
export default {
  props: {
    clientHeight: Number
  },

  data: () => ({
    isLoaded: false,
  }),
  methods: {
    onClickNotification: function (item) {
      this.$store.commit("messages/setCurrentIndexByID", item.id);
      this.$store.commit("messages/updateMessage", {path: "status", value: 3})
      this.$store.commit("messages/reCalculateStatus")
    }
  },
  mounted() {
    this.isLoaded = true;
  }
};
</script>
<style lang="stylus" scoped>
.recentCommentsPanelItem {
  border-bottom: 1px solid #ececec;
}

.recentCommentsPanelCaption {
  max-width: 100px;
  padding-left: 8px;
  text-align: right;
}

.lineVehicleStatus {
  border-bottom: 1px solid #f9f9f9;
  height: 50px;
}

#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 30px;
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.panelContent {
  height: calc(100% - 45px);
  overflow: auto;
}
</style>
