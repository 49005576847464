<template lang="pug">
  div
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Chat
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item(v-for='item in items', :key='item.title' @click="setStatus(item.value)")
                v-list-item-title() {{ item.title }}
          v-btn.ma-2(small,color="primary",@click="sendMessage")
            v-icon.pr-2(dark) alarm
            | Message
          v-btn.ma-2(small,color="primary",@click="setStatus('roster')")
            v-icon.pr-2(dark) alarm
            | Roster

    v-divider
    v-container(style="max-width:1320px")
      splitpanes.default-theme()
        pane(:size="30")
          .rosterPane(:style="{'height':`${windowHeight}px`, 'width':'100%'}" )
            .chatSearchBox.d-flex.align-center()
              v-text-field.hidden-xs-only.px-4(light, solo, :hide-details="true"   prepend-inner-icon='search', single-line, placeholder='Search')
            .roosterList.white()
              .rosterItem.px-4(v-for="(rosterItem,index) in rosterData" :key="index" @click="selectRosterItem(rosterItem)")
                chat-avatar(:jid="rosterItem.jid.local" :name="rosterItem.name")
                .fill-width
                  .d-flex.justify-space-between.align-center
                    div.subtitle-1() {{rosterItem.name}}
                    .caption(v-if="getLastTimeRead(rosterItem.jid.toString())")
                      template(v-if="isMoreThanADay(getLastTimeRead(rosterItem.jid.toString())) < 1")
                        // show - 24 hours
                        div() {{getLastTimeRead(rosterItem.jid.toString()).format('HH:mm')}}
                      template(v-else)
                        // show how many days ago
                        timeago(:datetime="getLastTimeRead(rosterItem.jid.toString()).toDate()")
                  .d-flex.justify-space-between.align-center
                    .userLabel.grey--text()
                      div(v-if="getLastThreadByJID(rosterItem.jid.toString())")
                        .roosterDescription()
                          template(v-if="getLastThreadByJID(rosterItem.jid.toString()).from == rosterItem.jid.toString()")
                            span() {{rosterItem.name}}:&nbsp;
                          template(v-else)
                            span() {{$store.state.profile.userInfo.contact.first_name}}:&nbsp;
                          span.font-weight-bold() {{getLastThreadByJID(rosterItem.jid.toString()).message}}
                    .unreadMessagesCounter.font-weight-bold.grey.lighten-2(v-if="getUnreadMessageCount(rosterItem.jid.toString()) > 0") {{getUnreadMessageCount(rosterItem.jid.toString())}}
        pane(:size="60")
          .bodyPane(:style="{'height':`${windowHeight}px`, 'width':'100%'}" )
            .chatSearchBox.d-flex.align-center.px-6()
              .d-flex.align-center()
                chat-avatar(:jid="getCurrentJIDLocal" :name="currentName")
                .d-flex.flex-column(style="line-height:16px;")
                  div() {{currentName}}
                  .caption.grey--text() users in the group or some content here
            .chatContainer.grey.lighten-2(ref="chatContainer")
              .chatMessage(v-for="(msg,index) in messageThread" :key="index")
                div.d-flex(:class="msg.type=='in'?'justify-end':msg.type=='time'?'justify-center':'justify-start'")
                  template(v-if="msg.type=='time'")
                    .bubbleCenter.grey.lighten-5.grey--text.text--darken-2() {{msg.timestampz}}
                  template(v-else)
                    .bubbleMessage.white(style="max-width:70%" :class="msg.type=='in'?'bubbleRight primary lighten-1 white--text':'bubbleLeft grey--text text--darken-2'")
                      div.mb-6(style="white-space: pre-wrap; ") {{msg.message}}
                      .bubbleTime()
                        div(v-text="formatTime(msg.timestampz)")
                        v-icon.pl-2(size="18" :color="msg.type=='in'?'white':'grey'") done_all
            .chatInputContainer.d-flex.align-center.px-8(v-if="$store.state.chat.currentJID")
              .px-3.py-3.mr-4.pointer()
                v-icon() attach_file
              v-textarea.mr-5(
                ref="textField"
                v-model="inputMessage"
                light,
                solo,
                :hide-details="true"
                placeholder='Type a message',
                :rows="1" auto-grow
                :row-height="12"
                class="chat-compose-input"
                max-lines="3"
                @keydown.enter.exact.prevent="submit"
                @keydown.enter.shift.exact.prevent="newLine"
                )

              .px-2.py-3.pointer(style="width:40px;" @click="submit()")
                transition(name="fade")
                  v-icon(color="grey" v-show="inputMessage") send
            .chatBtnScroll()
              transition(name="fade")
                v-btn(dark fab color="primary" @click="scrollToBottom()" v-if="scrollDiff")
                  v-icon() arrow_downward

</template>

<script>
import {initMatrix,closeMatrix,sendMessage} from "@/modules/messaging/store/MatrixHelper";

import {Pane, Splitpanes} from "splitpanes";
import moment from "moment";
import 'splitpanes/dist/splitpanes.css'
import ChatAvatar from "@/modules/messaging/components/ui/ChatAvatar";
export default {
  components:{
    Splitpanes,
    Pane,
    ChatAvatar,
  },
  data:()=>({
    windowHeight:null,
    scrollDiff:null,
    inputMessage: null,
    items: [
      {
        title: "Available",
        value:"available"
      },
      {
        title: "Away",
        value:"away"
      },
      {
        title: "Busy",
        value:"dnd"
      },
      {
        title: "Not Available",
        value:"xa"
      },
      {
        title: "Unavailable",
        value: "unavailable"
      },
      {
        title: "Quit",
        value: "quit"
      },
      {
        title: "Init",
        value: "init"
      },
    ],
    rosterItems:[],
  }),
  created() {
    window.addEventListener("resize",this.onResize)
  },

  destroyed() {
    window.removeEventListener('resize',this.onResize)


  },


  beforeDestroy() {
    if(typeof this.$refs.chatContainer !== 'undefined'){
      this.$refs.chatContainer.removeEventListener("scroll",this.onScrollBodyDifference)
    }
  },

  mounted() {
    this.$nextTick(()=>{
      this.onResize();
      if(this.$store.state.chat.currentJID != null){
        this.$refs.textField.focus();
      }

      this.$refs.chatContainer.addEventListener("scroll",this.onScrollBodyDifference)
    })



  },

  watch:{
    messageThread: function(){
      this.$nextTick(()=>{
        this.scrollToBottom();
      })

    }
  },

  computed:{
    messageThread: function(){
      return this.$store.getters["chat/getThread"];
    },

    rosterData: function(){

      return this.$store.getters["chat/getRosterData"];
    },

    userJID: function(){
      return this.$store.state.chat.userJID;
    },

    currentName: function() {
      return this.$store.getters["chat/getCurrentNameJID"]
    },

    getCurrentJIDLocal: function(){
      return this.$store.getters["chat/getCurrentJIDLocal"]
    }

  },
  methods:{
    formatTime: function(timestampISO){
      return moment(timestampISO).format("HH:mm")
    },

    getUnreadMessageCount: function(jid){
      return this.$store.getters["chat/getUnreadMessageCount"](jid)
    },


    getLastTimeRead: function(jid){
      let theTime = this.$store.getters["chat/getLastTimeRead"](jid);
      if (theTime == null) return null;
      return moment(theTime);
    },
    isMoreThanADay:function(theMoment){
     return moment().diff(theMoment,'days')
    },

    selectRosterItem: function(rosterItem){
      this.$store.commit("chat/setCurrentJID",rosterItem.jid.toString())
    },
    submit: function(){
      console.log(this.inputMessage);
      let payload = {
        from:this.userJID,
        to: this.$store.state.chat.currentJID,
        message:this.inputMessage.toString(),
        timestampz: new Date().toISOString(),
        type:'in'
      }
      this.$store.commit("chat/appendCurrentThread",payload,)
      // send Message to XMPP Server
      sendMessage(this.$store.state.chat.currentJID,this.inputMessage)
      // send to thread
      this.inputMessage = null;
    },
    newLine:function(){
      console.log("NEW LINE")
      this.inputMessage = this.inputMessage + '\n'
    },
    scrollToBottom: function(){
      // this.$refs.chatContainer;
      console.log(this.$refs.chatContainer.scrollHeight)
      this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight - this.$refs.chatContainer.clientHeight;
    },
    onScrollBodyDifference: function(){
      // Get the diffrenece for scrolling.
      if(typeof this.$refs.chatContainer === 'undefined') return 0;
      this.scrollDiff = ((this.$refs.chatContainer.scrollHeight - this.$refs.chatContainer.clientHeight) - this.$refs.chatContainer.scrollTop) > 0;
    },

    onResize:function(){
      this.windowHeight = window.innerHeight -220;
    },
    setStatus:async function(value){
      switch (value){
        case "away":
          console.log("away"); break;
        case "dnd": console.log("dnd"); break;
        case "quit": closeMatrix(); break;
        case "roster": console.log("init Roseter here"); break;
        case "init":
          if(typeof this.$store.xmpp == 'undefined' && this.$store.xmpp != null){
            await initMatrix(this.$store,this.$store.state.profile.userInfo.id,this.$store.state.profile.userInfo.oauth_token);
            console.log("change prescence here")
          }else{
            await closeMatrix();
          }

          break;
        default: console.log("unkonwn status");
      }

    },
    sendMessage:function(){
      sendMessage("mugambi@localhost",`hello34 world ${Math.random()}`)
    },

    getLastThreadByJID: function(jid){
      return this.$store.getters["chat/getLastThreadJID"](jid)
    },

  }
}
</script>

<style scoped lang="stylus">
.rosterPane{
  display: flex;
  flex-direction: column;
}
.chatSearchBox{
  height: 70px;
}
.roosterList{
  height: 100%;
  overflow-y: auto;


}
.rosterItem{
  top: 70px;
  width: 100%;
  height: 80px;
  border-bottom 1px solid #FFFFFF;
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  cursor: pointer;

}

.rosterItem:hover{
  background-color: #e2e2e2
}

.roosterDescription{
  height: 26px;
  //background-color #42b983
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bodyPane{
  position relative;
  display: flex;
  flex-direction: column;
}
.chatContainer{
  height: 100%;
  padding-left: 80px;
  padding-right: 80px;
  overflow-y: auto;
}
  .chatBtnScroll{
    position absolute;
    bottom: 110px;
    right: 24px;
  }
.chatMessage{
  padding 15px 10px;
}
.chatInputContainer{
  height 100px;
}
.bubbleMessage{
    min-width: 120px;
    padding 10px 10px
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    position: relative;
    box-shadow: 2px 2px #BBB;
  }

  .bubbleTime{
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 20px;
    font-size: 13px;
    display: flex;
  }
  .bubbleRight{
    border-top-left-radius: 12px;
  }

  .unreadMessagesCounter{
    min-width:30px;
    min-height:30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .bubbleLeft{
    border-top-right-radius: 12px;
  }

.bubbleCenter{
  border-radius: 8px;
  padding 5px 10px;
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: -15px;

}
.chat-compose-input::v-deep {
  textarea {
    max-height: 84px;
    overflow: auto;
  }
}

.chatInputContainer textarea{
  max-height: 84px;
  overflow: auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1.0s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}

.submit-enter-active, .submit-leave-active {
  transition: opacity 1.0s
}
.submit-enter, .submit-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}

  .userLabel{
    font-size: 14px;
  }
</style>