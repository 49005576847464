import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const PURCHASEORDER_API = "/purchaseOrder";

// ----------------------- STATE ------------------------- //
const state = {
  purchaseorder_data: [],
  currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getPurchaseOrder: state =>
    getField(state.purchaseorder_data[state.currentIndex]),

  getCurrentPurchaseOrder: state => {
    return state.purchaseorder_data[state.currentIndex];
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updatePurchaseOrder(state, payload) {
    updateField(state.purchaseorder_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.purchaseorder_data.findIndex(
      purchaseorder => purchaseorder.id == theID
    );
  },

  loadAllPurchaseOrders(state, payload) {
    state.purchaseorder_data = payload.data;
    state.currentIndex = 0;
  },

  addPurchaseOrder(state, payload) {
    state.purchaseorder_data.push(payload.data);
    state.currentIndex = state.purchaseorder_data.length - 1;
  },

  savePurchaseOrder(state, payload) {
    state.purchaseorder_data[state.currentIndex] = payload.data;
  },

  fetchPurchaseOrder(state, payload) {
    if (state.currentIndex == -1) {
      state.purchaseorder_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.purchaseorder_data[state.currentIndex] = payload.data;
    }
  },

  deletePurchaseOrder(state, payload) {
    state.currentIndex = state.purchaseorder_data.findIndex(
      purchaseorder => purchaseorder.id == payload.data.id
    );
    state.purchaseorder_data.splice(state.currentIndex, 1);
  },
  reset(state) {
    state.purchaseorder_data = []
    state.currentIndex = -1
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllPurchaseOrders({ commit }) {
    try {
      commit(
        "loadAllPurchaseOrders",
        await Repository.get(`${PURCHASEORDER_API}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async addPurchaseOrder({ commit }) {
    await Repository.get(`${PURCHASEORDER_API}/new`)
      .then(response => {
        commit("addPurchaseOrder", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async savePurchaseOrder({ state, commit }) {
    try {
      commit(
        "savePurchaseOrder",
        await Repository.post(
          `${PURCHASEORDER_API}`,
          state.purchaseorder_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchPurchaseOrder({ commit }, payloadID) {
    try {
      commit(
        "fetchPurchaseOrder",
        await Repository.get(`${PURCHASEORDER_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deletePurchaseOrder({ commit }, payloadID) {
    try {
      commit(
        "deletePurchaseOrder",
        await Repository.delete(`${PURCHASEORDER_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchPurchaseOrders({ commit }, searchTerm) {
    try {
      commit(
        "loadAllPurchaseOrders",
        await Repository.get(`${PURCHASEORDER_API}/search?q=${searchTerm}`)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
