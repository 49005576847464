<template lang="pug">
  div
    import-file-dialog(:dialogShow="dialogShow", @closeDialog="onCloseDialog")
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Data Tables
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item(v-for='item in items', :key='item.title' @click="onAction(item.value)")
                v-list-item-title {{ item.title }}
        v-btn.ma-2(small, color='primary', dark, @click='uploadExcel') Upload File
        // v-btn.ma-2.mr-5(small, color='primary', dark, @click='openEdit') Add Form
    v-divider
    
    v-layout.mx-4.my-2(column)
      v-item-group
        v-item(v-for='toggleItem in toggleItems', :key='toggleItem')
          v-chip(small, :color="\
          active ? 'primary white--text elevation-0' : 'white primary--text'\
          ", slot-scope='{ active, toggle }', @click.native='toggle', :input-value='active')
            | {{ toggleItem }}
      v-layout.mt-3(align-center, style='max-width:900px;')
        v-layout.px-3(row, wrap, align-center)
          search-autocomplete()
          //- v-combobox(v-model='select1', :items='selectOptions1', label='Select a favourite activity or create a new one')
          //- v-text-field.pl-2(v-model='searchFilter', label='Search Filter', :persistent-hint='true', required)
          v-btn.mx-3(small, color='primary', dark) Search
          div
            span.primary--text 1 filter
            span.pl-1.grey--text.text--darken-1 applied
          a().px-3.grey--text.text--darken-1 clear all
    v-divider
    .grey.lighten-4.py-3
    v-divider
    workflow-data-table()
    v-divider
</template>
<script>
import ImportFileDialog from "@/modules/global/components/table/ImportFileDialog";
import WorkflowDataTable from "@/modules/workflow/dataTables/components/tables/WorkflowDataTable";
import SearchAutocomplete from "@/modules/global/components/table/SearchAutocomplete";

export default {
  components: {
    ImportFileDialog,
    WorkflowDataTable,
    SearchAutocomplete
  },

  data: () => ({
    toggleItems: ["Active", "In Active", "Processing"],
    select1: "All Forms",
    selectOptions1: [
      "All Forms",
      "Overdue Only",
      "Overdue + Due Soon",
      "Due Soon Only"
    ],
    items: [
      {
        title: "Import Excel",
        value: "import_excel"
      },
      {
        title: "Import CSV",
        value: "import_csv"
      }
    ],

    vehiclesFilter: "",
    searchFilter: "",
    dialogShow: false,

    breadCrumbsItems: [
      {
        text: "Report",
        disabled: false,
        path: "reports"
      },
      {
        text: "Vehicle Report",
        disabled: true,
        path: "vehicleListReport"
      }
    ],
  }),

  methods: {
    openEdit: function() {
      console.log("openEdit");
    },
    showDialog: function() {},

    onCloseDialog: function() {
      this.dialogShow = false;
      this.$store.dispatch("workflow/dataTable/loadAllDataTables");
    },

    onAction: function(value) {
      console.log("onAction: " + value);
    },

    uploadExcel: function() {
      this.dialogShow = true;
    }
  }
};
</script>
<style scoped lang="stylus"></style>
