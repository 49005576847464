<template lang="pug">
  div
    v-container.my-0.pa-0(fluid)
      p.my-0 {{ schema.label }}
      v-layout.ml-0(v-bind="bindValues")
        v-checkbox.mr-3.pa-0.mt-0.mb-2(v-for="(item,index) in schema.options" :key="index" v-model="selected" :hide-details="true" :value="item" :label="item" @change="onChange" :disabled="disabled")
      p.caption(v-show="schema.hint != null") {{ schema.hint }}
</template>
<script>
export default {
  props: ["schema", "dataValue", "index","disabled"],

  data: () => ({
    selected: []
  }),

  computed: {
    bindValues: function() {
      let p = {};
      if (this.schema.orientation === "row") {
        p["row"] = true;
      }

      if (this.schema.orientation === "column") {
        p["column"] = true;
      }
      return p;
    }
  },

  methods: {
    onChange: function() {
      // set the value
      console.log(`selected: ${this.selected}`);
      this.$emit("onChange", {
        id: this.schema.id,
        value: this.selected,
        index: this.index
      });
    }
  },
  mounted() {
    if (this.dataValue != undefined) {
      this.selected = this.dataValue;
    }
  }
};
</script>
<style scoped lang="stylus"></style>
