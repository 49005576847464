<template lang="pug">
  div(v-if="$store.state.settings.roleSettings.role_data.length > 0")
    .theForm.pb-4
      v-btn.mr-4(small color="primary" outlined @click="cancel") Cancel
      v-btn(small color="primary" @click="save") Save
    v-card
      v-container
        v-layout(row wrap)
          v-flex(xs12)
            v-text-field(v-model="name"  hint="Enter name of the Role" :persistent-hint="true"  label="Role Name*"  required)
          v-flex(xs12)
            v-textarea(v-model="description"  hint="Enter name of the Description" :persistent-hint="true"  label="Description*"  required)

       
        .permmisions(v-for="(item,index) in permissions" :key="index")

          v-layout.px-3.d-flex.flex-row.justify-space-between.align-center(style="width:100%")
            div(style="display:flex")
              .title.grey--text.text--darken-1 {{item.name}}
            div(style="display:flex;  justify-content:center; align-items:center")
              .entries
                v-checkbox.pr-2(label="can view"  :input-value="getViewPermission(index)" @change="onChangeView(index)"  :color="item.view?'primary':'grey'")
                v-checkbox.pr-2(label="can edit" :input-value="getEditPermission(index)"  @change="onChangeEdit(index)"  :color="item.edit?'primary':'grey'")
</template>
<script>
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "settings/roleSettings/getRole",
  mutationType: "settings/roleSettings/updateRole"
});

export default {
  computed: {
    ...mapFields({
      name: "name",
      description: "description",
      permissions: "permissions"
    })
  },

  theView: {
    get: function(){
      return true;
    },
    set: function(value){
      console.log(value)
    }
  },

  methods: {
    getViewPermission:function(index){
      return this.permissions[index].view
    },

    getEditPermission:function(index){
      return this.permissions[index].edit
    },

    onChangeView:function(index){
      this.$store.commit("settings/roleSettings/changePermission",{index:index,type:"view"})
    },

    onChangeEdit:function(index){
      this.$store.commit("settings/roleSettings/changePermission",{index:index,type:"edit"})
    },


    cancel: function() {
      this.$store.dispatch("settings/roleSettings/fetchRoleSettings");
      this.$router.push({ name: "rolesettings" }).catch(()=>{});
    },

    save: function() {
      console.log(
        this.$store.state.settings.roleSettings.role_data[
          this.$store.state.settings.roleSettings.currentIndex
        ].account_id
      );
      var theObject = this.$store.state.settings.roleSettings.role_data[
        this.$store.state.settings.roleSettings.currentIndex
      ]; //todo: dont know why getCurrentItem does not work

      this.$store.dispatch("settings/roleSettings/saveRole", theObject);
      this.$router.push({ name: "rolesettings" }).catch(()=>{});
    }
  },

  mounted() {
    //if page is refreshed then re-fetch the data

    if (this.$store.state.settings.roleSettings.currentIndex === -1) {
      this.$store.dispatch(
        "settings/roleSettings/fetchRole",
        this.$route.params.roleID
      );
    }
  }
};
</script>
<style scoped lang="stylus">
.theForm
  display flex

  justify-content flex-end

.permmisions
  border-bottom 1px solid #E5E5E5
  margin-top 10px



.entries
  display flex
  flex-direction row
  justify-content space-around
  align-items center
  background-color #E8E8E8
  border-radius 9px;
  padding 0px 20px
  margin-bottom 20px;
  margin-top 10px;
  height 40px;
</style>
