<template lang="pug">
  .showAdvancedProperties
    v-icon(:size="32") {{showAdvanced?'arrow_drop_down':'arrow_right'}}
    .caption.showAdvancedText() Show Advanced Properties

</template>
<script>
export default {
  props: ["showAdvanced"],
  data: () => ({})
};
</script>
<style scoped lang="stylus">
.showAdvancedProperties {
  margin-left -14px;
  display flex;
  align-items center;
  cursor pointer
}



.showAdvancedProperties:hover{
  color red;
}
</style>
