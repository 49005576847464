<template lang="pug">
  div()
    .pl-2.d-flex.flex-wrap()
      .pointer.mx-3.my-4(v-if="$store.state.cameraCaptures.camera_data.length > 0" v-for="(item,index) in $store.state.cameraCaptures.camera_data" :key="item.id" @click.prevent="onClick(item)")
        camera-thumbnail(:width="200" :height="120" :item="item" :position="item.lens.charAt(item.lens.length-1) == 'r' ? 'fit':'center'" )
    v-dialog(v-if="dialog" v-model="dialog"  width="unset")
      v-card()
        template(v-if="selectedItem.lens == 'photor' || selectedItem.lens == 'photof'")
          img(v-if="theFilePath" :src="theFilePath")
        template(v-else)
          video(:src="theFilePath" type="video/mp4" controls)

</template>


<script>
import CameraThumbnail from "@/modules/global/components/forms/CameraThumbnail.vue";


export default {
  components: {CameraThumbnail},
  data:()=>({
    dialog:false,
    theFilePath:null,
    selectedItem:null,
  }),
  methods:{
    onClick: function(item){
      this.selectedItem = item;
      let startIndex = this.selectedItem.dash_cam.file_path.indexOf('/media')
      this.theFilePath = this.selectedItem.dash_cam.file_path.substring(startIndex,)
      console.log(this.theFilePath)
      this.dialog = true;
    }
  }
}
</script>



<style scoped lang="stylus">

</style>