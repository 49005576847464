<template lang="pug">
  .page(v-if="customer")
    v-progress-linear(v-if="isLoaded" indeterminate)
    customer-overview-header(:current-customer="customer")

    .mainBody
      customer-overview-side-bar(:current-customer="customer")
      router-view.contentBackground()
</template>
<script>
import CustomerOverviewHeader from "@/modules/contacts/components/ui/overview/CustomerOverviewHeader";
import CustomerOverviewSideBar from "@/modules/contacts/customers/overviews/CustomerOverviewSideBar";

export default {
  components: {
    CustomerOverviewSideBar,
    CustomerOverviewHeader,
  },
  data: () => ({
    customer:null,
    isLoaded:true,
  }),

  methods:{
      async getPlaceVisitationData() {
      if(this.customer.places_id != null){
         await this.$store.dispatch("customer/getVisitations",this.customer.places_id[0])
      }else{
        return;
      }
    },

    async getInventories() {
      await this.$store.dispatch("customer/getInventories",this.customer.id)
    }
  },

  mounted() {

    this.$store.commit("customer/setVisitationCount", null)
    this.$store.commit("customer/setVisitations", [])
    if(this.$store.getters["customer/getCustomerByID"](this.$route.params.contactID) == undefined){
      this.$store.dispatch("customer/fetchCustomer",this.$route.params.contactID).then(()=>{
        this.customer = this.$store.getters["customer/getCustomerByID"](this.$route.params.contactID)
        Promise.all([this.getPlaceVisitationData(),this.getInventories()]).then(()=>{
          this.isLoaded = false;
        })

      })
    }else{
      this.customer = this.$store.getters["customer/getCurrentCustomer"]
      Promise.all([this.getPlaceVisitationData(),this.getInventories()]).then(()=>{
        this.isLoaded = false;
      })
    }
  }
};
</script>
<style scoped lang="stylus">
.mainBody {
  border-top: 1px solid #CCCCCC;
  margin-top: 4px;
  padding-top: 8px;
  display: flex;
}

.contentBackground {
  width: 100%;
}

.page {
  background-color: #F5F5F5;
}
</style>
