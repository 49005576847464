<template lang="pug">
  div()
    .signPlaceholder()
      template(v-if="item != null && computedValue != null")
        .signature(:style="{ 'background-image': 'url(' + '/media/' + $store.state.profile.userInfo.account_id +'/'+ item.file_name + ')'}")
          .d-flex.align-start.mt-2()
            v-icon.mx-3(@click="onDelete") delete
            .mb-2.grey--text {{schema.label}}
      template(v-else)
        img(src="/static/web/sign_placeholder.png" @click="openSignatureDialog" )
    v-dialog(v-model="dialog" width="800")
      signature-dialog(v-model="computedValue" @closeDialog="closeDialog" :title="schema.hint")
</template>
<script>

import SignatureDialog from "@/modules/global/components/dialog/SignatureDialog";
import Repository from "@/repository";

export default {
  props: ["schema", "dataValue", "index","disabled"],
  components:{
    SignatureDialog
  },
  data: () => ({
    item:null,
    dialog:false
  }),
  computed:{
    signStyle:function(){
      let obj = {};
      if(this.computedValue != null){
        obj["background-image"] = 'url("' + this.computedValue + '")'
      }

      return obj;
    },
    computedValue:{
      get: function() {
        return this.dataValue;
      },
      set: function(value){
        let theValue = null;
        if(value != null){
          theValue = value.id;
          this.setItem(value);
        }
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    },
  },
  methods:{
    openSignatureDialog:function(){
      if(this.disabled){
        return;
      }
      this.dialog = true;
    },

    closeDialog:function(){
      this.dialog = false;
    },

    setItem: function(value){
      this.item = value;
    },

    loadImage: function(blobID) {
      Repository.get(`/upload/blob/${blobID}`)
          .then(payload => {
            this.item = payload.data;
          })
          .catch(payload => {
            console.log(payload.data);
          });
    },

    onDelete: function(){
      if(this.disabled){
        return;
      }
      this.theValue = null;
      this.item = null;
      this.openSignatureDialog();
    },
  }
};
</script>
<style scoped lang="stylus">
.signPlaceholder{
  border 2px solid #CCCCCC;
  display flex;
  justify-content center;
  align-items center;
  border-radius 10px;
  background-size contain;
  background-position center;
  width 240px;
  height 120px;

}
.signPlaceholder img{
  width 200px;
  height 100px;
}

.signature{
  width 200px;
  height: 120px;
  background-size: contain;
}
</style>
