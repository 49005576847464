<template lang="pug">
.nameColumn()
  div(:style="{ opacity: vehicle.status=='active'?1:0.4}")
    image-thumbnail.mr-3(:width='90', :height='60', :src="vehicle.thumbnail_id")
  .nameColumnInfo
    .nameColumnTitle {{ vehicle.name }}
    .nameColumnDescription.make.primary--text
      | {{ vehicle.make }}
    .nameColumnDescription
      | VIN: {{ vehicle.vin }}
    .nameColumnDescription
      | #
      b.grey--text.text--darken-4
        | {{ vehicle.number_plate }}
</template>
<script>
import VehicleStatus from "@/modules/global/components/others/Status";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components: {
    VehicleStatus,
    ImageThumbnail
  },
  props: ["vehicle"],
  data: () => ({})
};
</script>
<style scoped lang="stylus">
.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn, .nameColumnInfo {
}

.nameColumn, .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;
  padding: 0px;
}

.make {
  font-size: 11px;
  margin-top: -4px;
}
</style>
