import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const RECORD_API = "/workflowRecord";

// ----------------------- STATE ------------------------- //
const state = {
    record_data: [],
    currentIndex: -1,
    someData: null,
    show_form: false
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getRecord: state => getField(state.record_data[state.currentIndex]),

    getCurrentRecord: state => {
        return state.record_data[state.currentIndex];
    },



    getRecordSchema: state => {
        let dataObj = state.record_data[
            state.currentIndex
            ].workflow_template.data.find(
            e =>
                e.version ==
                state.record_data[state.currentIndex].workflow_template_version
        );

        console.log("data object");
        console.log(dataObj);
        if (dataObj) {
            return dataObj.schema;
        } else {
            return null;
        }
    },

    getCurrentWorkflowTemplate: state => {
        return state.record_data[state.currentIndex].workflow_template;
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateRecord(state, payload) {
        updateField(state.record_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.record_data.findIndex(
            record => record.id == theID
        );

        console.log(state.currentIndex);
    },

    loadAllRecords(state, payload) {
        state.record_data = payload.data;
        state.currentIndex = 0;
    },

    loadFilteredAllRecords(state, payload) {
        let index = -1;
        let currentRecord = state.record_data[state.currentIndex];
        if (state.currentIndex > -1) {
            let currentRecordID = currentRecord.id;
            index = payload.data.findIndex(item => item.id === currentRecordID);
        }

        state.record_data = payload.data;
        console.log("current record template");
        console.log(currentRecord.workflow_template);
        state.record_data.forEach(item => {
            item.workflow_template = currentRecord.workflow_template;
        });
        if (index > -1) {
            state.currentIndex = index;
        } else {
            state.record_data.unshift(currentRecord);
            state.currentIndex = 0;
        }
    },

    setFilteredIdAndVersion(state, payload) {
        state.filtered_id = payload.workflowTemplateId;
        state.filtered_version = payload.version;
    },

    addRecord(state, payload) {
        state.record_data.unshift(payload.data);
        state.currentIndex = 0;
    },

    saveRecord(payload) {
        console.log(payload);
        // let index = state.record_data.findIndex(item => item.id == payload.data.id);
        // if (index > -1) {
        //   // update
        //   state.record_data[index] = payload.data;
        //   state.currentIndex = index;
        // } else {
        //   // add
        //   state.record_data.unshift(payload.data);
        //   state.currentIndex = 0;
        // }
    },

    fetchRecord(state, payload) {

            if (state.currentIndex == -1) {
                state.record_data.push(payload.data);
                state.currentIndex = 0;
            } else {
                state.record_data[state.currentIndex] = payload.data;
            }

    },

    deleteRecord(state, payload) {
        state.currentIndex = state.record_data.findIndex(
            record => record.id == payload.data.id
        );

        state.record_data.splice(state.currentIndex, 1);
        if (state.record_data.length == 0) {
            state.currentIndex = -1;
        }
    },

    toggleShowForm(state, payload = null) {
        if (payload == null) {
            state.show_form = !state.show_form;
        } else {
            state.show_form = payload;
        }
    },

    clearAll(state) {
        state.record_data = [];
        state.currentIndex = -1;
    },
    // remove all items that begin with an '$' like the header component;
    cleanedFormData() {
        console.log("----------------- ze cleaned Data ------------------");
        let tempArray = Object.keys(state.record_data[state.currentIndex].data);
        tempArray.forEach(item => {
            // delete any key starting with '$'
            if (item.charAt(0) === '$') {
                delete state.record_data[state.currentIndex].data[item]
            }
        })

    },

    reset(state) {
        state.record_data = []
        state.currentIndex = -1
        state.someData = null
        state.show_form = false
    },

    setOneRecordFromBoard(state,payload){
      state.record_data = [payload]
      state.currentIndex = 0;
    },

    next(state){
        if(state.currentIndex == state.record_data.length-1){
            state.currentIndex = 0;
        }else{
            state.currentIndex += 1;
        }
    },

    previous(state){
        if(state.currentIndex == 0){
            state.currentIndex = state.record_data.length-1;
        }else{
            state.currentIndex -= 1;
        }
    },
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllRecords({commit}) {
        try {
            commit("loadAllRecords", await Repository.get(`${RECORD_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async loadAllRecordsByID({commit}, payload) {
        console.log(payload);
        payload = typeof payload === "undefined" ? "" : payload;
        try {
            commit(
                "loadAllRecords",
                await Repository.get(`${RECORD_API}?workflowTemplate_id=${payload}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async filterRecords({commit}, payload) {
        try {
            commit("setFilteredIdAndVersion", payload);
            commit(
                "loadAllRecords",
                await Repository.get(
                    `${RECORD_API}/filterRecords?workflowTemplate_id=${payload.workflowTemplateId}&version=${payload.version}`
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async addRecord({commit}, workflowTemplateID) {
        await Repository.get(`${RECORD_API}/new/${workflowTemplateID}`)
            .then(response => {
                commit("addRecord", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveRecord({state, commit}) {
        commit("cleanedFormData");
        console.log("save the record");
        console.log(state.record_data[state.currentIndex]);
        try {
            commit(
                "saveRecord",
                await Repository.post(
                    `${RECORD_API}`,
                    state.record_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchRecord({commit}, payloadID) {
        try {
            commit("fetchRecord", await Repository.get(`${RECORD_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteRecord({commit}, payloadID) {
        try {
            commit(
                "deleteRecord",
                await Repository.delete(`${RECORD_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchRecords({commit}, searchTerm) {
        try {
            commit(
                "loadAllRecords",
                await Repository.get(`${RECORD_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
