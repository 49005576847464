import axios from "axios";
import store from "@/store";
import router from "@/router";

const baseDomain = ""; // "http://tsvserver:4000"
const baseURL = `${baseDomain}/api`;
const instance = axios.create({
  baseURL: baseURL
});
instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {


    // when there is a 401 error then reroute back to login screen
    if (error.response.status === 401) {
        // store.commit("showSnackBar", {
        //     color: "error",
        //     message: error.response.statusText
        // });

      store.commit("login/deleteUserLoggedIn");
      store.commit("login/logOutUser");
      router.push({ name: "login" }, () => {});
    }
    return Promise.reject(error);
  }
);
export default instance;
