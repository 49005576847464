<template lang="pug">
  div.mt-2.mb-2.pb-2
    table.DetailTable
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 Name
        td {{currentVehicle.name}}
      tr(v-if="currentVehicle.group")
        td.goRight.grey--text.text--darken-1
          .mr-3 Group
        td.flexSpaceBetween.pr-4 
          | {{currentVehicle.group.name}}
          v-spacer
          div.handCursor.primary--text(@click="onClick('groupHistory')")  History
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 Status
        td.flexSpaceBetween.pr-4 
          | {{currentVehicle.status}}
          div.handCursor.primary--text(@click="onClick('activeHistory')") History
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 VIN/SN
        td.flexSpaceBetween.pr-4 
          | {{currentVehicle.vin}}
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 Number Plate
        td {{currentVehicle.number_plate}}
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 Year
        td {{currentVehicle.year}}
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 Make
        td {{currentVehicle.make}}
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 Model
        td {{currentVehicle.model}}
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 Trim
        td {{currentVehicle.series}}
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 Color
        td {{currentVehicle.color}}
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 Ownership
        td  {{currentVehicle.ownership}}
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 Body Type
        td {{currentVehicle.body}}
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 Body Sub-Type
        td {{currentVehicle.body_sub_type}}
      tr
        td.goRight.grey--text.text--darken-1
          .mr-3 MSRP
        td {{currentVehicle.msrp | money}}
</template>
<script>
import VehicleStatus from "@/modules/global/components/others/Status";
import { utilityMixin } from "@/mixin/utilityMixin";
export default {
  components: {
    VehicleStatus
  },
  mixins: [utilityMixin],
  data: () => ({}),

  computed: {
    currentVehicle: {
      get: function() {
        return this.$store.getters["vehicles/getCurrentItem"];
      }
    }
  }
};
</script>
<style scoped lang="stylus">
.DetailTable {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
}

.DetailTable {
  tr {
    margin: 0;
    padding: 0;
  }

  td {
    border-bottom: 1px solid #EEEEEE;
    margin: 0;
    padding: 0;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 8px;
  }
}

.goRight {
  text-align: right;
  width: 40%;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.hFlex {
  display: flex;
}

.handCursor {
  cursor: pointer;
}
</style>
