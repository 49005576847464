<template lang="pug">
  div(v-if="$store.state.inventory.item.currentIndex > -1")
    v-layout.pt-2.pr-3(align-center)
      v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
      v-spacer
      v-btn.ma-2(small color="primary" outlined dark @click="cancel") cancel
      v-btn.ma-2(small color="primary" @click="save") save

    v-form(ref="form" v-model="valid")
      v-container.py-2
        v-alert(v-if="errors.length" :value="true" color="error" icon="warning")
          div(v-for="(e,index) in errors" :key="index" v-text="e")


      v-container
        v-card
          v-card-text
            v-container(grid-list-xl)
              form-divider ITEM DETAILS
              v-row.mt-4()
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='name', hint='Name of Item', maxlength="30"  :counter="30" persistent-hint, label='Name of Item' required)
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='description', hint='Description', persistent-hint, label='Description of Item')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-select(v-model="inventory_type"   :items="inventoryTypeList" label="Item Type" item-text="text" item-value="value")
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  auto-combo-box(v-model='merchant_id' label="Select Supplier"  module="merchant" actionPrefix="Merchant")
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='inventory_reference', hint='Suppliers Reference No', persistent-hint, label='Suppliers Reference No')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  auto-combo-box(v-model='measurement_unit_id' label="Measurement Unit"  module="settings" submodule="measurementUnitSettings"  actionPrefix="MeasurementUnit")
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='upc', hint='Bar Code', persistent-hint, label='UPC (Bar Code)')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model.number='unit_cost', hint='Unit Cost', persistent-hint, label='Unit Cost')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-switch(v-model="item_active" label="Status")
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-switch(v-model="track_item" label="Track Item")
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='restock_point', hint='Restock Threshold', persistent-hint, label='Restock Threshold')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  .durationStyle()
                    .subtitle-1() Timeout of duration
                    .d-flex.align-baseline()
                      v-text-field(v-model.number='duration_amount', hint='Time Duration', label='Time Duration' @change="calculateDuration()")
                      v-select.ma-2(
                        v-model="duration_type"
                        :items="duration_types"
                        hint='Duration Type',
                        label="type"
                        @change="calculateDuration()"
                      )

              form-divider Attachments
              v-row.mt-4()
                v-col.px-2(cols="12")
                  attach-fill-form( :schema="imagesSchama" :data="{value:photos_id}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" :moreMenu="additionalImageMenuItems")
                v-col(cols="12")
                  attach-fill-form( :schema="documentsSchama" :data="{value:documents_id}" @onChange="saveDocuments"  @onMenuAction="onMenuActionDocuments")

              form-divider Custom Fields

              v-row.mt-2(v-if="currentItem.item_details" )
                v-col.px-2(v-for="(item,index) in currentItem.item_details.custom_fields" :key="index" cols="6" xs="12" md="6" xl="4")
                  .title() {{item.label}}
                  .subtitle-1.grey--text() {{item.type}} &mdash; {{item.order_no}}
              v-btn.mt-4(v-if="$store.state.profile.userInfo.is_administrator" outlined color="primary" small :to="{ name: 'customFieldsSettings'}") Edit Custom Fields

</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import FormHeaderTotal from "@/modules/global/components/forms/FormHeaderTotal";
import FormPhotoUpload from "@/modules/global/components/forms/FormPhotoUpload";
import AutoComboBox from "@/modules/global/components/forms/AutoComboBox";


import {createHelpers} from "vuex-map-fields";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";

const {mapFields} = createHelpers({
  getterType: "inventory/item/getItem",
  mutationType: "inventory/item/updateItem"
});

export default {
  components: {
    AutoComboBox,
    FormDivider,
    FormHeaderTotal,
    FormPhotoUpload,
    AttachFillForm,
  },
  data: () => ({

    error: false,
    errors: [],
    valid: true,
    inventoryTypeList: [
      {text: "Vehicle Part", value: "vehicle_part"},
      {text: "Equipment", value: "equipment"},
      {text: "Consumable", value: "consumable"}],
    name_rules: [v => v.length > 0 && v.length <= 30 || 'Max 30 characters'],
    imagesSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Item Images",
      hint: null,
      type: "image",
      image_size: "small",
      multiple: true,
    },
    additionalImageMenuItems: [
      {title: "Make Thumbnail", icon: "edit", action: "make_thumbnail"},
    ],

    documentsSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Item Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true,
    },
    duration_type: "Days",
    duration_types: ['Hours', 'Days', 'Months']
  }),

  computed: {
    currentItem: function(){
      return this.$store.getters["inventory/item/getCurrentItem"]
    },
    duration_amount: {
      get: function () {
        if (this.duration == null) return null;
        if (this.duration.Microseconds > 0) {
          return Math.round(this.duration.Microseconds / 3600000000)
        }
        if (this.duration.Days > 0) {
          return this.duration.Days
        }
        if (this.duration.Months > 0) {
          return this.duration.Months
        }
        return null;
      },
      set: function (value) {
        console.log(value)
        console.log(this.duration_type)
        let theObj = {};
        switch (this.duration_type) {
          case "Days":
            theObj = {Microseconds: 0, Days: value, Months: 0, Status: 2};
            break;
          case "Hours":
            theObj = {Microseconds: value * 3600000000, Days: 0, Months: 0, Status: 2};
            break;
          case "Months":
            theObj = {Microseconds: 0, Days: 0, Months: value, Status: 2};
            break;
        }
        this.duration = theObj;

      }
    },

    ...mapFields({

      name: "name",
      description: "description",
      thumbnail_id: "thumbnail_id",
      inventory_type: "inventory_type",
      inventory_reference: "inventory_reference",
      merchant: "merchant",
      merchant_id: "merchant_id",
      merchant_reference: "merchant_reference",
      measurement_unit: "measurement_unit",
      measurement_unit_id: "measurement_unit_id",
      upc: "upc",
      unit_cost: "unit_cost",
      item_active: "item_active",
      track_item: "track_item",
      restock_point: "restock_point",
      photos_id: "photos_id",
      documents_id: "documents_id",
      item_details: "item_details",
      shelf_details: "shelf_details",
      account_id: "account_id",
      duration: "duration"
    }),

  },

  methods: {
    calculateDuration: function () {
      console.log(this.duration_amount)
      console.log(this.duration_type)
    },

    cancel: function () {
      this.$router.push({name: "inventorys"}).catch(()=>{});
      this.$store.dispatch("inventory/item/loadAllItems")
    },

    save: function () {

      if (!this.$refs.form.validate()) {
        return false;
      }
      this.$store.dispatch("inventory/item/saveItem").then(() => {
        this.$router.push({name: "inventorys"}).catch(()=>{});
        this.$store.dispatch("inventory/item/loadAllItems");
      });

    },


    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Item List",
        disabled: false,
        to: {name: "inventorys"},
      });
      theArray.push({
        text: "Item Entry",
        disabled: true,
      });
      return theArray;
    },
    saveImages: function (theObject) {
      this.$store.commit("inventory/item/updateItem", {
        path: "photos_id",
        value: theObject.value,
      });
    },

    saveDocuments: function (theObject) {
      this.$store.commit("inventory/item/updateItem", {
        path: "documents_id",
        value: theObject.value,
      });
    },
    onMenuActionImages: function (value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("inventory/item/deleteImage", value.theObject.id);
          break;
        case "make_thumbnail":
          console.log("make thumbnail")
          console.log(value.theObject.id)
          this.$store.commit("inventory/item/updateItem", {
            path: "thumbnail_id",
            value: value.theObject.id,
          });
          break;
      }
    },


    onMenuActionDocuments: function (value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("inventory/item/deleteDocument", value.theObject.id);
          break;
      }
    },
  },

  mounted() {
    if (this.$store.state.inventory.item.currentIndex == -1) {
      this.$store
          .dispatch("inventory/item/fetchItem", this.$route.params.itemID)
          .then(() => {
            console.log("succefully loaded item")
          }).catch(() => {
        console.log("failed loading items")
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.durationStyle {
  border 1px solid grey
  border-radius 20px;
  padding 10px 10px;
  background-color: #f5f5f5
}
</style>
