<template lang="pug">
.Item
  .smallLetters(:style="titleStyle()")
    | {{item.serviceTask}}
  .caption.grey--text.text--darken-1
    | every {{item.meterInterval}} Miles or {{item.timeInterval}} {{item.timeUnits}}
  .caption.grey--text.text--darken-1
    | Due {{item.date | moment("Do MMM YYYY") }} 
</template>
<script>
export default {
  props: {
    item: Object
  },
  methods: {
    titleStyle: function() {
      let styleObject = {};
      switch (this.item.status) {
        case "due soon":
          styleObject.color = this.$vuetify.theme.info;
          break;
        case "overdue":
          styleObject.color = this.$vuetify.theme.error;
          break;
      }

      return styleObject;
    }
  }
};
</script>
<style scoped lang="stylus">
.Item {
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
