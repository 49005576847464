<template lang="pug">
  div.mt-2.mb-2.pb-2
    div(v-for="(dataItem,index) in info" :key="index")
      .infoTitle.pl-3.pt-2.grey.lighten-3
        | {{dataItem.title}}
      table.DetailTable.body-2.fill-width
        tr(v-for="(item,index) in dataItem.data" :key="index")
          td.goRight.grey--text.text--darken-1
            .mr-3 {{item.label}}
          td 
            | {{item.value}} 
            span {{item.units}}
</template>
<script>
export default {
  props: {
    info: Array
  },

  data: () => ({})
};
</script>
<style scoped lang="stylus">
.DetailTable
  width 100%
  margin 0
  padding 0
  border-spacing 0

.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0
    padding 0
    font-size 12px
    padding-top 6px
    padding-bottom 8px

.goRight
  text-align right
  width 40%

.infoTitle
  height 40px;
  font-size 18px;

.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0
    padding 0

    padding-top 6px
    padding-bottom 8px
</style>
