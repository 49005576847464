<template lang="pug">
  .OverviewSideBar 
    v-btn.ma-3(small color="primary" style="width:90%") 
      v-icon.mr-2(small) add
      | Quick Add
    overview-side-bar-item.menuSpacing(v-for="item in overviewItems1" :key="item.url"   :label="item.label" :icon="item.icon" :value="item.value" :url="item.url" @onclick="onAction")
    v-divider.mb-3.mt-3
    overview-side-bar-item.menuSpacing(v-for="item in overviewItems2" :key="item.url"   :label="item.label" :icon="item.icon" :value="item.value" :url="item.url" @onclick="onAction")
    v-divider.mb-3.mt-3
    overview-side-bar-item.menuSpacing(v-for="item in overviewItems3" :key="item.url"   :label="item.label" :icon="item.icon" :value="item.value" :url="item.url" @onclick="onAction")
</template>
<script>
import OverviewSideBarItem from "@/modules/global/components/others/OverviewSideBarItem";
export default {
  components: {
    OverviewSideBarItem
  },
  props:["currentCustomer"],
  computed: {

    overviewItems1: function() {
      let obj = [];
      obj.push({
        label: "Customer Overview",
        icon: "person",
        url: "customerDashboard"
      });
      // obj.push({
      //   label: "Photos",
      //   icon: "image",
      //   value: this.currentCustomer.images
      //     ? this.currentCustomer.images.length
      //     : 0,
      //   url: "customerPhotos"
      // });
      // obj.push({
      //   label: "Documents",
      //   icon: "insert_drive_file",
      //   value: this.currentCustomer.documents
      //     ? this.currentCustomer.documents.length
      //     : 0,
      //   url: "customerDocuments"
      // });
      // obj.push({
      //   label: "Comments",
      //   icon: "comment",
      //   value: this.$store.state.comments.comment_data.length,
      //   url: "customerComments"
      // });
      return obj;
    },
    overviewItems2: function() {
      let obj = [];
      // obj.push({
      //   label: "Location Visits",
      //   icon: "map",
      //   value: 1,
      //   url: "customerInspection"
      // });
      obj.push({
        label: "Inventory Records",
        icon: "category",
        value: this.$store.getters['customer/getInventoryCount'],
        url: "customerInventories"
      });
      obj.push({
        label: "Vehicle Visitations",
        icon: "local_shipping",
        value: this.$store.getters['customer/getVisitationsCount'],
        url: "customerVisitation"
      });
      // obj.push({
      //   label: "Emails Sent",
      //   icon: "notifications",
      //   value: 0,
      //   url: "customerRenewal"
      // });
      // obj.push({
      //   label: "SMS Sent",
      //   icon: "location_on",
      //   value: 0,
      //   url: "customerLocation"
      // });

      return obj;
    },
    overviewItems3: function() {
      let obj = [];
      // obj.push({
      //   label: "Notification Settings",
      //   icon: "settings",
      //   value: null,
      //   url: "customerNotification"
      // });
      return obj;
    }
  },
  data: () => ({}),

  methods: {
    onAction: function(url) {
      this.$router.push({
        name: url,
        params: { contactID: this.$route.params.contactID }
      }).catch(()=>{});
    }
  }
};
</script>
<style scoped lang="stylus">
.OverviewSideBar {
  width: 250px;
  height: 100%;
  background-color: #F5F5F5;
}

.menuSpacing {
  margin-bottom: 8px;
}
</style>
