<template lang="pug">
  div(v-if="$store.state.customer.currentIndex > -1")
    v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")

    v-container.py-2
      v-alert(v-if="error" :value="true" color="error" icon="warning")
        div(v-for="(e,index) in errors" :key="index" v-text="e")

    v-layout.mx-4(justify-end, align-center)
      v-btn.mx-4(icon small @click="previous")
        v-icon() skip_previous
      v-btn.mx-4(icon small @click="next")
        v-icon() skip_next
      v-btn.ma-2(small text color="grey" @click="cancel") cancel
      v-btn.ma-2(small color="primary" @click="save") Save
      v-btn.ma-2(small color="primary" @click="saveAndClose") Save & Close

    v-container
      v-card
        v-card-text
          v-container.grid-list-xl
            form-divider CUSTOMER ORGANIZATION DETAILS
            v-row.pt-4(justify="start")
              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="reference_id", label="Reference ID", hint="Enter Refrence ID", :persistent-hint="true")
              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="name", label="Organization Name", hint="Enter name of Vendor", :persistent-hint="true")
              v-col(cols="12" xs="12", md="6", xl="6")
                auto-combo-box(v-model='group_id' label="Group"  module="settings" submodule="groupSettings"  actionPrefix="Group")

              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="description", label="Description", hint="Organization Description", :persistent-hint="true")

              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="email", label="Organization Email", hint="Suppliers Email", :persistent-hint="true")
              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="phone", label="Organization Phone Number", hint="Phone Number", :persistent-hint="true")

            form-divider CONTACT PERSON
            v-row.pt-4(justify="start")
              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="first_name", label="Contact First Name", hint="Contact First Name", :persistent-hint="true")
              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="last_name", label="Contact Last Name", hint="Contact Last Name", :persistent-hint="true")

            form-divider LOCATION
            v-row.pt-4(justify="start")
              v-col(cols="12" xs="12", md="6", xl="6")
                places-auto-complete(v-model="places_id" @showMap="onShowMap" @closeMap="onCloseMap" label="Place (PIN Location)")

            form-divider ADDRESS
            v-row.pt-4(justify="start")
              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="address", label="Organization Postal Address", hint="P.O. Box Address", :persistent-hint="true")
              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="postal_code", label="Organization Postal Code", hint="Postal Code/Zip Code", :persistent-hint="true")
              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="city", label="City", hint="City", :persistent-hint="true")
              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="country", label="Country", hint="Country", :persistent-hint="true")
              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="street_address", label="Street Address", hint="Street Address/Road Name", :persistent-hint="true")
              v-col(cols="12" xs="12", md="6", xl="6")
                v-text-field(v-model="website", label="Organization Website", hint="Web page URL", :persistent-hint="true")

            form-divider CUSTOM FIELDS
            v-row(v-if="getCustomerCustomFields.length > 0")
              v-col.mt-3(v-for="(element,index) in getCustomerCustomFields" :key="index" cols="12" xs="12" md="6" xl="6")
                custom-field-component(:element="element" getterCurrentItemPath="customer/getCurrentCustomer" mutationTypePath="customer/updateCustomer")

    map-dialog.mapDialog(v-show="coordinates"  @closeMap="onCloseMap" :coordinates="coordinates" :radius="radius" :label="mapLabel" :zoom="zoom" :lineArray="lineArray")
</template>
<script>

import {createHelpers} from "vuex-map-fields";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import AutoComboBox from "@/modules/global/components/forms/AutoComboBox";
import PlacesAutoComplete from "@/modules/global/components/forms/PlacesAutoComplete";
import MapDialog from "@/modules/global/components/table/MapDialog";
import CustomFieldComponent from "@/modules/global/components/forms/CustomFieldComponent.vue";

const {mapFields} = createHelpers({
  getterType: "customer/getCustomer",
  mutationType: "customer/updateCustomer"
});

export default {
  components: {
    FormDivider,
    PlacesAutoComplete,

    AutoComboBox,
    MapDialog,
    CustomFieldComponent
  },
  data: () => ({
    error: false,
    errors: [],
    coordinates: null,
    radius: null,
    mapLabel: null,
    zoom: 0,
    lineArray: null,
  }),


  mounted() {
    if (this.$store.state.customer.currentIndex == -1) {
      this.$store
          .dispatch("customer/fetchCustomer", this.$route.params.contactID)
    }
  },

  computed: {
    ...mapFields([
      "reference_id",
      "name",
      "group_id",
      "first_name",
      "last_name",
      "description",
      "email",
      "phone",
      "street_address",
      "address",
      "postal_code",
      "city",
      "country",
      "website",
      "places_id",
    ]),

    getCustomerCustomFields(){
      return this.$store.getters["profile/getCustomFields"]("customer");
    },

  },
  methods: {



    async onShowMap(item) {
      this.coordinates = item.location;
      this.mapLabel = item.street;
      this.radius = null;
    },

     next(){
        this.$store.commit("customer/next");
     },
      previous() {
        this.$store.commit("customer/previous");
      },

    onCloseMap() {
      this.coordinates = null;
    },

    cancel: async function () {
      let tabID = (this.$store.state.profile.userInfo.is_administrator) ? 2 : 1;
      this.$router.push({name: "contacts", params: {tabID: tabID}}).catch(()=>{});
    },

    save: async function () {
      await this.$store.dispatch("customer/saveCustomer").then(() => {
        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Saved - ${this.name}`
        });
      });

    },

    saveAndClose: async function () {
      await this.$store.dispatch("customer/saveCustomer").catch(() => {
        this.$store.dispatch("customer/loadAllCustomers");
      });
      await this.cancel();
      this.$store.commit("showSnackBar", {
        color: "accent",
        message: `Organization Saved`
      });
    },

    deleteContactByIndex: function (index) {
      console.log(`delete contact ${this.contacts[index].id}`);
    },
    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Customer List",
        disabled: false,
        to: {name: "contacts",params:{tabID:2}}
      });
      theArray.push({
        text:this.name == null ? '': this.name,
        disabled: true
      });
      return theArray;
    }

  }
};
</script>
<style lang="stylus" scoped>
.classificationStyle {
  display: flex;
  flex-direction: row;
}
</style>
