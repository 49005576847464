<template lang="pug">
  div
    v-toolbar(color="primary" dark dense flat)
      v-badge(right, color="white" )
        span.primary--text(slot="badge", v-if="comments.length > 0") {{comments.length}}
        div.subheading.font-weight-bold Comments
      v-spacer
      v-dialog(v-model="dialog" width="500")
        template(v-slot:activator="{on}")
          v-btn(v-on="on" dark outlined small) + Add Comment 
        comment-dialog(@close="onCloseDialog"  refrenceType="vehicle" :refrenceID="$route.params.vehicleID")
      
    .pa-3
      div(v-for="(item,index) in lastItems" :key="item.id")
        vehicle-comment-item.mt-6( :item="item"  @edit="onEditComment" @delete="onDeleteComment")
    .d-flex.justify-end.ma-2(v-if="comments.length > lastItems.length")
      v-btn.mb-2(text color="primary" @click="goToVehicleComments") view more

    div(v-if="$store.state.comments.comment_data.length > 0")
      v-dialog(v-model="editDialog" width="500")
        v-card()
          v-card-title.px-8.grey.lighten-2() Edit Comment
          v-card-text()
            v-container.my-2()
              v-text-field(v-model='editTitle', label='Title')
              v-textarea(v-model='editComment', label='Comment')
          v-divider()
          v-card-actions()
            v-layout(justify-end)
              v-btn.ma-2(text @click="editDialog=false") Cancel
              v-btn.ma-2(color="primary" @click="saveComment()" :disabled="editComment == null") Save
</template>
<script>
import VehicleCommentItem from "@/modules/comments/components/lists/CommentItem";
import CommentDialog from "@/modules/comments/components/ui/CommentDialog";
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "comments/getComment",
  mutationType: "comments/updateComment"
});
export default {
  components: {
    VehicleCommentItem,
    CommentDialog
  },
  data: () => ({
    editDialog: false,
    dialog: false
  }),

  computed: {
    ...mapFields({
      editTitle: "title",
      editComment: "comment"
    }),
    lastItems: function() {
      return this.$store.getters["comments/getLastComments"](2);
    },
    comments: function() {
      return this.$store.state.comments.comment_data;
    }
  },

  methods: {
    goToVehicleComments: function() {
      this.$router.push({
        name: "vehicleComments",
        params: { vehicleID: this.$route.params.vehicleID }
      }).catch(()=>{});
    },
    onCloseDialog: function() {
      this.dialog = false;
    },
    saveComment: function() {
      this.$store.dispatch("comments/saveComment").then(() => {
        console.log("yesss it has worked");
      });
      this.editDialog = false;
    },
    onEditComment: function(item) {
      this.$store.commit("comments/setCurrentIndexByID", item.id);
      this.editDialog = true;
    },

    onDeleteComment: function(item) {
      confirm("Are you sure you want to delete this item?") &&
        this.$store.dispatch("comments/deleteComment", item.id);
    }
  }
};
</script>
<style scoped lang="stylus"></style>
