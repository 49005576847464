<template lang="pug">
  #blankcontent()
    .panelTitle() Open Issues
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-space-around.align-center.fill-height()
          .d-flex.flex-column.align-center()
            .tickerTitle.secondary--text() {{openIssues}}
            .tickerCaption.grey--text.text--darken-1() Open Issues
          .d-flex.flex-column.align-center()
            .tickerTitle.primary--text() {{closedIssues}}
            .tickerCaption.grey--text.text--darken-1() Closed Issues


</template>
<script>
import axios from "axios"

export default {
  data: () => ({
    openissues: [],
    closedissues: [],

  }),
  mounted() {
    axios.get("/api/dashboard/openissues").then(response =>{
      console.log(response.data)
      this.openissues = response.data.open_issues;
      this.closedissues = response.data.closed_issues;
    }

    
    )
    },

  computed: {
    openIssues() {
      return this.openissues;
    },
    closedIssues() {
      return this.closedissues;
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.tickerTitle {
  font-size: 45px;
}

.tickerCaption {
  margin-top: -10px;
  font-size: 12px;
}
</style>
