import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const MEASUREMENTUNIT_API = "/measurementUnit";

// ----------------------- STATE ------------------------- //
const state = {
  measurementunit_data: [],
  currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getMeasurementUnit: state =>
    getField(state.measurementunit_data[state.currentIndex]),

  getCurrentMeasurementUnit: state => {
    return state.measurementunit_data[state.currentIndex];
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updateMeasurementUnit(state, payload) {
    updateField(state.measurementunit_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.measurementunit_data.findIndex(
      measurementunit => measurementunit.id == theID
    );
  },

  loadAllMeasurementUnits(state, payload) {
    state.measurementunit_data = payload.data;
    state.currentIndex = 0;
  },

  addMeasurementUnit(state, payload) {
    state.measurementunit_data.push(payload.data);
    state.currentIndex = state.measurementunit_data.length - 1;
  },

  saveMeasurementUnit(state, payload) {
    state.measurementunit_data[state.currentIndex] = payload.data;
  },

  fetchMeasurementUnit(state, payload) {
    if (state.currentIndex == -1) {
      state.measurementunit_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.measurementunit_data[state.currentIndex] = payload.data;
    }
  },

  deleteMeasurementUnit(state, payload) {
    state.currentIndex = state.measurementunit_data.findIndex(
      measurementunit => measurementunit.id == payload.data.id
    );
    state.measurementunit_data.splice(state.currentIndex, 1);
  },
  reset(state) {
    state.measurementunit_data = []
    state.currentIndex = -1
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllMeasurementUnits({ commit }) {
    try {
      commit(
        "loadAllMeasurementUnits",
        await Repository.get(`${MEASUREMENTUNIT_API}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async addMeasurementUnit({ commit }) {
    await Repository.get(`${MEASUREMENTUNIT_API}/new`)
      .then(response => {
        commit("addMeasurementUnit", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async saveMeasurementUnit({ state, commit }) {
    try {
      commit(
        "saveMeasurementUnit",
        await Repository.post(
          `${MEASUREMENTUNIT_API}`,
          state.measurementunit_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchMeasurementUnit({ commit }, payloadID) {
    try {
      commit(
        "fetchMeasurementUnit",
        await Repository.get(`${MEASUREMENTUNIT_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deleteMeasurementUnit({ commit }, payloadID) {
    try {
      commit(
        "deleteMeasurementUnit",
        await Repository.delete(`${MEASUREMENTUNIT_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchMeasurementUnits({ commit }, searchTerm) {
    try {
      commit(
        "loadAllMeasurementUnits",
        await Repository.get(`${MEASUREMENTUNIT_API}/search?q=${searchTerm}`)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
