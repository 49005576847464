<template lang="pug">
div
  ul.pl-0
    li.pb-3(v-for='(item, index) in items' :key='index')
      template(v-if='!item.children')
        // Heading 1
        .title.font-weight-light.pl-2 {{ item.text }}
        v-divider.mt-3.ml-2
      template(v-else='')
        .iconSubmenu(v-if='item.text')
          v-icon.pr-2(style='font-size:20px;') {{ item.icon }}
          span(style='font-size:16px;') {{ item.text }}
        // Heading 2
        ul.pl-0
          li.theItem(v-for='(kid, kidIndex) in item.children' @click="$emit('selectItem', kid)" :key='kidIndex' :style='[selectedTo == kid.to ? activeStyle : inactiveStyle]')
            template(v-if="$route.name === kid.to")
              .selectedItem.grey.lighten-4.primary--text.font-weight-bold() {{ kid.text }}
            template(v-else)
              | {{kid.text}}

</template>
<script>
export default {
  props: {
    items: Array,
    selectedTo: String
  },
  data: () => ({}),

  computed: {
    activeStyle: function() {
      console.log(this.$vuetify.theme);
      return {
        "border-left-style": "solid",
        "border-left-width": "2px",
        "border-left-color": this.$vuetify.theme.primary,
        color: this.$vuetify.theme.primary
      };
    },

    inactiveStyle: function() {
      return {
        "margin-left": "2px"
      };
    }
  },

  methods: {}
};
</script>
<style scoped>
ul {
  list-style: none;
}

li {
}

.iconSubmenu {
  display: flex;
  padding-left: 6px;
}

.theItem {
  padding: 2px 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: #757575;
  font-size: 13px;
}

.theItem:hover {
  cursor: pointer;
  color: #424242;
  background-color: #f5f5f5;
}

.selectedItem{
  padding: 3px 5px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
