<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    v-text-field(v-model="selectHint" label="Description")
    v-checkbox(v-model="selectRequired" label="Required")

    div(@click.prevent="showAdvanced=!showAdvanced")
      showAdvancedProperties(:showAdvanced="showAdvanced" )
    div(v-show="showAdvanced")
      .subheading.mb-0() Image default size
        v-radio-group.mt-0(v-model="selectImageSize")
          v-radio(label="Small" value="small")
          v-radio(label="Medium" value="medium")
          v-radio(label="Large" value="large")
      v-checkbox(v-model="selectCommentOnFail" label="Must comment when no drawing is done")
      v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)
</template>
<script>
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";
import {inspectionPropertiesMixin} from "@/modules/inspections/components/formDesigner/properties/inspectionPropertiesMixin";
import ShowAdvancedProperties from "@/modules/workflow/formBuilder/components/ui/ShowAdvancedProperties.vue";
export default {
  mixins:[inspectionPropertiesMixin],
  components: {
    ShowAdvancedProperties,

  },

  data: () => ({
    properties: {},
    showAdvanced: false
  }),

  computed: {

    selectImageSize: {
      get: function() {
        return this.properties.image_size;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "image_size",
          value: value
        });
      }
    }
  },


};
</script>
<style scoped lang="stylus"></style>
