import { render, staticRenderFns } from "./CommandDialog.vue?vue&type=template&id=37074b46&scoped=true&lang=pug"
import script from "./CommandDialog.vue?vue&type=script&lang=js"
export * from "./CommandDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37074b46",
  null
  
)

export default component.exports