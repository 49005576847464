<template lang="pug">
  div()
    .signPlaceholder()
      template(v-if="item != null && computedValue != null")
        .signature(:style="{ 'background-image': 'url(' + '/media/' + $store.state.profile.userInfo.account_id +'/'+ item.file_name + ')'}")
          .d-flex.align-start.mt-2()

            v-icon.mx-3(@click="onDelete") delete
            .mb-2.grey--text {{schema.label}}
      template(v-else)
        img(src="/static/web/sign_placeholder.png" @click="openSignatureDialog" )
    v-dialog(v-model="dialog" width="800")
      signature-dialog(v-model="computedValue" @closeDialog="closeDialog" :title="schema.hint")
</template>
<script>

import SignatureDialog from "@/modules/global/components/dialog/SignatureDialog";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import {inspectionFillMixin} from "@/modules/inspections/components/formDesigner/fill/inspectionFillMixin";
import Repository from "@/repository";
export default {
  mixins: [inspectionFillMixin],
  components:{
    SignatureDialog,
    ImageThumbnail
  },
  data: () => ({
    item:null,
    dialog:false
  }),
  computed:{

    computedValue:{
      get: function() {
        return this.dataValue.value;
      },
      set: function(theValue){
        console.log(theValue);
        if(theValue != null){
          this.theValue = theValue.id;
        }else{
          this.theValue = null;
        }
        this.item = theValue;

        this.update();
      }
    },
  },
  methods:{
    openSignatureDialog:function(){
      this.dialog = true;
    },

    closeDialog:function(){
      this.dialog = false;
    },
    loadImage: function(blobID) {
      Repository.get(`/upload/blob/${blobID}`)
          .then(payload => {
            this.item = payload.data;
          })
          .catch(payload => {
            console.log(payload.data);
          });
    },

    onDelete: function(){
      this.theValue = null;
      this.item = null;
      this.update();
      this.openSignatureDialog();
    },

  },
  mounted() {
    console.log()
    if(this.item == null){
      this.loadImage(this.computedValue);
    }

  }
};
</script>
<style scoped lang="stylus">
.signPlaceholder{
  border 2px solid #CCCCCC;
  display flex;
  justify-content center;
  align-items center;
  border-radius 10px;
  background-size contain;
  background-position center;
  width 240px;
  height 120px;
}
.signPlaceholder img{
  width 240px;
  height 120px;
}
  .signature{
    width 240px;
    height: 120px;
    background-size: cover;
  }
</style>
