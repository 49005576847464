<template lang="pug">
  div()
    div.px-4.py-3()
      .subtitle-1.primary--text() Assignments
    div()
      .fill-width.white.px-3.py-4()
        table.fill-width.assignedOperator
          tr(v-for="(item,index) in items" :key="index" :class="index == items.length-1?'':'BottomLine'")
            td()
              contact-column(v-if="item.contact" :contact="item.contact")
            td.caption() {{item.description}}
            td.caption
              div()
                b Start:
                | &nbsp;{{formatDate(item.during.start)}}
              div(style="min-width:220px;")
                b End:
                | &nbsp; {{formatDate(item.during.end)}}
</template>
<script>
import axios from "axios";
import moment from "moment";
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";
import ContactColumn from "@/modules/global/components/table/ContactColumn";

export default {
  components:{
    PanelTitleHeader,
    ContactColumn
  },
  data: () => ({
    items:[],
  }),
  methods: {
    loadItems: function(){
      axios.get(`/api/assignment/vehicle/${this.$route.params.vehicleID}`).then( response =>{
        this.items = response.data
      })
    },

    formatDate: function(theDate){
      return moment(theDate).format("h:mm a, ddd, Do MMM YYYY")
    }
  },
  mounted(){
    this.loadItems();
  }
};
</script>
<style scoped lang="stylus">
.assignedOperator{
  border-collapse: collapse;
}
.assignedOperator tr td{
  padding 10px 5px;
}

.BottomLine {
  border-bottom 1px solid #CCCCCC;
}
</style>
