<template lang="pug">
  svg(:width="width" :height="height" )
    rect(ref="bgRect"  :width="width" :height="height"   fill="#f9f9f9"  )
    line(v-for="(item,index) in steps" :key="`t${index}`" stroke-width="1" stroke="#999999" :x1="getInterval(index)" :y1="0" :x2="getInterval(index)"  :y2="width" )
    text.schedulerTitle(v-for="(item,index) in steps" :key="`h${index}`" :x="getInterval(index)+titleAnchor" :y="20" fill="black" :width="width/steps" ) {{getTitleText(index)}}
    text.schedulerSubTitle(v-for="(item,index) in steps" :key="`m${index}`" :x="getInterval(index)+subTitleAnchor"  :y="38" fill="#8d8d8d" :width="width/steps"  ) {{getSubTitleText(index)}}
</template>
<script>
import moment from "moment";

export default {
  props: {
    width: Number,
    height: Number,
    steps: Number,
    substeps: Number
  },
  data: () => ({
    titleAnchor: 12,
    subTitleAnchor: 10
  }),

  components: {},

  methods: {
    getInterval: function (index) {
      if (this.$store.getters["vehicles/assignments/getMode"] == "year") {
        return index * (this.width / 12);
      } else {
        return index * (this.width / this.steps);
      }
    },

    getTitleText: function (index) {
      // get Start Date
      let startDateMoment = moment(
          this.$store.getters["vehicles/assignments/getStartDate"]
      );

      let label = "none";
      switch (this.$store.getters["vehicles/assignments/getMode"]) {
        case "hour":
          label = startDateMoment.add(index * 5, "minutes").format("HH:mm");
          this.subTitleAnchor = 10;
          this.titleAnchor = 12;
          break;
        case "day":
          label = startDateMoment.add(index, "hours").format("h");
          this.subTitleAnchor = 10;
          this.titleAnchor = 12;
          break;
        case "week":
          label = startDateMoment.add(index, "days").format("dddd");
          this.subTitleAnchor = 10;
          this.titleAnchor = 12;
          break;
        case "month":
          label = startDateMoment.add(index, "days").format("D");
          this.subTitleAnchor = 4;
          this.titleAnchor = 7;
          break;
        case "year":
          label = startDateMoment.add(index, "months").format("MMM");
          this.subTitleAnchor = 10;
          this.titleAnchor = 12;

          break;
      }
      return label;
    },

    getSubTitleText: function (index) {
      let startDateMoment = moment(
          this.$store.getters["vehicles/assignments/getStartDate"]
      );

      let label;
      switch (this.$store.getters["vehicles/assignments/getMode"]) {
        case "hour":
          label = startDateMoment.add(index * 5, "minutes").format("A");
          break;
        case "day":
          label = startDateMoment.add(index, "hours").format("A");
          break;
        case "week":
          label = startDateMoment.add(index, "days").format("Do MMM YYYY");
          break;
        case "month":
          label = startDateMoment.add(index, "days").format("MMM");
          break;
        case "year":
          label = startDateMoment.add(index, "months").format("YYYY");
          break;
      }

      return label;
    }
  },
  mounted() {
    // console.log("hello ")
  }
};
</script>
<style scoped>
.schedulerTitle {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  font-weight: bold;
  user-select: none;
  text-align: center;
}

.schedulerSubTitle {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
  text-align: center;
  /* user-select: none; */
}
</style>
