<template lang="pug">
div
  panel-title-header(label="Vehicle Information" btnVisible btnLabel="Edit" :routeObj="{name:'vehicleNew',params:{vehicleID:$route.params.vehicleID}}")
  v-tabs.tabs(left='', height='32', slider-color='primary')
    v-tab.tabs
      | Details
    v-tab-item
      information-details
    v-tab.tabs 
      | Specs
    v-tab-item
      information-generic(:info="specData")
    v-tab.tabs 
      | Engine &amp; Transmission
    v-tab-item
      information-generic(:info="engineTransmissionData")
    v-tab.tabs 
      | Wheels &amp; Tires
    v-tab-item
      information-generic(:info="wheelsTiresData")
    v-tab.tabs 
      | Fluids
    v-tab-item
      information-generic(:info="fluidsData")

 
</template>
<script>
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";
import InformationDetails from "@/modules/vehicles/components/ui/Overview/InformationDetails";
import InformationGeneric from "@/modules/vehicles/components/ui/Overview/InformationGeneric";

export default {
  components: {
    InformationDetails,
    InformationGeneric,
    PanelTitleHeader
  },

  data: () => ({}),
  computed: {
    currentVehicle: {
      get: function() {
        return this.$store.getters["vehicles/getCurrentItem"];
      }
    },
    specData() {
      return [
        {
          title: "Dimensions",
          data: [
            { label: "Length", value: this.currentVehicle.length, units: "cm" },
            { label: "Width", value: this.currentVehicle.width, units: "cm" },
            {
              label: "Wheel Base",
              value: this.currentVehicle.wheel_base,
              units: "cm"
            }
          ]
        },
        {
          title: "Weight",
          data: [
            {
              label: "Gross Weight",
              value: this.currentVehicle.gross_weight,
              units: "Kg"
            },
            {
              label: "Max Payload",
              value: this.currentVehicle.max_payload,
              units: "Kg"
            }
          ]
        }
      ];
    },
    engineTransmissionData() {
      return [
        {
          title: "Engine",
          data: [
            { label: "Engine Summery", value: this.currentVehicle.engine },
            {
              label: "Engine Cylinders",
              value: this.currentVehicle.engine_cylinders
            },
            {
              label: "Engine Displacement",
              value: this.currentVehicle.engine_displacement
            },
            {
              label: "Engine Horse Power",
              value: this.currentVehicle.engine_horse_power
            },
            {
              label: "Engine Aspiration",
              value: this.currentVehicle.engine_aspiration
            }
          ]
        },
        {
          title: "Transmission",
          data: [
            {
              label: "Transmission Drive Train",
              value: this.currentVehicle.transmission_drive_train
            },
            {
              label: "Transmission Type",
              value: this.currentVehicle.transmission_type
            },
            {
              label: "Transmission Gears",
              value: this.currentVehicle.transmission_gears
            }
          ]
        }
      ];
    },
    wheelsTiresData() {
      return [
        {
          title: "Suspension",
          data: [
            {
              label: "Front Suspension",
              value: this.currentVehicle.front_suspension
            },
            {
              label: "Rear Suspension",
              value: this.currentVehicle.rear_suspension
            }
          ]
        },
        {
          title: "Brakes",
          data: [
            { label: "Front Brakes", value: this.currentVehicle.front_brakes },
            { label: "Rear Brakes", value: this.currentVehicle.rear_brakes }
          ]
        },
        {
          title: "Tires",
          data: [
            { label: "Tire Size", value: this.currentVehicle.tire_size },
            { label: "Tire Pressure", value: this.currentVehicle.tire_pressure }
          ]
        }
      ];
    },
    fluidsData() {
      return [
        {
          title: "Fluids",
          data: [
            { label: "Fuel Type", value: this.currentVehicle.fuel_type },
            {
              label: "Fuel Capacity",
              value: this.currentVehicle.fuel_tank_capacity,
              units: "Litres"
            },
            {
              label: "Fuel Consumption (MPG)",
              value: this.currentVehicle.fuel_mpg,
              units: "MPG"
            }
          ]
        }
      ];
    }
  },

  methods: {
    onClick: function(value) {
      console.log(value);
    }
  }
};
</script>
<style scoped lang="stylus"></style>
