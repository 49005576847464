import { render, staticRenderFns } from "./InspectionFailureListLayout.vue?vue&type=template&id=5b5fc5ca&scoped=true&lang=pug"
import script from "./InspectionFailureListLayout.vue?vue&type=script&lang=js"
export * from "./InspectionFailureListLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5fc5ca",
  null
  
)

export default component.exports