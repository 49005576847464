<template lang="pug">
  div(v-if="list")
    .iconChip()
      v-icon(:size="20") {{icon}}
      | {{list.length}}
</template>
<script>
export default {
  props: {
    icon: String,
    list: Array
  },
  data: () => ({})
};
</script>
<style scoped lang="stylus">
.iconChip {
  border: solid 1px #CCCCCC;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  width: 50px;
  padding: 2px 2px;
  justify-content: space-around;
}
</style>
