<template lang="pug">
  v-layout(row='')
    v-flex
      v-card(outlined)
        v-subheader() {{title}}
        v-list(subheader='', dense='')
          v-divider
          v-list-item(v-for='(item, index) in menuItems', :key='item.title', @click='selectedItem(index)', :class="{ ['grey lighten-4']: index == selectedIndex }")
            v-icon(:class="{ ['primary white--text']: index == selectedIndex }") {{ item.icon }}
            v-list-item-content.ml-2
              v-list-item-title(:class="{ ['primary--text']: index == selectedIndex }")
                | {{ item.title }}

</template>

<script>
export default {
  props: {
    menuItems: Array,
    selectedIndex: {
      type: Number,
      default: 0
    },
    title: String
  },

  methods: {
    selectedItem: function(index) {
      this.$emit("selectedForm", index);
    },

  }
};
</script>

<style></style>
