<template lang="pug">
  div
    v-autocomplete(
      v-model="vehicles"
      :items="items == null ? $store.state.vehicles.all: items"
      :loading="isLoading"
      :search-input.sync="search"
      item-text="name"
      item-value="id"
      :label="label"
      :placeholder= "placeholder"
      chips
      @change="onChange"
      :filter="onFilter"
      :clearable="clearable"
      :disabled="disabled"
    )
      template(slot="selection" slot-scope="data")
        v-chip.chip--select-multi(:input-value="data.selected")
          image-thumbnail.ml-n2.mr-2(:width='30', :height='30', :radius='15' :src="data.item.thumbnail_id")
          | {{data.item.name}}

      template(slot="item" slot-scope="data")
        template(v-if="typeof data.item !== 'object'")
          v-list-item-content(v-text="data.item")
        template(v-else)
          v-list-item-content
            v-layout(align-center)
              image-thumbnail.mr-3(:width="60" :height="40"  :src="data.item.thumbnail_id")
              v-layout.ml-2(column)
                .body-2.font-weight-bold {{data.item.name}}
                .caption {{data.item.make}} · {{data.item.model}} · {{ data.item.numberplate}}
</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";

export default {
  components: {
    ImageThumbnail
  },

  props: {
    value: String,
    label: {
      type: String,
      default: "Select Vehicle"
    },
    placeholder: {
      type: String,
      default: "Start typing a Vehicle"
    },
    items: Array,
    clearable: {
      type: Boolean,
      default: false,
    },

      disabled:{
        type:Boolean,
        default:false,
      }

  },

  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    vehicles: null,
    search: null
  }),

  methods: {
    setData: function (theData) {
      this.$emit("input", theData);
    },

    onChange: function (theValue) {
      this.$emit("input", theValue);
      this.$emit("change", theValue);
    },

    onFilter: function (item, queryText) {
      const hasValue = val => (val != null ? val : "");
      const query = hasValue(queryText);
      const toSearchText =
          item.name +
          " " +
          item.vin +
          " " +
          item.model +
          " " +
          item.make +
          " " +
          item.numberplate;
      return (
          toSearchText
              .toString()
              .toLowerCase()
              .indexOf(query.toString().toLowerCase()) > -1
      );
    }
  },

  mounted() {
    this.vehicles = this.value;
    // if (this.$store.state.vehicles.length < 2) {
    this.isLoading = true;
    this.$store.dispatch("vehicles/loadAllItems").then(() => {
      this.isLoading = false;
    });
  }
};
</script>
<style lang="stylus" scoped>
.imageCrop {
  width: 60px;
  height: 40px;
  color: red;
  border-radius: 5px;
  background-color: #CCCCCC;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.emptyThumbnail {
  width: 60px;
  height: 40px;
  border: 2px dashed #CCCCCC;
  border-radius: 5px;
}
</style>
