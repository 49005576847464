import { render, staticRenderFns } from "./IconChip.vue?vue&type=template&id=27bf34da&scoped=true&lang=pug"
import script from "./IconChip.vue?vue&type=script&lang=js"
export * from "./IconChip.vue?vue&type=script&lang=js"
import style0 from "./IconChip.vue?vue&type=style&index=0&id=27bf34da&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27bf34da",
  null
  
)

export default component.exports