<template lang="pug">
  .borderTextInput
    .prefixSymbol.grey--text(v-if="prefix") {{prefix}}
    input.baseStyle(ref='theInput' :type="type", :value='value', @input="$emit('input',$event.target.value)"  :disabled="disabled" :style="[disabled ?  disabledStyle: isFocused ? focusStyle : ''  ]" @focus="isFocused=true" @blur="isFocused=false")
</template>
<script>
export default {
  props: ["value", "prefix", "type", "disabled"],

  data: () => ({
    isFocused: false
  }),

  computed: {
    focusStyle: function() {
      return {
        "border-color": this.$vuetify.theme.primary
      };
    },

    disabledStyle: function() {
      return {
        "border-color": "#CCCCCC",
        "background-color": "#DEDEDE",
        color: "#444444"
      };
    }
  }
};
</script>
<style scoped lang="stylus">

.baseStyle {
  border-width: 1px;
  border-style: solid;
  border-color: #BBBBBB;
  border-radius: 5px;
  min-width: 80px;
  width: 100%;
  height: 32px;
  text-align: right;
  padding: 5px 4px 5px 12px;
  background-color: none;
  float left

}

.prefixSymbol {
  width 40px;
  height 34px;
  // background-color #FF0000

  margin-right -40px;
  padding-left 5px;
  display flex;
  align-items center

}

.borderTextInput {
  display flex;
}
</style>
