<template lang="pug">
  div
    v-toolbar(color="primary" dark dense flat)
      div.subheading.font-weight-bold Contact Renewals
    div(v-if="showContent")
      .PairItems
        number-guage(:value="countOverdueReminders" label="Overdue" )
        number-guage(:value="countDueSoonReminders" label="Due Soon")
      v-divider
      div.my-2
        reminder-item(v-for="(item, index) in contactReminders" :key="index" :item="item")
    .title.grey--text.text-xs-center.pa-3(style="height:60px" v-else )
      | No Renewals Overdue or Due Soon
</template>
<script>
import NumberGuage from "@/modules/global/components/dashboard/NumberGuage";
import ReminderItem from "@/modules/reminders/components/ui/ReminderItem";
export default {
  components: {
    NumberGuage,
    ReminderItem
  },
  data: () => ({}),

  methods: {
    getVehicleID: function() {
      return this.$route.params.vehicleID;
    }
  },

  computed: {
    countOverdueReminders: function() {
      return this.$store.getters["reminders/contact_renewal/getOverdue"](
        this.getVehicleID()
      );
    },

    countDueSoonReminders: function() {
      return this.$store.getters["reminders/contact_renewal/getDueSoon"](
        this.getVehicleID()
      );
    },

    contactReminders: function() {
      return this.$store.getters[
        "reminders/contact_renewal/getVehicleRenewalReminder"
      ](this.getVehicleID());
    },

    showContent: function() {
      if (this.vehicleReminders == null || this.vehicleReminders == 0) {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.$store.dispatch(
      "reminders/contact_renewal/fetchByVehicleIDContact_renewal",
      this.$route.params.vehicleID
    );
  }
};
</script>
<style scoped lang="stylus">
.PairItems {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
