<template lang="pug">
  div()
    .listHeader()
      v-layout(align-center justify-center fill-height)
        .title.grey--text.text--lighten-1() Vehicles
    
    .listItem(v-for="(vehicle,index) in getData" :key="index" :style="{'height':`${rowHeight}px`}") 
      scheduler-vehicle-item(:vehicle="vehicle" :height="rowHeight")
 
</template>
<script>
import SchedulerVehicleItem from "@/modules/vehicles/components/scheduler/SchedulerVehicleItem";
export default {
  components: {
    SchedulerVehicleItem
  },
  props: {
    rowHeight: Number
  },
  data: () => ({}),
  methods: {},
  mounted() {
    // console.log("hello ")
    console.log(this.getData);
  },

  computed: {
    getData: function() {
      return this.$store.getters["vehicles/assignments/getData"];
    }
  }
};
</script>
<style scoped>
.listHeader {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  user-select: none;
}

.listItem {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  background-color: white;
  user-select: none;
}
</style>
