import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const FUELTYPE_API = "/expenseType";

// ----------------------- STATE ------------------------- //
const state = {
  expensetype_data: [],
  currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getExpenseType: state => getField(state.expensetype_data[state.currentIndex]),

  getCurrentExpenseType: state => {
    return state.expensetype_data[state.currentIndex];
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updateExpenseType(state, payload) {
    updateField(state.expensetype_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.expensetype_data.findIndex(
      expensetype => expensetype.id == theID
    );
  },

  loadAllExpenseTypes(state, payload) {
    state.expensetype_data = payload.data;
    state.currentIndex = 0;
  },

  addExpenseType(state, payload) {
    state.expensetype_data.push(payload.data);
    state.currentIndex = state.expensetype_data.length - 1;
  },

  saveExpenseType(state, payload) {
    state.expensetype_data[state.currentIndex] = payload.data;
  },

  fetchExpenseType(state, payload) {
    if (state.currentIndex == -1) {
      state.expensetype_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.expensetype_data[state.currentIndex] = payload.data;
    }
  },

  deleteExpenseType(state, payload) {
    state.currentIndex = state.expensetype_data.findIndex(
      expensetype => expensetype.id == payload.data.id
    );
    state.expensetype_data.splice(state.currentIndex, 1);
  },

  deleteExpenseTypeList(state, payload) {
    for (var item in payload.data) {
      let index = state.expensetype_data.findIndex(
        expensetype => expensetype.id == item.id
      );
      state.expensetype_data.splice(index, 1);
    }
  },
  reset(state) {
    state.expensetype_data = []
    state.currentIndex = -1
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllExpenseTypes({ commit }) {
    try {
      commit("loadAllExpenseTypes", await Repository.get(`${FUELTYPE_API}`));
    } catch (error) {
      console.log(error);
    }
  },

  async addExpenseType({ commit }) {
    await Repository.get(`${FUELTYPE_API}/new`)
      .then(response => {
        commit("addExpenseType", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async saveExpenseType({ state, commit }) {
    try {
      commit(
        "saveExpenseType",
        await Repository.post(
          `${FUELTYPE_API}`,
          state.expensetype_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchExpenseType({ commit }, payloadID) {
    try {
      commit(
        "fetchExpenseType",
        await Repository.get(`${FUELTYPE_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deleteExpenseType({ commit }, payloadID) {
    try {
      commit(
        "deleteExpenseType",
        await Repository.delete(`${FUELTYPE_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deleteExpenseTypeList({ commit }, payload) {
    try {
      commit(
        "deleteExpenseTypeList",
        await Repository.post(`${FUELTYPE_API}/deleteList`, payload)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchExpenseTypes({ commit }, searchTerm) {
    try {
      commit(
        "loadAllExpenseTypes",
        await Repository.get(`${FUELTYPE_API}/search?q=${searchTerm}`)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
