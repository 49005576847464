<template lang="pug">
div(v-if="$store.state.reminders.vehicle_renewal.currentIndex > -1")
  v-toolbar(dense, flat)
    v-toolbar-title Vehicle Renewal Form
    v-spacer
  v-divider

  v-container.py-2
    v-alert(v-if="error" :value="true" color="error" icon="warning") 
      div(v-for="(e,index) in errors" :key="index" v-text="e")

    v-layout(justify-end)
      v-btn.ma-2(small text color="grey" @click="cancel") cancel
      //v-btn.ma-2(small outlined color="primary" @click="saveAdd"  ) Save &amp; Add another
      v-btn.ma-2(small color="primary" @click="save"  ) Save

  v-container
    v-card
      v-form(ref="form" v-model="valid" lazy-validation)
       v-container.grid-list-xl()
        v-row(align="end")
          v-col(cols="12" xs="12", md="6")
            vehicles-auto-complete(v-model="vehicles_id" :multiple="true")
          v-col(cols="12" xs="12", md="6")
            autos-combo-box(v-model='vehicle_renewal_types_id'  label="Select Vehicle Renewal Types" module="reminders" submodule="vehicle_renewal_type"   actionPrefix="Vehicle_renewal_type")
  
        v-divider
        v-row()
          v-col(cols="12" xs="12", md="6")
            odometer-reminder(v-model="odoReminderObj" mode="vehicle_renewal")
          v-col(cols="12" xs="12", md="6")
            time-reminder(v-model="timeReminderObj"  mode="vehicle_renewal")

        v-divider
        v-row()
          v-col(xs="12", md="6")
            contacts-auto-complete(v-model="contacts_id")
          v-col(xs="12", md="6")
            v-row()
              v-col(cols="6")
                v-switch(label="Email Notifications" v-model="email_notifications" hint="If ON, emails are sent when reminder is 'due soon' or overdue", :persistent-hint="true")
              v-col(cols="6")
                v-switch(label="SMS Notifications" v-model="sms_notifications" hint="If ON, sms are sent when reminder is 'due soon' or overdue", :persistent-hint="true")
        v-row
          v-col(cols="12")
            v-textarea(v-model="comments" rows=3 label="comments")

  v-container.py-2
    v-alert(v-if="error" :value="true" color="error" icon="warning") 
      div(v-for="(e,index) in errors" :key="index" v-text="e")

    v-layout(justify-end)
      v-btn.ma-2(small text color="grey" @click="cancel") cancel
      //v-btn.ma-2(small outlined color="primary" @click="saveAdd"   ) Save &amp; Add another
      v-btn.ma-2(small color="primary" @click="save" ) Save
</template>
<script>
import VehiclesAutoComplete from "@/modules/global/components/forms/VehiclesAutoComplete";
import ContactsAutoComplete from "@/modules/global/components/forms/ContactsAutoComplete";
import AutosComboBox from "@/modules/global/components/forms/AutosComboBox";
import OdometerReminder from "@/modules/reminders/components/ui/OdometerReminder";
import TimeReminder from "@/modules/reminders/components/ui/TimeReminder";
import { createHelpers } from "vuex-map-fields";


const { mapFields } = createHelpers({
  getterType: "reminders/vehicle_renewal/getVehicle_renewal",
  mutationType: "reminders/vehicle_renewal/updateVehicle_renewal"
});

export default {
  components: {
  VehiclesAutoComplete,
  ContactsAutoComplete,
  AutosComboBox,
  OdometerReminder,
  TimeReminder
  },
  data: () => ({
    error: false,
    errors: [],
    valid: true
  }),

  computed: {
    ...mapFields([
      "vehicle_renewal_types_id",
      "vehicles_id",
      "start_date",
      "interval",
      "due_date_amount",
      "repeat",
      "start_odometer",
      "interval_odometer",
      "due_odometer",
      "contacts_id",
      "email_notifications",
      "sms_notifications",
      "comments",
      "odo_reminder",
      "time_reminder"
    ]),
    timeReminderObj: {
      get: function() {
        return {
          time_reminder: this.time_reminder,
          start_date: this.start_date,
          interval: this.interval,
          due_date_amount: this.due_date_amount
        };
      },
      set: function(value) {
        console.log("setting the timeRiminder object");
        console.log(value);
        this.time_reminder = value.time_reminder;
        this.start_date = value.start_date;
        this.interval = value.interval;
        this.due_date_amount = value.due_date_amount;
      }
    },
    odoReminderObj: {
      get: function() {
        return {
          odo_reminder: this.odo_reminder,
          start_odometer: this.start_odometer,
          interval_odometer: this.interval_odometer,
          due_odometer: this.due_odometer
        };
      },
      set: function(value) {
        console.log("setting odometer obj");
        console.log(value);
        this.odo_reminder = value.odo_reminder;
        this.start_odometer = value.start_odometer;
        this.interval_odometer = value.interval_odometer;
        this.due_odometer = value.due_odometer;
      }
    }
  },

  methods: {
    validateForm: function() {
      this.error = false;
      this.errors = [];

      if (this.vehiclesID === null) {
        this.errors.push("Select a Vehicle(s)");
        this.error = true;
      }

      if ( this.vehicle_renewal_types_id.length > 0 ){
        this.errors.push("Select a Vehicle Renewal Type");
        this.error = true;
      }

      if (this.contactID === null) {
        this.errors.push("Select a Contact(s) to send the reminder");
        this.error = true;
      }

      if (this.startDate.getTime() < new Date().getTime()) {
        this.errors.push("Enter a Date and Time in the future.");
        this.error = true;
      }

      return this.error;
    },

    cancel: function() {
      this.$router.push({ name: "reminders", params: { tabID: 1 } }).catch(()=>{});
    },

    save: function() {

            this.$store
                .dispatch("reminders/vehicle_renewal/saveVehicle_renewal")
                .then(() => {
                    this.$router.push({name: "reminders", params: {tabID: 1}}).catch(()=>{});
                });

    },

    saveAdd: function() {
      // todo: Save and Add Another
    }
  },

  mounted() {
    // get the Service Type
    if (this.$store.state.reminders.vehicle_renewal_type.currentIndex === -1) {
      this.$store.dispatch(
        "reminders/vehicle_renewal_type/loadAllVehicle_renewal_types"
      );
    }

    if (this.$store.state.reminders.vehicle_renewal.currentIndex === -1) {
      this.$store
        .dispatch(
          "reminders/vehicle_renewal/fetchVehicle_renewal",
          this.$route.params.reminderID
        )
        .then(() => {});
    }
  }
};
</script>
<style scoped lang="stylus"></style>
