<template lang="pug">
.itemsSection
  v-btn.mt-3(small  color="primary" @click="add") add
  table.mt-2(v-if="items.length > 0" style="width:100%" cellspacing="0")
    tr.itemTable.grey.lighten-4
      th(style="width:45%") Item
      th(style="width:18%") Unit Cost
      th(style="width:15%") Quantity
      th(style="width:5% text-align:right") Subtotal
      th
    tr.itemTable( v-for="(item,index) in items" :key="index")
      td
        v-autocomplete.pa-0.ma-0(v-model="item.itemID" item-text="itemNumber"  item-value="id" :items="$store.state.item.item_data" hide-details)
      td
        border-text-input(v-model="item.unitCost" :prefix="$store.state.profile.userInfo.account.region_settings.currency" type="number" )
      td
        border-text-input(v-model="item.quantity" prefix="Kilometers" type="number" )
      td(style="text-align:right") {{item.unitCost * item.quantity}}
      td
        more-drop-down-menu(:items="menuitems" @onClick="onClick" :theObject="item")


</template>
<script>
import BorderTextInput from "@/modules/global/components/forms/BorderTextInput";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";

export default {
  components: {
    BorderTextInput,
    MoreDropDownMenu
  },

  data: () => ({
    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ],

    items: [
      {
        id: 0,
        itemID: "65039b9d-2a2f-4653-a9a5-60132db92371",
        unitCost: 23234,
        quantity: 3,
        subtotal: 42123
      },
      {
        id: 1,
        itemID: "445004c8-681e-4886-9ef1-df2bf39b1f2b",
        unitCost: 232,
        quantity: 2,
        subtotal: 42123
      }
    ]
  }),

  methods: {
    add: function () {
      this.items.push({
        itemID: "445004c8-681e-4886-9ef1-df2bf39b1f2b",
        unitCost: 100,
        quantity: 2,
        subtotal: 42123
      });
    },
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit": {
          console.log("edit");
          // this.$store.commit("item/setCurrentIndexByID",value.theObject.id);
          break;
        }
        case "delete": {
          console.log(value.theObject);
          let theIndex = this.items.findIndex(
              item => item.id === value.theObject.id
          );
          this.items.splice(theIndex, 1);
          break;
        }
          // this.$store.dispatch("item/deleteItem",value.theObject.id)
      }
    }
  },

  mounted() {
    if (this.$store.state.item.currentIndex == -1) {
      // load Data
      this.$store.dispatch("item/loadAllItems").then(() => {
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.itemTable table
  width: 100%;
  border: 1px solid #CCCCCC

.itemTable
  text-align: left


.itemTable th
  border-top: 1px solid #CCCCCC
  padding 6px 10px
  border-bottom: 1px solid #CCCCCC

.itemTable td
  padding 7px 10px
  border-top: 1px solid #F2F2F2

.itemsSection
  border 1px solid #CCCCCC
  border-radius 10px
  padding-bottom 8px
</style>
