<template lang="pug">
  v-data-table(
    v-model="selectedIssues"
    :headers="headers" 
    :search="search" 
    :custom-filter="issueModuleFilter"  
    :items="getFilteredList" 
    item-key="id" 
    :loading="loading"  
    :single-select="false"
    @click:row="onClickRow"
    show-select
    )
    template(v-slot:progress)
      v-progress-linear(slot="progress"  height="2" :indeterminate="loading")

    template(v-slot:header.name="{ header }")
      v-tooltip(bottom)
        template(#activator="{on}")
          span(v-on="on") {{header.text}}
        span {{header.tooltip}}

      
    template(v-slot:item.vehicle="{item}")
      .issueSelect()
        vehicle-column(v-if="item.vehicle" :vehicle="item.vehicle")

    template(v-slot:item.name="{item}")
      issue-column(:issue="item")

    template(v-slot:item.closed="{item}")
      div(v-if="item.closed_by")
        .caption() {{item.closed_date_time | moment("ddd, Do MMM YYYY, h:mm a") }}
        .IssueSmallText() Closed by: 
          span.primary--text() {{item.closed_by.first_name}} {{item.closed_by.last_name}}
        timeago.primary--text.caption(:datetime="item.closed_date_time")

    template(v-slot:item.resolved="{item}")
      div(v-if="item.service_date_time")
        .IssueSmallText() Resolved on: 
          span.underline.primary--text(@click="onSelectServiceResolve(item.service_id)") {{item.service_date_time | moment("ddd, Do MMM YYYY, h:mm a") }}
        timeago.primary--text.caption(:datetime="item.service_date_time")

    template(v-slot:item.due="{item}")
      div(v-if="item.due_date_time")
        .IssueSmallText() {{item.due_date_time | moment("ddd, Do MMM YYYY, h:mm a") }}
        timeago.primary--text.caption(:datetime="item.due_date_time")

    template(v-slot:item.reported_by="{item}")
      div(v-if="item.reported_by")
        .caption.grey--text() Reported By: &nbsp;
          span.primary--text.font-weight-bold.underline() {{item.reported_by.first_name}} {{item.reported_by.last_name}}
      div(v-if="item.assigned_to")
        .caption.grey--text() Assigned To: &nbsp;
          span.font-weight-bold.underline() {{item.assigned_to.first_name}} {{item.assigned_to.last_name}}
      .d-flex.mt-2()
        IconChip(v-if="item.photos_id" icon="photo" :list="item.photos_id")
        IconChip.ml-4(v-if="item.documents_id" icon="insert_drive_file" :list="item.documents_id")
        //- IconChip.ml-4(icon="comment" :list="77")

    template(v-slot:item.assigned="{item}")
      contact-column(v-if="item.assigned_to" :contact="item.assigned_to")

    template(v-slot:item.photos="{item}")
      v-row(v-if="item.photos_id")
        image-thumbnail.ml-2(v-for="(itemID,index) in getPhotos(item.photos_id)" :key="index" :width='60', :height='60', :src="itemID")
          

    template( v-slot:item.action="{ item }")
      v-row.mr-7(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

</template>

<script>
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import ContactColumn from "@/modules/global/components/table/ContactColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import IssueColumn from "@/modules/global/components/table/IssueColumn";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import IconChip from "@/modules/global/components/table/IconChip";
import { searchMixin } from "@/mixin/searchListMixin";
export default {
  components: {
    VehicleColumn,
    MoreDropDownMenu,
    IssueColumn,
    ContactColumn,
    ImageThumbnail,
    IconChip
  },

  props: {
    search: String,
    status: {
      type: String,
      default: "open"
    }
  },

  mixins: [searchMixin],

  data: () => ({
    loading: false,

  }),

  computed: {

    selectedIssues: {
      get() {
        return this.$store.state.issues.selectedIssues;
      },
      set(value) {
        this.$store.commit("issues/setSelectedIssues", value);
      }
    },
    headers: function() {
      let tempHeaders = [
        {
          text: "Vehicle",
          value: "vehicle",
          tooltip: "Vehicle Name",
          align: "left"
        },
        { text: "Issue", value: "name", tooltip: "Issue", align: "left" }
      ];

      switch (this.status) {
        case "closed":
          tempHeaders.push({
            text: "Closed",
            value: "closed",
            align: "left"
          });
          break;
        case "resolved":
          tempHeaders.push({
            text: "Resolved",
            value: "resolved",
            align: "left"
          });
          break;
        case "overdue":
          tempHeaders.push({
            text: "Due Date",
            value: "due",
            align: "left"
          });
          break;
      }

      tempHeaders.push({
        text: "Reported / Assigned",
        value: "reported_by",
        tooltip: "Reported By",
        align: "left"
      });

      tempHeaders.push({
        text: "Photos",
        value: "photos",
        align: "left"
      });

      tempHeaders.push({
        text: "Action",
        value: "action",
        align: "right"
      });
      return tempHeaders;
    },

    getFilteredList: function() {
      return this.$store.getters["issues/getIssueByStatus"](this.status);
    },

    menuitems: function() {
      let tempMenuItems = [
        { title: "Edit", icon: "edit", action: "edit" },
        { title: "Delete", icon: "delete", action: "delete" }
      ];

      switch (this.status) {
        case "open":
          tempMenuItems.unshift({
            title: "Close Issue",
            icon: "close",
            action: "closeIssue"
          });
          tempMenuItems.unshift({
            title: "Add to Work order",
            icon: "assignment",
            action: "workorder"
          });
          tempMenuItems.unshift({
            title: "Resolve via Service Entry",
            icon: "build",
            action: "service"
          });

          break;
        case "overdue":
          tempMenuItems.unshift({
            title: "Close Issue",
            icon: "close",
            action: "closeIssue"
          });
          tempMenuItems.unshift({
            title: "Add to Work order",
            icon: "assignment",
            action: "workorder"
          });
          tempMenuItems.unshift({
            title: "Resolve via Service Entry",
            icon: "build",
            action: "service"
          });

          break;
        case "closed":
          tempMenuItems.unshift({
            title: "Re-Open Issue",
            icon: "lock_open",
            action: "openIssue"
          });

          break;
      }
      return tempMenuItems;
    }
  },

  methods: {
    onSelectVehicle: function(item) {
      this.$store.commit("issues/setCurrentIndexByID", item.id);
      this.$router.push({
        name: "issueOverview",
        params: { issueID: item.id }
      }).catch(()=>{});
    },
    getPhotos: function(list) {
      if (list.length > 2) {
        return list.slice(0, 2);
      } else {
        return list;
      }
    },
    onClickRow: function(item){
      this.$store.commit("issues/setCurrentIndexByID", item.id);
      this.$router.push({
        name: "issueOverview",
        params: { issueID: item.id }
      }).catch(()=>{});
    },
    onClick: function(value) {
      // console.log(value.theObject);
      switch (value.action) {
        case "edit":
          this.$store.commit("issues/setCurrentIndexByID", value.theObject.id);
          this.$router.push({
            name: "issueDetailsForm",
            params: { issueID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          confirm(
            "Are you sure you want to delete issue: " + value.theObject.name
          ) && this.$store.dispatch("issues/deleteIssue", value.theObject.id);
          break;
        case "history":
          console.log("history");
          break;
        case "closeIssue":
          this.$store
            .dispatch("issues/closeIssue", value.theObject.id)
            .then(() => {
              // or this.$emit("changeState", "closed");
              this.$store.dispatch("issues/loadAllIssues");
            });
          break;
        case "openIssue":
          this.$store
            .dispatch("issues/openIssue", value.theObject.id)
            .then(() => {
              // or  this.$emit("changeState", "open");
              this.$store.dispatch("issues/loadAllIssues");
            });
          break;
        case "service":
          console.log("resolve by service");
          break;
      }
    },

    issueModuleFilter: function(value, search, item) {
      let isFound = false;
      let patt = new RegExp(search.toLowerCase(), "i");
      // Search the Object
      isFound = this.searchObject(item, patt, isFound);

      // Search Vehicle
      isFound = this.searchObject(item.vehicle, patt, isFound);

      // Search Tags
      isFound = this.searchList(item.tags, patt, isFound);

      // Search Reported_By
      isFound = this.searchObject(item.reported_by, patt, isFound);

      // Search Assigned To
      isFound = this.searchObject(item.assigned_to, patt, isFound);

      return isFound;
    },

    onSelectServiceResolve: function(serviceID) {
      this.$router.push({
        name: "serviceDetailForm",
        params: { serviceID: serviceID }
      }).catch(()=>{});
    },

    onSelectUser: function(contactID) {
      console.log(`select contact:${contactID}`);
    }
  },

  mounted() {
    if (this.$store.state.issues.issue_data.length < 2) {
      this.$store.dispatch("issues/loadAllIssues").then(() => {});
    }
  }
};
</script>

<style scoped>
.underline {
  cursor: pointer;
}
.underline:hover {
  text-decoration: underline;
}
.issueSelect {
  cursor: pointer;
}
.issueSelect:hover {
}
</style>
