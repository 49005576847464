<template lang="pug">
  div()
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    v-text-field(v-model="selectHint" label="Description")


    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        showAdvancedProperties(:showAdvanced="showAdvanced" )
      div(v-show="showAdvanced")
        //v-text-field(v-model="selectDefault" label="Default Value")
        permissions-component()
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)


</template>

<script>
import ShowAdvancedProperties from "@/modules/workflow/formBuilder/components/ui/ShowAdvancedProperties.vue";
import SETTINGS_DEFAULT from "@/modules/workflow/formBuilder/store/formSettings";
import PermissionsComponent from "@/modules/workflow/formBuilder/components/ui/PermissionsComponent.vue";

export default {
  components: {
    ShowAdvancedProperties,
    PermissionsComponent
  },

  data: () => ({
    properties: {},
    showAdvanced: false
  }),
  computed: {
    selectedFieldName: {
      get: function() {
        return this.properties.label;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "label",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },

    selectedFormat: {
      get: function() {
        return this.properties.time_format;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "time_format",
          value: value
        });
      }
    },

    selectHint: {
      get: function() {
        return this.properties.hint;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "hint",
          value: value
        });
      }
    },

    selectID: {
      get: function() {
        return this.$store.getters[SETTINGS_DEFAULT.GET_PATH].id;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    }
  },
  mounted() {
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    this.$refs.fieldName.focus();

    console.log(this.properties);
  }

}
</script>

<style scoped>

</style>