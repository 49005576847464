<template lang="pug">
  div
    .btnArrangement
      v-btn.mb-2(small='' color='primary' dark='' @click='addNew') Add New
    form-sub-header Role Settings
    v-data-table(:headers='headers' :items='$store.state.settings.roleSettings.role_data' :loading='loading')


      template(v-slot:progress)
        v-progress-linear( height="2" :indeterminate="loading")

      template( v-slot:item.contacts="{ item }")
        div()
        .d-flex.flex-wrap()
          v-tooltip(v-for="(contact,index) in item.contacts" :key="index" bottom)
            template(v-slot:activator="{ on, attrs }")
              .contactRound.grey.white--text.pointer(v-bind="attrs"  v-on="on")  {{contact.first_name[0]}}{{contact.last_name[0]}}
            div() {{contact.first_name}} {{contact.last_name}}

      template( v-slot:item.action="{ item }")
        v-row.mr-7(justify="end")
          more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')
</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
export default {
  components: {
    FormSubHeader,
    MoreDropDownMenu
  },
  data: () => ({
    loading: false,
    selected: [],
    headers: [
      { text: "Name", value: "name", align: "left" },
      { text: "Users", value: "contacts", align: "left" },
      { text: "Actions", value: "action", align: "right" }
    ],

    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Make Copy", icon: "file_copy", action: "copy" },
      { title: "Delete", icon: "delete", action: "delete" }
    ],

    defaultRole: "Fleet Manager"
  }),
  methods: {
    addNew: function() {
      this.$store.dispatch("settings/roleSettings/addNew").then(() => {
        let theID = this.$store.getters["settings/roleSettings/getCurrentRole"].id;
        this.$router.push({ name: "roleform", params: { roleID: theID } }).catch(()=>{});
      });
    },

    onClick: function(value) {
      switch (value.action) {
        case "edit":
          this.$store.commit(
            "settings/roleSettings/setCurrentIndexByID",
            value.theObject.id
          );
          this.$router.push({
            name: "roleform",
            params: { roleID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          this.$store.dispatch(
            "settings/roleSettings/deleteRole",
            value.theObject.id
          );
          break;
        case "copy":
          this.$store.commit(
            "settings/roleSettings/setCurrentIndexByID",
            value.theObject.id
          );
          this.$store.dispatch("settings/roleSettings/copyRole");
          break;
      }
    }
  },

  mounted() {
    this.$store.dispatch("settings/roleSettings/fetchRoleSettings");
  }
};
</script>

<style scoped>
.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;

  padding: 0px;
}
</style>
