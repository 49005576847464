<template lang="pug">
  div
    // Tabs Selector
    .white.pt-2
      v-tabs.tabs(left='', height='32', slider-color='primary', v-model='active')
        v-tab.tabs(v-if='$store.getters["profile/getViewPermission"]("place")')  Places List
        v-tab-item
          places-list-layout()
        v-tab.tabs(v-if='$store.getters["profile/getViewPermission"]("osrm")')  Routes List
        v-tab-item
          router-list-layout()
        v-tab.tabs(v-if='$store.getters["profile/getViewPermission"]("journey")')  Journey List
        v-tab-item
          journey-list-layout()

</template>

<script>
import PlacesListLayout from "@/modules/places/layouts/PlacesListLayout";
import RouterListLayout from "@/modules/places/layouts/RouterListLayout";
import JourneyListLayout from "@/modules/places/layouts/JourneyListLayout";

export default {
  components: {
    PlacesListLayout,
    RouterListLayout,
    JourneyListLayout
  },

  computed: {
    active: {
      get: function() {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          tabIndex = tabIndex > 2 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function(value) {
        this.$route.params.tabID = value;
      }
    }
  }
};
</script>

<style scoped></style>
