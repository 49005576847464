<template lang="pug">
  div
    v-divider
    v-container.pa-2(fluid)
      v-layout(row, wrap)
        h2 Purchase Orders
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            v-btn(small,outlined, color='primary', dark, slot='activator')
              | Actions
              v-icon(dark) keyboard_arrow_down
            v-list
              v-list-tile(v-for='item in items', :key='item.title')
                v-list-tile-title {{ item.title }}
          v-btn.mb-2(small, color='primary', dark, @click='openEdit') Add Purchase Order
    v-divider
    v-layout.px-3(row, wrap)
      v-item-group
        v-item
          v-chip(small, :color="active ? 'primary white--text elevation-0': 'white primary--text'", slot-scope='{ active, toggle }', @click.native='toggle', :selected='active')
            | Open
        v-item
          v-chip(small, :color="active ? 'primary white--text elevation-0': 'white primary--text'", slot-scope='{ active, toggle }', @click.native='toggle', :selected='active')
            | Overdue
        v-item
          v-chip(small, :color="active ? 'primary white--text elevation-0': 'white primary--text'", slot-scope='{ active, toggle }', @click.native='toggle', :selected='active')
            | Resolved
        v-item
          v-chip(small, :color="active ? 'primary white--text elevation-0': 'white primary--text'", slot-scope='{ active, toggle }', @click.native='toggle', :selected='active')
            | Closed
    v-layout.px-3()
      v-flex(xs12 md10 xl8)
        v-layout(row align-center)
          v-text-field.pl-2(v-model='searchFilter', label='Search Filter', :persistent-hint='true', required)
          v-text-field.pl-2(v-model='vehiclesFilter', label='Vehicles Filter', :persistent-hint='true', required)
          v-text-field.pl-2(v-model='locationFilter', label='Location Filter', :persistent-hint='true', required)
          v-btn.mb-2(small, color='primary', dark) Search
          div
            span.primary--text 1 filter
            span.pl-1.grey--text.text--darken-1 applied
          span.px-3.grey--text.text--darken-1 clear all
    v-layout
      purchase-order-stepper


    .grey.lighten-4.py-3
    v-divider
    purchase-order-table
    v-divider
</template>

<script>
import PurchaseOrderTable from "@/modules/inventory/items/components/tables/PurchaseOrderTable";
import PurchaseOrderStepper from "@/modules/inventory/items/components/ui/purchaseOrderStepper";

export default {
  components: {
    PurchaseOrderTable,
    PurchaseOrderStepper
  },

  data: () => ({
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ],

    vehiclesFilter: "",
    searchFilter: "",
    locationFilter: ""
  }),

  methods: {
    openEdit: function () {
      this.$store.dispatch("purchaseOrder/addPurchaseOrder").then(() => {
        let currentObject = this.$store.state.purchaseOrder.purchaseorder_data[
            this.$store.state.purchaseOrder.currentIndex
            ];
        this.$router.push({
          name: "purchaseOrderDetailsForm",
          params: {itemID: currentObject.id}
        }).catch(()=>{});
      });
    },

    showDialog: function () {
    },

    closeDialog: function (value) {
      console.log(value);
    }
  },

  mounted() {
    // console.log(this.$vuetify.theme.primary);
    this.toggle;
  }
};
</script>

<style scoped></style>
