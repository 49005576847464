<template lang="pug">
  v-data-table.elevation-1(
    v-model="selected"
    :custom-filter="expensesFilter"
    :search="search"
    :headers='headers'
    :items='$store.state.expenses.expense_data'
    item-key="id"
    :loading='loading'
    :single-select="false"
    show-select
    @click:row="onDblClickRow"
    )
    template(v-slot:item.vehicle="{item}")
      vehicle-column(v-if="item.vehicle" :vehicle="item.vehicle")

    template(v-slot:item.date_time="{item}")
      .SmallText.grey--text.text--darken-2
        div
          v-icon(small) access_time
          span.font-weight-bold() &nbsp;&nbsp;{{item.date_time | moment("ddd, Do MMM YYYY, h:mm a") }}
        div(v-if="item.merchant")
          v-icon(small) business
          | &nbsp;&nbsp;{{item.merchant.name}}
        div(v-if="item.reported_by")
          v-icon(small) person
          | &nbsp;&nbsp;{{item.reported_by.first_name}} {{item.reported_by.last_name}}

    template(v-slot:item.expense_type="{item}")
      div(v-if="item.expense_type") 
        div {{item.expense_type.name}}
        .caption.grey--text()  {{item.expense_type.description}}

    template(v-slot:item.expense_items="{item}")
      table(id="expenseItemTable")
        tr
          th() Qty
          th.alignLeft() Item
          th.alignRight(style="text-alight:right") @
          th.alignRight(style="text-alight:right") Amount
        tr(v-for="(item,index) in item.expense_items" :key="index")
          td.qty() {{item.quantity}}
          td.item() {{item.item}}
          td.costperitem() {{item.cost_per_item}}
          td.amount() {{item.amount}}

    template(v-slot:item.merchant="{item}")
      div(v-if="item.merchant") 
        div() {{item.merchant.name}} 
        .caption.grey--text() {{item.merchant.description}} 

    template( v-slot:item.action="{ item }")
      v-row.mr-1(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')
    

 
   

</template>
<script>
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import DateColumn from "@/modules/global/components/lists/DateColumn";

export default {
  props: {
    search: String
  },
  components: {
    VehicleColumn,
    MoreDropDownMenu,
    DateColumn
  },
  data: () => ({
    selected: [],
    loading: false,
    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "archive", action: "delete" }
    ],
    headers: [
      {
        text: "Vehicle",
        value: "vehicle",
        tooltip: "Vehicle Name"
      },
      { text: "Info", value: "date_time" },
      { text: "Expense Type", value: "expense_type" },
      { text: "Expense Items", value: "expense_items" },
      { text: "Total", value: "total" },
      { text: "Actions", value: "action", align: "right", sortable: false }
    ]
  }),

  methods: {
    onDblClickRow: function (value) {
      this.$store.commit("expenses/setCurrentIndexByID", value.id);
      this.$router.push({
        name: "expenseOverview",
        params: {expenseID: value.id}
      }).catch(()=>{});
    },

    onClick: function(value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit(
            "expenses/setCurrentIndexByID",
            value.theObject.id
          );
          this.$router.push({
            name: "expenseForm",
            params: { vehicleID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          confirm("Are you sure you want to delete this item?") &&
            this.$store.dispatch("expenses/deleteExpense", value.theObject.id);
          break;
      }
    },

    expensesFilter: function(value, search, item) {
      let isFound = false;
      let patt = new RegExp(search.toLowerCase(), "i");
      // Search the Object
      for (var key in item) {
        if (item[key] != n.align-righull) {
          if (item[key].toString().match(patt)) {
            isFound = true;
            break;
          }
        }
      }

      if (isFound == false) {
        for (key in item.vehicle) {
          if (item.vehicle[key] != null) {
            if (item.vehicle[key].toString().match(patt)) {
              isFound = true;
              break;
            }
          }
        }
      }

      return isFound;
    }
  }
};
</script>
<style scoped lang="stylus">
.SmallText {
  font-size: 11px;
}

#expenseItemTable{
  width : 400px;
  border-collapse: collapse;
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: 10px
}

#expenseItemTable th{
  border 1px solid #CCC;
  background-color: #EEE;
  font-size: 9.5px;
  color: #3b3b3b;
}
#expenseItemTable td {
  border 1px solid #CCC;
  color: #3f3f3f;
}
#expenseItemTable .qty {
  text-align: center;
  width: 30px;
}

#expenseItemTable .item{
  width: 180px;
  padding-left: 10px;
}

#expenseItemTable .costperitem{
  width: 60px;
  text-align: right;
  padding-right: 10px;
}

#expenseItemTable .amount{
  width: 80px;
  text-align: right;
  padding-right: 10px;
}


#expenseItemTable .alignRight{
  text-align: right;
  padding-right: 10px;
}

#expenseItemTable .alignLeft{
  text-align: left;
  padding-left: 10px;
}
</style>
