<template lang="pug">
  div()
    .d-flex.align-center()
      .d-flex.flex-column.pointer(@click="onClick" :class="disabled?'grey--text':'primary--text'")
        .d-flex()
          v-icon() schedule
          div.px-2() {{renderDate}}
        .caption.grey--text() {{schema.label}}
      v-dialog(v-model="showDialog" width="600" @click:outside="onClose"  persistent)
        div.primary.py-2.px-3()
          .d-flex.justify-space-between.align-center()
            div.white--text() {{renderDate}}
            v-btn( @click="onClose" color="white" )
              .primary--text.pr-2() Save
              v-icon(color="primary" ) save
        vue-ctk-date-time-picker(
          id="somID"
          v-model="computedValue",
          inline

          outputFormat="YYYY-MM-DDTHH:mm:ssZ"
          :no-header="true"
          :noLabel="true"
          :button-color="$vuetify.theme.themes.light.primary"
          :color="$vuetify.theme.themes.light.primary")
      add-comment-button.mx-1(v-model="comments"  @showCommentInput="onShowCommentInput")
      upload-blob.mx-1(v-model="blobs" type="image" label="Label" hint="hint" :isMultiple="true")
    .d-flex()
      v-tooltip(bottom v-for="(item,index) in comments" :key="item.time")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") message
        span() {{item.value}}
      .mx-2()
      v-tooltip(bottom v-for="(item,index) in blobs" :key="item")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") image
        div()
          image-thumbnail(:width='360', :height='200', :src="item")

    div.mt-2(v-if="showCommentInput")
      v-textarea(outlined  ref="comment"  label="Comment" auto-grow rows="2" v-model="comment" hide-details )
      .d-flex.my-2()
        v-spacer
        v-btn.mx-2(small color='grey darken-1', text, @click.native="cancel()") Close
        v-btn.mx-2(small color='primary', @click.native="save()") Save


</template>
<script>

import AddCommentButton from "@/modules/inspections/components/formDesigner/component/AddCommentButton";
import UploadBlob from "@/modules/global/components/forms/UploadBlob"
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import {inspectionFillMixin} from "@/modules/inspections/components/formDesigner/fill/inspectionFillMixin";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import moment from "moment/moment";
export default {
  mixins: [inspectionFillMixin],
  components: {
    AddCommentButton,
    UploadBlob,
    ImageThumbnail,
    VueCtkDateTimePicker
  },
  props: ["schema", "dataValue", "index","disabled"],
  data: () => ({
    showDialog: false,
    resultDate: null,
  }),
  computed:{
    requiredRule: function() {
      return this.schema.required ? value => !!value || "Required." : false;
    },

    renderDate: function(){
      return  moment(new Date(this.dataValue.value)).format("ddd, Do MMM YYYY, h:mm a")
    },
    computedValue: {
      get: function() {
        if (this.dataValue.value != null){
          return moment(new Date(this.dataValue.value)).format("YYYY-MM-DD h:mm a")
        }else{
          return this.dataValue;
        }

      },

      set: function(theValue) {
        this.theValue = moment.utc(theValue).format();
        this.update();
      }
    },
  },

  methods:{
    onClose:function(){
      this.showDialog=false;
    },
    onClick: function(){
      if(this.disabled){
        return;
      }
      this.showDialog=true;
    },
  },

  mounted() {

    if (this.dataValue.value == null){
      this.computedValue = new Date().toUTCString();
    }

  }

}
</script>