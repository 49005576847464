import TemplateBuilderForm from "@/modules/workflow/formBuilder/components/forms/TemplateBuilderForm";
import RecordForm from "@/modules/workflow/records/components/forms/RecordForm";
import BoardLayout from "@/modules/workflow/board/layouts/BoardLayout";
import TemplateFillForm from "@/modules/workflow/inspections/layouts/TemplateFillForm";
import WorkflowTableEditor from "@/modules/workflow/dataTables/layouts/WorkflowTableEditor";
import RecordTableLayout from "@/modules/workflow/records/layouts/RecordTableLayout";


import WorkflowOverview from "@/modules/workflow/overview/layout/WorkflowOverview";
import WorkflowRecordOverview from "@/modules/workflow/records/layouts/WorkflowRecordOverview";
import WorkflowDashboard from "@/modules/workflow/overview/SubLayout/WorkflowDashboard";
import WorkflowPermissions from "@/modules/workflow/overview/SubLayout/WorkflowPermissions";
import WorkflowProcess from "@/modules/workflow/overview/SubLayout/WorkflowProcess";
import WorkflowRecords from "@/modules/workflow/overview/SubLayout/WorkflowRecords";
import WorkflowRecordTable from "@/modules/workflow/overview/SubLayout/WorkflowRecordTable";
import WorkflowSettings from "@/modules/workflow/overview/SubLayout/WorkflowSettings";
export const WORKFLOW_ROUTES = [
  {
    path: "workflowOverview",
    name: "workflowOverview",
    component: WorkflowOverview,
    children: [
      {
        path: "workflowDashboard",
        name: "workflowDashboard",
        component: WorkflowDashboard
      },
      {
        path: "workflowBoardOverview",
        name: "workflowBoardOverview",
        component: BoardLayout
      },
      {
        path: "workflowProcess",
        name: "workflowProcess",
        component: WorkflowProcess
      },
      {
        path: "workflowPermissions",
        name: "workflowPermissions",
        component: WorkflowPermissions
      },
      {
        path: "workflowRecordsTable",
        name: "workflowRecordsTable",
        component: WorkflowRecordTable
      },
      {
        path: "workflowRecords",
        name: "workflowRecords",
        component: WorkflowRecords
      },
      {
        path: "workflowSettings",
        name: "workflowSettings",
        component: WorkflowSettings
      }
    ]
  },
  {
    path: "templateBuilderForm",
    name: "templateBuilderForm",
    component: TemplateBuilderForm
  },
  {
    path: "workflowRecordForm/:workflowRecordID",
    name: "workflowRecordForm",
    component: RecordForm
  },
  {
    path: "workflowBoard",
    name: "workflowBoard",
    component: BoardLayout
  },
  {
    path: "templateFillForm",
    name: "templateFillForm",
    component: TemplateFillForm
  },
  {
    path: "workflowTableEditor",
    name: "workflowTableEditor",
    component: WorkflowTableEditor
  },
  {
    path: "recordTableLayout",
    name: "recordTableLayout",
    component: RecordTableLayout
  },
  {
    path: "workflowRecordOverview/:workflowRecordID",
    name: "workflowRecordOverview",
    component: WorkflowRecordOverview,
  }
];
