<template lang="pug">
  div(v-if="$store.state.settings.groupSettings.currentIndex > -1")
    .theForm.mb-6
      v-btn.ma-2(small color="primary" outlined @click="cancel") Cancel
      v-btn.ma-2(small color="primary" @click="save") Save
    v-card
      v-container
        v-layout(row wrap)
          v-flex(xs12)
            v-text-field(v-model="name"  hint="Enter name of the Group" :persistent-hint="true"  label="Group Name*"  required)
          v-flex(xs12)
            v-textarea(v-model="description"  hint="Enter name of the Description" :persistent-hint="true"  label="Description*"  required)
          v-flex(xs12)
            v-color-picker(v-model="color" :swatches="swatches" show-swatches)
          v-flex(xs12)
            v-select(:items="$store.getters['settings/groupSettings/getGroupsMinusSelf']"  v-model="parent" item-value="id" item-text="name" label="Parent Group" dense)
              
          




</template>
<script>
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "settings/groupSettings/getGroup",
  mutationType: "settings/groupSettings/updateGroup"
});

export default {
  data: () => ({
    swatches: [
      ["#B71C1C", "#880E4F", "#4A148C"],
      ["#311B92", "#1A237E", "#E65100"],
      ["#01579B", "#333333", "#004D40"],
      ["#3E2723", "#33691E", "#827717"],
      ["#BF360C", "#ec037b", "#000000"]
    ]
  }),

  computed: {
    ...mapFields({
      name: "name",
      description: "description",
      parent: "parent",
      color: "color"
    })
  },

  methods: {
    getAccess: function(item) {
      if (item.create && item.view && item.edit && item.delete) {
        return "Full";
      } else if (!item.create && !item.view && !item.edit && !item.delete) {
        return "No";
      } else {
        return "Some";
      }
    },

    cancel: function() {
      this.$store.dispatch("settings/groupSettings/fetchGroupSettings");
      this.$store.dispatch("settings/groupSettings/loadAllGroups")
      this.$router.push({ name: "groupsettings" }).catch(()=>{});
    },

    save: function() {
      var theObject = this.$store.state.settings.groupSettings.group_data[
        this.$store.state.settings.groupSettings.currentIndex
      ]; //todo: dont know why getCurrentItem does not work

      console.log(theObject);

      this.$store.dispatch("settings/groupSettings/saveGroup");
      this.$store.dispatch("settings/groupSettings/loadAllGroups")
      this.$router.push({ name: "groupsettings" }).catch(()=>{});
    }
  },

  mounted() {
    this.$store.dispatch("settings/groupSettings/loadAllGroups").then(() => {
      console.log(this.$store.state.settings.groupSettings.group_data);
      this.$store.commit(
        "settings/groupSettings/setCurrentIndexByID",
        this.$route.params.groupID
      );
    });
  }
};
</script>
<style scoped lang="stylus">
.theForm {
  display: flex;
  justify-content: flex-end;
}

.permmisions {
  border-bottom: 1px solid #E5E5E5;
  margin-top: 10px;
}

.entries {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #E8E8E8;
  border-radius: 9px;
  padding: 0px 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  height: 40px;
}
</style>
