<template lang="pug">
  div
    .btnArrangement
      v-btn.mb-2(small, color='primary' @click.prevent="newVehicleType") Add New
    form-sub-header.white Vehicle Renewal Types
    v-container(white)
        v-text-field(style="max-width:400px;" v-model="searchFilter", label="Search Filter", single-line append-icon="search" :persistent-hint="true" required) 
    v-divider
    vehicle-renewal-types-table(:search="searchFilter")
</template>
<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import VehicleRenewalTypesTable from "@/modules/reminders/components/tables/VehicleRenewalTypesTable";

export default {
  components: {
    FormSubHeader,
    VehicleRenewalTypesTable
  },
  data: () => ({
    searchFilter: ""
  }),

  methods: {
    newVehicleType: function() {
      this.$store
        .dispatch("reminders/vehicle_renewal_type/addVehicle_renewal_type")
        .then(() => {
          let currentIndex = this.$store.state.reminders.vehicle_renewal_type
            .currentIndex;
          let currentObject = this.$store.state.reminders.vehicle_renewal_type
            .vehicle_renewal_type_data[currentIndex];
          this.$router.push({
            name: "vehicleRenewalTypeForm",
            params: { vehicleRenewalTypeID: currentObject.id }
          }).catch(()=>{});
        });
    }
  }
};
</script>
<style scoped lang="stylus">
.btnArrangement {
  display: flex;
  justify-content: flex-end;
}
</style>
