<template lang="pug">
  div.mb-8()
    div()
    v-toolbar(color="primary" dark dense flat)
      div.subheading.font-weight-bold
        slot(name="title")
          | Title
      v-spacer
      slot(name="actions")
          
    div()
      slot(name="body")
        | body template

</template>
<script>
export default {
  data: () => ({})
};
</script>
<style scoped lang="stylus"></style>
