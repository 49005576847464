<template lang="pug">
  v-layout()
    v-autocomplete(
        v-model="result" 
        :items="entries"
        :search-input.sync="search"
        hide-no-data
        item-text="name",
        item-value="location",
        filled
        :loading="isLoading"
        placeholder="Start typing to Search"
        prepend-inner-icon="place"
        return-object
        @change="$emit('input', result)"
        )
      template(v-slot:item="data")
        v-list-item-content()
          v-list-item-title(v-html="data.item.name")
          v-list-item-subtitle(v-text="data.item.subtitle")
    v-icon.iconClose(@click="onCloseAutocomplete") close
</template>
<script>
import axios from "axios";
export default {

  data: () => ({
    result: null,
    isLoading: false,
    places: null,
    entries: [],
    search: null
  }),
  created: function() {
    this.debouncedGetLocation = this._.debounce(this.getLocations, 500);
  },
  watch: {
    search(val) {
      console.log(val);
      this.isLoading = true;
      this.debouncedGetLocation();
    }
  },

  computed: {},
  methods: {
    onCloseAutocomplete:function(){
      this.result = null;
      this.name = null;
    },


    getLocations: function() {
      console.log("debouncer called here ");
      this.entries = [];
      if(this.search == null || this.search.length == 0){
        this.isLoading = false;
        return;
      }

      // get cordinates from string

      if(this.search.indexOf(",") > 0 && this.search.split(",").length == 2){
        var locationArray = this.search.split(",")
        if(!isNaN(locationArray[0]) && !isNaN(locationArray[1])){
          let lat =  parseFloat(locationArray[0]);
          let lon =  parseFloat(locationArray[1]);
          this.$emit('input', {location:{lon:lon,lat:lat}})
        }
        this.isLoading=false;
        return;
      }



      axios
        .get(`/api/track/geocoding?search=${this.search}`)
        .then(response => {
          // console.log(response.data);

          response.data.forEach(item => {
            // console.log(item);

            let  subtitle = "";
            subtitle += (item.street.length > 0)?item.street+", ":"";
            subtitle += (item.locality.length > 0)?item.locality+", ":"";
            subtitle += (item.county.length > 0)?item.county:"";

            this.entries.push({
              name: item.name,
              subtitle: subtitle,
              location: item.location
            });

            this.isLoading = false;
          });
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
<style scoped lang="stylus">
.iconClose{
  cursor:pointer;
}

</style>
