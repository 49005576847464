<template lang="pug">
  div()
    location-select(v-model='computedValue' :width="400" :height="200" :label="schema.label" :description="schema.hint" :key="refreshKey" :disabled="disabled")
</template>
<script>

import LocationSelect from "@/modules/global/components/forms/LocationSelect";
export default {
  props: ["schema", "dataValue", "index","disabled"],
  components:{
    LocationSelect
  },
  data: () => ({
    refreshKey:0,
  }),
  watch: {
      computedValue:function(oldValue,newValue){
        if(newValue == null || oldValue == null){
          return;
        }

        if (oldValue.lat != newValue.lat ){
          this.refreshKey++;
        }
      }

  },
  computed:{
    computedValue:{
      get: function() {
        return this.dataValue;
      },
      set: function(theValue){

        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    },
  },
  methods:{
  }
};
</script>
<style scoped lang="stylus">

</style>
