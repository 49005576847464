<template lang="pug">
  v-layout(row)
    div.ml-2(v-if="imageUrl" :style="{'background-image':`url(\"${imageUrl}\")`, ' background-repeat':'no-repeat','background-size': 'cover','background-position': 'center',  'width':'40px','height':'40px'}" width="40" height="40" )
    v-layout.ml-2(column)
      .smallTitleTooltip() {{assignment.contact.first_name}} {{assignment.contact.last_name}}
      .smallSubTitleTooltip() {{beginDateTimeF}} &nbsp;&nbsp;&mdash;&nbsp;&nbsp;{{endDateTimeF}}
</template>
<script>
import moment from "moment";
export default {
  data: () => ({}),
  computed: {
    assignment: function() {
      return this.$store.getters["vehicles/assignments/getSelectedAssignment"];
    },

    imageUrl: function() {
      if (this.assignment.thumbnail) {
        return `/media/${this.assignment.account_id}/${this.assignment.thumbnail}`;
      } else {
        return null;
      }
    },
    beginDateTimeF: function() {
      return moment(this.assignment.during.tz_start).format(
        "ddd, h:mm a, Do MMM YYYY"
      );
    },

    endDateTimeF: function() {
      return moment(this.assignment.during.tz_end).format(
        "ddd, h:mm a, Do MMM YYYY "
      );
    }
  }
};
</script>
<style scoped lang="stylus">
.smallTitleTooltip {
  font-size 14px;
  font-font-weight bolder;
}

.smallSubTitleTooltip {
  font-size 12px;

}

.textDiv{
  display flex;
  flex-direction column;
}
</style>
