<template lang="pug">
  v-card()
    v-card-title.px-8.grey.lighten-2()
      | Import File
      v-spacer
      v-btn(icon)
        v-icon(@click="onClose") close
    v-divider()
    v-progress-linear(v-if="showProgress" indeterminate)
    v-container()

      v-stepper(:value="step")
        v-stepper-header
          v-stepper-step(step="1" :complete="step1Complete" ) Download CSV Template
          v-divider()
          v-stepper-step(step="2" :complete="step2Complete") Import Populated CSV File


      v-container()
        template(v-if="step==1")
          .step1
            a.d-flex.flex-column.justify-center.grey.lighten-3.ma-2.pa-4.rounded-xl(:href="templateUrl" target="_blank" @click="goToImport")
              .subtitle-1() Download the Template
              img.ma-6(src="/static/web/excel.png" style="width:120px")

        template(v-else-if="step==2")
          .d-flex.flex-column.justify-center.align-center(style="min-height:200px")
            .d-flex.flex-column.justify-center()
              .d-flex.flex-column.justify-center
                input( hidden type="file" id="file" ref="file" accept="text/csv"  @change="handleFileUpload()" required)
                v-btn.mb-2(outlined color="primary" @click="$refs.file.click()" )
                  v-icon(left) note_add
                  | Select File
                .d-flex.align-center.justify-center
                  .caption.grey--text.my-4() {{fileName || 'no file selected'}}
                  v-btn(v-if="fileName!=null" icon @click="reset")
                    v-icon() cancel
              v-select.ml-6.my-4(v-if="hasGroup" :items='$store.state.settings.groupSettings.group_data', item-text='name', item-value='id', @focus='onGroupSelectFocus', prepend-icon='group', v-model='group', label='Group' clearable style="max-width:350px")

      v-card-actions()
        v-layout(justify-end)
          template(v-if="step==1")
            v-btn.ma-2(color="primary" outlined @click="goToImport()") Skip Template
          template(v-else-if="step==2")
            v-btn.ma-2(color="primary" @click="uploadAction()" :disabled="fileName == null || showProgress")
              v-icon.mr-3() cloud_upload
              | Upload

</template>

<script>


import Repository from "@/repository";

export default {
  components:{

  },
  props: {
    templateUrl: {
      type:String,
      default: "/static/templates/template_customers.csv"
    },

    hasGroup:{
      type:Boolean,
      default: false
    },

    uploadUrl:{
      type:String,
      default: "/customer/upload"
    },

    loadAllDispatch:{
      type:String,
      default: "customer/loadAllCustomers"
    }
  },

  data:()=>({
    group:null,
    file:null,
    fileName: null,
    step:1,
    step1Complete:false,
    step2Complete:false,
    showProgress: false,

  }),

  mounted() {
    this.step=1;
    this.step1Complete = false;
    this.step2Complete = false;
    this.showProgress = false;
    this.file = null;

  },

  computed: {

  },
  methods:{

    goToImport:function() {

      this.step1Complete = true;
      this.step=2;
    },

    reset:function(){
      this.file = null;
      this.fileName = null;
      this.showProgress = false;
    },

    onClose: function(){
      // update the records
      this.$emit('closeImportDialog')
      this.$store.dispatch(this.loadAllDispatch)
      this.step=1;
      this.step1Complete = false;
      this.step2Complete = false;
    },

    handleFileUpload: function () {
      console.log(this.$refs.file.files[0]);
      this.file = this.$refs.file.files[0];

      this.fileName = this.file.name.replace(/\.[^/.]+$/, "");


},
    uploadAction: async function(){
      this.showProgress = true;


      if(this.file == null) {
        this.showProgress = false;
        return
      }


      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("group_id", this.group);

      //upload file
      await Repository.post(this.uploadUrl, formData, {
        headers: {"Content-Type": "multipart/form-data"},
      }).then(()=>{

        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Imported Customers Successfully`
        });

        this.onClose();
      }).catch((err)=>{
        this.$store.commit("showSnackBar", {
          color: "error",
          message: err
        });
      })
      this.showProgress = false;
      this.onClose();
    },

    testSleep: function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    onGroupSelectFocus: function () {
      // Load The group List
      if (this.$store.state.settings.groupSettings.currentIndex == -1) {
        this.$store.dispatch("settings/groupSettings/loadAllGroups");
      }
    },
  }
}
</script>

<style scoped lang="stylus">
.step1{
  display: flex;
  justify-content: center;
  width 100%;
}

  .iconUpload{
    background: url("/static/web/file.png")
  }
</style>