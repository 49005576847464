<template lang="pug">
  div
    .d-flex.align-center()
      v-container.my-0.pa-0(fluid)
        p.my-0 {{ schema.label }}
        v-radio-group.my-0(
          v-model="computedValue"
          v-bind="bindValues"
          :error-messages="errorMessages"
          :hint="schema.hint"
          :persistent-hint="schema.hint != null"
          :mandatory="schema.required"
          :rules="computeRules()"
          )
          v-radio(v-for="(item,index) in schema.options" :key="index" :label="item" :value="item")
      add-comment-button.mx-1(v-model="comments" @showCommentInput="onShowCommentInput")
      upload-blob.mx-1(v-model="blobs" type="image" label="Label" hint="hint" :isMultiple="true")
    .d-flex()
      v-tooltip(bottom v-for="(item,index) in comments" :key="item.time")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") message
        span() {{item.value}}
      .mx-2()
      v-tooltip(bottom v-for="(item,index) in blobs" :key="item")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") image
        div()
          image-thumbnail(:width='360', :height='200', :src="item")

    div.mt-2(v-if="showCommentInput")
      v-textarea(outlined  ref="comment"  label="Comment" auto-grow rows="2" v-model="comment" hide-details )
      .d-flex.my-2()
        v-spacer
        v-btn.mx-2(small color='grey darken-1', text, @click.native="cancel()") Close
        v-btn.mx-2(small color='primary', @click.native="save()") Save
    

</template>
<script>
import {inspectionFillMixin} from "@/modules/inspections/components/formDesigner/fill/inspectionFillMixin";
export default {
  mixins: [inspectionFillMixin],
  data: () => ({
    column: null,
    errorMessages: []
  }),

  computed: {
    computedValue: {
      get: function() {
        return this.dataValue.value;
      },

      set: function(theValue) {
        this.theValue = theValue;
        this.update();
      }
    },

    bindValues: function() {
      let p = {};
      if (this.schema.orientation === "row") {
        p["row"] = true;
      }

      if (this.schema.orientation === "column") {
        p["column"] = true;
      }
      return p;
    }
  },

  methods: {
    computeRules: function() {
      let rulesArray = [];
      if (this.schema.required) {
        rulesArray.push(v => !!v || "option is required");
      }

      return rulesArray;
    }
  },
  mounted() {}
};
</script>
<style scoped lang="stylus"></style>
