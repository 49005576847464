<template lang="pug">
  #blankcontent()
    .panelTitle() Vehicle Assignement
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-center.align-center.fill-height(v-if="$store.state.vehicles.currentIndex == -1")
        v-progress-circular(color="primary" indeterminate)
      .d-flex.justify-space-around.align-center.fill-height(v-else)
          .d-flex.flex-column.align-center()
            .tickerTitle.secondary--text() {{assignedVehicles}}
            .tickerCaption.grey--text.text--darken-1() Assigned
          .d-flex.flex-column.align-center()
            .tickerTitle.primary--text() {{nonAssignedVehicles}}
            .tickerCaption.grey--text.text--darken-1() Non Assigned


</template>
<script>
export default {
  data: () => ({}),
  mounted() {
    if (this.$store.state.vehicles.currentIndex == -1) {
      this.$store.dispatch("vehicles/loadAllItems").then(() => {
        console.log(this.$store.getters["vehicles/getAssignedVehicles"]);
        this.isLoaded = true;
      });
    }
  },

  computed: {
    assignedVehicles() {
      return this.$store.getters["vehicles/getAssignedVehicles"].length;
    },
    nonAssignedVehicles() {
      return this.$store.state.vehicles.all.length - this.assignedVehicles;
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.tickerTitle {
  font-size: 45px;
}

.tickerCaption {
  margin-top: -10px;
  font-size: 12px;
}
</style>
