<template lang="pug">
div
  panel-title-header(label="Assigned Operator" mode="dark")
  div.fill-width.px-3.py-4()
    table.fill-width.assignedOperator
      tr(v-for="(item,index) in items" :key="index" :class="index == items.length-1?'':'BottomLine'")
        td()
          contact-column(v-if="item.contact" :contact="item.contact")
        td.caption() {{item.description}}
        td.caption
          div()
            b Start:
            | &nbsp;{{formatDate(item.during.start)}}
          div(style="min-width:220px;")
            b End:
            | &nbsp; {{formatDate(item.during.end)}}



</template>
<script>
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";
import ContactColumn from "@/modules/global/components/table/ContactColumn";
import axios from "axios";
import moment from "moment";
export default {
  components: {
    PanelTitleHeader,
    ContactColumn
  },

  data: () => ({
    items:[],
  }),

  methods: {
    loadItems: function(){
      axios.get(`/api/assignment/vehicle/${this.$route.params.vehicleID}?limit=3`).then( response =>{
        this.items = response.data
      })
    },

    formatDate: function(theDate){
      return moment(theDate).format("h:mm a, ddd, Do MMM YYYY")
    }
  },



  computed: {
    getCurrentVehicle() {
      return this.$store.getters["vehicles/getCurrentItem"];
    }
  },

  mounted() {
    this.loadItems();
  }
};
</script>
<style scoped lang="stylus">
.PairItems {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}
  .assignedOperator{
    border-collapse: collapse;
  }
  .assignedOperator tr td{
    padding 5px 5px;
  }

  .BottomLine {
    border-bottom 1px solid #CCCCCC;
  }
</style>
