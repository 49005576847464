bnb<template lang="pug">
v-container(grid-list-lg)
  v-layout(row, wrap)
    v-flex(xs12, md6, xl6)
      report-list-item(@selectitem='onSelectItem', label='Vehicles', icon='local_shipping', :items='$store.state.reports.viewData.vehicles')
      report-list-item.mt-4(@selectitem='onSelectItem', label='Vehicle Assignments', icon='local_shipping', :items='$store.state.reports.viewData.vehicleAssignments')
      report-list-item.mt-4(@selectitem='onSelectItem', label='Contacts', icon='local_shipping', :items='$store.state.reports.viewData.contacts')
      
    v-flex(xs12, md6, xl6)
      report-list-item.mt-4(@selectitem='onSelectItem', label='Issues', icon='local_shipping', :items='$store.state.reports.viewData.issues')
      //- report-list-item(@selectitem='onSelectItem', label='Inspections', icon='local_shipping', :items='$store.state.reports.viewData.insepctions')
      report-list-item.mt-4(@selectitem='onSelectItem', label='Services', icon='local_shipping', :items='$store.state.reports.viewData.services')
      report-list-item.mt-4(@selectitem='onSelectItem', label='Fuel', icon='local_shipping', :items='$store.state.reports.viewData.fuel')
</template>
<script>
import ReportListItem from "@/modules/reports/components/ui/ReportListItem";
export default {
  components: {
    ReportListItem,
  },
  data: () => ({}),

  methods: {
    onSelectItem: function(item) {
      console.log(item.url);
      this.$router.push({ name: item.url }).catch(()=>{});
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
