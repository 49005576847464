<template lang="pug">
div
  v-divider
  v-container.mx-4(fluid)
    v-layout.mr-4.my-2(row, wrap)
      h2 Odometer Table
      v-spacer
  v-divider
  v-layout.mx-4(row, wrap, align-center)
    v-layout(align-center, style='max-width:900px;')
      v-menu(offset-y)
            template(v-slot:activator="{ on }")
              v-btn.mx-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list(dense)
              v-list-item-group(v-model="action" color="primary")
                v-list-item(v-for='item in items', :key='item.title')
                  v-list-item-title {{ item.title }}

      v-text-field.pl-2(style='width:10px;', v-model='search',append-icon="search" label='Search', :persistent-hint='true', required)
      v-btn.ma-2.grey--text(v-if="search.length > 0" text outlined small @click="search=''") clear all
  v-divider
  .grey.lighten-4.py-3
  v-divider
  odometer-table(:search="search")  

</template>

<script>
import OdometerTable from "@/modules/vehicles/components/tables/OdometerTable";
import VehicleTutorial from "@/modules/global/components/tutorial/tutorial";

export default {
  components: {
    OdometerTable,
    VehicleTutorial
  },
  data: () => ({
    action: null,
    search: "",
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ]
  }),
  methods: {
    openNew: function() {
      this.$store.dispatch("vehicles/addNew").then(() => {
        let newVehicleID = this.$store.state.vehicles.all[
          this.$store.state.vehicles.currentIndex
        ].id;
        this.$router.push({
          name: "vehicleNew",
          params: { vehicleID: newVehicleID }
        }).catch(()=>{});
      });
    },

    showDialog: function() {},

    closeDialog: function() {},

    tutorialAction: function() {
      console.log("tutorial Action Clicked");
    }
  },

  mounted() {
    this.$store.dispatch("odometers/loadAllOdometers");
  }
};
</script>

<style scoped></style>
