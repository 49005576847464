<template lang="pug">
  v-data-table(v-model="selected" :headers='headers', hide-default-footer, :items='tabledata', :loading='loading' item-key="id" :single-select="false" show-select)
 
    template(slot='headerCell', slot-scope='props')
      v-tooltip(bottom)
        span(slot='activator')
          | {{ props.header.text }}
        span
          | {{ props.header.tooltip }}

    template(v-slot:item.name="{item}")
      issue-column(:issue="item")

    template(v-slot:item.assigned_to="{item}")
      div(v-if="item.assigned_to") {{item.assigned_to.first_name}}  {{item.assigned_to.last_name}}

    template(v-slot:item.due_date_time="{item}")
      div() {{item.due_date_time |  moment("ddd, Do MMMM YYYY") }}  


    template(slot='no-data')
      v-alert(:value='true', color='error', icon='warning')
        | Sorry, nothing to display here :(
</template>

<script>
import ComplexLabelTags from "@/modules/global/components/others/ComplexLabelTags";
import IssueColumn from "@/modules/global/components/table/IssueColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
export default {
  components: {
    ComplexLabelTags,
    IssueColumn,
    MoreDropDownMenu
  },
  props: {
    tabledata: Array
  },

  data: () => ({
    selected: [],
    loading: false,
    headers: [
      { text: "Issue", value: "name", tooltip: "Issue", align: "left" },
      {
        text: "Assigned To",
        value: "assigned_to",
        align: "left"
      },
      {
        text: "Due Date",
        value: "due_date_time",
        align: "left"
      }
    ],
    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" }
    ]
  }),

  methods: {
    onClick: function(value) {
      // console.log(value.theObject);
      switch (value.action) {
        case "edit":
          console.log("edit Item");
          break;
        case "delete":
          console.log("Delete Item");
          break;
      }
    }
  },

  computed: {
    getIssuesData: function() {
      return this.$store.getters["issues/getIssueItems"];
    }
  },

  filters: {
    initials: function(value) {
      let initials = "";
      value.split(" ").forEach(item => {
        initials += item.charAt(0).toUpperCase();
      });

      return initials;
    }
  }
};
</script>

<style scoped>
.assignedSytle {
  display: flex;
  align-items: center;
}

.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;

  padding: 0px;
}

.IssueName {
  font-weight: bold;
}

.IssueSmallText {
  font-size: 11px;
}

.LabelTags {
  display: flex;
}
.avatarStyle {
  font-size: 10px;
}
</style>
