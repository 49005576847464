<template lang="pug">
v-data-table.elevation-1(:headers="headers", :search="search" :loading="isLoading" :single-select="false" show-select   :items-per-page="20" :footer-props="footerProps" :items="$store.state.service.serviceType.servicetype_data" )
  template( v-slot:item.action="{ item }")
      v-layout(align-center justify-center)
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')



</template>
<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
export default {
  props: {
    search: {
      type: String,
      default: null
    }
  },

  components: {
    MoreDropDownMenu
  },

  data: () => ({
    isLoading: true,
    headers: [
      { text: "Name", value: "name" },
      { text: "Description", value: "description" },
      { text: "Action", value: "action", align: "center" }
    ],
    footerProps: {
      "items-per-page-options": [5, 10, 20, 50,100,1000],
      pagnation: {itemsPerPage:20}
    },

    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" }
    ],

    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 10, // -1 for All
      sortBy: "name"
    }
  }),

  methods: {
    onClick: function(value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          console.log(value.theObject.id);
          this.$store.commit(
            "service/serviceType/setCurrentIndexByID",
            value.theObject.id
          );
          this.$router.push({
            name: "serviceTypeForm",
            params: { serviceID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          this.$store.dispatch(
            "service/serviceType/deleteServiceType",
            value.theObject.id
          );
          break;
      }
    }
  },

  mounted() {
    this.$store.dispatch("service/serviceType/loadAllServiceTypes").then(() => {
      this.isLoading = false;
    });
  }
};
</script>
<style scoped lang="stylus">
.editButtons
  display: flex

.dottedUnderline
  border-bottom 1px dotted
  text-decoration none
</style>
