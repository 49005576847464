<template lang="pug">
div(v-if="theData" )
  pie-chart(v-if="isLoaded" style="height:140px; width:200px" :chartdata="chartData" :options="chartOption")
  .d-flex.caption.justify-center()
    .mx-3(:class="['pointer grey px-2 rounded white--text']") Vehicles

</template>

<script>

import PieChart from "@/modules/global/components/charts/PieChart";


export default {
  components:{
    PieChart
  },
  props:{
    theData:{
      type: Array,
      default: null,
    }
  },
  data:()=>({
    isLoaded: false,
    keyCounter: 0,
    isVolume: true,
    chartOption : {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 10,
          bottom: 0
        }
      }
    },
    chartData: {
      labels: [

      ],
      datasets: [
        {

        }
      ]
    },
  }),
  computed:{
    getMetrics() {
      return this.$store.getters["fuel/getMetrics"];
    }
  },
  methods:{
    loadData: async function() {
      this.isLoaded = false;
      let keys = [];
      let names = [];
      let values = [];
      let bgColor = [];

      this.theData.forEach(item => {
        keys.push(item.key)
        names.push(item.name)
        values.push(item.doc_count)
        bgColor.push("#"+item.key.substr(1,6))

      })


      this.chartData.datasets[0].backgroundColor = bgColor;
      this.chartData.labels = names;
      this.chartData.datasets[0].data = values;
      this.isLoaded = true;
      this.keyCounter += 1;

    },
    onClick: function(){
      this.isVolume = !this.isVolume;
      this.loadData();
      this.keyCounter += 1;
    },
  },
  mounted() {
    this.loadData();

  }

}
</script>


<style scoped lang="stylus">

</style>