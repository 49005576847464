import { render, staticRenderFns } from "./PlacesPanel.vue?vue&type=template&id=1380f942&scoped=true&lang=pug"
import script from "./PlacesPanel.vue?vue&type=script&lang=js"
export * from "./PlacesPanel.vue?vue&type=script&lang=js"
import style0 from "./PlacesPanel.vue?vue&type=style&index=0&id=1380f942&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1380f942",
  null
  
)

export default component.exports