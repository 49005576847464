<template lang="pug">
  div(v-if="this.$route.params.tabID==1")
    iframe.kibanaFrame(
      :style="kibanaStyle"
      frameBorder="0"
      src="https://kibana.jahazi.com/app/dashboards?security_tenant=global#/view/3d58f7b0-cb6c-11ec-b438-695bb0f252c8?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1M,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:ZoaTaka_Dashboard,viewMode:view)&show-query-input=true&show-time-filter=true"
    )
</template>
<script>
export default {
  data: () => ({
    kibanaStyle: {
      width: "100%",
      height: window.innerHeight - 90 + "px",
      "margin-top": "5px"
    }
  })
}
</script>
<style lang="stylus" scoped>

</style>