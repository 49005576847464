<template lang="pug">
  div(v-if="dataLoaded && currentDataTable != null")
    v-toolbar(dense, flat)
      v-toolbar-title Workflow Table Editor
      v-spacer


    v-container.py-2
      v-alert(v-if="errors.length" :value="true" color="error" icon="warning")
        div(v-for="e,index in errors" :key="index" v-text="e")

      v-layout(justify-end)
        v-btn.mx-2(small text color="grey" @click="cancel") cancel
        v-btn.mx-2(small outlined color="primary" @click="saveAdd") Save &amp; Add another
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.mx-2(small, outlined, color='primary', dark, v-on="on")
              | Actions
              v-icon(dark) keyboard_arrow_down
          v-list
            v-list-item(v-for='item in items', :key='item.title' @click="onAction(item.value)")
              v-list-item-title {{ item.title }}
        v-btn.mx-2(small color="primary" @click="save" :disabled="!valid") Save

    v-container()
      v-card()
        v-container()
          v-data-table(:headers="getHeaders" :items="getData"  item-key="id" :single-select="false" show-select)
            template( v-slot:item.action="{ item }")
              v-row.mr-7(justify="end")
                more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')
            template( v-slot:item.active="{ item }")
              template(v-if="item.active")
                v-icon(color="success") check_circle
              template(v-else)
                v-icon(color="error") remove_circle


</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import { searchMixin } from "@/mixin/searchListMixin";
export default {
  components: {
    MoreDropDownMenu
  },
  mixins: [searchMixin],
  data: () => ({
    dataLoaded: false,
    valid: true,
    error: false,
    errors: [],
    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" }
    ],
    items: [
      {
        title: "Import Excel",
        value: "import_excel"
      },
      {
        title: "Import CSV",
        value: "import_csv"
      }
    ]
  }),

  computed: {
    currentDataTable: function() {
      return this.$store.getters["workflow/dataTable/getCurrentDataTable"];
    },

    getHeaders: function() {
      let headerList = [];
      for (var i = 0; i < this.currentDataTable.header.length; i++) {
        if (this.currentDataTable.header[i].name == "id") {
          continue;
        }
        headerList.push({
          text: this.currentDataTable.header[i].name,
          value: this.currentDataTable.header[i].name
        });
      }

      headerList.push({ text: "Action", value: "action", align: "right" });
      return headerList;
    },

    getData: function() {
      return this.currentDataTable.data;
    }
  },

  methods: {
    cancel: function() {},

    saveAdd: function() {},

    save: function() {},

    onClick: function(value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          console.log("edit");
          break;
        case "delete":
          console.log("delete");
          break;
      }
    }
  },

  mounted() {
    console.log("here is the mounted table editor");
    // fetch the workflow
    this.$store
      .dispatch(
        "workflow/dataTable/fetchDataTable",
        this.$route.params.workflowID
      )
      .then(() => {
        this.dataLoaded = true;
      });
  }
};
</script>

<style scoped></style>
