<template lang="pug">
  g()
    rect(ref="tooltip" :x="x" :y="getSpacerHeight"  :width="width" :height="height - (getSpacerHeight*2)" :rx="5"  fill="#000033"  )
    text.schedulerText(:x="15" :y="getSpacerHeight+20" fill="white" :width="width") {{title}}
    text.schedulerTextBold(:x="15" :y="getSpacerHeight+38" fill="white" :width="width" ) {{subTitle}}
    polygon(fill="#000033" :points="`0 -${arrowSize}, ${arrowSize} 0, 0 ${arrowSize}`" :transform="getTranslationArrow")

</template>
<script>
export default {
  props: {
    x: Number,
    y: Number,
    width: Number,
    height: Number,
    direction: {
      type: String,
      default: "right"
    },
    arrowSize: {
      type: Number,
      default: 15
    },
    title: String,
    subTitle: String
  },
  data: () => ({}),

  computed: {
    getTranslationArrow: function() {
      if (this.direction === "left") {
        return `translate(${this.width - 1},${this.height / 2})`;
      } else {
        return `translate(${1},${this.height / 2}) rotate(-180)`;
      }
    },

    getSpacerHeight: function() {
      // calculate the space between rows
      return this.height * 0.15;
    }
  },
  methods: {},
  mounted() {
    // console.log("hello ")
  }
};
</script>
<style scoped>
.schedulerTextBold {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 11px;
  font-weight: bold;
  user-select: none;
}

.schedulerText {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 11px;
  user-select: none;
}
</style>
