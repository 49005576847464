<template lang="pug">
  div(v-if="$store.state.settings.expenseTypeSettings.currentIndex > -1")
    .theForm.pb-2
      v-btn.ma-2(small color="primary" outlined @click="cancel") Cancel
      v-btn.ma-2(small color="primary" @click="save") Save
    v-card
      v-container
        v-layout(row wrap)
          v-flex(xs12)
            v-text-field(v-model="name"  hint="Enter name of the Expense" :persistent-hint="true"  label="Expense Type Name*"  required)
          v-flex(xs12)
            v-textarea(v-model="description"  hint="Enter name of the Description" :persistent-hint="true"  label="Description*"  required)
   
</template>
<script>
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "settings/expenseTypeSettings/getExpenseType",
  mutationType: "settings/expenseTypeSettings/updateExpenseType"
});

export default {
  data: () => ({
    levelArray: [0, 1, 2, 3, 4]
  }),
  computed: {
    ...mapFields({
      name: "name",
      description: "description"
    })
  },

  methods: {
    cancel: function() {
      this.$router.push({ name: "expenseTypeSettings" }).catch(()=>{});
    },

    save: function() {
      this.$store.dispatch("settings/expenseTypeSettings/saveExpenseType");
      this.$router.push({ name: "expenseTypeSettings" }).catch(()=>{});
    }
  },

  mounted() {
    if (this.$store.state.settings.expenseTypeSettings.currentIndex == -1) {
      this.$store.dispatch(
        "settings/expenseTypeSettings/fetchExpenseType",
        this.$route.params.expenseTypeID
      );
    }
  }
};
</script>
<style scoped lang="stylus"></style>
