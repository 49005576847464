import IssueDetailsForm from "@/modules/issues/components/forms/IssuesDetailsForm";
import IssueOverview from "@/modules/issues/components/overview/IssueOverview";

export const ISSUE_ROUTES = [
  {
    path: "issueDetailsForm",
    name: "issueDetailsForm",
    component: IssueDetailsForm
  },
  {
    path: "issueOverview",
    name: "issueOverview",
    component: IssueOverview
  }
];
