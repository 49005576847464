<template lang="pug">
  div(v-if="$store.state.fuel.fuel_data.length > 0")
    v-toolbar(dense flat)
      generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer( )
      .d-flex.align-center()
        .mr-2(v-if="$store.state.fuel.fuel_data.length > 1")
          v-btn.mx-4(icon small @click="previous")
            v-icon() skip_previous
          v-btn.mx-4(icon small @click="next")
            v-icon() skip_next
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
              | Export
              v-icon(dark) keyboard_arrow_down
          v-list-item-group.white()
            v-list-item(v-for='item in items', :key='item.title' dense @click="onAction(item.value)" )
              v-list-item-title
                v-layout(align-center)
                  v-icon.mr-2(:size="20") {{item.icon}}
                  .subtitle-2.mx-2() {{ item.title }}
        v-btn.mx-2(color="primary" small @click="editRecord") Edit Record
    v-divider()
    v-container.mt-8.pa-8.white(fluid)

      v-row()
        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:body)
              div.mt-2.mb-2.pb-2
                table.DetailTable.body-2.fill-width
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Vehicle
                    td
                      vehicle-column(v-if="getFuel.vehicle" :vehicle="getFuel.vehicle")
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Price ({{$store.state.profile.userInfo.account.region_settings.currency}})
                    td {{getFuel.price}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Volume (L)
                    td {{getFuel.volume}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Report By
                    td(v-if="getFuel.reported_by") {{getFuel.reported_by.first_name}} {{getFuel.reported_by.last_name}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Partial Usage
                    td {{getFuel.partial_fuel}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Location
                    td {{getFuel.reverse_location}}

                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Merchant
                    td()
                      div(v-if="getFuel.merchant")   {{getFuel.merchant.name}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Fuel Date
                    td()
                      div() {{getFuel.date_time | moment("ddd, Do MMM YYYY &ndash; h:mm a")}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Odometer
                    td()
                      div(v-if="getFuel.odometer") {{Math.round(getFuel.odometer/1000)}} Km

          location-map-panel(v-if="getFuel.location" :coordinates="getFuel.location" :title="`Location: ${getFuel.reverse_location}`")
        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:title)
              | Comments


            template(v-slot:body)
              div() {{getFuel.comments}}

          base-panel()
            template(v-slot:title)
              | Photos


            template(v-slot:body)
              attach-fill-form( :schema="imagesSchama" :data="{value:getFuel.photos_id}" @onChange="saveImages"  hideButton )

          base-panel()
            template(v-slot:title)
              | Documents

            template(v-slot:body)
              attach-fill-form(:schema="documentsSchama" :data="{value:getFuel.documents_id}" @onChange="saveDocuments"   hideButton )


</template>
<script>
import BasePanel from "@/modules/global/components/panel/basePanel";
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import LocationMapPanel from "@/modules/global/components/panel/LocationMapPanel";

export default {
  components: {
    BasePanel,
    VehicleColumn,
    AttachFillForm,
    GenericBreadCrumbs,
    LocationMapPanel
  },
  data: () => ({
    title: "header",
    answer: "Sengenge ni Ngombe",
    breadCrumbsItems: [
      {
        text: "Fuels",
        disabled: false,
        path: "fuels"
      },
      {
        text: "Fuel Detail",
        disabled: true
      }
    ],
    imagesSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Fuel Images",
      hint: null,
      type: "image",
      image_size: "medium",
      multiple: true
    },
    documentsSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Fuel Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true
    },
    items: [
      {
        title: "Copy Link to Clipboard",
        icon: "content_copy",
        value: "copy_link"
      },
      {
        title: "Open Link",
        icon: "open_in_new",
        value: "open_link"
      }

    ],
  }),
  computed: {
    getFuel: function () {
      return this.$store.getters["fuel/getCurrentFuel"];
    }
  },
  methods: {
    onCloseDialog: function () {
    },
    onMenuActionImages: function (value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("fuel/deleteImage", value.theObject.id);
          break;
      }
    },
    saveImages: function (theObject) {
      this.$store.commit("fuel/updateFuel", {
        path: "photos_id",
        value: theObject.value
      });
    },
    saveDocuments: function (theObject) {
      this.$store.commit("fuel/updateFuel", {
        path: "documents_id",
        value: theObject.value
      });
    },
    onMenuActionDocuments: function (value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("fuel/deleteDocument", value.theObject.id);
          break;
      }
    },
    next(){
      this.$store.commit("fuel/next");
    },
    previous() {
      this.$store.commit("fuel/previous");
    },

    editRecord: function(){
      // this.$store.commit("fuel/setCurrentIndexByID", this.$route.params.fuelID);
      let currentID = this.$store.getters["fuel/getCurrentFuel"].id
      this.$router.push({
        name: "fuelDetailsForm",
        params: {fuelID: currentID}
      }).catch(()=>{});

    },
    onAction: function(value) {
      let currentID = this.$store.getters["fuel/getCurrentFuel"].id
      switch (value) {
        case "copy_link":
          navigator.clipboard.writeText(`https://app.jahazi.com/api/renderhtml?type=fuelRecord&id=${currentID}`);
          this.$store.commit("showSnackBar", {
            color: "accent",
            message: `Copied to Clipboard`,
          });
          break;
        case "open_link":
          window.open(`/api/renderhtml?type=fuelRecord&id=${currentID}`);
          break;
      }

    },
  },
  mounted() {
    if (this.$store.state.fuel.fuel_data.length < 2) {
      this.$store
          .dispatch("fuel/fetchFuel", this.$route.params.fuelID)
          .then(() => {
          });
    }
  }
};
</script>
<style lang="stylus" scoped>
.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0
    padding 0

    padding-top 6px
    padding-bottom 8px
</style>
