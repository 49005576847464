<template lang="pug">

  .fill-width.pa-2()
    v-toolbar(dense flat)
      v-spacer
      v-btn.mb-2.mx-2(small, color='primary',outlined, dark, @click='cancel') Cancel
      v-btn.mb-2.mx-2(small, color='primary', dark, @click='save') Save

    .mx-4(v-if="$store.state.inspection.inspectionForm.currentIndex > -1")
      form-divider.mb-2 Inspection Permissions
      v-row.py-4()
        v-col(cols="12" xs="12" md="6" xl="6")
          roles-auto-complete(v-model="read_permissions_id" :multiple="true" label="Read Permissions")
        v-col(cols="12" xs="12" md="6" xl="6")
          roles-auto-complete(v-model="edit_permissions_id" :multiple="true" label="Edit Permissions")
</template>
<script>

import FormDivider from "@/modules/global/components/forms/FormDivider";
import RolesAutoComplete from "@/modules/global/components/forms/RolesAutoComplete.vue";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "inspection/inspectionForm/getInspectionForm",
  mutationType: "inspection/inspectionForm/updateInspectionForm"
});
export default {
  data: ()=>({

  }),
  components:{
    FormDivider,
    RolesAutoComplete
  },
  computed:{
    ...mapFields({
      read_permissions_id:"read_permissions_id",
      edit_permissions_id:"edit_permissions_id",
    }),
  },
  methods:{
    cancel:function(){

    },
    save:function (){
      this.$store.dispatch("inspection/inspectionForm/saveInspectionForm").then(()=>{
        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Updated Permissions`
        });
      })
    }
  }
}
</script>

<style scoped lang="stylus">

</style>
<script setup>
</script>