<template lang="pug">
  div
    iframe.kibanaFrame(
      :style="flowStyle"
      frameBorder="0"
      src="http://localhost:5173"
    )
</template>

<script>

export default {
  data:()=>({
    flowStyle: {
      width: "100%",
      height: window.innerHeight - 90 + "px",
      "margin-top": "5px",
      "background-color":"#FF3333"
    }
  })
}
</script>

<style scoped lang="stylus">

</style>