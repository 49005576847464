<template lang="pug">
  div
    .btnArrangement
      v-btn.mb-2(small, color='primary' @click.prevent="newContactType") Add New
    form-sub-header.white Contact Renewal Types
    v-container(white)
        v-text-field(style="max-width:400px;" v-model="searchFilter", label="Search Filter", single-line append-icon="search" :persistent-hint="true" required) 
    v-divider
    contact-renewal-types-table(:search="searchFilter")
</template>
<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import ContactRenewalTypesTable from "@/modules/reminders/components/tables/ContactRenewalTypesTable";

export default {
  components: {
    FormSubHeader,
    ContactRenewalTypesTable
  },
  data: () => ({
    searchFilter: ""
  }),

  methods: {
    newContactType: function() {
      this.$store
        .dispatch("reminders/contact_renewal_type/addContact_renewal_type")
        .then(() => {
          let currentIndex = this.$store.state.reminders.contact_renewal_type
            .currentIndex;
          let currentObject = this.$store.state.reminders.contact_renewal_type
            .contact_renewal_type_data[currentIndex];
          this.$router.push({
            name: "contactRenewalTypeForm",
            params: { contactRenewalTypeID: currentObject.id }
          }).catch(()=>{});
        });
    }
  }
};
</script>
<style scoped lang="stylus">
.btnArrangement {
  display: flex;
  justify-content: flex-end;
}
</style>
