<template lang="pug">
  div()
    v-text-field.mr-3.mt-2(v-model='search', :label='label'  clearable, required, :append-icon="'search'" )
</template>

<script>
export default {
  props:{
    value:String,
    label:{
      type: String,
      default: "Search Filter"
    },

  },
  name: "SearchFilter",
  watch: {
    search: function(){
      this.debounceResults();
    }
  },
  created: function() {
    this.debounceResults = this._.debounce(this.getResults,1500)
  },

  methods: {
    getResults:function(){
      this.$emit("input",this.search)
    }

  },

  data:()=>({
    search:null,
  }),

  mounted() {
    console.log("mounted")
    this.search = this.value;
  }


}
</script>

<style scoped>

</style>