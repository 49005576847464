import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";


const REPOSITORY_API = "/repository";

// ----------------------- STATE ------------------------- //
const state = {
    repository_data: [],
    currentIndex: -1,
    selectedRepositories: [],
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getRepository: (state) => getField(state.repository_data[state.currentIndex]),

    getCurrentRepository: (state) => {
        return state.repository_data[state.currentIndex];
    },


    getRepositoryByID: (state) => (id) => {
        return state.repository_data.find((element) => element.id == id);
    },

    getSelectedRepositories: state => {
        return state.selectedRepositories;
    },

    getFilterByGroup: (state) => (id) =>{
        if(id != null){
            return state.repository_data.filter( repository => repository.group_id == id)
        }else{
            return state.repository_data
        }
    },
    

};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateRepository(state, payload) {
        updateField(state.repository_data[state.currentIndex], payload);
    },

    updateCurrentRepository(state, payload) {
        state.repository_data[state.currentIndex].name= payload.name;
        state.repository_data[state.currentIndex].description= payload.description;
        state.repository_data[state.currentIndex].link= payload.link;
        state.repository_data[state.currentIndex].published_at= payload.published_at;

    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.repository_data.findIndex(
            (repository) => repository.id == theID
        );
    },

    loadAllRepositories(state, payload) {
        state.repository_data = payload.data;
        state.currentIndex = 0;
    },

    addRepository(state, payload) {
        state.repository_data.push(payload.data);
        state.currentIndex = state.repository_data.length - 1;
    },

    uploadRepository(state, payload) {
        state.repository_data.unshift(payload.data);
        state.currentIndex = 0;
    },



    saveRepository(state, payload) {
        state.repository_data[state.currentIndex] = payload.data;
    },

    fetchRepository(state, payload) {
        state.currentIndex = state.repository_data.findIndex((item) => item.id == payload.data.id)
        if (state.currentIndex == -1) {
            state.repository_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.repository_data[state.currentIndex] = payload.data;
        }
    },

    setSelectedRepositories(state, payload) {
        state.selectedRepositories = payload;
    },

    setCurrentIndex(state, payload) {
      state.currentIndex = payload;
    },

    deleteRepository(state, payload) {
        state.currentIndex = state.repository_data.findIndex(
            (repository) => repository.id == payload.data.id
        );
        state.repository_data.splice(state.currentIndex, 1);
    },

    deleteSelectedRepository(state, payload){
        payload.data.forEach(element => {
            let theIndex = state.repository_data.findIndex(
                (repository) => repository.id == element
            );
            state.repository_data.splice(theIndex, 1);
        })
        state.selectedRepositories = [];
    },

    duplicateRepository(state,payload){
        state.repository_data.splice(state.currentIndex, 0,payload.data)
    },


    reset(state) {
        state.repository_data = []
        state.currentIndex = -1
    },

    next(state){
        if(state.currentIndex == state.repository_data.length-1){
            state.currentIndex = 0;
        }else{
            state.currentIndex += 1;
        }
    },

    previous(state){
        if(state.currentIndex == 0){
            state.currentIndex = state.repository_data.length-1;
        }else{
            state.currentIndex -= 1;
        }
    },

};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllRepositories({ commit }) {
        try {
            commit("loadAllRepositories", await Repository.get(`${REPOSITORY_API}`));
        } catch (error) {
            console.log(error);
        }
    },



    async addRepository({ commit }) {
        await Repository.get(`${REPOSITORY_API}/new`)
            .then((response) => {
                commit("addRepository", response);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    async addRepositoryPost({ commit },payload) {
        await Repository.post(`${REPOSITORY_API}/new`,payload)
            .then((response) => {
                commit("addRepository", response);
            })
            .catch((error) => {
                console.log(error);
            });
    },


    async saveRepository({ state, commit }) {
        try {
            commit(
                "saveRepository",
                await Repository.post(
                    `${REPOSITORY_API}`,
                    state.repository_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchRepository({ commit }, payloadID) {
        try {
            commit("fetchRepository", await Repository.get(`${REPOSITORY_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },


    async deleteRepository({ commit }, payloadID) {
        try {
            commit(
                "deleteRepository",
                await Repository.delete(`${REPOSITORY_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteSelectedRepositories({ commit }, deleteSelectedIDs) {
        try {
            let idString = ''
            deleteSelectedIDs.forEach(element => {
                idString += "&id="+element
            })
            commit(
                "deleteSelectedRepository",
                await Repository.delete(`${REPOSITORY_API}/deleteSelected?${idString}`)

            );
        } catch (error) {
            console.log(error);
        }
    },

    async duplicateRepository({state, commit}) {
        commit("cleanUpDataBeforeSave");
        try {
            commit(
                "duplicateRepository",
                await Repository.post(
                    `${REPOSITORY_API}/duplicate`,
                    state.repository_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchRepositories({ commit }, searchTerm) {
        try {
            commit(
                "loadAllRepositories",
                await Repository.get(`${REPOSITORY_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    },
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
