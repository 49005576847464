import { render, staticRenderFns } from "./KeyElementsPanel.vue?vue&type=template&id=8487c446&scoped=true&lang=pug"
import script from "./KeyElementsPanel.vue?vue&type=script&lang=js"
export * from "./KeyElementsPanel.vue?vue&type=script&lang=js"
import style0 from "./KeyElementsPanel.vue?vue&type=style&index=0&id=8487c446&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8487c446",
  null
  
)

export default component.exports