<template lang="pug">
  div
    v-form(@submit="onSubmit")
      div(:style="getStyle")
        component( :is="getEditComponentName")
      v-layout.pt-3(v-if="$store.state.workflow.formBuilder.disableAllCompoents" justify-center align-center)
        v-btn.ma-2(text color="grey" type="reset" small @click="$store.commit('workflow/formBuilder/saveFieldProperties')") Cancel
        v-btn.ma-2(color="primary" type="submit" small @click="$store.commit('workflow/formBuilder/saveFieldProperties')") accept
</template>
<script>
import GlobalTemplateComponents from "@/modules/workflow/formBuilder/components/propertyTypes/GlobalTemplateComponents";
import RadioButtonProperties from "@/modules/workflow/formBuilder/components/propertyTypes/RadioButtonProperties";
import TextFieldProperties from "@/modules/workflow/formBuilder/components/propertyTypes/TextFieldProperties";
import CheckboxProperties from "@/modules/workflow/formBuilder/components/propertyTypes/CheckboxProperties";
import HeaderProperties from "@/modules/workflow/formBuilder/components/propertyTypes/HeaderProperties";
import TextAreaProperties from "@/modules/workflow/formBuilder/components/propertyTypes/TextAreaProperties";
import DateProperties from "@/modules/workflow/formBuilder/components/propertyTypes/DateProperties";
import TimeProperties from "@/modules/workflow/formBuilder/components/propertyTypes/TimeProperties";
import RadioProperties from "@/modules/workflow/formBuilder/components/propertyTypes/RadioProperties";
import SelectProperties from "@/modules/workflow/formBuilder/components/propertyTypes/SelectProperties";
import TableProperties from "@/modules/workflow/formBuilder/components/propertyTypes/TableProperties";
import AttachProperties from "@/modules/workflow/formBuilder/components/propertyTypes/AttachProperties";
import ImageProperties from "@/modules/workflow/formBuilder/components/propertyTypes/ImageProperties";
import SignatureProperties from "@/modules/workflow/formBuilder/components/propertyTypes/SignatureProperties";
import MapProperties from "@/modules/workflow/formBuilder/components/propertyTypes/MapProperties";
import SwitchProperties from "@/modules/workflow/formBuilder/components/propertyTypes/SwitchProperties";
import JahaziProperties from "@/modules/workflow/formBuilder/components/propertyTypes/JahaziProperties";
import ReadOnlyProperties from "@/modules/workflow/formBuilder/components/propertyTypes/ReadOnlyProperties.vue";
import DateTimeProperties from "@/modules/workflow/formBuilder/components/propertyTypes/DateTimeProperties.vue";
import ApprovalProperties from "@/modules/workflow/formBuilder/components/propertyTypes/ApprovalProperties.vue";
import DrawProperties from "@/modules/workflow/formBuilder/components/propertyTypes/DrawProperties.vue";
export default {
  props: {},

  components: {
    GlobalTemplateComponents,
    TextFieldProperties,
    RadioButtonProperties,
    CheckboxProperties,
    HeaderProperties,
    TextAreaProperties,
    DateProperties,
    TimeProperties,
    RadioProperties,
    SelectProperties,
    TableProperties,
    AttachProperties,
    ImageProperties,
    SignatureProperties,
    MapProperties,
    SwitchProperties,
    JahaziProperties,
    ReadOnlyProperties,
    DateTimeProperties,
    ApprovalProperties,
    DrawProperties
  },
  data: () => ({}),

  computed: {
    getEditComponentName: function() {
      let name = "GlobalTemplateComponents"; // the default component
      switch (this.$store.state.workflow.formBuilder.currentPropertyName) {
        case "TextField":
          name = "TextFieldProperties";
          break;
        case "Header":
          name = "HeaderProperties";
          break;
        case "Checkbox":
          name = "CheckboxProperties";
          break;
        case "TextArea":
          name = "TextAreaProperties";
          break;
        case "Date":
          name = "DateProperties";
          break;
        case "Time":
          name = "TimeProperties";
          break;
        case "Radio":
          name = "RadioProperties";
          break;
        case "Select":
          name = "SelectProperties";
          break;
        case "Table":
          name = "TableProperties";
          break;
        case "Attach":
          name = "AttachProperties";
          break;
        case "Image":
          name = "ImageProperties";
          break;
        case "Signature":
          name = "SignatureProperties";
          break;
        case "Map":
          name = "MapProperties";
          break;
        case "Switch":
          name = "SwitchProperties";
          break;
        case "Jahazi":
          name = "JahaziProperties";
          break;
        case "ReadOnly":
          name = "ReadOnlyProperties";
          break;
        case "DateTime":
          name = "DateTimeProperties";
          break;
        case "Approval":
          name= "ApprovalProperties";
          break;
        case "Draw":
          name= "DrawProperties";
          break;
      }
      return name;
    },

    getStyle: function() {
      if (this.$store.state.workflow.formBuilder.disableAllCompoents == false) {
        return null;
      }
      let styleObj = {
        "border-radius": "10px",
        padding: "10px 15px",
        "border-color": this.$vuetify.theme.primary,
        "border-style": "solid",
        "border-width": "4px"
      };

      return styleObj;
    }
  },

  methods: {
    onSubmit: function() {
      console.log("submitted the form");
    }
  },
  mounted() {
    // console.log(this.$store.state.workflow.formBuilder)
  }
};
</script>
<style scoped lang="stylus"></style>
