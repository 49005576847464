<template lang="pug">
  div
    v-autocomplete(
      v-bind:value="value"
      :items="$store.state.merchant.merchant_data"
      :filter="customFilter"
      :loadeing="isLoading"
      item-value="id"
      label="Select Merchants"
      placeholder="Start typing to Search"
      chips
      multiple
      @change="onChange"
      clearable
      :disabled="disabled"
    )
      template( v-slot:item="data")
        template(v-if="data.item.reference_id != null")
          v-list-item-content() {{data.item.reference_id}} - {{data.item.name}}
        template(v-else)
          v-list-item-content() {{data.item.name}}

      template(v-slot:selection="data")
        v-chip.chip--select-multi(:input-value="data.selected" small )
          | {{data.item.name}}
</template>
<script>
export default {
  props:{
    value:{
      type:Array
    },
    disabled:{
      type:Boolean,
      default:false,
    }
  },
  data: () => ({
    isLoading: false,
    merchantsID: null,
  }),

  methods: {
    onChange: function(theValue) {
      this.$emit("input", theValue);
    },
    customFilter: function(item,queryText){
      if(item.reference_id != null){
        return item.reference_id.toLowerCase().indexOf(queryText.toLowerCase()) > -1 ||  item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1
      }else{
        return item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1
      }
    }
  },

  mounted() {
    if (this.$store.state.merchant.currentIndex === -1) {
      this.isLoading = true;
      this.$store.dispatch("merchant/loadAllMerchants").then(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>
<style scoped lang="stylus"></style>
