<template lang="pug">
v-layout.mx-2(align-center  )
  image-thumbnail.mr-2(:width='60', :height="getHeight", :src="vehicle.thumbnail_id" :style="{'margin-top':`${getPadding*0.75}px`}")
  .nameColumnInfo
    .nameColumnTitle {{ vehicle.name }}
    .nameColumnDescription.make
      span.primary--text.mr-2() {{ vehicle.make }}
      span.grey-text() |
      span.grey--text.ml-2() {{vehicle.number_plate}}
    //- .nameColumnDescription
      | VIN: {{ vehicle.vin }}
    //- .nameColumnDescription
      b.grey--text.text--darken-4
        | {{ vehicle.numberplate }}
</template>
<script>
import VehicleStatus from "@/modules/global/components/others/Status";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components: {
    VehicleStatus,
    ImageThumbnail
  },
  props: {
    vehicle: Object,
    height: Number
  },
  data: () => ({}),
  computed: {
    getHeight: function() {
      return 0.8 * this.height;
    },
    getPadding: function() {
      return 0.1 * this.height;
    }
  }
};
</script>
<style scoped lang="stylus">
.nameColumn
  display: flex
  align-items: center


.nameColumn
.nameColumnInfo
  margin-left: 12px

.nameColumn
.nameColumnTitle
  font-weight: bold
  margin-bottom: 3px
  padding: 0px

.nameColumnDescription
  font-size: 10px
  color: gray
  padding: 0px

.make {
  font-size: 11px;
  margin-top: -4px;
}
</style>
