<template lang="pug">
  drop(:style="getStyleObject" @drop="handleDrop" @dragenter="handleDragEnter" @dragleave="handleDragLeave")
    .headline drag & drop new fields here
    v-btn(color="primary" outlined small @click="onClick" :disabled="$store.state.workflow.formBuilder.disableAllCompoents") Add
</template>
<script>
import { Drop } from "vue-drag-drop";
import colors from "vuetify/es5/util/colors";
export default {
  components: {
    Drop
  },

  data: () => ({
    withinDropBox: false
  }),

  computed: {
    getStyleObject: function() {
      let theStyleObject = {
        width: "100%",
        height: "100px",
        border: "3px dashed",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center"
      };

      if (this.withinDropBox) {
        theStyleObject["border-color"] = this.$vuetify.theme.primary;
        theStyleObject["background-color"] = colors.grey.lighten4;
        theStyleObject["color"] = this.$vuetify.theme.primary;
      } else {
        theStyleObject["border-color"] = "#CCCCCC";
        theStyleObject["background-color"] = "#FFFFFF";
        theStyleObject["color"] = colors.grey.lighten1;
      }

      if (this.$store.state.workflow.formBuilder.disableAllCompoents == true) {
        theStyleObject["opacity"] = "0.3";
      } else {
        theStyleObject["opacity"] = "1";
      }

      return theStyleObject;
    }
  },

  methods: {
    handleDrop(data) {
      this.$store.commit("workflow/formBuilder/addNewField", data);
      this.withinDropBox = false;
    },

    handleDragEnter() {
      this.withinDropBox = true;
    },

    handleDragLeave() {
      //data, event
      this.withinDropBox = false;
    },

    onDragendField: function(event) {
      console.log(event);
    },

    onClick: function() {
      if (this.$store.state.workflow.formBuilder.disableAllCompoents == false) {
        this.$store.commit("workflow/formBuilder/setModalWindow", true);
      }
    }
  }
};
</script>
<style scoped lang="stylus">
.tb_drop_zone {
  width: 100%;
  height: 100px;
  border: 3px dashed #CCCCCC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
