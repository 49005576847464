<template lang="pug">
  div(v-if="$store.state.issues.issue_data.length > 0")
    v-toolbar(dense flat)
      generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer()
      //- v-btn(color="primary" small) Some Button
    v-divider()
    v-container.mt-8.pa-8.white(fluid)
      
      v-row()
        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:body)
              div.mt-2.mb-2.pb-2
                table.DetailTable.body-2.fill-width
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Vehicle
                    td
                      vehicle-column(v-if="getIssue.vehicle" :vehicle="getIssue.vehicle")
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Name
                    td {{getIssue.name}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Description
                    td(style="white-space: pre-wrap;") {{getIssue.description}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Report Date
                    td() {{getIssue.report_date_time | moment("ddd, Do MMM YYYY &ndash; h:mm a")}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Report By
                    td() {{getIssue.reported_by.first_name}} {{getIssue.reported_by.last_name}} 
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Assigned
                    td() 
                      div(v-if="getIssue.assigned_to") {{getIssue.assigned_to.first_name}} {{getIssue.assigned_to.last_name}} 
                  tr(v-if="getIssue.service_date_time")
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Serviced Date
                    td() {{getIssue.service_date_time | moment("ddd, Do MMM YYYY &ndash; h:mm a")}}
                  tr(v-if="getIssue.closed_by")
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Closed By
                    td() {{getIssue.closed_by.first_name}} {{getIssue.closed_by.last_name}}
                  tr(v-if="getIssue.closed_date_time")
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Closed Date
                    td() {{getIssue.closed_date_time | moment("ddd, Do MMM YYYY &ndash; h:mm a")}}
                  tr
                    td.grey.lighten-1.text--darken-1.pl-4.white--text.subtitle-1(colspan="2") Overdue Setting
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Due Date
                    td() 
                      div(v-if="getIssue.assigned_to") {{getIssue.due_date_time | moment("Do MMM YYYY") }} 
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Odometer
                    td() 
                      div(v-if="getIssue.odometer") {{getIssue.odometer}} 

          location-map-panel(v-if="getIssue.location" :coordinates="getIssue.location")
        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:title)
              | Comments
              

            template(v-slot:body)
              div() {{getIssue.comments}}

          base-panel()
            template(v-slot:title)
              | Photos
              

            template(v-slot:body)
              attach-fill-form( :schema="imagesSchama" :data="{value:getIssue.photos_id}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" hideButton )

          base-panel()
            template(v-slot:title)
              | Documents
            
            template(v-slot:body)
              attach-fill-form(:schema="documentsSchama" :data="{value:getIssue.documents_id}" @onChange="saveDocuments"  @onMenuAction="onMenuActionImages" hideButton )
              

      

      
</template>
<script>
import BasePanel from "@/modules/global/components/panel/basePanel";
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import LocationMapPanel from "@/modules/global/components/panel/LocationMapPanel";

export default {
  components: {
    BasePanel,
    VehicleColumn,
    AttachFillForm,
    GenericBreadCrumbs,
    LocationMapPanel
  },
  data: () => ({
    title: "header",
    answer: "Sengenge ni Ngombe",
    breadCrumbsItems: [
      {
        text: "Issues",
        disabled: false,
        path: "issues"
      },
      {
        text: "Issue Detail",
        disabled: true
      }
    ],
    imagesSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Issue Images",
      hint: null,
      type: "image",
      image_size: "small",
      multiple: true
    },
    documentsSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Issue Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true
    }
  }),
  computed: {
    getIssue: function() {
      return this.$store.getters["issues/getCurrentIssue"];
    }
  },
  methods: {
    onCloseDialog: function() {},
    onMenuActionImages: function(value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("issues/deleteImage", value.theObject.id);
          break;
      }
    },
    saveImages: function(theObject) {
      this.$store.commit("issues/updateIssue", {
        path: "photos_id",
        value: theObject.value
      });
    },
    saveDocuments: function(theObject) {
      this.$store.commit("issues/updateIssue", {
        path: "documents_id",
        value: theObject.value
      });
    },
    onMenuActionDocuments: function(value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("issues/deleteDocument", value.theObject.id);
          break;
      }
    }
  },
  mounted() {
    if (this.$store.state.issues.issue_data.length < 2) {
      this.$store
        .dispatch("issues/fetchIssue", this.$route.params.issueID)
        .then(() => {});
    }
  }
};
</script>
<style scoped lang="stylus">
.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0
    padding 0

    padding-top 6px
    padding-bottom 8px
</style>
