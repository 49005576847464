import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

Vue.use(Vuetify);
const opts = {
    theme: {
        themes: {
            light: {
                primary: "#ec037b",
                secondary: "#424242",
                accent: "#63c5c7",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107"
            }
        }
    },
    icons: {
        iconfont: "md" // default - only for display purposes
    }
};

export default new Vuetify(opts);
