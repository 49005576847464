<template>
  <div
    class="thumbnail"
    :style="{
      'background-image':
        'url(' + $media_path + '/images/thumbnails/' + data.thumbnail + ')'
    }"
  >
    <div>
      <!--
        <v-icon class="deleteBtn" @click="$emit('onDelete',data)">zoom_in</v-icon>
      -->
      <v-icon class="deleteBtn" @click="$emit('onDelete', data)">close</v-icon>
    </div>

    <div class="myLabel">
      <slot>Mercedes Benz.jpg</slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  data: () => ({
    mouseOnClose: false,
    imagePict: ""
  }),

  methods: {
    getStyleMouse: function(value) {
      if (value) {
        this.closeStyle = {
          color: "red",
          "padding-left": "8px",
          cursor: "pointer",
          "font-size": "200%"
        };
      } else {
        this.closeStyle = {
          color: "grey",
          "padding-left": "8px",
          cursor: "pointer",
          "font-size": "150%"
        };
      }
    }
  }
};
</script>

<style scoped>
.thumbnail {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  min-width: 120px;
  min-height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px dotted #999999;
  border-radius: 13px;
  text-align: right;
  margin: 8px 15px;
}

.thumbnail:hover {
  border: 1px solid #333333;
  cursor: pointer;
}

.deleteBtn {
  height: 25px;
}

.deleteBtn:hover {
  color: black;
}

.myLabel {
  font-size: 12px;
  text-align: center;
  color: #999999;
}
</style>
