<template lang="pug">
  div()
    .d-flex.align-center()
      v-text-field(
        v-model="computedValue"
        :label="schema.label"
        :type="getType"
        :hint="schema.hint"
        :persistent-hint="schema.hint != null"
        :counter="schema.maxlength != null"
        :maxlength="schema.maxlength"
        :rules="[requiredRule]"
        clearable
        @keypress="integerNumbers($event)"
      )
      add-comment-button.mx-1(v-model="comments" @showCommentInput="onShowCommentInput")
      upload-blob.mx-1(v-model="blobs" type="image" label="Label" hint="hint" :isMultiple="true")

    .d-flex()
      v-tooltip(bottom v-for="(item,index) in comments" :key="item.time")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") message
        span() {{item.value}}
      .mx-2()
      v-tooltip(bottom v-for="(item,index) in blobs" :key="item")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") image
        div()
          image-thumbnail(:width='360', :height='200', :src="item")

    div.mt-2(v-if="showCommentInput")
      v-textarea(outlined  ref="comment"  label="Comment" auto-grow rows="2" v-model="comment" hide-details )
      .d-flex.my-2()
        v-spacer
        v-btn.mx-2(small color='grey darken-1', text, @click.native="cancel()") Close
        v-btn.mx-2(small color='primary', @click.native="save()") Save


</template>
<script>
import AddCommentButton from "@/modules/inspections/components/formDesigner/component/AddCommentButton";
import UploadBlob from "@/modules/global/components/forms/UploadBlob"
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";

export default {
  components: {
    AddCommentButton,
    UploadBlob,
    ImageThumbnail
  },
  props: ["schema", "dataValue", "index","disabled"],
  data: () => ({
    comment: null,
    showCommentInput: false,
    showBlobInput: false,
    theValue: null,
    theComments: null,
    theBlobs: null,
    theValid: null,
    thePassed: null
  }),


  computed: {
    requiredRule: function () {
      return this.schema.required ? value => !!value || "Required." : false;
    },

    getType: function () {
      if (this.schema.type == 'decimal') {
        return 'number'
      }
      return this.schema.type;
    },

    comments: {
      get: function () {
        return this._.clone(this.dataValue.comments);
      },
      set: function (theComments) {
        console.log(theComments)
        this.theComments = theComments;
        this.update();
      },
    },

    blobs: {
      get: function () {
        return this._.clone(this.dataValue.blobs);
      },
      set: function (theBlob) {
        this.theBlobs = theBlob;
        this.update();
      }
    },


    computedValue: {
      get: function () {
        return this.dataValue.value;
      },

      set: function (value) {
        this.theValue = value;

        if (this.getType === 'number') {
          this.theValue = parseInt(value)
        }

        if (this.schema.type === 'decimal') {
          this.theValue = parseFloat(value)
        }

        this.update();
      }
    }
  },

  methods: {
    update: function () {
      this.theValid = true;
      this.thePassed = true;
      // verify
      if (
          this.schema.required &&
          (this.theValue == null || ((this.getType === 'number' || this.getType === 'decimal') && isNaN(this.theValue)) || this.theValue.length == 0)) {
        this.theValid = false;
      }

      if(this.schema.comment_on_fail && this.theValue == null && this.comments.length == 0){
        this.theValid = false;
      }

      if(this.theValue == null){
        this.thePassed = false;
      }

      this.$emit("onChange", {
        id: this.schema.id,
        value: {valid: this.theValid, passed: this.thePassed, value: this.theValue, comments: this.theComments, blobs: this.theBlobs},
        index: this.index
      });

    },


    onShowCommentInput: function () {
      this.showCommentInput = true;
      this.$nextTick(()=>{
        this.$refs.comment.focus();
      })
    },


    cancel: function () {
      this.comment = null;
      this.showCommentInput = false;
    },


    save: function () {
      let theComments = [];
      if (this.comments != undefined || this.comments != null) {
        theComments = this.comments;
      }
      theComments.unshift({
        value: this.comment,
        time: new Date().getTime(),
        by_id: this.$store.state.profile.userInfo.contact.id,
        by: `${this.$store.state.profile.userInfo.contact.first_name[0]}${(this.$store.state.profile.userInfo.contact.last_name != null) ? this.$store.state.profile.userInfo.contact.last_name[0] : ''}`
      })
      this.comments = theComments;
      this.cancel();
    },
    integerNumbers: function (event) {
      // ignore the key "e" and "." from numbers
      if (this.schema.type == 'number') {
        if (/e/.test(event.key) || /\./.test(event.key)) {
          event.preventDefault();
        } else {
          return true

        }
      }
    }
  },

  mounted() {
    this.theValue = this.dataValue.value;
    this.theComments = this.dataValue.comments;
    this.theBlobs = this.dataValue.blobs;
    this.theValid = this.dataValue.valid;
    this.thePassed = this.dataValue.passed;
  }
};
</script>
<style lang="stylus" scoped></style>
