<template lang="pug">
  #blankcontent(v-if="totalcostdata.length > 0")
    .panelTitle() Total Cost Trend
    v-divider.mx-2()
    bar-chart(:style="{height:`${clientHeight-40}px`, width: `100%`}" :chartdata="getDataSets" :options="chartOption")

</template>
<script>
import BarChart from "@/modules/global/components/charts/BarChart";
import axios from "axios"
import moment from "moment"


export default {
  components: {
    BarChart
  },
  props: {
    clientHeight: Number
  },
  data: () => ({
    totalcostdata: [],
    dailydata: [],
    chartOption: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              maxTicksLimit: 5,
              beginAtZero: true
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0.05)"
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ]
      },
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 20,
          bottom: 0
        }
      }
    }
  }),

    mounted() {
    axios.get("/api/dashboard/totaldailycost")
    .then(response => {
      // console.log(response.data)
      this.totalcostdata = [];
      this.dailydata = [];
      let dailyindexes = []
      let currentday = moment().format("d")
      for (var i = 7; i > 0; i--) {
        let index = currentday - i 
        index = index < 1 ? 7 + index : index
        console.log(index)
        dailyindexes.push(index)
        let c = new Date(`2020-${index}-01`)
        this.dailydata.push(moment(c).format("ddd"))

      }
      dailyindexes.forEach(element => {
        let b = response.data.find(item => moment(item.day).format("d") == element.toString()) 
      
        if(typeof b == 'undefined'){
          this.totalcostdata.push(0)
        }
        else{
          this.totalcostdata.push(b.sum)
        }

      })
      
     
    });
  },


  
  
  computed: {
    getDataSets: function() {
      let obj = {
        labels: this.dailydata,
        datasets: [
          {
            label: "Days",
            data: this.totalcostdata
            // borderWidth: 2
          }
        ]
      };
      obj.datasets[0].backgroundColor = this.$vuetify.theme.themes.light.primary;
      // obj.datasets[0].borderColor = this.$vuetify.theme.themes.light.primary;
      return obj;
    }
  },
  methods: {
    generator() {
      let dayList = [];
      for (let i = 4; i >= 1; i--) {
        dayList.push(
          this.$moment(new Date())
            .subtract(i, "day")
            .format("ddd")
        );
      }

      let valueList = [];
      for (let i = 4; i >= 1; i--) {
        valueList.push(Math.round(i * 10));
      }

      return { x: dayList, y: valueList };
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight bold
  padding: 5px 30px;
  height:30px;
  display: flex;
  width: 100%
  justify-content: space-between;
}

.lineChartPanel {
  height: calc(100% - 45px);
}
</style>
