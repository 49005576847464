import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const PARTMANUFACTURER_API = "/partManufacturer";

// ----------------------- STATE ------------------------- //
const state = {
  partmanufacturer_data: [],
  currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getPartManufacturer: state =>
    getField(state.partmanufacturer_data[state.currentIndex]),

  getCurrentPartManufacturer: state => {
    return state.partmanufacturer_data[state.currentIndex];
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updatePartManufacturer(state, payload) {
    updateField(state.partmanufacturer_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.partmanufacturer_data.findIndex(
      partmanufacturer => partmanufacturer.id == theID
    );
  },

  loadAllPartManufacturers(state, payload) {
    state.partmanufacturer_data = payload.data;
    state.currentIndex = 0;
  },

  addPartManufacturer(state, payload) {
    state.partmanufacturer_data.push(payload.data);
    state.currentIndex = state.partmanufacturer_data.length - 1;
  },

  savePartManufacturer(state, payload) {
    state.partmanufacturer_data[state.currentIndex] = payload.data;
  },

  fetchPartManufacturer(state, payload) {
    if (state.currentIndex == -1) {
      state.partmanufacturer_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.partmanufacturer_data[state.currentIndex] = payload.data;
    }
  },

  deletePartManufacturer(state, payload) {
    state.currentIndex = state.partmanufacturer_data.findIndex(
      partmanufacturer => partmanufacturer.id == payload.data.id
    );
    state.partmanufacturer_data.splice(state.currentIndex, 1);
  },
  reset(state) {
    state.partmanufacturer_data = []
    state.currentIndex = -1
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllPartManufacturers({ commit }) {
    try {
      commit(
        "loadAllPartManufacturers",
        await Repository.get(`${PARTMANUFACTURER_API}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async addPartManufacturer({ commit }) {
    await Repository.get(`${PARTMANUFACTURER_API}/new`)
      .then(response => {
        commit("addPartManufacturer", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async savePartManufacturer({ state, commit }) {
    try {
      commit(
        "savePartManufacturer",
        await Repository.post(
          `${PARTMANUFACTURER_API}`,
          state.partmanufacturer_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchPartManufacturer({ commit }, payloadID) {
    try {
      commit(
        "fetchPartManufacturer",
        await Repository.get(`${PARTMANUFACTURER_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deletePartManufacturer({ commit }, payloadID) {
    try {
      commit(
        "deletePartManufacturer",
        await Repository.delete(`${PARTMANUFACTURER_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchPartManufacturers({ commit }, searchTerm) {
    try {
      commit(
        "loadAllPartManufacturers",
        await Repository.get(`${PARTMANUFACTURER_API}/search?q=${searchTerm}`)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
