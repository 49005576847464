<template lang="pug">
table.AlertMeter
  tr
    th.primary.py-0(width="10px")
      v-switch(v-model="switch1" color="white" @change="onSwitchChange")
    th.primary.py-0.px-0
        div.subheading Odometer Reminder
          .caption.primary.white--text  Leave all fields blank if you don't want to use this reminder by odometer
    th.primary.darken-1(v-show="switch1")
      .span.subheading Starting at:
      div.white--text.font-weight-bold {{start_odometer}}
  template(v-if="showOdometerSettings" )
    tr()
      td(colspan="2")
        v-container(v-show="switch1" )
          v-layout.pa-2(column)
            v-flex(xs12)
              v-text-field(v-model="interval_odometer",type="number", label="Kilometer Interval", hint="Repeat based on usage (e.g. Oil Change every 5,000 kilometers)" :persistent-hint="true" prepend-icon="autorenew")
            v-flex(xs12)
              .threshold
                v-text-field(v-model="due_odometer", label="Kilometer Threshold", hint="Number  of kilometers in advance you consider this reminder to be 'due soon' (ex: 500 kilometers is common for a typical fleet vehicle)", :persistent-hint="true")
            v-flex(xs12)
              odometer-input(v-model.number='start_odometer' :vehicle_id="getVehicles" label="Start Odometer" units="kilometers")
      td.grey.lighten-3.sampleColumn.body-2( v-show="switch1" style="width:200px")
        div(v-for="i in 10" :key="i")
          | {{ parseInt(start_odometer)+(parseInt(i)*interval_odometer) }}
        .mt-3.mb-1.grey--text.text--darken-1 ...repeats Infinitely
  template(v-else)
    tr()
      td(colspan="3")
        .odometerWarning.warning(v-if="switch1" ) Select One Vehicle => Odometer Reminder is only available for one vehicle at a time!
</template>
<script>
import OdometerInput from "@/modules/global/components/forms/OdometerInput";
export default {
  props: {
    value: Object,
    mode: {
      type: String,
      default: "service"
    }
  },

  data: () => ({
    switch1: true,
    odometer: 999,

  }),
  components:{
    OdometerInput,
  },
  computed: {

    getVehicles: function() {
      switch(this.mode) {
        case "service": return this.getCurrentServiceReminder; break;
        case "vehicle_renewal": return this.getCurrentVehicleRenewal; break;
      }

      return null;
    },

    getCurrentServiceReminder: function () {
      return this.$store.getters["reminders/service_reminder/getCurrentService_reminder"].vehicles_id;
    },

    getCurrentVehicleRenewal: function () {
      return this.$store.getters["reminders/vehicle_renewal/getCurrentVehicle_renewal"].vehicles_id;
    },
    start_odometer: {
      get: function() {
        return this.value.start_odometer;
      },
      set: function(value) {
        this.$emit("input", {
          odo_reminder: this.switch1,
          start_odometer: value,
          interval_odometer: this.interval_odometer,
          due_odometer: this.due_odometer
        });
      }
    },

    interval_odometer: {
      get: function() {
        return this.value.interval_odometer;
      },
      set: function(value) {
        this.$emit("input", {
          odo_reminder: this.switch1,
          start_odometer: this.start_odometer,
          interval_odometer: value,
          due_odometer: this.due_odometer
        });
      }
    },

    due_odometer: {
      get: function() {
        return this.value.due_odometer;
      },
      set: function(value) {
        this.$emit("input", {
          odo_reminder: this.switch1,
          start_odometer: this.start_odometer,
          interval_odometer: this.interval_odometer,
          due_odometer: value
        });
      }
    },
    showOdometerSettings:function (){

      switch(this.mode) {
        case "service":  if (this.getCurrentServiceReminder == null) {
          return false
        }else {
          console.log(this.getCurrentServiceReminder)
          if (this.getCurrentServiceReminder.length == 1) {
            console.log("show it now")
            return true
          }else{
            return false
          }
        }
        case "vehicle_renewal": if (this.getCurrentVehicleRenewal == null) {
          return false
        }else {
          console.log(this.getCurrentVehicleRenewal)
          if (this.getCurrentVehicleRenewal.length == 1) {
            console.log("show it now")
            return true
          }else{
            return false
          }
        }
      }


      return false;

    }
  },

  methods:{
    onSwitchChange: function() {
      this.$emit("input", {
        odo_reminder: this.switch1,
        start_odometer: this.start_odometer,
        interval_odometer: this.interval_odometer,
        due_odometer: this.due_odometer
      });
    }

  },

  mounted() {
    this.switch1 = this.value.odo_reminder;
  }
};
</script>
<style scoped lang="stylus">
.AlertMeter
  border-collapse collapse
  width 100%


.AlertMeter td
  border 1px solid #ddd

th
  padding 16px

.theFirstColumnTitle
  display flex



.AlertMeter th
  padding-top 12px
  padding-bottom 12px
  text-align left
  color white

.sampleColumn
  text-align center

.reminderSmallText
  font-size 12px;
  color white

.threshold
  border 1px solid #CCCCCC
  padding 12px
  border-radius 8px



.odometerWarning{
  padding 12px 10px;
  border 1px solid #CCCCCC;
  border-radius 15px;
  margin 30px 20px
  height 60px
  display flex;
  flex-direction: column;
  justify-content: center;
  font-weight bold
}
</style>
