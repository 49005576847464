<template lang="pug">
.py-3.px-1
  .theValue(:style='{ color: this.$vuetify.theme.primary }')
    | {{ value }}
  .theLabel
    slot
</template>

<script>
export default {
  props: {
    value: String
  },

  data: () => ({})
};
</script>

<style scoped>
.theLabel {
  color: #999999;
  font-size: 90%;
}

</style>
