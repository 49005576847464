<template lang="pug">
  v-badge(v-if="name" bordered bottom dot color="deep-purple accent-4"   offset-x="32" offset-y="8")
    v-avatar.accent.white--text.mr-5(size="40")
      template(v-if="thumbnail")
        img.chatAvatar(:src="`/media/${accountID}/${thumbnail}`")
      template(v-else)
        span() {{firstInitial}}{{lastInitial}}
</template>

<script>
import axios from "axios";
export default {
  props:['jid','name'],
  data:()=>({
    thumbnail:null,
    accountID:null,
    firstInitial:null,
    lastInitial:null,
  }),
  mounted() {
    axios.get(`/api/contact/getAvatarByUserID/${this.jid}`).then((response)=>{
      this.thumbnail = response.data.thumbnail;
      this.accountID = response.data.account_id;
      console.log(`/media/${this.accountID}/${this.thumbnail}`)
    })
    if (this.name != null){
      this.firstInitial = this.name.toUpperCase().split(" ")[0][0]
      this.lastInitial = this.name.toUpperCase().split(" ")[1][0]
    }

  }
}
</script>

<style scoped>
.chatAvatar{
  object-fit: cover;
}
</style>