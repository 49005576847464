<template lang="pug">
  div()
    div(v-if="$store.state.users.user_data.length > 0 && this.user.id !== '00000000-0000-0000-0000-000000000000'")
      v-container.pt-4(grid-list-xl)
        v-layout(row wrap)
          v-flex(xs6)
            v-card.my-3(color="white")
                overview-information-panel(:items="userInfo.items", :title="userInfo.title", @edit="onEditUser", showEditButton)
          v-flex(xs6)
            v-card.my-3(color="white")
                overview-information-panel(:items="logInfo.items", :title="logInfo.title")

                
    div(v-else)
      v-container.pt-4(grid-list-xl)
        v-layout(row wrap)
          v-flex(xs12)
            v-card.my-3(color="white")
              v-flex(xs12)
                overview-information-panel(:items="[{label:'Status',value: 'Not a System Users'}]", title="User Information" @edit="onEditUser", showEditButton)

</template>
<script>
import OverviewInformationPanel from "@/modules/global/components/others/OverviewInformationPanel";

export default {
  components: {
    OverviewInformationPanel
  },

  data: () => ({}),

  computed: {
    contact: {
      get() {
        console.log(this.$store.getters["contacts/getCurrentContact"]);
        return this.$store.getters["contacts/getCurrentContact"];
      }
    },

    user: {
      get: function() {
        return this.$store.state.users.user_data[0];
      }
    },

    userInfo: function() {
      return {
        title: "User Information",
        items: [
          { label: "Username", value: this.user.username },
          { label: "Email", value: this.contact.email },
          {
            label: "Role",
            value: this.user.role != null ? this.user.role.name : ""
          },
          {
            label: "Administrator",
            value: this.user.is_administrator
          },
          {
            label: "Active",
            value: this.user.active
          }
        ]
      };
    },

    logInfo: function() {
      return {
        title: "User Log Information",
        items: [
          { label: "Last Login Date", value: "TODO: last login date" },
          { label: "No of Logins", value: "5" }
        ]
      };
    }
  },

  methods: {
    onEditUser: function() {
      console.log("edit the user: OverviewUserDetails.vue");

      this.$router.push({
        name: "contactUserInfo",
        params: { contactID: this.$route.params.contactID }
      }).catch(()=>{});
    }
  }
};
</script>
<style scoped lang="stylus"></style>
