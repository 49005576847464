<template lang="pug">
  div
    panel-title-header(label="Inspection Details")
    div.mt-2.mb-2.pb-2
      table.DetailTable.body-2.fill-width
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3.pl-2 Vehicle
          td
            vehicle-column-by-id(v-if="record.vehicle_id" :vehicle_id="record.vehicle_id")
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3.pl-2 Odometer
          td
            div() {{record.odometer}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3.pl-2 Transaction ID
          td
            div() {{record.tx_id}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3.pl-2 Inspection Form
          td
            .d-flex.align-center()
              div(v-if="inspectionForm.thumbnail_id")
                image-thumbnail.mr-2(:src="inspectionForm.thumbnail_id" :height="60" :width="80")
              div.primary--text.font-weight-bold() {{inspectionForm.name}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3.pl-2 Inspection Form Description
          td
            div() {{inspectionForm.description}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3.pl-2 Inspection Form Version
          td
            div() {{record.inspection_form_version}}

        tr
          td.goRight.grey--text.text--darken-1
            .mr-3.pl-2 Submitted On
          td {{getTime(record.submitted_on)}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3.pl-2 Location Cordinates
          td {{record.geotag.lon}}, {{record.geotag.lat}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3.pl-2 Time taken
          td {{getTimeSpent(record.duration)}}
        tr
          td.goRight.grey--text.text--darken-1
            .mr-3.pl-2 Submitted By
          td
            contact-column-by-id(v-if="record.submitted_by_id" :contact_id="record.submitted_by_id")

</template>

<script>
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById";
import moment from "moment";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail.vue";

export default {
  props: {
    record: Object,
    inspectionForm: Object,
  },
  components: {
    ImageThumbnail,
    PanelTitleHeader,
    VehicleColumnById,
    ContactColumnById
  },
  methods: {
    getTime(submitted_time) {
      return moment(submitted_time).format("ddd, Do MMM YYYY, h:mm a")
    },
    getTimeSpent(theDuration) {
      if (theDuration == null || theDuration.status < 2) return "";
      let microseconds = moment
          .utc(
              moment
                  .duration(theDuration.Microseconds / 1000000, "seconds")
                  .asMilliseconds()
          )


      let days =
          theDuration.Days > 0
              ? theDuration.Days + " Days + "
              : "";

      let months =
          theDuration.Months > 0
              ? theDuration.Months + " Months + "
              : "";

      if (theDuration.Days == 0 && theDuration.Months == 0 && microseconds.hours() == 0 && microseconds.minutes() == 0) {
        return "less than a minute"
      } else {
        if (theDuration.Days == 0 && theDuration.Months == 0 && microseconds.hours() == 0) {
          return microseconds.minutes() + " minutes"
        } else {
          return months + days + microseconds.format("H [hr]:mm [min]");
        }

      }


    }

  }

}
</script>

<style scoped lang="stylus">
.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0 5px 0 5px
    padding 0

    padding-top 6px
    padding-bottom 8px
</style>