<template lang="pug">
  div
    v-data-table.elevation-1(
      @click:row="onClickOverview"
      :headers='headers', :items='$store.state.contacts.contact_data'  :loading="loading"  show-select)
      template(v-slot:progress)
        v-progress-linear(slot="progress"  height="2" :indeterminate="loading")


      template(v-slot:item.name="{item}")
        column-slot(:style="{height:'72px'}" )
          contact-column-by-id(v-if="item.id" :contact_id="item.id")


      template(v-slot:item.status="{item}")
        v-icon(small='', :style="{color: [item.status] == 'active' ? 'green' : 'gray'}") fiber_manual_record
        | {{ item.status }}


      template(v-slot:item.contact="{item}")
        .caption(v-if="item.technician") Technician
        .caption(v-if="item.operator") Technician
        .caption(v-if="item.employee") Employee
        .d-flex.caption(v-if="item.customer")
          .grey--text Customer:
          .font-weight-bold.ml-2.categoryType(@click="editCustomer(item.customer_id)") {{item.customer.name}}
        .d-flex.caption(v-if="item.merchant")
          .grey--text Supplier:
          .font-weight-bold.ml-2.categoryType(@click="editMerchant(item.merchant_id)") {{item.merchant.name}}


      template(v-slot:item.group="{item}")
        template(v-if="item.group")
          span(:style="groupStyle(item.group.color)") {{item.group.name}}
        template(v-else)
          .grey--text() no group


      template(v-slot:item.action="{item}")
        v-layout(justify-end align-center)
          more-drop-down-menu.mr-2(:items="menuitems" @onClick="onClick" :theObject="item" style="height:24px")

      template(v-slot:item.assignment_vehicle="{item}")
        vehicle-column(v-if="item.assignment_vehicle" :vehicle="item.assignment_vehicle")


</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById";
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import ColumnSlot from "@/modules/global/components/table/ColumnSlot";

export default {
  components: {
    ColumnSlot,
    MoreDropDownMenu,
    ImageThumbnail,
    ContactColumnById,
    VehicleColumn,
  },
  name: "ContactsTable",
  data() {
    return {
      dialog: false,
      selectedObject: null,

      loading: false,

      headers: [
        {text: "Name", value: "name"},
        {text: "Group", value: "group"},
        {text: "Assigned Vehicle", value: "assignment_vehicle"},
        {text: "Category", value: "contact"},
        {text: "Actions", value: "action", align: "right", sortable: false}
      ]
    };
  },

  computed: {
    menuitems: function () {
      let menuList = [];

      if (this.$store.state.profile.userInfo.is_administrator) {
        menuList.push({
          title: "Register Phone User",
          icon: "smartphone",
          action: "register_mobile"
        });

        menuList.push({title: "Delete", icon: "delete", action: "delete"});
      }
      menuList.push({title: "Edit", icon: "edit", action: "edit"});

      menuList.push({
        title: "Overview",
        icon: "dashboard",
        action: "overview"
      });
      menuList.push({
        title: "Download",
        icon: "cloud_download",
        action: "download"
      });
      return menuList;
    }
  },
  methods: {

    groupStyle: function (value) {
      let obj = {};
      obj["border-bottom"] = "3px solid";
      obj["border-bottom-color"] = value;
      return obj;
    },

    onClickOverview: function(object) {
      this.$store.commit(
          "contacts/setCurrentIndexByID",
          object.id
      );
      this.$router.push({
        name: "contactDashboard",
        params: {contactID: object.id}
      }).catch(()=>{});
    },
    onClickRow: function (object) {
      // call teh onClick Handler
      this.onClick({action: "edit", theObject: object});
    },

    editCustomer: function(value){
      this.$store.commit(
          "customer/setCurrentIndexByID",
          value
      );
      this.$router.push({
        name: "customerDetailsForm",
        params: { contactID: value }
      }).catch(()=>{});
    },
    editMerchant: function(value){
      this.$store.commit(
          "merchant/setCurrentIndexByID",
          value
      );
      this.$router.push({
        name: "merchantDetailsForm",
        params: { contactID: value }
      }).catch(()=>{});
    },

    onClick: function (value) {
      switch (value.action) {
        case "register_mobile":
          this.$emit("showQRCode", value.theObject.id);
          break;
        case "make_user":
          this.$store
              .dispatch("users/isContactUser", value.theObject.id)
              .then(() => {
                console.log("haleluyah");
              });
          break;
        case "edit":
          this.$store.commit(
              "contacts/setCurrentIndexByID",
              value.theObject.id
          );
          this.$router.push({
            name: "contactNewBasic",
            params: {contactID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          console.log("delete the Item");
          this.$store.dispatch("contacts/deleteContact", value.theObject.id);
          // this.selectedObject = value.theObject;
          // this.dialog = true;
          break;
        case "overview":
          this.$store.commit(
              "contacts/setCurrentIndexByID",
              value.theObject.id
          );
          this.$router.push({
            name: "contactDashboard",
            params: {contactID: value.theObject.id}
          }).catch(()=>{});
          break;
      }
    }
  },

  mounted() {
    this.$store.dispatch("contacts/loadAllContacts").then(() => {
      console.log(this.$store.state.contacts.contact_data[0]);
    });
  }
};
</script>

<style scoped>
.tabs {
  text-transform: none;
  font-size: small;
}

.contactDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactAddress {
  display: flex;
  align-items: center;
  margin-top: -5px;
}

.categoryType {
  cursor: pointer;
}

.categoryType:hover{
  text-decoration: underline;
}
</style>
