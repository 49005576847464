<template lang="pug">
  div
    div
      v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
      v-text-field(v-model="selectHint" label="Description")
      .subheading.mb-0() Select Upload Type
      v-radio-group.mt-0(v-model="selectType")
        v-radio(label="Any" value="any")
        v-radio(label="Document" value="document")
        v-radio(label="Image Type" value="image")
        v-radio(label="Video Type" value="video")
        v-radio(label="Audio Type" value="audio")
        v-radio(label="Media Type" value="media")

    
    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        showAdvancedProperties(:showAdvanced="showAdvanced" )
      

      div(v-show="showAdvanced")



        v-checkbox(v-model="selectMultiple" label="Allow Multiple Attachements")

        .subheading.mb-0() Preview Size
          v-radio-group.mt-0(v-model="selectPreviewSize")
            v-radio(label="Small" value="small")
            v-radio(label="Medium" value="medium")
            v-radio(label="Large" value="large")
        permissions-component()
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)
</template>
<script>
import SETTINGS_DEFAULT from "@/modules/workflow/formBuilder/store/formSettings";
import ShowAdvancedProperties from "@/modules/workflow/formBuilder/components/ui/ShowAdvancedProperties";
import PermissionsComponent from "@/modules/workflow/formBuilder/components/ui/PermissionsComponent.vue";

export default {
  components: {
    ShowAdvancedProperties,
    PermissionsComponent
  },
  data: () => ({
    properties: {},
    showAdvanced: false
  }),

  computed: {
    selectedFieldName: {
      get: function() {
        return this.properties.label;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "label",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },

    selectType: {
      get: function() {
        return this.properties.type;
      },

      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "type",
          value: value
        });
      }
    },

    selectHint: {
      get: function() {
        return this.properties.hint;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "hint",
          value: value
        });
      }
    },

    selectID: {
      get: function() {
        return this.$store.getters[SETTINGS_DEFAULT.GET_PATH].id;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },

    selectMultiple: {
      get: function() {
        return this.properties.multiple;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "multiple",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "is_full_width",
          value: value
        });
      }
    },

    selectPreviewSize: {
      get: function() {
        return this.properties.preview_size;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "preview_size",
          value: value
        });
      }
    }
  },

  mounted() {
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    this.$refs.fieldName.focus();
  }
};
</script>
<style scoped lang="stylus"></style>
