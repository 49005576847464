<template lang="pug">
  v-layout.py-2.px-6(align-center)
    image-thumbnail.mr-3(:width="80" :height="80"  :src="vehicle.thumbnail_id")
    .HeaderText
      h2.headline.font-weight-bold.grey--text.text--darken-4 {{vehicle.name}}
      p.ma-0.pa-0.caption.font-weight-regular {{vehicle.type}} · {{ vehicle.make }} · {{vehicle.data.vin}} · {{vehicle.numberplate}}
      v-divider.mt-1
      v-layout.caption(v-if="vehicle.odometer")
        .HorizontalAlignDiv(@click="onAction('editOdometer')")
          p.ma-0.pa-0 {{vehicle.odometer.meter}}
            span.grey--text.text--darken-2 Km
            v-icon.pl-1(small) edit
</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";

export default {
  components: {
    ImageThumbnail
  },
  props: {
    vehicle: Object
  },
  data: () => ({}),
  mounted() {
    console.log(this.value);
  }
};
</script>
<style lang="stylus" scoped>
.OverviewHeader {
  width: 100%;
  height: 150px;
}

.HeaderText {
  display: flex;
  flex-direction: column;
}
</style>
