<template lang="pug">
  div()
    v-dialog(v-model="dialogShow" persistent max-width="500px")
      input( hidden type="file" id="file" ref="file" :accept="getMimeType"  @change="handleFileupload()" required)
      template(v-slot:activator="{ on }")
        .blobBtn(v-on="on")
          v-icon() image
          .caption() {{blobs != null ? "+"+blobs.length :"&nbsp;"}}

      v-card
        v-toolbar(color="primary")
          .UploadToolbar.primary.white--text()
            .title {{label}}
            .caption.mt-1(v-if="hint") {{hint}}

        v-progress-linear.ma-0(v-show="progressVisible", v-model="percentageComplete")
        v-form(v-model="valid"  ref="form")
          v-container(grid-list-sm)
            v-layout(row, wrap, justify-end)
              v-flex(xs12)
                template(v-if="!isEdit")
                  .uploadDetails
                    v-btn(@click="selectFile()") {{hint || 'Select File'}}
                    v-text-field.pl-2(v-if="file"  v-model="file.name" disabled  :hint="`File Size: ${originalFileSize} Mb `" :persistent-hint="true" )
                template(v-else)
                  v-text-field.mb-3(v-model="originalFileName" disabled hint="File Name" :persistent-hint="true" readonly)
              v-flex(xs12)
                v-text-field(v-model="fileName" ref="fileName" tabindex="2"  label="Name of File" hint="Name of File" :persistent-hint="true" :rules="nameRules" required)
              v-flex(xs12)
                v-textarea(v-model="description" tabindex="3"  label="Description" hint="Description of the File" :persistent-hint="true")
              v-flex(xs12 v-if="errorString")
                v-layout(justify-end)
                  .caption.error--text {{errorString}}
        v-card-actions
          v-layout(justify-end)
            v-btn(text color="grey" @click="closeDialog" tabindex="5" ) Cancel
            v-btn(color="primary" @click="submit()" tabindex="4" ) {{isEdit?"Save":"Upload"}}

</template>

<script>
import Repository from "@/repository";

export default {
  name: "AddBlob.vue",
  props: ["value", "type", "label", "hint", "isMultiple"],
  data: () => ({
    dialogShow: false, // toggle to show the dialog window
    isEdit: false, // togle if on "edit Mode" or "new Mode"
    selectedEditID: null, // Usefull when the user clicks the Update Button
    progressVisible: true, // toggle to show the progress bar
    percentageComplete: 0, // percentage when complete
    valid: null, // check if the
    file: {},
    originalFileSize: null,
    originalFileName: null,
    fileName: "",
    fileType: null,
    description: "",
    nameRules: [
      v => !!v || "Name is required",
      v => (v && v.length >= 3) || "Name must be more than 3 characters"
    ],
    errorString: null,
    blobs: null,
  }),
  mounted() {
    this.blobs = this.value;
  },
  methods: {
    getMimeType: function () {
      let mime_type = "";
      switch (this.type) {
        case "image":
          mime_type = "image/bmp, image/jpeg, image/jpg, image/png, image/ttf, image/tiff";
          break;
        case "any":
          mime_type = "";
          break;
        case "custom":
          mime_type = "";
          break;
        case "audio":
          mime_type = "audio/mpeg, audio/aac, audio/wav, audio/webm,  audio/ogg,  audio/mpeg";
          break;
        case "video":
          mime_type = "video/x-msvideo, video/mpeg, video/ogg,  video/webm,  video/3gpp";
          break;
        case "media":
          mime_type = "video/x-msvideo, video/mpeg, video/ogg,  video/webm,  video/3gpp, audio/mpeg, audio/aac, audio/wav, audio/webm,  audio/ogg,  audio/mpeg, image/bmp, image/jpeg, image/jpg, image/png, image/ttf, image/tif";
          break;
        case "document":
          mime_type =
              "text/html, text/plain, application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          break;
      }

      return mime_type;
    },

    // other functions
    closeDialog: function () {
      this.isEdit = false;
      this.dialogShow = false;
    },

    // Step 1
    selectFile: function () {
      this.$refs.file.click();
    },

    // Step 2
    handleFileupload: function () {
      console.log(this.$refs.file.files[0]);
      this.file = this.$refs.file.files[0];
      this.originalFileSize = (this.file.size / 1048576).toFixed(2);
      this.fileType = this.file.type;
      this.errorString = null;
      this.fileName = this.file.name.replace(/\.[^/.]+$/, "");
      this.$refs.fileName.focus();
      console.log(`fileType: ${this.fileType}`);
    },

    resetUpload: function () {
      this.file = null;
      this.progressVisible = false;
      this.fileName = null;
      this.description = null;
    },

    // Step 3
    submit: function () {
      if (this.isEdit) {
        this.submitEditMode();
      } else {
        this.progressVisible = true;
        this.submitCreateMode();
      }
    },

    // on upload btn clicked
    submitCreateMode: function () {
      // validation
      if (this.file == null) {
        this.errorString = "*Select a File and Give it a Name";
        return;
      }

      // preparation of Data
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("name", this.fileName);
      formData.append("type", this.fileType);
      formData.append("description", this.description);

      this.progressVisible = true;

      Repository.post(`/upload/blob`, formData, {
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress: this.progress
      })
          .then(payload => {

            let tempArray = [];
            if (this.value != null) {
              tempArray = this.value;
            }

            tempArray.unshift(payload.data.id)
            this.blobs = tempArray;
            this.$emit("input", tempArray);


            this.resetUpload();
            this.closeDialog();
          })
          .catch(payload => {
            console.log("failure!!");
            console.log(payload);
            this.resetUpload();
          });
    },
  }
}
</script>

<style scoped>
.blobBtn{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-color: #e5e5e5;
  padding: 3px;
  border-radius: 5px;
  opacity: 0.2;
}
.blobBtn:hover{
  background-color: #f3f3f3;
  opacity: 1.0;
}
</style>