<template lang="pug">
  div.px-4.py-2()
    //- div() {{schema.id}} {{dataValue}}
    component( :is="getComponent(schema.type)" :schema="schema" :dataValue="dataValue[schema.id]" @change="onChange" )



</template>
<script>
import TextFieldTableField
  from "@/modules/workflow/formBuilder/components/ui/TableItems/components/TextFieldTableField.vue";
import CheckboxTableField
  from "@/modules/workflow/formBuilder/components/ui/TableItems/components/CheckboxTableField.vue";
import IntegerTableField
  from "@/modules/workflow/formBuilder/components/ui/TableItems/components/IntegerTableField.vue";
import DecimalTableField
  from "@/modules/workflow/formBuilder/components/ui/TableItems/components/DecimalTableField.vue";
import SelectTableField from "@/modules/workflow/formBuilder/components/ui/TableItems/components/SelectTableField.vue";

export default {
  components: {
    TextFieldTableField,
    CheckboxTableField,
    IntegerTableField,
    DecimalTableField,
    SelectTableField


  },
  props: {
    schema: Object,
    dataValue: Object,
  },
  data: () => ({
  }),

  computed: {
    getStyle: function() {
      let theStyle = {}
      return theStyle;
    }
  },

  methods: {
    // call the editor


    getComponent: function(componentName) {
      return `${componentName}TableField`;

    },

    onChange: function(value){
      this.$emit("change",value);
    }


  },

  mounted() {
    // console.log(this.getComponent('ChecklistProperties'))
  }
};
</script>
<style scoped lang="stylus">

</style>
