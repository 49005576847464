<template lang="pug">
v-data-table(
  :headers="headers" 
  :search="search" 
  :items="$store.state.reminders.contact_renewal.contact_renewal_data"
  item-key="id" 
  :loading="isLoading" 
  :single-select="false" 
  show-select
  )

  template(v-slot:progress)
    v-progress-linear(slot="progress"  height="2" :indeterminate="isLoading")

  template(v-slot:item.user_contacts="{item}")
    div(v-for="user,index in item.user_contacts" :key="index")
      .font-italic() {{user.first_name}} {{user.last_name}}

  template(v-slot:item.contact_renewal_types="{item}")
    div(v-for="contact_renewal_type,index in item.contact_renewal_types" :key="index")
      .font-weight-bold() {{contact_renewal_type.name}}

  template(v-slot:item.job="{item}")
    div(v-if="item.time_reminder")
      .caption.grey--text() ID: {{item.job_id}} > {{item.job_threshold_id}}
      .primary--text(v-if="item.job") {{item.job.next_run_at | moment("dddd, Do MMM YYYY &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; hh:mm a")}}

  template(v-slot:item.contacts="{item}")
    .d-flex
      .grey.rounded-lg.px-3.my-2.mr-2.white--text.darken-1.caption(v-if="item.contacts.length > 0 && item.contacts"  v-for="contact,index in item.contacts" :key="index")
        div() {{contact.first_name}} {{contact.last_name}}
  
  template( v-slot:item.action="{ item }")
    v-row.mr-5(justify="end")
      more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')
  template(v-slot:item.comments="{item}")
    .caption() ID: {{item.comments}}


</template>
<script>
import ContactColumn from "@/modules/global/components/table/ContactColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import { searchMixin } from "@/mixin/searchListMixin";
import Repository from "@/repository";
export default {
  components: {
    ContactColumn,
    MoreDropDownMenu
  },
  mixins: [searchMixin],
  props: ["search"],
  data: () => ({
    isLoading: true,

    headers: [
      {
        text: "Contact",
        value: "user_contacts"
      },
      { text: "Contact Renewal Type", value: "contact_renewal_types" },
      { text: "Next Due (Time)", value: "job" },
      { text: "Subscribers", value: "contacts" },
      { text: "Comments", value: "comments", align: "left" },
      { text: "Action", value: "action", align: "right" }
    ],

    menuitems: [
      {
        title: "Send Notification",
        icon: "notifications",
        action: "noficiation"
      },
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" },
      { title: "View Full History", icon: "history", action: "history" }
    ]
  }),

  methods: {
    onClick: function(value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          this.$store.commit(
            "reminders/contact_renewal/setCurrentIndexByID",
            value.theObject.id
          );
          this.$router.push({
            name: "contactRenewalForm",
            params: { reminderID: value.theObject.id }
          }).catch(()=>{});

          break;
        case "delete":
          this.$store.dispatch(
            "reminders/contact_renewal/deleteContact_renewal",
            value.theObject.id
          );

          break;
        case "notification":
          console.log("notification");
          Repository.get(`/contactRenewal/alarm/${value.theObject.id}`).then(response => {
            console.log(response);
          });
          break;
        case "history":
          console.log("history");
          break;
      }
    }
  },

  mounted() {
    this.$store
      .dispatch("reminders/contact_renewal/loadAllContact_renewals")
      .then(() => {
        this.isLoading = false;
      });
  }
};
</script>
<style scoped lang="stylus">
.editButtons
  display: flex

.dottedUnderline
  border-bottom 1px dotted
  text-decoration none
</style>
