<template lang="pug">
  div
    v-text-field(v-model="selectedLabelName" label="Header Label*" ref="fieldName" :rules="[value => !!value || 'Required.']")
    div(@click.prevent="showAdvanced=!showAdvanced")
      showAdvancedProperties(:showAdvanced="showAdvanced" )
    div(v-show="showAdvanced")
      v-checkbox(v-model="selectFullWidth" label="full width" hint="Span vertical Line across entire document" :persistent-hint="true" )
      v-checkbox(v-model="selectIsSubHeader" label="Subheader" hint="is the title a subheader" :persistent-hint="true" )

      
</template>
<script>
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";
import ShowAdvancedProperties from "@/modules/inspections/components/formDesigner/component/ShowAdvancedProperties";
export default {
  components: {
    ShowAdvancedProperties
  },

  data: () => ({
    properties: {},
    showAdvanced: false
  }),

  computed: {
    selectedLabelName: {
      get: function() {
        return this.properties.label;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "label",
          value: value
        });

        // set also the id
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: "$_header_"+this._.snakeCase(value)
        });
      }
    },

    selectFullWidth: {
      get: function() {
        return this.properties.is_full_width;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "is_full_width",
          value: value
        });
      }
    },

    selectIsSubHeader: {
      get: function() {
        return this.properties.is_subheader;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "is_subheader",
          value: value
        });
      }
    }


  },

  mounted() {
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    this.$refs.fieldName.focus();
  }
};
</script>
<style scoped lang="stylus"></style>
