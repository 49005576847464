<template lang="pug">
div(v-if="isLoaded" )
  pie-chart(style="height:140px; width:200px" :chartdata="chartData" :options="chartOption"  :key="keyCounter")
  .d-flex.caption.justify-center()
    .mx-3.px-2.rounded.grey--text() vehicle

</template>

<script>

import PieChart from "@/modules/global/components/charts/PieChart";

export default {
  components:{
    PieChart
  },
  data:()=>({
    isLoaded: false,
    keyCounter: 0,
    isVolume: true,
    chartOption : {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 10,
          bottom: 0
        }
      }
    },
    chartData: {
      labels: [

      ],

      datasets: [
        {

        }
      ]
    },
  }),
  watch:{
    servicePies: function(){
      this.keyCounter += 1;
    }
  },
  computed:{
    servicePies() {
      return this.$store.state.service.metrics.service_pies;
    }
  },
  methods:{
    loadData: async function() {
      this.isLoaded = false;
      let keys = [];
      let values = [];
      let bgColor = [];

      this.servicePies.forEach(item => {
        keys.push(item.vehicle);
        values.push(item.price);
        bgColor.push("#"+item.id.substr(1,6))
      })


      this.chartData.datasets[0].backgroundColor = bgColor;
      this.chartData.labels = keys;
      this.chartData.datasets[0].data = values;

      this.isLoaded = true;
    },
    onClick: function(){
      this.isVolume = !this.isVolume;
      this.loadData();
      this.keyCounter += 1;
    },
  },
  mounted() {
    this.loadData();
    this.keyCounter += 1;
  }

}
</script>


<style scoped lang="stylus">

</style>