./<template lang="pug">
  .mapContainer()
    #mapPlace
    .mapButtons()
      v-btn(fab color="primary" @click="zoomToPin")
        v-icon() place
      v-btn.mt-3(v-if="editable" fab color="primary" @click="placePinCenterMap")
        v-icon()  center_focus_strong
</template>
<script>
import mapboxgl from "mapbox-gl";
export default {
  props:{
    value:Object,
    editable:{
      type:Boolean,
      default:false,
    }


  },
  data: ()=>({
    center: { lng: 36.74372678308009, lat: -1.2878468314658136 },
  }),

  watch:{
    value:function(location){
      console.log("New Value here")
      this.marker.setLngLat( [location.lon, location.lat]);
      this.mapPlace.flyTo({
        center:[location.lon,location.lat],
        essential: true
      })
    },

  },

  mounted() {
    if(this.value){
      this.center.lng = this.value.lon;
      this.center.lat = this.value.lat;

    }

    this.$nextTick(()=>{
      this.createMap();
    })

  },

  beforeDestroy() {
    this.$nextTick(() => {
      if (this.mapPlace != null) {
        this.mapPlace.remove();
      }
    });
  },

  methods:{
  createMap: function () {
    mapboxgl.accessToken =
        "pk.eyJ1IjoibXVnYW1iaWtpbWF0aGkiLCJhIjoiY2p0OWNjaGZxMDdsdjQ0cXZjZTRhZnA0aSJ9.3b3qa7uHHInxWL3TBiMsSQ";
    this.mapPlace = new mapboxgl.Map({
      container: "mapPlace",
      style: "mapbox://styles/mapbox/streets-v10",
      minzoom: 1.3,
      center: [this.center.lng, this.center.lat], // Nairobi
      zoom: 16
    });

    this.marker = new mapboxgl.Marker({
      color: this.$vuetify.theme.themes.light.primary,
      draggable: this.editable
    })
    this.marker.setLngLat( [this.center.lng, this.center.lat]);
    this.marker.addTo(this.mapPlace);
    this.marker.on("dragend",()=>{
      this.$emit("input",{
        lon: this.marker.getLngLat().lng,
        lat: this.marker.getLngLat().lat,
      })
    })

    this.mapPlace.on("load",()=>{
      this.mapPlace.resize();
    })
  },


    zoomToPin: function() {
      this.mapPlace.flyTo({
        center:[this.marker.getLngLat().lng,this.marker.getLngLat().lat],
        essential: true
      })
    },

    placePinCenterMap: function() {
      this.marker.setLngLat(this.mapPlace.getCenter());
      this.$emit("input",{
        lon: this.marker.getLngLat().lng,
        lat: this.marker.getLngLat().lat,
      })
    }



  }
};
</script>
<style scoped lang="stylus">
.mapContainer{
  width 100%;
  height 100%;
  position relative;


}

#mapPlace{
  position absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.mapButtons{
  position absolute
  right 20px;
  top 10px;
  display: flex;
  flex-direction column


}


</style>
