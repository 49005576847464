<template lang="pug">
  .fill-width(v-if="getCurrentWorkflow")
    v-container()
      div(v-if="workflowTemplate")
        v-container.pa-2
          v-layout()
            div(v-if="workflowTemplate")
              v-layout(justify-center)
                v-select(v-model="selectedTemplate" dense @change="onChange" outlined color="primary" label="Select Workflow Version" :items="workflowTemplate.data" item-text="version" item-value="version")
            v-spacer()
            v-btn.ma-2(small color="primary" @click="onRefreshTable")
              v-icon.mr-2(:size="18") sync
              | table refresh
      record-form-table(v-if="loadedRecords" :headers="getTableHeaders" :schema="getSelectedSchema")
</template>

<script>
import RecordFormTable from "@/modules/workflow/records/components/Tables/RecordFormTable";

export default {
  components:{
    RecordFormTable,
  },
  data:()=>({
    snackbar: false,
    snackbarColor: "primary",
    snackBarMessage: "",
    workflowTemplate:null,
    selectedTemplate:null,
    loadedRecords: false,
  }),

  computed: {
    getCurrentWorkflow: function() {
      return this.$store.getters["workflow/getCurrentWorkflow"];
    },
    getTableHeaders:function(){
      let tableHeaders=[];
      this.workflowTemplate.data[this.selectedTemplate -1].schema.forEach((item)=>{
        if(item.id.charAt(0) !== '$'){ // remove any component with the '$" id such as $_header
          tableHeaders.push({
            text:item.label,
            value:item.id,
            name:item.name
          })
        }

      })

      tableHeaders.push({
        text: "Metadata",
        name: "Metadata",
        align: "right"
      })

      tableHeaders.push({
        text: "Action",
        value: "action",
        align: "right"
      })

      return tableHeaders;
    },
    getSelectedSchema: function() {
      console.log("The Schema is ")
      console.log(this.workflowTemplate.data[this.selectedTemplate -1].schema)
      return this.workflowTemplate.data[this.selectedTemplate -1].schema;
    },
  },

  methods: {

    onChange:function(value){
      console.log(value)
      this.$store.dispatch("workflow/workflowRecord/filterRecords",{version:value,workflowTemplateId:this.workflowTemplate.id});
    },
    onRefreshTable: function () {
      this.$store.dispatch("workflow/workflowRecord/filterRecords", {
        version: this.selectedTemplate,
        workflowTemplateId: this.workflowTemplate.id
      });
    }

  },

  mounted() {
    console.log("=================")
    console.log(this.$store.state.workflow.workflowRecord.currentIndex)
    this.workflowTemplate = this.$store.getters["workflow/getCurrentWorkflow"];
    if (this.$store.state.workflow.workflowRecord.currentIndex == -1) {
      console.log(this.workflowTemplate.id)
      this.$store.dispatch("workflow/workflowRecord/filterRecords", {
        version: this.workflowTemplate.version,
        workflowTemplateId: this.workflowTemplate.id
      }).then(()=>{
        this.loadedRecords = true;
      });
    }else{
      this.$store.dispatch("workflow/workflowRecord/filterRecords", {
        version: this.workflowTemplate.version,
        workflowTemplateId: this.workflowTemplate.id
      }).then(()=>{
        this.loadedRecords = true;
      });
    }
    // //
    this.selectedTemplate = this.workflowTemplate.version;

  }
}
</script>

<style scoped lang="stylus">

</style>