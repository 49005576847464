<template lang="pug">
    svg(:width="width" :height="height" )
      //- Grid
      rect(ref="bgRect"  :width="width" :height="height"  :fill="getBgRowColor"  @mousedown="$emit('rowMouseDown',$event,layerIndex)" stroke-width="0.25" stroke="#8d8d8d")
      g(v-if="$store.state.vehicles.assignments.mode !== 'year'")
        line(v-for="(item,index) in getSubStepsTotal" :key="`l${index}`" stroke-width="0.2" stroke="#CCCCCC" :x1="index*(width/getSubStepsTotal)" :y1="0" :x2="index*(width/getSubStepsTotal)"  :y2="width" )
        line(v-for="(item,index) in steps" :key="`t${index}`" stroke-width="0.8" stroke="#999999" :x1="index*(width/steps)" :y1="0" :x2="index*(width/steps)"  :y2="width" )
      g(v-else)
        line(v-for="(item,index) in steps" :key="`t${index}`" stroke-width="0.5" stroke="#999999" :x1="index*(width/12)" :y1="0" :x2="index*(width/12)"  :y2="width" )

      //- Assignments
      scheduler-item(
        v-for="(assignment,index) in item.assignments"
        :key="`a${index}`" 
        :x="getX(assignment)" 
        :width="getWidth(assignment)" 
        :height="height-(getSpacerHeight*2)" 
        :y="getSpacerHeight" 
        :assignment="assignment"
        :layerIndex="layerIndex"
        @selectAssignment="onSelectAssignment"
        @frontMouseDown="$emit('frontMouseDown',$event,assignment,layerIndex)"
        @endMouseDown="$emit('endMouseDown',$event,assignment,layerIndex)"
      )
</template>
<script>
import SchedulerItem from "@/modules/vehicles/components/scheduler/SchedulerItem";

export default {
  components: {
    SchedulerItem
  },
  props: {
    width: Number,
    height: Number,
    steps: Number,
    substeps: Number,
    layerIndex: Number,
    item: Object,
    unit: Number
  },
  data: () => ({}),

  mounted() {
    // console.log(this.getSubStepsTotal);
  },

  computed: {
    getSubStepsTotal: function() {
      return this.substeps * this.steps;
    },

    totalSteps: function() {
      return this.steps * this.substeps;
    },

    getSpacerHeight: function() {
      // calculate the space between rows
      return this.height * 0.15;
    },

    getBgRowColor: function() {
      return this.layerIndex % 2 == 0 ? "#E8E8E8" : "#eeeeee";
    }
  },

  methods: {
    getX: function(assignment) {
      let startDate = this.$store.getters["vehicles/assignments/getStartDate"];
      let tempX =
        (assignment.during.tz_start - startDate.getTime()) /
        this.$store.state.vehicles.assignments.timeSplit;
      // console.log(tempX);
      return tempX * this.unit; // return assignment.unit_start * this.unit
    },

    getWidth: function(assignment) {
      let startDate = this.$store.getters["vehicles/assignments/getStartDate"];
      let tempWidth =
        (assignment.during.tz_end - startDate.getTime()) /
        this.$store.state.vehicles.assignments.timeSplit;

      let tempX =
        (assignment.during.tz_start - startDate.getTime()) /
        this.$store.state.vehicles.assignments.timeSplit;

      // console.log(tempWidth - tempX);
      return (tempWidth - tempX) * this.unit; // return (assignment.unit_end - assignment.unit_start) * this.unit;
    },

    onSelectAssignment: function(event, assignment) {
      this.$emit("selectAssignment", event, assignment, this.layerIndex);
    }
  }
};
</script>
<style scoped lang="stylus"></style>
