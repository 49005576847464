<template lang="pug">
  div(v-if="inspectionForm")
    v-toolbar(dense flat)
      generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer()
      .d-flex.align-center()
        .mr-2()
          v-btn.mx-4(icon small @click="previous")
            v-icon() skip_previous
          v-btn.mx-4(icon small @click="next")
            v-icon() skip_next
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
              | Export
              v-icon(dark) keyboard_arrow_down
          v-list-item-group.white()
            v-list-item(v-for='item in items', :key='item.title' dense @click="onAction(item.value)" )
              v-list-item-title
                v-layout(align-center)
                  v-icon.mr-2(:size="20") {{item.icon}}
                  .subtitle-2.mx-2() {{ item.title }}
        v-btn.mx-2(color="primary" small @click="editRecord") Edit Record
    v-divider()
    v-container.mt-8.pa-8.white(fluid)
      v-row()
        v-col(md="6" sm="12")
          v-card(color="white")
            inspection-details-panel(:record="getCurrentRecord" :inspectionForm="inspectionForm")

          v-card.mt-12(color="white")
            location-map-panel(v-if="getCurrentRecord.geotag" :coordinates="getCurrentRecord.geotag" :vehicleCoordinates="getCurrentRecord.vehicle_loc")

        v-col(md="6" sm="12")
          v-card(color="white")
            inspection-checklist-items-panel(:schema="getRecordSchema()" :record="getCurrentRecord" :inspectionForm="inspectionForm")
    export-data-dialog(:fileExport="fileExport" :isFinished="isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="onCloseExportDialog" @download="onDownload" )
</template>

<script>
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import InspectionDetailsPanel from "@/modules/inspections/components/overview/InspectionDetailsPanel";
import InspectionChecklistItemsPanel from "@/modules/inspections/components/overview/InspectionChecklistItemsPanel";
import LocationMapPanel from "@/modules/global/components/panel/LocationMapPanel";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import Repository from "@/repository";

export default {
  components: {
    GenericBreadCrumbs,
    InspectionDetailsPanel,
    InspectionChecklistItemsPanel,
    LocationMapPanel,
    ExportDataDialog
  },

  computed: {
    getCurrentRecord: function () {
      let currentRecord = this.$store.getters["inspection/getCurrentInspection"];
      currentRecord = this._.clone(currentRecord)
      return currentRecord;
    },

  },


  data: () => ({
    isDownloadFinished: false,
    showExportDialog: false,
    fileExport: "",
    inspectionForm: undefined,
    breadCrumbsItems: [
      {
        text: "Inspections",
        disabled: false,
        path: "inspections"
      },
      {
        text: "Inspection Detail",
        disabled: true
      },
    ],
    items: [
      {
        title: "Export Pdf",
        icon: "cloud_download",
        value: "export_pdf"
      },
      {
        title: "Copy Link to Clipboard",
        icon: "content_copy",
        value: "copy_link"
      },
      {
        title: "Open Link",
        icon: "open_in_new",
        value: "open_link"
      }

    ],
  }),


  mounted() {
    if (this.$store.state.inspection.currentIndex == -1) {
      this.$store.dispatch("inspection/fetchInspection", this.$route.params.inspectionID).then(() => {
        this.getInspectionForm();
      })
    } else {
      this.getInspectionForm();
    }
  },

  methods: {

    editRecord: function(){
      this.$router.push({
        name: "inspectionNewLayout",
        params: {inspectionID:this.$route.params.inspectionID}
      }).catch(()=>{});

    },

    getInspectionForm: function () {
      this.inspectionForm = this.$store.getters["inspection/inspectionForm/getInspectionFormByID"](this.getCurrentRecord.inspection_form_id)
      if (this.inspectionForm == undefined) {
        this.$store.dispatch("inspection/inspectionForm/fetchInspectionForm", this.getCurrentRecord.inspection_form_id).then(() => {
          this.inspectionForm = this.$store.getters["inspection/inspectionForm/getInspectionFormByID"](this.getCurrentRecord.inspection_form_id)
        })
      }
    },
    getRecordSchema: function () {
      return this.inspectionForm.data[this.$store.getters["inspection/getCurrentInspection"].inspection_form_version - 1].schema;
    },

    onAction: function(value) {
      switch (value) {
        case "export_pdf":
          this.fileExport = "PDF";
          console.log("action PDF")
          this.downloadFile(`/report/inspectionRecord/PDF/${this.getCurrentRecord.id}`);
          this.showExportDialog = !this.showExportDialog;
          break;
        case "copy_link":
          navigator.clipboard.writeText(`https://app.jahazi.com/api/renderhtml?type=inspectionRecord&id=${this.getCurrentRecord.id}`);
          this.$store.commit("showSnackBar", {
            color: "accent",
            message: `Copied to Clipboard`,
          });
          break;
        case "open_link":
          window.open(`/api/renderhtml?type=inspectionRecord&id=${this.getCurrentRecord.id}`);
          break;
      }

    },

    downloadFile: function(url) {
      this.isDownloadFinished = false;

      Repository({
        headers: {
          "Mode": "Gotenberg",
        },
        method: "GET",
        url: url,
      }).then((response) => {
        console.log(response);
        let blob = response.data;

        let fileName = blob.file_name;

        let fileURL = `/media/${blob.account_id}/${blob.file_name}`
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      }).catch((error) => {
        console.log(error)
      });
    },

    onDownload: function() {
      // download the file
      this.fileLink.click();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function() {
      this.showExportDialog = false;
    },
    onOpenDialog: function() {
      // this.thedate = JSON.parse(JSON.stringify(this.originalDate));
      this.showDialog = true;
    },
    onCancelDialog: function() {
      this.showDialog = false;
      // this.thedate = JSON.parse(JSON.stringify(this.originalDate));
    },
    next(){
      this.$store.commit("inspection/next");
    },
    previous() {
      this.$store.commit("inspection/previous");
    },

  }
}
</script>

<style scoped>

</style>