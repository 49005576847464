import { render, staticRenderFns } from "./SearchAutocomplete.vue?vue&type=template&id=13d9f270&scoped=true&lang=pug"
import script from "./SearchAutocomplete.vue?vue&type=script&lang=js"
export * from "./SearchAutocomplete.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13d9f270",
  null
  
)

export default component.exports