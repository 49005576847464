<template lang="pug">
  div(v-if="$store.state.fuel.currentIndex > -1")
    v-layout.pt-2.pr-3(align-center)
      v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
      v-spacer
      v-btn.ma-2(small color="primary" outlined dark @click="cancel") cancel
      v-btn.ma-2(small color="primary" @click="save") save

    v-form(ref="form" v-model="valid" lazy-validation)
      v-container.py-2
        v-alert(v-if="errors.length" :value="true" color="error" icon="warning")
          div(v-for="(e,index) in errors" :key="index" v-text="e")




      v-container
        v-card
          v-card-text
            v-container(grid-list-xl)
              form-divider FUEL ENTRY DETAILS
              v-row()
                v-col(cols="12" xs="12" md="6" xl="4")
                  vehicle-auto-complete(v-model='vehicle_id')
                v-col(cols="12" xs="12" md="6" xl="4")
                  contact-auto-complete(v-model='reported_by_id')
                v-col(cols="12" xs="12" md="6" xl="4")
                  auto-date-time-picker(v-model="date_time", labelDate="Date", labelTime="Time")
                v-col(cols="12" xs="12" md="6" xl="4")
                  v-row()
                    v-col(cols="4" xs="4" md="5" xl="5")
                      v-text-field(v-model='volume', hint='Volume in Litres', :persistent-hint='true', label='Litres')
                    v-col(cols="4" xs="4" md="5" xl="5")
                      v-text-field(v-model='price', hint='Total Price Paid', :persistent-hint='true', label='Price Paid')
                    v-col(v-if="volume && price" cols="4" xs="4" md="2" xl="2")
                      .primary--text {{(price/volume).toFixed(2)}}
                      .caption.grey--text Cost Per Litre
                v-col(cols="12" xs="12" md="6" xl="4")
                  odometer-input(v-model.number='odometer' :vehicle_id="vehicle_id" :time="date_time")
              form-divider ADDITIONAL DETAILS
              v-row()
                v-col(cols="12" xs="12" md="6" xl="4")
                  merchant-auto-complete(v-model='merchant_id')
                v-col(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='reference', hint='Transaction Detail: e.g. Receipt No., Transaction ID etc.', :persistent-hint='true', label='Reference')
                v-col(cols="12" xs="12" md="6" xl="4")
                  LocationSelect(v-model='location' :width="400" :height="200" label="Location" description="Select your location")

              form-divider SETTINGS
              v-layout(row, wrap, justify-start)
                v-flex(xs12, md6, xl4)
                  v-switch(label='Partial Fuel-up', v-model='partial_fuel', hint='Turn on if patial Fuel Up at Service Point', :persistent-hint='true')
                v-flex(xs12, md6, xl4)
                  v-switch(label='Reset Usage', v-model='reset_usage', hint='Turn on if you missed entering the previous fuel-up in the System', :persistent-hint='true')
              form-divider Attachments
              v-row.mt-2()
                v-col(cols="12"  xs="12")
                  attach-fill-form( :schema="imagesSchama" :data="{value:photos_id}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" :moreMenu="additionalImageMenuItems")

                v-col(cols="12"  xs="12")
                  attach-fill-form( :schema="documentsSchama" :data="{value:documents_id}" @onChange="saveDocuments"  @onMenuAction="onMenuActionDocuments")
              form-divider(v-if="fuelCustomFields.length > 0") Custom Fields {{fuelCustomFields.length}}
              v-row.mt-2(v-if="fuelCustomFields.length > 0")
                v-col(v-for="(item,index) in fuelCustomFields" :key="index" cols="12" xs="12" md="6" xl="4")
                  div() {{item.label_id}}
                  custom-field-text-field(v-bind:value="getCustomField(item.label_id)" mutationType="fuel/updateFuel"  :element="item")

      v-container.py-2
        v-layout(justify-end)
          v-btn.ma-2(small text color="grey" @click="cancel") cancel
          v-btn.ma-2(small color="primary" @click="save") Save
</template>

<script>
import VehicleAutoComplete from "@/modules/global/components/forms/VehicleAutoComplete";
import ContactAutoComplete from "@/modules/global/components/forms/ContactAutoComplete";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import FormHeaderTotal from "@/modules/global/components/forms/FormHeaderTotal";
import FuelTypeAutoComplete from "@/modules/global/components/forms/FuelTypeAutoComplete";
import MerchantAutoComplete from "@/modules/global/components/forms/MerchantAutoComplete";
import LocationSelect from "@/modules/global/components/forms/LocationSelect";
import AutoDateTimePicker from "@/modules/global/components/forms/AutoDateTimePicker";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
import CustomFieldTextField from "@/modules/global/components/forms/CustomFieldTextField";
import OdometerInput from "@/modules/global/components/forms/OdometerInput";
import {createHelpers} from "vuex-map-fields";

const {mapFields} = createHelpers({
  getterType: "fuel/getFuel",
  mutationType: "fuel/updateFuel",
});

export default {
  components: {
    FormDivider,
    FormHeaderTotal,
    VehicleAutoComplete,
    ContactAutoComplete,
    FuelTypeAutoComplete,
    MerchantAutoComplete,
    LocationSelect,
    AutoDateTimePicker,
    AttachFillForm,
    CustomFieldTextField,
    OdometerInput
  },
  data: () => ({
    error: false,
    errors: [],
    valid: true,

    imagesSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Fuel Images",
      hint: null,
      type: "image",
      image_size: "small",
      multiple: true,
    },
    additionalImageMenuItems: [

    ],

    documentsSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Fuel Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),

  computed: {
    ...mapFields([
      "date_time",
      "vehicle_id",
      "odometer",
      "reported_by",
      "reported_by_id",
      "volume",
      "price",
      "usage",
      "reference",
      "partial_fuel",
      "reset_usage",
      "photos_id",
      "documents_id",
      "comment",
      "merchant",
      "merchant_id",
      "account",
      "account_id",
      "location",
        "details",
    ]),

    fuelCustomFields:function(){
      return this.$store.getters["profile/getCustomFields"]("fuel")
    }
  },

  methods: {
    getCustomField: function (labelID) {


      let temp = {}


      // initiazlize details
      if (this.$store.getters["fuel/getCurrentFuel"].details == null) {
        this.$store.commit("fuel/updateFuel", {path: `details`, value: temp})
      }

      // check if the key exists
      if (labelID in this.$store.getters["fuel/getCurrentFuel"].details) {
        return this.$store.getters["fuel/getCurrentFuel"].details[labelID]
      } else {
        return null;
      }
    },

    cancel: function () {
      this.$store.dispatch("fuel/loadAllFuels").then(() => {
        this.$router.back();
      });

      // refresh the List
      this.$store.dispatch("fuel/loadAllFuels");
    },

    save: function () {
      this.errors = [];
      console.log(this.vehicle_id);
      if (this.$refs.form.validate()) {
        if (
            this.vehicle_id === "00000000-0000-0000-0000-000000000000" ||
            !this.vehicle_id
        ) {
          console.log("hello yess");
          this.errors.push("Vehicle Required");
        }

        if (
            this.reported_by_id === "00000000-0000-0000-0000-000000000000" ||
            !this.reported_by_id
        ) {
          this.errors.push(
              "Contact of the Person Reporting the Fuel Measurements is Required"
          );
        }



        // if all is well then post
        if (!this.errors.length) {
          this.$store.dispatch("fuel/saveFuel").then(() => {
            this.$store.dispatch("fuel/loadAllFuels").then(() => {
              this.$store.commit("showSnackBar", {
                color: "primary",
                message: `Fuel Record Saved`,
              });
              this.$router.back();
            });
          });
        }
      }
    },

    setCompletionDate: function (value) {
      this.$refs.menu.save(value);
      this.completionDate = new Date(
          `${parseInt(value.split("-")[0])}-${parseInt(
              value.split("-")[1]
          )}-${parseInt(value.split("-")[2])}`
      );
      this.completionDateTime = this.completionDate.toISOString();
    },

    setCompletionTime: function (value) {
      this.$refs.menu2.save(value);
      this.completionDate.setHours(
          parseInt(value.split(":")[0]),
          parseInt(value.split(":")[1])
      );
      this.completionDateTime = this.completionDate.toISOString();
    },

    saveImages: function (theObject) {
      this.$store.commit("fuel/updateFuel", {
        path: "photos_id",
        value: theObject.value,
      });
    },

    saveDocuments: function (theObject) {
      this.$store.commit("fuel/updateFuel", {
        path: "documents_id",
        value: theObject.value,
      });
    },
    onMenuActionImages: function (value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("fuel/deleteImage", value.theObject.id);
          break;
      }
    },

    onMenuActionDocuments: function (value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("fuel/deleteDocument", value.theObject.id);
          break;
      }
    },
    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Fuel List",
        disabled: false,
        to: {name: "fuels"},
      });
      theArray.push({
        text: "Fuel Entry",
        disabled: true,
      });
      return theArray;
    },
  },

  mounted() {
    if (this.$store.state.fuel.currentIndex == -1) {
      this.$store
          .dispatch("fuel/fetchFuel", this.$route.params.fuelID)
          .then(() => {
            //init here
            this.completionDate = new Date(this.completionDateTime);
          });
    } else {
      this.completionDate = new Date(this.completionDateTime);
    }
  },
};
</script>

<style scoped></style>
