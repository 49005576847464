<template lang="pug">
  v-autocomplete(
    v-model="placeId"
    :items="getDataItems"
    item-text="name"
    item-value="id"
    :label="label"
    placeholder="Start typing to Search Place"
    chips
    clearable
    :disabled="disabled"
  )
    template(v-slot:selection="data")
      v-chip.chip--select-multi(:input-value="data.selected" small )
        | {{data.item.name}}
</template>

<script>
export default {
  props:{
    value:String,
    label:String,
    is_hub:Boolean,
    disabled:{
      type: Boolean,
      default: false
    }
  },
  data:()=>({

  }),
  mounted() {
    this.loadPlaces();
  },

  computed:{
    routeLocked:function(){
      return this.$store.getters["routing/getCurrentRouting"].locked;
    },

    getDataItems:function(){
      if(this.is_hub){
        return this.$store.getters["place/getPlacesHubs"]
      }else{
        return this.$store.state.place.place_data;
      }
    },

    placeId:{
      get: function(){
        return this.value;
      },
      set: function(value){
        if(typeof(value) == "undefined"){
          this.$emit("input", null);
          this.$emit("change");
        }else{
          this.$emit("input", value);
          this.$emit("change");
        }

      }
    }
  },

  methods: {
    loadPlaces() {
      if(this.$store.state.place.currentIndex == -1){
        this.$store.dispatch("place/loadAllPlaces").then(()=>{

        })
      }
    }
  },

}
</script>

<style scoped>

</style>