<template lang="pug">
div(v-if="merchant")
  .ml-0(v-if="merchant != null" @click="openMerchantDetail(merchant.id)")
      .underline() {{ merchant.name }}


</template>
<script>
import axios from "axios";

export default {
  components: {
  },
  props: {
    merchant_id: String,
    clickToDetail:{
      type:Boolean,
      default: false,
    }
  },
  data: () => ({
    merchant: null
  }),
  watch:{
    merchant_id:function(oldVal,newVal){
      if(oldVal !== newVal){
        this.getMerchant();
      }
    }
  },
  methods: {
    getMerchant: function(){
      let tempMerchant = this.$store.getters["merchant/getMerchantByID"](this.merchant_id);
      if (tempMerchant == undefined) {
        axios.get(`/api/merchant/${this.merchant_id}`).then((response)=>{
          this.merchant = response.data;
        });
      } else {
        this.merchant = tempMerchant;
      }
    },
    openMerchantDetail: function (merchantID) {
      if(this.clickToDetail){
        this.$store.commit("merchant/setCurrentIndexByID", merchantID);
        this.$router.push({
          name: "merchantDashboard",
          params: {contactID: merchantID}
        }).catch(()=>{});
      }

    }
  },
  mounted() {
    this.getMerchant();
  }
};
</script>
<style lang="stylus" scoped>
.nameColumn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn, .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn, .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;
  padding: 0px;
}

.underline {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}
</style>
