<template lang="pug">
div()
  v-progress-linear(v-if="loading" indeterminate)
  v-data-table(
    v-model="selectedRepositories"
    :headers="headers"
    :items="getData"
    item-key="id"
    :items-per-page="footerProps.pagnation.itemsPerPage",
    :footer-props="footerProps"
    show-select
  )
    template(v-slot:item.name="{item}")
      .d-flex.align-center.my-1()
        div(v-if="item.blob && item.blob.thumbnail != null")
          .repoThumbnail.grey.lighten-3.my-2.mr-2(:style="{ 'background-image': 'url(' + '/media/' + $store.state.profile.userInfo.account_id +'/'+ item.blob.thumbnail + ')'}")
        .descriptionRepo()
          .body-1() {{item.name}}
          .caption.grey--text(v-if="item.description && item.description != 'null'" ) {{item.description}}

    template(v-slot:item.published_at="{item}")
      .d-flex.flex-column()
        div() {{getPublishedAt(item.published_at)}}
        .d-flex.align-end.grey--text.mt-1(v-if="item.uploaded_by")
          v-icon.mr-1(:size="18" color="grey lighten-1") person
          .caption() {{item.uploaded_by.first_name}} {{item.uploaded_by.last_name}}

    template(v-slot:item.blob="{item}")
      div()
        div.font-weight-bold(v-if="item.blob") {{item.blob.original_name}}
        div(v-if="item.link && item.link != 'null'")
          a(:href="item.link") {{item.link}}
        //- div.grey--text(v-if="item.blob") {{item.blob.mime_type}}

    template(v-slot:item.link="{item}")
        div(v-if="item.link && item.link != 'null'")
          a(:href="item.link") {{item.link}}

    template( v-slot:item.action="{ item }")
      v-row.mr-7(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onAction', :theObject='item')

</template>
<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import moment from "moment";
export default {
  components:{
    MoreDropDownMenu,
  },
data: () => ({
  selectedRepository: [],
  loading: false,
  headers: [
    {text: "Name", value: "name"},
    {text: "Publish Date", value: "published_at"},
    {text: "Original File / File Type / Link", value: "blob"},
    {text: "Role Permissions", value: "roles"},
    {text: "Action", value: "action", align: "right"}
  ],
  footerProps: {
    "items-per-page-options": [5, 10, 20, 50, 100, 1000],
    pagnation: {itemsPerPage: 20}
  },
  menuitems: [
    {title: "Edit", icon: "edit", action: "edit"},
    {title: "Delete", icon: "delete", action: "delete"}
  ]

}),

  computed: {
    selectedRepositories: {
      get() {
        return this.$store.state.repository.selectedRepositories;
      },
      set(value) {
        this.$store.commit("repository/setSelectedRepositories", value);
      }
    },

    getData: function() {
      if (this.$store.state.repository.repository_data.length > 0){
        return this.$store.state.repository.repository_data;
      }else{
        return [];
      }
    }
  },


  methods: {
    getPublishedAt: function(value){
      return moment(value).format("Do MMMM YYYY")
    },
    onAction: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("repository/setCurrentIndexByID",value.theObject.id);
          this.$emit("editDialog")
          break;
        case "delete":
          confirm("Are you sure you want to delete this item?") &&
          this.$store.dispatch("repository/deleteRepository", value.theObject.id);
          break;
      }
    },
  },

  mounted() {
    if(this.$store.state.repository.repository_data.length == 0){
      this.loading = true;
      this.$store.dispatch("repository/loadAllRepositories").then(()=>{
        this.loading = false;
      })
    }
  }
}
</script>



<style scoped lang="stylus">
.repoThumbnail {
  width: 80px;
  height: 60px;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2px 2px;
}
  .descriptionRepo {
    max-width: 350px;
    text-overflow: ellipsis;
    max-height: 90px;
    overflow: hidden;

  }
</style>