<template lang="pug">
  div
    router-view.fill-body
</template>
<script>
export default {
  data: () => ({}),
  mounted() {
    console.log("Latest Schema")

  }
};
</script>
<style scoped lang="stylus">


</style>
