<template lang="pug">
  div
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        div()
          h2.mx-6 Vehicle Report
          generic-bread-crumbs.py-1(:items="breadCrumbsItems")
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Export
                v-icon(dark) keyboard_arrow_down
            v-list-item-group()
              v-list-item(v-for='item in items', :key='item.title' dense @click="onExport(item.value)")
                v-list-item-title
                  v-layout(align-center)
                    v-icon.mr-2(:size="20") {{item.icon}}
                    .subtitle-2.mx-2() {{ item.title }}
        v-btn.ma-2.mr-5(small, color='primary', dark, @click='print')
          v-icon.mr-2() print
          | Print
    v-divider
    div.mx-4.my-2()
      vehicle-filter-dialog()
      v-menu(:close-on-content-click="false")
        template(v-slot:activator="{on}")
          v-btn.mx-2(outlined color="primary" v-on="on")
            | Columns
            v-icon(dark) keyboard_arrow_down
        .reportHeaderList()
          v-list-item-group()
            v-list(dense)
              v-list-item(v-for="(item, index) in headers" :key="index" )
                template(v-slot:default="{active}")
                  v-list-item-action()
                    v-checkbox(v-model="item.visible" color="primary")
                  v-list-item-content(@click="item.visible=!item.visible")
                    v-list-item-title.body-2() {{item.text}}

    v-divider
    .grey.lighten-4.py-3
    v-divider
    div(v-if="$store.state.vehicles.all.length>0")
      v-data-table.elevation-1(:headers='getHeaders', :items='$store.state.vehicles.all',item-key="id")
        template(v-slot:item.status="{item}")
          v-icon(small='', :style="{color: [item.status] == 'active' ? 'green' : 'gray'}") fiber_manual_record
          | {{ item.status }}


        template(v-slot:item.group="{item}")
          template(v-if="item.group")
            span(:style="groupStyle(item.group.color)") {{item.group.name}}
          template(v-else)
            .grey--text() no group

        template(v-slot:item.odometer="{item}")
          template(v-if="item.odometer")
            div {{item.odometer.meter}}
            a.caption.grey--text.text--lighten-1()  &#35;{{item.odometer.odometer_reference}} &gt; {{item.odometer.date_time | moment("HH:mm ddd, Do MMM YYYY")}}
          template(v-else)
            .grey--text.text--lighten-2() unknown

        template(v-slot:item.operator="{item}")
          template(v-if="item.operator")
            .d-flex.align-center()
              div(:style="operatorStyle(item.operator)") {{item.operator.first_name[0]}}{{item.operator.last_name[0]}}
              div {{item.operator.first_name}} {{item.operator.last_name}}

    export-data-dialog(:fileExport="fileExport" :isFinished="$store.state.reports.isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="onCloseExportDialog" @download="onDownload" )
</template>

<script>
import VehicleFilterDialog from "@/modules/global/components/dialog/VehicleFilterDialog";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import {downloadFile, startDownload} from "@/modules/reports/store/reportUtility"
import Repository from "@/repository";

export default {
  components: {
    GenericBreadCrumbs,
    VehicleFilterDialog,
    ExportDataDialog
  },
  data: () => ({

    fileLink: null,
    showExportDialog: false,
    fileExport: "",
    breadCrumbsItems: [
      {
        text: "Report",
        disabled: false,
        path: "reports"
      },
      {
        text: "Vehicle Report",
        disabled: true,
        path: "vehicleListReport"
      }
    ],
    dialog: true,
    items: [
      {
        title: "Export CSV",
        icon: "cloud_download",
        value: "export_csv"
      },
      {
        title: "Export Excel",
        icon: "cloud_download",
        value: "export_excel"
      }
    ],
    search: "",

    headers: [
      { text: "Name", align: "left", value: "name", visible: true },
      { text: "Make", value: "model", align: "left", visible: true },
      { text: "Status", value: "status", align: "left", visible: true },
      { text: "Type", value: "type", align: "left", visible: true },
      { text: "Group", value: "group", align: "left", visible: true },
      { text: "VIN", value: "vin", align: "left", visible: false },
      {
        text: "Registration",
        value: "number_plate ",
        align: "left",
        visible: true
      },
      { text: "Operator", value: "operator", align: "left", visible: true },
      { text: "Last Millage", value: "odometer", align: "left", visible: true },
      {
        text: "Last Millage Date",
        value: "odometer_date",
        align: "left",
        visible: false
      },
      { text: "Fuel Tank Capacity", value: "fuel_tank_capacity", visible: true },
      { text: "Service Costs", value: "service_cost", visible: false },
      { text: "Total Expenses", value: "total_expense", visible: false },
      { text: "Fuel Costs", value: "fuel_cost", visible: false },
      { text: "Cost/Km", value: "cost_per_km", align: "left", visible: false },
      {
        text: "Purchase Vendor",
        value: "purchase_vendor",
        align: "left",
        visible: false
      },
      {
        text: "Purchase Price",
        value: "purchase_price",
        align: "left",
        visible: false
      },
      {
        text: "Purchase Comments",
        value: "purchase_comments",
        align: "left",
        visible: false
      },
      {
        text: "Purchase Odometer",
        value: "purchase_odometer",
        align: "left",
        visible: false
      },
      {
        text: "Warrant Expiration",
        value: "warrant_expiration",
        align: "left",
        visible: false
      },
      {
        text: "Warrant Maximum Millage",
        value: "warrant_max_milage",
        align: "left",
        visible: false
      }

      // To insert Loan Details Here
    ]
  }),

  computed: {
    getHeaders: function() {
      let h = [];
      this.headers.forEach(item => {
        if (item.visible == true) {
          h.push(item);
        }
      });
      return h;
    }
  },
  methods: {
    onExport: function(value) {
      this.$store.commit("reports/setDownloadState", false);
      switch (value) {
        case "export_csv":
          this.fileExport = "CSV";
          downloadFile("/report/CSV/vehicle_list",this.headers,this.$store);
          break;
        case "export_excel":
          this.fileExport = "Excel";
          downloadFile("/report/Excel/vehicle_list",this.headers,this.$store);
          break;
        case "export_issue":
          this.fileExport = "Excel";
           downloadFile("/report/Excel/issue_list",this.headers,this.$store);
          break
      }
      this.showExportDialog = !this.showExportDialog;
    },

    downloadFile: function(url) {
      this.isDownloadFinished = false;
      console.log(JSON.stringify(this.headers.filter(item => item.visible == true)))
      Repository({
        method: "POST",
        url: url,
        data: this.headers.filter(item => item.visible == true),
        responseType: "blob"
      }).then(response => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function() {
      // download the file
      startDownload();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function() {
      this.showExportDialog = false;
    },
    print: function() {
      console.log("print");
    },
    groupStyle: function(value) {
      let obj = {};
      obj["border-bottom"] = "3px solid";
      obj["border-bottom-color"] = value;
      return obj;
    },

    operatorStyle: function(operator) {
      let obj = {};
      obj["border-radius"] = "50%";
      obj["color"] = "#FFFFFF";
      obj["padding"] = "3px";
      obj["margin-right"] = "5px";

      if (operator.group != null) {
        obj["background-color"] = operator.group.color;
      } else {
        obj["background-color"] = "#333333";
      }
      console.log(operator.group);
      return obj;
    }
  },

  mounted() {
    if (this.$store.state.vehicles.all.length == 0) {
      this.$store.dispatch("vehicles/loadAllItems");
    }
  }
};
</script>

<style scoped>
.reportHeaderList {
  overflow: auto;
  max-height: 400px;
  min-width: 300px;
}
</style>
