<template lang="pug">
  div
    v-menu(
      v-model='menu' 
      :close-on-content-click='false'
      :nudge-right='40'
      transition='scale-transition' 
      offset-y
      
      min-width='290px'
      )
      template(v-slot:activator='{ on }')
        v-text-field(v-model='computedDate', prepend-icon="event",  :label="schema.label" :hint="schema.hint"  :persistent-hint="schema.hint != null" readonly v-on='on')
      v-date-picker(v-model='date', @input='onDateChange' )

</template>
<script>
import moment from "moment";
import SETTINGS from "@/modules/inspections/store/formSettings";
export default {
  props: {
    schema: Object
  },
  data: () => ({
    menu: false,
    date: new Date().toISOString().substr(0, 10)
  }),
  computed: {
    computedDate: {
      get: function() {
        let val;
        val = this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id);
        if (val == null) {
          this.onSetDate(new Date().toISOString().substr(0, 10));
        } else {
          this.onSetDate(new Date(val).toISOString().substr(0, 10));
        }
        return moment(this.date).format(this.schema.date_format);
      },

      set: function(theValue) {

        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }
  },

  methods: {
    onSetDate: function(value) {
      this.date = value;
    },

    onDateChange: function(value) {
      this.computedDate = new Date(value).toISOString();
      this.menu = false;
    }
  },

  mounted() {
    let theValue = this.computedValue;
    if (theValue == null) {
      if (this.schema.default != null) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: this.schema.default
        });
      }
    }
  }
};
</script>
<style scoped lang="stylus"></style>
