<template lang="pug">
div
  .white.pt-2
    v-tabs.tabs(left='', height='32', slider-color='primary', v-model='active')
      v-tab.tabs  Service History 
      v-tab-item
        services-history-list
      v-tab.tabs  Service Type 
      v-tab-item
        service-type
      //- v-tab.tabs  Work Orders
      //- v-tab-item
        work-orders

</template>

<script>
import ServicesHistoryList from "@/modules/services/layouts/ServicesHistoryList";
import ServiceType from "@/modules/services/layouts/ServiceType";
import WorkOrders from "@/modules/services/layouts/WorkOrders";
export default {
  components: {
    ServicesHistoryList,
    ServiceType,
    WorkOrders
  },

  computed: {
    active: {
      get: function() {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          tabIndex = tabIndex > 2 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function(value) {
        this.$route.params.tabID = value;
      }
    }
  }
};
</script>

<style scoped></style>
