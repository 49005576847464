<template lang="pug">
  div
    .d-flex.align-center()
      v-combobox(
          v-model="computedValue"
          :label="schema.label"
          :hint="schema.hint"
          :persistent-hint="schema.hint != null"
          :items="items"
          v-bind="bindValues"
          clearable
        )
        template(v-if="schema.multiple" v-slot:selection="{ item, parent, selected }")
          v-chip(small close @click:close="removeItem(item)")
            | {{item}}
      add-comment-button.mx-1(v-model="comments" @showCommentInput="onShowCommentInput")
      upload-blob.mx-1(v-model="blobs" type="image" label="Label" hint="hint" :isMultiple="true")
    .d-flex()
      v-tooltip(bottom v-for="(item,index) in comments" :key="item.time")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") message
        span() {{item.value}}
      .mx-2()
      v-tooltip(bottom v-for="(item,index) in blobs" :key="item")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") image
        div()
          image-thumbnail(:width='360', :height='200', :src="item")

    div.mt-2(v-if="showCommentInput")
      v-textarea(outlined  ref="comment"  label="Comment" auto-grow rows="2" v-model="comment" hide-details )
      .d-flex.my-2()
        v-spacer
        v-btn.mx-2(small color='grey darken-1', text, @click.native="cancel()") Close
        v-btn.mx-2(small color='primary', @click.native="save()") Save
</template>
<script>
import {inspectionFillMixin} from "@/modules/inspections/components/formDesigner/fill/inspectionFillMixin";
export default {
  mixins: [inspectionFillMixin],
  data: () => ({}),
  computed: {
    computedValue: {
      get: function() {
        return this.dataValue.value;
      },
      set: function(theValue) {
        this.theValue = theValue;
        this.update();
      }
    },

    items: function() {
      return this.schema.options;
    },

    bindValues: function() {
      let p = {};

      if (this.schema.item_text != null) {
        p["item-text"] = this.schema.item_text;
      }

      if (this.schema.item_value != null) {
        p["item-value"] = this.schema.item_value;
      }

      if (this.schema.multiple == true) {
        p['multiple'] = '';
        p['small-chips'] = '';
      }

      return p;
    }
  },

  methods: {
    removeItem: function (item) {

      let array = this._.clone(this.computedValue);
      if (this.schema.type == "Simple") {
        // for Simple
        const index = array.indexOf(item);
        if (index > -1) {
          array.splice(index, 1);
          this.computedValue = array;
        }
      } else {

        // for APIS
        const index = array.findIndex(e => e.id == item.id);
        if (index > -1) {
          array.splice(index, 1);
          this.computedValue = array;
        }
      }

    }
  },
};
</script>
<style scoped lang="stylus"></style>
