<template lang="pug">
  #blankcontent()
    .panelTitle() Service Reminders
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-space-around.align-center.fill-height()
          .d-flex.flex-column.align-center()
            .tickerTitle.secondary--text() {{duesoon}}
            .tickerCaption.grey--text.text--darken-1() Due Soon
          .d-flex.flex-column.align-center()
            .tickerTitle.primary--text() {{overdue}}
            .tickerCaption.grey--text.text--darken-1() Over Due


</template>
<script>
import axios from "axios"


export default {
  data: () => ({
    duesoon: [],
    overdue: [],
  }),


  mounted() {
  axios.get("/api/dashboard/servicereminder").then(response =>{
    console.log(response.data)
    this.duesoon = response.data.open;
    this.overdue = response.data.closed;
  }

  
  )
  },


  computed: {
  dueSoon() {
    return this.open;
  },
  overDue() {
    return this.closed;
  }
}
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}
  .tickerTitle{
    font-size: 45px;
  }
  .tickerCaption{
    margin-top:-10px;
    font-size:12px;
  }
</style>
