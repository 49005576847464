<template lang="pug">
  .white.pt-2
    v-tabs.tabs(left='' height='32' slider-color='primary' v-model='active')
      v-tab.tabs  Inspection History
      v-tab-item.fill-tab
        inspections-list-layout
      //v-tab.tabs  Inspection Failures
      //v-tab-item.fill-tab
      //  inspection-failure-list-layout
      v-tab.tabs  Form Templates
      v-tab-item.fill-tab
        inspection-grid-template-layout
</template>

<script>
import InspectionsListLayout from "@/modules/inspections/layouts/InspectionsListLayout";
import InspectionFailureListLayout from "@/modules/inspections/layouts/InspectionFailureListLayout";
import InspectionGridTemplateLayout from "@/modules/inspections/layouts/InspectionGridTemplateLayout";

export default {
  components: {
    InspectionFailureListLayout,
    InspectionsListLayout,
    InspectionGridTemplateLayout,
  },
  computed: {
    active: {
      get: function() {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          tabIndex = tabIndex > 2 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function(value) {
        this.$route.params.tabID = value;
      }
    }
  }
};
</script>

<style scoped lang="stylus">

</style>
