import ExpenseForm from "@/modules/expense/components/forms/ExpenseForm.vue";
import ExpenseOverview from "@/modules/expense/layouts/ExpenseOverview.vue";

export const EXPENSE_ROUTES = [
  {
    path: "expenseForm",
    name: "expenseForm",
    component: ExpenseForm
  },
  {
    path: "expenseOverview",
    name: "expenseOverview",
    component: ExpenseOverview
  }
];
