<template lang="pug">
  v-card.tableHeight()
    v-toolbar.headline.white--text(color="primary" )
      | Table Editor
      v-spacer()
      v-btn(icon @click="close")
        v-icon(outlined color="white" ) close

    div.py-0()
      .d-flex.justify-end.py-4.px-4()
        v-btn.mx-3( outlined color="primary" @click="onAdd" small) Add Column
        div.mx-3() |
        v-btn.mx-3(outlined color="grey" small @click="close('cancel')") Cancel
        v-btn.mx-3(color="primary" small  @click="save()") Save
      v-divider()
      v-row.my-0.mx-0()
        v-col(:cols="propertyBoxVisible?8:12")
          .propertiesDialogSize
            draggable(v-model="fields"   @start="drag=true" @end="drag=false" :move="checkMove")
              inspection-table-row.mr-3(v-for="(item,index) in fields" :selectedItems="selectedItems" :key="index" :item="item" :index="index" :selectedIndex="selectedIndex" @delete="onDelete"   @showProperties="onShowProperties" @select="onSelect")
        v-col(cols="4" v-if="propertyBoxVisible && fields.length>0")
          .fill-height.tablePropertiesStyle()
            .tablePropertiesHeader.grey.lighten-2.body-2.font() Column Properties > {{schema.label}}
            .px-3

              v-radio-group(v-model="theAlignment" row)
                .d-flex.flex-column()
                  .caption.grey--text.text--darken-2() Column Alignment
                  .d-flex
                    v-radio(v-for="(item,index) in alignmentOptions" :key="index" :label="item.label" :value="item.value" )


              template(v-if="schema.type == 'Checkbox'")
                v-checkbox(v-model="theDefault" :label="theDefault?'True':'False'" hint="Default Option" persistent-hint)
              template(v-else-if="schema.type == 'Integer' || schema.type == 'Decimal'")
                v-text-field(v-model.number="theDefault" type="number" label="Default Value" hint="optional default value" :rules="numberRules" persistent-hint clearable )
              template(v-else-if="schema.type == 'Signature'")
                div()
              template(v-else-if="schema.type == 'Image'")
                div()
              template(v-else-if="schema.type == 'Select'")
                v-combobox.mt-3(v-model="theOptions"    label="Type optins and press enter"  multiple  small-chips clearable :deletable-chips="true" )
                  template(v-slot:selection="{ item, parent, selected }")
                    v-chip(small close @click:close="parent.selectItem(item)" )
                      | {{item}}
              template(v-else)
                v-text-field(v-model="theDefault" label="Default Value" hint="optional default value" persistent-hint clearable )



              .my-4()
                div(@click.prevent="showAdvanced=!showAdvanced")
                  showAdvancedProperties(:showAdvanced="showAdvanced"  )
                div(v-show="showAdvanced")
                  v-text-field(v-model="theID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" )



</template>
<script>
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";
import InspectionTableRow
  from "@/modules/inspections/components/formDesigner/properties/tableUI/InspectionTableRow.vue";
import ShowAdvancedProperties from "@/modules/inspections/components/formDesigner/component/ShowAdvancedProperties.vue";
import draggable from "vuedraggable";
export default {
  components: {
    InspectionTableRow,
    draggable,
    ShowAdvancedProperties,
    showAdvanced: true,
  },
  data: () => ({
    columnText: [],
    fields: [],
    properties: {},
    height: null,
    propertyBoxVisible: true,
    selectedItems: ["TextField", "Integer", "Decimal", "Checkbox", "Select","Signature","Image"],
    selectedIndex: 0,
    alignmentOptions: [{value:"left",label:"Left"},{value:"center",label:"Center"},{value:"right",label:"Right"}],
    showAdvanced: false,
    numberRules: [

      v => Number.isInteger(v) || 'The value must be an integer number',

    ],
    defaultSelectOptions:["small","medium","large"]


  }),

  watch:{
    // getLabel:function(newVal){
    //   this.theID = newVal
    // }

    getType: function(newVal){
      switch(newVal){
        case "Checkbox": this.theDefault = false; break;
        case "Decimal": this.theDefault = 0; break;
        case "Integer": this.theDefault = 0; break;
        default: this.theDefault = null;
      }
    }

  },

  created() {
    window.addEventListener("resize",this.onResize)
  },

  destroyed() {
    window.removeEventListener('resize',this.onResize)


  },



  mounted() {
    this.selectedIndex = 0;
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    console.log("+++++++++++++++++")
    console.log(this.properties)
    this.onLoadFields();
    this.onResize();
  },

  computed: {

    schema:function(){
      return this.fields[this.selectedIndex];
    },
    theAlignment:{
      get: function(){
        return this.schema.align
      },
      set: function(value){
        this.schema.align = value;
      }
    },
    theDefault:{
      get: function(){
        return this.schema.default;
      },
      set: function(value){
        this.schema.default = value;
      }
    },

    theOptions:{
      get: function(){
        if(this.schema.options){
          return this.schema.options
        }else{
          this.saveDefaultOptions();
          return this.defaultSelectOptions;
        }

      },
      set:function(value){
        this.schema.options = value
      }
    },
    getLabel:function(){
      if(this.schema){
        return this.schema.label;
      }else{
        return null
      }
    },

    getType:function(){
      if(this.schema){
        return this.schema.type;
      }else{
        return null
      }
    },


    theID:{
      get: function(){
        if(this.schema){
          return this.schema.id;
        }else{
          return null
        }
      },
      set: function(value){
        this.schema.id = this._.snakeCase(value)
      }
    }

  },
  methods: {

    save:function(){
      this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
        field: "columns",
        value: this.fields
      });
      this.$emit("close");
    },
    onResize:function(){
      this.height = window.innerHeight - 150;
    },
    onLoadFields: function() {
      this.fields = JSON.parse(JSON.stringify(this.properties.columns));
    },



    onAdd: function() {
      let index = this.fields.length + 1
      this.fields.push({ id: `column_${index}`, label: `Column ${index}`, type:"TextField", default:null, align:"left", details:{} });
    },

    onDelete: function(id) {
      let deletedIndex = this.fields.findIndex(item => item.id == id)
      this.fields.splice(deletedIndex, 1);
      this.propertyBoxVisible = false;
    },


    onShowProperties: function(value){
      this.propertyBoxVisible = value
    },

    checkMove: function(value){
      console.log(value)
    },

    close: function(value) {
      console.log(value);
      this.$emit("close");
    },

    onSelect:function(id){
      this.propertyBoxVisible = true;
      this.selectedIndex = this.fields.findIndex(item => item.id == id)
    },


    // setAlignment: function(value){
    //   this.fields[this.selectedIndex].details.align = value
    // }

    // remove the clicked item
    saveDefaultOptions: function(){
      this.schema.options = this.defaultSelectOptions;
    }
  },


};
</script>
<style scoped lang="stylus">
.propertiesDialogSize {
  height: 800px;
  overflow-y scroll
}
.tablePropertiesStyle{
  border 1px #cccccc solid;
  border-radius 10px;
  //padding 5px 10px;
}

.tablePropertiesHeader{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding 10px 10px;
}

</style>
