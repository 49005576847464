<template lang="pug">
  #blankcontent()
    .panelTitle() Inspection item Failure Rate
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-space-around.align-center.fill-height()
          .d-flex.flex-column.align-center()
            .tickerTitle.secondary--text() {{CurrentWeekfailureRate}}%
            .tickerCaption.grey--text.text--darken-1() Current Week Failure Rate
          .d-flex.flex-column.align-center()
            .tickerTitle.primary--text() {{LastWeekfailureRate}}%
            .tickerCaption.grey--text.text--darken-1() Last Week Failure Rate


</template>
<script>
export default {
  data: () => ({}),
  mounted() {
    
  },

  computed: {
    CurrentWeekfailureRate() {
      return this.$store.getters["dashboard/getCurrentWeekfailureRate"];
    },
    LastWeekfailureRate() {
      return this.$store.getters["dashboard/getLastWeekfailureRate"];
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.tickerTitle {
  font-size: 45px;
}

.tickerCaption {
  margin-top: -10px;
  font-size: 12px;
}
</style>
