<template lang="pug">
  tr
    td.taskStyle()
      .pt-1
        v-icon(small) build
        span.subheading.mx-3 {{getServiceTypeFromID(value.item.serviceTypeID).name}}
      v-layout.pb-2(row wrap)
        v-flex(xs12 md8 xl8)
          .grey--text LAST COMPLETED
          div(v-if="serviceHistoryTask")
            div
              v-tooltip(top)
                span Mon Jun, 2018
                span.primary--text.fromNow(slot="activator") Yesterday 4562456 mi ago
            .accent--text History
          div(v-else)
            .grey--text.text--lighten-1 None
            div()
        
        v-flex(xs12 md4 xl4)
          .grey--text SCHEDULE
          div(v-if="scheduleTaskReminder")
            div
              span.primary--text.mr-2 Every one Month
              router-link(to="fuel")
                v-icon(small) edit
          div(v-else)
            .accent--text Create Service Reminder
    
    td.text-xs-left
      v-btn(small fab color="grey" flat style="width:25px; height:25px")
        v-icon.pt-1(v-if="value.item.issue_id") link
        v-icon.pt-1(v-else) link_off

    td.text-xs-left.rowStyle
      service-input(field="labour" :getPath="getPath" :id="value.item.id" :setPath="setPath" )

    td.text-xs-left.rowStyle
      service-input(field="parts" :getPath="getPath" :id="value.item.id" :setPath="setPath" )
      
    td.text-xs-left.rowStyle
      service-input(field="total" :getPath="getPath" :id="value.item.id" :setPath="setPath" )
      
    td.text-xs-left.rowStyle.pt-3
        more-drop-down-menu(:items="menuitems" @onClick="onClick" :theObject="value.item")



</template>
<script>
import ServiceInput from "@/modules/services/components/ui/ServiceInput";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";

export default {
  components: {
    ServiceInput,
    MoreDropDownMenu
  },

  props: ["value"],

  data: () => ({
    getPath: "service/getCurrentserviceDetailItem",
    setPath: "service/fieldUpdateIndex",

    serviceHistoryTask: true,
    scheduleTaskReminder: true,

    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" },
      { title: "View Full History", icon: "history", action: "download" }
    ]
  }),

  methods: {
    onClick: function(value) {
      console.log(value);
    },

    getServiceTypeFromID: function(value) {
      return this.$store.getters["service/serviceType/getServiceTypeFromID"](
        value
      );
    }
  }
};
</script>
<style scoped lang="stylus">


.taskStyle {
  min-width: 100%;
}

.fromNow:hover {
  cursor: help;
}
</style>
