import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const ROLL_SETTINGS_API = "/role";

const state = {
    role_data: [],
    currentIndex: -1
};

const getters = {
    getRole: state => {
        return getField(state.role_data[state.currentIndex]);
    },


    getCurrentRole: state => {
        if (state.currentIndex > -1) {
            console.log(`getCurrentItem index: ${state.currentIndex}`);
            console.log(
                `getCurrentItem object: ${state.role_data[state.currentIndex].name}`
            );
            console.log(`getCurrentItem length: ${state.role_data.length}`);
        }

        return state.role_data[state.currentIndex];
    },


};

const mutations = {
    updateRole(state, payload) {
        updateField(state.role_data[state.currentIndex], payload);
    },

    changePermission(state,payload){
        state.role_data[state.currentIndex].permissions[payload.index][payload.type] = !state.role_data[state.currentIndex].permissions[payload.index][payload.type]
        if(payload.type === "view" && state.role_data[state.currentIndex].permissions[payload.index].view === false){
            state.role_data[state.currentIndex].permissions[payload.index].edit = false;
        }
        if(payload.type === "edit" && state.role_data[state.currentIndex].permissions[payload.index].edit === true){
            state.role_data[state.currentIndex].permissions[payload.index].view = true;
        }
    },

    setCurrentIndexByID(state, theIndex) {
        state.currentIndex = state.role_data.findIndex(item => item.id == theIndex);
    },

    saveRole(state, payload) {
        state.role_data[state.currentIndex] = payload.data;
    },

    fetchRoleSettings(state, payload) {
        state.role_data = payload.data;
    },

    fetchRole(state, payload) {
        if (state.currentIndex == -1) {
            state.role_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.role_data[state.currentIndex] = payload.data;
        }
    },

    loadAllRoles(state,payload) {
        state.role_data = payload.data;
        state.currentIndex = 0;
    },

    deleteRole(state, payload) {
        state.currentIndex = state.role_data.findIndex(
            item => item.id == payload.data.id
        );
        state.role_data.splice(state.currentIndex, 1);
    },

    addRole(state, payload) {
        state.role_data.push(payload.data);
        state.currentIndex = state.role_data.length - 1;
    },

    reset(state) {
        state.role_data = []
        state.currentIndex = -1
        state.defaultPermissions = []
    }
};

const actions = {
    async fetchRoleSettings({commit}) {
        try {
            commit("fetchRoleSettings", await Repository.get(`${ROLL_SETTINGS_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async loadAllRoles({commit}){
        try{
            commit("loadAllRoles", await Repository.get(`${ROLL_SETTINGS_API}`));
        }catch(error){
            console.log(error)
        }
    },

    async fetchRole({commit}, payload) {
        try {
            commit(
                "fetchRole",
                await Repository.get(`${ROLL_SETTINGS_API}/${payload}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async saveRole({commit}, payload) {
        try {
            commit(
                "saveRole",
                await Repository.post(`${ROLL_SETTINGS_API}`, payload)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteRole({commit}, payload) {
        try {
            commit(
                "deleteRole",
                await Repository.delete(`${ROLL_SETTINGS_API}/${payload}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async addNew({commit}) {
        await Repository.get(`${ROLL_SETTINGS_API}/new`)
            .then(response => {
                console.log(response.data);
                commit("addRole", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async copyRole({commit, getters}) {
        try {
            commit(
                "copyRole",
                await Repository.post(
                    `${ROLL_SETTINGS_API}/copy`,
                    getters.getCurrentRole
                )
            );
        } catch (error) {
            console.log(error);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
