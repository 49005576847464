<template lang="pug">
  div()
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Failed Items
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item(v-for='item in actionItems', :key='item.title')
                v-list-item-title {{ item.title }}
        v-btn.ma-2.mr-5(small, color='primary', dark)
          v-icon.mr-2() playlist_add
          | Start Inspection
    v-divider
    v-layout.mx-4.my-2(column)
      v-chip-group(v-model="statusIndex" column  active-class="primary--text")
        v-chip(v-for='toggleItem in toggleItems', :key='toggleItem' small v-html="toggleItem")
    v-layout.ma-4(row align-center)
      v-row()
        v-col(cols="12" xs="12" md="5" xl="3")
          .d-flex.align-center()
            v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" )
            v-btn(v-if="search.length > 0" text outlined small @click="search=''") clear all


    v-divider
    .grey.lighten-4.py-3
    v-divider
    template(v-if="isLoaded")
      inspections-failure-table(:search="search" :status="status" )
    template(v-else)
      .d-flex.fill-width.py-8.justify-center()
        v-progress-circular(indeterminate color="primary")
    v-divider
</template>

<script>
import InspectionsFailureTable from "@/modules/inspections/components/tables/InspectionsFailureTable";

export default {
  components: {
    InspectionsFailureTable,

  },

  data: () => ({
    isLoaded: false,
    search: "",
    vehiclesID: [],
    toggleItems: ["All", "Needs Action", "Open Issue", "Acknowledged", "Resolved"],
    statusIndex: 0,
    actionItems: [

      {
        title: "Export PDF"
      },
      {
        title: "Export Excel"
      },
      {
        title: "Import Excel"
      }
    ]
  }),

  computed: {
    status: {
      get: function () {
        return this.toggleItems[this.statusIndex];
      },
      set: function (value) {
        this.statusIndex = this.toggleItems.findIndex(item => item === value)
      }
    }
  },

  methods: {
    openEdit: function () {
      this.$store.dispatch("inspections/addInspection").then(() => {
        let currentObject = this.$store.state.inspection.inspection_data[
            this.$store.state.inspection.currentIndex
            ];
        this.$router.push({
          name: "inspectionDetailsForm",
          params: {inspectionID: currentObject.id}
        }).catch(()=>{});
      });
    },


    showDialog: function () {
    },

    closeDialog: function (value) {
      console.log(value);
    }
  },

  mounted() {
    console.log(`vehiclesIndex: ${this.$store.state.vehicles.currentIndex} contactIndex: ${this.$store.state.contacts.currentIndex}`)
    this.isLoaded = true;
  }
};
</script>

<style scoped></style>
