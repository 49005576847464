<template lang="pug">
  .pageBase.grey.lighten-4
    .Tutorial
      youtube(:video-id="youtubeID")
      .pa-3.grey--text {{description}}
      v-btn(small color="primary" @click="$emit('tutorialAction')") {{buttonLabel}}

</template>
<script>
export default {
  props: {
    buttonLabel: String,
    description: String,
    youtubeID: String
  },
  data: () => ({})
};
</script>
<style scoped lang="stylus">
.pageBase
  width 100%
  display flex
  align-items center
  justify-content center

.Tutorial
  min-width 500px
  min-height 400px
  margin 30px 20px
  background-color white
  border 3px dashed #CCC
  border-radius 30px
  padding 30px 30px
  display flex
  flex-direction column
  align-items  center
</style>
