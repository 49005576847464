<template lang="pug">
  div()
    location-select(v-model='computedValue' :width="400" :height="200" :label="schema.label" :description="schema.hint")

</template>
<script>

import SETTINGS from "@/modules/inspections/store/formSettings";
import LocationSelect from "@/modules/global/components/forms/LocationSelect";

export default {
  components:{
    LocationSelect
  },

  props: {
    schema: Object
  },
  data: ()=>({

  }),
  computed:{

    computedValue: {
      get: function() {
        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id);
      },

      set: function(theValue) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }
  },

  methods:{

  }
};
</script>
<style scoped lang="stylus">


</style>
