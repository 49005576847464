<template lang="pug">
  .fill-width.pa-2()
    v-toolbar(dense flat)
      v-toolbar-title Settings
      v-spacer
      v-btn.mb-2.mx-2(small, color='primary',outlined, dark, @click='edit') Edit Template
      v-btn.mb-2.mx-2(small, color='primary',outlined, dark, @click='cancel') Cancel
      v-btn.mb-2.mx-2(small, color='primary', dark, @click='save') Save

    .settingPadding()
      form-divider.mb-2 Workflow
      v-form(v-model="valid" ref="form")
        v-row()
          v-col(cols="12" xs="12" md="6" xl="4")
            v-text-field(v-model='name' label='Form Name')
          v-col(cols="12" xs="12" md="6" xl="4")
            v-text-field(v-model='description' label='Form Description')
          v-col(cols="12" xs="12" md="12" xl="12")
            attach-fill-form(:schema="imageSchemas" :data="{ value:thumbnail_id}" @onChange="saveWorkflowImage"  @onMenuAction="onMenuAction")
        form-divider.mb-2 Record Preview
        v-row()
          v-col(cols="12" xs="12" md="6" xl="6")
            v-row()
              v-col(cols="12" xs="12" md="6" xl="6")
                v-select(:value="title" @input="title=$event?$event:null" :items="getTitleSubTitle" label="Title" item-text="label" item-value="id" :clearable="true" )
              v-col(cols="12" xs="12" md="6" xl="6")
                v-select(:value="sub_title1" @input="sub_title1=$event?$event:null"  :items="getTitleSubTitle" label="Sub Title 1"  item-text="label" item-value="id"  :clearable="true" )
            v-row()
              v-col(cols="12" xs="12" md="6" xl="6")
                v-select(:value="avatar" @input="avatar=$event?$event:null"  :items="getImageTypes" label="Avatar"    item-text="label" item-value="id"  :clearable="true" )
              v-col(cols="12" xs="12" md="6" xl="6")
                v-select(:value="sub_title2" @input="sub_title2=$event?$event:null"  :items="getTitleSubTitle" label="Sub Title 2"    item-text="label" item-value="id"  :clearable="true" )

            v-row()
              v-col(cols="12" xs="12" md="6" xl="6")
                v-checkbox.mt-0(v-model='showAuthor', label='Show Author', type='checkbox')
              v-col(cols="12" xs="12" md="6" xl="6")
                v-checkbox.mt-0(v-model='showDate', label='Show Date', type='checkbox')

          v-col(cols="12" xs="12" md="6" xl="6")
            .d-flex.fill-height.grey.lighten-3
              .boardCardSample(v-if="$store.state.profile.userInfo")
                v-card()
                  v-card-title.pr-1.pl-2.pt-2.pb-0
                    v-layout(justify-space-between align-center)
                      .body-1(v-text="`{{ ${title} }}`")
                      div()
                        v-icon.zIcon() notifications
                        v-menu()
                          template( v-slot:activator="{ on, attrs }")
                            v-btn(icon v-bind="attrs" v-on="on")
                              v-icon() expand_more
                          v-list(dense)
                            v-list-item-group()
                              v-list-item()
                                v-list-item-icon()
                                  v-icon(:size="20") edit
                                v-list-item-title() Edit Record
                              v-list-item(v-if="isAdministrator")
                                v-list-item-icon()
                                  v-icon(:size="20")  delete
                                v-list-item-title() Delete Record
                  v-layout.mx-1.my-2(align-center)
                    .boardAvatar.ml-2(v-if="avatar")
                    v-card-subtitle.py-0()
                      .body-2(v-if="sub_title1" v-text="`{{ ${sub_title1} }}`")
                      .body-2.mt-1(v-if="sub_title2" v-text="`{{ ${sub_title2} }}`")
                      //- .caption {{item.board_column_id}}
                  div(v-if="showAuthor || showDate")
                    v-divider()
                    v-card-actions.my-0.py-1()
                      v-avatar(v-if="showAuthor" :style="getAvatarBackground" size="20" )
                        .smallWorkflowAvartar() MK
                      v-spacer()
                      .caption.grey--text(v-if="showDate" v-text="getCurrentDate")

        form-divider.mb-2 Mobile Layout
        v-row()
          v-col(cols="12" xs="12" md="6" xl="6")
            v-row()
              v-col(cols="12" xs="12" md="6" xl="6")
                v-select(:value="map_background" @input="map_background=$event?$event:null"  :items="getMapTypes" label="Background Map"  item-text="label" item-value="id"  :clearable="true" )



              v-col(cols="12" xs="12" md="6" xl="6")
                .mapLayout(:style=`{opacity:(map_background == null)?0.2:1.0}`)
        form-divider.mb-2 Delete Template
        v-row()
          v-col(cols="12" xs="12" md="6" xl="6")
            v-btn.my-2(color="primary" @click="deleteTemplate()" ) Delete Template

</template>

<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
import moment from "moment";
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "workflow/getWorkflow",
  mutationType: "workflow/updateWorkflow"
});
export default {
  components:{
    FormSubHeader,
    FormDivider,
    AttachFillForm
  },
  data: ()=>({
    valid: false,

    imageSchemas:{
      name: "Attach",
      id: "12345",
      value: [],
      label: "WORKFLOW THUMBNAIL",
      hint: "jpeg or png",
      type: "image",
      image_size: "small",
      multiple: false
    }
  }),

  computed: {
    ...mapFields({
      name: "name",
      description: "description",
      thumbnail_id: "thumbnail_id",
      title: "details.settings.title",
      sub_title1:"details.settings.sub_title1",
      sub_title2:"details.settings.sub_title2",
      avatar:"details.settings.avatar",
      showAuthor:"details.settings.show_author",
      showDate:"details.settings.show_date",
      map_background: "details.settings.map_background"
    }),

    isMapBackground: {
      get: function() {
        return false;
      },
      set: function(value) {
        console.log(value)
      }
    },

    getTitleSubTitle:function(){
      console.log(this.schemeData)

      let list = []

      this.$store.getters["workflow/getLatestSchema"].forEach(item => {
        if(item.name !== "Header" && item.name !== "Attach" && item.name !== "Image" ){
          list.push({id:item.id,label:item.label});
        }

      })


      return list
    },

    getImageTypes: function() {
      let list = [];
      this.$store.getters["workflow/getLatestSchema"].forEach(item => {
        if(item.name == "Image" || item.name == "Signature" ){
          list.push({id:item.id,label:item.label});
        }

      })

      return list
    },

    getMapTypes: function() {
      let list = [];
      this.$store.getters["workflow/getLatestSchema"].forEach(item => {
        if(item.name == "Map"){
          list.push({id:item.id,label:item.label});
        }

      })

      return list
    },

    getCurrentDate:function(){
      return moment().format("ddd, Do MMM YY - h:mm a")
    },
    getAvatarBackground: function() {
      // console.log(this.$store.state.profile.userInfo.contact);
      let obj = {};
      obj["background-color"] = "#333333";
      if (this.$store.state.profile.userInfo.contact.group != null) {
        obj[
            "background-color"
            ] = this.$store.state.profile.userInfo.contact.group.color;
      }
      return obj;
    },
    isAdministrator: function(){
      return this.$store.state.profile.userInfo.is_administrator;
    },
  },
  methods: {

    onButtonTest() {
      console.log("testing the clear button")
      console.log(this.title)
      this.title = null;
    },

    edit() {
        let item =this.$store.getters["workflow/getCurrentWorkflow"];
        this.$store.commit("workflow/formBuilder/resetFormBuilder");
        this.$store.commit(
          "workflow/setCurrentIndexByID",
          item.id
        );
        // save the latest scheme
        this.$store.commit(
          "workflow/formBuilder/setSchema",
          this._.clone(
            item.data[item.data.length - 1].schema
          )
        );
        this.$router.push({
          name: "templateBuilderForm",
          params: { workflowID: item.id }
        }).catch(()=>{});

    },

    cancel() {
      this.$router.push({name:"workflows"}).catch(()=>{});
    },
    save(){
      this.$store.dispatch("workflow/saveWorkflow").then(()=>{
        this.$store.commit("showSnackBar", {
          color: "success",
          message: "<B>Saved Workflow Settings</B>"
        });
        // this.$router.push({ name: "workflows" }).catch(()=>{});
        this.$router.push({name:"workflows"}).catch(()=>{});
      })
    },
    saveWorkflowImage(theObject) {
      this.$store.commit("workflow/updateWorkflow", {
        path: "thumbnail_id",
        value: theObject.value[0]
      });
    },

    deleteTemplate:function(){
      confirm(
        "Are you sure you want to delete the template? " +
        this.$store.getters["workflow/getCurrentWorkflow"].name
      ) &&
      this.$store.dispatch("workflow/deleteWorkflow", this.$store.getters["workflow/getCurrentWorkflow"].id).then(()=>{
        this.$router.push({name:"workflows"}).catch(()=>{});
      });
    },

    onMenuAction: function(value) {
      // console.log(value);
      switch (value.action) {
        case "delete":
          // this.$store.commit("settings/accountSettings/updateAccountSetting", {
          //   path: "thumbnail",
          //   value: null
          // });

          break;
        case "edit":
          console.log("edit");
          break;
        case "make_thumbnail":
          console.log("make_default");
          break;
      }
    },
  },
}
</script>

<style scoped lang="stylus">
.settingPadding{
  padding 0px 10px;
}
.boardCardSample{
  margin auto;
  cursor: default;
  min-width: 270px;
}
  .boardAvatar{
    width 80px;
    height 80px;
    border-radius 10px;
    background-color #330033;
  }
  .smallWorkflowAvartar{
    font-size 12px;
    color white;
    font-weight bold
  }

  .mapLayout{
    height 400px;
    width 200px;
    background-repeat no-repeat;
    background-size contain;
  }
</style>