<template lang="pug">
  div 
    panel-title-header(label="Assigned Work Orders")
    .PairItems
      number-guage(:value="countOpenWorkOrders" label="Open" )
      number-guage(:value="countClosedWorkOrders" label="Closed")
    v-divider
</template>
<script>
import NumberGuage from "@/modules/global/components/dashboard/NumberGuage";
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";

export default {
  components: {
    PanelTitleHeader,
    NumberGuage
  },
  data: () => ({
    countOpenWorkOrders: 0,
    countClosedWorkOrders: 0
  }),

  methods: {
    onClick: function() {}
  }
};
</script>
<style scoped lang="stylus"></style>
