<template lang="pug">
  div()
    .d-flex.flex-column(style="width:100%")
      table.pBorder()
        tr()
          th.py-1(style="width:70px;")
          th.py-1(v-for="(item,index) in schema.columns" :key="index")
            // Lables
            div.caption.font-weight-bold(:style="getHeaderStyle(item)") {{item.label}}
          th.py-1(style="width:60px;")
        tr(v-for="(row,rowIndex) in database" )
          td.d-flex.justify-center()
            v-checkbox.mb-2(dense hide-details="true" :value="rowIndex" v-model="selectedCheckboxes" )
          // data
          td(v-for="(item,index) in schema.columns" :key="index")
            div.body-2(:style="getHeaderStyle(item)")
              template(v-if="item.type == 'Checkbox'")
                v-checkbox(v-model="row[item.id]" disabled)
              template(v-else)
                | {{row[item.id]}}
          td()
            more-drop-down-menu.pl-2(v-show="!disabled" :items='menuitems', @onClick='onAction', :theObject='{item:row,index:rowIndex}')
      .d-flex.justify-end.mt-3(v-if="!disabled")
        v-btn.ml-2(outlined x-small color="primary" @click="add" ) add
        v-btn.ml-2(outlined x-small color="primary" :disabled="selectedCheckboxes.length == 0" @click="onDelete") delete
    v-dialog(v-model="showEditDialog" max-width="600" )
      v-card()
        v-toolbar.headline.white--text(color="primary" )
          | Edit Item
          v-spacer()
          v-btn(icon @click="showEditDialog=false")
            v-icon(outlined color="white" ) close
        div()
          .d-flex.flex-column()
            table-render-component(v-for="(schema,index) in schema.columns" :key="index" :schema="schema" :dataValue="selectedItem" @change="onChange")
        v-divider()
        v-card-actions()
          v-layout(justify-end)
            v-btn.ma-2(text @click="cancelDialogBtn") Cancel
            v-btn.ma-2(color="primary" @click="saveDialogBtn") Save


</template>
<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import TableRenderComponent from "@/modules/workflow/formBuilder/components/ui/TableItems/TableRenderComponent.vue";
export default {
  props: ["schema", "dataValue", "index","disabled"],
  data: () => ({
    selectedCheckboxes:[],
    database:[],
    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" },
    ],
    showEditDialog: false,
    selectedItem:null,
    selectedIndex:null,
    cancelObj:null,
  }),
  components:{
    MoreDropDownMenu,
    TableRenderComponent
  },

  watch:{
    computedValue:function(newVal){
      if (newVal == null || newVal == undefined){
        this.database = []
      }else{
        this.database = JSON.parse(JSON.stringify(newVal))
      }


    }
  },

  mounted() {

    if(this.dataValue == undefined || this.dataValue==null){
      this.database = [];
    }else{
      this.database = JSON.parse(JSON.stringify(this.dataValue))
    }

  },
  computed: {
    headers: function() {
      let headerList = [];
      this.schema.columns.forEach( item => {
            headerList.push({text: item.label, align:item.align, value:item.id})
        }
      )
      headerList.push({text: "Action", align:"right",value:"action"})
      return headerList;
    },

    requiredRule: function() {
      return this.schema.required ? value => !!value || "Required." : false;
    },

    computedValue: {
      get: function() {
        return this.dataValue;
      },

      set: function(theValue) {
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    }
  },

  methods:{
    add:function(){

      if (this.disabled){
        return;
      }

      let rand = Math.round(Math.random()*50).toString()
      let obj = {};
      this.schema.columns.forEach(item => {
        if(item.default == undefined || item.default ==null){
          obj[item.id] = null;
        }else{
          obj[item.id] = item.default;

          // TODO: To delete below
          if(item.id == "first_name"){
            obj[item.id] += rand
          }
        }

      })

      this.database.push(obj)
      this.selectedItem = obj;
      this.selectedIndex = this.database.length-1;
      this.cancelObj = JSON.parse(JSON.stringify(obj))
      this.showEditDialog = true;

      // this.save();
    },

    onDelete: function(){
      if (this.disabled){
        return;
      }

      if(this.selectedCheckboxes.length != 0){
        let newArray = [];
        for(var i=0; i<this.database.length;i++){

          let result = this.selectedCheckboxes.findIndex(theIndex => theIndex == i)

          if (result == -1){
            newArray.push(this.database[i])
          }
        }
        this.database = newArray;
        this.selectedCheckboxes = [];
        // save the database
        this.save();
      }

    },

    save: function(){
      this.$emit("onChange", {
        id: this.schema.id,
        value: this.database,
        index: this.index
      });
    },

    onAction:function(value){
      console.log(value)
      switch(value.action){
        case "edit":
          this.selectedItem = value.theObject.item;
          this.selectedIndex = value.theObject.index;
          this.cancelObj = JSON.parse(JSON.stringify(value.theObject.item))
          this.showEditDialog = true;
          break;
        case "delete":
          confirm(
              `Are you sure you want to delete the report ${value.theObject.item}`
          ) && this.$store.dispatch("reports/deleteReport",value.theObject.id); break;
      }
    },

    // update the selected item when updateing
    onChange: function(item){
      this.selectedItem[item.id] = item.value;
    },

    cancelDialogBtn: function(){
      this.database[this.selectedIndex] = JSON.parse(JSON.stringify(this.cancelObj));
      this.selectedItem = this.database[this.selectedIndex]
      this.showEditDialog = false;
    },

    saveDialogBtn:function(){
      this.showEditDialog = false;
      this.save();

    },

    getHeaderStyle:function(item){
      let theStyle = {'display':'flex'};
      switch (item.align){
        case "left": theStyle["justify-content"] = "flex-start"; break;
        case "center": theStyle["justify-content"] = "center"; break;
        case "right": theStyle["justify-content"] = "flex-end"; break;
        default: theStyle["justify-content"] = "flex-start";
      }

      return theStyle;
    }
  }
};
</script>
<style scoped lang="stylus">
.pBorder{
  border-collapse: collapse;
  borde: none;
  //border: 1px solid #E8E8E8;
  //border-radius 10px;
}

.pBorder td{
  border-bottom: 1px solid #E8E8E8;
  //border-left : 1px solid #E8E8E8;
  padding: 0px 10px;
}
.pBorder th{
  //border: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  //border-left : 1px solid #E8E8E8;
  padding: 0px 10px;


}

</style>
