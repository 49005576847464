<template lang="pug">
div
  v-data-table.elevation-1(v-model="selected" :search="search" :custom-filter="vehicleModuleFilter" :headers='headers', :items='$store.state.vehicles.all',item-key="id", :loading='loading', :single-select="false" show-select)
    template(v-slot:progress)
      v-progress-linear(height='2', :indeterminate='loading')

    template(v-slot:item.name="{item}")
      .vehicleName(@click="onClick({theObject:item,action:'overview'})")
        vehicle-column(:vehicle="item")

    template(v-slot:item.status="{item}")
      v-icon(small='', :style="{color: [item.status] == 'active' ? 'green' : 'gray'}") fiber_manual_record
      | {{ item.status }}


    template(v-slot:item.group="{item}")
      template(v-if="item.group") 
        span(:style="groupStyle(item.group.color)") {{item.group.name}}
      template(v-else)
        .grey--text() no group

    template(v-slot:item.odometer="{item}")
      template(v-if="item.odometer")
        div {{item.odometer.meter}}
        a.caption.grey--text.text--lighten-1()  &#35;{{item.odometer.odometer_reference}} &gt; {{item.odometer.date_time | moment("HH:mm ddd, Do MMM YYYY")}}
      template(v-else)
        .grey--text.text--lighten-2() unknown

    template(v-slot:item.operator="{item}")
      template(v-if="item.operator") 
        .d-flex.align-center()
          div(:style="operatorStyle(item.operator)") {{item.operator.first_name[0]}}{{item.operator.last_name[0]}}
          div {{item.operator.first_name}} {{item.operator.last_name}}
      template(v-else)
        a.caption.grey--text.text--lighten-1(@click="openAssignmentDialog") Unassigned
    
    template( v-slot:item.action="{ item }")
      v-row.mr-1(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

  export-data-dialog(:fileExport="fileExport" :isFinished="isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="showExportDialog = false;" @download="onDownload" )

</template>

<script>
import AlertBox from "@/modules/global/components/others/AlertBox";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import FormImageThumbnail from "@/modules/global/components/forms/FormImageThumbnail";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import { searchMixin } from "@/mixin/searchListMixin";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog.vue";
import Repository from "@/repository";
export default {
  components: {
    AlertBox,
    MoreDropDownMenu,
    FormImageThumbnail,
    ImageThumbnail,
    VehicleColumn,
    ExportDataDialog
  },
  mixins: [searchMixin],

  props: {
    search: String
  },

  data() {
    return {
      dialog: false,
      selected: [],
      selectedObject: null,

      loading: false,
      menuitems: [
        { title: "Edit", icon: "edit", action: "edit" },

        { title: "Overview", icon: "dashboard", action: "overview" },
        { title: "Download", icon: "cloud_download", action: "download" },
        { title: "Archive", icon: "archive", action: "delete" },
        { title: "Download Certificate", icon: "description", action: "certificate" },
      ],

      headers: [
        {
          text: "Name",
          align: "left",
          value: "name"
        },
        { text: "Status", value: "status", align: "left" },
        { text: "Type", value: "type", align: "left" },
        { text: "Group", value: "group", align: "left" },
        { text: "Last Milage", value: "odometer", align: "left" },
        { text: "Operator", value: "operator", align: "left" },
        { text: "Actions", value: "action", align: "right", sortable: false }
      ],
      isDownloadFinished: false,
      fileLink: null,
      showExportDialog: false,
      fileExport: "",
    };
  },

  methods: {
    groupStyle: function(value) {
      let obj = {};
      obj["border-bottom"] = "3px solid";
      obj["border-bottom-color"] = value;
      return obj;
    },

    operatorStyle: function(operator) {
      let obj = {};
      obj["border-radius"] = "50%";
      obj["color"] = "#FFFFFF";
      obj["padding"] = "3px";
      obj["margin-right"] = "5px";

      if (operator.group != null) {
        obj["background-color"] = operator.group.color;
      } else {
        obj["background-color"] = "#333333";
      }
      console.log(operator.group);
      return obj;
    },

    vehicleModuleFilter: function(value, search, item) {
      let isFound = false;
      let patt = new RegExp(search.toLowerCase(), "i");
      // Search the Object
      for (var key in item) {
        if (item[key] != null) {
          if (item[key].toString().match(patt)) {
            isFound = true;
            break;
          }
        }
      }

      // search group
      if (isFound == false && item.group != null) {
        isFound = item.group.name.toString().match(patt) ? true : false;
      }

      //search First Name operator
      if (isFound == false && item.operator != null) {
        isFound = item.operator.first_name.toString().match(patt)
          ? true
          : false;
      }

      //search Last Name operator
      if (isFound == false && item.operator != null) {
        isFound = item.operator.last_name.toString().match(patt) ? true : false;
      }

      return isFound;
    },

    editItem: function(object) {
      this.$store.commit("vehicles/setCurrentIndex", object);
      this.$router.push({
        name: "vehicleNew",
        params: { vehicleID: object.id }
      }).catch(()=>{});
    },

    deleteItem: function(object) {
      console.log("delete the Item");
      this.selectedObject = object;
      this.dialog = true;
    },

    onConfirmDelete: function(object) {
      console.log(object);
      this.$store.commit("vehicles/setCurrentIndex", object);
      this.onCloseDialog();
      this.$store.dispatch("vehicles/deleteItem").then(() => {
        this.$store.dispatch("vehicles/loadAllItems").then(() => {
          this.$store.dispatch("vehicles/assignments/loadAllSchedules");
        });
      });
    },

    onCloseDialog: function() {
      this.dialog = false;
    },

    openAssignmentDialog: function() {
      console.log("open Assignment dialog");
    },

    downloadFile: function (url) {
      this.isDownloadFinished = false;
      console.log(url)

      Repository({
        method: "GET",
        url: url,
        responseType: "blob",
      }).then((response) => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function () {
      // download the file
      this.fileLink.click();
      this.showExportDialog = false;
    },

    onClick: function(value) {
      console.log("more function recorded **********************");
      switch (value.action) {
        case "edit":
          this.$store.commit("vehicles/setCurrentIndex", value.theObject);
          this.$router.push({
            name: "vehicleNew",
            params: { vehicleID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          // this.selectedObject = value.theObject;
          // this.dialog = true;
          this.$store
            .dispatch("vehicles/deleteItem", value.theObject.id)
            .then(() => {
              this.$store.dispatch("vehicles/loadAllItems").then(() => {
                this.$store.dispatch("vehicles/assignments/loadAllSchedules");
              });
            });
          break;

          case "certificate":
            console.log(value.theObject);
            if (value.theObject.device_id == null){
              this.$store.commit("showSnackBar", {
                color: "red",
                message: `Vehicle ${value.theObject.name} has NO Telematics Device Installed`
              });
            }else{
              this.$store.commit("vehicles/setCurrentIndex", value.theObject);
              this.fileExport = "Pdf";
              this.downloadFile(`/vehicle/certificate?vehicle_id=${value.theObject.id}`);
              this.showExportDialog = !this.showExportDialog;
            }

            break;

        case "overview":
          this.$store.commit("vehicles/setCurrentIndex", value.theObject);
          this.$router.push({
            name: "vehicleDashboard",
            params: { vehicleID: value.theObject.id }
          }).catch(()=>{});
          break;
      }
      console.log(value);
    }
  },

  mounted() {}
};
</script>

<style scoped>
.vehicleName {
  cursor: pointer;
}
</style>
