<template lang="pug">
div(v-if="item")
  .nameColumn(v-if="item != null")
    image-thumbnail.mr-2(:width='width', :height='height', :src="item.thumbnail_id")
    .nameColumnInfo
      .nameColumnTitle {{ item.name }}
      .nameColumnDescription.make.primary--text
        | {{ item.item_reference }}
      .nameColumnDescription(v-if="size == 'normal' || size == 'big'")
        | {{ item.description }}
      .nameColumnDescription2
        | Type: {{ item.inventory_type }} &nbsp;&nbsp;&ndash; &nbsp;&nbsp;
        span.grey--text.text--darken-4(v-if="item.balance")
          span() Balance: &nbsp;
          span.font-weight-bold.primary--text() {{item.balance}} {{item.measurement_unit != null ? item.measurement_unit.name : 'units'}}
</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";

export default {
  components: {
    ImageThumbnail
  },
  props: {
    item_id: String,
    size: {
      type: String,
      default: "normal"
    },


  },
  data: () => ({
    item: null,
    width: 90,
    height: 60,
  }),
  mounted() {
    if (this.size == 'small') {
      this.height = 40;
      this.width = 60;
    }

    let tempItem = this.$store.getters["inventory/item/getItemById"](this.item_id);
    if (tempItem == undefined) {
      this.$store.dispatch("inventory/item/fetchItem",this.item_id).then(() => {
        tempItem = this.$store.getters["inventory/item/getItemById"](this.item_id);
        this.item = tempItem;
      })
    } else {
      this.item = tempItem;
    }
  }
};
</script>
<style lang="stylus" scoped>
.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  //width: 80px;
  //height: 60px;
}

.nameColumn, .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn, .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription2 {
  font-size: 12px;
  color: gray;
  padding: 0px;
}

.make {
  font-size: 11px;
  margin-top: -4px;
}
</style>
