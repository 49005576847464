<template lang="pug">
  div
    v-combobox(
      ref='theCombo'
      v-model="select"
      :hide-no-data="!search"
      :search-input.sync="search"
      hide-selected
      :items="items"
      :label="label"
      :multiple="true"
      item-text="name"
      item-value="id"
      @keyup.enter="onEdit"
      @change="onChange"
      :loading="isLoading"



    )
      template(slot="selection" slot-scope="data")
        v-chip.chip--select-multi(:input-value="data.selected" :color="isNewLabel?'primary white--text':'grey lighten-2'" small close @click:close="onRemove(data.item.id)" )
          | {{data.item.name}}

      template(slot="no-data")
        v-list-item
          span.subheading Create
          v-chip.ml-2(:color="'primary white--text'" label small) {{search}}





</template>
<script>
export default {
  props: {
    value: Array,
    label: {
      type: String,
      default: "Select Item"
    },

    module: {
      type: String,
      required: true
    },

    submodule: {
      type: String,
      default: null,
      required: false
    },

    actionPrefix: {
      type: String,
      required: true
    }
  },
  data: () => ({
    modulePath: null,
    select: null,

    isLoading: false,
    loadAll: false,

    search: null,
    editing: null,
    isNewLabel: false
  }),

  computed: {
    items: {
      get: function() {
        if (this.submodule) {
          return this.$store.state[this.module][this.submodule][
            this.actionPrefix.toLowerCase() + "_data"
          ];
        } else {
          return this.$store.state[this.module][
            this.actionPrefix.toLowerCase() + "_data"
          ];
        }
      },
      set: function(value) {
        console.log(value);
      }
    }
  },

  methods: {
    onChange: function(theValue) {
      this.isLoading = false;

      if (theValue != null) {
        let tempArray = [];
        theValue.forEach(item => {
          tempArray.push(item.id);
        });
        this.$emit("input", tempArray);
        this.isNewLabel = false;
      }
    },

    onLoad: function() {
      if (this.loadAll == false) {
        this.isLoading = true;
        this.$store
          .dispatch(`${this.modulePath}/loadAll${this.actionPrefix}s`)
          .then(() => {
            if (this.submodule) {
              this.items = this.$store.state[this.module][this.submodule][
                this.actionPrefix.toLowerCase() + "_data"
              ];
            } else {
              this.items = this.$store.state[this.module][
                this.actionPrefix.toLowerCase() + "_data"
              ];
            }
            // reset the Select and add the components
            this.select = [];
            if (this.value && this.value.length > 0) {
              for (var i = 0; i < this.value.length; i++) {
                for (var j = 0; j < this.items.length; j++) {
                  if (this.items[j].id == this.value[i]) {
                    this.select.push(this.items[j]);
                  }
                }
              }
            }

            this.isLoading = false;
            this.loadAll = true;
          });
      }
    },

    onRemove: function(itemID) {
      let tempArray = [];
      for (var i = 0; i < this.select.length; i++) {
        if (this.select[i].id != itemID) {
          tempArray.push(this.select[i]);
        }
      }
      this.select = tempArray;
      this.onChange(this.select);
    },

    onEdit: function() {
      // check if the last item is a typed item and does not exist as a chip
      if (
        typeof this.$refs.theCombo.$data.selectedItems[
          this.$refs.theCombo.$data.selectedItems.length - 1
        ] == "string"
      ) {
        this.isLoading = true;
        let newValue = this.$refs.theCombo.$data.selectedItems[
          this.$refs.theCombo.$data.selectedItems.length - 1
        ];

        // add
        this.$store
          .dispatch(`${this.modulePath}/add${this.actionPrefix}`)
          .then(() => {
            // mutate store
            this.$store.commit(
              `${this.modulePath}/update${this.actionPrefix}`,
              { path: "name", value: newValue }
            );

            let currentItem = this.$store.getters[
              `${this.modulePath}/getCurrent${this.actionPrefix}`
            ];

            // // save to database
            this.$store
              .dispatch(`${this.modulePath}/save${this.actionPrefix}`)
              .then(() => {
                this.select[this.select.length - 1] = currentItem;
                this.isLoading = false;
                this.isNewLabel = true;
                this.onChange(this.select);
                // need to close the menu
              });
          });
      }
    }
  },

  mounted() {
    if (this.submodule) {
      this.modulePath = `${this.module}/${this.submodule}`;
    } else {
      this.modulePath = `${this.module}`;
    }

    this.onLoad();
  }
};
</script>
<style scoped lang="stylus"></style>
