<template lang="pug">
  div()
    .theForm.mb-6
      v-btn.mx-2(small color="primary" outlined @click="cancel") Cancel
      v-btn.mx-2(small color="primary" @click="save") Save
    v-card
      v-container
        v-layout(row wrap)
          v-flex(xs12)
            v-text-field(v-model="name"  hint="Enter name of the Name" :persistent-hint="true"  label="Label Name*"  required)
          v-flex(xs12)
            v-textarea(v-model="url"  hint="Paste the URL Link" :persistent-hint="true"  label="URL Link*"  required)

</template>
<script>


import Repository from "@/repository";

export default {
  data: () => ({
    name:null,
    url:null,

  }),
  computed: {

  },

  methods: {
    cancel: function() {
      this.$store.dispatch("settings/tagSettings/loadAllTags")
      this.$router.push({ name: "customDashboardSettings" }).catch(()=>{});
    },

    save: function() {

      if (this.$store.state.profile.selectedCustomDashboardIndex == -1){
        this.$store.dispatch("profile/addCustomDashboard",{name:this.name,url:this.url}).then(()=>{
          this.$router.push({ name: "customDashboardSettings" }).catch(()=>{});
        })
      }else{
        this.$store.dispatch("profile/saveCustomDashboard",{name:this.name,url:this.url}).then(()=>{
          this.$router.push({ name: "customDashboardSettings" }).catch(()=>{});
        })
      }






    }
  },

  mounted() {
    if (this.$store.state.profile.selectedCustomDashboardIndex > -1){
      this.url=this.$store.getters["profile/getSelectedCustomDashboard"].url;
      this.name = this.$store.getters["profile/getSelectedCustomDashboard"].name;
    }
  }
};
</script>
<style scoped lang="stylus"></style>
