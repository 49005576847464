ghghhghh gjhjkgkhjhjg jghjhhh bkjghjg ghghj jghh ghkjgh ghhk kghhj hjjgh hhgjj jhj  bhghghjghhg jjhhh hhjhjh jhjhj  hjh hjhjhjh <template lang="pug">
  div
    // Tabs Selector
    .white.pt-2
      v-tabs.tabs(left height='32' slider-color='primary'  v-model="active")



        v-tab.tabs(@click.prevent="onClick")
          | Vehicle Report
        v-tab-item
          VehicleReport()

        v-tab.tabs(@click.prevent="onClick")
          | Scheduled Reports
        v-tab-item
          ScheduledReport()

        v-tab.tabs(@click.prevent="onClick")
          | Advanced Reports
        v-tab-item
          AdvancedReport()

        v-tab.tabs(@click.prevent="onClick")
          | Repository Archive
        v-tab-item
          RepositoryListPage()




</template>

<script>
import VehicleReport from "@/modules/reports/layouts/VehicleReport";
import AdvancedReport from "@/modules/reports/layouts/AdvancedReport";
import ScheduledReport from "@/modules/reports/layouts/scheduled/ScheduledReport";
import RepositoryListPage from "@/modules/repository/layout/RepositoryListPage.vue";
export default {
  components: {
    ScheduledReport,
    VehicleReport,
    AdvancedReport,
    RepositoryListPage
  },

  computed: {
    active: {
      get: function() {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          tabIndex = tabIndex > 3 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function(value) {
        console.log(value);
        this.$route.params.tabID = value;

        // this.$router.push({ name: "reports", params: { tabID: 1 } }).catch(()=>{});
      }
    }
  },

  methods: {
    onClick: function() {
      console.log("clicked me");
      // this.$route.params.tabID = 1;
    }
  }
};
</script>

<style scoped></style>
