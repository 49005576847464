/*
  Default Setup Properties for the workflow module.

*/
import {uuid} from "vue-uuid";

const SET_PATH = "inspection/formDesigner/setCurrentProperty";
const SET_VALUE_PATH = "inspection/formDesigner/setValueCurrentProperty";
const GET_PATH = "inspection/formDesigner/getCurrentProperty";
const SET_ARRAY_ITEM = "inspection/formDesigner/setCurrentPropertyArrayItem";

const GET_SCHEMA_VALUE = "inspection/formDesigner/getSchemaValue";
const SET_SCHEMA_VALUE = "inspection/formDesigner/setSchemaValue";

let defaultTextProperties = function () {
    return {
        name: "TextField",
        id: uuid.v4(),
        value: null,
        label: null,
        hint: null,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        },
        comment_on_fail: true,
        required: true,
        max_length: null,
        type: "text" // can either be "text" | "decimal" | "number" | "email" | "weblink"
    };
};

let defaultSwitchProperties = function () {
    return {
        name: "Switch",
        id: uuid.v4(),
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        },
        value: null,
        label: null,
        hint: null,
        type: "pass_fail",
        options: ["Pass", "Fail"],
        comment_on_fail: true,
        required: true
    };

};

let defaultHeaderProperties = function () {
    return {
        name: "Header",
        id: "$_" + uuid.v4(),
        value: null,
        label: "Heading 1",
        default:null,
        is_subheader: false,
        is_full_width: true
    };
};

let defaultDateProperties = function () {
    return {
        name: "Date",
        id: uuid.v4(),
        value: null,
        label: null,
        hint: null,
        date_format: "D/MM/YYYY",
        required: true,
        comment_on_fail: true,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        }
    };
};

let defaultTimeProperties = function () {
    return {
        name: "Time",
        id: uuid.v4(),
        value: null,
        label: null,
        hint: null,
        time_format: "ampm", // either 'ampm' or '24hr'
        required: true,
        comment_on_fail: true,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        }
    };
};


let defaultDateTimeProperties = function () {
    return {
        name: "DateTime",
        id: uuid.v4(),
        value: null,
        label: null,
        hint: null,
        time_format: "ampm", // either 'ampm' or '24hr'
        required: true,
        comment_on_fail: true,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        }
    };
};


let defaultTextAreaProperties = function () {
    return {
        name: "TextArea",
        id: uuid.v4(),
        value: null,
        label: null,
        hint: null,
        required: true,
        comment_on_fail: true,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        },
        max_length: null,
        auto_grow: false,
        is_full_width: false
    };
};


let defaultRadioProperties = function () {
    return {
        name: "Radio",
        id: uuid.v4(),
        value: null,
        label: null,
        hint: null,
        orientation: "column",
        options: ["Rock", "Paper", "Scissors"],
        required: true,
        comment_on_fail: true,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        },
    };
};

let defaultCheckboxProperties = function () {
    return {
        name: "Checkbox",
        id: uuid.v4(),
        value: [],
        label: null,
        hint: null,
        orientation: "column",
        options: ["check A", "check B"],
        required: true,
        comment_on_fail: true,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        },
    };
};
let defaultSelectProperties = function () {
    return {
        name: "Select",
        id: uuid.v4(),
        value: null,
        label: null,
        api: "http://localhost:4000/api/vehicle/",
        hint: null,
        options: ["small", "medium", "large"], // other options [{text:"option A", value:4},{text:"option B", value:24}]
        item_text: ["name", "id"], // set to: "text"
        item_value: "id", // set to: "value"
        type: "Simple", // type are: Simple or Table or API
        multiple: false,
        required: true,
        comment_on_fail: true,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        },
    };
};

let defaultTableProperties = function () {
    return {
        name: "Table",
        id: uuid.v4(),
        label: null,
        hint: null,
        max_rows: 20,
        required: false,
        comment_on_fail: true,
        vertical_layout:false,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        },
        columns: [
            { id: "first_column", label: "First Column", type: "TextField",align:"left",default:"sample data 1",details:{}},
            { id: "second_column", label: "Second Column", type: "TextField",align:"left",default:"sample data 2",details:{} },
            { id: "third_column", label: "Third Column", type: "TextField",align:"left",default:"sample data 3",details:{} },
        ],
        value: null,
        is_full_width: true
    };
};

let defaultAttachProperties = function () {
    return {
        name: "Attach",
        id: uuid.v4(),
        value: null,
        label: null,
        hint: "Upload Attachment",
        type: "any",  //FileType.custom, FileType.any, FileType.audio, FileType.video, FileType.media, FileType.image, document
        preview_size: "medium", //small, medium, large
        multiple: false,
        is_full_width: true,
        required: true,
        comment_on_fail: true,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        },
    };
};


let defaultImageProperties = function () {
    return {
        name: "Image",
        id: uuid.v4(),
        value: null,
        label: null,
        hint: "Image",
        type: "image",
        image_size: "small", //small, medium, large
        multiple: false,
        is_full_width: true,
        required: true,
        comment_on_fail: true,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        },
    };
};

let defaultDrawProperties = function() {
    return {
        name: "Draw",
        id: uuid.v4(),
        value: null,
        label: null,
        hint: "Draw",
        required: true,
        image_size: "medium",
        comment_on_fail: false,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        }
    };
};

let defaultSignatureProperties = function () {
    return {
        name: "Signature",
        id: uuid.v4(),
        value: null,
        label: null,
        hint: "Draw your signature",
        required: true,
        comment_on_fail: false,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        },
    };
};

let defaultMapProperties = function () {
    return {
        name: "Map",
        id: uuid.v4(),
        value: null,
        label: null,
        hint: "Select Location",
        required: true,
        comment_on_fail: false,
        default: {
            valid: false,
            passed: false,
            value: null,
            comments: [],
            blobs: []
        },
    };
};


let defaultRouteIDProperties = function () {
    return {
        name: "RouteID",
        id: "_route_id",
        value: null,
        default: null,
    };
};
let defaultRouteNameProperties = function () {
    return {
        name: "RouteName",
        id: "route_name",
        value: null,
        default: null,
    };
};

let defaultPlaceIDProperties = function () {
    return {
        name: "PlaceID",
        id: "_place_id",
        value: null,
        default: null,
    };
};

let defaultPlaceNameProperties = function () {
    return {
        name: "PlaceName",
        id: "place_name",
        value: null,
        default: null,
    };
};


export default {

    SET_PATH: SET_PATH,
    GET_PATH: GET_PATH,
    SET_VALUE_PATH: SET_VALUE_PATH,
    GET_SCHEMA_VALUE,
    SET_SCHEMA_VALUE,
    SET_ARRAY_ITEM,

    default_text_properties: defaultTextProperties,
    default_header_properties: defaultHeaderProperties,
    default_textarea_properties: defaultTextAreaProperties,
    default_date_properties: defaultDateProperties,
    default_time_properties: defaultTimeProperties,
    default_datetime_properties: defaultDateTimeProperties,
    default_radio_properties: defaultRadioProperties,
    default_checkbox_properties: defaultCheckboxProperties,
    default_select_properties: defaultSelectProperties,
    default_table_properties: defaultTableProperties,
    default_attach_properties: defaultAttachProperties,
    default_image_properties: defaultImageProperties,
    default_signature_properties: defaultSignatureProperties,
    default_draw_properties:defaultDrawProperties,
    default_map_properties: defaultMapProperties,
    default_switch_properties: defaultSwitchProperties,
    default_route_id_properties: defaultRouteIDProperties,
    default_route_name_properties: defaultRouteNameProperties,
    default_place_id_properties: defaultPlaceIDProperties,
    default_place_name_properties: defaultPlaceNameProperties

};
