<template lang="pug">
  #blankcontent()
    .panelTitle() Critical Faults
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-space-around.align-center.fill-height()
          .d-flex.flex-column.align-center()
            .tickerTitle.secondary--text() {{OpenCriticalFaults}}
            .tickerCaption.grey--text.text--darken-1() Open 
          .d-flex.flex-column.align-center()
            .tickerTitle.primary--text() {{PendingCriticalFaults}}
            .tickerCaption.grey--text.text--darken-1() Pending


</template>
<script>
export default {
  data: () => ({}),
  mounted() {
    
  },

  computed: {
    OpenCriticalFaults() {
      return this.$store.getters["dashboard/getOpenCriticalFaults"];
    },
    PendingCriticalFaults() {
      return this.$store.getters["dashboard/getPendingCriticalFaults"];
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.tickerTitle {
  font-size: 45px;
}

.tickerCaption {
  margin-top: -10px;
  font-size: 12px;
}
</style>
