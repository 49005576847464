<template lang="pug">
div
  v-divider
  v-container(fluid)
    v-layout.my-2(row, wrap)
      div
        h2.mx-6 Fuel Summary by Merchant
        generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer
      .text-xs-center
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color="primary", dark, v-on="on")
              | Export
              v-icon(dark) keyboard_arrow_down
          v-list-item-group
            v-list-item(
              v-for="item in items",
              :key="item.title",
              dense,
              @click="onExport(item.value)"
            )
              v-list-item-title
                v-layout(align-center)
                  v-icon.mr-2(:size="20") {{ item.icon }}
                  .subtitle-2.mx-2 {{ item.title }}
      v-btn.ma-2.mr-5(small, color="primary", dark, @click="print")
        v-icon.mr-2 print
        | Print
  v-divider
  .mx-4.my-2
    vehicle-filter-dialog
    v-menu(:close-on-content-click="false")
      template(v-slot:activator="{ on }")
        v-btn.mx-2(outlined, color="primary", v-on="on")
          | Columns
          v-icon(dark) keyboard_arrow_down
      .reportHeaderList
        v-list-item-group
          v-list(dense)
            v-list-item(v-for="(item, index) in headers", :key="index")
              template(v-slot:default="{ active }")
                v-list-item-action
                  v-checkbox(v-model="item.visible", color="primary")
                v-list-item-content(@click="item.visible = !item.visible")
                  v-list-item-title.body-2 {{ item.text }}

  v-divider
  .grey.lighten-4.py-3
  v-divider
  div(v-if="dataItems.length > 0")
    v-data-table.elevation-1(
      :items-per-page="2",
      :headers="getHeaders",
      :items="dataItems",
      item-key="id"
    )

  export-data-dialog(
    :fileExport="fileExport",
    :isFinished="isDownloadFinished",
    v-bind:show="showExportDialog",
    @closeExportDialog="onCloseExportDialog",
    @download="onDownload"
  )
</template>

<script>
import VehicleFilterDialog from "@/modules/global/components/dialog/VehicleFilterDialog";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import Repository from "@/repository";
import {
  downloadFile,
  startDownload
} from "@/modules/reports/store/reportUtility";

export default {
  components: {
    GenericBreadCrumbs,
    VehicleFilterDialog,
    ExportDataDialog
  },
  data: () => ({
    isDownloadFinished: false,
    showExportDialog: false,
    fileExport: "",
    breadCrumbsItems: [
      {
        text: "Report",
        disabled: false,
        path: "reports"
      },
      {
        text: "Fuel Summary by Location",
        disabled: true,
        path: "fuelSummarybyLocation"
      }
    ],
    dialog: true,
    items: [
      {
        title: "Export CSV",
        icon: "cloud_download",
        value: "export_csv"
      },
      {
        title: "Export Excel",
        icon: "cloud_download",
        value: "export_excel"
      }
    ],
    search: "",

    headers: [
      { text: "Fuel Vendor", align: "left", value: "name", visible: true },
      {
        text: "Transactions",
        align: "left",
        value: "transactions",
        visible: true
      },
      {
        text: "Volume (L)",
        align: "left",
        value: "total_volume",
        visible: true
      },
      { text: "Cost", align: "left", value: "total_price", visible: true }
    ],

    dataItems: []
  }),

  computed: {
    getHeaders: function() {
      let h = [];
      this.headers.forEach(item => {
        if (item.visible == true) {
          h.push(item);
        }
      });
      return h;
    }
  },
  methods: {
    onExport: function(value) {
      switch (value) {
        case "export_csv":
          this.fileExport = "CSV";
          this.isDownloadFinished = downloadFile(
            "/report/CSV/fuel_merchant_summary_list",
            this.headers
          );
          break;
        case "export_excel":
          this.fileExport = "Excel";
          this.isDownloadFinished = downloadFile(
            "/report/Excel/fuel_merchant_summary_list",
            this.headers
          );
          break;
      }
      this.showExportDialog = !this.showExportDialog;
    },

    onDownload: function() {
      startDownload();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function() {
      this.showExportDialog = false;
    },
    print: function() {
      console.log("print");
    },
    groupStyle: function(value) {
      let obj = {};
      obj["border-bottom"] = "3px solid";
      obj["border-bottom-color"] = value;
      return obj;
    },

    operatorStyle: function(operator) {
      let obj = {};
      obj["border-radius"] = "50%";
      obj["color"] = "#FFFFFF";
      obj["padding"] = "3px";
      obj["margin-right"] = "5px";

      if (operator.group != null) {
        obj["background-color"] = operator.group.color;
      } else {
        obj["background-color"] = "#333333";
      }
      console.log(operator.group);
      return obj;
    }
  },

  mounted() {
    Repository.get("/report/fuelMerchantSummary").then(response => {
      this.dataItems = response.data;
    });
  }
};
</script>

<style scoped>
.reportHeaderList {
  overflow: auto;
  max-height: 400px;
  min-width: 300px;
}
</style>
