import PlaceDetailsForm from "@/modules/places/components/form/PlaceDetailsForm";
import RouteDetailsForm from "@/modules/places/components/form/RouteDetailsForm";
export const PLACE_ROUTES = [
  {
    path: "placeDetailsForm",
    name: "placeDetailsForm",
    component: PlaceDetailsForm,
  },
  {
    path: "routeDetailsForm",
    name: "routeDetailsForm",
    component: RouteDetailsForm,
  },
];
