<template lang="pug">
  div
    | yikes bad
    .d-flex.align-center()
      v-menu(
        v-model='menu'
        ref='menu'
        :close-on-content-click="false"
        :nudge-right="70"
        :return-value.sync="time"
        transition='scale-transition'
        offset-y
      )
        template(v-slot:activator='{ on }')
          v-text-field(v-model='computedTime',clearable, prepend-icon="access_time", :label="schema.label" :hint="schema.hint"  :persistent-hint="schema.hint != null" readonly v-on='on')
        v-card()
          v-time-picker(v-if="menu" :format="schema.time_format" @input="onChange" v-model='time'  )
            v-btn(flatoutlined color="primary" @click="onCancel()") Cancel
            v-spacer()
            v-btn(color="primary" @click="onSave()") Save

      add-comment-button.mx-1(v-model="comments" @showCommentInput="onShowCommentInput")
      upload-blob.mx-1(v-model="blobs" type="image" label="Label" hint="hint" :isMultiple="true")
    .d-flex()
      v-tooltip(bottom v-for="(item,index) in comments" :key="item.time")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") message
        span() {{item.value}}
      .mx-2()
      v-tooltip(bottom v-for="(item,index) in blobs" :key="item")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") image
        div()
          image-thumbnail(:width='360', :height='200', :src="item")

    div.mt-2(v-if="showCommentInput")
      v-textarea(outlined  ref="comment"  label="Comment" auto-grow rows="2" v-model="comment" hide-details )
      .d-flex.my-2()
        v-spacer
        v-btn.mx-2(small color='grey darken-1', text, @click.native="cancel()") Close
        v-btn.mx-2(small color='primary', @click.native="save()") Save

</template>
<script>
import moment from "moment";
import {inspectionFillMixin} from "@/modules/inspections/components/formDesigner/fill/inspectionFillMixin";


export default {
  mixins: [inspectionFillMixin],


  data: () => ({
    menu: false,
    time: null,
    theTime:null,
  }),

  computed: {
    computedTime: {
      get: function() {
        let val;
        val = this.theTime;
        if (val == null) {
          if(this.schema.required){
            val = moment().format("HH:mm");
            this.onSetTime(val)
          }else{
            return null;
          }
        }


        if (this.schema.time_format === "ampm") {
          return moment(val, "HH:mm").format("LT");
        } else {
          return moment(val, "HH:mm").format("HH:mm");
        }
      },
      set: function(theValue) {
        this.theTime = theValue;
        console.log("TEA TIME =================")
        console.log(theValue)
        this.theValue = theValue;
        this.update();
      }
    }
  },

  methods: {
    onSetTime: function(value) {
      this.time = value;
    },

    onChange: function(value) {
      this.time = value;
    },

    onSave: function() {
      this.computedTime = this.time;
      this.menu = false;
    },

    onCancel: function() {
      this.menu = false;
    }
  },

  mounted() {
      this.theTime = JSON.parse(JSON.stringify(this.dataValue.value));
    if(this.schema.required == true && this.theTime == null){
      this.theValue  = this.computedTime;
      this.update();
    }
  }
};
</script>
<style scoped lang="stylus"></style>
