<template lang="pug">
  div(v-if="this.$store.state.inventory.item.currentIndex > -1")
    v-data-table.elevation-1(
      :headers='headers',
      :search="search",
      :items='getData',
      :custom-filter="customerModuleFilter"
      item-key="id"
      :single-select="false",
      :items-per-page="footerProps.pagnation.itemsPerPage",
      :footer-props="footerProps"
      show-select
    )
      v-progress-linear(slot='progress', height='2', :indeterminate='loading')

      template(v-slot:item.name="{item}" )
        .d-flex.py-2.itemName(@click="onClickRow(item)")
          image-thumbnail.mr-2(v-if="item.thumbnail_id" :src="item.thumbnail_id" :height="60" :width="80")
          .d-flex.flex-column.justify-center()
            .subtitle-1.primary--text {{ item.name }}
            div.grey--text.descriptionText() {{item.description}}


      template(v-slot:item.inventory_type="{item}")
        div() {{ getInventory(item.inventory_type) }}

      template(v-slot:item.merchant="{item}")
        div(v-if="item.merchant")
          | {{ item.merchant.name }}
          .caption.grey--text {{item.merchant.name}}

      template(v-slot:item.balance="{item}")
        div() {{ item.balance }} &nbsp;
          span.caption.grey--text(v-html="item.measurement_unit != null ? item.measurement_unit.name :'units' ")

      template(v-slot:item.shelf_details="{item}")
        div(v-if="item.shelf_details")
          div.grey--text {{ item.unit_cost }}/{{ item.itemDetails.row }}/{{ item.itemDetails.bin }}


      template(v-slot:item.duration="{item}")
        .d-flex(v-if="item.duration")
          template(v-if="item.duration.Microseconds > 0")
            .caption.grey--text {{Math.round(item.duration.Microseconds/3.6e+9)}} Hours &nbsp;
          template(v-if="item.duration.Days> 0")
            .caption.grey--text {{item.duration.Days}} Days &nbsp;
          template(v-if="item.duration.Months> 0")
            .caption.grey--text {{item.duration.Months}} Months &nbsp;

      template( v-slot:item.action="{ item }")
        v-row.mr-7(justify="end")
          more-drop-down-menu(:items='menuItems', @onClick='onClick', :theObject='item')

</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import {searchMixin} from "@/mixin/searchListMixin";

export default {
  props: {
    search: String,
    selectedItems: Array,
    group: String,
  },
  mixins: [searchMixin],
  components: {
    MoreDropDownMenu,
    ImageThumbnail
  },

  data: () => ({
    footerProps: {
      "items-per-page-options": [5, 10, 20, 50, 100, 1000],
      pagnation: {itemsPerPage: 20}
    },
    isLoadingTable: false,
    groupsLoaded: false,
    loading: false,
    headers: [
      {
        text: "Name",
        value: "name"
      },
      {text: "Balance", value: "balance"},
      {text: "Type", value: "inventory_type"},
      {text: "Supplier", value: "merchant"},
      {text: "Unit Cost", value: "unit_cost"},
      {text: "Location (A/R/B)", value: "arb"},
      {text: "Duration", value: "duration"},

      {text: "Action", value: "action", align: "right"}
    ],

    menuItems: [
      {title: "Item In", icon: "add", action: "add"},
      {title: "Item Out", icon: "remove", action: "subtract"},
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ]
  }),

  computed: {
    getData: function () {
      return this.$store.getters["inventory/item/getFilterByGroup"](this.group)
    },
    selectedPlaces: {
      get: function () {
        return this.$store.getters["inventory/item/getSelectedItems"];
      },
      set: function (value) {
        this.$store.commit("inventory/item/setSelectedItems", value);
      }
    },

  },

  methods: {
    customerModuleFilter: function (value, search, item) {
      let isFound = false;
      let patt = new RegExp(search.toLowerCase(), "i");
      // Search the Object
      isFound = this.searchObject(item, patt, isFound);

      // Search Group
      isFound = this.searchObject(item.group, patt, isFound);

      return isFound;
    },

    getInventory: function (inventoryType) {
      let result = "Vehicle Part";
      switch (inventoryType) {
        case "vehicle_part":
          result = "Vehicle Part";
          break;
        case "equipment":
          result = "Equipment";
          break;
        case "consumable":
          result = "Consumable";
          break;
      }
      return result;
    },
    onClickRow: function (value) {
      this.$store.commit("inventory/reset")
      this.$store.commit("inventory/item/setCurrentIndexByID", value.id);
      this.$router.push({
        name: "inventoryOverviewLayout",
        params: {itemID: value.id, tabID: 0}
      }).catch(()=>{});
    },
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "add":
          this.$store.commit("inventory/setMode", "add")
          this.$store.commit("inventory/item/setCurrentIndexByID", value.theObject.id)
          this.$store.dispatch("inventory/addInventory", value.theObject.id).then(() => {
            let currentObject = this.$store.getters["inventory/getCurrentInventory"];
            this.$router.push({
              name: "inventoryForm",
              params: {itemID: currentObject.id}
            }).catch(()=>{});
          });
          break;
        case "subtract":
          this.$store.commit("inventory/setMode", "subtract")
          this.$store.commit("inventory/item/setCurrentIndexByID", value.theObject.id)
          this.$store.dispatch("inventory/addInventory", value.theObject.id).then(() => {
            let currentObject = this.$store.getters["inventory/getCurrentInventory"];
            this.$router.push({
              name: "inventoryForm",
              params: {itemID: currentObject.id}
            }).catch(()=>{});
          });
          break;
        case "edit":
          this.$store.commit("inventory/item/setCurrentIndexByID", value.theObject.id);
          this.$router.push({
            name: "itemDetailsForm",
            params: {itemID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          this.$store.dispatch("inventory/item/deleteItem", value.theObject.id);
          break;
      }
    },
    getGroup(groupID) {
      return (this.$store.getters["settings/groupSettings/getGroupByID"](groupID)).name
    }
  },

  mounted() {

      // load Data
      this.$store.dispatch("inventory/item/loadAllItems").then(() => {
      });

  }
};
</script>

<style lang="stylus" scoped>
.descriptionText {
  max-width: 200px;
  line-height: 13px;
  height: 28px;
  font-size: 12px;
  text-overflow ellipsis;
  overflow: hidden;
}

.itemName:hover {
  cursor pointer;
}
</style>
