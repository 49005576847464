<template lang="pug">
  svg(ref="scheduleItem" :width="width" :height="height" )
    defs()
      clipPath(id="user-space" clipPathUnits="userSpaceOnUse")
        rect(x="5" y="2" rx="2" ry="4" :width="height" :height="height-3")
    rect(ref="bgRect"  :width="width" :height="height" :rx="8"  fill="#ffffff"  stroke-width="4" stroke="#BBBBBB" )
    line(x1="4" y1="1" :x2="width-4" y2="1" stroke-width="2" :stroke="getColor")
    image(v-if="imageUrl" :xlink:href="imageUrl" :height="height+3" :width="height" :x="5" :y="0" preserveAspectRatio="xMidYMid slice" clip-path="url(#user-space)")
    g(:transform="`translate(${(imageUrl)?height:0},0)`")
      text.assignmentTitle(  x="13" y="20" fill="#333333" :width="width"  )    {{assignment.contact.first_name}} {{assignment.contact.last_name}}
      //- {{unitStart}} -  {{assignment.during.tz_start}} - {{assignment.vehicle_id}} END{{unitEnd}} - End - {{assignment.during.tz_end}}
      text.assignmentSubTitle(  x="13" y="40" fill="#666666" :width="width"  )  {{beginDateTimeF}} - {{endDateTimeF}}
    rect.moveItem(ref="bgRect"  :width="width" :height="height" :rx="8"  fill="#00ff00"   @mouseover="onMouseOver" @mousemove="onMouseMove" @mouseleave="onMouseLeave" @mousedown="assignmentPressed" fill-opacity="0" )
    rect.widthResize(:width="10" :height="height" fill="#333333" rx="5"  @mousedown="$emit('frontMouseDown',$event,assignment)" @mouseover="leftResizeOpacity=1" @mouseleave="leftResizeOpacity=0.1" :fill-opacity="leftResizeOpacity")
    rect.widthResize(:x="width-10" :width="10" :height="height" rx="5" fill="#333333" @mousedown="$emit('endMouseDown',$event,assignment)"  @mouseover="rightResizeOpacity=1" @mouseleave="rightResizeOpacity=0.1" :fill-opacity="rightResizeOpacity")
</template>
<script>
import moment from "moment";

export default {
  props: {
    width: Number,
    height: Number,
    assignment: Object,
    layerIndex: Number
  },
  data: () => ({
    isDragging: false,
    pictureOffset: 200,

    thumbnailObject: null,
    startXPos: null,
    startYPos: null,
    isMouseOver: false,

    leftResizeOpacity: 0.1,
    rightResizeOpacity: 0.1
  }),

  methods: {
    assignmentPressed: function (event) {
      this.startXPos = event.clientX;
      this.startYPos = event.clientY;

      this.$emit("selectAssignment", event, this.assignment);
    },

    onDoubleClick: function () {
      console.log("double Clicked");
    },

    getContactImage: function () {
      console.log(this.assignment.contact.thumbnail_id);
    },
    onMouseOver: function (event) {
      this.$store.commit("vehicles/assignments/setAssignment", this.assignment);
      this.$store.commit("vehicles/assignments/setTooltip", {
        visible: true,
        xPos: event.clientX,
        yPos: event.clientY
      });
    },
    onMouseMove: function (event) {
      this.$store.commit("vehicles/assignments/setTooltip", {
        visible: true,
        xPos: event.clientX,
        yPos: event.clientY
      });
    },
    onMouseLeave: function (event) {
      this.$store.commit("vehicles/assignments/setTooltip", {
        visible: false,
        xPos: event.clientX,
        yPos: event.clientY
      });
    }
  },

  computed: {
    imageUrl: function () {
      if (this.assignment.contact.thumbnail) {
        return `/media/${this.assignment.account_id}/${this.assignment.contact.thumbnail.thumbnail}`;
      } else {
        return null;
      }
    },

    unitStart: function () {
      let startDate = this.$store.getters["vehicles/assignments/getStartDate"];
      return (
          (this.assignment.during.tz_start - startDate.getTime()) /
          this.$store.getters["vehicles/assignments/getTimeSplit"]
      );
    },

    unitEnd: function () {
      let startDate = this.$store.getters["vehicles/assignments/getStartDate"];

      return (
          (this.assignment.during.tz_end - startDate.getTime()) /
          this.$store.getters["vehicles/assignments/getTimeSplit"]
      );
    },

    beginDateTimeF: function () {
      return moment(this.assignment.during.tz_start).format("ddd, h:mm a");
    },

    endDateTimeF: function () {
      return moment(this.assignment.during.tz_end).format("ddd, h:mm a ");
    },

    getColor: function () {
      // console.log(this.assignment.contact.group);
      if (this.assignment.contact.group != null) {
        return this.assignment.contact.group.color;
      } else {
        return "#333333";
      }
    }
  },

  mounted() {
  }
};
</script>
<style lang="stylus" scoped>
.assignmentTitle {
  font-family: 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
  font-weight: bold;
  user-select: none;
}

.assignmentSubTitle {
  font-family: 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 11px;
  user-select: none;
}

.widthResize {
  cursor: w-resize;
}

.moveItem {
  cursor: move;
}
</style>
