<template lang="pug">
  v-dialog(v-model="dialog" width="500")
    template(v-slot:activator="{on}")
      v-btn.ma-2(color="primary" v-on="on")
        v-icon.mr-2() filter_list
        .subtitle-2() Filter

    v-card()
      v-card-title.px-2.grey.lighten-3()
        v-layout(justify-space-between)
          v-layout(align-center)
            v-icon.mr-2() filter_list
            .subtitle-1() Filters
          div()
            v-btn.ma-2(text @click="dialog=false" outlined small) Close
              v-icon() close
      v-divider
      v-card-text()
        v-container()
          vehicles-auto-complete(v-model="vehicles_id" :multiple="true")
          v-select(v-model='vehicle_type', :items='vehicleTypes', hint='Categorize this Vehicle', :persistent-hint='true', label='Type', required)
          v-select(v-model='vehicle_status', :items='statusTypes', hint='Current Status of the Vehicle', :persistent-hint='true', label='Status')
      v-divider
      v-card-actions()
        v-layout.ma-2(justify-end)
          v-btn(color="primary" small)
            v-icon.mr-2() search
            | Search


</template>

<script>
import VehiclesAutoComplete from "@/modules/global/components/forms/VehiclesAutoComplete";
export default {
  components: {
    VehiclesAutoComplete
  },
  data: () => ({
    dialog: false,
    vehicles_id: null,
    vehicle_status: null,
    vehicle_type: null,
    vehicleTypes: [
      "ATV",
      "Bus",
      "Car",
      "Forklift",
      "Generator",
      "Loader",
      "Motorcycle",
      "Mower"
    ],
    statusTypes: ["active", "inactive", "in shop", "out of service", "sold"]
  })
};
</script>

<style scoped></style>
