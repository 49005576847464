<template lang="pug">
  v-text-field(v-model="result" :hint="element.label" :persistent-hint="true"  :label="element.label"  clearable )
</template>

<script>
export default {
  props: ["value", "element", "mutationType"],
  watch: {
    result: function (newVal) {
      this.$store.commit(this.mutationType, {path: `details.${this.element.label_id}`, value: newVal})
    }
  },
  data: () => ({
    result: null,
  }),
  methods: {},
  mounted() {
    this.result = this.value;
  }
}
</script>

<style scoped>

</style>