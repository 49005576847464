v,.m,mn,mvbn,m,m,m,m, mnm,.nm,nmb,nmn,,nm,jckhkfdgjkjkjhlk
khjgkhjhkljgkjklfdgjkdjkdjhkjkljkfjdkhj hjkjhkj khjkhjkjj
<template lang="pug">
  div
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        div()
          h2.mx-6 Total Cost Trend
          generic-bread-crumbs.py-1(:items="breadCrumbsItems")
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(sbnmall, outlined, color='primary', dark, v-on="on")
                | Export
                v-icon(dark) keyboard_arrow_down
            v-list-item-group()
              v-list-item(v-for='item in items', :key='item.title' dense @click="onExport(item.value)")
                v-list-item-title
                  v-layout(align-center)
                    v-icon.mr-2(:size="20") {{item.icon}}
                    .subtitle-2.mx-2() {{ item.title }}
        v-btn.ma-2.mr-5(small, color='primary', dark, @click='print')
          v-icon.mr-2() print
          | Print
    v-divider
    div.mx-4.my-2()
      vehicle-filter-dialog()


    v-divider
    .grey.lighten-4.py-3
    v-divider
    div.ma-6()
      table.costTrendTable.grey--text.text--darken-2(cellspacing="0" cellpadding="0")
        tr.grey.lighten-4
          th(style="width:26%") Vehicle
          th(style="width:12%") {{ getMoment().subtract(5,"month").format("MMM 'YY")}}
          th(style="width:12%") {{ getMoment().subtract(4,"month").format("MMM 'YY")}}
          th(style="width:12%") {{ getMoment().subtract(3,"month").format("MMM 'YY")}}
          th(style="width:12%") {{ getMoment().subtract(2,"month").format("MMM 'YY")}}
          th(style="width:12%") {{ getMoment().subtract(1,"month").format("MMM 'YY")}}
          th(style="width:12%") {{ getMoment().format("MMM 'YY")}}

        tr(v-for="(vehicle,index) in getVehicleArray" :key="index" :style="index%2==0?{}:{'background-color':'#F2F2F2'}")
          td {{vehicle.name}}
          td Ksh. 1
          td Ksh. 2
          td Ksh. 3
          td Ksh. 4
          td Ksh. 5
          td Ksh. 6
      

    export-data-dialog(:fileExport="fileExport" :isFinished="isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="onCloseExportDialog" @download="onDownload" )
</template>

<script>
import VehicleFilterDialog from "@/modules/global/components/dialog/VehicleFilterDialog";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import Repository from "@/repository";
import moment from "moment";

export default {
  components: {
    GenericBreadCrumbs,
    VehicleFilterDialog,
    ExportDataDialog,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,

    isDownloadFinished: false,
    fileLink: null,
    showExportDialog: false,
    fileExport: "",
    breadCrumbsItems: [
      {
        text: "Report",
        disabled: false,
        path: "reports",
      },
      {
        text: "Total Cost Trend",
        disabled: true,
        path: "totalCostTrend",
      },
    ],
    dialog: true,
    items: [
      {
        title: "Export CSV",
        icon: "cloud_download",
        value: "export_csv",
      },
      {
        title: "Export Excel",
        icon: "cloud_download",
        value: "export_excel",
      },
    ],
    search: "",

    headers: [
      { text: "Vehicle", align: "left", value: "", visible: true },
      {
        text: "Service Costs(Filtered)",
        align: "left",
        value: "",
        visible: true,
      },
      { text: "Fuel Costs(Filtered)", align: "left", value: "", visible: true },
      {
        text: "Other Costs(Filtered)",
        align: "left",
        value: "",
        visible: true,
      },
      {
        text: "Total Costs(Filtered)",
        align: "left",
        value: "",
        visible: true,
      },
      { text: "Cost/Meter(Filtered)", align: "left", value: "", visible: true },
    ],

    dataItems: [{}],
  }),

  computed: {
    getVehicleArray: function() {
      let tempArray = [];
      let beginIndex = (this.page - 1) * this.itemsPerPage;
      let endIndex = beginIndex + this.itemsPerPage;
      for (var i = beginIndex; i < endIndex; i++) {
        if (i < this.$store.state.vehicles.all.length) {
          tempArray.push(this.$store.state.vehicles.all[i]);
        }
      }
      return tempArray;
    },

    getHeaders: function() {
      let h = [];
      this.headers.forEach((item) => {
        if (item.visible == true) {
          h.push(item);
        }
      });
      return h;
    },
  },
  methods: {
    getMoment: function() {
      return moment(new Date());
    },

    onExport: function(value) {
      switch (value) {
        case "export_csv":
          this.fileExport = "CSV";
          this.downloadFile("/report/CSV/vehicle_list");
          break;
        case "export_excel":
          this.fileExport = "Excel";
          this.downloadFile("/report/Excel/vehicle_list");
          break;
      }
      this.showExportDialog = !this.showExportDialog;
    },

    downloadFile: function(url) {
      this.isDownloadFinished = false;

      Repository({
        method: "POST",
        url: url,
        data: this.headers.filter((item) => item.visible == true),
        responseType: "blob",
      }).then((response) => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function() {
      // download the file
      this.fileLink.click();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function() {
      this.showExportDialog = false;
    },
    print: function() {
      console.log("print");
    },
    groupStyle: function(value) {
      let obj = {};
      obj["border-bottom"] = "3px solid";
      obj["border-bottom-color"] = value;
      return obj;
    },

    operatorStyle: function(operator) {
      let obj = {};
      obj["border-radius"] = "50%";
      obj["color"] = "#FFFFFF";
      obj["padding"] = "3px";
      obj["margin-right"] = "5px";

      if (operator.group != null) {
        obj["background-color"] = operator.group.color;
      } else {
        obj["background-color"] = "#333333";
      }
      console.log(operator.group);
      return obj;
    },
  },

  mounted() {
    if (this.$store.state.vehicles.all.length == 0) {
      this.$store.dispatch("vehicles/loadAllItems");
    }
  },
};
</script>

<style scoped>
.reportHeaderList {
  overflow: auto;
  max-height: 400px;
  min-width: 300px;
}

.costTrendTable th {
  text-align: left;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.costTrendTable {
  width: 100%;
  border: 1px solid #cccccc;
}

.costTrendTable td {
  padding: 13px 16px;
  border-right: 1px solid #cccccc;
}
</style>
