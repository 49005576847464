<template lang="pug">
  div

    v-container.pt-0(grid-list-sm)
      form-divider DETAILS
      v-layout(row, wrap, justify-start)
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='name', hint='Enter a nickname to distinguish this vehicle', :persistent-hint='true', label='Vehicle Name*', required)
        v-flex(xs12, md6, xl4)
          v-layout(row)
            v-text-field(v-model='vin', hint='Vehicle Identification Number', :persistent-hint='true', label='VIN/SIN')
            decode-v-i-n(v-if='vin', :item="$store.getters['vehicles/getCurrentItem']")
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='numberplate', label='Number Plate', hint='Vehicle Registration Plate Issued', :persistent-hint='true', required)
        v-flex(xs12, md6, xl4)
          v-select(v-model='type', :items='vehicleType', hint='Categorize this Vehicle', :persistent-hint='true', label='Type*', required)
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='year', hint='eg. 2012,2013 etc.', :persistent-hint='true', label='Year')
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='make', hint='eg. Toyota, Mercedes, Volvo', :persistent-hint='true', label='Make')
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='model', label='Model', hint='eg. Corolla, Actros, Fuso, FRR', :persistent-hint='true')
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='trim', label='Trim', hint='eg. XE, SE, XLE', :persistent-hint='true')
        v-flex(xs12, md6, xl4)
          tags-auto-complete(v-model="tags_id")
        v-flex(xs12, md12, xl12)
      form-divider CLASSIFICATION
      v-layout(row, wrap, justify-start)
        v-flex(xs12, md6, xl4)
          v-select(v-model='status', :items='statusTypes', hint='Current Status of the Vehicle', :persistent-hint='true', label='Status*' item-text="name" item-value="value")
        v-flex(xs12, md6, xl4)
          v-select(:items='$store.state.settings.groupSettings.group_data', item-text='name', item-value='id', @focus='onGroupSelectFocus', prepend-icon='group', v-model='group_id', label='Group', data-vv-name='select')
        v-flex(xs12, md6, xl4)
          v-select(v-model='ownership', :items='ownershipTypes', label='Ownership*')
      form-divider ADDITIONAL DETAILS
      v-layout(row, wrap, justify-start)
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='color', label='Color', hint='Body Color', :persistent-hint='true')
        v-flex(xs12, md6, xl4)
          v-select(v-model='body', :items='bodyTypes', hint='Body type (Lorry, Pickup, Sedan, Station Wagon etc...)', :persistent-hint='true', label='Body Type')
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='bodySubType', label='Body Sub Type', hint='Body subtype (Extended Cab, Crew Cab, etc...)', :persistent-hint='true')
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='msrp', label='MSRP', hint='Manufacturer suggested retail price.', type='number', :persistent-hint='true')
      form-divider PHOTOS
      v-layout(row, wrap, justify-start)
        v-flex(xs12)
          attach-fill-form( :schema="imagesSchama" :data="{value:images}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" :moreMenu="additionalImageMenuItems")
      form-divider DOCUMENTS
      v-layout(row, wrap, justify-start)
        v-flex(xs12)
          attach-fill-form( :schema="documentsSchama" :data="{value:documents}" @onChange="saveDocuments"  @onMenuAction="onMenuActionDocuments")

</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import {createHelpers} from "vuex-map-fields";

import DecodeVIN from "@/modules/vehicles/components/ui/DecodeVIN";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
import TagsAutoComplete from "@/modules/global/components/forms/TagsAutoComplete.vue";

const {mapFields} = createHelpers({
  getterType: "vehicles/getItem",
  mutationType: "vehicles/updateItem"
});

export default {
  components: {
    AttachFillForm,
    FormDivider,
    DecodeVIN,
    TagsAutoComplete
  },

  data: () => ({
    vehicleData: {},
    bodyTypes: [
      "Prime Mover",
      "Heavy Truck",
      "Light Truck",
      "Bus",
      "Pickup",
      "Sedan",
      "Station Wagon",
      "SUV"
    ],
    bodySubTypes: ["Extended Cab", "Crew Cab"],
    ownershipTypes: ["owned", "leased", "rented", "customer"],
    GroupTypes: ["FleetA", "FleetB"],
    statusTypes: [
      {
        name: "Active",
        value: "active"
      },
      {
        name: "In Active",
        value: "in_active"
      },
      {
        name: "In Shop",
        value: "in_shop"
      },
      {
        name: "Out of Service",
        value: "out_of_service"
      },
      {
        name: "Sold",
        value: "sold"
      }
    ],
    vehicleTypes: [
      "ATV",
      "Bus",
      "Car",
      "Forklift",
      "Generator",
      "Loader",
      "Motorcycle",
      "Mower"
    ],
    makeTypes: ["Alfa Romeo", "BMW", "Buick", "Toyota", "Subaru"],
    vehicleType: ["Commercial Vehicle", "Passenger Car"],
    // green, blue, orange, red, gray

    // Image Manipulation Data
    rowsPerPageItems: [5, 10, 15],
    pagination: {
      rowsPerPage: 5
    },

    imagesSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Vehicle Images",
      hint: null,
      type: "image",
      image_size: "small",
      multiple: true
    },
    additionalImageMenuItems: [
      {title: "Make Thumbnail", icon: "edit", action: "make_thumbnail"}
    ],

    documentsSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Vehicle Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true
    }
  }),

  computed: {
    ...mapFields({
      name: "name",
      type: "type",
      thumbnail: "thumbnail_id",
      images: "images",
      documents: "documents",
      status: "status",
      ownership: "ownership",

      numberplate: "number_plate",
      vin: "vin",
      year: "year",
      make: "make",
      model: "model",
      group_id: "group_id",
      trim: "series",
      color: "color",
      body: "body",
      bodySubType: "body_sub_type",
      msrp: "msrp",
      tags_id:"tags_id"
    })
  },

  methods: {
    saveImages: function (theObject) {
      // save image if there is no thumnail_id and there is no images
      if (
          this.thumbnail == null &&
          this.images == null &&
          theObject.value.length > 0
      ) {
        this.$store.commit("vehicles/updateItem", {
          path: "thumbnail_id",
          value: theObject.value[0]
        });
      }

      // save the images uploaded
      this.$store.commit("vehicles/updateItem", {
        path: "images",
        value: theObject.value
      });
    },

    saveDocuments: function (theObject) {
      this.$store.commit("vehicles/updateItem", {
        path: "documents",
        value: theObject.value
      });
    },

    onMenuActionImages: function (value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("vehicles/deleteImage", value.theObject.id);
          break;
        case "make_thumbnail":
          this.$store.commit("vehicles/makeThumbnail", value.theObject.id);
          break;
      }
    },

    onMenuActionDocuments: function (value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("vehicles/deleteDocument", value.theObject.id);
          break;
      }
    },

    //load groups
    onGroupSelectFocus: function () {
      // Load The group List
      if (this.$store.state.settings.groupSettings.currentIndex == -1) {
        this.$store.dispatch("settings/groupSettings/loadAllGroups");
      }
    }
  },

  mounted() {
    // console.log(`==========${this.$store.state.vehicles.currentIndex}`);
    // this.$store.dispatch("uploads/getImagesByVehicle", {
    //   id: this.$route.params.vehicleID
    // });
  }
};
</script>

<style lang="stylus" scoped>
.topiDivider {
  color: #aa00cc;
  border-bottom: 2px solid #aa00cc;
  margin-top: 20px;
  font-size: 18px;
}

.uploadImage {
  display: flex;
  flex-direction: column;
}
</style>
