<template lang="pug">
v-item-group
  v-item
    v-chip(:selected="active", @click.native="toggle", small, slot-scope="{ active, toggle }", :color="active ? 'primary white--text elevation-0': 'grey lighten-2 primary--text'") Open
  v-item
    v-chip(:selected="active", @click.native="toggle", small, slot-scope="{ active, toggle }", :color="active ? 'primary white--text elevation-0': 'grey lighten-2 primary--text'") Overdue
  v-item
    v-chip(:selected="active", @click.native="toggle", small, slot-scope="{ active, toggle }", :color="active ? 'primary white--text elevation-0': 'grey lighten-2 primary--text'") Resolved
</template>
<script>
export default {
  data: () => ({})
};
</script>
<style scoped lang="stylus"></style>
