<template lang="pug">
div
  v-layout(align-center justify-end)
    v-menu(v-if="selected.length > 0" offset-y)
      template(v-slot:activator="{ on }")
        v-btn.mx-2(small, outlined, color='primary', dark, v-on="on")
          | Actions
          v-icon(dark) keyboard_arrow_down
      v-list(dense )
        v-list-item-group(color="primary" )
          v-list-item(v-for='item in menuActions', :key='item.title'  @click="onClick(item)")
            v-list-item-title {{ item.title }}

    v-btn.ma-2(small='', color='primary', dark='', @click='addNew') Add New
  form-sub-header Expense Type Settings
  v-data-table(v-model="selected" :headers='headers', :items='$store.state.settings.expenseTypeSettings.expensetype_data', item-key="id", :loading='loading', :single-select="false" show-select)
    v-progress-linear(slot='progress', height='2', :indeterminate='loading')
    template( v-slot:item.action="{ item }")
      v-row.mr-7(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')
</template>
<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";

export default {
  components: {
    FormSubHeader,
    MoreDropDownMenu
  },

  data: () => ({
    selected: [],
    loading: false,

    menuActions: [
      {
        title: "Unselect All",
        icon: "edit",
        action: "unselect_all"
      },
      {
        title: "Delete Selected",
        icon: "delete",
        action: "delete_selected"
      }
    ],
    headers: [
      { text: "Name", value: "name", align: "left" },
      { text: "Description", value: "description", align: "left" },
      { text: "Actions", value: "action", align: "right" }
    ],

    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" }
    ]
  }),

  methods: {
    addNew: function() {
      console.log("add New");
      this.$store
        .dispatch("settings/expenseTypeSettings/addExpenseType")
        .then(() => {
          // console.log(this.$store.getters["settings/tagSettings/getCurrentTag"].id)
          this.$router.push({
            name: "expenseTypeForm",
            params: {
              expenseTypeID: this.$store.getters[
                "settings/expenseTypeSettings/getCurrentExpenseType"
              ].id
            }
          }).catch(()=>{});
        });
    },

    onClick: function(value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          this.$router.push({
            name: "expenseTypeForm",
            params: { expenseTypeID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          this.$store.dispatch(
            "settings/expenseTypeSettings/deleteExpenseType",
            value.theObject.id
          );
          break;
        case "unselect_all":
          this.selected = [];
          break;
        case "delete_selected":
          this.$store.dispatch(
            "settings/expenseTypeSettings/deleteExpenseTypeList",
            this.selected
          );
          break;
      }
    }
  },

  mounted() {
    console.log(new Date().toISOString());
    if (this.$store.state.settings.expenseTypeSettings.currentIndex == -1) {
      this.$store.dispatch("settings/expenseTypeSettings/loadAllExpenseTypes");
    }
  }
};
</script>
<style scoped lang="stylus">
.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;

  padding: 0px;
}
</style>
