<template lang="pug">
v-card()
  v-card-title.pl-8.grey.lighten-1.py-8(style="width:500px;")
    .span(v-if="selectedMerchants.length > 0") {{selectedMerchants.length}} &nbsp;&nbsp;
    .span() Merchants Selected
    v-spacer()
    v-btn.mx-2(outlined small @click="$emit('close')") Cancel
    v-btn(color="primary" small  @click="onSave") Save

  div.mt-2()
    div.grey.lighten-2()
      v-row.mx-1()
        v-col(cols="6")
          v-menu(
            ref="startMenu"
            v-model="startMenu"
            :close-on-content-click="false"
            :return-value.sync="startDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          )
            template( v-slot:activator="{ on, attrs }")
              v-text-field.pt-4.mt-2(
                v-model="startDateFormatted"
                label="Start Date"
                prepend-icon="event"
                readonly
                v-bind="attrs"
                v-on="on"
                :hide-details="true"
              )
            v-date-picker(v-model="startDate"
              no-title
              scrollable
              :max="getMaxNowDate"
              @input="$refs.startMenu.save(startDate)"
            )
        v-col(cols="6")
          v-menu(
            ref="endMenu"
            v-model="endMenu"
            :close-on-content-click="false"
            :return-value.sync="endDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :hide-details="true"
          )
            template( v-slot:activator="{ on, attrs }")
              v-text-field.pt-4.mt-2(
                v-model="endDateFormatted"
                label="End Date"
                prepend-icon="event"
                readonly
                v-bind="attrs"
                v-on="on"
              )
            v-date-picker(v-model="endDate"
              no-title
              scrollable
              @input="$refs.endMenu.save(endDate)"
              :max="getMaxNowDate"
            )

  div(style="position:relative;overflow-y:scroll; max-height:500px")
    v-data-table(
      :headers="headers"
      :items="$store.state.merchant.merchant_data"
      item-key="id"
      :single-select="false"
      show-select
      v-model="selectedMerchants"
      :options="dataTableOptions"
      hide-default-footer
      @click:row="onClickRow"
      :sort-by="['name']"
      :sort-desc="[false]"
     )
     template(v-slot:item.name="{item}")
       merchant-column-by-id(:merchant_id="item.id")

</template>
<script>

import MerchantColumnById from "@/modules/global/components/table/MerchantColumnById.vue";
import moment from "moment";
export default {

  components:{
    MerchantColumnById
  },
  props:{
    subtractUnit:{
      type:String,
      default: "week"
    },
    subtractAmount: {
      type: Number,
      default: 1
    }
  },

  data: ()=> ({
    startMenu: false,
    startDate: moment().subtract(1,"week").format("YYYY-MM-DD"),

    endMenu: false,
    endDate: moment().format("YYYY-MM-DD"),

    dataTableOptions: {
      itemsPerPage: 1000,
    },
    selectedMerchants:[],
    headers: [
      {
        text: "Merchant",
        value: "name",
        align: "left"
      },
    ]
  }),
  computed:{
    getMaxNowDate() {
      return moment().format("YYYY-MM-DD");
    },

    getSortedMerchantData(){
      return this.$store.getters["merchant/getSortedMerchants"]
    },

    startDateFormatted: {
      get: function(){
        return moment(this.startDate,'YYYY-MM-DD').format("Do MMM YYYY")
      }
    },

    endDateFormatted: {
      get: function(){
        return moment(this.endDate,'YYYY-MM-DD').format("Do MMM YYYY")
      }
    },

  },
  methods:{


    onClickRow: function(item){
      let theIndex = this.selectedMerchants.findIndex(merchant => merchant.id === item.id)

      if (theIndex > -1) {
        this.selectedMerchants.splice(theIndex, 1);
      }else{
        this.selectedMerchants.push(item);
      }
    },
    onClear:function(){
      this.selectedMerchants = [];
      this.$emit("close")
    },

    onCancel:function(){
      this.selectedMerchants = [];
      this.$emit("close")
    },
    onSave:function(){
      this.$emit("save", {"selectedMerchants":this.selectedMerchants,"from":this.startDate,"to":this.endDate});

    }
  },
  mounted() {
    this.selectedMerchants = [];
    this.startDate = moment().subtract(this.subtractAmount,this.subtractUnit).format("YYYY-MM-DD");
    if(this.$store.state.merchant.merchant_data.length  <2){
      this.$store.dispatch("merchant/loadAllMerchants").then(()=>{
        this.$store.state.merchant.merchant_data.forEach(merchant => {
          this.selectedMerchants.push(merchant);
        })
      });
    }else{
      this.$store.state.merchant.merchant_data.forEach(merchant => {
        this.selectedMerchants.push(merchant);
      })
    }


  },
}
</script>

<style scoped lang="stylus">

</style>