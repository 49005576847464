xvcxvnbvnbnvbvnbvnbvn nvbvbnbvnbcnbvnbv vbnmbvvbmbvvbnbnncbnbvn
vnbmbvnbvnbnbnmvbmn vbnbvbvbv nbxnbvcnbnbvnb bvbnbbnbn bnb bbnbvc
<template lang="pug">
  div
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        div()
          h2.mx-6 Vehicle Details Report
          generic-bread-crumbs.py-1(:items="breadCrumbsItems")
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Export
                v-icon(dark) keyboard_arrow_down
            v-list-item-group()
              v-list-item(v-for='item in items', :key='item.title' dense @click="onExport(item.value)")
                v-list-item-title
                  v-layout(align-center)
                    v-icon.mr-2(:size="20") {{item.icon}}
                    .subtitle-2.mx-2() {{ item.title }}
        v-btn.ma-2.mr-5(small, color='primary', dark, @click='print')
          v-icon.mr-2() print
          | Print
    v-divider
    .d-flex.mx-4.my-2()
      vehicle-filter-dialog()
      v-spacer()
      v-pagination(v-model="page" :length="Math.ceil($store.state.vehicles.all.length/itemsPerPage)" :total-visible="7")
        
  
    .grey.lighten-4.py-3
    v-divider
    div(v-if="$store.state.vehicles.all.length>0" )
      .vehicleDetailReport.ma-6()
        div.mt-12(v-for="(vehicle,index) in getVehicleArray" :key="index")
          .d-flex.align-end.mb-4()
            img(:src="`/media/${vehicle.account_id}/${vehicle.thumbnail.thumbnail}`" )
            .title.ml-2() {{vehicle.name}}
          vehicle-detail-report(:vehicle="vehicle")

    export-data-dialog(:fileExport="fileExport" :isFinished="isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="onCloseExportDialog" @download="onDownload" )
</template>

<script>
import VehicleFilterDialog from "@/modules/global/components/dialog/VehicleFilterDialog";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import Repository from "@/repository";
import vehicleDetailReport from "@/modules/reports/components/ui/VehicleDetailReport";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";


export default {
  components: {
    GenericBreadCrumbs,
    VehicleFilterDialog,
    ExportDataDialog,
    vehicleDetailReport,
    ImageThumbnail,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 2,
    isDownloadFinished: false,
    fileLink: null,
    showExportDialog: false,
    fileExport: "",
    breadCrumbsItems: [
      {
        text: "Report",
        disabled: false,
        path: "reports",
      },
      {
        text: "Vehicle Details Report",
        disabled: true,
        path: "vehicleDetailsReport",
      },
    ],
    dialog: true,
    items: [
      {
        title: "Export CSV",
        icon: "cloud_download",
        value: "export_csv",
      },
      {
        title: "Export Excel",
        icon: "cloud_download",
        value: "export_excel",
      },
    ],
    search: "",

    headers: [
      { text: "Vehicle", align: "left", value: "name", visible: true },
      {
        text: "Number plate",
        align: "left",
        value: "number_plate",
        visible: true,
      },
      { text: "VIN", align: "left", value: "vin", visible: true },
      { text: "Year", align: "left", value: "year", visible: true },
      { text: "Series", align: "left", value: "series", visible: true },
      {
        text: "Manufacturer",
        align: "left",
        value: "manufacturer",
        visible: true,
      },
      { text: "Body type", align: "left", value: "body", visible: true },
      { text: "Type", align: "left", value: "type", visible: true },
    ],
  }),

  computed: {
    dataItems: function() {
      return this.$store.state.vehicles.all;
    },
    getHeaders: function() {
      let h = [];
      this.headers.forEach((item) => {
        if (item.visible == true) {
          h.push(item);
        }
      });
      return h;
    },

    getVehicleArray: function() {
      let tempArray = [];
      let beginIndex = (this.page - 1) * this.itemsPerPage;
      let endIndex = beginIndex + this.itemsPerPage;
      for (var i = beginIndex; i < endIndex; i++) {
        if (i < this.$store.state.vehicles.all.length) {
          tempArray.push(this.$store.state.vehicles.all[i]);
        }
      }
      console.log(tempArray);
      return tempArray;
      // this.$store.state.vehicles.all[this.page]
    },
  },
  methods: {
    onExport: function(value) {
      switch (value) {
        case "export_csv":
          this.fileExport = "CSV";
          this.downloadFile("/report/CSV/vehicle_list");
          break;
        case "export_excel":
          this.fileExport = "Excel";
          this.downloadFile("/report/Excel/vehicle_list");
          break;
      }
      this.showExportDialog = !this.showExportDialog;
    },

    downloadFile: function(url) {
      this.isDownloadFinished = false;

      Repository({
        method: "POST",
        url: url,
        data: this.headers.filter((item) => item.visible == true),
        responseType: "blob",
      }).then((response) => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function() {
      // download the file
      this.fileLink.click();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function() {
      this.showExportDialog = false;
    },
    print: function() {
      console.log("print");
    },
    groupStyle: function(value) {
      let obj = {};
      obj["border-bottom"] = "3px solid";
      obj["border-bottom-color"] = value;
      return obj;
    },

    operatorStyle: function(operator) {
      let obj = {};
      obj["border-radius"] = "50%";
      obj["color"] = "#FFFFFF";
      obj["padding"] = "3px";
      obj["margin-right"] = "5px";

      if (operator.group != null) {
        obj["background-color"] = operator.group.color;
      } else {
        obj["background-color"] = "#333333";
      }
      console.log(operator.group);
      return obj;
    },
  },

  mounted() {
    if (this.$store.state.vehicles.all.length == 0) {
      this.$store.dispatch("vehicles/loadAllItems");
    }
  },
};
</script>

<style scoped>
.reportHeaderList {
  overflow: auto;
  max-height: 400px;
  min-width: 300px;
}

.vehicleDetailReport img {
  max-width: 200px;
  height: auto;
}

.vehiclePagenation {
  width: 200px;
  background-color: blue;
  height: 50px;
}
</style>
