import contact_renewal from "@/modules/reminders/store/contactRenewal";
import service_reminder from "@/modules/reminders/store/serviceReminder";
import vehicle_renewal from "@/modules/reminders/store/vehicleRenewal";
import vehicle_renewal_type from "@/modules/reminders/store/vehicleRenewalType";
import contact_renewal_type from "@/modules/reminders/store/contactRenewalType";

export default {
  namespaced: true,
  modules: {
    service_reminder,
    contact_renewal,
    vehicle_renewal,
    vehicle_renewal_type,
    contact_renewal_type
  }
};
