<template lang="pug">
div(v-if="vehicle")
  template(v-if="mode == 'textOnly'")
    div() {{ vehicle.name }}
  template(v-else)
    .nameColumn(v-if="vehicle != null" @click="openVehicleDetail(vehicle.id)")
      image-thumbnail.mr-2(:width='width', :height='height', :src="vehicle.thumbnail_id")
      .nameColumnInfo(v-if="showDetails" :class="size == 'small' ? 'smallSize':size == 'large'?'largeSize':''")
        .nameColumnTitle {{ vehicle.name }}
        .nameColumnDescription.make.primary--text
          | {{ vehicle.make }}
        .nameColumnDescription(v-if="size == 'normal' || size == 'big'")
          | VIN: {{ vehicle.vin }}
        .nameColumnDescription
          | #
          b.grey--text.text--darken-4
              | {{ vehicle.number_plate }}
</template>
<script>
import VehicleStatus from "@/modules/global/components/others/Status";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components: {
    VehicleStatus,
    ImageThumbnail
  },
  props: {
    vehicle_id:String,
    size:{
      type:String,
      default: "normal"
    },
    clickToDetail:{
      type:Boolean,
      default: false,
    },
      showDetails:{
        type:Boolean, default: true

      },
    mode: {
      type: String,
      default: null,
    }
  },

  watch: {
    vehicle_id:function(oldVal,newVal){
      if(oldVal !== newVal){
        this.getVehicle();
      }
    }
  },

  data: () => ({
    vehicle:null,
    width:90,
    height:60,
  }),
  methods:{
      getVehicle: function(){
        let tempVehicle = this.$store.getters["vehicles/getVehicleByID"](this.vehicle_id);
        if(tempVehicle == undefined){
          this.$store.dispatch("vehicles/loadAllItems").then(()=>{
            tempVehicle = this.$store.getters["vehicles/getVehicleByID"](this.vehicle_id);
            this.vehicle = tempVehicle;
          })
        }else{
          this.vehicle = tempVehicle;
        }
      },
    openVehicleDetail: function (vehicleID) {
      if(this.clickToDetail){
        this.$store.commit("vehicles/setCurrentIndexByID", vehicleID);
        this.$router.push({
          name: "vehicleDashboard",
          params: {vehicleID: vehicleID}
        }).catch(()=>{});
      }


    },
  },
  mounted() {
    if(this.size == 'small'){
      this.height=40;
      this.width = 60;
    }

    this.getVehicle();
  }
};
</script>
<style scoped lang="stylus">
.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  //width: 80px;
  //height: 60px;
}

.nameColumn, .nameColumnInfo {
}

.nameColumn, .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;
  padding: 0px;
}

.make {
  font-size: 11px;
  margin-top: -4px;
}

  .smallSize{
    min-width: 70px;
  }

.mediumSize{
  min-width: 70px;
}

.largeSize{

}
</style>
