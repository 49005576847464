<template lang="pug">
div(v-if="id" )
  template(v-if="schemaItem.type == 'customer' ")
    customer-column-by-id(:customer_id="id")
  template(v-if="schemaItem.type == 'merchant' ")
    merchant-column-by-id(:merchant_id="id")
  template(v-else-if="schemaItem.type == 'contact'")
    contact-column-by-id(:contact_id="id")
  template(v-else-if="schemaItem.type == 'vehicle'")
    vehicle-column-by-id(:vehicle_id="id")
  template(v-else-if="schemaItem.type == 'group'")
    group-column-by-id(:group_id="id")
  //div() {{schemaItem}}
  //div() {{responseObject}}
  //div() {{id}}

</template>

<script>
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById";
import CustomerColumnById from "@/modules/global/components/table/CustomerColumnById";
import MerchantColumnById from "@/modules/global/components/table/MerchantColumnById";
import GroupColumnById from "@/modules/global/components/table/GroupColumnById.vue";
export default {
  components:{
    VehicleColumnById,
    ContactColumnById,
    CustomerColumnById,
    MerchantColumnById,
    GroupColumnById
  },
  props:["schemaItem","id"],
  data:()=>({
    responseObject:null,
  }),
  mounted() {

  }
}
</script>

<style scoped>

</style>