import state from "./state";
import billingSettings from "@/modules/settings/store/billingSettings";
import roleSettings from "@/modules/settings/store/roleSettings";
import groupSettings from "@/modules/settings/store/groupSettings";
import tagSettings from "@/modules/settings/store/tagSettings";
import fuelTypeSettings from "@/modules/settings/store/fuelTypeSettings";
import expenseTypeSettings from "@/modules/settings/store/expenseTypeSettings";
import inventoryAdjustmentReasonSettings from "@/modules/settings/store/inventoryAdjustmentReasonSettings";
import measurementUnitSettings from "@/modules/settings/store/measurementUnitSettings";
import partCategorySettings from "@/modules/settings/store/partCategorySettings";
import partLocationSettings from "@/modules/settings/store/partLocationSettings";
import partManufacturerSettings from "@/modules/settings/store/partManufacturerSettings";
import purchaseOrderStatusSettings from "@/modules/settings/store/purchaseOrderStatusSettings";
import accountSettings from "@/modules/settings/store/accountSettings";
import customFieldSettings from "@/modules/settings/store/customFieldSettings";


export default {
  namespaced: true,
  state,

  modules: {
    billingSettings,
    roleSettings,
    groupSettings,
    tagSettings,
    customFieldSettings,
    fuelTypeSettings,
    expenseTypeSettings,
    inventoryAdjustmentReasonSettings,
    measurementUnitSettings,
    partCategorySettings,
    partLocationSettings,
    partManufacturerSettings,
    purchaseOrderStatusSettings,
    accountSettings,



  }
};
