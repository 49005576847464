<template>
  <v-app id="inspire">
    <router-view></router-view>
  </v-app>
</template>
<script>


export default {
  created: function () {
    const beforeUnloadListener = async () => {
      if (this.$store.mqttclient != undefined){
        this.$store.mqttclient.end();
      }

    };

    window.addEventListener('beforeunload', beforeUnloadListener);


    let isLoggedIn = this.$store.getters["login/isUserLoggedIn"];
    console.log(`is user logged in: ${isLoggedIn}`);
    // // this.$theTimer;

    // if user was already logged in try and autoLogin
    if (isLoggedIn == true) {
      this.$store
          .dispatch("login/autoLoginUser")
          .then(() => {
            // get route that is saved in localStrage
            let routeLocalStore = localStorage.getItem("route");
            // Login in to the saved path
            if (routeLocalStore) {
              this.$router.push({path: routeLocalStore} ).catch(()=>{});
            }
          })
          .catch(() => {
            this.$router.push({name: "login"}).catch(()=>{});
          });
    } else {
      // if user is logged out and the url window is not for login then set to login screen
      if (this.$route.name !== "login") {
        this.$router.push({name: "login"}, ).catch(()=>{});
      }
    }




  },


};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

.fill-view {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background: #333;*/
}

.fill-body {
  height: calc(100vh - 70px);
  width: 100%;
  /*background-color: #330033;*/
}

.fill-tab {
  height: calc(100vh - 120px);
  width: 100%;
  /*background: #0F0;*/
}

.fill-width {
  width: 100%;
}

.max-height {
  height: calc(100% - 120px);
}


#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.pointer {
  cursor: pointer;
}

.pointer:hover{
  color: #ec037b;
}
</style>
