<template lang="pug">
div
  v-divider
  v-container.ma-2(fluid)
    v-layout.mx-1(row, wrap)
      h2 Service History
      v-spacer
      v-btn.ma-2(small, outlined, color='primary' @click="onRefresh")
        v-icon() sync
      .text-xs-center
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
              | Actions
              v-icon(dark) keyboard_arrow_down
          v-list()
            v-list-item-group(v-model="action" color="primary")
              v-list-item(v-for='item in items', :key='item.title' @click='onAction(item.value)')
                v-list-item-icon()
                  v-icon() {{item.icon}}
                v-list-item-title {{ item.title }}
      // Dialog for Tasks
      v-btn.ma-2(small, color='primary', dark, @click='openNew') Add Service
  v-divider

  v-layout.mx-3.my-2.align-center(v-if="getMetrics" row, wrap)
    service-bar-chart.ml-4()
    .listHeaderMetric()
      .primary--text.font-weight-bold() {{$store.state.profile.userInfo.account.region_settings.currency}} {{getMetrics.monthly_cost}}
      .grey--text.text--darken-2() {{getCurrentMonthName}}
    .listHeaderMetric()
      .primary--text.font-weight-bold() {{$store.state.profile.userInfo.account.region_settings.currency}} {{getMetrics.total_cost}}
      .grey--text.text--darken-2() Total Cost
    service-pie-chart()
    merchant-service-pie-chart()


  v-divider
  v-layout.mx-4.my-0(row align-center)
    v-row()
      v-col(cols="12" xs="12" md="5" xl="3")
        .d-flex.align-center()
          v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :prepend-icon="'search'" )
          v-btn(v-if="search.length > 0" text outlined small @click="search=''") clear all
  v-divider
  .grey.lighten-4.py-3
  v-divider
  service-history-table(:loading="isLoading")
  v-divider
  v-dialog(v-model="vehicle_dialog" width="500")
    select-vehicles(v-if="vehicle_dialog" :subtract-unit="'Months'" :subtract-amount="3"  :showDialog="true" @close="onCloseVehicleDialog"  @save="onSaveVehicleDialog")
  v-dialog(v-model="merchant_dialog" width="500")
    select-merchants(v-if="merchant_dialog" :subtract-unit="'Months'" :subtract-amount="3" :showDialog="true" @close="onCloseMerchantDialog"  @save="onSaveMerchantDialog")


</template>

<script>
import VehiclesFilterCombo from "@/modules/global/components/forms/VehiclesFilterCombo";
import ServiceHistoryTable from "@/modules/services/components/tables/ServiceHistoryTable";
import MerchantServicePieChart from "@/modules/services/components/tables/MerchantServicePieChart.vue";
import Repository from "@/repository";
import ServiceBarChart from "@/modules/services/components/tables/ServiceBarChart";
import ServicePieChart from "@/modules/services/components/tables/ServicePieChart.vue";
import FormHeaderTotal from "@/modules/global/components/forms/FormHeaderTotal.vue";
import SelectVehicles from "@/modules/global/components/dialog/SelectVehicles.vue";
import SelectMerchants from "@/modules/global/components/dialog/SelectMerchants.vue";
import moment from "moment/moment";

export default {
  components: {
    VehiclesFilterCombo,
    ServiceHistoryTable,
    ServiceBarChart,
    ServicePieChart,
    FormHeaderTotal,
    MerchantServicePieChart,
    SelectVehicles,
    SelectMerchants
  },

  data: () => ({
    search: "",
    action: null,
    isLoading:true,
    vehicle_dialog: false,
    merchant_dialog: false,
    items: [
      {
        title: "Export PDF by Vehicles",
        value: "export_pdf_vehicles",
        icon: "download"
      },
      {
        title: "Export PDF by Merchants",
        value: "export_pdf_merchants",
        icon: "download"
      },
      {
        title: "Export Excel",
        value: "export_xls",
        icon: "download"
      },
      {
        title: "Delete Selected",
        value: "delete_selected",
        icon: "delete"
      }
    ]
  }),

  computed: {
    getMetrics() {
      return this.$store.getters["service/getMetrics"];
    },
    getCurrentMonthName: function (){
      return moment().format("MMMM YYYY")
    }
  },

  methods: {
    onCloseVehicleDialog: function(){
      this.vehicle_dialog = false;
      this.isLoading = false;
    },

    onSaveVehicleDialog: function(result){
      this.vehicle_dialog = false;
      var vehicle_ids = "";

      result.selectedVehicles.forEach(vehicle => {
        vehicle_ids += "&vehicle_id="+vehicle.id
      })

      window.open(`/api/renderhtml?type=serviceRecordsVehicles&from=${result.from}&to=${result.to}&account_id=${this.$store.state.profile.userInfo.account.id}${vehicle_ids}`,"_blank")
    },
    onCloseMerchantDialog: function(){
      this.merchant_dialog = false;
      this.isLoading = false;
    },

    onSaveMerchantDialog: function(result){
      this.merchant_dialog = false;
      var merchant_ids = "";

      result.selectedMerchants.forEach(merchant => {
        merchant_ids += "&merchant_id="+merchant.id
      })

      window.open(`/api/renderhtml?type=serviceRecordsMerchants&from=${result.from}&to=${result.to}&account_id=${this.$store.state.profile.userInfo.account.id}${merchant_ids}`,"_blank")
    },
    openNew: function() {
      this.$store.dispatch("service/addService").then(() => {
        let currentObject = this.$store.getters["service/getCurrentService"];
        this.$router.push({
          name: "serviceDetailForm",
          params: { serviceID: currentObject.id }
        }).catch(()=>{});
      });
    },
    onRefresh: function() {

      this.$store.dispatch("service/loadAllServices").then(()=>{
        this.isLoading = false;
      });
    },

    onAction: function(value) {
      switch (value) {
        case "export_pdf_vehicles":
          this.vehicle_dialog = true;
          break;
        case "export_pdf_merchants":
          this.merchant_dialog = true;
          break;
        case "export_xls":
          this.downloadFile("/service/renderXLS");
          break;
        case "delete_selected":
          if (this.$store.state.service.selectedServices.length == 0){
            this.$store.commit("showSnackBar", {
              color: "error",
              message: `No Checkbox Selected`
            });
            break;
          }
          confirm(`Are you sure you want to delete ${this.$store.state.service.selectedServices.length} items?`) &&
          this.$store.dispatch("service/deleteSelectedServices");
          break;
      }
    },
    onClick: function(index){
      console.log(index);
      switch(index){
        case 0: this.downloadFile("/service/renderXLS"); break;
      }
    },

    downloadFile: async function(url) {
      await Repository({
        method: "GET",
        url: url,
        responseType: "blob"
      }).then(response => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
      this.fileLink.click();
    },

    onDownload: function() {
      // download the file
      this.fileLink.click();
      this.onCloseExportDialog();
    },
  },

  mounted() {
    // console.log(this.$vuetify.theme.primary);
    this.isLoading = true;
    if (this.$store.state.service.currentIndex == -1) {
      // load Data
      this.onRefresh();
    }else{
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.listHeaderMetric {
  border: 2px solid #CCC;
  background-color: #f5f5f5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  margin: 10px 30px;
}
</style>
