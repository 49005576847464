<template lang="pug">
  div(style="width:100%")
    component( :is="getComponent(schema.type)" v-model="computedValue" :label="schema.description" :disabled="disabled")
</template>
<script>


import ContactAutoComplete from "@/modules/global/components/forms/ContactAutoComplete";
import CustomerAutoComplete from "@/modules/global/components/forms/CustomerAutoComplete";
import MerchantAutoComplete from "@/modules/global/components/forms/MerchantAutoComplete";
import VehicleAutoComplete from "@/modules/global/components/forms/VehicleAutoComplete";
import VehiclesAutoComplete from "@/modules/global/components/forms/VehiclesAutoComplete";
import CustomersAutoComplete from "@/modules/global/components/forms/CustomersAutoComplete";
import MerchantsAutoComplete from "@/modules/global/components/forms/MerchantsAutoComplete";
import ContactsAutoComplete from "@/modules/global/components/forms/ContactsAutoComplete";
import PlaceAutoComplete from "@/modules/global/components/forms/PlaceAutoComplete";
import PlacesAutoComplete from "@/modules/global/components/forms/PlacesAutoComplete";
import BlankRenderer from "@/modules/workflow/formBuilder/components/renderTypes/BlankRenderer";
import GroupAutoComplete from "@/modules/global/components/forms/GroupAutoComplete.vue";


export default {
  components:{
    ContactAutoComplete,
    CustomerAutoComplete,
    MerchantAutoComplete,
    VehicleAutoComplete,
    CustomersAutoComplete,
    MerchantsAutoComplete,
    ContactsAutoComplete,
    VehiclesAutoComplete,
    PlaceAutoComplete,
    PlacesAutoComplete,
    GroupAutoComplete,

    BlankRenderer,
  },
  props: ["schema", "dataValue", "index","disabled"],

  data: () => ({
  }),
  computed:{

    computedValue:{
      get: function() {
        return this.dataValue;
      },
      set: function(theValue){
        console.log(`setting theValue: ${theValue}`);
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    },
  },
  methods:{
    getComponent: function(jahaziType) {
      let theJahaziType = 'BlankRenderer';
      console.log(this.schema.multiple)
      console.log(jahaziType)
      if(this.schema.multiple){
        switch (jahaziType){
          case 'contact': theJahaziType = "ContactsAutoComplete"; break;
          case 'vehicle': theJahaziType = "VehiclesAutoComplete"; break;
          case 'customer': theJahaziType = "CustomersAutoComplete"; break;
          case 'merchant': theJahaziType = "MerchantsAutoComplete"; break;
          case 'place': theJahaziType = "PlacesAutoComplete"; break;
          case 'group': theJahaziType = "GroupAutoComplete"; break;
        }
      }else{
        switch (jahaziType){
          case 'contact': theJahaziType = "ContactAutoComplete"; break;
          case 'vehicle': theJahaziType = "VehicleAutoComplete"; break;
          case 'customer': theJahaziType = "CustomerAutoComplete"; break;
          case 'merchant': theJahaziType = "MerchantAutoComplete"; break;
          case 'place': theJahaziType = "PlaceAutoComplete"; break;
          case 'group': theJahaziType = "GroupAutoComplete"; break;
        }
      }


      return theJahaziType;

    }
  }
};
</script>
<style scoped lang="stylus">
.signPlaceholder{
  border 2px solid #CCCCCC;
  display flex;
  justify-content center;
  align-items center;
  border-radius 10px;
  background-size contain;
  background-position center;
  width 240px;
  height 120px;

}
.signPlaceholder img{
  width 130px;
  height 100px;
}
</style>
