<template lang="pug">
  div(v-if='$store.state.inspection.currentIndex > -1')
    v-toolbar(dense, flat)
      v-toolbar-title Inspections Form
      v-spacer


    v-container.py-2
      v-alert(v-if="errors.length" :value="true" color="error" icon="warning")
        div(v-for="e,index in errors" :key="index" v-text="e")

      v-layout(justify-end)
        v-btn.mx-2(small text color="grey" @click="cancel") cancel
        v-btn.mx-2(small outlined color="primary" @click="saveAdd") Save &amp; Add another
        v-btn.mx-2(small color="primary" @click="save" :disabled="!valid") Save

    v-form(ref="form" v-model="valid" lazy-validation)
      v-container
        v-card
          v-container(grid-list-xl)
            form-divider INSPECTION DETAILS
            v-row(align="end")
              v-col(cols="12" xs="12" md="6" xl="4")
                vehicle-auto-complete(v-model='vehicle_id' label="Select Vehicle*" placeholder="Vehicle with Inspection")
              v-col(cols="12" xs="12" md="6" xl="4")
                auto-date-time-picker(v-model="report_date_time", labelDate="Reported Date", labelTime="Reported Time")
              v-col(cols="12" xs="12" md="6" xl="4")
                v-text-field(v-model.number='odometer', hint='Odometer Reading', persistent-hint, label='Odometer')
              v-col(cols="12" xs="12" md="6" xl="4")
                contact-auto-complete(v-model='reported_by_id'  label="Reported By*" placeholder="Contact who reported the inspection")
              v-col(cols="12" xs="12" md="6" xl="4")
                contact-auto-complete(v-model='assigned_to_id' label="Assigned To" placeholder="Contact assigned the inspection")
              v-col(cols="12" xs="12" md="6" xl="4")
                autos-combo-box(v-model='tags_id'  label="Select Label" module="settings" submodule="tagSettings"   actionPrefix="Tag")
              v-col(cols="12" xs="12" md="6" xl="4")
                auto-date-time-picker(v-model="due_date_time", labelDate="Due Date (Optional)", labelTime="Due Date")
              v-col(cols="12" xs="12" md="6" xl="4")
                v-text-field(v-model='name', hint='Brief Inspection Description', :rules="[rules.required]" :persistent-hint='true', label='Describe Inspection*', )
              v-col(cols="12" xs="12" md="6" xl="4")
                v-textarea(v-model="description" hint="Inspection Description" :persistent-hint="true" label="Inspection Description")


            form-divider ATTACHMENTS
            v-row()
              v-col(cols="12"  xs="12" md="6")
                attach-fill-form( :schema="imagesSchama" :data="{value:photos_id}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" )
              v-col(cols="12"  xs="12" md="6")
                attach-fill-form( :schema="documentsSchama" :data="{value:documents_id}" @onChange="saveDocuments"  @onMenuAction="onMenuActionDocuments")

          v-container.py-2
            v-layout(justify-end)
              v-btn.mx-2(small text color="grey" @click="cancel") cancel
              v-btn.mx-2(small outlined color="primary" @click="saveAdd") Save &amp; Add another
              v-btn.mx-2(small color="primary" @click="save" :disabled="!valid") Save
</template>

<script>
import VehicleAutoComplete from "@/modules/global/components/forms/VehicleAutoComplete";
import ContactAutoComplete from "@/modules/global/components/forms/ContactAutoComplete";
import TagsAutoComplete from "@/modules/global/components/forms/TagsAutoComplete";
import AutoDateTimePicker from "@/modules/global/components/forms/AutoDateTimePicker";
import AutosComboBox from "@/modules/global/components/forms/AutosComboBox";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import FormHeaderTotal from "@/modules/global/components/forms/FormHeaderTotal";
import {createHelpers} from "vuex-map-fields";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";

const {mapFields} = createHelpers({
  getterType: "inspections/getInspection",
  mutationType: "inspections/updateInspection"
});
const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  components: {
    FormDivider,
    FormHeaderTotal,
    VehicleAutoComplete,
    ContactAutoComplete,
    TagsAutoComplete,
    AttachFillForm,
    AutoDateTimePicker,
    AutosComboBox
  },
  data: () => ({
    valid: true,
    error: false,
    errors: [],

    menu: false,
    progress: false,

    imagesSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Inspection Images",
      hint: null,
      type: "image",
      image_size: "small",
      multiple: true
    },

    documentsSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Inspection Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true
    },

    rules: {
      required: value => !!value || "Required.",
      email: value => {
        return pattern.test(value) || "Invalid e-mail.";
      },
      min8: value => {
        return value.length >= 8 || "Minimum 8 Characters";
      },
      hasValue: theObject => {
        console.log("hellooooo" + theObject);
        return false;
      }
    }
  }),

  methods: {
    cancel: function () {
      // show the List Page
      this.$router.back();

      // refresh the List
      this.$store.dispatch("inspections/loadAllInspections");
    },

    save: function () {
      // check for errors
      this.errors = [];
      if (this.$refs.form.validate()) {
        if (!this.vehicle_id) {
          this.errors.push("Vehicle Required");
        }

        if (!this.reported_by_id) {
          this.errors.push("Reported By Required");
        }

        if (!this.errors.length) {
          this.$store.dispatch("inspections/saveInspection").then(() => {
            this.$store.commit("showSnackBar", {
              color: "primary",
              message: `Inspection "${this.name}" is saved`
            });
            // show the List Page
            this.$router.back();
            // refresh the List
            this.$store.dispatch("inspections/loadAllInspections");
          });
        }
      }
    },

    saveAdd: function () {
      console.log("saveAdd");
    },

    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },

    saveImages: function (theObject) {
      this.$store.commit("inspections/updateInspection", {
        path: "photos_id",
        value: theObject.value
      });
    },

    saveDocuments: function (theObject) {
      this.$store.commit("inspections/updateInspection", {
        path: "documents_id",
        value: theObject.value
      });
    },

    onMenuActionImages: function (value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("inspections/deleteImage", value.theObject.id);
          break;
      }
    },

    onMenuActionDocuments: function (value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("inspections/deleteDocument", value.theObject.id);
          break;
      }
    }
  },

  computed: {
    ...mapFields([
      "name",
      "description",
      "report_date_time",
      "due_date_time",
      "odometer",
      "reported_by_id",
      "assigned_to_id",
      "tags_id",
      "vehicle_id",
      "status",
      "account_id",
      "photos_id",
      "documents_id"
    ])
  },

  mounted() {
    if (this.$store.state.inspection.currentIndex == -1) {
      // Load
      this.$store
          .dispatch("inspections/fetchInspection", this.$route.params.inspectionID)
          .then(() => {
            this.completionDate = this.completionDateTime;
          });
    } else {
      this.completionDate = this.completionDateTime;
    }
  }
};
</script>

<style scoped></style>
