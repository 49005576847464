<template lang="pug">
  #blankcontent()
    .panelTitle() Vehicle Status
    v-divider.mx-2()
    .panelContent()
      template(v-if="$store.state.vehicles.currentIndex == -1")
        .d-flex.justify-center.align-center.fill-height()
          v-progress-circular(color="primary" indeterminate)
      template(v-else)
          v-list-item-group()
            v-list-item.lineVehicleStatus(v-for="(item,index) in vehicleStatusItems" :key="index")
              v-list-item-content()
                .body-2(v-text="item.title")
              v-list-item-avatar(:color="item.color" :size="28")
                .font-weight-bold.caption() {{item.value}}

</template>
<script>
export default {
  props: {
    clientHeight: Number
  },
  computed: {
    vehicleStatusItems: {
      get() {
        let items = [];
        items.push({
          title: "Active",
          color: this.$store.getters["vehicles/getStatusColor"]("active"),
          value: this.$store.getters["vehicles/getStatus"]("active").length
        });
        items.push({
          title: "In Active",
          color: this.$store.getters["vehicles/getStatusColor"]("in_active"),
          value: this.$store.getters["vehicles/getStatus"]("in_active").length
        });
        items.push({
          title: "In Shop",
          color: this.$store.getters["vehicles/getStatusColor"]("in_shop"),
          value: this.$store.getters["vehicles/getStatus"]("in_shop").length
        });
        items.push({
          title: "Out of Service",
          color: this.$store.getters["vehicles/getStatusColor"](
            "out_of_service"
          ),
          value: this.$store.getters["vehicles/getStatus"]("out_of_service")
            .length
        });
        items.push({
          title: "Sold",
          color: this.$store.getters["vehicles/getStatusColor"]("sold"),
          value: this.$store.getters["vehicles/getStatus"]("sold").length
        });
        return items;
      }
    }
  },
  data: () => ({})
};
</script>
<style scoped lang="stylus">
.lineVehicleStatus {
  border-bottom: 1px solid #f9f9f9;
  height: 50px;
}

#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 30px;
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.panelContent {
  height: calc(100% - 45px);
  overflow: auto;
}
</style>
