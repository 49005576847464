import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const MERCHANT_API = "/merchant";

// ----------------------- STATE ------------------------- //
const state = {
    merchant_data: [],
    currentIndex: -1,
    selectedMerchants: []
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getMerchant: state => getField(state.merchant_data[state.currentIndex]),

    getCustomerByID: (state) => (id) => {
        return state.merchant_data.find((element) => element.id == id);
    },
    getCurrentMerchant: state => {
        return state.merchant_data[state.currentIndex];
    },
    getSortedMerchants: state => {
        return state.merchant_data.sort((a, b) => a.name.localeCompare(b.name));
    },
    getFilterByGroup: (state) => (id) => {
        if (id != null) {
            return state.merchant_data.filter(item => item.group_id == id)
        } else {
            return state.merchant_data
        }
    },
    getSelectedMerchants: (state) => state.selectedMerchants,
    getMerchantByID: (state) => (id) => {
        return state.merchant_data.find((element) => element.id == id);
    },

};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateMerchant(state, payload) {
        updateField(state.merchant_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.merchant_data.findIndex(
            merchant => merchant.id == theID
        );
    },

    loadAllMerchants(state, payload) {
        state.merchant_data = payload.data;
        state.currentIndex = 0;
    },

    addMerchant(state, payload) {
        state.merchant_data.push(payload.data);
        state.currentIndex = state.merchant_data.length - 1;
    },

    saveMerchant(state, payload) {
        state.merchant_data[state.currentIndex] = payload.data;
    },

    fetchMerchant(state, payload) {
        if (state.currentIndex == -1) {
            state.merchant_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.merchant_data[state.currentIndex] = payload.data;
        }
    },

    deleteMerchant(state, payload) {
        state.currentIndex = state.merchant_data.findIndex(
            merchant => merchant.id == payload.data.id
        );
        state.merchant_data.splice(state.currentIndex, 1);
    },
    reset(state) {
        state.merchant_data = []
        state.currentIndex = -1
    },
    next(state){
        if(state.currentIndex == state.merchant_data.length-1){
            state.currentIndex = 0;
        }else{
            state.currentIndex += 1;
        }
    },

    previous(state){
        if(state.currentIndex == 0){
            state.currentIndex = state.merchant_data.length-1;
        }else{
            state.currentIndex -= 1;
        }
    },


    setSelectedMerchants(state, payload) {
        state.selectedMerchants = payload;
    },
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllMerchants({commit}) {
        try {
            commit("loadAllMerchants", await Repository.get(`${MERCHANT_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addMerchant({commit}) {
        await Repository.get(`${MERCHANT_API}/new`)
            .then(response => {
                commit("addMerchant", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveMerchant({state, commit}) {
        try {
            console.log(state.merchant_data[state.currentIndex]);

            commit(
                "saveMerchant",
                await Repository.post(
                    `${MERCHANT_API}`,
                    state.merchant_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchMerchant({commit}, payloadID) {
        try {
            commit(
                "fetchMerchant",
                await Repository.get(`${MERCHANT_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteMerchant({commit}, payloadID) {
        try {
            commit(
                "deleteMerchant",
                await Repository.delete(`${MERCHANT_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchMerchants({commit}, searchTerm) {
        try {
            commit(
                "loadAllMerchants",
                await Repository.get(`${MERCHANT_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
