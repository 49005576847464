import { makeItem, makeList } from "@/modules/workflow/board/utils/data";
import {
  getItemById,
  getListById,
  getListByItemId
} from "@/modules/workflow/board/utils/board";

import Repository from "@/repository";
const board_API = "/board";

const state = {
  currentIndex: -1,
  lists: []
};

const getters = {
  getListById: state => listId => {
    return getListById(state.lists, listId);
  },

  getListByItemId: state => itemId => {
    return getListByItemId(state.lists, itemId);
  },

  getItemById: state => itemId => {
    return getItemById(state.lists, itemId);
  }
};

const mutations = {
  clearAll(state) {
    state.currentIndex=-1;
    state.lists = [];
  },

  lists(state, value) {
    state.lists = value;
  },


  addList(state, { title }) {
    state.lists.push(makeList(title));
  },

  moveList(state, [fromIndex, toIndex]) {
    state.lists.splice(toIndex, 0, state.lists.splice(fromIndex, 1)[0]);
  },

  removeList(state, { listId }) {
    const index = state.lists.findIndex(list => list.id === listId);
    state.lists.splice(index, 1);
  },

  addItem(state, { listId, title, description, date }) {
    const list = getListById(state.lists, listId);
    list.items.push(makeItem(title, description, date));
  },

  updateItem(state, { itemId, title, description, date }) {
    const item = getItemById(state.lists, itemId);
    if (item) {
      Object.assign(item, makeItem(title, description, date, itemId));
    }
  },

  moveItem(state, [fromListRef, fromIndex, toListRef, toIndex]) {

    const fromList =
      typeof fromListRef === "number"
        ? state.lists[fromListRef].items
        : getListById(state.lists, fromListRef);
    const toList =
      typeof toListRef === "number"
        ? state.lists[toListRef].items
        : getListById(state.lists, toListRef);
    toList.splice(toIndex, 0, fromList.splice(fromIndex, 1)[0]);
  },

  // set the new board column after moving
  setBoardColumn(state,payload){
    state.lists[payload.columnIndex].items[payload.recordIndex].board_column_id = payload.columnID;
  },

  removeItem(state, { itemId }) {
    const list = getListByItemId(state.lists, itemId);
    list.items.splice(
      list.items.findIndex(item => item.id === itemId),
      1
    );
  },

  fetchboard(state, payload) {
    let tempBoard = [];
    payload.data.forEach(column => {
      let tempColumn = {};
      tempColumn.id = column.id;
      tempColumn.name = column.name;
      tempColumn.description = column.description;
      tempColumn.account_id = column.account_id;
      tempColumn.workflow_template_id = column.workflow_template_id;
      tempColumn.items = [];

      column.workflow_records =
        column.workflow_records == null ? [] : column.workflow_records; // remove null
      column.workflow_records.forEach(record => {
        record.board_column_id = tempColumn.id;
        tempColumn.items.push(record);
      });
      tempBoard.push(tempColumn);
    });

    state.lists = tempBoard;
  },

  saveLayout(state, payload) {
    console.log(payload.data);
  },

  addBoardColumn(state, payload) {
    // get the newboardList
    let tempColumn = {};
    tempColumn.id = payload.data.id;
    tempColumn.name = payload.data.name;
    tempColumn.description = payload.data.description;
    tempColumn.account_id = payload.data.account_id;
    tempColumn.workflow_template_id = payload.data.workflow_template_id;
    tempColumn.items = [];
    state.lists.push(tempColumn);
  },

  deleteRecord(state, payload) {
    let columnIndex = state.lists.findIndex(
      item => item.id == payload.data.board_column_id
    );
    if (columnIndex > -1) {
      let recordIndex = state.lists[columnIndex].items.findIndex(
        item => item.id == payload.data.id
      );
      if (recordIndex > -1) {
        state.lists[columnIndex].items.splice(recordIndex, 1);
      }
    }
  },

  orderColumns(state, payload) {
    console.log(payload.data);
  },

  pushRecord(state, payload) {
    let columnIndex = state.lists.findIndex(
      item => item.id === payload.columnID
    );

    let recordIndex = state.lists[columnIndex].items.findIndex(
      r => r.id === payload.record.id
    );

    // get the record
    let record = payload.record.data;
    // get the Fields

    record.board_column_id = payload.columnID;

    if (recordIndex == -1) {
      // add to column
      state.lists[columnIndex].items.unshift(record);
    } else {
      state.lists[columnIndex].items[recordIndex] = record;
    }
  },

  deleteColumn(state,payload){

    let columnIndex = state.lists.findIndex(
        item => item.id === payload.data.id
    );
    if (columnIndex > -1){
      state.lists.splice(columnIndex,1)
    }
  },

  reset(state){
    state.currentIndex = -1
    state.lists = []
  }
};

const actions = {
  async fetchboard({ commit }, payloadID) {
    try {
      commit("fetchboard", await Repository.get(`${board_API}/${payloadID}`));
    } catch (error) {
      console.log(error);
    }
  },

  async addBoardColumn({ commit }, payload) {
    try {
      commit(
        "addBoardColumn",
        await Repository.post(`${board_API}/new`, payload)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deleteRecord({ commit }, payload) {
    try {
      commit(
        "deleteRecord",
        await Repository.post(`${board_API}/deleteRecord`, payload)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async orderColumns({ commit }, workflowTemplate) {
    try {
      let columnsArray = [];
      state.lists.forEach(column => {
        columnsArray.push(column.id);
      });

      let tempTemplate = {
        id: workflowTemplate.id,
        board_columns_id: columnsArray
      };
      commit(
        "orderColumns",
        await Repository.post(`${board_API}/orderColumns`, tempTemplate)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async saveLayout({ state, commit }) {
    try {
      let boardList = [];
      state.lists.forEach(column => {
        let obj = {};

        obj.id = column.id;
        obj.name = column.name;
        obj.description = column.description;
        obj.account_id = column.account_id;
        obj.workflow_template_id = column.workflow_template_id;
        obj.workflow_records_id = [];
        column.items.forEach(record => {
          obj.workflow_records_id.push(record.id);
        });

        boardList.push(obj);
      });

      commit("saveLayout", await Repository.post(`${board_API}`, boardList));
    } catch (error) {
      console.log(error);
    }
  },

  async deleteColumn({commit},payload){
    try{
      commit("deleteColumn",await Repository.delete(`${board_API}/${payload}`))
    }catch(error){
      console.log(error)
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
