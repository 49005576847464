<template lang="pug">
  div(v-if="$store.state.service.service_data.length > 0")

    v-toolbar(dense flat)
      generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer()
      .d-flex.align-center()
        .mr-2(v-if="$store.state.service.service_data.length > 1")
          v-btn.mx-4(icon small @click="previous")
            v-icon() skip_previous
          v-btn.mx-4(icon small @click="next")
            v-icon() skip_next
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
              | Export
              v-icon(dark) keyboard_arrow_down
          v-list-item-group.white()
            v-list-item(v-for='item in items', :key='item.title' dense @click="onAction(item.value)" )
              v-list-item-title
                v-layout(align-center)
                  v-icon.mr-2(:size="20") {{item.icon}}
                  .subtitle-2.mx-2() {{ item.title }}
        v-btn.mx-2(color="primary" small @click="editRecord") Edit Record
    v-divider()
    v-container.mt-8.pa-8.white(fluid)
      v-row()
        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:body)
              div.mt-2.mb-2.pb-2
                table.DetailTable.body-2.fill-width
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Vehicle
                    td
                      vehicle-column-by-id(v-if="service.vehicle_id" :vehicle_id="service.vehicle_id")
                  tr()
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Reference
                    td
                      div(v-if="service.reference") {{service.reference}}
                  tr()
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Odometer
                    td
                      div(v-if="service.odometer") {{service.odometer}}
                  tr()
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Merchant
                    td
                      div(v-if="service.merchant") {{service.merchant.name}}
                  tr()
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Start Date
                    td {{getDateFormat(service.start_date_time)}}
                  tr()
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Completion Date
                    td {{getDateFormat(service.completion_date_time)}}
                  tr()
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Comments
                    td {{service.comments}}
                  tr()
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Submited On
                    td {{getDateTimeFormat(service.updated_at)}}
                  tr()
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Submited By
                    td
                      contact-column-by-id(v-if="service.reported_by_id" :contact_id="service.reported_by_id")
        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:title)
              | Service Details
            template(v-slot:body)
              div
                table.ServiceTable
                  tr
                    td.grey.lighten-4.pl-4() Service Items
                    td.grey.lighten-2.serviceRight.pr-4() Parts
                    td.grey.lighten-3.serviceRight.pr-4() Labour
                    td.grey.lighten-2.serviceRight.pr-4() Total

                  tr(v-for="(item,index) in service.service_details" :key="index")
                    td.grey--text.text--darken-1.pl-4
                      .mr-3 {{item.service_type.name}}
                    td.serviceRight.pr-4
                      | {{item.parts}}
                    td.serviceRight.pr-4
                      | {{item.labour}}
                    td.serviceRight.pr-4
                      | {{item.total}}
                  //- tr(v-if="service.issues_id.length > 0")
                    td(colspan="4")
                  //- tr(v-if="service.issues_id.length > 0")
                    td.grey.lighten-4.pl-4() Issues
                    td.grey.lighten-2.serviceRight.pr-4() Parts
                    td.grey.lighten-3.serviceRight.pr-4() Labour
                    td.grey.lighten-2.serviceRight.pr-4() Total
                  tr(v-for="(item,index) in service.issues" :key="index")
                    td.grey--text.text--darken-1.pl-4
                      .mr-3 {{item.issue}}
                    td.serviceRight.pr-4
                      | {{item.parts}}
                    td.serviceRight.pr-4
                      | {{item.labour}}
                    td.serviceRight.pr-4
                      | {{item.total}}
                  tr()
                    td.grey(colspan="4")
                  tr
                    td.serviceRight.subtitle-2.pr-4() Sub Totals
                    td.grey.lighten-2.serviceRight.subtitle-2.pr-4() {{service.sub_parts}}
                    td.grey.lighten-3.serviceRight.subtitle-2.pr-4() {{service.sub_labour}}
                    td.grey.lighten-2.serviceRight.subtitle-2.pr-4() {{service.sub_total}}
                  tr
                    td.serviceRight.subtitle-2.pr-4.primary--text(colspan="3") Grand Total
                    td.grey.lighten-4.serviceRight.subtitle-1.pr-4.font-weight-bold.primary--text() {{service.grand_total}}
          base-panel()
            template(v-slot:title)
              | Photos


            template(v-slot:body)
              attach-fill-form( :schema="imagesSchama" :data="{value:getService.photos_id}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" hideButton )

          base-panel()
            template(v-slot:title)
              | Documents

            template(v-slot:body)
              attach-fill-form(:schema="documentsSchama" :data="{value:getService.documents_id}" @onChange="saveDocuments"  @onMenuAction="onMenuActionImages" hideButton )




</template>

<script>
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import BasePanel from "@/modules/global/components/panel/basePanel";
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById.vue";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
import moment from "moment";
import Repository from "@/repository";

export default {
  components:{
    GenericBreadCrumbs,
    BasePanel,
    VehicleColumnById,
    ContactColumnById,
    AttachFillForm
  },
  data: () => ({
    breadCrumbsItems: [
      {
        text: "Service History",
        disabled: false,
        path: "services"
      },
      {
        text: "Service Detail",
        disabled: true
      }
    ],
    imagesSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Issue Images",
      hint: null,
      type: "image",
      image_size: "medium",
      multiple: true
    },
    documentsSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Issue Documents",
      hint: null,
      type: "document",
      image_size: "medium",
      multiple: true
    },
    items: [
      {
        title: "Export Pdf",
        icon: "cloud_download",
        value: "export_pdf"
      },
      {
        title: "Copy Link to Clipboard",
        icon: "content_copy",
        value: "copy_link"
      },
      {
        title: "Open Link",
        icon: "open_in_new",
        value: "open_link"
      }

    ],
  }),
  computed:{
    service: function() {
      return this.$store.getters["service/getCurrentService"];
    },
    getService: function() {
      return this.$store.getters["service/getCurrentService"]
    },
  },
  methods:{

    getDateFormat:function(theDate){
      return moment(theDate).format("ddd, Do MMM YYYY")
    },

    getDateTimeFormat:function(theDate){
      return moment(theDate).format("h:mm a, ddd, Do MMM YYYY")
    },

    saveImages: function(theObject) {
      this.$store.commit("service/updateService", {
        path: "photos_id",
        value: theObject.value
      });
    },

    saveDocuments: function(theObject) {
      this.$store.commit("service/updateService", {
        path: "documents_id",
        value: theObject.value
      });
    },

    onMenuActionImages: function(value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("service/deleteImage", value.theObject.id);
          break;
      }
    },

    onMenuActionDocuments: function(value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("service/deleteDocument", value.theObject.id);
          break;
      }
    },
    onAction: function(value) {
      switch (value) {
        case "export_pdf":
          window.open(`/api/renderhtml?type=serviceRecordPDF&id=${this.$route.params.serviceID}`);
          break;
        case "copy_link":
          navigator.clipboard.writeText(`https://app.jahazi.com/api/renderhtml?type=serviceRecord&id=${this.$route.params.serviceID}`);
          this.$store.commit("showSnackBar", {
            color: "accent",
            message: `Copied to Clipboard`,
          });
          break;
        case "open_link":
          window.open(`/api/renderhtml?type=serviceRecord&id=${this.$route.params.serviceID}`);
          break;
      }

    },

    downloadFile: function(url) {
      this.isDownloadFinished = false;

      Repository({
        headers: {
          "Mode": "Gotenberg",
        },
        method: "GET",
        url: url,
      }).then((response) => {
        console.log(response);
        let blob = response.data;

        let fileName = blob.file_name;

        let fileURL = `/media/${blob.account_id}/${blob.file_name}`
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      }).catch((error) => {
        console.log(error)
      });
    },

    onDownload: function() {
      // download the file
      this.fileLink.click();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function() {
      this.showExportDialog = false;
    },
    onOpenDialog: function() {
      // this.thedate = JSON.parse(JSON.stringify(this.originalDate));
      this.showDialog = true;
    },
    onCancelDialog: function() {
      this.showDialog = false;
      // this.thedate = JSON.parse(JSON.stringify(this.originalDate));
    },
    next(){
      this.$store.commit("service/next");
    },
    previous() {
      this.$store.commit("service/previous");
    },

    editRecord: function(){
      this.$router.push({
        name: "serviceDetailForm",
        params: { serviceID: this.$store.getters["service/getCurrentService"].id }
      }).catch(()=>{});

    },



  },
  mounted() {
    if(this.$store.state.service.currentIndex == -1) {
      this.$store.dispatch("service/fetchService", this.$route.params.serviceID)
    }
  }
}
</script>

<style scoped lang="stylus">
.ServiceTable {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
}

.ServiceTable {
  tr {
    margin: 0;
    padding: 0;
  }

  td {
    border-bottom: 1px solid #EEEEEE;
    margin: 0;
    padding: 0;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 8px;
  }
}

.serviceRight{
  text-align right;
}
.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0
    padding 0

    padding-top 6px
    padding-bottom 8px
</style>