<template lang="pug">
  v-data-table(:headers='headers', :items='$store.state.fuelCard.fuelcard_data', item-key="id", :loading='loading', :single-select="false" show-select)
    v-progress-linear(slot='progress', height='2', :indeterminate='loading')

    template(v-slot:item.fuel_provider="{ item }")
      img(:src="`/static/web/${item.fuel_provider}.png`" style="width:40px;height:40px" )

    template(v-slot:item.vehicles="{ item }")
      .d-flex.flex-row.align-center.mb-1(v-for="vehicle,index in item.vehicles" :key="index")
        image-thumbnail.mr-3( :width="30" :height="30" :src="vehicle.thumbnail_id")
        .caption() {{vehicle.name}}

    template(v-slot:item.contacts="{ item }")
      span.mr-2(v-for="contact,index in item.contacts" :key="index") {{contact.first_name}} {{contact.last_name}},
      
    
    template( v-slot:item.action="{ item }")
      v-row.mr-7(justify="end")
        more-drop-down-menu(:items='menuItems', @onClick='onClick', :theObject='item')

      
</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
export default {
  components: {
    ImageThumbnail,
    MoreDropDownMenu
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: "Names",
        value: "name"
      },
      {
        text: "Provider",
        value: "fuel_provider"
      },
      {
        text: "Vehicles",
        value: "vehicles"
      },
      {
        text: "Contacts",
        value: "contacts"
      },
      {
        align: "right",
        value: "action"
      }
    ],
    items: [
      {
        name: "Mango",
        provider: "Shell",
        vehicles: "Tata",
        contacts: "mugambi"
      }
    ],
    menuItems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" }
    ]
  }),

  mounted() {},

  methods: {
    onClick: function(value) {
      switch (value.action) {
        case "edit":
          this.$store.commit(
            "fuelCard/setCurrentIndexByID",
            value.theObject.id
          );
          this.$router.push({
            name: "fuelCardForm",
            params: { fuelID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          confirm(
            `Are you sure you want to delete the fuel card item ${value.theObject.name}`
          ) &&
            this.$store.dispatch("fuelCard/deleteFuelCard", value.theObject.id);

          break;
      }
    }
  }
};
</script>
<style scoped lang="stylus"></style>
