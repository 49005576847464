<template lang="pug">
  .fill-width(v-if="$store.state.workflow.workflowRecord.currentIndex > -1")
    v-layout.mx-4.my-2(column)
      v-layout.mt-3(align-center, style="max-width:900px;")
        v-layout.px-3(row, wrap, align-center)
          search-autocomplete
          //- v-combobox(v-model='select1', :items='selectOptions1', label='Select a favourite activity or create a new one')
          //- v-text-field.pl-2(v-model='searchFilter', label='Search Filter', :persistent-hint='true', required)
          v-btn.mx-3(small, color="primary", dark) Search
          div
            span.primary--text 1 filter
            span.pl-1.grey--text.text--darken-1 applied
          a.px-3.grey--text.text--darken-1 clear all
    v-divider
    .grey.lighten-4.py-3
    v-divider
    record-table
    v-divider
    //- #previewWindow
</template>
<script>
import ImportFileDialog from "@/modules/global/components/table/ImportFileDialog";
import SearchAutocomplete from "@/modules/global/components/table/SearchAutocomplete";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import RecordTable from "@/modules/workflow/records/components/Tables/RecordTable";
export default {
  components: {
    ImportFileDialog,
    SearchAutocomplete,
    GenericBreadCrumbs,
    RecordTable
  },

  mounted() {
    this.$store.dispatch("workflow/workflowRecord/loadAllRecordsByID",this.$route.params.workflowID);
    console.log(this.$store.state.workflow.workflowRecord.currentIndex);
  },

  data: () => ({
    select1: "All Forms",
    selectOptions1: [
      "All Forms",
      "Overdue Only",
      "Overdue + Due Soon",
      "Due Soon Only"
    ],
    items: [
      {
        title: "Import Excel",
        value: "import_excel"
      },
      {
        title: "Import CSV",
        value: "import_csv"
      }
    ],

    vehiclesFilter: "",
    searchFilter: "",
    dialogShow: false,
    breadCrumbsItems: [
      {
        text: "Table",
        disabled: false,
        path: "reports"
      },
      {
        text: "Record",
        disabled: true,
        path: "vehicleListReport"
      }
    ]
  }),

  methods: {
    print: function() {
      console.log("print");
    },
    showDialog: function() {},

    onCloseDialog: function() {
      this.dialogShow = false;
      this.$store.dispatch("workflow/dataTable/loadAllDataTables",this.$route.params.workflowID);
    },

    onAction: function(value) {
      console.log("onAction: " + value);
    },

    uploadExcel: function() {
      this.dialogShow = true;
    }
  }
};
</script>
<style scoped lang="stylus">
#previewWindow {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 400px;
  height: 500px;
  max-height: 500px;
  background-color: #330033;
}
</style>
