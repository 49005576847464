<template lang="pug">
  v-breadcrumbs(:items="items")
    template(v-slot:item="props")
      v-breadcrumbs-item(:style="getBreadCrumbStyle(props.item)" @click="onClick(props.item)") {{ props.item.text}}
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        {
          text: "Primary",
          disabled: false,
          path: "primary",
        },
        {
          text: "Secondary",
          disabled: true,
          path: "secondary"
        }
      ]
    }
  },
  data: () => ({}),

  methods: {
    onClick: function(item) {
      if (!item.disabled) {
        console.log(item);
        if(item.tabID == undefined || item.tabID == null){
          this.$router.push({ name: item.path }).catch(()=>{});
        }else{
          this.$router.push({ name: item.path ,params:{tabID:item.tabID} }).catch(()=>{});
        }

      }
    },

    getBreadCrumbStyle: function(item) {
      let obj = {};
      if (item.disabled) {
        obj.color = "grey";
        obj["pointer-events"] = "none";
      } else {
        obj.color = this.$vuetify.theme.themes.light.primary;
        obj.cursor = "pointer";
        obj["text-decoration"] = "underline";
      }

      return obj;
    }
  }
};
</script>
<style scoped lang="stylus">
.disabledBreadCrumb {
  color: grey;
  pointer-events: none;
}

.enabledBreadCrumb {
  color: primary;
  text-decoration: underline;
}
</style>
