<template lang="pug">
  #blankcontent()
    .panelTitle() Activity Events
    v-divider.mx-2()
    .panelContent()
      v-progress-linear(v-if="isLoading" indeterminate)
      .d-flex.justify-center(v-if="hideLoader" )
        div(style="width:450px;")
          event-timeline(style="margin-left:-200px"  url="/activityEvent" @loadData="onLoadData" )

</template>
<script>
import EventTimeline from "@/modules/messaging/layouts/Events/EventTimeline.vue";

export default {
  components:{EventTimeline},
  props: {
    clientHeight: Number
  },
  data:()=>({
      hideLoader:false,
      isLoading:true,
  }),
  mounted() {
    if(this.$store.state.contacts.contact_data.length <= 1){
        Promise.all([this.$store.dispatch("contacts/loadAllContacts"),this.$store.dispatch("vehicles/loadAllItems")]).then(()=>{
            this.hideLoader = true;
        })
    }else{
        this.hideLoader = true;
    }
  },

    methods:{
        onLoadData: function(){
            this.isLoading = false;
        },
    }

};
</script>
<style scoped lang="stylus">
.lineVehicleStatus {
  border-bottom: 1px solid #f9f9f9;
  height: 50px;
}

#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 30px;
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.panelContent {
  height: calc(100% - 45px);
  overflow: auto;
}
</style>
