<template lang="pug">
  div(v-if="item")
    div()
    template(v-if="getMimeType")
      div(:style="getPhotoDocument" )
        div(:style="getImageCoverStyle" @click="showDialog()")
          v-layout.ma-1(align-start justify-end v-if="size==='small'")
            more-drop-down-menu(v-if="menuitems.length > 0" :items="menuitems" @onClick="onClick" :size="size" style="opacity:0.8")
        div(:style="getFileVehicleDetails" )
          .fileInfo.grey--text.text--darken-2
            v-tooltip(bottom)
              span {{item.module}} photo
            .cut-text.caption(:style="getCaptionStyle")
              span(:class="[ this.size==='large' ? 'title' : '']") {{item.name}} &nbsp;
            v-tooltip(bottom v-if="!(this.size === 'small')" )
              template(v-slot:activator="{ on }")
                v-icon(v-on="on" small) info_outline
              span 
                b Original File Name: 
                <br/>{{item.original_ngetImageCoverStyleame}}
          more-drop-down-menu( v-if="!(this.size === 'small')" :items="menuitems" @onClick="onClick" )
        .largeDescription( v-if="this.size === 'large'")
          | {{item.description}}
    //- if it is text
    template(v-else)
      .textStyle() 
        |{{item.name}}
        more-drop-down-menu.ml-2( :items="menuitems" @onClick="onClick" :size="size" style="opacity:0.8")
    a(:href="`/media/${item.account_id}/${item.file_name}`" ref="downloadLink" :download="item.original_name" hidden )
    v-dialog(v-if="dialog" v-model="dialog"  width="unset")
      v-card()
        v-toolbar(color='primary' dark style="position:fixed;")
          v-toolbar-title.text-subtitle-1(v-html="isSignature? 'Signature': item.original_name")
          v-btn.mr-2.ml-4(color="white" small @click="showDialog()")
            v-icon(color="primary") close
        img(:src="`/media/${this.item.account_id}/${this.item.file_name}`")
</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import Repository from "@/repository";
export default {
  components: {
    MoreDropDownMenu
  },
  props: {
    id: String,
    size: {
      type:String,
      default:"small"
    },
    showEditMenuItem:{
      type:Boolean,
      default: false,
    },
    showDeleteMenuItem:{
      type:Boolean,
      default: false,
    },
    showDownloadMenuItem:{
      type:Boolean,
      default: false,
    },
    isSignature:{
      type: Boolean,
      default: false,
    },
    moreMenu: Array
  },
  data: () => ({
    dialog:false,
    item:null,
    icons: {
      vehicle: "local_shipping",
      service: "build",
      issues: "warning",
      fuel: "local_gas_station",
      work_order: "assignment"
    },

    // Menu Items for Drop Menu
    menuitems: []
  }),

  watch: {
    src: function(newValue) {
      console.log(`Watched hahahaha!! ${newValue}`);
      if (newValue) {
        this.loadImage();
      } else {
        this.resultObject = null;
      }
    },
    id: function (newValue) {
      if (newValue) {
        this.loadImage();
      } else {
        this.resultObject = null;
      }
    }

  },

  mounted() {
    console.log(`The Source is ${this.id}`)
    // add aditional Menu items in the thumbnail if needed
    if(this.showEditMenuItem){
      this.menuitems.push( { title: "Edit", icon: "edit", action: "edit" });
    }

    if(this.showDeleteMenuItem){
      this.menuitems.push( { title: "Delete", icon: "delete", action: "delete" });
    }

    if(this.showDownloadMenuItem){
      this.menuitems.push({ title: "Download", icon: "cloud_download", action: "download" });
    }

    if (this.moreMenu) {
      this.moreMenu.forEach(item => {
        this.menuitems.push(item);
      });
    }
    this.loadImage();
  },

  computed: {
    // style for Thumbnail Caption
    getCaptionStyle: function() {
      switch (this.size) {
        case "small":
          return {width:"150px"};
        case "large":
          return  {width:"240px"};
        default:
          return {width:"540"};
      }
    },
    // Styles Formating
    getPhotoDocument: function() {
      let mediumStyle = {
        "background-color": "#ffffff",
        "border-radius": "8px",
        "cursor":"pointer",
        border: "1px solid #BEBEBE",
        width: "300px",
        padding: "10px",
        display: "flex",
        "flex-direction": "column",
        "align-items": "center"
      };

      let smallStyle = {
        "background-color": "#ffffff",
        "border-radius": "6px",
        "cursor":"pointer",
        border: "1px solid #BEBEBE",
        width: "150px",
        padding: "5px",
        display: "flex",
        "flex-direction": "column",
        "align-items": "center"
      };

      let largeStyle = {
        "background-color": "#ffffff",
        "border-radius": "15px",
        "cursor":"pointer",
        border: "1px solid #BEBEBE",
        width: "600px",
        padding: "14px",
        display: "flex",
        "flex-direction": "column",
        "align-items": "center"
      };

      switch (this.size) {
        case "small":
          return smallStyle;
        case "large":
          return largeStyle;
        default:
          return mediumStyle;
      }
    },

    getImageCoverStyle: function() {
      let mediumStyle = {
        width: "280px",
        height: "180px",
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-image": `url("/media/${this.item.account_id}/${this.item.thumbnail}")` //to resize for thumbnail i.e. have a way to get the thumbnail and cache it
      };

      let smallStyle = {
        width: "140px",
        height: "90px",
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-image": `url("/media/${this.item.account_id}/${this.item.thumbnail}")` //to resize for thumbnail i.e. have a way to get the thumbnail and cache it
      };

      let largeStyle = {
        width: "560px",
        height: "360px",
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-image": `url("/media/${this.item.account_id}/${this.item.file_name}")`
      };

      if(this.isSignature){
        smallStyle["background-image"] = `url("/media/${this.item.account_id}/${this.item.file_name}")`;
        mediumStyle["background-image"] = `url("/media/${this.item.account_id}/${this.item.file_name}")`;
      }

      switch (this.size) {
        case "small":
          return smallStyle;
        case "large":
          return largeStyle;
        default:
          return mediumStyle;
      }
    },

    getFileVehicleDetails: function() {
      let mediumStyle = {
        "padding-top": "10px",
        display: "flex",
        "flex-direction": "row",
        width: "100%"
      };

      let largeStyle = {
        padding: "15px 15px",
        display: "flex",
        "flex-direction": "row",
        width: "100%"
      };

      switch (this.size) {
        case "small":
          return {};
        case "large":
          return largeStyle;
        default:
          return mediumStyle;
      }
    },

    getMimeType: function() {
      if (
        this.item.mime_type === "image/bmp" ||
        this.item.mime_type === "image/jpeg" ||
        this.item.mime_type === "image/png"
      )
        return true;
      else return false;
    }
  },

  methods: {
    showDialog:function(){
      this.dialog = !this.dialog;
    },
    loadImage: function() {
      Repository.get(`/upload/blob/${this.id}`)
          .then(payload => {
            this.item = payload.data;
            console.log(this.item);

          })
          .catch(payload => {
            console.log(payload.data);
          });
    },

    onClick: function(value) {
      value.theObject = this.item;
      if (value.action == "download") {
        this.$refs.downloadLink.click();
      } else {
        this.$emit("onMenuAction", value);
      }
    },

    downloadFile: function() {}
  }
};
</script>
<style scoped lang="stylus">
.theIconMenus {
  display: flex;
  justify-content: space-between;
  padding: 2px 2px;
}

.fileInfo {
  width: 100%;
}

.largeDescription {
  text-align: left;
  width: 100%;
  padding: 0px 15px;
}

.textStyle {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #BEBEBE;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  padding 2px 5px 0px 5px;
  white-space: nowrap;
  text-align center;
  direction: rtl;
}
</style>
