<template lang="pug">
div
  v-layout(align-center justify-end)
    v-btn.ma-2(small='', color='primary', dark='', @click='addNew') Add New
  form-sub-header Fuel Type Settings
  v-data-table(:headers='headers', :items='$store.state.settings.fuelTypeSettings.fueltype_data', :loading='loading')
    v-progress-linear(slot='progress', height='2', :indeterminate='loading')
    template(slot='items', slot-scope='props')
      td.nameColumnTitle
        | {{ props.item.name }} 
        .nameColumnDescription {{ props.item.description }}
      td.text-xs-right
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='props.item')
</template>
<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";

export default {
  components: {
    FormSubHeader,
    MoreDropDownMenu
  },

  data: () => ({
    loading: false,
    headers: [
      { text: "Name", value: "name", align: "left" },
      { text: "Actions", value: null, align: "right" }
    ],

    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" }
    ]
  }),

  methods: {
    addNew: function() {
      console.log("add New");
      this.$store.dispatch("settings/fuelTypeSettings/addFuelType").then(() => {
        // console.log(this.$store.getters["settings/tagSettings/getCurrentTag"].id)
        this.$router.push({
          name: "fuelTypeForm",
          params: {
            fuelTypeID: this.$store.getters[
              "settings/fuelTypeSettings/getCurrentFuelType"
            ].id
          }
        }).catch(()=>{});
      });
    },

    onClick: function(value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          this.$router.push({
            name: "fuelTypeForm",
            params: { fuelTypeID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          this.$store.dispatch(
            "settings/fuelTypeSettings/deleteFuelType",
            value.theObject.id
          );
          break;
      }
    }
  },

  mounted() {
    if (this.$store.state.settings.fuelTypeSettings.currentIndex == -1) {
      this.$store.dispatch("settings/fuelTypeSettings/loadAllFuelTypes");
    }
  }
};
</script>
<style scoped lang="stylus">
.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;
  padding: 0px;
}
</style>
