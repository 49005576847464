import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const MESSAGE_API = "/sms";

// ----------------------- STATE ------------------------- //
const state = {
    sms_data: [],
    currentIndex: -1,
    selectedItems: [],
    unread:0,
    read:0,
    total:0,
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getSms: state => getField(state.sms_data[state.currentIndex]),

    getFilterByGroup: (state) => (group) => {
        let data = [];
        switch(group){
            case "Unread": data = state.sms_data.filter(element => element.status == 1); break;
            case "Read": data = state.sms_data.filter(element => element.status == 3); break;
            default: data = state.sms_data;
        }
        return data;
    },

    getCurrentSms: state => {
        return state.sms_data[state.currentIndex];
    },
    getLastSmss: state => maxItems => {
        if (state.sms_data.length > maxItems - 1) {
            return state.sms_data.slice(0, maxItems);
        } else {
            return state.sms_data;
        }
    },

    unreadSmss: state => {
        if (state.sms_data.length == 0) {
            return 0;
        }else{
            let read = 0;
            state.sms_data.forEach(element => {
                if (element.status == 3) {
                    read += 1;

                }
            })

            return state.sms_data.length - read;
        }

    }

};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateSms(state, payload) {
        updateField(state.sms_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.sms_data.findIndex(
            sms => sms.id == theID
        );
    },

    setNext(state){
        state.currentIndex += 1;
        if(state.sms_data[state.currentIndex].status != null){
            state.sms_data[state.currentIndex].status = 3;
        }
    },
    setPrevious(state){
        state.currentIndex -= 1;
        if(state.sms_data[state.currentIndex].status != null){
            state.sms_data[state.currentIndex].status = 3;
        }
    },

    setSelectedItems(state,payload){
      state.selectedItems = payload;
    },


    markCurrentAsRead(state){
        if(state.sms_data[state.currentIndex].status != null){
            state.sms_data[state.currentIndex].status = 3;
        }
    },

    loadAllSms(state, payload) {
        state.sms_data = payload.data;
        state.currentIndex = 0;
    },



    addSms(state, payload) {
        state.sms_data.unshift(payload.data);
        state.currentIndex = state.sms_data.length - 1;
    },

    saveContactSms(state, payload) {
        state.sms_data[state.currentIndex] = payload.data;
    },

    fetchSms(state, payload) {
        if (state.currentIndex == -1) {
            state.sms_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.sms_data[state.currentIndex] = payload.data;
        }
    },

    fetchSmsListByReference(state, payload) {
        if (payload.data.length > 0) {
            state.currentIndex = 0;
        }
        state.sms_data = payload.data;
    },

    deleteSms(state, payload) {
        state.currentIndex = state.sms_data.findIndex(
            sms => sms.id == payload.data.id
        );
        state.sms_data.splice(state.currentIndex, 1);
        state.currentIndex = 0;
    },

    deleteManySmss(state,payload){
      let deletedIDs  = payload.data;

            deletedIDs.forEach(id => {
                for(let i=0; i<state.sms_data.length; i++) {
                    if(id === state.sms_data[i].id){
                        state.sms_data.splice(i, 1);
                    }
                }

            })
    },
    markManyAsRead(state,payload){
      let deletedIDs  = payload.data;
            deletedIDs.forEach(id => {
                for(let i=0; i<state.sms_data.length; i++) {
                    if(id === state.sms_data[i].id){
                        state.sms_data[i].status = 3;
                    }
                }

            })
    },

    reCalculateStatus(state) {
        state.unread = 0;
        state.total = 0;
        state.sms_data.forEach(element => {
            if (element.status == 1) {
                state.unread += 1;

            }
            state.total += 1;
        })

        state.read = state.total - state.unread;
    },

    ContactSmsStatus(state, payload) {
        state.unread = payload.data.unread
        state.total = payload.data.total
        state.read = payload.data.read
    },
    reset(state) {
        state.sms_data = []
        state.currentIndex = -1
        state.selectedItems = []
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllContactSms({commit}) {
        try {
            commit("loadAllSms", await Repository.get(`${MESSAGE_API}/contact`));
        } catch (error) {
            console.log(error);
        }
    },

    async ContactSmsStatus({commit}) {
        try {
            commit("ContactSmsStatus", await Repository.get(`${MESSAGE_API}/contact/count`));
        } catch (error) {
            console.log(error);
        }
    },

    async loadAllSms({commit}) {
        try {
            commit("loadAllSms", await Repository.get(`${MESSAGE_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addSms({commit}, payload) {
        await Repository.post(`${MESSAGE_API}/new`, payload)
            .then(response => {
                commit("addSms", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveContactSms({state, commit}) {
        try {
            commit(
                "saveContactSms",
                await Repository.post(
                    `${MESSAGE_API}`,
                    state.sms_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchSms({commit}, payloadID) {
        try {
            commit(
                "fetchSms",
                await Repository.get(`${MESSAGE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchSmsListByReference({commit}, referenceID) {
        try {
            console.log(`${MESSAGE_API}/reference/${referenceID}`);
            commit(
                "fetchSmsListByReference",
                await Repository.get(`${MESSAGE_API}/reference/${referenceID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteManySmss({commit},payload) {
        try {
            commit(
                "deleteManySmss",
                await Repository.post(`${MESSAGE_API}/${payload.type}/delete`,payload.ids)
            );
        } catch (error) {
            console.log(error);
        }
    },
    async markManyAsRead({commit},payload) {
        try {
            commit(
                "markManyAsRead",
                await Repository.post(`${MESSAGE_API}/contact/markManyAsRead`,payload)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteSms({commit}, payloadID) {
        try {
            commit(
                "deleteSms",
                await Repository.delete(`${MESSAGE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchSmss({commit}, searchTerm) {
        try {
            commit(
                "loadAllSmss",
                await Repository.get(`${MESSAGE_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
