<template lang="pug">
  v-layout.ma-2(fill-height)
    v-flex(xs12)
      attach-fill-form( :schema="documentsSchama" :data="{value:documents}" @onChange="saveDocuments"  @onMenuAction="onMenuActionDocuments")
</template>
<script>
import { createHelpers } from "vuex-map-fields";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
const { mapFields } = createHelpers({
  getterType: "vehicles/getItem",
  mutationType: "vehicles/updateItem"
});
export default {
  components: {
    AttachFillForm
  },
  data: () => ({
    documentsSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Vehicle Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true
    }
  }),
  computed: {
    ...mapFields({
      documents: "documents"
    })
  },
  methods: {
    saveDocuments: function(theObject) {
      this.$store.commit("vehicles/updateItem", {
        path: "documents",
        value: theObject.value
      });
      this.saveVehicleToServer();
    },

    onMenuActionDocuments: function(value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("vehicles/deleteDocument", value.theObject.id);
          break;
      }
      this.saveVehicleToServer();
    },

    saveVehicleToServer: function() {
      this.$store.dispatch("vehicles/saveCurrentAndDoNothing");
    }
  }
};
</script>
<style scoped lang="stylus"></style>
