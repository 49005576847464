import { render, staticRenderFns } from "./GroupsAutoComplete.vue?vue&type=template&id=dd567e88&scoped=true&lang=pug"
import script from "./GroupsAutoComplete.vue?vue&type=script&lang=js"
export * from "./GroupsAutoComplete.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd567e88",
  null
  
)

export default component.exports