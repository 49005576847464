<template lang="pug">
  div
    v-container.pt-0(grid-list-sm)
      form-divider FUEL
      v-layout(row, wrap, justify-start)
        v-flex(xs12, md6, xl4)
          auto-combo-box(v-model='fuel_type_id' label="Fuel Type"  module="settings" submodule="fuelTypeSettings"  actionPrefix="FuelType")
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='fuel_tank_capacity', label='Fuel Tank Capacity in L', type='number', hint='The Volume of Fuel Tank Capacity in L', persistent-hint)
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='fuel_mpg', label='Compined Fuel Economy (MPG)', type='number', hint='How far a car can travel in one Galon of fuel', persistent-hint)
        v-flex(xs12, md6, xl4)
          v-text-field(v-model='monthly_fuel_budget', label='Monthly Budget for Vehicle', type='number', hint='Monthly Budget for Vehicle', persistent-hint)


</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import { createHelpers } from "vuex-map-fields";
import AutoComboBox from "@/modules/global/components/forms/AutoComboBox";

const { mapFields } = createHelpers({
  getterType: "vehicles/getItem",
  mutationType: "vehicles/updateItem"
});

export default {
  components: {
    AutoComboBox,
    FormDivider
  },

  data: () => ({
    // green, blue, orange, red, gray

  }),

  computed: {
    ...mapFields(["fuel_type_id", "fuel_tank_capacity", "fuel_mpg","monthly_fuel_budget"])
  }
};
</script>

<style scoped>
.topiDivider {
  color: #aa00cc;
  border-bottom: 2px solid #aa00cc;
  margin-top: 20px;
  font-size: 18px;
}
</style>
