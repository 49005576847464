import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const USER_API = "/user";

// ----------------------- STATE ------------------------- //
const state = {
    user_data: [],
    currentIndex: -1,
    isContactUser: null // state: true or false
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getUser: state => getField(state.user_data[state.currentIndex]),
    getUserSingle: state => getField(state.user_data[0]),
    getCurrentUser: state => {
        return state.user_data[state.currentIndex];
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateUser(state, payload) {
        updateField(state.user_data[state.currentIndex], payload);
    },
    updateUserSingle(state, payload) {
        updateField(state.user_data[0], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.user_data.findIndex(user => user.id == theID);
    },

    loadAllUsers(state, payload) {
        state.user_data = payload.data;
        state.currentIndex = 0;
    },

    addUser(state, payload) {
        state.user_data.push(payload.data);
        state.currentIndex = state.user_data.length - 1;
    },

    enableUser(state, payload) {
        console.log(payload.data);
        state.user_data.push(payload.data);
        state.currentIndex = state.user_data.length - 1;
    },

    saveUser(state, payload) {
        state.user_data.push(payload.data);
        state.currentIndex = -1;
    },

    fetchUser(state, payload) {
        if (state.currentIndex == -1) {
            state.user_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.user_data[state.currentIndex] = payload.data;
        }
    },

    fetchUserByContact(state, payload) {
        state.user_data.push(payload.data);
        state.currentIndex = -1;
    },

    deleteUser(state, payload) {
        state.currentIndex = state.user_data.findIndex(
            user => user.id == payload.data.id
        );
        state.user_data.splice(state.currentIndex, 1);
    },

    deactivateUser(state, payload) {
        state.currentIndex = state.user_data.findIndex(user => user.id == payload.data.id);
        state.user_data[state.currentIndex].active = false;

    },
    activateUser(state, payload) {
        state.currentIndex = state.user_data.findIndex(user => user.id == payload.data.id);
        state.user_data[state.currentIndex].active = true;
    },

    //check if the contactID is a user
    isContactUser(state, payload) {
        console.log(payload.data);
        state.isContactUser = payload.data.isContactUser;
    },

    resetStore(state) {
        state.user_data = [];
        state.currentIndex = -1;
    },
    reset(state) {
        state.user_data = []
        state.currentIndex = -1
        state.isContactUser = null // state: true or false
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllUsers({commit}) {
        try {
            commit("loadAllUsers", await Repository.get(`${USER_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addUser({commit}) {
        await Repository.get(`${USER_API}/new`)
            .then(response => {
                commit("addUser", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async enableUser({commit}, payload) {
        console.log("payload is");
        console.log(payload);
        await Repository.post(
            `${USER_API}/enableUser/${payload.contact_id}`,
            payload
        )
            .then(response => {
                commit("enableUser", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveUser({commit}, payload) {
        commit("resetStore");
        console.log(payload);
        try {
            commit("saveUser", await Repository.post(`${USER_API}`, payload));
        } catch (error) {
            console.log(error);
        }
    },

    async fetchUser({commit}, payloadID) {
        try {
            commit("fetchUser", await Repository.get(`${USER_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async fetchUserByContact({commit}, contactID) {
        commit("resetStore");
        try {
            commit(
                "fetchUserByContact",
                await Repository.get(`${USER_API}/contact/${contactID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteUser({commit}, payloadID) {
        try {
            commit("deleteUser", await Repository.delete(`${USER_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },
    async deactivateUser({commit}, payloadID) {
        try {
            commit("deactivateUser", await Repository.get(`${USER_API}/deactivate/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },
    async activateUser({commit}, payloadID) {
        try {
            commit("activateUser", await Repository.get(`${USER_API}/activate/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },



    async isContactUser({commit}, contactID) {
        console.log("is contact user");
        console.log(`${USER_API}/isContactUser/${contactID}`);
        try {
            commit(
                "isContactUser",
                await Repository.get(`${USER_API}/isContactUser/${contactID}`)
            );
        } catch (error) {
            console.log("there is an error");
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
