<template lang="pug">
  div(:style="getStyle" @mouseover="showEdit=true" @mouseout="showEdit=false")
    .d-flex.caption(v-if="resultObject") {{resultObject.original_name}}
    v-btn.ml-2(text icon color="primary"  @click="onClick")
      v-icon(small) get_app

</template>
<script>
import Repository from "@/repository";

export default {
  components: {},
  props: {
    src: String,

    route: {
      type: Object,
      default: null
    },

    radius: {
      type: Number,
      default: 6
    }
  },
  data: () => ({
    resultObject: null,
    originalName: null,
    isDownloadFinished:true
  }),

  computed: {
    getStyle: function() {
      var styleObject = {
        border: "1px solid #E5E5E5",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "border-radius": `${this.radius}px`,
        "padding-left": "10px"
      };
      if (this.resultObject) {
        // path string
        styleObject["border-style"] = "solid";
        styleObject["border-width"] = "1px";
      } else {
        styleObject["border-style"] = "dashed";
        styleObject["border-width"] = "2px";
      }

      return styleObject;
    }
  },

  watch: {
    src: function(newValue) {
      if (newValue) {
        this.loadBlob();
      } else {
        this.resultObject = null;
      }
    }
  },

  methods: {
    loadBlob: function() {
      Repository.get(`/upload/blob/${this.src}`)
        .then(payload => {
          console.log(payload.data);
          this.resultObject = payload.data;
        })
        .catch(payload => {
          console.log(payload.data);
        });
    },


    onClick:function() {
      this.downloadFile(this.resultObject);
    },

    downloadFile: function(theResultObject) {


        this.fileLink = document.createElement("a");

        // this.fileLink.href = fileURL;
        this.fileLink.href = `/media/${theResultObject.account_id}/${theResultObject.file_name}`;
        this.fileLink.setAttribute("download", theResultObject.original_name);
        document.body.appendChild(this.fileLink);
        this.fileLink.click();


    },

    onDownload: function() {
      // download the file
      this.fileLink.click();
      this.onCloseExportDialog();
    },




  },

  mounted() {
    if (this.src) {
      this.loadBlob();
    }
  }
};
</script>
<style scoped lang="stylus"></style>
