<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    
    v-text-field(v-model="selectHint" label="Description")
    
    v-checkbox(v-model="selectRequired" label="Required")
    v-checkbox(v-if="selectOptions" v-model="selectCommentOnFail" :label="`Take picture or comment when '${selectOptions[1]}' selected`")

    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        showAdvancedProperties(:showAdvanced="showAdvanced" )
      div(v-show="showAdvanced")
        v-select(v-model="selectedType"  label="Data Type" item-text="text" item-value="value" :items="switchTypes")
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)

</template>
<script>
// var snakeCase = require('lodash.snakecase');
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";
import {inspectionPropertiesMixin} from "@/modules/inspections/components/formDesigner/properties/inspectionPropertiesMixin";

export default {
  mixins:[inspectionPropertiesMixin],
  
  data: () => ({
    switchTypes:[
      {
        value:"pass_fail",
        text:"Pass or Fail",
      },
      {
        value:"true_false",
        text:"True or False",
      },
      {
        value:"yes_no",
        text:"Yes or No",
      },
      {
        value:"success_failed",
        text:"Success or Failed",
      }
    ]
  }),

  computed: {
    selectedType: {
      get: function() {
        return this.properties.type || this.switchTypes[0];
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "type",
          value: value
        });
        let theOptions = [];
        switch(value){
          case 'pass_fail': theOptions = ["Pass", "Fail"]; break;
          case 'true_false': theOptions = ["True", "False"]; break;
          case 'yes_no': theOptions = ["Yes", "No"]; break;
          case 'success_failed': theOptions = ["Success", "Failure"]; break;
        }
        this.selectOptions = theOptions;
      }
    },

    selectOptions: {
      get: function() {
        return this.properties.options;
      },
      set: function(value) {
        this.errorMessages = [];
        if (value.length <= 0) {
          this.errorMessages.push("Enter option and press `enter`");
        }
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "options",
          value: value
        });
      }
    },

  },
};
</script>
<style scoped lang="stylus">
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}
</style>
