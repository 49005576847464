import { render, staticRenderFns } from "./VehicleNewLayout.vue?vue&type=template&id=ca5675aa&scoped=true&lang=pug"
import script from "./VehicleNewLayout.vue?vue&type=script&lang=js"
export * from "./VehicleNewLayout.vue?vue&type=script&lang=js"
import style0 from "./VehicleNewLayout.vue?vue&type=style&index=0&id=ca5675aa&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca5675aa",
  null
  
)

export default component.exports