<template lang="pug">
  div(v-if="$store.state.contacts.currentIndex != -1")
    v-layout.pt-2.pr-3(align-center)
      v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
      v-spacer
      v-btn.ma-2(small color="primary" outlined dark @click="cancel") cancel

      v-btn.ma-2(v-if="$route.name != 'contactUserInfo'" small color="primary" @click="save") save
    v-container(grid-list-xl)
      v-layout(row wrap justify-end)
        v-flex(xs12 md3 xl3 class="hidden-sm-and-down")
          sub-navigation(:menuItems="menuItems" title="Contact Information" :selectedIndex="selectedMenuIndex"  @selectedForm="onSelectForm")
        v-flex(xs12 md9 xl9)
          v-card(outlined)
            v-progress-linear(v-show="showProgress" indeterminate)
            .HeaderBody.py-2
              .HeaderInfo
                image-thumbnail.mr-3.ml-3(:width="80" :height="80"  :src="currentContact.thumbnail_id")
                .HeaderText
                  .headline.font-weight-bold.grey--text.text--darken-3
                    | {{currentContact.first_name}} . {{currentContact.last_name}}
                  p.ma-0.pa-0.caption.font-weight-regular
                    | {{currentContact.email}} . {{currentContact.mobile}}
                  v-divider.mt-1
                  p.ma-0.pa-0.caption.grey--text.font-weight-regular
                    span() status .
                    span(v-if="currentContact.group") {{currentContact.group.name}} .
                    span() {{currentContact.id}}
              v-spacer

            v-divider
            router-view


</template>
<script>
import SubNavigation from "@/modules/global/components/navigation/SubNavigation";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";

export default {
  components: {
    SubNavigation,
    ImageThumbnail
  },

  data: () => ({
    showProgress: false,
    selectedForm: "BasicInformation",
    selectedMenuIndex: 0,

  }),

  computed: {
    currentContact: {
      get: function () {
        console.log("*******************************************")
        console.log(this.$store.getters["contacts/getCurrentContact"])
        return this.$store.getters["contacts/getCurrentContact"];
      }
    },
    menuItems: function () {
      let menuItems = [];
      menuItems.push({
            title: "Basic Information",
            iconClass: "primary white--text",
            icon: "person",
            url: "contactNewBasic"
          }
      );
      if(this.$store.state.profile.userInfo.is_administrator) {
          menuItems.push({
                  title: "User Management",
                  iconClass: "secondary white--text",
                  icon: "person_add",
                  url: "contactUserInfo"
              }
          );
      }

      menuItems.push({
            title: "Contact Information",
            icon: "contacts",
            iconClass: "secondary white--text",
            url: "contactNewInfo"
          }
      );
      menuItems.push(
          {
            title: "Personal Information",
            icon: "contact_phone",
            iconClass: "secondary white--text",
            url: "contactNewPersonal"
          }
      )


      return menuItems;

    },
  },

  methods: {


    save: function () {
      this.$store.dispatch("contacts/saveContact").then(() => {
        this.$store.dispatch("contacts/loadAllContacts").then(() => {
          this.$router.push({name: "contacts"}).catch(()=>{});
        });
      });
    },

    cancel: function () {
      this.$store.dispatch("contacts/loadAllContacts").then(() => {
        this.$router.push({name: "contacts"}).catch(()=>{});
      });
    },

    onSelectForm: async function (index) {
      console.log(this.currentContact)

      if (this.currentContact.first_name?.length == 0 || this.currentContact.last_name?.length == 0 || this.currentContact.email?.length == 0 || this.currentContact.mobile?.length == 0) {
        this.$store.commit("showSnackBar", {
          color: "error",
          message: "<B>Fill in all required fields</B>"
        });
      } else {
        this.$router.push({name: this.menuItems[index].url}).catch(()=>{});
      }

      this.selectedMenuIndex = index;
    },
    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Contact List",
        disabled: false,
        to: {name: "contacts"}
      });
      theArray.push({
        text:
            this.currentContact.first_name + " " + this.currentContact.last_name,
        disabled: true
      });
      return theArray;
    }
  }
};
</script>
<style lang="stylus" scoped>
.headerButtons {
  display: flex;
  justify-content: flex-end;
}

.HeaderText {
  display: flex;
  flex-direction: column;
}

.HeaderInfoButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}

.HeaderBody {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.HeaderInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
</style>
