import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const TAG_API = "/tag";

// ----------------------- STATE ------------------------- //
const state = {
  tag_data: [],
  currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getTag: state => getField(state.tag_data[state.currentIndex]),

  getCurrentTag: state => {
    return state.tag_data[state.currentIndex];
  },

  getTagByID: (state) => (id) => {
    let result = state.tag_data.find((element) => element.id == id)
    if (result != undefined){
      return result;
    }else{
      return null
    }
  },

  getTagsMinusSelf: state => {
    let myArray = [
      {
        id: "00000000-0000-0000-0000-000000000000",
        name: "None"
      }
    ];
    for (var i = 0; i < state.tag_data.length; i++) {
      if (state.tag_data[state.currentIndex].id != state.tag_data[i].id) {
        myArray.push(state.tag_data[i]);
      }
    }

    return myArray;
  },



};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updateTag(state, payload) {
    updateField(state.tag_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.tag_data.findIndex(tag => tag.id == theID);
  },

  loadAllTags(state, payload) {
    state.tag_data = payload.data;
    state.currentIndex = 0;
  },

  addTag(state, payload) {
    state.tag_data.push(payload.data);
    state.currentIndex = state.tag_data.length - 1;
  },

  saveTag(state, payload) {
    state.tag_data[state.currentIndex] = payload.data;
  },

  fetchTag(state, payload) {
    if (state.currentIndex == -1) {
      state.tag_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.tag_data[state.currentIndex] = payload.data;
    }
  },

  deleteTag(state, payload) {
    state.currentIndex = state.tag_data.findIndex(
      tag => tag.id == payload.data.id
    );
    state.tag_data.splice(state.currentIndex, 1);
  },

  reset(state) {
    state.tag_data = []
    state.currentIndex = -1
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllTags({ commit }) {
    try {
      commit("loadAllTags", await Repository.get(`${TAG_API}`));
    } catch (error) {
      console.log(error);
    }
  },

  async addTag({ commit }) {
    await Repository.get(`${TAG_API}/new`)
      .then(response => {
        commit("addTag", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async saveTag({ state, commit }) {
    try {
      commit(
        "saveTag",
        await Repository.post(`${TAG_API}`, state.tag_data[state.currentIndex])
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchTag({ commit }, payloadID) {
    try {
      commit("fetchTag", await Repository.get(`${TAG_API}/${payloadID}`));
    } catch (error) {
      console.log(error);
    }
  },

  async deleteTag({ commit }, payloadID) {
    try {
      commit("deleteTag", await Repository.delete(`${TAG_API}/${payloadID}`));
    } catch (error) {
      console.log(error);
    }
  },

  async searchTags({ commit }, searchTerm) {
    try {
      commit(
        "loadAllTags",
        await Repository.get(`${TAG_API}/search?q=${searchTerm}`)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
