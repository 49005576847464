import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const ODOMETER_API = "/odometer";

// ----------------------- STATE ------------------------- //
const state = {
    odometer_data: [],
    currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getOdometer: state => getField(state.odometer_data[state.currentIndex]),

    getCurrentOdometer: state => {
        return state.odometer_data[state.currentIndex];
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateOdometer(state, payload) {
        updateField(state.odometer_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.odometer_data.findIndex(
            odometer => odometer.id == theID
        );
    },

    loadAllOdometers(state, payload) {
        console.log("loaded odometers");
        state.odometer_data = payload.data;
        state.currentIndex = 0;
    },

    addOdometer(state, payload) {
        state.odometer_data.push(payload.data);
        state.currentIndex = state.odometer_data.length - 1;
    },

    saveOdometer(state, payload) {
        state.odometer_data[state.currentIndex] = payload.data;
    },

    fetchOdometer(state, payload) {
        if (state.currentIndex == -1) {
            state.odometer_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.odometer_data[state.currentIndex] = payload.data;
        }
    },





    deleteOdometer(state, payload) {
        state.currentIndex = state.odometer_data.findIndex(
            odometer => odometer.id == payload.data.id
        );
        state.odometer_data.splice(state.currentIndex, 1);
    },
    reset(state) {
        state.odometer_data = []
        state.currentIndex = -1
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllOdometers({commit}) {
        try {
            commit("loadAllOdometers", await Repository.get(`${ODOMETER_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addOdometer({commit}) {
        await Repository.get(`${ODOMETER_API}/new`)
            .then(response => {
                commit("addOdometer", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveOdometer({state, commit}) {
        try {
            commit(
                "saveOdometer",
                await Repository.post(
                    `${ODOMETER_API}`,
                    state.odometer_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchOdometer({commit}, payloadID) {
        try {
            commit(
                "fetchOdometer",
                await Repository.get(`${ODOMETER_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteOdometer({commit}, payloadID) {
        try {
            commit(
                "deleteOdometer",
                await Repository.delete(`${ODOMETER_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchOdometers({commit}, searchTerm) {
        try {
            commit(
                "loadAllOdometers",
                await Repository.get(`${ODOMETER_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
