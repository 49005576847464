<template lang="pug">
  div()
    .title.pa-3(:class="['grey lighten-4']")
      | Personal Information
    v-divider()
    .theform
      v-form(v-model='valid')
        v-flex.px-4(md12)
          v-container(grid-list-sm)
            v-layout(row, wrap, justify-end)
              v-flex(md12, xs12, xl6)
                v-text-field(v-model='jobTitle', label='Job Title')
              v-flex(md12, xs12, xl6)
                date-picker(v-model='date', label="Date of Birth")
              v-flex(md12, xs12, xl4)
                v-text-field(v-model='employeeNumber', label='Employee Number')
              v-flex(md6, xs12, xl4)
                date-picker(v-model='startDate', label="Start Date")
              v-flex(md6, xs12, xl4)
                date-picker(v-model='endDate', label="End Date")
              v-flex(md12, xs12, xl6)
                v-text-field(v-model='licenseNo', label='License Number')
              v-flex(md12, xs12, xl6)
                v-text-field(v-model='licenseClass', label='License Class')
              v-flex(md12)
                v-text-field(v-model='labourRate', label='Daily Labour Rate')
              v-row.mx-0(v-if="getCustomFields.length > 0")
                v-col.mt-3(v-for="(element,index) in getCustomFields" :key="index" cols="12" xs="12" md="6" xl="6")
                  custom-field-component(:element="element" getterCurrentItemPath="contacts/getCurrentContact" mutationTypePath="contacts/updateContact")
</template>

<script>
import DatePicker from "@/modules/global/components/forms/FormDatePicker";
import { createHelpers } from "vuex-map-fields";
import CustomFieldComponent from "@/modules/global/components/forms/CustomFieldComponent.vue";

const { mapFields } = createHelpers({
  getterType: "contacts/getContact",
  mutationType: "contacts/updateContact"
});

export default {
  components: {
    DatePicker,
    CustomFieldComponent
  },
  data: () => ({
    valid: false
  }),

  computed: {
    ...mapFields({
      jobTitle: "job_title",
      licenseNo: "license_no",
      licenseClass: "license_class",
      labourRate: "labour_rate",
      date: "date_of_birth",
      startDate: "start_date",
      endDate: "end_date",
      employeeNumber: "employee_number"
    }),

    getCustomFields(){
      return this.$store.getters["profile/getCustomFields"]("contact");

    },
  }
};
</script>

<style></style>
