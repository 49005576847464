import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const JOURNEY_API = "/journey";

// ----------------------- STATE ------------------------- //
const state = {
    journey_data: [],
    currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getJourney: state =>
        getField(state.journey_data[state.currentIndex]),

    getCurrentJourney: state => {
        return state.journey_data[state.currentIndex];
    },


};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateJourney(state, payload) {
        updateField(state.journey_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.journey_data.findIndex(
            journey => journey.id == theID
        );
    },

    loadAllJourneys(state, payload) {
        state.journey_data = payload.data;
        state.currentIndex = 0;
    },

    addJourney(state, payload) {
        state.journey_data.push(payload.data);
        state.currentIndex = state.journey_data.length - 1;
    },

    saveJourney(state, payload) {
        state.journey_data[state.currentIndex] = payload.data;
    },

    fetchJourney(state, payload) {
        if (state.currentIndex == -1) {
            state.journey_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.journey_data[state.currentIndex] = payload.data;
        }
    },


    deleteJourney(state, payload) {
        state.currentIndex = state.journey_data.findIndex(
            journey => journey.id == payload.data.id
        );
        state.journey_data.splice(state.currentIndex, 1);
    },

    reset(state) {
        state.journey_data = []
        state.currentIndex = -1
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllJourneys({commit}) {
        try {
            commit(
                "loadAllJourneys",
                await Repository.get(`${JOURNEY_API}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async addJourney({commit}) {
        await Repository.get(`${JOURNEY_API}/new`)
            .then(response => {
                commit("addJourney", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveJourney({state, commit}) {
        try {
            commit(
                "saveJourney",
                await Repository.post(
                    `${JOURNEY_API}`,
                    state.journey_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchJourney({commit}, payloadID) {
        try {
            commit(
                "fetchJourney",
                await Repository.get(`${JOURNEY_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },



    async deleteJourney({commit}, payloadID) {
        try {
            commit(
                "deleteJourney",
                await Repository.delete(`${JOURNEY_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchJourneys({commit}, searchTerm) {
        try {
            commit(
                "loadAllJourneys",
                await Repository.get(`${JOURNEY_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
