<template lang="pug">
  v-autocomplete(
    ref='theCombo'
    v-model='groups'
    :items='$store.state.settings.groupSettings.group_data'
    label="Groups"
    item-text='name'
    item-value='id'
    filled
    multiple
    @keyup.enter="onAddGroupComboBox"
    :hide-no-data="!search"
    :search-input.sync="search"
    clearable
    :menu-props="{closeOnContentClick: true}"
    @change="onChange"
    )
    template(slot='selection', slot-scope='data')
      v-chip.chip--select-multi(:input-value='data.selected', small close @click:close="onRemoveItem(data.item.id)")
        | {{ data.item.name }}

    template(slot="no-data")
      v-list-item
        span.subheading Create
        v-chip.ml-2(:color="'primary white--text'" label small) {{search}}
</template>

<script>
export default {
  name: "GroupsAutoComplete",
  props:['value'],
  data: () => ({
    groups:[],
    search: null,
  }),

  created() {
    this.groups = this.value;
  },

  mounted() {
    if(this.$store.state.settings.groupSettings.currentIndex == -1){
      this.$store.dispatch("settings/groupSettings/loadAllGroups")
    }

  },

  methods: {

    onChange: function(value){
      this.$emit('input',value)
    },
    onAddGroupComboBox: function() {

      this.$store.dispatch("settings/groupSettings/addGroup").then(()=>{
        // commit the name
        this.$store.commit("settings/groupSettings/setName",this.search)

        // save the name
        this.$store.dispatch("settings/groupSettings/saveGroup").then(()=>{
          this.groups.push(this.$store.getters["settings/groupSettings/getCurrentGroup"].id)
          this.search = null
          this.$emit('input',this.group)
        });
      })

    },

    onRemoveItem: function(id){
      // console.log(this.groups)
      let index = this.groups.findIndex((item) => item === id);
      if (index >= 0) {
        this.groups.splice(index,1);
        this.$emit('input',this.groups)
        this.search = null

      }
    },


  },
}
</script>

<style scoped>

</style>