<template lang="pug">
.nameColumn(@click="openContactDetail(contact.id)")
  image-thumbnail.mr-2(:width='60', :height='60', :src="contact.thumbnail_id")
  .nameColumnInfo
    .nameColumnTitle.underline() {{ contact.first_name }} {{contact.last_name}}
    contact-status.caption(:status="contact.status")
    .nameColumnDescription {{ contact.email }} · {{ contact.mobile}}
</template>
<script>
import ContactStatus from "@/modules/global/components/others/Status";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components: {
    ContactStatus,
    ImageThumbnail
  },
  props: {
    contact: Object
  },
  data: () => ({}),
  methods: {
    openContactDetail: function(contactID) {
      this.$store.commit("contacts/setCurrentIndexByID", contactID);
      this.$router.push({
        name: "contactDashboard",
        params: { contactID: contactID }
      }).catch(()=>{});
    }
  }
};
</script>
<style scoped lang="stylus">
.nameColumn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn, .nameColumnInfo {
}

.nameColumn, .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;
  padding: 0px;
}

.underline {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}
</style>
