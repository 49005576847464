<template lang="pug">
  .fill-width()
    v-tabs.mx-3.tabs.fill-width(left height='32' slider-color='primary' v-model="index")
      v-tab.tabs
        | Basic Dashboard
      v-tab-item.fill-tab.pr-6
        basic-inspection-form-dashboard()

</template>

<script>
import BasicInspectionFormDashboard
  from "@/modules/inspections/components/overview/components/BasicInspectionFormDashboard";
export default {
  components:{
    BasicInspectionFormDashboard,
  },
  data: ()=>({
    index:0,
  }),
}
</script>

<style scoped>

</style>