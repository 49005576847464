<template lang="pug">
  div(v-if="$store.state.inspection.inspectionForm.currentIndex > -1")
    //- Header
    v-container.mx-4(fluid)
      v-toolbar(dense, flat)
        v-toolbar-title
          .title.grey--text() Inspection Designer
        v-spacer

      v-container.pa-2
        v-alert(v-if="error" :value="true" color="error" icon="warning")
          div(v-for="(e,index) in errors" :key="index" v-text="e")
        v-layout(align-center)
          v-btn(color="primary" @click="preview" )
            v-icon.mr-2(:size="20") remove_red_eye
            | Preview


          v-spacer()
          v-btn.ma-2(small text color="grey" @click="cancel") cancel
          v-btn.ma-2(small color="primary" @click="save") Save
    v-container
      v-container.grid-list-xl
        v-layout.pb-4(row wrap justify-start)

          v-flex(sm12 md8)
            v-card.grey.lighten-4
              v-container()
                v-row()
                  v-col(:cols="6")
                    v-text-field(v-model="name" color="accent" label="Form Name" required)
                  v-col(:cols="6")
                    v-text-field(v-model="description" color="accent" label="Form Description" )

            .my-8()
            v-card
              v-container.grid-list-md()

                //- Dragging of the Components
                draggable(v-model="schemeData" group="people" @start="drag=true" @end="drag=false"  :move="checkMove" )
                  //- Rendering the components
                  render-component(v-for="(item,index) in schemeData" :key="index" :item="item")

                //- Bottom DropZone Component
                template-bottom-drop-zone()

          v-flex(sm12 md4)
            div(ref="theProperties" style="position:sticky; top:60px;")
              v-card
                v-container.grid-list-sm
                  properties-holder()


    v-dialog(v-model="$store.state.inspection.formDesigner.modalWindow" max-width="500px")
      v-card
        v-card-text
          global-template-components()
        v-card-actions
          v-btn(color="primary" text @click="modalWindow=false") Close
</template>

<script>
import TemplateBottomDropZone from "@/modules/inspections/components/formDesigner/TemplateBottomDropZone";
import draggable from "vuedraggable";
import PropertiesHolder from "@/modules/inspections/components/formDesigner/PropertiesHolder";
import RenderComponent from "@/modules/inspections/components/formDesigner/RenderComponent";
import GlobalTemplateComponents from "@/modules/inspections/components/formDesigner/GlobalTemplateComponents";
import {createHelpers} from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "inspection/inspectionForm/getInspectionForm",
  mutationType: "inspection/inspectionForm/updateInspectionForm"
});
export default {
  components:{
    draggable,
    TemplateBottomDropZone,
    PropertiesHolder,
    RenderComponent,
    GlobalTemplateComponents
  },
  data:()=>({
    modalWindow:true,
    error: false,
    errors: [],

  }),

  mounted() {
    if(this.$store.state.inspection.inspectionForm.currentIndex == -1){
      this.$store.dispatch("inspection/inspectionForm/fetchInspectionForm",this.$route.params.inspectionID).then(()=>{
        this.$store.commit("inspection/formDesigner/setSchema",this.$store.getters["inspection/inspectionForm/getLatestSchema"])
      })
    }
  },
  computed:{
    ...mapFields([
      "name",
      "version",
      "description",
      "title",
      "sub_title",
      "details",
      "status",
      "data",
      "account_id"
    ]),

    getCurrentInspection: function() {
      return this.$store.getters["inspection/inspectionForm/getCurrentInspectionForm"];
    },
    schemeData: {
      get: function() {

        return this.$store.state.inspection.formDesigner.schema_data;
      },
      set: function(value) {
        this.$store.commit("inspection/formDesigner/setSchema", value);
      }
    },

  },
  methods:{
    cancel: function(){
      // if(this.$router.history.current.path == this.$router.history._startLocation){
      this.$store.commit("inspection/formDesigner/resetFormBuilder");
        this.$router.push({name:"inspections",params:{tabID:1}}).catch(()=>{})

      // }else{
      //   this.$router.go(-1);
      // }
    },
    save: function(){
      // save the properties
      this.$store.commit("inspection/formDesigner/saveFieldProperties")
      // order in how they are arranged
      this.$store.commit("inspection/formDesigner/setOrderNumbers")
      let schemaTouched = false;
      if(this.version > 1){
        let inspectionSchema = JSON.stringify(this.$store.getters["inspection/inspectionForm/getPreviousSchema"])
        let designerSchema = JSON.stringify(this.$store.getters["inspection/formDesigner/getSchema"])

        schemaTouched = !(inspectionSchema === designerSchema);
      }else{
        schemaTouched = true;
      }
      if(schemaTouched){
        this.$store.commit(
            "inspection/inspectionForm/setSchema",
            {schema: this.$store.getters["inspection/formDesigner/getSchema"], version:this.version+1, date:new Date().toISOString()}
        );
      }

      // saving the inspection form
      this.$store.dispatch("inspection/inspectionForm/saveInspectionForm").then(()=>{
        this.$store.dispatch("inspection/inspectionForm/loadAllInspectionForms").then(()=>{
          // reset formDesigner
          this.$store.commit("inspection/formDesigner/resetFormBuilder")
          // show snackbar
          this.$store.commit("showSnackBar", {
            color: "accent",
            message: `Inspection Record Saved`
          });
          this.cancel();
        })
      })

    },
    preview: function(){

    },
    checkMove: function() {

    }
  }

}
</script>

<style scoped>

</style>