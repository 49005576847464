<template lang="pug">
  div
    v-progress-linear(v-if="!isLoaded" height='4', indeterminate)
    .white.pt-2
      v-tabs.tabs(left='', height='32', slider-color='primary', v-model='active')


        v-tab.tabs  Notifications
        v-tab-item
          notifications-layout(v-if="isLoaded")
        v-tab.tabs  Sms
        v-tab-item
          sms-layout(v-if="isLoaded")
        v-tab.tabs  Email
        v-tab-item
          email-layout(v-if="isLoaded")
        v-tab.tabs Events
        v-tab-item
            event-layout(v-if="isLoaded" )
        v-tab.tabs  Messenger
        v-tab-item
          chat-layout()




</template>

<script>
import NotificationsLayout from "@/modules/messaging/layouts/Notifications/NotificationsLayout";
import ChatLayout from "@/modules/messaging/layouts/Chat/ChatLayout";
import SmsLayout from "@/modules/messaging/layouts/Sms/SmsLayout";
import EmailLayout from "@/modules/messaging/layouts/Email/EmailLayout";
import EventLayout from "@/modules/messaging/layouts/Events/EventLayout.vue";

export default {
  components: {
      EventLayout,
    EmailLayout,
    SmsLayout,
    NotificationsLayout,
    ChatLayout
  },
  data:()=>({
    isLoaded:false,
  }),
  computed: {
    active: {
      get: function() {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          tabIndex = tabIndex > 2 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function(value) {
        this.$route.params.tabID = value;
      }
    }
  },
  async mounted()  {
    this.isLoaded = false;
    if(this.$store.state.vehicles.all.length < 2 ){
      await this.$store.dispatch("vehicles/loadAllItems")
    }
    if(this.$store.state.contacts.contact_data.length < 2){
      await this.$store.dispatch("contacts/loadAllContacts")
    }
    this.isLoaded = true;
  }
};
</script>

<style scoped></style>
