<template lang="pug">
  div
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 SMS Notifications
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item(v-for='item in getMenuItems', :key='item.title' @click="onClick(item)" :disabled="$store.state.messages.selectedItems.length == 0")
                v-list-item-icon()
                  v-icon() {{item.icon}}
                v-list-item-title {{ item.title }}
          v-btn.ma-2(small,color="primary",@click="sendNotification")
            v-icon.pr-2(dark) alarm
            | Send SMS

    v-divider
    v-layout.mx-4.my-2(column)
      div()
        span.primary--text.text--lighten-1.font-weight-bold.title() {{getSentCount}}
        span.ml-2.title.grey--text() Total SMS Sent
      //- v-chip-group(column  active-class="primary--text" v-model="filterIndex" @change="onChangeFilter")
        v-chip(v-for='toggleItem in toggleItems', :key='toggleItem' small ) {{ toggleItem }}
    v-layout.filterStyles(align-center)
      v-text-field.pl-2( v-model="searchFilter", label="Search Filter", :persistent-hint="true" required)
      v-text-field.pl-2( v-model="groupFilter", label="Group Filter", :persistent-hint="true" required)
      v-btn.ma-2(small color="primary") Search
      div
        span.primary--text 1 filter
        span.pl-1.grey--text.text--darken-1 apply
    v-spacer

    v-divider
    .grey.lighten-4.py-3
    v-divider
    sms-table(:search="search", :group="group")




</template>
<script>
import ToggleChips from "@/modules/global/components/lists/ToggleChips";
import NotificationTable from "@/modules/messaging/components/tables/NotificationTable";
import SmsTable from "@/modules/messaging/components/tables/SmsTable";

export default {
  components: {
    SmsTable,
    ToggleChips,
    NotificationTable
  },
  data: () => ({
    select1: "All Reminders",

    selectOptions1: [
      "All Reminders",
      "Overdue Only",
      "Overdue + Due Soon",
      "Due Soon Only"
    ],

    searchFilter: null,
    groupFilter: null,
    search: "",
    filterIndex:0,
    group:"all",
    toggleItems: ["All","Unread", "Read"]
  }),

  computed:{
    isAdministrator: function(){
      return this.$store.state.profile.userInfo.is_administrator;
    },

    getSentCount: function() {
      return this.$store.state.sms.sms_data.length;
    },

    getMenuItems: function() {
      let menuList = [];
      if(!this.isAdministrator){
        menuList.push({
          title: "Mark as Read",
          icon: "drafts",
          value: "read"
        })
      }

      menuList.push({
        title: "Delete Selected",
        icon: "delete",
        value: "deleted"
      })

      return menuList;
    }

  },
  methods: {
    sendNotification: function() {

    },

    onChangeFilter:function(value){
      this.group = this.toggleItems[value]
    },

    showDialog: function() {},

    closeDialog: function(value) {
      console.log(value);
    },

    onClick: function(item){
      var theIDs = [];
      this.$store.state.messages.selectedItems.forEach(element => {
        theIDs.push(element.id);
      })
      let theType = this.isAdministrator?'admin':'contact'
      switch(item.value){
        case "deleted":
          confirm(this.isAdministrator?"Are you sure you want to delete selected messages for all users?":"Are you sure you want to delete selected messages?") &&
          this.$store.dispatch("messages/deleteManyMessages",{ids:theIDs,type:theType})
          break;
        case "read": this.$store.dispatch("messages/markManyAsRead",theIDs); break;
      }
    }
  }
};
</script>
<style scoped lang="stylus">
.filterStyles{
  max-width 900px;
}
</style>
