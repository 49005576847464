<template lang="pug">
.pa-4(v-if="$store.state.inventory.currentIndex > -1")
  v-row()
    v-col.d-flex(cols="12")
      v-btn-toggle(color="primary" v-model="durationMode" @change="updateCalendarMode")
        v-btn(text  value="start_at") Start Time
        v-btn(text  value="end_at" ) End Time


  v-row()
    v-col(cols="12" xs="12" md="6" xl="6")
      v-card.elevation-5()
        .d-flex.grey.py-4.lighten-4.align-center()
          v-btn.mx-2( color="primary" icon @click="$refs.calendar.prev()")
            v-icon() arrow_back
          v-btn.mx-2( color="primary" icon @click="$refs.calendar.next()")
            v-icon() arrow_forward
          .font-weight-bold.title.mx-4() {{getCurrentMonth}}
          v-spacer()

          v-btn(text small @click="setToday") Today
          v-btn(text small  @click="setThisWeek") This Week
          v-btn-toggle.mx-4(v-model="type"  @change="updateCalendarMode")
            v-btn(text small value="day") day
            v-btn(text small value="4day") 4 day
            v-btn(text small value="week") week
            v-btn(text small value="month") month
        v-sheet(height="600")
          v-calendar(
            v-model="value"
            ref="calendar"
            :weekdays="weekday"
            :type="type"
            :events="events"
            event-overlap-mode="column"

            :event-color="getEventColor"
            @click:date="viewDay"
            @change="getEvents"
          )
    v-col(cols="12" xs="12" md="6" xl="6")
      v-card.elevation-5()
        .d-flex.grey.py-5.px-4.lighten-4.align-center.justify-space-between()
          .d-flex()
            div.grey--text.text--darken-2() Total Debit
              span.primary.darken-2.rounded.px-2.mx-2.white--text.font-weight-bold() {{this.totalDebit}}
            div.grey--text.text--darken-2.ml-4() Total Credit
              span.green.darken-2.rounded.px-2.mx-2.white--text.font-weight-bold() {{this.totalCredit}}
          v-btn(small, outlined, color='primary' @click="onAction('export_xls')")
            v-icon.mr-2() download
            | Export Calendar
        v-data-table.elevation-1(
          :headers="getHeaders()",
          :items="getCalendarItems",
          item-key="id",
        )
          template(v-slot:item.customer="{item}")
            template(v-if="item.customer")
              div() {{item.customer.reference_id}} &ndash; {{item.customer.name}}
              .caption.grey--text(v-if="item.customer.area") {{item.customer.area}}
            template(v-else-if="item.merchant")
              div() {{item.merchant.reference_id}} &ndash;  {{item.merchant.name}}

          template(v-slot:item.start_at="{item}")
            template(v-if="durationMode==='start_at'")
              .d-flex.justify-end.pr-4() {{getFormattedTime(item.start_at)}}
            template(v-else)
              .d-flex.justify-end.pr-4() {{getFormattedTime(item.end_at)}}

          template(v-slot:item.debit="{item}")
            .d-flex.justify-end.pr-4()  {{item.debit}}

          template(v-slot:item.credit="{item}")
            .d-flex.justify-end.pr-4()  {{item.credit}}



          template( v-slot:item.action="{ item }")
            v-row.mr-7(justify="end")
              more-drop-down-menu(:items='menuItems', @onClick='onClick', :theObject='item')
  export-data-dialog(:fileExport="fileExport" :isFinished="isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="showExportDialog = false;" @download="onDownload" )

</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import moment from "moment";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import Repository from "@/repository";


export default {
  components:{
    MoreDropDownMenu,
    ExportDataDialog,
  },
  data: () => ({

    value: '',
    weekday: [0, 1, 2, 3, 4, 5, 6],

    type: 'month',
    types: ['month', 'week', 'day', '4day'],
    events: [],
    colors: ['green', 'primary'],
    names: ['Add', 'Subtract'],
    mode: 'column',


    durationMode:"start_at",
    footerProps: {
      "items-per-page-options": [5, 10, 20, 50, 100, 1000],
      pagnation: {itemsPerPage: 10}
    },
    calendarValue:null,
    calendarStart:null,
    calendarEnd:null,
    calendarItems:[],
    menuItems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ],
    isDownloadFinished: false,
    fileLink: null,
    showExportDialog: false,
    fileExport: "",

    actions: [
      {
        title: "Export XLS",
        value: "export_xls"
      }
    ],
    totalDebit: 0,
    totalCredit: 0,

  }),

  watch:{
    totalDebit:function (oldVal,newVal){
      // console.log(`Total Debit ${oldVal} - ${newVal}`)
      if(oldVal != newVal){
        this.updateCalendarMode();
      }
    }
  },


  computed: {
    currentItem: function () {
      return this.$store.getters["inventory/item/getCurrentItem"];
    },

    getCurrentMonth: function() {
      if (this.calendarStart == null){
        return ""
      }else{
        return moment(this.calendarStart).format("MMMM - YYYY")
      }
    },

    getCalendarItems: function() {
      let result = [];
      if(this.calendarStart != null){
        console.log(this.calendarStart)
        console.log(this.calendarEnd)
        let theCalendarTime = {start: this.calendarStart.getTime(),end:this.calendarEnd.getTime()}

        if(this.durationMode === 'start_at'){
          result = this.$store.getters["inventory/getStartCalendarItems"](theCalendarTime);
        }else{
          result = this.$store.getters["inventory/getEndCalendarItems"](theCalendarTime);
        }
      }
      this.getTotals(result);

      return result;

      // if(this.calendarValue == null) return [];
      //
    },



  },

  methods: {

    getHeaders: function(){
      let headers = [
        {text: "Contact", value: "customer"},
        {text: "Debit", value: "debit", align:"right"},
        {text: "Credit", value: "credit", align:"right"},
        {text: "Time", value: "start_at", align:"right"},
      ]
      // insert new fields here
      if(this.currentItem && this.currentItem.item_details && this.currentItem.item_details.custom_fields){
        this.currentItem.item_details.custom_fields.forEach(element => {
          headers.push({text: element.label, value: `details.${element.label_id}`, align: "right"})
        })
      }

      headers.push({text: "Action", value: "action", align:"right"},)

      return headers;
    },

    getTotals: function(theResultArray){
      this.totalDebit = 0;
      this.totalCredit = 0;
      theResultArray.forEach(element => {
        if(element.debit != null){
          this.totalDebit += element.debit
        }else{
          this.totalCredit += element.credit
        }
      })

    },

    getEvents: function (value) {
      this.events = [];
      console.log(value)
      console.log(typeof value)
      this.calendarValue = value;
      this.calendarStart = new Date(value.start.date)
      this.calendarEnd = moment(new Date(value.end.date)).endOf('day').toDate()
      this.$store.state.inventory.inventory_data.forEach(element => {
        let obj = {};
        if (element.merchant != null) {
          obj['name'] = `${element.merchant.reference_id ?? ''} - ${element.merchant.name}`
        } else if (element.customer != null) {
          obj['name'] = `${element.customer.reference_id ?? ''} - ${element.customer.name}`
        }


        if(this.durationMode ==='start_at'){
          obj['start'] = new Date(element.start_at);
        }else{
          obj['start'] = new Date(element.end_at);
        }
        obj['color'] = element.credit != null ? 'green' : 'primary';
        obj['timed'] = true;
        this.events.push(obj);
      })

      return this.events;
    },
    getEventColor(event) {
      return event.color
    },
    viewDay ({ date }) {

      this.value = date
      this.type = 'day'

      this.$nextTick(() => {
        this.$refs.calendar.scrollToTime('08:00')
      })
    },

    showEvent({ nativeEvent, event }){
      console.log(nativeEvent);
      console.log(event)
      nativeEvent.stopPropagation()
    },

    getFormattedTime: function (theDate) {
      return moment(theDate).format("Do MMM YYYY")
    },

    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("inventory/item/setCurrentIndexByID", this.$route.params.itemID)
          if (value.theObject.credit != null) {
            this.$store.commit("inventory/setMode", "add");
          } else {
            this.$store.commit("inventory/setMode", "delete")
          }
          this.$store.commit("inventory/setCurrentIndexByID", value.theObject.id)
          this.$router.push({
            name: "inventoryForm",
            params: {itemID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          this.$store.dispatch("inventory/deleteInventory", value.theObject.id);
          break;
      }
    },

    updateCalendarMode: function(){
     this.getEvents(this.calendarValue)
      this.$nextTick(() => {
        this.$refs.calendar.scrollToTime('08:00')
      })
    },

    setToday: function() {
      this.type='day'
      this.value = ''
      this.$nextTick(() => {
        this.$refs.calendar.scrollToTime('08:00')
      })
    },

    setThisWeek: function() {
      this.type='week'
      this.value = ''
    },


    onAction: function (value) {
      let day = moment(this.calendarStart).format("ddd, Do MMM YYYY").toString() + "-" + moment(this.calendarEnd).format("ddd, Do MMM YYYY").toString() ;
      switch (value) {
        case "export_csv":
          break;
        case "export_xls":
          this.fileExport = "Excel";
          this.downloadFile(`/report/calendarInventory?mode=${this.durationMode}&item_id=${this.currentItem.id}&day=${day}`);
          this.showExportDialog = !this.showExportDialog;
          break;
      }

    },

    downloadFile: function (url) {
      this.isDownloadFinished = false;
      console.log(url)

      let calendarItemIDs = [];
      this.getCalendarItems.forEach(element => {
        calendarItemIDs.push(element.id)
      })

      console.log(calendarItemIDs);

      Repository({
        method: "POST",
        url: url,
        data: calendarItemIDs,
        responseType: "blob",
      }).then((response) => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function () {
      // download the file
      this.fileLink.click();
      this.showExportDialog = false;
    },
  },

  mounted() {
    // this.$refs.calendar.checkChange()

  }

}
</script>

<style scoped>

</style>