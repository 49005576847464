<template lang="pug">
  div()
    v-progress-linear(:indeterminate="isLoading")
    div(v-if="!isLoading")
      v-container.mx-4(fluid)
        v-toolbar(dense, flat)
          v-toolbar-title
            .mb-2()
              .title.grey--text() {{getTemplate.name}}
              v-breadcrumbs.ma-0.pa-0(:items="getBreadCrumbItems()" divider="/")
          v-spacer
        v-container.pa-2
          v-layout()

            v-spacer()
            .align-center()
              //v-btn.ma-2(small outlined text color="grey" @click="validate") validate
              //v-btn.ma-2(small outlined text  color="grey" @click="reset") reset
              v-btn.ma-2(small text color="grey" @click="cancel") cancel
              v-btn.ma-2(small color="primary" @click="saveAndAdd") Save and Add New
              v-btn.ma-2(small color="primary" @click="save") Save

    v-container()
      v-container.pa-2
        v-card()
          v-container.grid-list-md()
            v-form(ref="form")
            div.pa-3()
              .headline() {{getTemplate.name}}
              .subtitle-2() Here is the description
            v-divider()
            record-renderer(v-if="getRecordSchema()" )


</template>
<script>
import RecordRenderer from "@/modules/workflow/records/components/forms/RecordRenderer";


export default {
  components: {
    RecordRenderer,
  },

  created() {
    // stretch the elements to fill the height
    this.height = window.innerHeight - 230;
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.height = window.innerHeight - 90;
      });
    });
  },

  data: () => ({
    snackbar: false,
    snackbarColor: "primary",
    snackBarMessage: "",
    isLoading: true,
    cancelRecord:null,


  }),

  computed: {

    getCurrentRecord: function () {
      let currentRecord = this.$store.getters["workflow/workflowRecord/getCurrentRecord"];
      currentRecord = this._.clone(currentRecord)
      console.log(currentRecord);
      return currentRecord;
    },

    getTemplate: function() {
      let template = this.$store.getters["workflow/getWorkflowTemplateByID"](this.getCurrentRecord.workflow_template_id);
      return template;
    },


    flexSize: function (gridSize) {
      return gridSize;
    },


  },

  methods: {


    cancel: function () {
      this.$store.dispatch("workflow/workflowRecord/loadAllRecords").then(()=>{
        this.$router.back();
      })

    },
    design: function () {
      this.$router.push({name: "templateBuilderForm"}).catch(()=>{});
    },

    save: function () {
      this.$store.dispatch("workflow/workflowRecord/saveRecord").then(() => {

        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Form ${this.$store.getters["workflow/workflowRecord/getCurrentRecord"].workflow_template_name} is saved`
        });
        this.$router.back();
        // this.$store.commit("workflow/workflowRecord/toggleShowForm",false);
      });
    },

    saveAndAdd: function () {
      // saving
      this.$store.dispatch("workflow/workflowRecord/saveRecord").then(() => {
        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Form ${this.$store.getters["workflow/workflowRecord/getCurrentRecord"].workflow_template_name} is saved`
        });
        this.$store.commit("workflow/workflowRecord/toggleShowForm", true);
        // create New
        this.$store.dispatch("workflow/workflowRecord/addRecord", this.$store.getters["workflow/workflowRecord/getCurrentRecord"].workflow_template_id).then(() => {
          console.log(this.$store.getters["workflow/workflowRecord/getCurrentRecord"].workflow_template_id);
        })
      });


    },

    validate: function () {
      if (this.$refs.form.validate()) {
        this.$store.commit("showSnackBar", {
          color: "primary",
          message: "Well Done you filled correctly",
        });
      } else {
        this.$store.commit("showSnackBar", {
          color: "error",
          message: "Error found in Form",
        });
      }
    },

    reset: function () {
      console.log("RRRRRRRRRRRRRRRRRRRRReeset");
      console.log(this.form_data);
      // this.$refs.form.reset();
    },

    getComponent: function (propertyName) {
      // Ensure the Renderer is Registered. the Registered Component has an appended `FillForm`
      return `${propertyName}FillForm`;
    },

    getRecordSchema: function () {
      return this.$store.getters["workflow/getCurrentWorkflow"].data[this.$store.getters["workflow/workflowRecord/getCurrentRecord"].workflow_template_version-1].schema;
    },





    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "workflow",
        disabled: false,
        to: {name: "workflows"}
      });
      theArray.push({
        text: this.getTemplate.name,
        disabled: true
      });
      return theArray;
    },

    setTheSchemaInit: function() {
      this.$store.commit(
          "workflow/formBuilder/setSchema",
          this.getRecordSchema()
      )
    }

  },

  mounted() {
    // console.log(`workflow ID  workflowCurrent: ${this.$store.state.workflow.currentIndex} REcord Current:${this.$store.state.workflow.workflowRecord.currentIndex}`);
    // console.log(this.$store.state.workflow.workflowRecord.record_data);
    this.isLoading = true;
    if(this.$store.state.workflow.currentIndex == -1){

      // console.log(this.$route.params)
      this.$store.dispatch("workflow/fetchWorkflow",this.$route.params.workflowID).then(()=>{
        if(this.$store.state.workflow.workflowRecord.currentIndex == -1){
          this.$store.dispatch("workflow/workflowRecord/fetchRecord",this.$route.params.workflowRecordID).then(()=>{
            // console.log("loaded record")
            this.isLoading = false;
            this.setTheSchemaInit();
          })
        }
      })
    }else{
      if(this.$store.state.workflow.workflowRecord.currentIndex == -1){
        this.$store.dispatch("workflow/workflowRecord/fetchRecord",this.$route.params.workflowRecordID).then(()=>{
          this.isLoading = false;
          this.setTheSchemaInit();
        })
      }else{
        this.isLoading = false;
        this.setTheSchemaInit();
      }
    }



  },
};
</script>
<style lang="stylus" scoped>
.basicFormComponent {
  height: 90px;
  background-color: #CCCCCC;
}

.splitpanes__pane {

  overflow-y: auto;
}
</style>
