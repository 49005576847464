import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";
import inspection from "@/modules/workflow/inspections/store/inspection";
import formBuilder from "@/modules/workflow/formBuilder/store/formBuilder";
import dataTable from "@/modules/workflow/dataTables/store/dataTable";
import workflowRecord from "@/modules/workflow/records/store/WorkflowRecord";
import board from "@/modules/workflow/board/store/board";

const WORKFLOW_API = "/workflowTemplate";

// ----------------------- STATE ------------------------- //
const state = {
  workflow_data: [],
  currentIndex: -1,

};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getWorkflow: state => getField(state.workflow_data[state.currentIndex]),

  getCurrentWorkflow: state => {
    return state.workflow_data[state.currentIndex];
  },

  getWorkflowTemplateByID: state => id => {
    let result = state.workflow_data.findLast(item => item.id == id)
    return result;
  },

  getCurrentWorkflowSettings: state => {
    return state.workflow_data[state.currentIndex].details.settings
  },

  getLatestSchema: state => {
    return state.workflow_data[state.currentIndex].data[
      state.workflow_data[state.currentIndex].data.length - 1
    ].schema;
  },

  getSchemaByVersion: state => version => {
    return state.workflow_data[state.currentIndex].data[version -1].schema;
  },

  getPreviousSchema: state => {
    return state.workflow_data[state.currentIndex].data[
      state.workflow_data[state.currentIndex].data.length - 2
    ].schema;
  },

  getWorkflowByID: state => workflowID => {
    return state.workflow_data.find(function (item) {
      return item.id == workflowID;
    });
  },

  getLatestVesion: state => {
    return state.workflow_data[state.currentIndex].data[
      state.workflow_data[state.currentIndex].data.length - 1
    ].version;
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updateWorkflow(state, payload) {
    updateField(state.workflow_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.workflow_data.findIndex(
      workflow => workflow.id == theID
    );
  },

  setSchema(state, payload) {
    // push to data array
    console.log("DATA SCHEMA");
    console.log(state.workflow_data[state.currentIndex].data);
    state.workflow_data[state.currentIndex].data.push(payload);
    state.workflow_data[state.currentIndex].version = payload.version;

  },

  setRouteCurrentWorkflow(state,payload){
    state.workflow_data[state.currentIndex].details.route = payload;
  },

  loadAllWorkflows(state, payload) {
    state.workflow_data = payload.data;
    state.currentIndex = 0;

  },

  addWorkflow(state, payload) {
    state.workflow_data.push(payload.data);
    state.currentIndex = state.workflow_data.length - 1;

  },

  saveWorkflow(state, payload) {
    state.workflow_data[state.currentIndex] = payload.data;

  },

  fetchWorkflow(state, payload) {
    if (state.currentIndex == -1) {
      state.workflow_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.workflow_data[state.currentIndex] = payload.data;
    }

  },

  deleteWorkflow(state, payload) {
    state.currentIndex = state.workflow_data.findIndex(
      workflow => workflow.id == payload.data.id
    );
    state.workflow_data.splice(state.currentIndex, 1);
  },

  setTitleAndAvatar(state){
    console.log(">> DETAILS")

    if(state.workflow_data[state.currentIndex].details.settings.title == null){
      // add the elements
      // console.log(state.workflow_data[state.currentIndex].data)
      let theSchema = JSON.parse(JSON.stringify(state.workflow_data[state.currentIndex].data[state.workflow_data[state.currentIndex].version-1].schema));

      // insert Title
      for(let i=0; i<theSchema.length; i++){
        if(theSchema[i].name === "TextField"){
          state.workflow_data[state.currentIndex].details.settings.title = theSchema[i].id;
          theSchema.splice(i,1);
          break;
        }
      }

      // insert Subtitle
      for(let i=0; i<theSchema.length; i++){
        if(theSchema[i].name === "TextField"){
          state.workflow_data[state.currentIndex].details.settings.sub_title1 = theSchema[i].id;
          theSchema.splice(i,1);
          break;
        }
      }

      // insert Avatar
      for(let i=0; i<theSchema.length; i++){
        if(theSchema[i].name === "Image"){
          state.workflow_data[state.currentIndex].details.settings.avatar = theSchema[i].id;
          theSchema.splice(i,1);
          break;
        }
      }



      console.log(theSchema);
    }
  },

  reset(state) {
    state.workflow_data = []
    state.currentIndex = -1
  },


};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllWorkflows({ commit }) {
    try {
      commit("loadAllWorkflows", await Repository.get(`${WORKFLOW_API}`));
    } catch (error) {
      console.log(error);
    }
  },

  async addWorkflow({ commit }) {
    await Repository.get(`${WORKFLOW_API}/new`)
      .then(response => {
        commit("addWorkflow", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async saveWorkflow({ state, commit }) {
    console.log("save the record");
    console.log(state.workflow_data[state.currentIndex].thumbnail_id);
    commit("setTitleAndAvatar");
    try {
      commit(
        "saveWorkflow",
        await Repository.post(
          `${WORKFLOW_API}`,
          state.workflow_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchWorkflow({ commit }, payloadID) {
    try {
      commit(
        "fetchWorkflow",
        await Repository.get(`${WORKFLOW_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deleteWorkflow({ commit }, payloadID) {
    try {
      commit(
        "deleteWorkflow",
        await Repository.delete(`${WORKFLOW_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchWorkflows({ commit }, searchTerm) {
    try {
      commit(
        "loadAllWorkflows",
        await Repository.get(`${WORKFLOW_API}/search?q=${searchTerm}`)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    inspection,
    formBuilder,
    dataTable,
    workflowRecord,
    board
  }
};
