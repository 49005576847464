import { render, staticRenderFns } from "./ServiceInput2.vue?vue&type=template&id=7672c5f0&scoped=true&lang=pug"
import script from "./ServiceInput2.vue?vue&type=script&lang=js"
export * from "./ServiceInput2.vue?vue&type=script&lang=js"
import style0 from "./ServiceInput2.vue?vue&type=style&index=0&id=7672c5f0&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7672c5f0",
  null
  
)

export default component.exports