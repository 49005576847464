<template lang="pug">
  v-dialog(v-model="dialog" width="500")
    v-card()
      v-card-title.px-8.grey.lighten-2() Export {{fileExport}}
      v-card-text()
        v-container.my-5()
          .title(v-text="getMessage.title")
          .caption(v-text="getMessage.body")
      v-divider()
      v-card-actions()
        v-layout(justify-end)
          v-btn.ma-2(text @click="dialog=false") Cancel
          template(v-if="isFinished")
            v-btn.ma-2(color="primary" @click="$emit('download')") Download Now
          template(v-else)
            v-btn.ma-2(color="primary" disabled) Please Wait...


</template>

<script>

export default {

  name: "ExportDataDialog",
  props: {
    fileExport: {
      type: String,
      default: "CSV",
      required: true
    },
    show: Boolean,
    isFinished: Boolean
  },
  data: () => ({
    title: null,
    body: null
  }),

  methods: {},

  computed: {
    dialog: {
      get: function() {
        return this.show;
      },
      set: function(value) {
        console.log(`setting the value ${value}`);
        this.$emit("closeExportDialog");
      }
    },

    getMessage: function() {
      if (!this.git ) {
        return {
          title: `Preparing  ${this.fileExport} file`,
          body: `You'll be able to download the ${this.fileExport} file in a moment \n Please Wait (May take a while for large data)`
        };
      } else {
        return {
          title: `Ready to Download ${this.fileExport}`,
          body: `Download your ${this.fileExport} file by clicking the button below`
        };
      }
    }
  }
};
</script>

<style scoped></style>
