<template lang="pug">
div(v-if="contact" )
  template(v-if="mode == 'textOnly'")
    div() {{ contact.first_name }} {{contact.last_name}}
  template(v-else-if="mode == 'initials'")
    div() {{ contact.first_name[0] }}{{contact.last_name[0]}}
  template(v-else)
    .nameColumn.ml-0(v-if="contact != null" @click="openContactDetail(contact.id)")
      image-thumbnail.mr-2(:width='60', :height='60', :src="contact.thumbnail_id")
      .nameColumnInfo
        .nameColumnTitle.underline() {{ contact.first_name }} {{contact.last_name}}
        contact-status.caption(:status="contact.status")
        .nameColumnDescription {{ contact.email }} · {{ contact.mobile}}
</template>
<script>
import ContactStatus from "@/modules/global/components/others/Status";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";

export default {
  components: {
    ContactStatus,
    ImageThumbnail
  },
  props: {
    contact_id: String,
    clickToDetail:{
      type:Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: null,
    }
  },
  data: () => ({
    contact: null
  }),
  watch: {
    contact_id:function(oldVal,newVal){
      if(oldVal !== newVal){
        this.getContact();
      }
    }
  },
  methods: {
    getContact: function(){
      let tempContact = this.$store.getters["contacts/getContactByID"](this.contact_id);
      if (tempContact == undefined) {
        this.$store.dispatch("contacts/loadAllContacts").then(() => {
          tempContact = this.$store.getters["contacts/getContactByID"](this.contact_id);
          this.contact = tempContact;
        })
      } else {
        this.contact = tempContact;
      }
    },

    openContactDetail: function (contactID) {
      if(this.clickToDetail){
        this.$store.commit("contacts/setCurrentIndexByID", contactID);
        this.$router.push({
          name: "contactDashboard",
          params: {contactID: contactID}
        }).catch(()=>{});
      }

    }
  },
  mounted() {
    this.getContact();
  }
};
</script>
<style lang="stylus" scoped>
.nameColumn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn, .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn, .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;
  padding: 0px;
}

.underline {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}
</style>
