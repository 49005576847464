<template lang="pug">
  div
    v-divider
    v-container.pa-0(fluid)
      v-layout.pa-4(row, wrap)
        h2.mx-4 Approved Journeys
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on", )
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list(small)
              v-list-item-group(color="primary")
                v-list-item(v-for='item in items', :key='item.title' @click='onAction(item.value)')
                  v-list-item-title() {{ item.title }}
        v-btn.ma-2(small, color='primary', dark, @click='editJourney') Add New Journey
      v-divider
      v-layout.ma-4(row align-center)
        v-row.fill-width()
          .d-flex.mx-4.fill-width()
            .d-flex.align-center.fill-width()
              v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" style="max-width:300px")
              v-icon(v-if="search.length > 0" color="primary" outlined small @click="search=''") close
              v-btn.ml-4(v-if="this.$store.getters['routing/getSelectedPlace'].length" color="primary" outlined @click="clearAllSelection") Clear All {{this.$store.getters['routing/getSelectedPlace'].length}} Selected Places
      v-divider
      .grey.lighten-4.py-3
      v-divider
      journey-table( :search="search")
      v-divider


</template>

<script>
import JourneyTable from "@/modules/places/components/tables/JourneyTable";
export default {
  name: "JourneyListLayout",
  components:{
    JourneyTable
  },
  data: ()=>({
    search: "",
    items: [
      {
        title: "Delete Selected",
        value: "delete_selected"
      },
      {
        title: "Approve Selected",
        value: "approve_selected"
      },

    ],
    searchFilter: ""
  }),

  methods: {
    onAction: function(value) {
      switch(value){
        case "delete_selected":
          console.log(value);
          break;
          case "approve_selected":
            console.log(value);
            break;

      }
    },
    editJourney: function(){
      console.log("edit Jouney")
    }

  },
  mounted() {

  }
}
</script>

<style scoped>

</style>