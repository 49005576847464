import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";
import inspectionForm from "@/modules/inspections/store/inspectionForm";
import formDesigner from "@/modules/inspections/store/formDesigner";

const INSPECTION_API = "/inspectionRecord";

// ----------------------- STATE ------------------------- //
const state = {
    inspection_data: [],

    currentIndex: -1,
    selectedItems: [],
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getInspection: state => getField(state.inspection_data[state.currentIndex]),

    getCurrentInspection: state => {
        return state.inspection_data[state.currentIndex];
    },

    getFailures: state => {
        print(state.inspection_data)
        return [];
    },

    getSelectedItems: state => {
        return state.selectedItems;
    },

    getInspectionsByVehicleId: state => vehicleID => {
        return state.inspection_data.filter(element => element.vehicle_id == vehicleID)
    },

    getInspectionsByFormTemplate: state => formID => {
      return state.inspection_data.filter(element => element.inspection_form_id = formID)
    },


    getInspectionByStatus: state => status => {
        let tempList = [];
        switch (status) {
            case "All":
                tempList = state.inspection_data;
                break;
            case "Submitted with Failure":
                tempList = state.inspection_data.filter(
                    item =>
                        item.failed_items_count > 0
                );
                break;
        }
        return tempList;
    },

    getVehicleFaults: state => vehicleID => {
        return state.inspection_data.filter(item => item.vehicle_id == vehicleID);
    },

    getVehiclePendingFaults: state => vehicleID => {
        // todo: getVehiclePeningFaults
        return state.inspection_data.filter(item => item.vehicle_id === vehicleID)
            .length;
    },

    getReportedInspectionsByUser: state => contactID => {
        return state.inspection_data.filter(item => item.reported_by_id == contactID)
            .length;
    },
    getReportedAssignedByUser: state => contactID => {
        return state.inspection_data.filter(item => item.assigned_to_id == contactID)
            .length;
    },

    getVehicleOpenFaults: state => vehicleID => {
        // todo: get Open Faults
        return state.inspection_data.filter(item => item.vehicle_id === vehicleID)
            .length;
    },

    getOverdueInspections: state => {
        return state.inspection_data.filter(item => {
            if (item.due_date_time) {
                let theDate = new Date(item.due_date_time).getTime();
                let currentDate = new Date().getTime();
                return theDate < currentDate;
            } else {
                return false;
            }
        });
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateInspection(state, payload) {
        updateField(state.inspection_data[state.currentIndex], payload);
    },

    deleteImage(state, payload) {
        let index = state.inspection_data[state.currentIndex].photos_id.findIndex(
            item => item === payload
        );
        state.inspection_data[state.currentIndex].photos_id.splice(index, 1);
    },

    deleteDocument(state, payload) {
        let index = state.inspection_data[state.currentIndex].documents_id.findIndex(
            item => item === payload
        );
        state.inspection_data[state.currentIndex].documents_id.splice(index, 1);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.inspection_data.findIndex(inspection => inspection.id == theID);
    },

    loadAllInspections(state, payload) {
        state.inspection_data = payload.data;
        state.currentIndex = 0;
    },

    loadVehicleInspections(state, payload) {
        if (payload.data.length > 0) {
            state.currentIndex = 0;
        }
        state.inspection_data = payload.data;
    },

    loadFilterInspections(state, payload) {
        if (payload.data.length > 0) {
            state.currentIndex = 0;
        }
        state.inspection_data = payload.data;
    },

    loadContactInspections(state, payload) {
        if (payload.data.length > 0) {
            state.currentIndex = 0;
        }
        state.inspection_data = payload.data;
    },

    addInspection(state, payload) {
        state.inspection_data.push(payload.data);
        state.currentIndex = state.inspection_data.length - 1;
    },

    saveInspection(state, payload) {
        state.inspection_data[state.currentIndex] = payload.data;
    },

    fetchInspection(state, payload) {
        if (state.currentIndex == -1) {
            state.inspection_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.inspection_data[state.currentIndex] = payload.data;
        }
    },

    deleteInspection(state, payload) {
        state.currentIndex = state.inspection_data.findIndex(
            inspection => inspection.id == payload.data.id
        );
        state.inspection_data.splice(state.currentIndex, 1);
    },

    openInspection(state, payload) {
        state.currentIndex = state.inspection_data.findIndex(
            inspection => inspection.id == payload.data.id
        );
        state.inspection_data[state.currentIndex] = payload.data;
    },

    closeInspection(state, payload) {
        state.currentIndex = state.inspection_data.findIndex(
            inspection => inspection.id == payload.data.id
        );
        state.inspection_data[state.currentIndex] = payload.data;
    },

    cleanDataBeforeSaving(state) {
        state.inspection_data[state.currentIndex].vehicle = null;
        state.inspection_data[state.currentIndex].submitted_by = null;
        console.log("++++++++++++++++++++++++++");
        console.log(state.inspection_data[state.currentIndex].submitted_on);
        state.inspection_data[state.currentIndex].inspection_form = null;
    },

    reset(state) {
        state.inspection_data = []
        state.currentIndex = -1
    },

    next(state){
        if(state.currentIndex == state.inspection_data.length-1){
            state.currentIndex = 0;
        }else{
            state.currentIndex += 1;
        }
    },

    previous(state){
        if(state.currentIndex == 0){
            state.currentIndex = state.inspection_data.length-1;
        }else{
            state.currentIndex -= 1;
        }
    },

    setSelectedItems(state, payload) {
        state.selectedItems = payload;
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllInspections({commit},payload) {
        try {
            if(payload){
                commit("loadAllInspections", await Repository.get(`${INSPECTION_API}?from=${payload.from}&to=${payload.to}`));
            }else{
                commit("loadAllInspections", await Repository.get(`${INSPECTION_API}`));
            }

        } catch (error) {
            console.log(error);
        }
    },

    async loadVehicleInspections({commit}, vehicle_id) {
        try {
            console.log(vehicle_id);
            commit(
                "loadVehicleInspections",
                await Repository.get(`${INSPECTION_API}/vehicle/${vehicle_id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async loadFilterInspections({commit}, form_id) {
        try {
            console.log(form_id);
            commit(
              "loadFilterInspections",
              await Repository.get(`${INSPECTION_API}/filterRecords?form_id=${form_id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },


    async loadContactInspections({commit}, contact_id) {
        try {
            commit(
                "loadContactInspections",
                await Repository.get(`${INSPECTION_API}/contact/${contact_id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async addInspection({commit}, payload) {
        await Repository.get(`${INSPECTION_API}/new/${payload}`)
            .then(response => {
                commit("addInspection", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveInspection({state, commit}) {
        try {
            console.log(state.inspection_data[state.currentIndex]);
            commit("cleanDataBeforeSaving");

            commit(
                "saveInspection",
                await Repository.post(
                    `${INSPECTION_API}`,
                    state.inspection_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchInspection({commit}, payloadID) {
        try {
            commit("fetchInspection", await Repository.get(`${INSPECTION_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteInspection({commit}, payloadID) {
        try {
            commit(
                "deleteInspection",
                await Repository.delete(`${INSPECTION_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteSelectedItems({ commit }, deleteSelectedIDs) {
        try {
            let idString = ''
            deleteSelectedIDs.forEach(element => {
                idString += "&id="+element
            })
            commit(
              "deleteSelectedPlace",
              await Repository.delete(`${INSPECTION_API}/deleteSelected?${idString}`)

            );
        } catch (error) {
            console.log(error);
        }
    },

    async closeInspection({commit}, payloadID) {
        try {
            commit(
                "closeInspection",
                await Repository.get(`${INSPECTION_API}/close/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async openInspection({commit}, payloadID) {
        try {
            commit(
                "openInspection",
                await Repository.get(`${INSPECTION_API}/open/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchInspections({commit}, searchTerm) {
        try {
            commit(
                "loadAllInspections",
                await Repository.get(`${INSPECTION_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
        inspectionForm,
        formDesigner
    }
};
