import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const FUELTYPE_API = "/fuelType";

// ----------------------- STATE ------------------------- //
const state = {
  fueltype_data: [],
  currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getFuelType: state => getField(state.fueltype_data[state.currentIndex]),

  getCurrentFuelType: state => {
    return state.fueltype_data[state.currentIndex];
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updateFuelType(state, payload) {
    updateField(state.fueltype_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.fueltype_data.findIndex(
      fueltype => fueltype.id == theID
    );
  },

  loadAllFuelTypes(state, payload) {
    state.fueltype_data = payload.data;
    state.currentIndex = 0;
  },

  addFuelType(state, payload) {
    state.fueltype_data.push(payload.data);
    state.currentIndex = state.fueltype_data.length - 1;
  },

  saveFuelType(state, payload) {
    state.fueltype_data[state.currentIndex] = payload.data;
  },

  fetchFuelType(state, payload) {
    if (state.currentIndex == -1) {
      state.fueltype_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.fueltype_data[state.currentIndex] = payload.data;
    }
  },

  deleteFuelType(state, payload) {
    state.currentIndex = state.fueltype_data.findIndex(
      fueltype => fueltype.id == payload.data.id
    );
    state.fueltype_data.splice(state.currentIndex, 1);
  },
  reset(state) {
    state.fueltype_data = []
    state.currentIndex = -1
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllFuelTypes({ commit }) {
    try {
      commit("loadAllFuelTypes", await Repository.get(`${FUELTYPE_API}`));
    } catch (error) {
      console.log(error);
    }
  },

  async addFuelType({ commit }) {
    await Repository.get(`${FUELTYPE_API}/new`)
      .then(response => {
        commit("addFuelType", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async saveFuelType({ state, commit }) {
    try {
      commit(
        "saveFuelType",
        await Repository.post(
          `${FUELTYPE_API}`,
          state.fueltype_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchFuelType({ commit }, payloadID) {
    try {
      commit(
        "fetchFuelType",
        await Repository.get(`${FUELTYPE_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deleteFuelType({ commit }, payloadID) {
    try {
      commit(
        "deleteFuelType",
        await Repository.delete(`${FUELTYPE_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchFuelTypes({ commit }, searchTerm) {
    try {
      commit(
        "loadAllFuelTypes",
        await Repository.get(`${FUELTYPE_API}/search?q=${searchTerm}`)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
