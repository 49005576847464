import { render, staticRenderFns } from "./OverviewHeader.vue?vue&type=template&id=1895e1fb&scoped=true&lang=pug"
import script from "./OverviewHeader.vue?vue&type=script&lang=js"
export * from "./OverviewHeader.vue?vue&type=script&lang=js"
import style0 from "./OverviewHeader.vue?vue&type=style&index=0&id=1895e1fb&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1895e1fb",
  null
  
)

export default component.exports