<template lang="pug">
  v-row.mx-2.body-2.black--text()
    v-col.showBorder(cols="12" sm="12" md="8" order-sm="1" order="2")

      v-textarea.theTextArea(v-model="comments" name='input-7-1', label='GENERAL NOTES',outlined, hide-details)
    v-col.showBorder(cols="12" sm="12" md="4" order-sm="2" order="1")
      v-row(align="end" :style="$store.state.service.subTotalInputsDisabled?{'opacity':0.3}:{'opacity':1.0}")
        v-col.showBorder(cols="4") SubTotal:
        v-col.showBorder.d-flex.justify-end(cols="4") {{$store.state.profile.userInfo.account.region_settings.currency}}
        v-col.showBorder(cols="4")
          service-input2(v-model.number="sub_total" :isDisabled="$store.state.service.subTotalInputsDisabled")
      v-row(align="end")
        v-col.showBorder(cols="4") Labour:
        v-col.showBorder.d-flex.justify-end(cols="4") {{$store.state.profile.userInfo.account.region_settings.currency}}
        v-col.showBorder(cols="4")
          service-input2(v-model.number="sub_labour" :isDisabled="$store.state.service.subLabourInputDisabled")

      v-row(align="end")
        v-col.showBorder(cols="4") Parts:
        v-col.showBorder.d-flex.justify-end(cols="4") {{$store.state.profile.userInfo.account.region_settings.currency}}
        v-col.showBorder(cols="4")
          service-input2(v-model.number="sub_parts" :isDisabled="$store.state.service.subPartsInputDisabled")

      //- v-row(align="center")
        v-col.showBorder(cols="4") Discount:
        v-col.showBorder.py-0(cols="4" style="text-align:right;")
          v-select.body-2(v-model="discount_type" label="Discount Type" hide-details :items="discountTypes"    item-text="label"  item-value="id"  @change="onChange"  )
        v-col.showBorder(cols="4")
          service-input2(v-model.number="discount")

      //- v-row(align="center")
        v-col.showBorder(cols="4") Tax:
        v-col.showBorder.py-0(cols="4" style="text-align:right;")
          v-select.body-2(v-model="tax_type" label="Tax Type" hide-details :items="taxTypes"    item-text="label"  item-value="id"  @change="onChange"  )
        v-col.showBorder(cols="4")
          service-input2(v-model.number="tax")

      v-row(align="center")
        v-col(cols="4")
          v-btn(x-small outlined color="grey" @click="reset") clear all
        v-col.showBorder.py-0(cols="4")
          .primary--text.subtitle-1.d-flex.justify-end() Total:
        v-col.showBorder(cols="4")
          service-input2(v-model="grand_total" :isDisabled="$store.state.service.totalInputDisabled")


</template>
<script>
import ServiceInput2 from "@/modules/services/components/ui/ServiceInput2";

export default {
  components: {
    ServiceInput2
  },

  data: () => ({
    getPath: "service/getCurrentService",
    setPath: "service/fieldUpdate",
    discountTypes: [
      {
        id: "fixed",
        label: "(Ksh) fixed"
      },
      {
        id: "percentage",
        label: "(%) percent"
      }
    ],
    taxTypes: [
      {
        id: "fixed",
        label: "(Ksh) fixed"
      },
      {
        id: "percentage",
        label: "(%) percent"
      }
    ]
  }),

  methods: {
    reset: function () {
      this.$store.commit("service/clearAll")
    },

    onChange: function () {
      this.$store.commit("service/calculateSubTotals");
    }
  },
  computed: {
    //getCurrent Service
    currentService: function () {
      return this.$store.getters["service/getCurrentService"];
    },

    sub_total: {
      get: function () {
        return this.currentService.sub_total;
      },
      set: function (value) {
        this.$store.commit("service/updateService", {
          path: "sub_total",
          value: value
        });
        this.$store.commit("service/calculateSubTotals");
      }
    },

    sub_labour: {
      get: function () {
        return this.currentService.sub_labour;
      },
      set: function (value) {
        this.$store.commit("service/updateService", {
          path: "sub_labour",
          value: value
        });
        this.$store.commit("service/calculateSubTotals");
      }
    },

    sub_parts: {
      get: function () {
        return this.currentService.sub_parts;
      },
      set: function (value) {
        this.$store.commit("service/updateService", {
          path: "sub_parts",
          value: value
        });
        this.$store.commit("service/calculateSubTotals");
      }
    },

    discount_type: {
      get: function () {
        return this.currentService.discount_type;
      },
      set: function (value) {
        this.$store.commit("service/updateService", {
          path: "discount_type",
          value: value
        });
        this.$store.commit("service/calculateSubTotals");
      }
    },

    discount: {
      get: function () {
        return this.currentService.discount;
      },
      set: function (value) {
        this.$store.commit("service/updateService", {
          path: "discount",
          value: value
        });
        this.$store.commit("service/calculateSubTotals");
      }
    },

    tax_type: {
      get: function () {
        return this.currentService.tax_type;
      },
      set: function (value) {
        this.$store.commit("service/updateService", {
          path: "tax_type",
          value: value
        });
        this.$store.commit("service/calculateSubTotals");
      }
    },

    tax: {
      get: function () {
        return this.currentService.tax;
      },
      set: function (value) {
        this.$store.commit("service/updateService", {
          path: "tax",
          value: value
        });
        this.$store.commit("service/calculateSubTotals");
      }
    },

    grand_total: {
      get: function () {
        return this.currentService.grand_total;
      },
      set: function (value) {
        this.$store.commit("service/updateService", {
          path: "grand_total",
          value: value
        });
        this.$store.commit("service/calculateSubTotals");
      }
    },

    comments: {
      get: function () {
        return this.currentService.comments;
      },
      set: function (value) {
        this.$store.commit("service/updateService", {
          path: "comments",
          value: value
        });
        this.$store.commit("service/calculateSubTotals");
      }
    }
  }
};
</script>
<style lang="stylus" scoped></style>
