<template lang="pug">
div
  .btnArrangement.mb-6
    v-btn.ma-2(v-if="this.tree.length > 0" small text color='primary' @click.prevent="onDelete") Delete
    v-btn.ma-2(v-if="this.tree.length > 0" small outlined  color='primary' @click.prevent="onEdit") Edit
    v-btn.ma-2(small, color='primary' @click.prevent="newGroup") Add New
  form-sub-header.white Group Settings
  v-container.white(fill-height)
    v-treeview(v-model='tree',  selectable  :open-all="true"  :items="$store.getters['settings/groupSettings/getTreeList']",  item-key='id')
      template(v-slot:append="{item, open, leaf}")
        .colorSwatch(:style="{'background-color':item.color}")
      //- template(slot='prepend', slot-scope='{ item, open, leaf }')
      //-   v-icon edit
    
        
     
</template>

<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
export default {
  components: {
    FormSubHeader,
    MoreDropDownMenu
  },

  data: () => ({
    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Make Copy", icon: "file_copy", action: "copy" },
      { title: "Delete", icon: "delete", action: "delete" }
    ],

    open: ["public"],
    files: {
      html: "mdi-language-html5",
      js: "mdi-nodejs",
      json: "mdi-json",
      md: "mdi-markdown",
      pdf: "mdi-file-pdf",
      png: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel"
    },
    tree: [],
    items: [
      {
        name: "public",
        children: [
          {
            name: "static",
            children: [
              {
                name: "logo.png",
                file: "png",
                children: [
                  {
                    name: ".gitignore",
                    file: "txt"
                  }
                ]
              }
            ]
          },
          {
            name: "logo.png",
            file: "png"
          },
          {
            name: "index.html",
            file: "html"
          }
        ]
      },
      {
        name: ".gitignore",
        file: "txt"
      }
    ]
  }),

  methods: {
    newGroup: function() {
      this.$store.dispatch("settings/groupSettings/addGroup").then(() => {
        let theID = this.$store.getters[
          "settings/groupSettings/getCurrentGroup"
        ].id;
        this.$router.push({ name: "groupform", params: { groupID: theID } }).catch(()=>{});
      });
    },

    onClick: function(value) {
      console.log(value);
    },

    onDelete: function() {
      this.tree.forEach(item => {
        this.$store.dispatch("settings/groupSettings/deleteGroup", item);
      });
    },

    onEdit: function() {
      console.log(this.tree);
      let theID = this.tree[0];
      this.$router.push({ name: "groupform", params: { groupID: theID } }).catch(()=>{});
    }
  },

  mounted() {
    this.$store.dispatch("settings/groupSettings/loadAllGroups");
  }
};
</script>

<style scoped>
.v-treeview-node__content {
  font-size: 10px;
}

.colorSwatch {
  width: 50px;
  height: 30px;
  border: 1px solid #999999;
  margin-left: 20px;
}
</style>
