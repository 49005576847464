<template lang="pug">
  div
    //- when the panel is a modal window
    template(v-if="$store.state.workflow.formBuilder.modalWindow") 
      .templateGlobalButton( :transfer-data="item.value" @click="onClick(item.value)" :style="{opacity:item.opacity}"  )
        img.templateFormIcon(:src="getImgUrl(item.icon)")
        .templateFormTextStyle {{item.label}}
    //- when the panel is on the right of the screen. Unfortunatly clicking is not allowed during drag
    template(v-else)
      drag.templateGlobalButton( :transfer-data="item.value" @click="onClick(item.value)"  :style="{opacity:item.opacity}" )
        img.templateFormIcon(:src="getImgUrl(item.icon)")
        .templateFormTextStyle {{item.label}}


</template>
<script>
import { Drag } from "vue-drag-drop";

export default {
  components: {
    Drag
  },
  props: {
    item: Object
  },
  data: () => ({}),
  //
  methods: {
    getImgUrl(theIcon) {
      var images = require.context("@/assets/icons/", false, /\.png$/);
      return images("./" + theIcon + ".png");
    },

    onClick: function(value) {
      this.$store.commit("workflow/formBuilder/addNewField", value);
      this.$store.commit("workflow/formBuilder/setModalWindow", false);
    }
  }
};
</script>
<style scoped lang="stylus">
.templateGlobalButton {
  width: 100%
  border 1px dashed #CCC;
  display flex
  align-items center
  padding 4px 2px;
  color: #333

  cursor: pointer
  background-color: '#ffffff'
}

.templateGlobalButton:hover{
  background-color #CCC
  border 1px solid #000000;
}


.templateFormTextStyle {
  font-size 14px;
  font-weight thin;

}

.templateFormIcon {
  width 20px;
  height 20px;
  margin 0px 10px;
}
</style>
