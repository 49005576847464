import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const EXPENSE_API = "/expense";

// ----------------------- STATE ------------------------- //
const state = {
  expense_data: [],
  currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getExpense: state => getField(state.expense_data[state.currentIndex]),

  getCurrentExpense: state => {
    return state.expense_data[state.currentIndex];
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updateExpense(state, payload) {
    updateField(state.expense_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.expense_data.findIndex(
      expense => expense.id == theID
    );
  },

  deleteImage(state, payload) {
    let index = state.expense_data[state.currentIndex].photos_id.findIndex(
      item => item === payload
    );

    state.expense_data[state.currentIndex].photos_id.splice(index, 1);
    console.log(state.expense_data[state.currentIndex].photos_id);
  },

  deleteDocument(state, payload) {
    let index = state.fuel_data[state.currentIndex].documents_id.findIndex(
      item => item === payload
    );
    state.expense_data[state.currentIndex].documents_id.splice(index, 1);
  },

  loadAllExpenses(state, payload) {
    state.expense_data = payload.data;
    state.currentIndex = 0;
  },

  addExpense(state, payload) {
    state.expense_data.push(payload.data);
    state.currentIndex = state.expense_data.length - 1;
  },

  saveExpense(state, payload) {
    state.expense_data[state.currentIndex] = payload.data;
  },

  fetchExpense(state, payload) {
    if (state.currentIndex == -1) {
      state.expense_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.expense_data[state.currentIndex] = payload.data;
    }
  },

  deleteExpense(state, payload) {
    state.currentIndex = state.expense_data.findIndex(
      expense => expense.id == payload.data.id
    );
    state.expense_data.splice(state.currentIndex, 1);
  },

  cleanForSave(state) {
    state.expense_data[state.currentIndex].amount = parseFloat(
      state.expense_data[state.currentIndex].amount
    );
  },
  next(state){
    if(state.currentIndex == state.expense_data.length-1){
      state.currentIndex = 0;
    }else{
      state.currentIndex += 1;
    }
  },

  previous(state){
    if(state.currentIndex == 0){
      state.currentIndex = state.expense_data.length-1;
    }else{
      state.currentIndex -= 1;
    }
  },
  reset(state) {
    state.expense_data = []
    state.currentIndex = -1
  },
  initExpenseItems(state, ) {
    state.expense_data[state.currentIndex].expense_items = [];
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllExpenses({ commit }) {
    try {
      commit("loadAllExpenses", await Repository.get(`${EXPENSE_API}`));
    } catch (error) {
      console.log(error);
    }
  },

  async addExpense({ commit }) {
    await Repository.get(`${EXPENSE_API}/new`)
      .then(response => {
        commit("addExpense", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async saveExpense({ state, commit }) {
    try {
      commit("cleanForSave");
      commit(
        "saveExpense",
        await Repository.post(
          `${EXPENSE_API}`,
          state.expense_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchExpense({ commit }, payloadID) {
    try {
      commit(
        "fetchExpense",
        await Repository.get(`${EXPENSE_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deleteExpense({ commit }, payloadID) {
    console.log(payloadID);
    try {
      commit(
        "deleteExpense",
        await Repository.delete(`${EXPENSE_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchExpenses({ commit }, searchTerm) {
    try {
      commit(
        "loadAllExpenses",
        await Repository.get(`${EXPENSE_API}/search?q=${searchTerm}`)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
