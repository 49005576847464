<template lang="pug">
  div()
    template(v-if="this.blob")
      div(:style="getStyle" @mouseover="showEdit=true" @mouseout="showEdit=false")
        v-btn(v-show="showEditButton()" @click="navigateTo()" text icon color="primary")
          v-icon(small) edit
    template(v-else)
      div(:style="getEmptyStyle")
    
  
</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components: {
    ImageThumbnail
  },
  props: {
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    blob: Object,

    route: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    showEdit: false
  }),

  computed: {
    getEmptyStyle: function() {
      var styleObject = {
        border: "1px solid #E5E5E5",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-position": "center",
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        "border-radius": "6px",
        width: this.width + "px",
        height: this.height + "px",
        "font-size": "10px",
        "border-style": "dashed",
        "border-width": "2px"
      };

      return styleObject;
    },

    getStyle: function() {
      var styleObject = {
        border: "1px solid #E5E5E5",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-position": "center",
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        "border-radius": "6px",
        width: this.width + "px",
        height: this.height + "px",
        "font-size": "10px"
      };
      if (this.blob.thumbnail) {
        // path string
        let path = `/media/${this.blob.account_id}/${this.blob.thumbnail}`;

        // set the background
        styleObject["background-image"] = 'url("' + path + '")';
        styleObject["border-style"] = "solid";
        styleObject["border-width"] = "1px";
      } else {
        styleObject["border-style"] = "dashed";
        styleObject["border-width"] = "2px";
      }

      return styleObject;
    }
  },

  // watch: {
  //   src: function(newValue) {
  //     console.log(`Watched hahahaha!! ${newValue}`);
  //     if (newValue) {
  //       this.loadImage();
  //     } else {
  //       this.resultObject = null;
  //     }
  //   }
  // },

  methods: {
    navigateTo: function() {
      if (this.route) {
        this.$router.push(this.route).catch(()=>{});
      }
    },

    showEditButton: function() {
      if (this.route && this.showEdit) {
        return true;
      } else {
        return false;
      }
    }
  },

  mounted() {}
};
</script>
<style scoped lang="stylus"></style>
