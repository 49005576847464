<template lang="pug">
  div(v-if="$store.state.inventory.item.currentIndex > -1")
    v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
    .d-flex()
      item-column-by-id.py-3(:item_id="$route.params.itemID")
      v-spacer()
      .d-flex.align-center.mr-4()
        template(v-if="loading")
          v-progress-circular.mr-4(:indeterminate="true" color="primary" )
        template(v-else)
          .grey--text.mr-4() {{$store.state.inventory.inventory_data.length}} Records
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='grey', dark, v-on="on")
                div(v-html="`past <b>${timelines[getSelectedTimelineIndex].back}</b>, to Future <b>${timelines[getSelectedTimelineIndex].forward}</b>`")
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item-group(color="primary")
                v-list-item(v-for='(timeline,index) in timelines', :key='index')
                  v-list-item-title.caption(@click="selectTimeline(index)" v-html="`Past <b>${timeline.back}</b>, to Future <b>${timeline.forward}</b>`")
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item-group(color="primary")
                v-list-item(v-for='item in items', :key='item.title' @click='onAction(item.value)')
                  v-icon.pr-2() {{item.icon}}
                  v-list-item-title() {{item.title}}
    div.white.pt-2()
      v-tabs.tabs(v-model="pageIndex" height="32" left slider-color="primary")
        v-tab.tabs() Activity Log
        v-tab-item()
          activity-item()
        v-tab.tabs() Calendar
        v-tab-item()
          activity-item-calendar()
        v-tab.tabs() Overview
        v-tab-item()
          overview-item()
    export-data-dialog(:fileExport="fileExport" :isFinished="isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="showExportDialog = false;" @download="onDownload" )
    v-dialog(v-model="importDialog" width="800" :persistent="true")
      import-data-dialog(@closeImportDialog="importDialog=false" :templateUrl="`/api/report/inventoryTemplateCSV?item_id=${currentItem.id}`" :uploadUrl="`/inventory/upload?item_id=${currentItem.id}`" loadAllDispatch="inventory/loadAllInventorys")
</template>

<script>
import OverviewItem from "@/modules/inventory/items/components/overview/OverviewItem";
import ActivityItem from "@/modules/inventory/layouts/ActivityItem";
import ItemColumnById from "@/modules/global/components/table/ItemColumnById";
import ActivityItemCalendar from "@/modules/inventory/layouts/ActivityItemCalendar";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog.vue";
import ImportDataDialog from "@/modules/global/components/dialog/ImportDataDialog.vue";
import Repository from "@/repository";

export default {
  components: {
    OverviewItem,
    ActivityItem,
    ItemColumnById,
    ActivityItemCalendar,
    ExportDataDialog,
    ImportDataDialog,
  },

  data:() => ({
    loading: true,
    importDialog: false,
    items: [
      {
        icon: "delete",
        title: "Delete Selected",
        value: "delete_selected"
      },
      {
        icon: "cloud_upload",
        title: "Import CSV",
        value: "import_csv"
      },
      {
        icon: "cloud_download",
        title: "Export XLS",
        value: "export_xls"
      }
    ],
    isDownloadFinished: false,
    fileLink: null,
    showExportDialog: false,
    fileExport: "",
  }),


  computed: {
    pageIndex: {
      get: function () {
        return this.$route.params.tabID;
      },
      set: function (value) {
        // this.$route.params.tabID = value;
        this.$router.push({
          name: "inventoryOverviewLayout",
          params: {tabID: value}
        }).catch(()=>{});
      }

    },

    currentItem: function () {
      return this.$store.getters["inventory/item/getCurrentItem"];
    },

    getSelectedTimelineIndex: function(){
      return this.$store.state.inventory.selectedTimelineIndex
    },

    timelines: function(){
      return this.$store.state.inventory.timelines
    }
  },
  methods: {
    selectTimeline: function(index){
      this.$store.commit("inventory/setTimelineIndex",index);
      // reload file
      this.loading = true;
      this.$store.dispatch("inventory/loadAllByItem", this.$route.params.itemID).then(()=>{
        this.loading = false;
      })
    },

    onAction: function (value) {
      switch (value) {
        case "download_template":
          console.log("Download template")
          this.fileExport = "Excel";
          this.downloadFile(`/report/inventoryTemplate?item_id=${this.currentItem.id}`);
          this.showExportDialog = !this.showExportDialog;
          break;

        case "import_csv":
          this.importDialog = true;
          break;
        case "export_xls":
          this.fileExport = "Excel";
          this.downloadFile(`/report/Inventory?item_id=${this.currentItem.id}`);
          this.showExportDialog = !this.showExportDialog;
          break;
        case "delete_selected":
          var deletedIds = [];

          this.$store.getters["inventory/getSelectedItems"].forEach((element) => {
            deletedIds.push(element.id);
          })
          if (deletedIds.length == 0) break;
          confirm(`Are you sure you want to delete this ${deletedIds.length} items?`) &&
          this.$store.dispatch("inventory/deleteSelectedInventories", deletedIds);
          break;
      }

    },

    downloadFile: function (url) {
      this.isDownloadFinished = false;
      console.log(url)

      Repository({
        method: "GET",
        url: url,
        responseType: "blob",
      }).then((response) => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function () {
      // download the file
      this.fileLink.click();
      this.showExportDialog = false;
    },

    showDialog: function () {
    },

    closeDialog: function (value) {
      console.log(value);
    },

    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Items List",
        disabled: false,
        to: {name: "inventorys"},
      });
      theArray.push({
        text: "Item Details",
        disabled: true,
      });
      return theArray;
    },
  },
  mounted() {

    if(this.$store.state.inventory.item.currentIndex == -1){
      this.$store.dispatch("inventory/item/fetchItem",this.$route.params.itemID).then(()=>{
        this.$store.dispatch("inventory/loadAllByItem", this.$route.params.itemID).then(()=>{
          this.loading = false;
        })
      })
    }else{
      this.$store.dispatch("inventory/loadAllByItem", this.$route.params.itemID).then(()=>{
        this.loading = false;
      })
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>