import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const CONTACT_RENEWAL_TYPE_API = "/contactRenewalType";

// ----------------------- STATE ------------------------- //
const state = {
  contact_renewal_type_data: [],
  currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getContact_renewal_type: state =>
    getField(state.contact_renewal_type_data[state.currentIndex]),

  getCurrentContact_renewal_type: state => {
    return state.contact_renewal_type_data[state.currentIndex];
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updateContact_renewal_type(state, payload) {
    updateField(state.contact_renewal_type_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.contact_renewal_type_data.findIndex(
      contact_renewal_type => contact_renewal_type.id == theID
    );
  },

  loadAllContact_renewal_types(state, payload) {
    state.contact_renewal_type_data = payload.data;
    state.currentIndex = 0;
  },

  addContact_renewal_type(state, payload) {
    state.contact_renewal_type_data.push(payload.data);
    state.currentIndex = state.contact_renewal_type_data.length - 1;
  },

  saveContact_renewal_type(state, payload) {
    state.contact_renewal_type_data[state.currentIndex] = payload.data;
  },

  fetchContact_renewal_type(state, payload) {
    if (state.currentIndex == -1) {
      state.contact_renewal_type_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.contact_renewal_type_data[state.currentIndex] = payload.data;
    }
  },

  deleteContact_renewal_type(state, payload) {
    state.currentIndex = state.contact_renewal_type_data.findIndex(
      contact_renewal_type => contact_renewal_type.id == payload.data.id
    );
    state.contact_renewal_type_data.splice(state.currentIndex, 1);
  },
  reset(state) {
    state.contact_renewal_type_data = []
    state.currentIndex = -1
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllContact_renewal_types({ commit }) {
    try {
      commit(
        "loadAllContact_renewal_types",
        await Repository.get(`${CONTACT_RENEWAL_TYPE_API}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async addContact_renewal_type({ commit }) {
    await Repository.get(`${CONTACT_RENEWAL_TYPE_API}/new`)
      .then(response => {
        commit("addContact_renewal_type", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async saveContact_renewal_type({ state, commit }) {
    try {
      commit(
        "saveContact_renewal_type",
        await Repository.post(
          `${CONTACT_RENEWAL_TYPE_API}`,
          state.contact_renewal_type_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchContact_renewal_type({ commit }, payloadID) {
    try {
      commit(
        "fetchContact_renewal_type",
        await Repository.get(`${CONTACT_RENEWAL_TYPE_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deleteContact_renewal_type({ commit }, payloadID) {
    try {
      commit(
        "deleteContact_renewal_type",
        await Repository.delete(`${CONTACT_RENEWAL_TYPE_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchContact_renewal_types({ commit }, searchTerm) {
    try {
      commit(
        "loadAllContact_renewal_types",
        await Repository.get(
          `${CONTACT_RENEWAL_TYPE_API}/search?q=${searchTerm}`
        )
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
