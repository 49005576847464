<template lang="pug">
  div()
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    v-select(v-model="selectedFieldType" :items="items" item-text="text" item-value="value" label="Data Type" )
    v-text-field(v-model="selectHint" label="Description")

    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        showAdvancedProperties(:showAdvanced="showAdvanced" )
      div(v-show="showAdvanced")
        v-text-field(v-model="selectDefault" label="Default Value")
        permissions-component()
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)


</template>

<script>
import ShowAdvancedProperties from "@/modules/workflow/formBuilder/components/ui/ShowAdvancedProperties.vue";
import SETTINGS_DEFAULT from "@/modules/workflow/formBuilder/store/formSettings";
import PermissionsComponent from "@/modules/workflow/formBuilder/components/ui/PermissionsComponent.vue";

export default {
  components: {
    ShowAdvancedProperties,
    PermissionsComponent
  },
  data: () => ({
    properties: {},
    showAdvanced: false,
    items:[
      {
        value:"string",
        text:"Text",
      },
      {
        value:"float",
        text:"Number",
      },
      {
        value:"timestamp",
        text:"Timestamp",
      },
      {
        value:"location",
        text:"Location",
      },
      // {
      //   value:"osrm",
      //   text:"Route",
      // },
      // {
      //   value:"place",
      //   text:"Place",
      // },
    ],
  }),
  computed: {
    selectedFieldName: {
      get: function () {
        return this.properties.label;
      },
      set: function (value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "label",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },

    selectedFieldType: {
      get: function() {
        console.log(this.properties.type)
        return this.properties.type || this.items[0]
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "type",
          value: value
        });
      }
    },

    selectHint: {
      get: function () {
        return this.properties.hint;
      },
      set: function (value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "hint",
          value: value
        });
      }
    },


    selectID: {
      get: function () {
        return this.$store.getters[SETTINGS_DEFAULT.GET_PATH].id;
      },
      set: function (value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },

    selectDefault: {
      get: function() {
        return this.properties.default;
      },

      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "default",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "value",
          value: value
        });
      }
    },
  },
  mounted() {
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    this.$refs.fieldName.focus();
  }
}
</script>

<style scoped>

</style>