import ContactNew from "@/modules/contacts/layouts/ContactNew";
import BasicInformation from "@/modules/contacts/components/forms/BasicInformation";
import UserInformation from "@/modules/contacts/components/forms/UserInformation";
import ContactsInformation from "@/modules/contacts/components/forms/ContactsInformation";
import PersonalInformation from "@/modules/contacts/components/forms/PersonalInformation";

import ContactOverview from "@/modules/contacts/layouts/ContactOverview";
import ContactComments from "@/modules/contacts/layouts/overviews/ContactComments";
import ContactDashboard from "@/modules/contacts/layouts/overviews/ContactDashboard";
import ContactDocumentsUploads from "@/modules/contacts/layouts/overviews/ContactDocumentsUploads";
import ContactPhotosUploads from "@/modules/contacts/layouts/overviews/ContactPhotosUploads";
import ContactInspection from "@/modules/contacts/layouts/overviews/ContactInspection";
import ContactIssues from "@/modules/issues/components/ui/OverviewIssues";
import ContactAssignment from "@/modules/contacts/layouts/overviews/ContactAssignment";
import ContactLocation from "@/modules/contacts/layouts/overviews/ContactLocation";
import ContactNotification from "@/modules/contacts/layouts/overviews/ContactNotification";
import ContactUserManagement from "@/modules/contacts/layouts/overviews/ContactUserManagement";
import ContactRenewal from "@/modules/contacts/layouts/overviews/ContactRenewal";
import CustomerDetailsForm from "@/modules/contacts/customers/components/forms/CustomerDetailsForm";
import MerchantDetailsForm from "@/modules/contacts/merchants/components/forms/MerchantDetailsForm";
import CustomerOverview from "@/modules/contacts/customers/overviews/CustomerOverview";
import CustomerDashboard from "@/modules/contacts/customers/overviews/CustomerDashboard";
import CustomerInventories from "@/modules/contacts/customers/overviews/CustomerInventories";
import CustomerVisitation from "@/modules/contacts/customers/overviews/CustomerVisitation";

export const CONTACT_ROUTES = [
  {
    path: "edit",
    name: "contactEdit",
    component: ContactNew,
    children: [
      {
        path: "basic",
        name: "contactNewBasic",
        component: BasicInformation
      },
      {
        path: "user",
        name: "contactUserInfo",
        component: UserInformation
      },
      {
        path: "info",
        name: "contactNewInfo",
        component: ContactsInformation
      },
      {
        path: "personal",
        name: "contactNewPersonal",
        component: PersonalInformation
      }
    ]
  },
  {
    path: "overview",
    name: "contactOverview",
    component: ContactOverview,
    children: [
      {
        path: "dashboard/:contactTabID?",
        name: "contactDashboard",
        component: ContactDashboard
      },

      {
        path: "documents",
        name: "contactDocuments",
        component: ContactDocumentsUploads
      },

      {
        path: "photos",
        name: "contactPhotos",
        component: ContactPhotosUploads
      },
      {
        path: "comments",
        name: "contactComments",
        component: ContactComments
      },
      {
        path: "inspection",
        name: "contactInspection",
        component: ContactInspection
      },
      {
        path: "issue",
        name: "contactIssues",
        component: ContactIssues
      },
      {
        path: "renewal",
        name: "contactRenewal",
        component: ContactRenewal
      },
      {
        path: "assignment",
        name: "contactAssignment",
        component: ContactAssignment
      },
      {
        path: "location",
        name: "contactLocation",
        component: ContactLocation
      },
      {
        path: "notification",
        name: "contactNotification",
        component: ContactNotification
      },
      {
        path: "userManagment",
        name: "contactUserManagement",
        component: ContactUserManagement
      }
    ]
  },
  {
    path: "overviewCustomer",
    name: "customerOverview",
    component: CustomerOverview,
    children: [
      {
        path: "dashboard/:contactTabID?",
        name: "customerDashboard",
        component: CustomerDashboard
      },


      {
        path: "documents",
        name: "contactDocuments",
        component: ContactDocumentsUploads
      },

      {
        path: "photos",
        name: "contactPhotos",
        component: ContactPhotosUploads
      },
      {
        path: "comments",
        name: "contactComments",
        component: ContactComments
      },
      {
        path: "inspection",
        name: "contactInspection",
        component: ContactInspection
      },
      {
        path: "issue",
        name: "contactIssues",
        component: ContactIssues
      },
      {
        path: "renewal",
        name: "contactRenewal",
        component: ContactRenewal
      },
      {
        path: "assignment",
        name: "contactAssignment",
        component: ContactAssignment
      },
      {
        path: "location",
        name: "contactLocation",
        component: ContactLocation
      },
      {
        path: "inventories",
        name: "customerInventories",
        component: CustomerInventories
      },
      {
        path:"vehicleVisitations",
        name: "customerVisitation",
        component: CustomerVisitation
      },
      {
        path: "userManagment",
        name: "contactUserManagement",
        component: ContactUserManagement
      }
    ]
  },
  {
    path: "customerDetailsForm",
    name: "customerDetailsForm",
    component: CustomerDetailsForm
  },
  {
    path: "merchantDetailsForm",
    name: "merchantDetailsForm",
    component: MerchantDetailsForm
  }
];
