import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const SERVICE_REMINDER_API = "/serviceReminder";

// ----------------------- STATE ------------------------- //
const state = {
    service_reminder_data: [],
    currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getService_reminder: state =>
        getField(state.service_reminder_data[state.currentIndex]),

    getCurrentService_reminder: state => {
        return state.service_reminder_data[state.currentIndex];
    },

    getVehicleServiceReminder: state => vehicleID => {
        return state.service_reminder_data.filter(item => {
            let index = item.vehicles_id.findIndex(theID => theID == vehicleID);
            return index != -1;
        });
    },
    getOverdue: state => vehicleID => {
        // todo get Overdue
        let serviceReminders = state.service_reminder_data.filter(item => {
            let index = item.vehicles_id.findIndex(theID => theID == vehicleID);
            return index != -1;
        });
        return serviceReminders.length;
    },

    getDueSoon: state => vehicleID => {
        // todo Get Due Soon
        let serviceReminders = state.service_reminder_data.filter(item => {
            let index = item.vehicles_id.findIndex(theID => theID == vehicleID);
            return index != -1;
        });
        return serviceReminders.length;
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateService_reminder(state, payload) {
        updateField(state.service_reminder_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.service_reminder_data.findIndex(
            service_reminder => service_reminder.id == theID
        );
    },

    loadAllService_reminders(state, payload) {
        state.service_reminder_data = payload.data;
        state.currentIndex = 0;
    },

    addService_reminder(state, payload) {
        state.service_reminder_data.push(payload.data);
        state.currentIndex = state.service_reminder_data.length - 1;
    },

    saveService_reminder(state, payload) {
        state.service_reminder_data[state.currentIndex] = payload.data;
    },

    fetchService_reminder(state, payload) {
        if (state.currentIndex == -1) {
            state.service_reminder_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.service_reminder_data[state.currentIndex] = payload.data;
        }
    },

    fetchByVehicleIDService_reminder(state, payload) {
        if (payload.data.length > 0) {
            state.service_reminder_data = payload.data;
            state.currentIndex = -1;
        }
    },

    deleteService_reminder(state, payload) {
        state.currentIndex = state.service_reminder_data.findIndex(
            service_reminder => service_reminder.id == payload.data.id
        );
        state.service_reminder_data.splice(state.currentIndex, 1);
    },

    cleanUpStringToNumbers(state) {
        let item = state.service_reminder_data[state.currentIndex];
        item.start_odometer = parseInt(item.start_odometer);
        item.interval_odometer = parseInt(item.interval_odometer);
        item.due_odometer = parseInt(item.due_odometer);
    },
    reset(state) {
        state.service_reminder_data = []
        state.currentIndex = -1
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllService_reminders({commit}) {
        try {
            commit(
                "loadAllService_reminders",
                await Repository.get(`${SERVICE_REMINDER_API}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async addService_reminder({commit}) {
        await Repository.get(`${SERVICE_REMINDER_API}/new`)
            .then(response => {
                commit("addService_reminder", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveService_reminder({state, commit}) {
        try {
            commit("cleanUpStringToNumbers");
            commit(
                "saveService_reminder",
                await Repository.post(
                    `${SERVICE_REMINDER_API}`,
                    state.service_reminder_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchService_reminder({commit}, payloadID) {
        try {
            commit(
                "fetchService_reminder",
                await Repository.get(`${SERVICE_REMINDER_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchByVehicleIDService_reminder({commit}, payloadID) {
        try {
            commit(
                "fetchByVehicleIDService_reminder",
                await Repository.get(`${SERVICE_REMINDER_API}/vehicle/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteService_reminder({commit}, payloadID) {
        try {
            commit(
                "deleteService_reminder",
                await Repository.delete(`${SERVICE_REMINDER_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchService_reminders({commit}, searchTerm) {
        try {
            commit(
                "loadAllService_reminders",
                await Repository.get(`${SERVICE_REMINDER_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
