<template lang="pug">
  div
    v-text-field(
      v-model="computedValue" 
      :label="schema.label" 
      :type="getType"
      :hint="schema.hint" 
      :persistent-hint="schema.hint != null"
      :counter="schema.maxlength != null"
      :maxlength="schema.maxlength"
      :rules="[requiredRule]"
      @keypress="integerNumbers($event)"
      :disabled="disabled"
      )
</template>
<script>
export default {
  props: ["schema", "dataValue", "index","disabled"],
  data: () => ({}),

  computed: {
    requiredRule: function() {
      return this.schema.required ? value => !!value || "Required." : false;
    },

    getType:function(){
      if(this.schema.type == 'decimal'){
        return 'number'
      }
      return this.schema.type;
    },

    computedValue: {
      get: function() {
        return this.dataValue;
      },

      set: function(theValue) {
        if(this.getType === 'number'){
          theValue =  parseInt(theValue)
        }

        if(this.schema.type === 'decimal'){
          theValue = parseFloat(theValue)
        }
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    }
  },

  methods:{
    integerNumbers:function(event){
      // ignore the key "e" and "." from numbers
      if(this.schema.type == 'number'){
        if (/e/.test(event.key) || /\./.test(event.key) ) {
          event.preventDefault();
        } else {
          return true

        }
      }
    }
  },

  mounted() {}
};
</script>
<style scoped lang="stylus"></style>
