<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    v-select(v-model="selectedFieldType" :items="items" item-text="text" item-value="value" label="Jahazi Field" )
    v-text-field(v-model="selectHint" label="Description")


    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        showAdvancedProperties(:showAdvanced="showAdvanced" )
      div(v-show="showAdvanced")
        v-switch(
          v-model="selectMultiple"
          color="primary"
          label="Multiple"
        )
        //v-switch(
        //  v-model="selectDefault"
        //  color="primary"
        //  :label="`Default Value : ${selectDefault?'on':'off'}` "
        //)
        permissions-component()
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)

</template>
<script>
// var snakeCase = require('lodash.snakecase');
import SETTINGS_DEFAULT from "@/modules/workflow/formBuilder/store/formSettings";
import ShowAdvancedProperties from "@/modules/workflow/formBuilder/components/ui/ShowAdvancedProperties";
import PermissionsComponent from "@/modules/workflow/formBuilder/components/ui/PermissionsComponent.vue";

export default {
  components: {
    ShowAdvancedProperties,
    PermissionsComponent
  },

  data: () => ({
    properties: {},
    showAdvanced: false,
    items:[
      {
        value:"vehicle",
        text:"Vehicle",
      },
      {
        value:"contact",
        text:"Contact",
      },
      {
        value:"customer",
        text:"Customer",
      },
      {
        value:"merchant",
        text:"Merchant",
      },
      {
        value:"group",
        text:"Group",
      },
      // {
      //   value:"osrm",
      //   text:"Route",
      // },
      // {
      //   value:"place",
      //   text:"Place",
      // },
    ],

  }),

  computed: {
    selectedFieldName: {
      get: function() {
        return this.properties.label;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "label",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },

    selectedFieldType: {
      get: function() {
        console.log(this.properties.type)
        return this.properties.type || this.items[0]
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "type",
          value: value
        });
      }
    },


    selectHint: {
      get: function() {
        return this.properties.hint;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "hint",
          value: value
        });
      }
    },



    selectDefault: {
      get: function() {
        return this.properties.default;
      },

      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "default",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "value",
          value: value
        });
      }
    },

    selectMultiple: {
      get: function (){
        return this.properties.multiple;
      },
      set: function(value){
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "multiple",
          value: value
        });
      }
    },

    selectRequired: {
      get: function() {
        return this.properties.required;
      },

      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "required",
          value: value
        });
      }
    },

    selectID: {
      get: function() {
        return this.$store.getters[SETTINGS_DEFAULT.GET_PATH].id;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },



  },

  mounted() {
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    this.$refs.fieldName.focus();
  }
};
</script>
<style scoped lang="stylus">
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}
</style>
