<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*")
    v-checkbox(v-model="selectRequired" label="Required")
    div(@click.prevent="showAdvanced=!showAdvanced")
      showAdvancedProperties(:showAdvanced="showAdvanced" )
    div(v-show="showAdvanced")
      v-checkbox(v-model="selectCommentOnFail" label="Must comment when no drawing is done")
      v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)
</template>
<script>
import {inspectionPropertiesMixin} from "@/modules/inspections/components/formDesigner/properties/inspectionPropertiesMixin";
export default {
  mixins:[inspectionPropertiesMixin],
};
</script>
<style scoped lang="stylus"></style>
