<template lang="pug">
  div()
    v-container(style="min-width:800px")
      .headline() Workflow Process
      v-row.fill-width(align="center")
        v-col(cols="12")
          v-select(:items="entry" label="On Entry")
          v-textarea(outlined rows="5")
          v-btn(color="primary") update

        v-col(cols="12")
          v-select(:items="update" label="On Update")
          v-textarea(outlined  rows="5")
          v-btn(color="primary") update

</template>

<script>
export default {
  data: ()=> ({
    entry:["Send SMS","Send Email","Enter Script"],
    update:["Send SMS","Send Email","Enter Script"],
  })
}
</script>

<style scoped lang="stylus">

</style>