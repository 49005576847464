<template lang="pug">
  div(v-if="$store.state.service.serviceType.currentIndex != -1 && $store.state.service.currentIndex != -1")
    v-toolbar(dense, flat)
      v-toolbar-title Service Details
      v-spacer
      v-toolbar-items.hidden-sm-and-down
    v-divider

    v-container.py-2
      v-alert(v-if="error" :value="true" color="error" icon="warning")
        div(v-for="e,index in errors" :key="index" v-text="e")

      v-layout(justify-end)
        v-btn.ma-2(small text color="grey" @click="cancel") cancel
        v-btn.ma-2(small outlined color="primary" @click="saveAdd") Save &amp; Add another
        v-btn.ma-2(small color="primary" @click="save") Save

    v-container
      v-card
        v-form(ref="form" v-model="valid" lazy-validation)
          v-container(grid-list-xl)
            form-divider SERVICE ENTRY DETAILS
            v-row(align="end")
              v-col(cols="12" xs="12" md="6" xl="4")
                vehicle-auto-complete(v-model='vehicle_id')
              v-col(cols="12" xs="12" md="6" xl="4")
                auto-date-time-picker(v-model="start_date_time", labelDate="Start Date", labelTime="Start Time", :hideTime="true")

              v-col(cols="12" xs="12" md="6" xl="4")
                odometer-input(v-model.number='odometer' :vehicle_id="vehicle_id" :time="start_date_time")
              v-col(cols="12" xs="12" md="6" xl="4")
                auto-date-time-picker(v-model="completion_date_time", labelDate="Completion Date", labelTime="Completion Time" :hideTime="true")
              v-col(cols="12" xs="12" md="6" xl="4")
                merchant-auto-complete(v-model='merchant_id')
              v-col(cols="12" xs="12" md="6" xl="4")
                v-text-field(v-model='reference', hint='Optional (e.g. invoice number, etc.)', :persistent-hint='true', label='Reference')
            //-- form-divider ISSUES {{getVehicleIssues.length}}
            //-- .issuesCard
              v-tabs.tabs(left, height='32', slider-color='primary')
                v-tab.tabs()  Open 
                  v-avatar.grey.darken-2.white--text.ml-2.avatarText(size='16') {{ this.openIssues.length }}
                v-tab-item
                  v-alert.mt-2(dismissible, type='success',outlined)
                    | Select any issues that were resolved as part of this service.
                  service-issues-table(:tabledata='this.openIssues')
                v-tab.tabs Resolved 
                  v-avatar.grey.darken-2.white--text.ml-2.avatarText(size='16') {{ this.resolvedIssues.length }}
                v-tab-item
                  service-issues-table(:tabledata='this.resolvedIssues')
                v-tab.tabs Closed 
                  v-avatar.grey.darken-2.white--text.ml-2.avatarText(size='16') {{ this.closedIssues.length }}
                v-tab-item
                  service-issues-table(:tabledata='this.closedIssues')

            form-divider LINE ITEMS
            v-autocomplete(
              ref='theCombo'
              v-model='serviceTasks'
              :items='$store.state.service.serviceType.servicetype_data'
              label="Serivce Tasks"
              item-text='name'
              item-value='id'
              filled
              multiple
              @keyup.enter="onAddServiceTaskComboBox"
              :hide-no-data="!search"
              :search-input.sync="search"

            )
              template(slot='selection', slot-scope='data')
                v-chip.chip--select-multi(:input-value='data.selected', small close @click:close="onRemove(data.item.id)")
                  | {{ data.item.name }}

              template(slot="no-data")
                v-list-item
                  span.subheading Create
                  v-chip.ml-2(:color="'primary white--text'" label small) {{search}}


            //- div() {{service_details}}
            service-task-list-table(v-if="service_details.length > 0" :service_details="service_details")
            service-sub-totals()

            form-divider ATTACHMENTS
            v-row.mt-4.mx-2()
              v-col(cols="12"  xs="12" md="6")
                attach-fill-form( :schema="imagesSchama" :data="{value:photos_id}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" )
              v-col(cols="12"  xs="12" md="6")
                attach-fill-form( :schema="documentsSchama" :data="{value:documents_id}" @onChange="saveDocuments"  @onMenuAction="onMenuActionDocuments")
        v-layout.mt-4.mb-3(justify-end)
          v-btn.ma-2(small text color="grey" @click="cancel") cancel
          v-btn.ma-2(small outlined color="primary" @click="saveAdd") Save &amp; Add another
          v-btn.ma-2(small color="primary" @click="save") Save


</template>

<script>
import {createHelpers} from "vuex-map-fields";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import OdometerInput from "@/modules/global/components/forms/OdometerInput";
import VehicleAutoComplete from "@/modules/global/components/forms/VehicleAutoComplete";
import FormPhotoUpload from "@/modules/global/components/forms/FormPhotoUpload";
import ServiceIssuesTable from "@/modules/services/components/tables/ServiceIssuesTable";
import ServiceTaskListTable from "@/modules/services/components/tables/ServiceTaskListTable";
import ServiceSubTotals from "@/modules/services/components/ui/ServiceSubTotals";
import AutoDateTimePicker from "@/modules/global/components/forms/AutoDateTimePicker";
import MerchantAutoComplete from "@/modules/global/components/forms/MerchantAutoComplete";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";

const {mapFields} = createHelpers({
  getterType: "service/getService",
  mutationType: "service/updateService"
});

export default {
  components: {
    FormDivider,
    VehicleAutoComplete,
    FormPhotoUpload,
    ServiceIssuesTable,
    ServiceTaskListTable,
    ServiceSubTotals,
    OdometerInput,
    AutoDateTimePicker,
    MerchantAutoComplete,
    AttachFillForm
  },
  data: () => ({
    isLoaded: false,
    error: false,
    errors: [],
    valid: true,
    search: null,
    menu: false,
    menu2: false,
    menu3: false,
    menu4: false,

    alert: "Select any issues that were resolved as part of this service.",

    autoUpdate: true,

    isUpdating: false,

    people: [
      {header: "Group 1"},
      {name: "Sandra Adams", group: "Group 1"},
      {name: "Ali Connors", group: "Group 1"},
      {name: "Trevor Hansen", group: "Group 1"},
      {name: "Tucker Smith", group: "Group 1"},
      {divider: true},
      {header: "Group 2"},
      {name: "Britta Holt", group: "Group 2"},
      {name: "Jane Smith ", group: "Group 2"},
      {name: "John Smith", group: "Group 2"},
      {name: "Sandra Williams", group: "Group 2"}
    ],

    imagesSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Service Images",
      hint: null,
      type: "image",
      image_size: "small",
      multiple: true
    },

    documentsSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Service Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true
    },

  }),

  methods: {
    onClickMe: function () {
    },

    cancel: function () {
      console.log("cancel");
    },

    save: function () {
      this.$store.commit("service/calculateSubTotals");
      this.$store.dispatch("service/saveService").then(() => {
        this.$store.dispatch("service/loadAllServices").then(() => {
          this.$router.push({name: "services", params: {tabID: 0}}).catch(()=>{})
        })

      });
    },

    saveAdd: function () {
      console.log("saveAdd");
    },

    onRemove: function (selectedID) {
      this.$store.commit("service/removeServiceDetailByServiceTypeID", {
        id: selectedID
      });
      this.$store.commit("service/calculateSubTotals");
    },

    refreshVehicleIssues: function (theID) {
      if (theID !== "00000000-0000-0000-0000-000000000000" || theID != null) {
        // Load issues for the vehicle
        this.$store.dispatch("issues/loadVehicleIssues", theID);
      }
    },

    onAddServiceTaskComboBox: function () {
      console.log("++++++++++++++++++=");
      console.log(this.$refs.theCombo.$data.selectedItems);
      let selectTypeData = this.$store.state.service.serviceType
          .servicetype_data;
      let isFound = false;

      // filter to remove that which is selected
      for (var i = 0; i < selectTypeData.length; i++) {
        if (
            selectTypeData[i].name
                .toLocaleLowerCase()
                .indexOf(this.search.toLocaleLowerCase()) > -1
        ) {
          isFound = true;
          break;
        }
      }
      console.log(isFound);
      if (this.search && isFound == false) {
        console.log("add Service");
        this.$store.dispatch("service/serviceType/addServiceType").then(() => {
          // mutate store
          this.$store.commit(`service/serviceType/updateServiceType`, {
            path: "name",
            value: this.search
          });

          // get object to add to selected
          let currentItem = this.$store.getters[
              "service/serviceType/getCurrentServiceType"
              ];

          this.$store
              .dispatch("service/serviceType/saveServiceType")
              .then(() => {
                this.serviceTasks.push(currentItem.id);
                this.search = null;
              });
        });
      }
    },

    saveImages: function(theObject) {
      this.$store.commit("service/updateService", {
        path: "photos_id",
        value: theObject.value
      });
    },

    saveDocuments: function(theObject) {
      this.$store.commit("service/updateService", {
        path: "documents_id",
        value: theObject.value
      });
    },

    onMenuActionImages: function(value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("service/deleteImage", value.theObject.id);
          break;
      }
    },

    onMenuActionDocuments: function(value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("service/deleteDocument", value.theObject.id);
          break;
      }
    }
  },

  computed: {
    vehicle_id: {
      get: function () {
        if (this.$store.getters["service/getCurrentService"] != null) {
          return this.$store.getters["service/getCurrentService"].vehicle_id;
        } else {
          return null;
        }
      },

      set: function (value) {
        this.$store.commit("service/updateService", {
          path: "vehicle_id",
          value: value
        });
        this.refreshVehicleIssues(value);
      }
    },

    ...mapFields([
      "odometer",
      "completion_date_time",
      "start_date_time",
      "merchant_id",
      "reference",

      "sub_total",
      "sub_labour",
      "sub_parts",
      "discount",
      "discount_type",
      "tax",
      "tax_type",
      "total",
      "tags_id",
      "issues_id",
      "service_details",
      "account_id",
      "comments",
        "photos_id",
        "documents_id"
    ]),

    getVehicleIssues: function () {
      return [];
      // let result = this.$store.state.issues.issues_data;
      // console.log(result);
      // if (result != null) {
      //   return result;
      // } else {
      //   return [];
      // }
    },

    openIssues: function () {
      return this.getVehicleIssues.filter(item => item.status == "open");
    },

    resolvedIssues: function () {
      return this.getVehicleIssues.filter(item => item.status == "resolved");
    },

    closedIssues: function () {
      return this.getVehicleIssues.filter(item => item.status == "closed");
    },

    serviceTasks: {
      get: function () {
        return this.$store.getters["service/getServiceTypesIDs"];
      },

      set: function (updatedServiceTasksIDs) {
        // hapa iko mambo

        //  ------------------------ DELETEING FUNCTION ----------------//

        // console.log(updatedServiceTasksIDs)
        var tempDeleteArray = [];
        this.currentService.service_details.forEach(tmp => {
          tempDeleteArray.push(tmp);
        });
        // console.log(tempDeleteArray)
        tempDeleteArray.forEach(theItem => {
          var isFound2 = false;
          updatedServiceTasksIDs.forEach(up => {
            if (up === theItem.serviceTypeID) {
              isFound2 = true;
            }
          });

          if (isFound2 == false) {
            this.$store.commit("service/removeServiceDetail", theItem);
          }
        });

        //------------- ADDITION FUNCTION --------------------

        // find service task
        var isFound;
        updatedServiceTasksIDs.forEach(updatedServiceTaskID => {
          isFound = false;
          for (var i = 0; i < this.currentService.service_details.length; i++) {
            if (
                updatedServiceTaskID ==
                this.currentService.service_details[i].service_type_id
            ) {
              isFound = true;
            }
          }

          // if missing task if found. create a new ServiceDetail
          if (isFound == false) {
            let tempServiceDetail = {
              id: this.$uuid.v4(),
              service_id: this.currentService.id,
              vehicle_id: this.currentService.vehicle_id,
              account_id: this.currentService.account_id,
              service_type_id: updatedServiceTaskID,
              issue: null,
              issue_id: null,
              labour: 0,
              parts: 0,
              total: 0,
              account: null,
              labour_disabled: false,
              parts_disabled: false,
              total_disabled: false,
              comments: null
            };

            // insert the serviceType Object (may be optional)
            var stIndex = this.$store.state.service.serviceType.servicetype_data.findIndex(
                tmp => tmp.id == updatedServiceTaskID
            );
            tempServiceDetail.service_type = this.$store.state.service.serviceType.servicetype_data[
                stIndex
                ];

            //Add the service Detail to
            this.$store.commit("service/addServiceDetail", tempServiceDetail);
          }
        });

        // call the calculating function
        this.$store.commit("service/calculateSubTotals");
      }
    },

    //getCurrent Service
    currentService: function () {
      return this.$store.getters["service/getCurrentService"];
    }
  },

  mounted() {
    if(this.$store.state.service.serviceType.currentIndex == -1){
      this.$store.dispatch("service/serviceType/loadAllServiceTypes");
    }


    if (this.$store.state.service.currentIndex == -1 ) {
      this.$store.dispatch("service/fetchService", this.$route.params.serviceID);
    }


  },
  //   if (this.$store.state.service.currentIndex == -1) {
  //     // Load
  //     this.$store
  //         .dispatch("service/fetchService", this.$route.params.serviceID)
  //         .then(() => {
  //           this.refreshVehicleIssues(this.vehicle_id);
  //           console.log(`SERVICE DETAILS`);
  //           console.log(this.service_details);
  //         });
  //
  //   }
  //
  //   // Laod serviceTypes for the Drop Down Selection
  //   if (this.$store.state.service.serviceType.currentIndex == -1) {
  //     this.$store
  //         .dispatch("service/serviceType/loadAllServiceTypes")
  //         .then(() => {
  //           console.log(this.$store.state.service.serviceType.servicetype_data);
  //         });
  //   }
  // }

};
</script>

<style scoped>
.tabs {
  font-size: 12px;
}

.issuesCard {
  padding: 20px 10px;
}

.avatarText {
  font-size: 10px;
}

.v-list__tile {
  height: 20px;
}

.listRows {
  height: 20px;
}
</style>
