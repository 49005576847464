<template lang="pug">
  div(:style="getStyle"  @mouseover.prevent="menuVisible=true" @mouseleave.prevent="menuVisible=false")
    field-component-menu(v-show="menuVisible && !$store.state.workflow.formBuilder.disableAllCompoents" style="float:right;" @onClick="onClick")
    .rowContainer
      .dragHolder()
        v-icon(:size="20" color="white" style="margin:0px; padding:0px") unfold_more
      component( :is="getComponent(item.name)" v-bind="{schema:item}")



</template>
<script>
import FieldComponentMenu from "@/modules/workflow/formBuilder/components/ui/FieldComponentMenu";
import TextFieldRenderer from "@/modules/workflow/formBuilder/components/renderTypes/TextFieldRenderer";
import CheckboxRenderer from "@/modules/workflow/formBuilder/components/renderTypes/CheckboxRenderer";
import HeaderRenderer from "@/modules/workflow/formBuilder/components/renderTypes/HeaderRenderer";
import TextAreaRenderer from "@/modules/workflow/formBuilder/components/renderTypes/TextAreaRenderer";
import DateRenderer from "@/modules/workflow/formBuilder/components/renderTypes/DateRenderer";
import TimeRenderer from "@/modules/workflow/formBuilder/components/renderTypes/TimeRenderer";
import RadioRenderer from "@/modules/workflow/formBuilder/components/renderTypes/RadioRenderer";
import SelectRenderer from "@/modules/workflow/formBuilder/components/renderTypes/SelectRenderer";
import TableRenderer from "@/modules/workflow/formBuilder/components/renderTypes/TableRenderer";
import AttachRenderer from "@/modules/workflow/formBuilder/components/renderTypes/AttachRenderer";
import SignatureRenderer from "@/modules/workflow/formBuilder/components/renderTypes/SignatureRenderer";
import MapRenderer from "@/modules/workflow/formBuilder/components/renderTypes/MapRenderer";
import SwitchRenderer from "@/modules/workflow/formBuilder/components/renderTypes/SwitchRenderer";
import JahaziRenderer from "@/modules/workflow/formBuilder/components/renderTypes/JahaziRenderer";
import ImageRenderer from "@/modules/workflow/formBuilder/components/renderTypes/ImageRenderer";
import BlankRenderer from "@/modules/workflow/formBuilder/components/renderTypes/BlankRenderer";
import ReadOnlyRenderer from "@/modules/workflow/formBuilder/components/renderTypes/ReadOnlyRenderer.vue";
import DateTimeRenderer from "@/modules/workflow/formBuilder/components/renderTypes/DateTimeRenderer.vue";
import ApprovalRenderer from "@/modules/workflow/formBuilder/components/renderTypes/ApprovalRenderer.vue";
import DrawRenderer from "@/modules/workflow/formBuilder/components/renderTypes/DrawRenderer.vue";
export default {
  components: {
    FieldComponentMenu,
    TextFieldRenderer,
    CheckboxRenderer,
    HeaderRenderer,
    TextAreaRenderer,
    DateRenderer,
    TimeRenderer,
    RadioRenderer,
    SelectRenderer,
    TableRenderer,
    AttachRenderer,
    ImageRenderer,
    SignatureRenderer,
    MapRenderer,
    SwitchRenderer,
    BlankRenderer,
    JahaziRenderer,
    ReadOnlyRenderer,
    DateTimeRenderer,
    ApprovalRenderer,
    DrawRenderer,
  },
  props: {
    item: Object
  },
  data: () => ({
    menuVisible: false
  }),

  computed: {
    getStyle: function() {
      let theStyle = {
        width: " 100%",
        "border-radius": "5px",
        padding: "5px 8px",
        margin: "10px 0px 10px 0px"
      };

      if (
        this.$store.getters["workflow/formBuilder/getCurrentID"] == this.item.id
      ) {
        theStyle["border-color"] = "#CCC";
        theStyle["border-style"] = "solid";
        theStyle["border-width"] = "8px";
        theStyle["background-color"] = "#FAFAFA";
      } else {
        theStyle["border-color"] = "#CCC";
        theStyle["border-style"] = "dotted";
        theStyle["border-width"] = "1px";
        theStyle["background-color"] = "#FFF";
      }

      return theStyle;
    }
  },

  methods: {
    // call the editor
    onClick: function(value) {
      switch (value) {
        case "edit":
          this.$store.commit("workflow/formBuilder/editFormByID", this.item.id);
          break;
        case "file_copy":
          this.$store.commit("workflow/formBuilder/copyFormByID", this.item.id);
          break;
        case "delete":
          this.$store.commit(
            "workflow/formBuilder/deleteFormByID",
            this.item.id
          );
          break;
      }
    },

    getComponent: function(componentName) {
      // Ensure the Renderer is Registered by appending the String `Renderer` to the compent name
      if(componentName === 'RouteID' || componentName === 'RouteName' || componentName === 'PlaceID' || componentName === 'PlaceName'){
        return "BlankRenderer"
      }else{
        return `${componentName}Renderer`;
      }

    }
  },

  mounted() {
    // console.log(this.getComponent('ChecklistProperties'))
  }
};
</script>
<style scoped lang="stylus">
.rowContainer{
  display flex;
  align-items center;

}

.dragHolder{
  display flex
  flex-direction column
  width 20px
  height 20px;
  background-color #cccccc
  border-radius 50%
  margin-right 10px;
}

.dragHolder:hover{
  cursor grab
}
</style>
