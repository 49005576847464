<template lang="pug">
  v-combobox(
    v-model="computedValue"
    :label="schema.label"
    :hint="schema.hint"
    :persistent-hint="schema.hint != null"
    :items="items"
    :item-text="getText"
    item-value="id"
    v-bind="getProperties"
  )
    template(v-if="schema.multiple" v-slot:selection="{ item, parent, selected }")
      v-chip(small close @click:close="removeItem(item)")
        | {{item}}
</template>
<script>
//v-bind="bindValues"
import axios from "axios";
import SETTINGS from "@/modules/workflow/formBuilder/store/formSettings";

export default {
  props: {
    schema: Object
  },
  data: () => ({
    items: []
  }),
  computed: {
    computedValue: {
      get: function () {
        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id);
      },
      set: function (theValue) {
        console.log(theValue);
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    },

    theItems: {
      get: function () {
        return this.initItems();
      },
      set: function (value) {
        console.log(value);
      }
    },

    getProperties: function () {
      let obj = {};
      if (this.schema.multiple == true) {
        obj['multiple'] = '';
        obj['small-chips'] = '';
      }
      return obj;
    },
  },

  methods: {

    getText: function (value) {
      if (this.schema.type === "Simple") {
        return value;
      }

      let tempString = "";
      this.schema.item_text.forEach(item => {
        tempString += value[item] + " ";
      });
      return tempString;
    },


    initItems: function () {
      console.log(`--->${this.schema.type}`);
      this.$refs.multiple = "";
      console.log(this.$refs.multiple);
      this.items = [];
      if (this.schema.type == "Simple") {
        console.log(`===> to put options for schema`);
        this.items = this.schema.options;
      }


      if (this.schema.type == "API") {
        axios.get(this.schema.api).then(response => {
          this.items = response.data;
          console.log(response.data);
        });
      }

      return this.items;
    },

    removeItem: function (item) {
      let array = this._.clone(this.computedValue);
      if (this.schema.type == "Simple") {
        // for Simple
        const index = array.indexOf(item);
        if (index > -1) {
          array.splice(index, 1);
          this.computedValue = array;
        }
      } else {

        // for APIS
        const index = array.findIndex(e => e.id == item.id);
        if (index > -1) {
          array.splice(index, 1);
          this.computedValue = array;
        }
      }

    }

  },

  mounted() {
    this.initItems();
  }
};
</script>
<style scoped lang="stylus"></style>
