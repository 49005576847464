import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const FUEL_API = "/fuel";

// ----------------------- STATE ------------------------- //
const state = {
    fuel_data: [],
    currentIndex: -1,
    metrics: null,
    selectedFuels: [],
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getFuel: state => getField(state.fuel_data[state.currentIndex]),

    getSelectedFuels: state => { return state.selectedFuels},

    getCurrentFuel: state => {
        return state.fuel_data[state.currentIndex];
    },

    getMetrics: state => {
        return state.metrics;
    },

    getFuelByVehicleID: state => vehicleID => {
        return state.fuel_data.filter(element => element.vehicle_id == vehicleID)
    },
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateFuel(state, payload) {
        updateField(state.fuel_data[state.currentIndex], payload);
    },

    setSelectedFuels(state, payload) {
        state.selectedFuels = payload;
    },
    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.fuel_data.findIndex(fuel => fuel.id == theID);
    },

    loadAllFuels(state, payload) {
        state.fuel_data = payload.data;
        state.currentIndex = 0;
    },

    loadMetrics(state, payload) {
        state.metrics = payload.data;
    },

    addFuel(state, payload) {
        state.fuel_data.push(payload.data);
        state.currentIndex = state.fuel_data.length - 1;
    },

    saveFuel(state, payload) {
        state.fuel_data[state.currentIndex] = payload.data;
    },

    fetchFuel(state, payload) {
        if (state.currentIndex == -1) {
            state.fuel_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.fuel_data[state.currentIndex] = payload.data;
        }
    },

    deleteFuel(state, payload) {
        state.currentIndex = state.fuel_data.findIndex(
            fuel => fuel.id == payload.data.id
        );
        state.fuel_data.splice(state.currentIndex, 1);
    },
    deleteImage(state, payload) {
        let index = state.fuel_data[state.currentIndex].photos_id.findIndex(
            item => item === payload
        );

        state.fuel_data[state.currentIndex].photos_id.splice(index, 1);
        console.log(state.fuel_data[state.currentIndex].photos_id);
    },

    deleteDocument(state, payload) {
        let index = state.fuel_data[state.currentIndex].documents_id.findIndex(
            item => item === payload
        );
        state.fuel_data[state.currentIndex].documents_id.splice(index, 1);
    },

    deleteSelectedFuels(state,payload){
        payload.data.forEach(element => {
            let theIndex = state.fuel_data.findIndex(
                (fuel) => fuel.id == element
            );
            state.fuel_data.splice(theIndex, 1);

        })
        state.selectedFuels = [];
    },

    cleanForSave(state) {
        state.fuel_data[state.currentIndex].volume = parseFloat(
            state.fuel_data[state.currentIndex].volume
        );
        state.fuel_data[state.currentIndex].price = parseFloat(
            state.fuel_data[state.currentIndex].price
        );
        state.fuel_data[state.currentIndex].odometer = parseInt(
            state.fuel_data[state.currentIndex].odometer
        );

        state.fuel_data[state.currentIndex].vehicle = null;
        state.fuel_data[state.currentIndex].reported_by = null;
        state.fuel_data[state.currentIndex].fuel_type = null;
        state.fuel_data[state.currentIndex].merchant = null;
        state.fuel_data[state.currentIndex].account = null;
    },
    next(state){
        if(state.currentIndex == state.fuel_data.length-1){
            state.currentIndex = 0;
        }else{
            state.currentIndex += 1;
        }
    },

    previous(state){
        if(state.currentIndex == 0){
            state.currentIndex = state.fuel_data.length-1;
        }else{
            state.currentIndex -= 1;
        }
    },
    reset(state) {
        state.fuel_data = []
        state.currentIndex = -1
        state.selectedFuels = []
        state.metrics = null
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllFuels({commit}) {
        try {
            commit("loadAllFuels", await Repository.get(`${FUEL_API}`));
        } catch (error) {
            console.log(error);
        }
        try {
            commit("loadMetrics", await Repository.get(`${FUEL_API}/metrics`));
        } catch (error) {
            console.log(error);
        }
    },

    async addFuel({commit}) {
        await Repository.get(`${FUEL_API}/new`)
            .then(response => {
                commit("addFuel", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveFuel({state, commit}) {
        try {
            commit("cleanForSave");
            console.log(state.fuel_data[state.currentIndex]);
            commit(
                "saveFuel",
                await Repository.post(
                    `${FUEL_API}`,
                    state.fuel_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchFuel({commit}, payloadID) {
        try {
            commit("fetchFuel", await Repository.get(`${FUEL_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteFuel({commit}, payloadID) {
        try {
            commit("deleteFuel", await Repository.delete(`${FUEL_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteSelectedFuels({ state,commit }) {
        try {
            let idString = ''
            state.selectedFuels.forEach(element => {
                idString += "&id="+element.id
            })
            commit(
                "deleteSelectedFuels",
                await Repository.delete(`${FUEL_API}/deleteSelected?${idString}`)

            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchFuels({commit}, searchTerm) {
        try {
            commit(
                "loadAllFuels",
                await Repository.get(`${FUEL_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
