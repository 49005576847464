<template lang="pug">
  v-layout(row wrap justify-start)
    v-flex.px-3.py-2( v-for="(item,index) in schemaData" :key="index" v-bind="item.is_full_width?fullWidth:autoWidth"  )
      computed(:is="getComponent(item.name)" v-bind="{schema:item, dataValue:form_data[item.id], index:index}"  @onChange='onChange')
</template>

<script>
import TextFieldFillForm from "@/modules/inspections/components/formDesigner/fill/TextFieldFillForm";
import CheckboxFillForm from "@/modules/inspections/components/formDesigner/fill/CheckboxFillForm";
import HeaderFillForm from "@/modules/inspections/components/formDesigner/fill/HeaderFillForm";
import TextAreaFillForm from "@/modules/inspections/components/formDesigner/fill/TextAreaFillForm";
import DateFillForm from "@/modules/inspections/components/formDesigner/fill/DateFillForm";
import TimeFillForm from "@/modules/inspections/components/formDesigner/fill/TimeFillForm";
import DateTimeFillForm from "@/modules/inspections/components/formDesigner/fill/DateTimeFillForm";
import RadioFillForm from "@/modules/inspections/components/formDesigner/fill/RadioFillForm";
import SelectFillForm from "@/modules/inspections/components/formDesigner/fill/SelectFillForm";
import TableFillForm from "@/modules/inspections/components/formDesigner/fill/TableFillForm";
import AttachFillForm from "@/modules/inspections/components/formDesigner/fill/AttachFillForm";
import ImageFillForm from "@/modules/inspections/components/formDesigner/fill/ImageFillForm";
import SignatureFillForm from "@/modules/inspections/components/formDesigner/fill/SignatureFillForm";
import MapFillForm from "@/modules/inspections/components/formDesigner/fill/MapFillForm";
import SwitchFillForm from "@/modules/inspections/components/formDesigner/fill/SwitchFillForm";
import BlankFillForm from "@/modules/inspections/components/formDesigner/fill/BlankFillForm";
import DrawFillForm from "@/modules/inspections/components/formDesigner/fill/DrawFillForm";

export default {
  props: ['value'],
  components: {
    TextFieldFillForm,
    CheckboxFillForm,
    HeaderFillForm,
    TextAreaFillForm,
    DateFillForm,
    TimeFillForm,
    DateTimeFillForm,
    RadioFillForm,
    SelectFillForm,
    TableFillForm,
    AttachFillForm,
    ImageFillForm,
    SignatureFillForm,
    MapFillForm,
    SwitchFillForm,
    BlankFillForm,
    DrawFillForm

  },

  data: () => ({

    schemaData: [],
    autoWidth: {["xs12 sm12 md6 lg6 xl4"]: ""},
    fullWidth: {["xs12"]: ""},

  }),

  mounted() {
    this.schemaData = this.getRecordSchema();
  },

  methods: {
    getRecordSchema: function () {
      return this.$store.getters["inspection/inspectionForm/getCurrentInspectionForm"].data[this.$store.getters["inspection/getCurrentInspection"].inspection_form_version - 1].schema;
    },
    onChange: function (item) {
      console.log("===========++ZE ITEM ==============")

      let cloneObj = JSON.parse(JSON.stringify(this.form_data))
      console.log(item)
      cloneObj[item.id] = item.value

      this.$store.commit("inspection/updateInspection", {path: "data", value: cloneObj})
      this.$store.commit("inspection/updateInspection", {path: "submitted_on", value: new Date().toISOString()})

    },


    getComponent: function (propertyName) {
      return `${propertyName}FillForm`;
    },


  },


  computed: {
    form_data: function () {
      let originalData = this._.clone(this.$store.getters["inspection/getCurrentInspection"].data);


      let newData = {};

      if (originalData == null) {
        // for a new form
        this.schemaData.forEach(item => {
          newData[item.id] = item.default
        });
      } else {
        // loading an old form and setting it up to fill in missing components such as '$_header'
        this.schemaData.forEach(item => {

          // check if key exists
          if (item.id in originalData) {
            newData[item.id] = originalData[item.id]
          } else {
            newData[item.id] = item.default
          }

        })
      }


      return newData;
    },


  }


}
</script>

<style lang="stylus" scoped>

</style>
