<template lang="pug">
  div(v-if="$store.state.settings.measurementUnitSettings.currentIndex > -1")
    .theForm.pb-2
      v-btn(small color="primary" outlined @click="cancel") Cancel
      v-btn(small color="primary" @click="save") Save
    v-card
      v-container
        v-layout(row wrap)
          v-flex(xs12)
            v-text-field(v-model="name"  hint="Enter name of the Meaurement Unit" :persistent-hint="true"  label="Name*"  required)
          v-flex(xs12)
            v-text-field(v-model="symbol"  hint="Enter symbol of the Meaurement Unit" :persistent-hint="true"  label="Symbol*"  required)
          v-flex(xs12)
            v-textarea(v-model="description"  hint="Enter Description" :persistent-hint="true"  label="Description"  required)
   
</template>
<script>
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "settings/measurementUnitSettings/getMeasurementUnit",
  mutationType: "settings/measurementUnitSettings/updateMeasurementUnit"
});

export default {
  computed: {
    ...mapFields({
      name: "name",
      symbol: "symbol",
      description: "description"
    })
  },

  methods: {
    cancel: function() {
      this.$router.push({ name: "measurementUnitSettings" }).catch(()=>{});
    },

    save: function() {
      this.$store.dispatch(
        "settings/measurementUnitSettings/saveMeasurementUnit"
      );
      this.$router.push({ name: "measurementUnitSettings" }).catch(()=>{});
    }
  },

  mounted() {
    if (this.$store.state.settings.measurementUnitSettings.currentIndex == -1) {
      this.$store.dispatch(
        "settings/measurementUnitSettings/fetchMeasurementUnit",
        this.$route.params.measurementUnitID
      );
    }
  }
};
</script>
<style scoped lang="stylus"></style>
