<template lang="pug">
div.white.pt-2
  v-tabs.tabs(left='', height='32', slider-color='primary', v-model='active')

    

    v-tab.tabs  Vehicle List 
    v-tab-item
      vehicle-list-layout

    v-tab.tabs  Vehicle Assignments 
    v-tab-item
      vehicle-assignments

       
    v-tab.tabs Odometer History
    v-tab-item
      vehicle-odometer-layout


    v-tab.tabs Expenses History
    v-tab-item
      expense-layout


    v-tab.tabs  Manage GPS Devices 
    v-tab-item
      devices-list-layout



      
    
</template>

<script>
import VehicleListLayout from "@/modules/vehicles/layouts/VehicleListLayout";
import VehicleAssignments from "@/modules/vehicles/layouts/VehicleAssignments";
import DevicesListLayout from "@/modules/vehicles/layouts/DevicesListLayout";
import VehicleOdometerLayout from "@/modules/vehicles/layouts/VehicleOdometerLayout";
import ExpenseLayout from "@/modules/expense/layouts/ExpenseLayout";
export default {
  components: {
    VehicleListLayout,
    VehicleAssignments,
    DevicesListLayout,
    VehicleOdometerLayout,
    ExpenseLayout
  },

  computed: {
    active: {
      get: function() {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          tabIndex = tabIndex > 4 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function(value) {
        this.$route.params.tabID = value;
      }
    }
  }
};
</script>

<style scoped></style>
