<template lang="pug">
  #blankcontent()
    .panelTitle() User Records
    v-divider.mx-2()
    bar-chart(v-if="isLoaded" :style="{height:`${clientHeight-40}px`, width: `100%`}" :chartdata="getDataSets" :options="chartOption")

</template>
<script>
import BarChart from "@/modules/global/components/charts/BarChart";
import axios from "axios";

export default {
  components: {
    BarChart
  },
  props: {
    clientHeight: Number
  },
  data: () => ({
    theLabels:[],
    theData:[],
    isLoaded:false,
    chartOption: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize:1
            }
            // gridLines: {
            //   color: "rgba(0, 0, 0, 0)"
            // }
          }
        ],
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ]
      },
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 20,
          bottom: 0
        }
      }
    }
  }),
  mounted() {
    axios.get(`/api/inspectionForm/dashboard/user/${this.$route.params.inspectionID}`).then(response => {

      response.data.forEach(element =>{
        this.theLabels.push(element.user);
        this.theData.push(element.count);
      })
      this.isLoaded = true;
    });
  },
  computed: {
    getDataSets: function() {
      let obj = {
        labels: this.theLabels,
        datasets: [
          {
            label: "Records",
            data: this.theData,
            // borderWidth: 2
          }
        ]
      };
      obj.datasets[0].backgroundColor = this.$vuetify.theme.themes.light.primary;
      obj.datasets[0].barThickness = 20;
      // obj.datasets[0].borderColor = this.$vuetify.theme.themes.light.primary;
      return obj;
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight bold
  padding: 5px 30px;
  height:30px;
  display: flex;
  width: 100%
  justify-content: space-between;
}

.lineChartPanel {
  height: calc(100% - 45px);
}
</style>
