import { render, staticRenderFns } from "./ExpensesPage.vue?vue&type=template&id=e5604894&scoped=true&lang=pug"
import script from "./ExpensesPage.vue?vue&type=script&lang=js"
export * from "./ExpensesPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5604894",
  null
  
)

export default component.exports