import Vue from "vue";
import Router from "vue-router";
import { MAIN_ROUTES } from "@/router/MainRoutes.js";


Vue.use(Router);

const Main = () => import(/* webpackChunkName: "main" */ '@/modules/app/layouts/Main')
const Login = () => import(/* webpackChunkName: "login" */ '@/modules/login/layouts/Login')
const ResetPassword = () => import(/* webpackChunkName: "password-reset" */ '@/modules/login/components/forms/PasswordReset')

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "login",
      component: Login
    },
    {
      path: "/resetpassword/:id",
      name: "resetPassword",
      component: ResetPassword
    },

    {
      path: "/main", //UserID of the User
      name: "main",
      component: Main,
      children: MAIN_ROUTES
    }
  ]
});

// save path to local storage
// called by LoginForm.vue when mounted
router.afterEach(to => {
  if (
    to.path != "/" &&
    to.name !== "resetPassword" &&
    to.path.substr(0, 5) === "/main"
  ) {
    // console.log(`saved localstorage:---${to.path}---`);
    localStorage.setItem("route", to.path);
  }
});

export default router;
