<template lang="pug">
  div()
    v-divider
    v-container.py-2.px-0(fluid)
      v-layout.my-2.mr-4.ml-2(row, wrap)
        h2.mx-4 {{currentItem.name}}
        v-spacer
        .d-flex.align-center()
          v-btn.ma-2(small, color='primary', dark, @click='sellItem')
            v-icon() remove
            | {{currentItem.name}} Out
          v-btn.ma-2(small, color='primary', dark, @click='purchaseItem')
            v-icon() add
            | {{currentItem.name}} In
      v-divider
      v-layout.px-3(row, wrap, align-center)
        search-filter.mt-4.ml-4.fill-width(v-model="searchFilter" style="max-width:500px" )
    v-divider
    .grey.lighten-4.py-3
    v-divider
    item-activity-table(:search="searchFilter" )
    v-divider

</template>

<script>
import ItemActivityTable from "@/modules/inventory/components/tables/ItemActivityTable";

import SearchFilter from "@/modules/global/components/table/SearchFilter";

export default {
  components: {
    ItemActivityTable,

    SearchFilter
  },



  data: () => ({



    vehiclesFilter: "",
    searchFilter: null,


  }),


  computed: {
    currentItem: function () {
      return this.$store.getters["inventory/item/getCurrentItem"];
    },



  },

  methods: {





    purchaseItem: function () {
      this.$store.commit("inventory/setMode", "add")
      this.$store.commit("inventory/item/setCurrentIndexByID", this.$route.params.itemID)
      this.$store.dispatch("inventory/addInventory", this.$route.params.itemID).then(() => {
        let currentObject = this.$store.getters["inventory/getCurrentInventory"];
        this.$router.push({
          name: "inventoryForm",
          params: {itemID: currentObject.id}
        }).catch(()=>{});
      });
    },

    sellItem: function () {
      this.$store.commit("inventory/setMode", "subtract")
      this.$store.commit("inventory/item/setCurrentIndexByID", this.$route.params.itemID)
      this.$store.dispatch("inventory/addInventory", this.$route.params.itemID).then(() => {
        let currentObject = this.$store.getters["inventory/getCurrentInventory"];
        this.$router.push({
          name: "inventoryForm",
          params: {itemID: currentObject.id}
        }).catch(()=>{});
      });
    },


  },

  mounted() {
    // console.log(this.$vuetify.theme.primary);
    this.toggle;
  }
}
</script>

<style scoped>

</style>