<template lang="pug">
  v-container(:class="index==selectedIndex?'grey lighten-3':''" style="border-radius:8px")
    v-row()
      v-col(cols="6")
        .d-flex.flex-row.align-center
          v-icon.tableDragHolder() menu
          v-text-field.ml-4(v-model="item.label" :label="`Column Name ${index}:${selectedIndex}`" hint="Enter name of Column" @change="onChange" @focus="onSelect" )
      v-col(cols="6")
        .d-flex.flex-row.align-center
          v-select.mr-4(v-model="item.type" :items="selectedItems" @change="onSelect" @focus="onSelect")
          v-btn.ml-2(icon small @click="onSelect")
            v-icon() edit
          v-btn.ml-2(icon small @click="onDeleteProperties")
            v-icon() delete

</template>
<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
export default {
  props: {
    item: Object,
    selectedItems: Array,
    index:Number,
    selectedIndex:Number,
  },




  components: {
    MoreDropDownMenu
  },

  data: () => ({
    name: "hello world",
    theObject: null,
    selected: null,
    selectedType: null,


    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" }
    ]
  }),

  methods: {
    onClick: function(value) {
      switch (value.action) {
        case "edit":
          console.log("edit");
          break;
        case "delete":
          this.$emit("delete", this.item);
          break;
      }
    },

    onChange: function(){
      this.item.id=this._.snakeCase(this.item.label);
    },





    onEditProperties: function(){
      this.$emit("showProperties",true);
    },

    onDeleteProperties:function(){
      this.$emit("delete", this.item.id);
    },

    onSelect:function(){
      this.$emit("select", this.item.id);
    }
  },


};
</script>
<style scoped lang="stylus">
.tableDragHolder:hover{
  cursor grab
}

</style>
