import ItemDetailsForm from "@/modules/inventory/items/components/forms/ItemDetailsForm";
import PurchaseOrderDetailsForm from "@/modules/inventory/purchase_orders/forms/PurchaseOrderDetailsForm";
import InventoryOverviewLayout from "@/modules/inventory/layouts/InventoryOverviewLayout";
import InventoryForm from "@/modules/inventory/components/forms/InventoryForm";

export const INVENTORY_ROUTES = [
    {
        path: "itemDetailsForm",
        name: "itemDetailsForm",
        component: ItemDetailsForm
    },
    {
        path: "inventoryOverviewLayout/:tabID?",
        name: "inventoryOverviewLayout",
        component: InventoryOverviewLayout
    },

    {
        path: "purchaseOrderDetailsForm",
        name: "purchaseOrderDetailsForm",
        component: PurchaseOrderDetailsForm
    },
    {
        path: "inventoryForm",
        name: "inventoryForm",
        component: InventoryForm
    },
];
