<template lang="pug">
  .commentBtn(@click="$emit('showCommentInput')")
    v-icon() message
    .caption() {{value != null ? "+"+value.length :"&nbsp;"}}

</template>

<script>
export default {
  name: "AddComment",
  props: ["value"],

}
</script>

<style scoped>
.commentBtn{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-color: #e5e5e5;
  padding: 3px;
  border-radius: 5px;
  opacity: 0.2;
}
.commentBtn:hover{
  background-color: #f3f3f3;
  opacity: 1.0;
}
</style>