<template lang="pug">
  div
    .title.pa-3(:class="['grey lighten-4']") Contact Information
    v-divider
    .px-3
      .theform
        v-form(v-if="contact")
          v-flex(md12)
            v-container(grid-list-sm)
              v-layout(row, wrap, justify-end)
                v-flex(md9, xs12)
                  v-text-field(v-model="phone", name='input-1-3', prepend-icon='phone', label='Phone', single-line)
                v-flex(md3, xs12)
                  v-select(v-model="phone_type", prepend-icon='smartphone', :items='phone_types', label='Phone')
                v-flex(md12, xs12)
                  v-text-field(v-model="street_address" prepend-icon='place',  label='Street Location')
                v-flex(md12, xs12, xl4)
                  v-text-field(v-model='address', label='Postal Address')
                v-flex(md4, xs12, xl4)
                  v-text-field(v-model='postal_code',label='Postal Code')
                v-flex(md8, xs12, xl4)
                  v-text-field(v-model='city', label='City')
                v-flex(md12, xs12)
                  v-select(v-model='country', :items='countryItems', label='Country',)

</template>

<script>
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "contacts/getContact",
  mutationType: "contacts/updateContact"
});
export default {
  data: () => ({
    valid: false,
    phone_types: ["Work", "Home", "Main", "Other"],
    countryItems: ["Kenya", "Tanzania", "Uganda", "Rwanda"],
    requiredRules: [v => !!v || "Name is required"]
  }),
  computed: {
    ...mapFields({
      phone: "phone",
      phone_type: "phone_type",
      street_address: "street_address",
      address: "address",
      city: "city",
      postal_code: "postal_code",
      country: "country"
    }),

    contact: {
      get() {
        return this.$store.getters["contacts/getCurrentContact"];
      }
    }
  }
};
</script>
<style></style>
