

export const MESSAGE_ROUTES = [
    // {
    //     path: "serviceTypeForm",
    //     name: "serviceTypeForm",
    //     component: ServiceTypeForm
    // },

];
