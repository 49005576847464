<template lang="pug">
  div(style="width:100%")
    component( :is="getComponent(schema.type)" v-model="computedValue" :label="schema.description")



</template>
<script>

import SETTINGS from "@/modules/workflow/formBuilder/store/formSettings";
import ContactAutoComplete from "@/modules/global/components/forms/ContactAutoComplete";
import CustomerAutoComplete from "@/modules/global/components/forms/CustomerAutoComplete";
import MerchantAutoComplete from "@/modules/global/components/forms/MerchantAutoComplete";
import VehicleAutoComplete from "@/modules/global/components/forms/VehicleAutoComplete";
import VehiclesAutoComplete from "@/modules/global/components/forms/VehiclesAutoComplete";
import CustomersAutoComplete from "@/modules/global/components/forms/CustomersAutoComplete";
import MerchantsAutoComplete from "@/modules/global/components/forms/MerchantsAutoComplete";
import ContactsAutoComplete from "@/modules/global/components/forms/ContactsAutoComplete";
import PlaceAutoComplete from "@/modules/global/components/forms/PlaceAutoComplete";
import PlacesAutoComplete from "@/modules/global/components/forms/PlacesAutoComplete";
import BlankRenderer from "@/modules/workflow/formBuilder/components/renderTypes/BlankRenderer";
import GroupAutoComplete from "@/modules/global/components/forms/GroupAutoComplete.vue";

export default {
  components:{
    ContactAutoComplete,
    CustomerAutoComplete,
    MerchantAutoComplete,
    VehicleAutoComplete,
    CustomersAutoComplete,
    MerchantsAutoComplete,
    ContactsAutoComplete,
    VehiclesAutoComplete,
    PlaceAutoComplete,
    PlacesAutoComplete,
    BlankRenderer,
    GroupAutoComplete,
  },
  props: {
    schema: Object
  },
  data: ()=>({
    dialog:false
  }),
  computed:{



    computedValue: {
      get: function() {
        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id) || this.schema.default;
      },

      set: function(theValue) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }
  },

  methods:{
    getComponent: function(jahaziType) {
      let theJahaziType = 'BlankRenderer';
      console.log(this.schema.multiple)
      console.log(jahaziType)
      if(this.schema.multiple){
        switch (jahaziType){
          case 'contact': theJahaziType = "ContactsAutoComplete"; break;
          case 'vehicle': theJahaziType = "VehiclesAutoComplete"; break;
          case 'customer': theJahaziType = "CustomersAutoComplete"; break;
          case 'merchant': theJahaziType = "MerchantsAutoComplete"; break;
          case 'place': theJahaziType = "PlacesAutoComplete"; break;
          case 'group': theJahaziType = "GroupAutoComplete"; break;
        }
      }else{
        switch (jahaziType){
          case 'contact': theJahaziType = "ContactAutoComplete"; break;
          case 'vehicle': theJahaziType = "VehicleAutoComplete"; break;
          case 'customer': theJahaziType = "CustomerAutoComplete"; break;
          case 'merchant': theJahaziType = "MerchantAutoComplete"; break;
          case 'place': theJahaziType = "PlaceAutoComplete"; break;
          case 'group': theJahaziType = "GroupAutoComplete"; break;
        }
      }


      return theJahaziType;

    }
  }
};
</script>
<style scoped lang="stylus">

</style>
