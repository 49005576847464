<template lang="pug">
div
  div(v-if="this.$store.state.contacts.currentIndex > -1")
    router-view
    //- Todo Have a Loader if stuff is not yet in
  div(v-else)
    v-layout.pt-5(fill-height align-center justify-center)
      v-progress-circular(:size="70" :width="7" color="primary" indeterminate)

</template>
<script>
export default {
  // This page acts as the controller for the contact
  mounted() {
    if (this.$store.state.contacts.currentIndex == -1) {
      //load from server
      this.$store.dispatch(
        "contacts/fetchContact",
        this.$route.params.contactID
      );
    }
  }
};
</script>
<style scoped lang="stylus"></style>
