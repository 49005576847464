<template lang="pug">
.reportPanelStyle
  v-toolbar(color='white', flat, dense)
    v-btn(icon, light)
      v-icon(color='grey darken-1') {{icon}}
    v-toolbar-title.grey--text.text--darken-2.subheading.ml-0 {{label}}
    v-spacer
    v-btn(icon, light)
      v-icon(color='grey darken-2') search
  v-list(two-line, dense)
    template(v-for='(item, index) in items')
      v-list-item(:key='index', ripple, @click="$emit('selectitem',item)")
        v-list-item-content
          v-list-item-title(v-html='item.name')
          v-list-item-subtitle(v-html='item.desc')
</template>
<script>
export default {
  props: {
    items: Array,
    label: String,
    icon: String
  },
  data: () => ({})
};
</script>
<style scoped>
.reportPanelStyle {
}
</style>
