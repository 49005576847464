<template lang="pug">
  v-layout(row wrap justify-start)
      v-flex.px-3(v-if="canRead(item)"  v-for="(item,index) in schemaData" :key="index" v-bind="item.is_full_width?fullWidth:autoWidth"  )
        computed(:is="getComponent(item.name)" v-bind="{schema:item, dataValue:form_data[item.id], index:index}"  @onChange='onChange' :disabled="isDisabled(item)")
</template>

<script>
import TextFieldFillForm from "@/modules/workflow/inspections/components/forms/TextFieldFillForm";
import CheckboxFillForm from "@/modules/workflow/inspections/components/forms/CheckboxFillForm";
import HeaderFillForm from "@/modules/workflow/inspections/components/forms/HeaderFillForm";
import TextAreaFillForm from "@/modules/workflow/inspections/components/forms/TextAreaFillForm";
import DateFillForm from "@/modules/workflow/inspections/components/forms/DateFillForm";
import TimeFillForm from "@/modules/workflow/inspections/components/forms/TimeFillForm";
import RadioFillForm from "@/modules/workflow/inspections/components/forms/RadioFillForm";
import SelectFillForm from "@/modules/workflow/inspections/components/forms/SelectFillForm";
import TableFillForm from "@/modules/workflow/inspections/components/forms/TableFillForm";
import AttachFillForm from "@/modules/workflow/inspections/components/forms/AttachFillForm";
import ImageFillForm from "@/modules/workflow/inspections/components/forms/ImageFillForm";
import SignatureFillForm from "@/modules/workflow/inspections/components/forms/SignatureFillForm";
import MapFillForm from "@/modules/workflow/inspections/components/forms/MapFillForm";
import SwitchFillForm from "@/modules/workflow/inspections/components/forms/SwitchFillForm";
import JahaziFillForm from "@/modules/inspections/components/formDesigner/fill/JahaziFillForm";
import BlankFillForm from "@/modules/workflow/inspections/components/forms/BlankFillForm";
import ReadOnlyFillForm from "@/modules/workflow/inspections/components/forms/ReadOnlyFillForm.vue";
import DateTimeFillForm from "@/modules/workflow/inspections/components/forms/DateTimeFillForm.vue";

export default {
  props:['value'],
  components: {
    TextFieldFillForm,
    CheckboxFillForm,
    HeaderFillForm,
    TextAreaFillForm,
    DateFillForm,
    TimeFillForm,
    RadioFillForm,
    SelectFillForm,
    TableFillForm,
    AttachFillForm,
    ImageFillForm,
    SignatureFillForm,
    MapFillForm,
    SwitchFillForm,
    JahaziFillForm,
    BlankFillForm,
    ReadOnlyFillForm,
    DateTimeFillForm

  },

  data:()=>({

    schemaData: [],
    autoWidth: { ["xs12 sm12 md6 lg6 xl4"]: "" },
    fullWidth: { ["xs12"]: "" },

  }),

  mounted(){
    this.schemaData =this.getRecordSchema();
  },



  methods:{
    getRecordSchema: function(){
      return this.$store.getters["workflow/getCurrentWorkflow"].data[this.$store.getters["workflow/workflowRecord/getCurrentRecord"].workflow_template_version-1].schema;
    },
    onChange: function(item) {
      // console.log("===========++ZE ITEM ==============")

      let cloneObj = JSON.parse(JSON.stringify(this.form_data))
      // console.log(cloneObj)
      // console.log(item)
      cloneObj[item.id] = item.value

      this.$store.commit("workflow/workflowRecord/updateRecord",{path:"data",value:cloneObj})

    },



    getComponent: function(propertyName) {
      // Ensure the Renderer is Registered. the Registered Component has an appended `FillForm`
      if(propertyName === 'RouteID' || propertyName === 'RouteName' || propertyName === 'PlaceID' || propertyName === 'PlaceName'){
        return "BlankFillForm"
      }else{
        return `${propertyName}FillForm`;
      }

    },

    canRead: function(item){

      if(item.permissions == undefined || item.permissions.read.length == 0 ){
        return true;
      }

      // Get if Role has permision to read
      const searchIndex = item.permissions.read.findIndex(element => element == this.$store.state.profile.userInfo.role_id)
      if (searchIndex > -1) {
        return true;
      }else{
        return false;
      }
    },

    isDisabled: function(item){


      if(item.permissions == undefined || item.permissions.write.length == 0 ){
        return false;
      }

      // Get if Role has permsion to write
      const searchIndex = item.permissions.write.findIndex(element => element == this.$store.state.profile.userInfo.role_id)

      if (searchIndex > -1) {
        return false;
      }else{
        return true;
      }

    }

  },


  computed: {



    form_data: function () {
      let originalData = JSON.parse(JSON.stringify(this.$store.getters["workflow/workflowRecord/getCurrentRecord"].data))



      let newData = {};

      if(originalData == null){
        // for a new form
        this.schemaData.forEach(item => {
          newData[item.id] = item.default
        });
      }else{
        // loading an old form and setting it up to fill in missing components such as '$_header'
        this.schemaData.forEach(item => {

          // check if key exists
          if(item.id in originalData){
            newData[item.id] = originalData[item.id]
          }else{
            newData[item.id] = item.default
          }

        })
      }


      return newData;
    },



  }



}
</script>

<style scoped lang="stylus">

</style>
