<template lang="pug">
  v-data-table(
    :headers="headers"
    :search="search"
    :custom-filter="messageModuleFilter"
    :items="getData"
    item-key="id"
    :loading="isLoading"
    :single-select="false"
    show-select
    v-model="selectedItems"
  )
    template(v-slot:progress)
      v-progress-linear(v-slot="progress"  height="2" :indeterminate="isLoading")

    template(v-slot:item.created_at="{item}")
      div(style="min-width:220px") {{getDate(item.created_at)}}

    template(v-slot:item.to="{item}")
      div.caption(v-if="item.to.length") {{item.to[0]}}
      v-chip(v-if="item.contact_id" x-small ) {{item.contact_id}}
      v-chip(v-if="item.customer_id" x-small ) {{item.customer_id}}
      v-chip(v-if="item.merchant_id" x-small ) {{item.merchant_id}}
      //- v-chip(v-if="item.contact_id" x-small ) {{getContact(item.contact_id).full_name}}
      //- v-chip(v-if="item.customer_id"  x-small ) {{getContact(item.customer_id).full_name}}
      //- v-chip(v-if="item.merchant_id"  x-small ) {{getContact(item.merchant_id).full_name}}

    template( v-slot:item.action="{item}")
      v-row.mr-5(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import {searchMixin} from "@/mixin/searchListMixin";
import moment from "moment/moment";

export default {
  name: "MailTable",
  components: {
    MoreDropDownMenu,
  },
  mixins: [searchMixin],
  props: ["search","group"],
  data: () => ({
    isLoading: true,
    menuitems: [
      {
        title: "Send Notification",
        icon: "notifications",
        action: "notification"
      },
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" },
      { title: "View Full History", icon: "history", action: "history" }
    ],

  }),
  computed: {
    getData: function () {
      //return this.$store.getters["messages/getFilterByGroup"](this.group)
      return this.$store.state.mail.mail_data;
    },
    selectedItems: {
      get: function(){
        return this.$store.state.mail.selectedItems;
      },
      set: function(value){
        this.$store.commit("mail/setSelectedItems",value)
      }
    },
    isAdministrator: function(){
      return this.$store.state.profile.userInfo.is_administrator;
    },
    headers : function(){
      let headerList = [
        {
          text: "Sent To",
          value: "to"
        },
        {
          text: "Sent",
          value: "created_at"
        },
        { text: "Subject", value: "subject" },
        { text: "Action", value: "action", align: "right" }
      ];


      // if(this.isAdministrator){
      //   headerList.splice(2,0,{
      //     text: "To",
      //     value: "contacts_id"
      //   })
      // }
      return headerList;
    }
  },
  methods:{
    onClick: function(value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          console.log("edit")
          break;
        case "delete":
          console.log("delete")
          break;
        case "notification":
          console.log("notification");
          break;
        case "history":
          console.log("history");
          break;
      }
    },
    getContact(contact_id){
      let result =  this.$store.getters["contacts/getContactByID"](contact_id)
      if(typeof result == "undefined"){
        return null
      }else{
        return result;
      }
    },
    messageModuleFilter: function(value,search,item){
      let isFound = false;
      let patt = new RegExp(search.toLowerCase(), "i")

      // Search the Object
      isFound = this.searchObject(item,patt,isFound)

      return isFound;
    },
    getDate(theDate){
      return moment(theDate).format("h:mm a - ddd, Do MMM YYYY")
    },
  },
  mounted() {
    this.$store.dispatch(this.isAdministrator?"mail/loadAllMail":"messages/loadAllContactMail")
        .then(() => {
          this.isLoading = false;
        });
  }
}
</script>

<style scoped>

</style>