<template lang="pug">
  div(v-if="$store.state.profile")
    // Tabs Selector
    .white.pt-2
      v-tabs.tabs(v-model='active' left height='32')
        v-tab.tabs(tabindex='1' v-if="$store.getters['profile/getViewPermission']('contact')")  CONTACTS LIST
        v-tab-item
          contacts-list
        
        v-tab.tabs(tabindex='2', v-if='$store.state.profile.userInfo.is_administrator')
          | USER MANAGEMENT
        v-tab-item(v-if='$store.state.profile.userInfo.is_administrator')
          user-list
        v-tab.tabs(tabindex='3', v-if="$store.getters['profile/getViewPermission']('customer')")
          | CUSTOMERS LIST
        v-tab-item
          customers-list
        v-tab.tabs(tabindex='4', v-if="$store.getters['profile/getViewPermission']('merchant')")
          | SUPPLIERS LIST
        v-tab-item
          merchants-list
    v-divider
</template>

<script>
import ContactsList from "@/modules/contacts/layouts/ContactsList";
import MerchantsList from "@/modules/contacts/merchants/layouts/MerchantListLayout";
import UserList from "@/modules/contacts/layouts/UserList";
import CustomersList from "@/modules/contacts/customers/layouts/CustomersList";

export default {
  components: {
    ContactsList,
    UserList,
    MerchantsList,
    CustomersList
  },

  data: () => ({}),

  computed: {
    active: {
      get: function () {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          // tabIndex = tabIndex > 2 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function (value) {
        this.$route.params.tabID = value;
      }
    }
  },

  mounted() {
    let max = (this.$store.state.profile.userInfo.is_administrator) ? 3 : 2;
    if (this.$route.params.tabID != undefined) {
      let tabIndex = parseInt(this.$route.params.tabID);
      this.active = tabIndex > max || tabIndex < 0 ? 0 : tabIndex;
    } else {
      this.active = 0;
    }
  }
};
</script>

<style scoped>
.tabs {
  text-transform: none;
  font-size: small;
}
</style>
