<template lang="pug">
div
  v-layout.ma-2(justify-end)
    v-btn.mb-2.mx-2(small, color='primary', dark, @click='save') Save
  form-sub-header Report Settings
  v-progress-linear(v-if="isLoading" :indeterminate="isLoading")
  v-container(:key="counter")
    v-row.my-4()
      v-col(cols="12")
        .d-flex.align-center
          v-icon.mr-4(color="primary" :size="32") timer
          contacts-auto-complete.fill-width(v-model="dailyActivityReportContacts" :multiple="true" hint="Contacts to email Daily activity reports" label="Daily Activity Reports")
          v-btn.mx-4(v-if="dailyActivityReportContacts" outlined @click="dailyActivityReportContacts=null;counter+=1;") Clear

    v-row.my-4()
      v-col(cols="12")
        .d-flex.align-center
          v-icon.mr-4(color="primary" :size="32") local_shipping
          contacts-auto-complete.fill-width(v-model="monthlyVehicleReportContacts" :multiple="true" hint="Contacts to email Monthly Reports" label="Vehicle Monthly Reports")
          v-btn.mx-4(v-if="monthlyVehicleReportContacts" outlined @click="monthlyVehicleReportContacts=null;counter+=1;") Clear
    v-row.my-4()
      v-col(cols="12")
        .d-flex.align-center
          v-icon.mr-4(color="primary" :size="32") local_gas_station
          contacts-auto-complete.fill-width(v-model="monthlyFuelReportContacts" :multiple="true" hint="Contacts to email Monthly Fuel Reports" label="Monthly Fuel Reports")
          v-btn.mx-4(v-if="monthlyFuelReportContacts" outlined @click="monthlyFuelReportContacts=null;counter+=1;") Clear
  hr.my-4.primary(style="border-top: 1px solid;border-bottom: none;")
  v-layout.ma-2(justify-end)
    v-btn.mb-2.mx-2(small, color='primary', dark, @click='save') Save
</template>

<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import FormDivider from "@/modules/global/components/forms/FormDivider.vue";
import ContactsAutoComplete from "@/modules/global/components/forms/ContactsAutoComplete";
import Repository from "@/repository";
export default {
  name: "ReportSettings",
  components:{
    FormSubHeader,
    ContactsAutoComplete,
    FormDivider
  },
  data:()=>({
    dailyActivityReportContacts:null,
    monthlyVehicleReportContacts:null,
    monthlyFuelReportContacts:null,
    isLoading:true,
    counter:0,
  }),

  methods:{
    save: function() {
      let obj = {
        daily_activity_reports:this.dailyActivityReportContacts,
        monthly_vehicle_reports: this.monthlyVehicleReportContacts,
        monthly_fuel_reports: this.monthlyFuelReportContacts
      }

      this.isLoading = true;
      Repository.post(`account/details/report_settings`,obj).then(()=>{
        this.isLoading = false;
        this.counter +=1;
        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Report Settings Saved`
        });

      })

    },
    cancel: function() {
      console.log("Cancel");
    }
  },
  mounted() {
    this.isLoading = true;
    Repository.get(`account/details/report_settings`).then((response)=>{
      this.isLoading = false;

      if (response.data != null) {
        this.dailyActivityReportContacts = response.data.daily_activity_reports;
        this.monthlyVehicleReportContacts = response.data.monthly_vehicle_reports;
        this.monthlyFuelReportContacts = response.data.monthly_fuel_reports;
      }
      this.counter +=1;

    })
  }
}
</script>

<style scoped lang="stylus">

</style>