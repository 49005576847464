<template lang="pug">
  div
    v-divider
    v-container.ma-2(fluid)
      v-layout.mx-1(row, wrap)
        h2 Service Type
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item-group(color="primary")
                v-list-item(v-for='item in items', :key='item.title')
                  v-list-item-title {{ item.title }}
        v-btn.ma-2(small, color='primary')
          v-icon.pr-2(dark) attachment
          | Import Service Type
        // Dialog for Tasks
        v-btn.ma-2(small, color='primary', dark, @click='newItem') Add Service Type
    v-divider
    v-row.mx-2(align="center")
      v-col()
        v-text-field.pl-2(style='max-width:400px;', v-model='searchFilter', label='Search Filter', single-line, append-icon='search', :persistent-hint='true', required)
    v-divider
      .grey.lighten-4.py-3
    v-divider
    service-type-table(:search='searchFilter')
</template>

<script>
import ServiceTypeTable from "@/modules/services/components/tables/ServiceTypeTable";

export default {
  components: {
    ServiceTypeTable
  },
  data: () => ({
    action: null,
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ],

    searchFilter: ""
  }),

  methods: {
    onEditItem: function(payload) {
      console.log(payload);
    },

    newItem: function() {
      this.$store.dispatch("service/serviceType/addServiceType").then(() => {
        let currentIndex = this.$store.state.service.serviceType.currentIndex;
        let currentObject = this.$store.state.service.serviceType
          .servicetype_data[currentIndex];
        this.$router.push({
          name: "serviceTypeForm",
          params: { serviceID: currentObject.id }
        }).catch(()=>{});
      });
    },

    onCloseDialog: function() {
      console.log("close Dialog");
    },

    onSaveDialog: function(payload) {
      console.log(payload);
    },

    onDeleteItem: function(payload) {
      console.log(payload);
    }
  },

  mounted() {
    console.log(this.$store.state.service.serviceType.currentIndex);
  }
};
</script>

<style scoped></style>
