<template lang="pug">
  #blankcontent()
    .panelTitle() Data Statistics
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-space-around.align-center.fill-height()
          .d-flex.flex-column.align-center()
            .tickerTitle.primary--text() {{elements.places}}
            .tickerCaption.grey--text.text--darken-1() Places
          .d-flex.flex-column.align-center()
            .tickerTitle.secondary--text() {{elements.routes}}
            .tickerCaption.grey--text.text--darken-1() Routes
          .d-flex.flex-column.align-center()
            .tickerTitle.primary--text() {{elements.vehicles}}
            .tickerCaption.grey--text.text--darken-1() Vehicles
          .d-flex.flex-column.align-center()
            .tickerTitle.secondary--text() {{elements.contacts}}
            .tickerCaption.grey--text.text--darken-1() Contacts

</template>
<script>
import axios from "axios"

export default {
  data: () => ({
    elements : {
      vehicles: null,
      contacts: null,
      places: null,
      routes: null
    }

  }),
  mounted() {
    axios.get("/api/dashboard/totalKeyElements").then(response =>{
      this.elements = response.data;
    })
  },

};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.tickerTitle {
  font-size: 45px;
}

.tickerCaption {
  margin-top: -10px;
  font-size: 12px;
}
</style>
