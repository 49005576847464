<template lang="pug">
  div
    v-container.pt-4(grid-list-xl)
      v-layout(row wrap)
        v-flex(xs12 md6 xl6)
          v-card.my-3(color="white")
            .PairItems
              number-guage(:value="fuelCost" :label="'Fuel Costs'" :unitLabel="$store.state.profile.userInfo.account.region_settings.currency")
              number-guage(:value="serviceCost" :label="'Service Costs'" :unitLabel="$store.state.profile.userInfo.account.region_settings.currency")
            .PairItems
              number-guage(:value="millage/1000" label="Millage" unitLabel="Km.")
              number-guage(:value="expenseCost"  label="Expenses Costs" unitLabel="Ksh.")
          v-card.my-3(color="white")
            vehicle-details-panel
          v-card.my-3(color="white")
            service-reminder-panel
          v-card.my-3(color="white")
            vehicle-renewal-panel
          v-card.my-3(color="white")
            contact-renewal-panel

        v-flex(xs12 md6 xl6)
          v-card.my-3(color="white")
            overview-distance-panel
          v-card.my-3(color="white")
            assigned-panel
          v-card.my-3(color="white")
            overview-inspection-panel
          v-card.my-3(color="white")
            issues-panel
          v-card.my-3(color="white")
            critical-faults-panel
          v-card.my-3(color="white")
            comments-panel


</template>
<script>
import NumberGuage from "@/modules/global/components/dashboard/NumberGuage";
import VehicleDetailsPanel from "@/modules/vehicles/components/ui/Overview/VehiclesDetailsInformation";
import OverviewInformationPanel from "@/modules/global/components/others/OverviewInformationPanel";
import ServiceReminderPanel from "@/modules/vehicles/components/ui/Overview/ServiceReminderPanel";
import VehicleRenewalPanel from "@/modules/vehicles/components/ui/Overview/VehicleRenewalPanel";
import ContactRenewalPanel from "@/modules/vehicles/components/ui/Overview/ContactRenewalPanel";
import CriticalFaultsPanel from "@/modules/vehicles/components/ui/Overview/CriticalFaultsPanel";
import CommentsPanel from "@/modules/vehicles/components/ui/Overview/CommentsPanel";
import AssignedPanel from "@/modules/vehicles/components/ui/Overview/OverviewAssignedPanel";
import OverviewDistancePanel from "@/modules/vehicles/components/ui/Overview/OverviewDistancePanel";
import IssuesPanel from "@/modules/vehicles/components/ui/Overview/OverviewIssuePanel";
import OverviewInspectionPanel from "@/modules/vehicles/components/ui/Overview/OverviewInspectionPanel";
import Repository from "@/repository"

export default {
  components: {
    NumberGuage,
    VehicleDetailsPanel,
    OverviewInformationPanel,
    IssuesPanel,
    ServiceReminderPanel,
    VehicleRenewalPanel,
    ContactRenewalPanel,
    CriticalFaultsPanel,
    CommentsPanel,
    AssignedPanel,
    OverviewDistancePanel,
    OverviewInspectionPanel
  },
  data: () => ({
    fuelCost: null,
    serviceCost: null,
    millage: null,
    expenseCost: null
  }),

  methods: {
    onEdit: function (titlename) {
      console.log(titlename);
    },

    getVehicleCosts: function () {
      Repository.get(`/vehicle/getFuelServiceCosts/${this.$route.params.vehicleID}`).then(response => {
        this.fuelCost = response.data.fuel;
        this.serviceCost = response.data.service;
        this.millage = Math.round(response.data.odometer / 1000);
        this.expenseCost = response.data.expenses;
      })
    },


  },

  mounted() {
    this.getVehicleCosts();
  }
};
</script>
<style lang="stylus" scoped>
.PairItems {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
