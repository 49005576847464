<template lang="pug">
div
  v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
  v-text-field(v-model="selectHint" label="Description")
  v-text-field(v-model.number="selectMaxRows" label="Maximum Rows" mask="###")
  v-btn(@click="dialog=true" outlined small color="primary") Edit Table
  v-container()
    v-checkbox(v-model="selectRequired" label="Required")
    v-checkbox(v-model="selectCommentOnFail" label="Must comment when there is no input")
    v-dialog(v-model="dialog" max-width="1200" )
      table-properties-dialog(v-if="dialog" @close="dialog=false" )

  div
    div(@click.prevent="showAdvanced=!showAdvanced")
      showAdvancedProperties(:showAdvanced="showAdvanced"  )

    div(v-show="showAdvanced")
      v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)
</template>

<script>
import {inspectionPropertiesMixin} from "@/modules/inspections/components/formDesigner/properties/inspectionPropertiesMixin";
import TablePropertiesDialog from "@/modules/inspections/components/formDesigner/properties/tableUI/TablePropertiesDialog.vue";
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";
import ShowAdvancedProperties from "@/modules/inspections/components/formDesigner/component/ShowAdvancedProperties.vue";

export default {
  mixins: [inspectionPropertiesMixin],
  components: {
    TablePropertiesDialog,
    ShowAdvancedProperties
  },
  data: () => ({
    properties: {},
    showAdvanced: false,
    dialog: false
  }),

  computed: {
    selectRequired: {
      get: function () {
        return this.properties.required;
      },

      set: function (value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "required",
          value: value
        });
      }
    },

    selectCommentOnFail: {
      get: function () {
        return this.properties.comment_on_fail;
      },

      set: function (value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "comment_on_fail",
          value: value
        });
      }
    },

    selectedFieldName: {
      get: function() {
        return this.properties.label;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "label",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },

    selectHint: {
      get: function() {
        return this.properties.hint;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "hint",
          value: value
        });
      }
    },

    selectID: {
      get: function() {
        return this.$store.getters[SETTINGS_DEFAULT.GET_PATH].id;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },

    selectMaxRows: {
      get: function() {
        return this.properties.max_rows;
      },

      set: function(value) {
        if (value != null) {
          let i = parseInt(value);
          // only execute is an integer and greater than 0
          if (i > 0) {
            this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
              field: "max_rows",
              value: value
            });
          } else {
            this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
              field: "max_rows",
              value: null
            });
          }
        }
      }
    }
  },



  mounted() {
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    this.$refs.fieldName.focus();


  }
}

</script>

<style lang="stylus" scoped>

</style>