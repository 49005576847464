<template lang="pug">
  div
    v-checkbox( v-model="computedValue"  :label="schema.label")
</template>

<script>
export default {
  props:["dataValue","schema"],
  data:()=>({
    result:null,
  }),
  computed:{
    computedValue: {
      get: function() {
        return this.dataValue;
      },

      set: function(theValue) {
        console.log(theValue)
        this.$emit("change", {
          id: this.schema.id,
          value: theValue,
        });
      }
    }
  }
}
</script>

<style scoped lang="stylus">

</style>