<template lang="pug">
div(v-if="$store.state.workflow.workflowRecord.currentIndex > -1")
  import-file-dialog(:dialogShow="dialogShow", @closeDialog="onCloseDialog")
  v-divider
  v-container(fluid)
    v-layout.my-2(row, wrap)
      div
        h2.mx-6 Records
        generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer
      .text-xs-center
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, outlined, color="primary", dark, v-on="on")
              | Export
              v-icon(dark) keyboard_arrow_down
          v-list-item-group
            v-list-item(
              v-for="item in items",
              :key="item.title",
              dense,
              @click="onExport(item.value)"
            )
              v-list-item-title
                v-layout(align-center)
                  v-icon.mr-2(:size="20") {{ item.icon }}
                  .subtitle-2.mx-2 {{ item.title }}
      v-btn.ma-2.mr-5(small, color="primary", dark, @click="print")
        v-icon.mr-2 print
        | Print
  v-divider

  v-layout.mx-4.my-2(column)
    v-layout.mt-3(align-center, style="max-width:900px;")
      v-layout.px-3(row, wrap, align-center)
        search-autocomplete
        //- v-combobox(v-model='select1', :items='selectOptions1', label='Select a favourite activity or create a new one')
        //- v-text-field.pl-2(v-model='searchFilter', label='Search Filter', :persistent-hint='true', required)
        v-btn.mx-3(small, color="primary", dark) Search
        div
          span.primary--text 1 filter
          span.pl-1.grey--text.text--darken-1 applied
        a.px-3.grey--text.text--darken-1 clear all
  v-divider
  .grey.lighten-4.py-3
  v-divider
  record-table
  v-divider
  //- #previewWindow
</template>
<script>
import ImportFileDialog from "@/modules/global/components/table/ImportFileDialog";
import SearchAutocomplete from "@/modules/global/components/table/SearchAutocomplete";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import RecordTable from "@/modules/workflow/records/components/Tables/RecordTable";
export default {
  components: {
    ImportFileDialog,
    SearchAutocomplete,
    GenericBreadCrumbs,
    RecordTable
  },

  mounted() {
    this.$store.dispatch("workflow/workflowRecord/loadAllRecords");
    console.log(this.$store.state.workflow.workflowRecord.currentIndex);
  },

  data: () => ({
    select1: "All Forms",
    selectOptions1: [
      "All Forms",
      "Overdue Only",
      "Overdue + Due Soon",
      "Due Soon Only"
    ],
    items: [
      {
        title: "Import Excel",
        value: "import_excel"
      },
      {
        title: "Import CSV",
        value: "import_csv"
      }
    ],

    vehiclesFilter: "",
    searchFilter: "",
    dialogShow: false,
    breadCrumbsItems: [
      {
        text: "Table",
        disabled: false,
        path: "reports"
      },
      {
        text: "Record",
        disabled: true,
        path: "vehicleListReport"
      }
    ]
  }),

  methods: {
    print: function() {
      console.log("print");
    },
    showDialog: function() {},

    onCloseDialog: function() {
      this.dialogShow = false;
      this.$store.dispatch("workflow/dataTable/loadAllDataTables");
    },

    onAction: function(value) {
      console.log("onAction: " + value);
    },

    uploadExcel: function() {
      this.dialogShow = true;
    }
  }
};
</script>
<style scoped lang="stylus">
#previewWindow {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 400px;
  height: 500px;
  max-height: 500px;
  background-color: #330033;
}
</style>
