<template lang="pug">
div(v-if="customer")
  .ml-0(v-if="customer != null" @click="openCustomerDetail(customer.id)")
      .underline() {{customer.reference_id}} {{ customer.name }}


</template>
<script>
import axios from "axios";

export default {
  components: {
  },
  props: {
    customer_id: String,
    clickToDetail:{
      type:Boolean,
      default: false,
    }
  },
  data: () => ({
    customer: null
  }),
  watch: {
    customer_id:function(oldVal,newVal){
      if(oldVal !== newVal){
        this.getCustomer();
      }
    }
  },
  methods: {
    openCustomerDetail: function (customerID) {
      if(this.clickToDetail){
        this.$store.commit("customer/setCurrentIndexByID", customerID);
        this.$router.push({
          name: "customerDashboard",
          params: {contactID: customerID}
        }).catch(()=>{});
      }


    },
    getCustomer: function(){
      let tempCustomer = this.$store.getters["customer/getCustomerByID"](this.customer_id);

      if (tempCustomer == undefined) {
        axios.get(`/api/customer/${this.customer_id}`).then((response)=>{
          this.customer = response.data;
        });
      } else {
        this.customer = tempCustomer;
      }
    }
  },
  mounted() {
    this.getCustomer();
  }
};
</script>
<style lang="stylus" scoped>
.nameColumn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn, .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn, .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;
  padding: 0px;
}

.underline {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}
</style>
