<template lang="pug">
  v-data-table(
    :headers='headers',
    :items='$store.state.fuel.fuel_data',
    item-key="id",
    :loading='loading',
    :single-select="false"
    show-select
    v-model="selectedFuels"
    @click:row="onDblClickRow"
  )
    v-progress-linear(slot='progress', height='2', :indeterminate='loading')

    template(v-slot:item.vehicle="{item}")
      vehicle-column(v-if="item.vehicle" :vehicle="item.vehicle")

    template(v-slot:item.info="{item}")
      .SmallText
        div
          v-icon(small) access_time
          | &nbsp;&nbsp;{{item.date_time | moment("ddd, Do MMM YYYY, h:mm a") }}
        div
          v-icon(small) place
          | &nbsp;&nbsp;{{item.location_name}}
        div(v-if="item.merchant")
          v-icon(small) business
          | &nbsp;&nbsp;{{item.merchant.name}} -
          span(v-if="item.fuel_type") ({{item.fuel_type.name}})
        div
          span.grey--text by 
          span.primary--text(v-if="item.reported_by") {{item.reported_by.first_name}} {{item.reported_by.last_name}}
          span.grey--text.text--darken-2 &nbsp;&nbsp;&#35;{{item.tx_id}}

    template(v-slot:item.odometer="{item}")
      div(v-if="item.odometer") {{Math.round(item.odometer/1000)}}
        div
          span.DottedUnderline.grey--text(v-if="item.usage") {{(item.usage.toFixed(2))}} Km/l

    template(v-slot:item.total="{item}")
      div
        strong {{$store.state.profile.userInfo.account.region_settings.currency}} {{item.price}}
        div
          span.DottedUnderline.grey--text(v-if="item.prev_odometer") {{(item.price / ((item.odometer - item.prev_odometer)/1000)).toFixed(2)}} Ksh/km


    //template(v-slot:item.fuelEconomy="{item}")
    //  div 88.8/mi]]

    template(v-slot:item.photos="{item}")
      .d-flex.align-center(v-if="item.photos_id" )
        div(v-for="(itemID,index) in getPhotos(item.photos_id)" :key="index")
          v-tooltip(left)
            template(v-slot:activator="{ on, attrs }")
              .ml-2(v-bind="attrs" v-on="on")
                image-thumbnail(:width="60" :height="60" :src="itemID" )
            div()
              image-thumbnail(:width='455', :height='600', :src="itemID" :key="index")
        .ml-3.grey--text.text--darken-.font-weight-bold(v-if="item.photos_id > 2") +{{item.photos_id - 2}}
        //- image-thumbnail.ml-2(v-for="(itemID,index) in getPhotos(item.photos_id)" :key="index" :width='60', :height='60', :src="itemID")

    template(v-slot:item.cost="{item}")
      div {{item.price}}/km

    template( v-slot:item.action="{ item }")
      v-row.mr-7(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item' :disabled="$store.state.profile.userInfo.account_id != item.account_id")

</template>

<script>
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import IconChip from "@/modules/global/components/table/IconChip";

export default {
  props:["loading"],
  components: {
    VehicleColumn,
    ImageThumbnail,
    IconChip,
    MoreDropDownMenu
  },

  data: () => ({
    selected: [],
    pagination: {
      sortBy: "time"
    },
    headers: [
      {
        text: "Vehicle",
        value: "vehicle",
        tooltip: "Vehicle Name",
        align: "left"
      },
      {
        text: "Info",
        value: "info",
        tooltip: "Fuel Information",
        align: "left"
      },
      {text: "Odometer in Km", value: "odometer", tooltip: "Odometer", align: "center"},
      {
        text: "Volume (L)",
        value: "volume",
        tooltip: "Volume of Fuel",
        align: "center"
      },
      {text: "Total", value: "total", tooltip: "Total", align: "left"},
      {
        text: "Photos",
        value: "photos",
        tooltip: "Photos",
        align: "left"
      },
      // { text: "Cost", value: "cost", tooltip: "Cost", align: "left" },
      {text: "Action", value: "action", tooltip: "Edit", align: "right"}
    ],

    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ]
  }),

  computed:{
    selectedFuels: {
      get: function () {
        return this.$store.getters["fuel/getSelectedFuels"];
      },
      set: function (value) {
        this.$store.commit("fuel/setSelectedFuels", value);
      }
    },
  },
  methods: {
    getPhotos: function(list) {
      if (list.length > 2) {
        return list.slice(0, 2);
      } else {
        return list;
      }
    },
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("fuel/setCurrentIndexByID", value.theObject.id);
          this.$router.push({
            name: "fuelDetailsForm",
            params: {fuelID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          confirm("Are you sure you want to delete this item?") &&
          this.$store.dispatch("fuel/deleteFuel", value.theObject.id);
          break;
      }
    },

    onDblClickRow: function (value) {
      this.$store.commit("fuel/setCurrentIndexByID", value.id);
      this.$router.push({
        name: "fuelOverview",
        params: {fuelID: value.id}
      }).catch(()=>{});
    }
  },

  mounted() {

  }
};
</script>

<style scoped>
.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;

  padding: 0px;
}

.SmallText {
  font-size: 11px;
}

.DottedUnderline {
  border-bottom: 1px dotted #999;
}
</style>
