<template lang="pug">
div(v-if="$store.state.vehicles.currentIndex != -1")
  v-layout.pt-2.pr-3(align-center)
    v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
    v-spacer
    v-btn.mx-2(small, color='primary', outlined, dark, @click='cancel') Cancel
    v-btn.mx-2(small, color='primary', dark, @click='save') Save
  v-container(v-if="currentVehicle" grid-list-xl)
    v-layout(row, wrap, justify-end)
      v-flex(xs12 md3 xl3 class="hidden-sm-and-down")
        sub-navigation(:menuItems="menuItems" title="Vehicle Information" :selectedIndex="selectedMenuIndex"  @selectedForm="onSelectForm")
      v-flex(xs12, md9, xl9)
        v-card(outlined)
          .HeaderBody.py-2
            .HeaderInfo
              image-thumbnail.mr-3.ml-3(:width="80" :height="80"  :src="currentVehicle.thumbnail_id")
              .HeaderText
                .title {{ currentVehicle.name }}
                p.ma-0.pa-0.caption.font-weight-regular
                  | {{ currentVehicle.make }} · {{ currentVehicle.model }} ·
                  | {{ currentVehicle.vin }} · {{ currentVehicle.numberplate }}
                v-divider.mt-1
                p.ma-0.pa-0.caption.grey--text.font-weight-regular
                  | {{ currentVehicle.body }}
                  | {{ currentVehicle.drive_type ? ` | ${currentVehicle.drive_type}`   : null  }}
            v-spacer
            
          v-divider
          keep-alive
            component(:is="selectedForm")
</template>

<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import SubNavigation from "@/modules/global/components/navigation/SubNavigation";
import VehicleDeatilsForm from "@/modules/vehicles/components/forms/VehicleDetailsForm";
import VehicleSpecificationForm from "@/modules/vehicles/components/forms/VehicleSpecificationForm";
import VehicleEngineTransmision from "@/modules/vehicles/components/forms/VehicleEngineTransmissionForm";
import VehicleWheelsForm from "@/modules/vehicles/components/forms/VehicleWheelsForm";
import VehicleFluidsForm from "@/modules/vehicles/components/forms/VehicleFluidsForm";
import VehicleSettingsForm from "@/modules/vehicles/components/forms/VehicleSettingsForm";
import VehicleNotificationForm from "@/modules/vehicles/components/forms/VehicleNotificationForm";

// import { mapGetters } from "vuex";
export default {
  components: {
    SubNavigation,
    VehicleDeatilsForm,
    VehicleSpecificationForm,
    VehicleEngineTransmision,
    VehicleWheelsForm,
    VehicleFluidsForm,
    VehicleSettingsForm,
    ImageThumbnail,
    VehicleNotificationForm
  },

  data: () => ({
    selectedForm: "VehicleDeatilsForm",
    selectedMenuIndex: 0,
    menuItems: [
      {
        title: "Basic Details",
        iconClass: "primary white--text",
        icon: "local_shipping",
        formName: "VehicleDeatilsForm"
      },
      {
        title: "Dimensions & Weight",
        iconClass: "secondary white--text",
        icon: "rv_hookup",
        formName: "VehicleSpecificationForm"
      },
      {
        title: "Engine & Transmission",
        icon: "drive_eta",
        iconClass: "secondary white--text",
        formName: "VehicleEngineTransmision"
      },
      {
        title: "Suspension, Brakes & Tyres",
        icon: "trip_origin",
        iconClass: "secondary white--text",
        formName: "VehicleWheelsForm"
      },
      {
        title: "Fuel & Economy",
        icon: "local_gas_station",
        iconClass: "secondary white--text",
        formName: "VehicleFluidsForm"
      },
      {
        title: "Notifications",
        icon: "contact_phone",
        iconClass: "secondary white--text",
        formName: "VehicleNotificationForm"
      },
      // {
      //   title: "Violation Notifications",
      //   icon: "contact_phone",
      //   iconClass: "secondary white--text",
      //   formName: "VehicleSettingsForm"
      // }
    ]
  }),

  computed: {
    currentVehicle: {
      get() {
        return this.$store.getters["vehicles/getCurrentItem"];
      }
    },

    getUrl: function() {
      return `/main/vehicle/${this.currentVehicle.id}/new`;
    }
  },

  mounted() {
    // if data is not loaded route to the Page List of vehicles i.e. /vehicles
    if (this.$store.state.vehicles.currentIndex == -1) {
      this.$store.dispatch("vehicles/fetchVehicle", {
        id: this.$route.params.vehicleID
      });
    }
  },

  methods: {
    onSelectForm: function(index) {
      this.selectedForm = this.menuItems[index].formName;
      this.selectedMenuIndex = index;
    },

    save: function() {
      this.$store.dispatch("vehicles/saveItem").then(() => {
        this.$router.push({ name: "vehicles" });
        this.$store.dispatch("vehicles/loadAllItems").then(() => {
          this.$store.dispatch("vehicles/assignments/loadAllSchedules");
        }).catch(()=>{});
      });
    },

    cancel: function() {
      this.$store.dispatch("vehicles/loadAllItems").then(() => {
        this.$store.dispatch("vehicles/assignments/loadAllSchedules");
      });
      this.$router.push({ name: "vehicles" }).catch(()=>{});
    },

    addMultiple: function() {
      console.log("add multiple");
    },
    getBreadCrumbItems: function() {
      var theArray = [];
      theArray.push({
        text: "Vehicle List",
        disabled: false,
        to: { name: "vehicles" }
      });
      theArray.push({
        text: this.currentVehicle.name,
        disabled: true
      });
      return theArray;
    }
  }
};
</script>

<style scoped lang="stylus">
.headerButtons {
  display: flex;
  justify-content: flex-end;
}

.HeaderText {
  display: flex;
  flex-direction: column;
}

.HeaderInfoButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}

.HeaderBody {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.HeaderInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.EmptyThumbnail {
  width: 80px;
  height: 80px;
  border: 2px dashed #cccccc;
  border-radius: 6px;
  font-size: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #CCCCCC;
}
</style>
