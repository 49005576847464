<template lang="pug">

  svg( xmlns="http://www.w3.org/2000/svg"  :width="rowWidth" :height="totalHeight+5" :style="getMoveStyle")
    scheduler-header(
      :width="rowWidth"
      :height="50"
      :steps="steps"
    )
    g(transform="translate(0,50)")
      rect(
        ref="base"
        :width="rowWidth"
        :height="totalHeight+5"
        fill="#f8f8f8"
      )

      scheduler-row(
        v-for="(item,index) in getData"
        :y="index*rowHeight"
        :key="index"
        :width="rowWidth"
        :height="rowHeight"
        :steps="steps"
        :substeps="substeps"
        :layerIndex="index"
        @rowMouseDown="RowMouseDown"
        :item="item"
        :unit="unit"
        @selectAssignment="onStartDragItem"
        @frontMouseDown="onFrontMouseDownItem"
        @endMouseDown="onEndMouseDownItem"
      )
      line(:x1="xPosTimeMarker" :y1="0" :x2="xPosTimeMarker" :y2="totalHeight-50"  :stroke="markerColor" stroke-width="2" stroke-opacity="0.5")

      polygon(
        v-show="showHighlight"
        ref="highlighter"
        fill="#55aaff"
        fill-opacity="0.5"
        :points="`${highlighterX} ${highlighterY}, ${highlighterX+highlighterWidth} ${highlighterY},${highlighterX+highlighterWidth}  ${highlighterY+rowHeight},${highlighterX} ${highlighterY+rowHeight}`"

      )
      rect(
        v-show="showHighlight"
        :x="highlighterX"
        :y="positionFrontAndEndY+resizeBracketWidth"
        :width="resizeBracketWidth*2"
        :height="rowHeight-(resizeBracketWidth*2)"
        fill="#000033"
        @mousedown="FrontMouseDown"
      )
      rect(
        v-show="showHighlight"
        :x="highlighterX+highlighterWidth-(resizeBracketWidth*2)"
        :y="positionFrontAndEndY+resizeBracketWidth"
        :width="resizeBracketWidth*2"
        :height="rowHeight-(resizeBracketWidth*2)"
        fill="#000033"
        @mousedown="EndMouseDown"
      )

      scheduler-tooltip(
        v-show="showHighlight"
        :title="getContactName"
        :subTitle="`${xPosFromDate}`"
        :width="toolTipWidth"
        :height="rowHeight"
        :arrowSize="tooltipArrowSize"
        :transform="leftToolTipXPos"
        direction="left"
      )
      scheduler-tooltip(
        v-show="showHighlight"
        :title="getContactName"
        :subTitle="`${xPosToDate}`"
        :width="toolTipWidth"
        :height="rowHeight"
        :arrowSize="tooltipArrowSize"
        :transform="rightToolTipXPos" direction="right"
      )
</template>
<script>
import SchedulerRow from "@/modules/vehicles/components/scheduler/SchedulerRow";
import SchedulerHeader from "@/modules/vehicles/components/scheduler/SchedulerHeader";
import SchedulerTooltip from "@/modules/vehicles/components/scheduler/SchedulerTooltip";
import moment from "moment";

export default {
  components: {
    SchedulerHeader,
    SchedulerRow,
    SchedulerTooltip
  },
  props: {
    rowHeight: Number,
    rowWidth: Number
  },
  data: () => ({
    selectecLayerID: null,
    selectedAssignment: null,
    highlighterWidth: 500,

    showHighlight: false,
    highlighterX: 0,
    highlighterOriginX: 0,
    highlighterY: 0,
    highlighterOriginY: 0,

    isDragging: false,
    isCopying: false,
    rowDragging: false,

    endDragging: false,
    FrontDragging: false,
    FrontOriginX: null,
    FrontOriginWidth: null,
    resizeBracketWidth: 5,

    toolTipWidth: 200,
    tooltipArrowSize: 15,

    tz_start: null,
    tz_end: null,

    xPosTimeMarker: null,
    intervalID: null,
    theTimer: Date.now(),
    markerColor: "red",
    markerCounter: 5,

  }),

  computed: {
    selectedLayerIndex: {
      get: function () {
        return this.$store.state.vehicles.assignments.layerIndex;
      },
      set: function (value) {
        this.$store.commit("vehicles/assignments/setLayerIndex", value);
      }
    },

    getData: function () {
      return this.$store.getters["vehicles/assignments/getData"];
    },

    steps: function () {
      return this.$store.getters["vehicles/assignments/getSteps"];
    },

    substeps: function () {
      return this.$store.getters["vehicles/assignments/getSubSteps"];
    },

    // Get the Height of the component
    totalHeight: function () {
      return this.rowHeight * this.getData.length + 50;
    },

    // Get the Position for expanding Markers
    positionFrontAndEndY: function () {
      return this.selectedLayerIndex * this.rowHeight;
    },

    // calculate total steps in the grid
    totalSteps: function () {
      return this.steps * this.substeps;
    },

    // get pixel unit value of one step
    unit: function () {
      return this.rowWidth / this.totalSteps;
    },

    // position of rightToolTip
    rightToolTipXPos: function () {
      return `translate(${this.highlighterX +
      this.highlighterWidth +
      this.tooltipArrowSize},${this.rowHeight * this.selectedLayerIndex})`;
    },

    // position of LeftToolTip
    leftToolTipXPos: function () {
      return `translate(${this.highlighterX -
      this.toolTipWidth -
      this.tooltipArrowSize},${this.rowHeight * this.selectedLayerIndex})`;
    },

    // reverse lookup for the start, from pixels -> units
    getUnitsXPos: function () {
      return Math.round(this.highlighterX / this.unit);
    },

    // revers lookup for the end, pixels -> units
    getUnitsWidth: function () {
      return Math.round(
          (this.highlighterX + this.highlighterWidth) / this.unit
      );
    },

    // Position of start pixels based on the units u,e. timestamp -> unites -> pixels
    xPosFromDate: function () {
      let startDate = this.$store.getters["vehicles/assignments/getStartDate"];
      let tempMiliseconds =
          startDate.getTime() +
          this.getUnitsXPos *
          this.$store.getters["vehicles/assignments/getTimeSplit"];
      this.setTzStart(tempMiliseconds);
      return moment(tempMiliseconds).format("h:mm a ddd, Do MMM YYYY");
    },

    // Position of end pixels based on the units u,e. timestamp -> unites -> pixels
    xPosToDate: function () {
      let startDate = this.$store.getters["vehicles/assignments/getStartDate"];
      let tempMiliseconds =
          startDate.getTime() +
          this.getUnitsWidth *
          this.$store.getters["vehicles/assignments/getTimeSplit"];
      this.setTzEnd(tempMiliseconds);
      return moment(tempMiliseconds).format("h:mm a ddd, Do MMM YYYY");
    },

    getMoveStyle: function () {
      let obj = {};
      if (this.isDragging) {
        if (this.isCopying) {
          obj["cursor"] = "copy";
        } else {
          obj["cursor"] = "move";
        }
      }

      return obj;
    },


    getContactName: function () {
      if (this.selectedAssignment) {
        return `${this.selectedAssignment.contact.first_name} ${this.selectedAssignment.contact.last_name}`;
      } else {
        return null;
      }
    },

    modeChange: function () {
      return this.$store.state.vehicles.assignments.mode
    }
  },

  watch: {
    modeChange: function () {
      this.calcXPosTimeMarker();
    }
  },

  methods: {
    setTzStart: function (value) {
      this.tz_start = value;
    },
    setTzEnd: function (value) {
      this.tz_end = value;
    },
    // called when mouse is down over the assignment schedule job
    startDrag: function (event) {
      this.isDragging = true;
      // check if item is being copied by holding shipf
      if (event.getModifierState("Shift")) {
        this.isCopying = true;
      } else {
        this.isCopying = false;
      }
      // check if item is being copied by holding shipf
      this.showHighlight = true;
      this.highlighterOriginX =
          event.clientX - event.target.getBoundingClientRect().x;
      this.highlighterOriginY =
          event.clientY - event.target.getBoundingClientRect().y;

      window.addEventListener("mouseup", this.stopDrag);
      window.addEventListener("mousemove", this.doDrag);
    },
    // stop the above drag
    stopDrag: function (event) {
      this.isDragging = false;

      // check for a click
      let diffX = Math.abs(
          this.highlighterOriginX -
          (event.clientX - event.target.getBoundingClientRect().x)
      );
      let diffY = Math.abs(
          this.highlighterOriginY -
          (event.clientY - event.target.getBoundingClientRect().y) +
          10.5
      );

      //when clicked then open Dialog
      if (diffX < 2.0 && diffY < 2.0) {
        // console.log("clicked");
        // clean up
        this.showHighlight = false;

        // send event to grandfather object
        this.$store.commit(
            "vehicles/assignments/setAssignment",
            this.selectedAssignment
        );
        this.$emit("openDialog");
      } else {
        if (event.getModifierState("Shift")) {
          //copy the assignement
          this.UpdateAssignment(true);
        } else {
          // update assignment
          this.UpdateAssignment(false);
        }
      }

      window.removeEventListener("mouseup", this.stopDrag);
      window.removeEventListener("mousemove", this.doDrag);
    },

    // dRAGGING FUNCTIONALITY
    doDrag: function (event) {
      event.preventDefault();

      if (this.isDragging) {
        // check if item is being copied by holding shipf
        if (event.getModifierState("Shift")) {
          this.isCopying = true;
        } else {
          this.isCopying = false;
        }

        // get the position of the xPoint
        let tempX =
            event.clientX -
            this.$refs.base.getBoundingClientRect().x -
            this.highlighterOriginX;

        // snapping of the start point to nearest unit
        this.highlighterX = Math.floor(tempX / this.unit) * this.unit;

        // positioning the layer by snapping to the next layer
        this.selectedLayerIndex = Math.floor(
            (event.clientY - this.$refs.base.getBoundingClientRect().y) /
            this.rowHeight
        );

        //constrain the layer - lower bounds
        this.selectedLayerIndex =
            this.selectedLayerIndex < 0 ? 0 : this.selectedLayerIndex;

        //constrain the layer - upper bounds
        this.selectedLayerIndex =
            this.selectedLayerIndex > this.getData.length - 1
                ? this.getData.length - 1
                : this.selectedLayerIndex;

        this.selectedLayerID = this.getData[this.selectedLayerIndex].id;

        // moving the lyaer to the appriate layer
        this.highlighterY = this.rowHeight * this.selectedLayerIndex; // jump in steps of 70
      }
    },

    // draging the ROW similar to functionality as startDrag. functionality is disabled as it is
    RowMouseDown: function (event, index) {
      this.showHighlight = true;
      this.selectedLayerIndex = index;
      this.selectedLayerID = this.getData[index].id;
      let tempX = event.clientX - this.$refs.base.getBoundingClientRect().x; // get the postion
      // single substep with units
      this.highlighterX = Math.floor(tempX / this.unit) * this.unit; //  calculate inital position of X Axis
      this.highlighterY = index * this.rowHeight; // step the height
      this.highlighterWidth =
          event.clientX -
          this.highlighterX -
          this.$refs.base.getBoundingClientRect().x;
      this.rowDragging = true; // flag the animation for streching the hightlight
      window.addEventListener("mouseup", this.RowStopDrag);
      window.addEventListener("mousemove", this.RowSelectDrag);
    },

    RowStopDrag: function () {
      this.showHighlight = false;
      this.rowDragging = false;

      // invert if item is dragged to the left, why: because a rect cant have -ve width
      let xDifference =
          this.$refs.highlighter.points[0].x - this.$refs.highlighter.points[1].x; // if the diffrence is +ve rather than -ve then the selection is to the left of the mouse
      if (xDifference > 0) {
        this.highlighterX = this.$refs.highlighter.points[1].x;
        this.highlighterWidth = xDifference;
      }

      this.highlighterWidth =
          this.highlighterWidth < this.unit && xDifference < 0
              ? this.unit
              : this.highlighterWidth; // constrain to be one unit when just clicked

      // send event to grandfather object
      this.$emit("openDialog");
      this.$store.commit("vehicles/assignments/setAssignment", {
        id: null,
        during: {
          tz_start: this.tz_start,
          tz_end: this.tz_end
        },
        vehicle_id: this.$store.getters["vehicles/assignments/getSelectedLayer"]
            .id,
        contact_id: null,
        description: null,
        email_notification: false,
        sms_notification: false,
        start_odometer: null,
        end_odometer: null,
        thumbnail: null
      });
      window.removeEventListener("mouseup", this.RowStopDrag);
      window.removeEventListener("mousemove", this.RowSelectDrag);
    },

    RowSelectDrag: function (event) {
      if (this.rowDragging) {
        let tempWidth =
            event.clientX -
            this.highlighterX -
            this.$refs.base.getBoundingClientRect().x;
        this.highlighterWidth = Math.ceil(tempWidth / this.unit) * this.unit;
      }
    },

    // functions by extending the tail of the schedule job
    EndMouseDown: function () {
      this.endDragging = true;
      this.showHighlight = true;
      window.addEventListener("mouseup", this.EndStopDrag);
      window.addEventListener("mousemove", this.EndDoDrag);
    },

    EndStopDrag: function () {
      this.endDragging = false;

      // update assignment
      this.UpdateAssignment();

      window.removeEventListener("mouseup", this.EndStopDrag);
      window.removeEventListener("mousemove", this.EndDoDrag);
    },

    EndDoDrag: function (event) {
      if (this.endDragging) {
        let tempWidth =
            event.clientX - this.$refs.highlighter.getBoundingClientRect().x;
        tempWidth = Math.ceil(tempWidth / this.unit) * this.unit;
        this.highlighterWidth = tempWidth < this.unit ? this.unit : tempWidth;
      }
    },

    // functions by extending the head of the schedule job
    FrontMouseDown: function (event) {
      this.showHighlight = true;
      this.FrontOriginX =
          event.clientX -
          this.resizeBracketWidth -
          this.$refs.base.getBoundingClientRect().x;
      this.FrontOriginWidth = this.highlighterWidth;
      this.FrontDragging = true;

      window.addEventListener("mouseup", this.FrontStopDrag);
      window.addEventListener("mousemove", this.FrontDoDrag);
    },

    FrontStopDrag: function () {
      this.FrontDragging = false;

      // update assignment
      this.UpdateAssignment();

      window.removeEventListener("mouseup", this.FrontStopDrag);
      window.removeEventListener("mousemove", this.FrontDoDrag);
    },

    FrontDoDrag: function (event) {
      if (this.FrontDragging) {
        let tempX =
            event.clientX -
            this.resizeBracketWidth -
            this.$refs.base.getBoundingClientRect().x;
        this.highlighterX = Math.floor(tempX / this.unit) * this.unit;

        let tempWidth =
            this.FrontOriginWidth + (this.FrontOriginX - this.highlighterX);
        tempWidth = Math.round(tempWidth / this.unit) * this.unit;
        this.highlighterWidth = tempWidth < this.unit ? this.unit : tempWidth;
      }
    },

    // Updates the dragged schedule by calling the vuex store to reposition it
    UpdateAssignment: function (isCopy) {
      if (this.selectedAssignment) {
        this.$store
            .dispatch("vehicles/assignments/moveSchedule", {
              assignment: this.selectedAssignment,
              unitStart: this.getUnitsXPos,
              unitEnd: this.getUnitsWidth,
              layerIndex: this.selectedLayerIndex,
              vehicleID: this.getData[this.selectedLayerIndex].id,
              startDate: this.$store.getters["vehicles/assignments/getStartDate"],
              isCopy: isCopy
            })
            .catch(error => {
              this.$store.commit("showSnackBar", {
                color: "error",
                message: error
              });
            });

        // clean up
        this.selectedAssignment = null;
        this.showHighlight = false;
      }
    },

    // Called when mouse is ppressed over the schedule Job. It overlays the Job with the blue highligher
    FitToAssignment: function (assignment, layerIndex) {
      this.showHighlight = true;

      // console.log(assignment.unit_start * this.unit);
      // get the initial starting Date
      let startDate = this.$store.getters["vehicles/assignments/getStartDate"];

      // get the Starting Units and Ending Units
      let unitStart =
          (assignment.during.tz_start - startDate.getTime()) /
          this.$store.getters["vehicles/assignments/getTimeSplit"];
      let unitEnd =
          (assignment.during.tz_end - startDate.getTime()) /
          this.$store.getters["vehicles/assignments/getTimeSplit"];

      // console.log(unitStart);
      // console.log(unitEnd);

      // set the border to the selected assignment
      this.selectedAssignment = assignment;
      this.highlighterX = unitStart * this.unit;
      this.highlighterWidth = (unitEnd - unitStart) * this.unit;
      this.selectedLayerIndex = layerIndex;
      this.selectedLayerID = this.getData[this.selectedLayerIndex].id;
      this.highlighterY = this.rowHeight * this.selectedLayerIndex; // jump in steps of 70
    },

    // called when scheduleItem is clicked. Event is propageted from the schedueItem Grandchild
    onStartDragItem: function (event, assignment, layerIndex) {
      this.FitToAssignment(assignment, layerIndex);
      // start the draging procdure
      this.startDrag(event);
    },

    // called when scheduleItem Head extension is clicked. Event is propageted from the schedueItem Grandchild
    onFrontMouseDownItem: function (event, assignment, layerIndex) {
      this.FitToAssignment(assignment, layerIndex);

      // call frontMouseDown to stretch
      this.FrontMouseDown(event);
    },

    // called when scheduleItem Tail extension is clicked. Event is propageted from the schedueItem Grandchild
    onEndMouseDownItem: function (event, assignment, layerIndex) {
      this.FitToAssignment(assignment, layerIndex);

      //call EndMouseDown stretch
      this.EndMouseDown(event);
    },


    calcXPosTimeMarker: function () {
      let startDate = this.$store.getters["vehicles/assignments/getStartDate"];
      let tempX =
          (new Date().getTime() - startDate.getTime()) /
          this.$store.state.vehicles.assignments.timeSplit;
      // console.log(tempX);
      this.xPosTimeMarker = tempX * this.unit;//  assignment.unit_start * this.unit

    },

    onRefreshCurrentTime() {
      // console.log(`The current time ${Date.now()}`)
      // console.log(`xPosMarker: ${this.xPosTimeMarker}`)
      if (this.markerCounter % 5 == 0) {
        this.markerColor = this.markerColor == "blue" ? "red" : "blue";
      }
      this.markerCounter++;
      this.theTimer = Date.now();
      this.calcXPosTimeMarker();
    }
  },


  mounted() {
    this.onRefreshCurrentTime();

    this.intervalID = setInterval(() => {
      this.onRefreshCurrentTime();
    }, 500)
  },

  beforeDestroy() {
    clearInterval(this.intervalID);
  }
};
</script>
<style lang="stylus" scoped></style>
