<template lang="pug">
  div(v-if="getCurrentWorkflow")
    //- v-progress-linear(:indeterminate="loading")
    //- Header
    v-container.mx-4(fluid)
      v-toolbar(dense, flat)
        v-toolbar-title
          .title.grey--text() Form Designer &mdash; {{version}}
        v-spacer

      v-container.pa-2
        v-alert(v-if="error" :value="true" color="error" icon="warning") 
          div(v-for="(e,index) in errors" :key="index" v-text="e")
        v-layout(align-center)
          v-btn(color="primary" @click="preview" ) 
            v-icon.mr-2(:size="20") remove_red_eye
            | Preview

          
          v-spacer()
          v-btn.ma-2(small text color="grey" @click="cancel") cancel
          v-btn.ma-2(small color="primary" @click="save") Save
    v-container
        v-container.grid-list-xl
          v-layout.pb-4(row wrap justify-start)
            
            v-flex(sm12 md8)
              v-card.grey.lighten-4
                v-container()
                  v-row()
                    v-col(:cols="2")
                      v-text-field(v-model="name" color="accent" label="Form Name" required)
                    v-col(:cols="4")
                      v-text-field(v-model="description" color="accent" label="Form Description" )
                    v-col(:cols="3")
                      v-select(v-model="title" :items="getTitleSubTitle" label="Title" item-text="label" item-value="id")
                    v-col(:cols="3")
                      v-select(v-model="sub_title" :items="getTitleSubTitle" label="Sub Title"  item-text="label" item-value="id")
              .my-8()
              v-card
                v-container.grid-list-md()

                  //- Dragging of the Components
                  draggable(v-model="schemeData" group="people" @start="drag=true" @end="drag=false"  :move="checkMove" )
                    //- Rendering the components
                    render-component(v-for="(item,index) in schemeData" :key="index" :item="item")

                  //- Bottom DropZone Component
                  template-bottom-drop-zone()
                  
            v-flex(sm12 md4)
              div(ref="theProperties" style="position:sticky; top:60px;")
                v-card
                  v-container.grid-list-sm
                    properties-holder()
                    
    
    v-dialog(v-model="modalWindow" max-width="500px")
      v-card
        v-card-text
          global-template-components()
        v-card-actions
          v-btn(color="primary" text @click="modalWindow=false") Close

            
</template>
<script>
import GlobalTemplateComponents from "@/modules/workflow/formBuilder/components/propertyTypes/GlobalTemplateComponents";
import RenderComponent from "@/modules/workflow/formBuilder/components/ui/RenderComponent";
import TemplateBottomDropZone from "@/modules/workflow/formBuilder/components/ui/TemplateBottomDropZone";
import PropertiesHolder from "@/modules/workflow/formBuilder/components/PropertiesHolder";
import draggable from "vuedraggable";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "workflow/getWorkflow",
  mutationType: "workflow/updateWorkflow"
});
export default {
  components: {
    GlobalTemplateComponents,
    draggable,
    RenderComponent,
    TemplateBottomDropZone,
    PropertiesHolder
  },

  data: () => ({
    error: false,
    errors: [],
    loading: true
  }),

  computed: {
    ...mapFields([
      "name",
      "version",
      "description",
      "title",
      "sub_title",
      "details",
      "status",
      "data",
      "account_id"
    ]),

    getCurrentWorkflow: function() {
      return this.$store.getters["workflow/getCurrentWorkflow"];
    },

    schemeData: {
      get: function() {
        return this.$store.state.workflow.formBuilder.schema_data;
      },
      set: function(value) {
        this.$store.commit("workflow/formBuilder/setSchema", value);
      }
    },

    modalWindow: {
      get: function() {
        return this.$store.state.workflow.formBuilder.modalWindow;
      },
      set: function(value) {
        this.$store.commit("workflow/formBuilder/setModalWindow", value);
      }
    },

    getTitleSubTitle:function(){
      let list = []

      this.schemeData.forEach(item => {
        list.push({id:item.id,label:item.label});
      })


      return list
    }
  },

  methods: {
    cancel: function() {
      this.$store.dispatch("workflow/loadAllWorkflows");
      this.$router.push({
        name: "workflows"
      }).catch(()=>{});
    },
    preview: function() {
      this.$router.push({
        name: "templateFillForm",
        params: { workflowID: this.$route.params.workflowID }
      }).catch(()=>{});
    },

    save: function() {
      this.loading = true;
      // console.log("save schema");
      this.$store.commit('workflow/formBuilder/saveFieldProperties'); // save the form
      this.$store.commit("workflow/formBuilder/setOrderNumbers"); // Arrange the Order for components
      // console.log("check if the values are the same")
      // console.log("======================= SCHEMA TOUCHED ==============================")
      let schemaTouched = false;
      if(this.version > 0){
        // check diffrences between the two schema
        let workSchema = JSON.stringify(this.$store.getters["workflow/getLatestSchema"])


        let builderSchama = JSON.stringify(this.$store.getters["workflow/formBuilder/getSchema"])


        // console.log(workSchema.toString());
        // console.log("----------")
        // console.log(builderSchama.toString())
        // lodash isEqual
        schemaTouched =  !(workSchema.toString() == builderSchama.toString())
        // console.log(schemaTouched);

      }else{
        schemaTouched = true;
      }

      console.log(schemaTouched)
      if(schemaTouched){
        this.$store.commit(
            "workflow/setSchema",
            {schema: this.$store.getters["workflow/formBuilder/getSchema"], version:this.version+1, date:new Date().toISOString()}
        );
      }


      this.$store.dispatch("workflow/saveWorkflow").then(() => {
        this.$store.dispatch("workflow/loadAllWorkflows").then(() => {
          // reset formBuilderSchema
          this.$store.commit("workflow/formBuilder/setSchema",[])
          this.$store.commit("showSnackBar", {
            color: "accent",
            message: `Workflow Record Saved`
          });
          this.$router.push({ name: "workflows" }).catch(()=>{});
        });
      });
    },

    checkMove: function() {
      // disable move when editing the proeprtie
      return !this.$store.state.workflow.formBuilder.disableAllCompoents;
    }
  },

  mounted() {

    if (this.$store.state.workflow.currentIndex == -1) {
      this.$store
        .dispatch("workflow/fetchWorkflow", this.$route.params.workflowID)
        .then(() => {
          this.$store.commit("workflow/formBuilder/setSchema",JSON.parse(JSON.stringify(this.$store.getters["workflow/getLatestSchema"])))
          this.loading = false;
        }).catch(() => {
          this.cancel();
      });
    }else {
      if (this.getCurrentWorkflow.data.length > 1) {
        this.$store.commit("workflow/formBuilder/setSchema", JSON.parse(JSON.stringify(this.$store.getters["workflow/getLatestSchema"])))
        this.loading = false;
      }
    }
  }
};
</script>
<style scoped lang="stylus">

.documentTitle {
  font-size: 18px;
  border-bottom: 1px #CCC solid;
  width: 500px;
  height: 30px;
}

.drag {
  font-family: sans-serif;
  display: inline-block;
  border-radius: 10px;
  background: #ccc;
  position: relative;
  padding: 30px;
  text-align: center;
  vertical-align: top;
}

.drop {
  font-family: sans-serif;
  display: inline-block;
  border-radius: 10px;
  position: relative;
  padding: 30px;
  text-align: center;
  vertical-align: top;
  background: #eee;
}
</style>
