<template lang="pug">
  div(v-if="loadedUser")
    .title.pa-3(:class="['grey lighten-4']") User Management
    v-divider
    v-container
      div(v-if="$store.state.profile.userInfo.is_administrator")
        v-switch(v-model="isUser" color="primary" :disabled="!$store.state.profile.userInfo.is_administrator" label="Enable User" hint="Enable login access and permissions" :persistent-hint="true" )
        v-form(v-if="isUser" ref="updateUserForm")
          v-divider.my-4()
          v-layout(row,wrap )
            v-flex(xs12 md6)
              v-layout(row,wrap)
                v-flex(xs12 md12)
                  v-text-field(color="primary" label="Username" v-model="username", hint='Select Username to login',:persistent-hint='true',  tabindex="1"  :hide-details=false  required)
                v-flex(xs12 md12)
                  .passwordDiv()
                    v-text-field(
                      v-model="password",
                      hint="Enter New Password",
                      :persistent-hint="true"
                      :error-messages='passwordErrorMessages',
                      label="password",
                      :append-icon="passwordVisible ? 'visibility' : 'visibility_off'",
                      @click:append='() => (passwordVisible = !passwordVisible)',
                      :type="passwordVisible ? 'password' : 'text'", name='input-10-1',
                      @focus="resetPasswordErrors"
                    )

                    v-btn.my-2(v-if="password"  color="primary" @click="savePassword" small) Save New Password
                .my-4.grey--text.caption.ml-4() API Token: {{user.api_token}}
            v-flex(xs12 md6)
              v-layout(row,wrap)
                v-flex(xs12 md12)
                  v-select(:items="$store.state.settings.roleSettings.role_data",  hint='Select a role for user',:persistent-hint='true', tabindex="4", item-text="name" item-value="id"  prepend-icon="person_outline" v-model="role_id" label="Role" )

                v-flex(xs12 md12)
                  v-checkbox(v-model="is_administrator" :label="`Administrator`" :hint="is_administrator ? 'The user is an administrator':''" persistent-hint)
                v-flex(xs12 md12)
                  v-layout.ma-2(align-center)
                    v-btn(color="primary" @click="onShowQRCode") Register Mobile
                    img.mx-5(src="/static/web/qr_code.png" width="60" height="76")
                  v-dialog(v-model='mobile_registration_dialog', width='500' @click:outside="onCloseDialog")
                    mobile-register-dialog(:selectedContactId="$route.params.contactID" @closeMobileDialog="mobile_registration_dialog = false" :key="dialogKey")
          v-divider.my-4()
          v-btn.ml-0(color="primary" @click="saveUser" large)
            v-icon.mr-2() person_add
            | Save User Changes



      div(v-else)
        .caption.red--text() You need to be an administrator for permissions


</template>

<script>
// const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
import {createHelpers} from "vuex-map-fields";
import MobileRegisterDialog from "@/modules/contacts/components/ui/MobileRegisterDialog";

const {mapFields} = createHelpers({
  getterType: "users/getUserSingle",
  mutationType: "users/updateUserSingle"
});
export default {
  components: {
    MobileRegisterDialog
  },
  data: () => ({
    passwordErrorMessages: [],
    mobile_registration_dialog: false,
    dialogKey: 0,
    loadedUser: false,
    theInterval: null,

    password: null,
    passwordVisible: true,
    roleID: "00000000-0000-0000-0000-000000000000"
  }),

  computed: {
    ...mapFields(["username", "role_id", "is_administrator", "active"]),
    contact: {
      get() {
        return this.$store.getters["contacts/getCurrentContact"];
      }
    },

    user: {
      get() {
        return this.$store.state.users.user_data[0];
      }
    },

    isUser: {
      get() {
        return this.$store.state.users.user_data.length > 0 && this.user.active;
      },
      async set(value) {
        if (value == true) {
          await this.$store.dispatch("contacts/saveContact")
        }

        // if user exists then make inactive
        console.log(`change of settings ${value}`);
        this.$store.commit("users/updateUserSingle", {
          path: "active",
          value: value
        });

        // user does not exist exists
        if (this.user.id == "00000000-0000-0000-0000-000000000000") {
          this.$store.commit("users/updateUserSingle", {
            path: "contact_id",
            value: this.$route.params.contactID
          });
        }
        // save
        this.$store.dispatch("users/saveUser", this.user);
      }
    }
  },

  mounted() {
    //is the signedinUserAdministrator

    // get to know if the user is enabled

    this.$store
        .dispatch("users/fetchUserByContact", this.$route.params.contactID)
        .then(() => {
          console.log("==============================");
          console.log(this.$store.state.profile.userInfo.is_administrator);
          console.log("==============================");
          this.loadedUser = true;
          console.log("user_data");
          console.log(this.$store.state.users.user_data);
        });

    this.$store.dispatch("settings/roleSettings/fetchRoleSettings");
  },

  methods: {
    saveUser: function () {
      this.$store.dispatch("users/saveUser", this.user).then(() => {
        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `User Settings Saved`
        });
      });
    },

    resetPasswordErrors: function () {
      this.passwordErrorMessages = [];
    },

    savePassword: async function () {
      if (this.password.length < 8) {
        this.passwordErrorMessages.push("Must be greater than 8 characters")
      }

      if (this.passwordErrorMessages.length > 0) {
        return;
      }

      await this.$store.dispatch("users/saveUser", this.user)
      this.$http
          .post("/user/setPassword", {
            password: this.password,
            id: this.user.id
          })
          .then(() => {
            this.$store.commit("showSnackBar", {
              color: "accent",
              message: `Password Saved`
            });
          })
          .catch(error => {
            console.log(`error found: ${error}`);
          });
    },

    onGeneratePassword: function () {
      var length = 8,
          charset =
              "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
          retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.password = retVal;
      this.passwordVisible = false;
    },

    onCloseDialog: function () {
      console.log("clear interval");

      clearInterval(this.theInterval);
      this.theInterval = null;
    },

    onShowQRCode: function (value) {
      console.log(value);
      this.mobile_registration_dialog = true;
      this.dialogKey += 1;
      this.theInterval = setInterval(this.onInterval, 32000);
    },

    onInterval: function () {
      this.dialogKey += 1;
    }
  }
};
</script>

<style>
.userManagementHeader {
  display: inline-flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
}

.enableUserText {
  font-size: 120%;
  padding: 0px;
  margin: 0px;
}

.theswitch {
  width: 80px;
  margin-bottom: -20px;
}

.addBorder {
  border: 1px solid #cccccc;
}

.passwordDiv {
  border-radius: 10px;
  background-color: #eeeeee;
  padding: 10px 10px;
}
</style>
