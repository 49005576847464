<template lang="pug">
  #blankcontent()
    .panelTitle() Place Authors
    v-divider.mx-2()
    .panelContent()
      v-list-item-group()
        v-list-item.lineVehicleStatus(v-for="(item,index) in items" :key="index")
          v-list-item-content()
            .body-2(v-text="item.key")
          v-list-item-avatar( :size="28" color="#d7d7d7")
            .font-weight-bold.caption() {{item.value}}

</template>
<script>
import axios from "axios";

export default {
  props: {
    clientHeight: Number
  },

  mounted() {
    this.items = [];
    axios.get("/api/dashboard/placeAuthors").then(response =>{
      this.items = response.data;
    })
  },
  data: () => ({
    items : []
  })
};
</script>
<style scoped lang="stylus">
.lineVehicleStatus {
  border-bottom: 1px solid #f9f9f9;
  height: 50px;
}

#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 30px;
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.panelContent {
  height: calc(100% - 45px);
  overflow: auto;
}
</style>
