<template lang="pug">
  div
    v-container.my-0.pa-0(fluid)
      p.my-0 {{ schema.label }}
      v-layout.ml-0(v-bind="bindValues")
        v-checkbox.mr-3.pa-0.mt-0.mb-2(v-for="(item,index) in schema.options" :key="index" v-model="computedValue" :hide-details="true" :value="item" :label="item")
      p.caption(v-show="schema.hint != null") {{ schema.hint }}
</template>
<script>
import {inspectionFillMixin} from "@/modules/inspections/components/formDesigner/fill/inspectionFillMixin";
export default {
  mixins: [inspectionFillMixin],


  data: () => ({

  }),

  computed: {
    bindValues: function() {
      let p = {};
      if (this.schema.orientation === "row") {
        p["row"] = true;
      }

      if (this.schema.orientation === "column") {
        p["column"] = true;
      }
      return p;
    },

    computedValue: {
      get: function() {
        return this.dataValue.value == null ? [] : this.dataValue.value;
      },
      set: function(theValue) {
        if(theValue.length == 0){
          this.theValue = null;
        }else{
          this.theValue = theValue;
        }

        this.update();
      }
    },
  },


};
</script>
<style scoped lang="stylus"></style>
