<template lang="pug">
  div()
    .signPlaceholder(:style="signStyle" @click="openSignatureDialog")
      img(v-if="computedValue==null" src="/static/web/sign_placeholder.png" )
    v-dialog(v-model="dialog" width="800")
      signature-dialog(v-model="computedValue" @closeDialog="closeDialog" :title="schema.hint")
</template>
<script>

import SETTINGS from "@/modules/workflow/formBuilder/store/formSettings";
import SignatureDialog from "@/modules/global/components/dialog/SignatureDialog";

export default {
  components:{
    SignatureDialog
  },
  props: {
    schema: Object
  },
  data: ()=>({
    dialog:false
  }),
  computed:{
    signStyle:function(){
        let obj = {};
        if(this.computedValue != null){
          obj["background-image"] = 'url("' + this.computedValue + '")'
        }

        return obj;
    },
    computedValue: {
      get: function() {
        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id);
      },

      set: function(theValue) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }
  },

  methods:{
    openSignatureDialog:function(){
      this.dialog = true;
    },

    closeDialog:function(){
      this.dialog = false;
    }
  }
};
</script>
<style scoped lang="stylus">
.signPlaceholder{
  border 2px solid #CCCCCC;
  display flex;
  justify-content center;
  align-items center;
  border-radius 10px;
  background-size contain;
  background-position center;
  width 240px;
  height 120px;

}
.signPlaceholder img{
  width 130px;
  height 100px;
}

</style>
