<template lang="pug">
v-card()
  v-card-title.primary.white--text() {{title}}
  div.pa-6.grey.lighten-2()
    VueSignaturePad#signature(width="100%" height="250px" ref="signaturePad" :options="options")
  v-divider
  v-card-actions()
    v-btn(small color="grey" outlined @click="resetSignature") Clear
    v-spacer
    v-btn(small color="grey"  outlined @click="$emit('closeDialog')") Cancel
    v-btn(small color="primary" @click="saveSignature") Save
</template>

<script>
import Repository from "@/repository"
export default {
props:['value','title'],

  data:()=>({
    imagePath:null,
    options: {
      dotSize: 4.5,
      minWidth: 3,
      maxWidth: 6,
      penColor: "#c0f"
    }

  }),

  mounted() {
    this.imagePath = this.value;
    this.$nextTick(() => {
      this.$refs.signaturePad.resizeCanvas();
    })
  },

  methods:{
    saveSignature:function(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log(isEmpty);
      console.log(data);
      if(isEmpty) {
        this.$emit("closeDialog");
      }
      let base64ContentArray = data.split(",")
      let mimeType = base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0]
      let base64Data = base64ContentArray[1]
      let postObj = {
        "type":mimeType,
        "name":"upload_your_signature",
        "data": base64Data
      }
      Repository.post("/upload/blob/base64",postObj).then( response =>{
        console.log(response.data)
        this.$emit('input',response.data)
        this.$emit("closeDialog")
      })


    },

    resetSignature:function(){
      this.$refs.signaturePad.clearSignature();
      this.$emit('input',null);
      this.$emit("closeDialog")
    },
    onBegin() {
      console.log('=== Begin ===');
    },
    onEnd() {
      console.log('=== End ===');
    }



  }
}
</script>

<style scoped>
#signature {
  border: 1px solid #CCC;
  border-radius: 10px;
  background-color: #FFFFFF;
}
</style>
