import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";
import ShowAdvancedProperties from "@/modules/inspections/components/formDesigner/component/ShowAdvancedProperties";

export const inspectionPropertiesMixin = {
    components: {
        ShowAdvancedProperties
    },
    data: () => ({
        properties: {},
        showAdvanced: false
    }),
    computed: {
        selectDefault: {
            get: function () {
                return this.properties.default.value;
            },

            set: function (theDefault) {
                console.log(theDefault)
                let tempDefault = JSON.parse(JSON.stringify(this.properties.default))
                tempDefault.value = theDefault
                // print("=================")
                // print( this.properties.default.value)
                // print(value)
                this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
                    field: "default",
                    value: tempDefault
                });
            }
        },


        selectedFieldName: {
            get: function () {
                return this.properties.label;
            },
            set: function (value) {
                this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
                    field: "label",
                    value: value
                });
                this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
                    field: "id",
                    value: this._.snakeCase(value)
                });
            }
        },

        selectHint: {
            get: function () {
                return this.properties.hint;
            },
            set: function (value) {
                this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
                    field: "hint",
                    value: value
                });
            }
        },

        selectID: {
            get: function () {
                return this.$store.getters[SETTINGS_DEFAULT.GET_PATH].id;
            },
            set: function (value) {
                this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
                    field: "id",
                    value: this._.snakeCase(value)
                });
            }
        },

        selectRequired: {
            get: function () {
                return this.properties.required;
            },

            set: function (value) {
                this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
                    field: "required",
                    value: value
                });
            }
        },

        selectCommentOnFail: {
            get: function () {
                return this.properties.comment_on_fail;
            },

            set: function (value) {
                this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
                    field: "comment_on_fail",
                    value: value
                });
            }
        },

        selectType: {
            get: function() {
                return this.properties.type;
            },

            set: function(value) {
                this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
                    field: "type",
                    value: value
                });
            }
        },
    },

    mounted() {
        this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
        this.$refs.fieldName.focus();
    }


}