<template lang="pug">
  div
    .d-flex.align-startcenter()
      v-textarea(
        v-model="computedValue"
        :label="schema.label"
        :hint="schema.hint"
        :persistent-hint="schema.hint != null"
        :counter="schema.maxlength != null"
        :maxlength="schema.maxlength"
        :rules="[requiredRule]"
        :auto-grow="schema.auto_grow")
      .d-flex.flex-column
        add-comment-button.my-1.ml-1(v-model="comments" @showCommentInput="onShowCommentInput")
        upload-blob.my-1.ml-1(v-model="blobs" type="image" label="Label" hint="hint" :isMultiple="true")
    .d-flex()
      v-tooltip(bottom v-for="(item,index) in comments" :key="item.time")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") message
        span() {{item.value}}
      .mx-2()
      v-tooltip(bottom v-for="(item,index) in blobs" :key="item")
        template(v-slot:activator="{ on, attrs }")
          v-icon.mr-1(color="grey lighten-1" dark v-bind="attrs" v-on="on") image
        div()
          image-thumbnail(:width='360', :height='200', :src="item")

    div.mt-2(v-if="showCommentInput")
      v-textarea(outlined  ref="comment"  label="Comment" auto-grow rows="2" v-model="comment" hide-details )
      .d-flex.my-2()
        v-spacer
        v-btn.mx-2(small color='grey darken-1', text, @click.native="cancel()") Close
        v-btn.mx-2(small color='primary', @click.native="save()") Save

</template>
<script>
import {inspectionFillMixin} from "@/modules/inspections/components/formDesigner/fill/inspectionFillMixin";
export default {
  mixins: [inspectionFillMixin],
  data: () => ({}),

  computed: {


    computedValue: {
      get: function() {
        return this.dataValue.value;
      },

      set: function(theValue) {
        this.theValue = theValue;
        this.update();
      }
    }
  },

  mounted() {}
};
</script>
<style scoped lang="stylus"></style>
