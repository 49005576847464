import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const GROUP_API = "/group";

// ----------------------- STATE ------------------------- //
const state = {
    group_data: [],
    currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getGroup: state => getField(state.group_data[state.currentIndex]),

    getCurrentGroup: state => {
        return state.group_data[state.currentIndex];
    },

    getData: state => state.group_data,

    getGroupByID: (state) => (id) => {
        let result = state.group_data.find((element) => element.id == id)
        if (result != undefined){
            return result;
        }else{
            return null
        }
    },

    getGroupsMinusSelf: state => {
        let myArray = [
            {
                id: "00000000-0000-0000-0000-000000000000",
                name: "None"
            }
        ];
        for (var i = 0; i < state.group_data.length; i++) {
            if (state.group_data[state.currentIndex].id != state.group_data[i].id) {
                myArray.push(state.group_data[i]);
            }
        }

        return myArray;
    },



    getTreeList: state => {
        // recursively search for parents
        let myArray = [];
        for (var i = 0; i < state.group_data.length; i++) {
            let theChildren = [];
            for (var j = 0; j < state.group_data.length; j++) {
                if (state.group_data[i].id != state.group_data[j].id) {
                    if (state.group_data[i].id == state.group_data[j].parent) {
                        if (state.group_data[j].stale != true) {
                            console.log(
                                `pushed ${state.group_data[j].id} into ${state.group_data[i].id}`
                            );
                            theChildren.push(state.group_data[j]);
                            state.group_data[j].stale = true;
                        }
                    }
                }
            }

            // add the group
            state.group_data[i].children = theChildren;
            if (
                state.group_data[i].stale != true &&
                state.group_data[i].parent == "00000000-0000-0000-0000-000000000000"
            ) {
                myArray.push(state.group_data[i]);
            }
        }

        return myArray;
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateGroup(state, payload) {
        updateField(state.group_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.group_data.findIndex(group => group.id == theID);
    },

    loadAllGroups(state, payload) {
        state.group_data = payload.data;
        state.currentIndex = 0;
    },

    addGroup(state, payload) {
        state.group_data.push(payload.data);
        state.currentIndex = state.group_data.length - 1;
    },

    saveGroup(state, payload) {
        state.group_data[state.currentIndex] = payload.data;
    },

    setName(state, payload){
        state.group_data[state.currentIndex].name = payload
    },

    fetchGroup(state, payload) {
        if (state.currentIndex == -1) {
            state.group_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.group_data[state.currentIndex] = payload.data;
        }
    },

    deleteGroup(state, payload) {
        state.currentIndex = state.group_data.findIndex(
            group => group.id == payload.data.id
        );
        state.group_data.splice(state.currentIndex, 1);
    },

    reset(state) {
        state.group_data = []
        state.currentIndex = -1
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllGroups({commit}) {
        try {
            commit("loadAllGroups", await Repository.get(`${GROUP_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addGroup({commit}) {
        await Repository.get(`${GROUP_API}/new`)
            .then(response => {
                commit("addGroup", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveGroup({state,commit}) {
        try {
            commit("saveGroup", await Repository.post(`${GROUP_API}`, state.group_data[state.currentIndex]));
        } catch (error) {
            console.log(error);
        }
    },

    async fetchGroup({commit}, payloadID) {
        try {
            commit("fetchGroup", await Repository.get(`${GROUP_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteGroup({commit}, payloadID) {
        try {
            commit(
                "deleteGroup",
                await Repository.delete(`${GROUP_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
