<template lang="pug">
  div() BlankFillForm Schema id: {{schema.id}} value: {{schema.value}} datavalue: {{dataValue}}
</template>
<script>


export default {
  props: ["schema", "dataValue", "index","disabled"],

  data: () => ({
  }),
  computed:{
  },
  methods:{
  }
};
</script>
<style scoped lang="stylus">
.signPlaceholder{
  border 2px solid #CCCCCC;
  display flex;
  justify-content center;
  align-items center;
  border-radius 10px;
  background-size contain;
  background-position center;
  width 240px;
  height 120px;

}
.signPlaceholder img{
  width 130px;
  height 100px;
}
</style>
