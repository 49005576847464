<template lang="pug">
  div(v-if="item != null")
    v-divider()
    //- v-toolbar(dense flat)
      generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer()
      //- v-btn(color="primary" small) Some Button
    v-divider()
    v-container.pa-8.white(fluid)

      v-row()
        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:title)
              | Item Details
            template(v-slot:body)
              div.mt-2.mb-2.pb-2
                table.DetailTable.body-2.fill-width
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3() Item
                    td
                      .d-flex.py-2()
                        div(v-if="item.thumbnail_id")
                          image-thumbnail.mr-2(:src="item.thumbnail_id" :height="60" :width="80")
                        .d-flex.flex-column.justify-center()
                          .subtitle-1.primary--text {{ item.name }}
                          div.grey--text.descriptionText(v-if="item.description") {{item.description}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Type
                    td {{getInventory(item.inventory_type)}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 supplier
                    td
                      div(v-if="item.merchant")
                        div() {{item.merchant.name}}
                        .caption() {{item.merchant.description}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Supplier Reference No
                    td {{item.merchant_reference}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Measurement Unit
                    td()
                      .mr-3(v-if="item.measurement_unit != null") {{item.measurement_unit.name}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Duration
                    td()
                      .mr-3(v-if="item.duration != null")
                        template(v-if="item.duration.Microseconds > 0")
                          .mr-3  {{Math.round(item.duration.Microseconds/3600000000)}} Hours &nbsp;
                        template(v-if="item.duration.Days> 0")
                          .mr-3  {{item.duration.Days}} Days &nbsp;
                        template(v-if="item.duration.Months> 0")
                          .mr-3  {{item.duration.Months}} Months &nbsp;
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 UPC (Bar Code)
                    td {{item.upc}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Unit Cost
                    td {{item.unit_cost}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Item Status
                    td {{item.item_active?'active':'inactive'}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Track Item
                    td {{item.track_item?'enabled':'disabled'}}

          base-panel()
            template(v-slot:title)
              | Inventory Summary
            template(v-slot:body)
              div.mt-2.mb-2.pb-2
                table.DetailTable
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Restock Point
                    td {{item.restock_point}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Shelf Details
                    td Bin {{item.shelf_details.bin}} | Row {{item.shelf_details.row}} | Aisle {{item.shelf_details.aisle}}



        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:title)
              | Comments
            template(v-slot:body)
              div() the comments here

          base-panel()
            template(v-slot:title)
              | Photos


            template(v-slot:body)
              attach-fill-form( :schema="imagesSchama" :data="{value:item.photos_id}"  hideButton )

          base-panel()
            template(v-slot:title)
              | Documents

            template(v-slot:body)
              attach-fill-form(:schema="documentsSchama" :data="{value:item.documents_id}" hideButton )


</template>

<script>
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import BasePanel from "@/modules/global/components/panel/basePanel";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";

export default {
  components: {
    GenericBreadCrumbs,
    BasePanel,
    ImageThumbnail,
    AttachFillForm,
  },
  data: () => ({
    breadCrumbsItems: [
      {
        text: "Items",
        disabled: false,
        path: "items"
      },
      {
        text: "Item Detail",
        disabled: true
      }
    ],
    imagesSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Fuel Images",
      hint: null,
      type: "image",
      image_size: "small",
      multiple: true
    },
    documentsSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Fuel Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true
    }
  }),

  computed: {
    item: function () {
      return this.$store.getters["inventory/item/getCurrentItem"];
    }
  },
  methods: {
    getInventory: function (inventoryType) {
      let result = "Vehicle Part";
      switch (inventoryType) {
        case "vehicle_part":
          result = "Vehicle Part";
          break;
        case "equipment":
          result = "Equipment";
          break;
        case "Consumable":
          result = "Consumable";
          break;
      }
      return result;
    },
    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Item List",
        disabled: false,
        to: {name: "inventorys"},
      });
      theArray.push({
        text: "Item Entry",
        disabled: true,
      });
      return theArray;
    },
  },

  mounted() {
    if (this.$store.state.inventory.item.currentIndex == -1) {
      this.$store.dispatch("inventory/item/fetchItem", this.$route.params.itemID);
    }
  }
}
</script>

<style lang="stylus" scoped>
.DetailTable {

  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;

  tr {
    margin: 0;
    padding: 0;
  }

  td {
    border-bottom: 1px solid #EEEEEE;
    margin: 0;
    padding: 0;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 8px;
  }
}
</style>