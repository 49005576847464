<template lang="pug">
  v-layout(justify-center align-start column)
    span.grey--text {{$store.state.profile.userInfo.account.region_settings.currency}}
    input.baseStyle(type='number',   :value='theValue', @input="theValue = $event.target.value" :disabled="getDisabledStatus" :style="[getDisabledStatus ?  disabledStyle: isFocused ? focusStyle : ''  ]" @focus="isFocused=true" @blur="isFocused=false")
</template>
<script>
export default {
  props: {
    id: String,
    field: String,
    getPath: String,
    setPath: String
  },

  data: () => ({
    isFocused: false
  }),

  computed: {
    theValue: {
      get: function() {
        return this.$store.getters[this.getPath](this.id)[this.field];
      },

      set: function(newValue) {
        this.$store.commit(this.setPath, {
          path: this.field,
          value: newValue,
          id: this.id
        });
        this.$store.commit("service/calculateSubTotals");
      }
    },

    getDisabledStatus() {
      return this.$store.getters[this.getPath](this.id)[
        this.field + "Disabled"
      ];
    },

    focusStyle: function() {
      return {
        "border-color": this.$vuetify.theme.primary
      };
    },

    disabledStyle: function() {
      return {
        "border-color": "#CCCCCC",
        "background-color": "#DEDEDE"
      };
    }
  },

  mounted() {
    console.log();
  }
};
</script>
<style scoped lang="stylus">

.baseStyle {
  border-width: 1px;
  border-style: solid;
  border-color: #999999;
  border-radius: 5px;
  min-width: 100px;
  max-width: 200px;
  height: 34px;
  text-align: right;
  padding: 6px 4px 6px 12px;
  background-color: none;
}
</style>
