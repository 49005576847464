<template lang="pug">
  div(v-if="theData")
    bar-chart(style="height:140px" :chartdata="chartData" :options="chartOption" :key="keyCounter")
</template>
<script>

import BarChart from "@/modules/global/components/charts/BarChart";


export default {
  components: {
    BarChart
  },
  props: {
    theData: {
      type: Array,
      default: null,
    }
  },
  computed: {},
  data: () => ({
    isLoaded: false,
    keyCounter: 0,
    isMonths: false,
    chartOption: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
              beginAtZero: true,
            },

          }
        ],
        xAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      },
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 10,
          bottom: 0
        }
      }
    },
    chartData: {
      labels: [
        'Unplug',
        'Idling',
        'Braking',
        'Cornering',
        'Acceleration',
        'Overspeeding'
      ],
      datasets: [
        {
          label: 'Violations',
          backgroundColor: '#63c5c7',
          data: [0, 0, 0, 0, 0,0]
        }
      ]
    },
  }),
  methods: {

    loadData: async function () {
      this.isLoaded = false;

      let values = [];

      this.theData.forEach(item => {
        values.push(item.value)
      })

      console.log("values--------------")
      console.log(values)
      // this.chartData.labels = keys;
      this.chartData.datasets[0].data = values;
      this.isLoaded = true;
      this.keyCounter +=1;
    },




  },


  mounted() {
    this.loadData();
  }
}
</script>


<style scoped lang="stylus">

</style>