<template lang="pug">
    v-card()
      v-card-title.px-8.grey.lighten-2() Add Comment
      v-card-text()
        v-container.my-2()
          v-text-field(v-model='title', label='Title')
          v-textarea(v-model='comment', label='Comment')
      v-divider()
      v-card-actions()
        v-layout(justify-end)
          v-btn.ma-2(text @click="closeDialog()") Cancel
          v-btn.ma-2(color="primary" @click="sendComment()" :disabled="comment == null") Send
</template>
<script>
export default {
  props: {
    refrenceType: String,
    refrenceID: String
  },
  data: () => ({
    // data to be placed in the store
    title: null,
    comment: null
  }),
  methods: {
    sendComment: function() {
      this.$store
        .dispatch("comments/addComment", {
          reference_id: this.refrenceID,
          reference_type: this.refrenceType,
          title: this.title,
          comment: this.comment
        })
        .then(() => {
          console.log("yesss it has worked");
        });
      this.title = null;
      this.comment = null;
      this.closeDialog();
    },

    closeDialog: function() {
      this.$emit("close");
      this.title = null;
      this.comment = null;
    }
  },
  mounted() {
    this.title = null;
    this.comment = null;
  }
};
</script>
<style scoped lang="stylus"></style>
