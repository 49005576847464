import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const DATATABLE_API = "/workflowDataTable";

// ----------------------- STATE ------------------------- //
const state = {
  datatable_data: [],
  currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getDataTable: state => getField(state.datatable_data[state.currentIndex]),

  getCurrentDataTable: state => {
    return state.datatable_data[state.currentIndex];
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updateDataTable(state, payload) {
    updateField(state.datatable_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.datatable_data.findIndex(
      datatable => datatable.id == theID
    );
  },

  loadAllDataTables(state, payload) {
    state.datatable_data = payload.data;
    state.currentIndex = 0;
  },

  addDataTable(state, payload) {
    state.datatable_data.push(payload.data);
    state.currentIndex = state.datatable_data.length - 1;
  },

  saveDataTable(state, payload) {
    state.datatable_data[state.currentIndex] = payload.data;
  },

  fetchDataTable(state, payload) {
    if (state.currentIndex == -1) {
      state.datatable_data.push(payload.data);
      state.currentIndex = 0;
    } else {
      state.datatable_data[state.currentIndex] = payload.data;
    }
  },

  deleteDataTable(state, payload) {
    state.currentIndex = state.datatable_data.findIndex(
      datatable => datatable.id == payload.data.id
    );
    state.datatable_data.splice(state.currentIndex, 1);
  },
  reset(state) {
    state.datatable_data = []
    state.currentIndex = -1
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadAllDataTables({ commit }, payload) {
    try {
      commit(
        "loadAllDataTables",
        await Repository.get(`${DATATABLE_API}?workflowID=${payload}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async addDataTable({ commit }) {
    await Repository.get(`${DATATABLE_API}/new`)
      .then(response => {
        commit("addDataTable", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async updateTableColumns({ commit }, payload) {
    await Repository.post(`${DATATABLE_API}/updateHeaderType`, payload)
      .then(response => {
        commit("addDataTable", response);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async saveDataTable({ state, commit }) {
    try {
      commit(
        "saveDataTable",
        await Repository.post(
          `${DATATABLE_API}`,
          state.datatable_data[state.currentIndex]
        )
      );
    } catch (error) {
      console.log(error);
    }
  },

  async fetchDataTable({ commit }, payloadID) {
    try {
      commit(
        "fetchDataTable",
        await Repository.get(`${DATATABLE_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async deleteDataTable({ commit }, payloadID) {
    try {
      commit(
        "deleteDataTable",
        await Repository.delete(`${DATATABLE_API}/${payloadID}`)
      );
    } catch (error) {
      console.log(error);
    }
  },

  async searchDataTables({ commit }, searchTerm) {
    try {
      commit(
        "loadAllDataTables",
        await Repository.get(`${DATATABLE_API}/search?q=${searchTerm}`)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
