<template lang="pug">
  .fill-width()
    v-tabs.mx-3.tabs.fill-width(left height='32' slider-color='primary' v-model="index")
      v-tab.tabs
        | Basic Dashboard
      v-tab-item.fill-tab.pr-6
        basic-workflow-layout()

      v-tab.tabs
        | Custom Dashboard A
      v-tab-item.fill-tab
        div() custom dashboard A

      v-tab.tabs
        | Custom Dashboard B
      v-tab-item.fill-tab
        div() custom dashboard B

    .workflowDashboardMenu
      .d-flex()
        v-menu(offset-y)
          template( v-slot:activator="{ on, attrs }")
            v-btn(v-bind="attrs"  v-on="on" small icon)
              v-icon(color="primary") expand_more
          v-list(dense)
            v-list-item-group()
              v-list-item(@click="save")
                v-list-item-icon()
                  v-icon(:size="20") save
                v-list-item-title() Save Dashboard
              v-list-item(@click="add")
                v-list-item-icon()
                  v-icon(:size="20") dashboard
                v-list-item-title() Add Custom Dashboard
              v-list-item(@click="edit")
                v-list-item-icon()
                  v-icon(:size="20")  edit
                v-list-item-title() Edit Custom Dashboard
              v-list-item(@click="remove")
                v-list-item-icon()
                  v-icon(:size="20")  delete
                v-list-item-title() Remove Custom Dashboard

</template>

<script>
import BasicWorkflowLayout from "@/modules/workflow/overview/DashboardLayout/BasicWorkflowLayout";
export default {
  components:{
    BasicWorkflowLayout,
  },
  data: ()=>({
    index:0,
  }),
  methods: {
    save() {

    },
    add(){

    },
    edit(){

    },
    remove(){

    }
  },
}
</script>

<style scoped lang="stylus">
.workflowDashboardMain{
  position: relative;
}
.workflowDashboardMenu{
  position: absolute;
  top: 3px;
  right: 20px;
  display: flex;
  justify-content: flex-end;

}

.workflowTabsMenu{
  width: 100%;
}

</style>