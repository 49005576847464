<template lang="pug">
div
  v-divider
  v-container(fluid)
    v-layout.my-2(row, wrap)
      h2.mx-4 Events
      v-spacer
      .text-xs-center
      v-menu(offset-y)
        template(#activator="{on}")
          v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
            | Actions
            v-icon(dark) keyboard_arrow_down
          v-list
          //- v-list-item(v-for='item in getMenuItems', :key='item.title' @click="onClick(item)" :disabled="$store.state.messages.selectedItems.length == 0")
              v-list-item-icon()
                  v-icon() {{item.icon}}
              v-list-item-title {{ item.title }}
      v-btn.ma-2(small,color="primary")
        v-icon.pr-2(dark) alarm
        | Send Notification

  v-divider
  .d-flex()
    div(style="width:800px;")
      event-timeline(style="margin-left:-200px" url="/activityEvent")



</template>

<script>
import EventTimeline from "@/modules/messaging/layouts/Events/EventTimeline.vue";
export default {
  components:{EventTimeline}
}
</script>

<style scoped lang="stylus" >

</style>