<template lang="pug">
  div.fill-width.pt-4()
    v-row.py-4()
      v-layout.mx-4.fill-width(justify-space-between)
        p.pl-4 {{ schema.label }}
        .d-flex(align-center)
          v-btn.mr-2(outlined color="primary") Reject
          v-btn.mr-2(color="primary") Approve
    v-row.mx-4()
      v-text-field(label="Comments")


    
    

</template>
<script>
export default {
  props: {
    schema: Object
  },
  data: () => ({
    column: null,
    errorMessages: []
  }),

  computed: {
    bindValues: function() {
      let p = {};
      if (this.schema.orientation === "row") {
        p["row"] = true;
      }

      if (this.schema.orientation === "column") {
        p["column"] = true;
      }
      return p;
    }
  },

  mounted() {}
};
</script>
<style scoped lang="stylus"></style>
