<template lang="pug">
  div(v-if="inspectionForm != undefined")
    v-container.mx-4(fluid)

      v-toolbar(dense, flat)
        v-toolbar-title
          .mb-2()
            .title.grey--text()
            v-breadcrumbs.ma-0.pa-0(:items="getBreadCrumbItems()" divider="/")
        v-spacer
      v-container.pa-2
        v-layout()

          v-spacer()
          .align-center()
            //v-btn.ma-2(small outlined text color="grey" @click="validate") validate
            //v-btn.ma-2(small outlined text  color="grey" @click="reset") reset
            v-btn.ma-2(small text color="grey" @click="cancel") cancel
            //v-btn.ma-2(small color="primary" @click="saveAndAdd") Save and Add New
            v-btn.ma-2(small color="primary" @click="save" :disabled="validate") Save
    v-container()

      v-container.pa-2
        v-card()
          v-container.grid-list-md()
            v-form(ref="form")
            .d-flex.justify-space-between()
              div.pa-3()
                .headline() {{getCurrentRecord.inspection_form_name}}
                .subtitle-2() Here is the description
              div()
                vehicle-auto-complete(v-model='vehicle_id' label="Select Vehicle*" placeholder="Vehicle with Issue")
            v-divider()
            record-renderer(v-if="getRecordSchema()" )
</template>

<script>
import RecordRenderer from "@/modules/inspections/components/formDesigner/RecordRenderer";
import VehicleAutoComplete from "@/modules/global/components/forms/VehicleAutoComplete";
import {createHelpers} from "vuex-map-fields";

const {mapFields} = createHelpers({
  getterType: "inspection/getInspection",
  mutationType: "inspection/updateInspection"
});
export default {

  components: {
    RecordRenderer,
    VehicleAutoComplete
  },
  data: () => ({
    inspectionForm: undefined,
    startTime: null,
  }),
  mounted() {
    if (this.$store.state.inspection.currentIndex == -1) {
      this.$store.dispatch("inspection/fetchInspection", this.$route.params.inspectionID).then(() => {
        this.getInspectionForm();
      })
    } else {
      this.getInspectionForm();
    }
    this.startTime = new Date().getTime();
  },
  computed: {
    ...mapFields([
      "duration",
      "submitted_on",
      "vehicle_id",
      "submitted_by_id",
      "inspection_form_id",
      "account_id",
      "data"

    ]),
    getCurrentRecord: function () {
      let currentRecord = this.$store.getters["inspection/getCurrentInspection"];
      currentRecord = this._.clone(currentRecord)
      return currentRecord;
    },

    validate: function () {
      let isInvalid = false;
      if (this.vehicle_id == null) {
        isInvalid = true;
      }

      if(this.getCurrentRecord.data != null){
        Object.values(this.getCurrentRecord.data).forEach(element => {
          if(element.valid != null && element.valid == false){
            isInvalid = true;
          }
        })
      }else{
        isInvalid = true;
      }


      return isInvalid;
    },
  },


  methods: {
    getInspectionForm: function () {
      this.inspectionForm = this.$store.getters["inspection/inspectionForm/getInspectionFormByID"](this.getCurrentRecord.inspection_form_id)
      if (this.inspectionForm == undefined) {
        this.$store.dispatch("inspection/inspectionForm/fetchInspectionForm", this.getCurrentRecord.inspection_form_id).then(() => {
          this.inspectionForm = this.$store.getters["inspection/inspectionForm/getInspectionFormByID"](this.getCurrentRecord.inspection_form_id)
        })
      }
    },

    cancel: function () {
      // if (this.$router.history.current.path == this.$router.history._startLocation) {
      this.$store.commit("inspection/formDesigner/resetFormBuilder")
      this.$router.push({name: "inspections", params: {tabID: 0}}).catch(()=>{})
      // } else {
      //   this.$router.back();
      // }
    },

    design: function () {
    },

    save: function () {
      // update the date of submission
      this.submitted_on = new Date().toISOString();
      if (this.duration.Microseconds == 0) {
        let theDuration = this._.clone(this.duration)
        theDuration.Microseconds = (new Date().getTime() - this.startTime) * 1000;
        this.duration = theDuration;
      }

      console.log("8888888888888888")
      console.log(this.data);
      // save becaule data exists
      if(this.data != null){

        this.$store.dispatch("inspection/saveInspection").then(() => {
          this.$store.commit("showSnackBar", {
            color: "accent",
            message: `Form ${this.inspectionForm.name} is saved`
          });
          this.cancel();
        });
      }else{
        this.$store.commit("showSnackBar", {
          color: "error",
          message: `No data found on document`
        });
      }

    },

    saveAndAdd: function () {
    },


    reset: function () {

    },

    getComponent: function (propertyName) {
      // Ensure the Renderer is Registered. the Registered Component has an appended `FillForm`
      return `${propertyName}FillForm`;
    },

    getRecordSchema: function () {
      // console.log("asdf")
      // console.log(this.$store.getters["inspection/getCurrentInspection"].inspection_form_version)
      // console.log(this.$store.getters["inspection/inspectionForm/getCurrentInspectionForm"])
      // console.log(this.inspectionForm.data[this.$store.getters["inspection/getCurrentInspection"].inspection_form_version - 1].schema)
      return this.inspectionForm.data[this.$store.getters["inspection/getCurrentInspection"].inspection_form_version - 1].schema;
    },

    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "inspection",
        disabled: false,
        to: {name: "inspections", params: {tabID: 1}}
      });
      theArray.push({
        text: this.getCurrentRecord.inspection_form_name,
        disabled: true
      });
      return theArray;
    }
  }
}
</script>

<style scoped>

</style>