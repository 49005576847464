import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const SERVICETYPE_API = "/serviceType";

// ----------------------- STATE ------------------------- //
const state = {
    servicetype_data: [],
    currentIndex: -1
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getServiceType: state => getField(state.servicetype_data[state.currentIndex]),

    getCurrentServiceType: state => {
        return state.servicetype_data[state.currentIndex];
    },


    getServiceTypeFromID: state => theID => {
        let theIndex = state.servicetype_data.findIndex(
            servicetype => servicetype.id == theID
        );
        return state.servicetype_data[theIndex];
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateServiceType(state, payload) {
        updateField(state.servicetype_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.servicetype_data.findIndex(
            servicetype => servicetype.id == theID
        );
    },

    loadAllServiceTypes(state, payload) {
        state.servicetype_data = payload.data;
        state.currentIndex = 0;
    },

    addServiceType(state, payload) {
        state.servicetype_data.push(payload.data);
        state.currentIndex = state.servicetype_data.length - 1;
    },

    saveServiceType(state, payload) {
        state.servicetype_data[state.currentIndex] = payload.data;
    },

    fetchServiceType(state, payload) {
        if (state.currentIndex == -1) {
            state.servicetype_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.servicetype_data[state.currentIndex] = payload.data;
        }
    },

    deleteServiceType(state, payload) {
        state.currentIndex = state.servicetype_data.findIndex(
            servicetype => servicetype.id == payload.data.id
        );
        state.servicetype_data.splice(state.currentIndex, 1);
    },
    reset(state) {
        state.servicetype_data = []
        state.currentIndex = -1
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllServiceTypes({commit}) {
        try {
            commit("loadAllServiceTypes", await Repository.get(`${SERVICETYPE_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addServiceType({commit}) {
        await Repository.get(`${SERVICETYPE_API}/new`)
            .then(response => {
                commit("addServiceType", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveServiceType({state, commit}) {
        try {
            commit(
                "saveServiceType",
                await Repository.post(
                    `${SERVICETYPE_API}`,
                    state.servicetype_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchServiceType({commit}, payloadID) {
        try {
            commit(
                "fetchServiceType",
                await Repository.get(`${SERVICETYPE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteServiceType({commit}, payloadID) {
        try {
            commit(
                "deleteServiceType",
                await Repository.delete(`${SERVICETYPE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchServiceTypes({commit}, searchTerm) {
        try {
            commit(
                "loadAllServiceTypes",
                await Repository.get(`${SERVICETYPE_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
