<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    v-text-field(v-model="selectHint" label="Description")
    v-select(v-model="selectedFormat" label="Date Format" :items="dateFormatItems" :hint="computeDateFormat()" :persistent-hint="true" )
    v-checkbox(v-model="selectRequired" label="Required")
    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        showAdvancedProperties(:showAdvanced="showAdvanced")
      div(v-show="showAdvanced")
        v-checkbox(v-model="selectCommentOnFail" label="Must comment when no date is given")
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)
</template>
<script>
import moment from "moment";
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";
import {inspectionPropertiesMixin} from "@/modules/inspections/components/formDesigner/properties/inspectionPropertiesMixin";
export default {
  mixins:[inspectionPropertiesMixin],

  data: () => ({
    dateFormatItems: [
      "D/MM/YYYY",
      "MM/D/YYYY",
      "D-MM-YYYY",
      "Do MMM YYYY",
      "Do MMMM YYYY",
      "YY/MM/DD",
      "YY-MM-DD",
      "MMMM D, YYYY",
      "MMMM Do, YYYY"
    ]
  }),

  computed: {

    selectedFormat: {
      get: function() {
        return this.properties.date_format;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "date_format",
          value: value
        });
      }
    },


  },

  methods: {
    computeDateFormat: function() {
      //return "haleluyah"
      return "example: " + moment(new Date()).format(this.selectedFormat);
    }
  },
};
</script>
<style scoped lang="stylus">
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}
</style>
