<template lang="pug">
  div(v-if="$store.state.contacts.currentIndex > -1")
    v-container.pt-4(grid-list-xl)
      v-layout(row wrap)
        v-flex(xs12 md6 xl6)
          v-card.my-3(color="white")
            overview-information-panel(:items="basicInfo.items", :title="basicInfo.title", @edit="onEdit('contactNewBasic')" showEditButton)
          v-card.my-3( color="white")
            overview-information-panel(:items="customFieldItems", title="Journey Approvals", @edit="onEdit('contactNewBasic')" showEditButton)
          v-card.my-3(color="white")
            overview-information-panel(:items="contactInfo.items", :title="contactInfo.title", @edit="onEdit('contactNewInfo')" showEditButton)
          v-card.my-3(color="white")
            overview-information-panel(:items="personalInfo.items", :title="personalInfo.title", @edit="onEdit('contactNewPersonal')" showEditButton)
        v-flex(xs6)
          v-card.my-3(color="white")
            contact-assigned-vehicles
          v-card.my-3(color="white")
            contact-assigned-issues()
          v-card.my-3(color="white")
            contact-assigned-work-orders()
          v-card.my-3(color="white")
            contact-renewal-reminders()

</template>
<script>
import OverviewInformationPanel from "@/modules/global/components/others/OverviewInformationPanel";
import ContactAssignedVehicles from "@/modules/contacts/components/ui/overview/ContactAssignedVehicles";
import ContactAssignedIssues from "@/modules/contacts/components/ui/overview/ContactAssignedIssues";
import ContactAssignedWorkOrders from "@/modules/contacts/components/ui/overview/ContactAssignedWorkOrders";
import ContactRenewalReminders from "@/modules/contacts/components/ui/overview/ContactRenewalReminders";
import Repository from "@/repository";

export default {
  components: {
    OverviewInformationPanel,
    ContactAssignedVehicles,
    ContactAssignedIssues,
    ContactAssignedWorkOrders,
    ContactRenewalReminders
  },

  data: () => ({
    customFieldItems: []
  }),

  computed: {

    contact: {
      get() {
        return this.$store.getters["contacts/getCurrentContact"];
      }
    },

    basicInfo: function () {
      return {
        title: "Basic Information",
        items: [
          {label: "First Name", value: this.contact.first_name},
          {label: "Middle Name", value: this.contact.middle_name},
          {label: "Last Name", value: this.contact.last_name},
          {label: "Group", value: this.contact.group != null ? this.contact.group.name : ''},
          {label: "Email", value: this.contact.email},
          {label: "Mobile", value: this.contact.mobile},
          {label: "Operator", value: this.contact.operator},
          {label: "Technician", value: this.contact.technician},
          {label: "Employee", value: this.contact.employee}
        ]
      };
    },

    getJourneyApprovalCustomFields: function () {
      return this.$store.getters["profile/getCustomFields"]("journey_approval");
    },


    contactInfo: function () {
      console.log(this.contact);
      return {
        title: "Contact Information",
        items: [
          {
            label: "Official Phone",
            value: `${this.contact.phone} | ${this.contact.phone_type}`
          },
          {label: "Street Address", value: this.contact.street_address},
          {label: "Address", value: this.contact.address},
          {label: "Postal Code", value: this.contact.postal_code},
          {label: "City", value: this.contact.city},
          {label: "Country", value: this.contact.country}
        ]
      };
    },

    personalInfo: function () {
      console.log(this.contact.job_title);
      return {
        title: "Personal Information",
        items: [
          {label: "Job Title", value: this.contact.job_title},
          {label: "Date of Birth", value: this.contact.date_of_birth},
          {label: "Employee Number", value: this.contact.employee_number},
          {label: "Start Date", value: this.contact.start_date},
          {label: "End Date", value: this.contact.end_date},
          {
            label: "License Number",
            value: this.contact.license_no
          },
          {label: "License Class", value: this.contact.license_class},
          {label: "Labour Rate", value: this.contact.labour_rate}
        ]
      };
    }
  },

  methods: {

    getJourneyApprovalCustomFieldsContacts: function () {

      this.customFieldItems = [];
      // check if Details Esists
      if (this.contact.details != null) {
        this.getJourneyApprovalCustomFields.forEach(element => {

          // check if the label element exists
          if (element.label_id in this.contact.details && this.contact.details[element.label_id] != null) {
            // fetch the contact Data
            Repository.get(`/contact/${this.contact.details[element.label_id]}`).then((response) => {
              this.customFieldItems.push({label: element.label, value: response.data.full_name})
            })
          } else {
            // insert empty entry
            this.customFieldItems.push({label: element.label, value: null})
          }
        })
      } else {
        // create empty label list if it doe not exist
        this.getJourneyApprovalCustomFields.forEach(element => {
          this.customFieldItems.push({label: element.label, value: null})
        })
      }


    },
    async getContact(theId) {
      await Repository.get(`/contact/${theId}`).then((response) => {
        return response.data;
      })
      return null;
    },
    onEdit: function (routeName) {
      this.$router.push({
        name: routeName,
        params: {contactID: this.$route.params.contactID}
      }).catch(()=>{});
    }
  },

  mounted() {
    // console.log(this.$store.state.contacts.currentIndex);
    this.getJourneyApprovalCustomFieldsContacts();
  }
};
</script>
<style lang="stylus" scoped></style>
