<template lang="pug">
  #blankcontent()
    .panelTitle() Recent Comments
    v-divider.mx-2()
    .panelContent()
      template(v-if="!isLoaded")
        .d-flex.justify-center.align-center.fill-height()
          v-progress-circular(color="primary" indeterminate)
      template(v-else)
        v-list(three-line)
          template(v-for='(item, index) in $store.state.comments.comment_data')
            v-list-item.recentCommentsPanelItem(:key='item.title' @click='')
              v-list-item-avatar
                v-img(:src='"/media/"+item.account_id+"/"+item.posted_by.thumbnail.thumbnail')
              v-list-item-title()
                .d-flex.caption.mb-2()
                  span.primary--text() {{item.posted_by.first_name}} {{item.posted_by.last_name}}
                  span.ml-2.grey--text() Commented on
                  span.ml-1(v-text="item.comment_reference")
                  span.ml-1.font-weight-bold()
                    a.grey--text(href="" v-html="'#'") 
                div
                  v-list-item-subtitle.black--text.caption(v-html="item.title")
                  div.grey--text.caption(v-html="'&mdash;&nbsp;' + item.comment")

              .recentCommentsPanelCaption()
                timeago.caption.font-weight-light(:datetime="item.date_time")

</template>
<script>
export default {
  props: {
    clientHeight: Number
  },

  data: () => ({
    isLoaded: false,
    items: [
      {
        avatar: "/static/web/passport.jpg",
        name: "Ali Connors",
        type: "Fuel Entry",
        link: "#",
        date: "2019-12-07T13:46:11.395Z",
        comment:
          "I'll be in your neighborhood doing errands this weekend. Do you want to hang out? I'll be in your neighborhood doing errands this weekend. Do you want to hang out? I'll be in your neighborhood doing errands this weekend. Do you want to hang out?"
      },
      {
        avatar: "/static/web/passport2.jpg",
        name: "Alex, Scott",
        type: "Service Reminder Entry",
        link: "#",
        date: "2019-12-02T09:46:11.395Z",
        comment: "Wish I could come, but I'm out of town this weekend."
      },
      {
        avatar: "/static/web/passport3.jpg",
        name: "Sandra Adamss",
        type: "Inspection Entry",
        link: "#",
        date: "2019-12-07T00:46:11.395Z",
        comment: "Do you have Paris recommendations? Have you ever been?"
      },
      {
        avatar: "/static/web/passport.jpg",
        name: "Trevor Hansen",
        type: "Service Reminder Entry",
        link: "#",
        date: "2019-12-06T13:46:11.395Z",
        comment:
          "Have any ideas about what we should get Heidi for her birthday?"
      },
      {
        avatar: "/static/web/passport2.jpg",
        name: "Britta Holt",
        type: "Assignment Entry",
        link: "#",
        date: "2019-12-03T02:46:11.395Z",
        comment: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos."
      },
      {
        avatar: "/static/web/passport3.jpg",
        name: "Britta Holt",
        type: "Assignment Entry",
        link: "#",
        date: "2019-12-03T02:46:11.395Z",
        comment: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos."
      },
      {
        avatar: "/static/web/passport.jpg",
        name: "Britta Holt",
        type: "Assignment Entry",
        link: "#",
        date: "2019-12-03T02:46:11.395Z",
        comment: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos."
      }
    ]
  }),
  mounted() {
    this.isLoaded = false;
    this.$store.dispatch("comments/loadAllComments").then(() => {
      this.isLoaded = true;
      console.log(this.$store.state.comments.comment_data);
    });
  }
};
</script>
<style scoped lang="stylus">
.recentCommentsPanelItem {
  border-bottom: 1px solid #ececec;
}

.recentCommentsPanelCaption {
  max-width: 100px;
  padding-left: 8px;
  text-align: right;
}

.lineVehicleStatus {
  border-bottom: 1px solid #f9f9f9;
  height: 50px;
}

#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 30px;
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.panelContent {
  height: calc(100% - 45px);
  overflow: auto;
}
</style>
