<template lang="pug">
  .d-flex.fill-width.justify-space-between.align-center()
      div()
        div() {{ schema.label }}
        .caption.grey--text() {{ schema.hint }}

      v-radio-group(
        v-model="result"
        row
        :error-messages="errorMessages"
        :mandatory="false"
      )
        v-radio( :label="schema.options[0]" :value="schema.options[0]")
        v-radio( :label="schema.options[1]" :value="schema.options[1]")
</template>
<script>

import SETTINGS from "@/modules/inspections/store/formSettings";


export default {

  props: {
    schema: Object
  },
  data: ()=>({
    result: null,
    errorMessages: []
  }),
  computed:{
    computedValue: {
      get: function() {
        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id) || this.schema.default;
      },
      set: function() {

      }
    }
  },

  methods:{

  }
};
</script>
<style scoped lang="stylus">

</style>
