<template lang="pug">
  div
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        div()
          h2.mx-6 Contact Report
          generic-bread-crumbs.py-1(:items="breadCrumbsItems")
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Export
                v-icon(dark) keyboard_arrow_down
            v-list-item-group()
              v-list-item(v-for='item in items', :key='item.title' dense @click="onExport(item.value)")
                v-list-item-title
                  v-layout(align-center)
                    v-icon.mr-2(:size="20") {{item.icon}}
                    .subtitle-2.mx-2() {{ item.title }}
        v-btn.ma-2.mr-5(small, color='primary', dark, @click='print')
          v-icon.mr-2() print
          | Print
    v-divider
    div.mx-4.my-2()
      vehicle-filter-dialog()
      v-menu(:close-on-content-click="false")
        template(v-slot:activator="{on}")
          v-btn.mx-2(outlined color="primary" v-on="on")
            | Columns
            v-icon(dark) keyboard_arrow_down
        .reportHeaderList()
          v-list-item-group()
            v-list(dense)
              v-list-item(v-for="(item, index) in headers" :key="index" )
                template(v-slot:default="{active}")
                  v-list-item-action()
                    v-checkbox(v-model="item.visible" color="primary")
                  v-list-item-content(@click="item.visible=!item.visible")
                    v-list-item-title.body-2() {{item.text}}

    v-divider
    .grey.lighten-4.py-3
    v-divider
    div(v-if="$store.state.contacts.contact_data.length>0")
      v-data-table.elevation-1(:headers='getHeaders', :items='$store.state.contacts.contact_data',item-key="id")
        template(v-slot:item.status="{item}")
          v-icon(small='', :style="{color: [item.status] == 'active' ? 'green' : 'gray'}") fiber_manual_record
          | {{ item.status }}


        template(v-slot:item.group="{item}")
          template(v-if="item.group")
            span(:style="groupStyle(item.group.color)") {{item.group.name}}
          template(v-else)
            .grey--text() no group

        template(v-slot:item.assignment="{item}")
          vehicle-column(v-if="item.assignment_vehicle_id" :vehicle="item.assignment_vehicle")

        template(v-slot:item.technician="{item}")
          v-icon(v-if="item.technician") done
        template(v-slot:item.operator="{item}")
          v-icon(v-if="item.operator") done
        template(v-slot:item.employee="{item}")
          v-icon(v-if="item.employee") done

        

        //- template(v-slot:item.operator="{item}")
        //-   template(v-if="item.operator")
        //-     .d-flex.align-center()
        //-       div(:style="operatorStyle(item.operator)") {{item.operator.first_name[0]}}{{item.operator.last_name[0]}}
        //-       div {{item.operator.first_name}} {{item.operator.last_name}}

    export-data-dialog(:fileExport="fileExport" :isFinished="isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="onCloseExportDialog" @download="onDownload" )
</template>

<script>
import VehicleFilterDialog from "@/modules/global/components/dialog/VehicleFilterDialog";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import Repository from "@/repository";

export default {
  components: {
    GenericBreadCrumbs,
    VehicleFilterDialog,
    ExportDataDialog,
    VehicleColumn
  },
  data: () => ({
    isDownloadFinished: false,
    fileLink: null,
    showExportDialog: false,
    fileExport: "",
    breadCrumbsItems: [
      {
        text: "Report",
        disabled: false,
        path: "reports"
      },
      {
        text: "Contact Report",
        disabled: true,
        path: "contactListReport"
      }
    ],
    dialog: true,
    items: [
      {
        title: "Export CSV",
        icon: "cloud_download",
        value: "export_csv"
      },
      {
        title: "Export Excel",
        icon: "cloud_download",
        value: "export_excel"
      }
    ],
    search: "",

    headers: [
      { text: "First Name", align: "left", value: "first_name", visible: true },
      {
        text: "Middle Name",
        align: "left",
        value: "middle_name",
        visible: false
      },
      { text: "Last Name", align: "left", value: "last_name", visible: true },
      { text: "Status", align: "center", value: "status", visible: true },
      { text: "Group", align: "left", value: "group", visible: true },

      { text: "Email", align: "left", value: "email", visible: true },
      { text: "Mobile", align: "left", value: "mobile", visible: true },
      {
        text: "Technician",
        align: "center",
        value: "technician",
        visible: true
      },
      { text: "Operator", align: "center", value: "operator", visible: true },
      { text: "Employee", align: "center", value: "employee", visible: true },

      { text: "Phone", align: "left", value: "phone", visible: false },
      {
        text: "Street Address",
        align: "left",
        value: "street_address",
        visible: false
      },
      { text: "Address", align: "left", value: "address", visible: false },
      { text: "City", align: "left", value: "city", visible: false },
      {
        text: "Postal Code",
        align: "left",
        value: "postal_code",
        visible: false
      },
      { text: "Country", align: "left", value: "country", visible: false },
      {
        text: "License Number",
        align: "left",
        value: "license_no",
        visible: false
      },
      {
        text: "License Class",
        align: "left",
        value: "license_class",
        visible: false
      },
      {
        text: "Labour Rate",
        align: "left",
        value: "labour_rate",
        visible: false
      },
      {
        text: "Date of Birth",
        align: "left",
        value: "date_of_birth",
        visible: false
      },
      {
        text: "Start Date",
        align: "left",
        value: "start_date",
        visible: false
      },
      { text: "End Date", align: "left", value: "end_date", visible: false },
      {
        text: "Employee Number",
        align: "left",
        value: "employee_number",
        visible: false
      },
      {
        text: "Assigned Vehicle",
        align: "left",
        value: "assignment",
        visible: true
      }
    ]
  }),

  computed: {
    getHeaders: function() {
      return this.headers.filter(item => item.visible);
    }
  },
  methods: {
    onExport: function(value) {
      switch (value) {
        case "export_csv":
          this.fileExport = "CSV";
          this.downloadFile("/report/CSV/contact_list");
          break;
        case "export_excel":
          this.fileExport = "Excel";
          this.downloadFile("/report/Excel/contact_list");
          break;
      }
      this.showExportDialog = !this.showExportDialog;
    },

    downloadFile: function(url) {
      this.isDownloadFinished = false;

      let postList = this.headers.filter(item => item.visible == true);
      Repository({
        method: "POST",
        url: url,
        data: postList,
        responseType: "blob"
      }).then(response => {
        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },

    onDownload: function() {
      // download the file
      this.fileLink.click();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function() {
      this.showExportDialog = false;
    },
    print: function() {
      console.log("print");
    },
    groupStyle: function(value) {
      let obj = {};
      obj["border-bottom"] = "3px solid";
      obj["border-bottom-color"] = value;
      return obj;
    },

    operatorStyle: function(operator) {
      let obj = {};
      obj["border-radius"] = "50%";
      obj["color"] = "#FFFFFF";
      obj["padding"] = "3px";
      obj["margin-right"] = "5px";

      if (operator.group != null) {
        obj["background-color"] = operator.group.color;
      } else {
        obj["background-color"] = "#333333";
      }
      console.log(operator.group);
      return obj;
    }
  },

  mounted() {
    if (this.$store.state.contacts.contact_data.length == 0) {
      this.$store.dispatch("contacts/loadAllContacts");
    }
  }
};
</script>

<style scoped>
.reportHeaderList {
  overflow: auto;
  max-height: 400px;
  min-width: 300px;
}
</style>
