<template lang="pug">
div
  v-container.pt-0(grid-list-sm)
    form-divider ENGINE
    v-layout(row, wrap, justify-start)
      v-flex(xs12, md6, xl4)
        v-text-field(v-model='engine', hint='Engine Summary Description', persistent-hint, label='Engine')
      v-flex(xs12, md6, xl4)
        v-text-field(v-model='engine_displacement', label='Displacement', type='number', hint='CC of the Engine e.g. 2000cc', persistent-hint,)
      v-flex(xs12, md6, xl4)
        v-text-field(v-model='engine_cylinders', label='Cylinders', type='number', hint='Cylinders', persistent-hint,)
      v-flex(xs12, md6, xl4)
        v-text-field(v-model='engine_horse_power', label='Engine Horse Power', type='number', hint='Horese Power', persistent-hint,)
      v-flex(xs12, md6, xl4)
        v-select(v-model='engine_aspiration', :items='aspirationTypes', label='Aspiration', hint='Method of Drawing air into Engine e.g. Natural, Turbo', persistent-hint)
    form-divider TRANSMISSION
    v-layout(row, wrap, justify-start)
      v-flex(xs12, md6, xl4)
        v-select(v-model='transmission_drive_train', :items='transmissionDriveTrainTypes', label='Drive Train', hint='Power onto the Wheels', persistent-hint)
      v-flex(xs12, md6, xl4)
        v-select(v-model='transmission_type', :items='transmissionTypes' label='Transmission Type', hint='Transmission - Manual or Automatic', persistent-hint)
      v-flex(xs12, md6, xl4)
        v-text-field(v-model='transmission_gears', hint='Transmission Gears' persistent-hint, label='Number of Gears Available')

</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "vehicles/getItem",
  mutationType: "vehicles/updateItem"
});

export default {
  components: {
    FormDivider
  },

  data: () => ({
    aspirationTypes: [
      "Natural",
      "Turbo",
      "Supercharged",
      "Turbo + Supercharged"
    ],

    transmissionDriveTrainTypes: [
      "Front Wheel Drive",
      "Rear Wheel Drive",
      "4x4",
      "4x2",
      "6x2",
      "6x4"
    ],

    transmissionTypes: ["Manual", "Automatic"]

    // green, blue, orange, red, gray
  }),

  computed: {
    ...mapFields([
      "engine",
      "engine_displacement",
      "engine_cylinders",
      "engine_horse_power",
      "engine_aspiration",
      "transmission_drive_train",
      "transmission_type",
      "transmission_gears"
    ])
  }
};
</script>

<style scoped>
.topiDivider {
  color: #aa00cc;
  border-bottom: 2px solid #aa00cc;
  margin-top: 20px;
  font-size: 18px;
}
</style>
