<template lang="pug">
div
  .d-flex.justify-end()

    v-btn.ma-2.mb-6(small='', color='primary', dark='', @click='addNew') Add New
  form-sub-header Label Settings
  v-data-table(
    :headers='headers',
    :items='$store.state.settings.tagSettings.tag_data',
    :loading='loading'
    )

    template(v-slot:progress)
      v-progress-linear(slot="progress"  height="2" :indeterminate="loading")

    template(v-slot:item.name="{item}")
      div(v-html="item.name")
    template(v-slot:item.description="{item}")
      div(v-html="item.description")
    template(v-slot:item.level="{item}")
      div(v-html="item.level")
    template(v-slot:item.action="{item}")
      v-row.mr-7(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

</template>
<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";

export default {
  components: {
    FormSubHeader,
    MoreDropDownMenu
  },

  data: () => ({
    loading: false,
    headers: [
      { text: "Name", value: "name", align: "left" },
      { text: "Description", value: "description", align: "left" },
      { text: "Level", value: "level", align: "left" },
      { text: "Actions", value: "action", align: "right" }
    ],

    menuitems: [
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" }
    ]
  }),

  methods: {
    addNew: function() {
      console.log("add New");
      this.$store.dispatch("settings/tagSettings/addTag").then(() => {
        // console.log(this.$store.getters["settings/tagSettings/getCurrentTag"].id)
        this.$router.push({
          name: "tagform",
          params: {
            tagID: this.$store.getters["settings/tagSettings/getCurrentTag"].id
          }
        }).catch(()=>{});
      });
    },

    onClick: function(value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          this.$router.push({
            name: "tagform",
            params: { tagID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "delete":
          this.$store.dispatch(
            "settings/tagSettings/deleteTag",
            value.theObject.id
          );
          break;
      }
    }
  },

  mounted() {
    if (this.$store.state.settings.tagSettings.currentIndex == -1) {
      this.$store.dispatch("settings/tagSettings/loadAllTags");
    }
  }
};
</script>
<style scoped lang="stylus">
.nameColumn {
  display: flex;
  align-items: center;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;

  padding: 0px;
}
</style>
