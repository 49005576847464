<template lang="pug">
  #blankcontent()

    .panelTitle()
      .d-flex.justify-space-between(style="height:30px; width:100%")
        div(style="width:150px;") Total Distance in Km
        div(style="width:130px")
          v-select.font-weight-regular(v-model="interval" :items="intervalItems" item-value="value" item-text="label" hide-details height="20" style="margin-top:-10px; width:130px" @change="onChange" )


    v-divider.mx-2()
    bar-chart(v-if="loaded" :style="{height:`${clientHeight-40}px`, width: `100%`}" :chartdata="getDataSets" :options="chartOption")

</template>
<script>
import BarChart from "@/modules/global/components/charts/BarChart";
import axios from "axios";
import moment from "moment";
export default {
  components: {
    BarChart
  },
  props: {
    clientHeight: Number
  },
  data: () => ({
    interval: "6 days",
    intervalItems: [
      {label:"Last 7 Days", value: "6 days"},
      {label:"Last 14 Days", value: "13 days"},
    ],
    xData: [],
    yData: [],
    loaded: false,
    chartOption: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              maxTicksLimit: 5,
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ]
      },

      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 20,
          bottom: 0
        }
      }
    }
  }),
  computed: {
    getDataSets: function() {
      let obj = {
        labels: this.xData,
        datasets: [
          {
            label: "Day",
            data: this.yData
            // borderWidth: 2
          }
        ]
      };
      obj.datasets[0].backgroundColor = this.$vuetify.theme.themes.light.primary;
      // obj.datasets[0].borderColor = this.$vuetify.theme.themes.light.primary;
      return obj;
    }
  },
  mounted() {
    this.loaded = false;
    this.loadData();
  },

  methods: {
    loadData() {
      this.xData = [];
      this.yData = [];
      axios.post("/api/dashboard/dailyAccountDistances", {interval: this.interval}).then(response => {
        this.yData = [];
        response.data.forEach( element => {
          if(this.interval === '6 days'){
            this.xData.push(moment(element.the_date).format("ddd"))
          }else{
            this.xData.push(moment(element.the_date).format("DD MMM"))
          }

          this.yData.push(element.the_value);
        })
        this.loaded  = true;
      });

    },

    onChange:function(){
      this.loaded = false;
      this.loadData();
    }
  },
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.panelTitle {
  font-size: 14px;
  font-weight bold
  padding: 5px 30px;
  height:30px;
  display: flex;
  width: 100%
  justify-content: space-between;
}

.lineChartPanel {
  height: calc(100% - 45px);
}
</style>
