<template lang="pug">
  .fill-height()
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Inspection Forms
        v-spacer
        .text-xs-center
          v-btn.mx-2(outlined small color="primary" @click="onRefresh" )
            v-icon.mr-2(small) sync
            | Refresh
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item(v-for='item in items', :key='item.title')
                v-list-item-title {{ item.title }}
        v-btn.ma-2.mr-5(small, color='primary', dark, @click='newInspectionForm') New Template
    v-divider
    .grey.lighten-4.py-3
    v-divider
    .max-height.d-flex()
      div.navBar
        v-list.fill-height(dense shaped)
          v-subheader() INSPECTION FORMS
          v-list-item-group(color="primary")
            v-list-item()
              v-list-item-icon()
                v-icon() mdi-cog-outline
              v-list-item-content()
                v-list-item-title My Forms


            v-list-group(prepend-icon="mdi-account-circle")
              template(v-slot:activator)
                v-list-item-title Sample Templates
              v-list-item(v-for="(n, i) in 3"  :key="i")
                v-list-item-content()
                  .leftListPadding()
                    v-list-item-title yup anoter {{n}}
                v-list-item-icon()
                  v-icon() mdi-cog-outline


            v-list-item()
              v-list-item-icon()
                v-icon() mdi-delete
              v-list-item-content()
                v-list-item-title Others

      #templateGridView.grey.lighten-3()
        div(:style="getContainerStyle()")
          v-container()
            v-layout(align-center)
              .title.ml-3.grey--text() INSPECTION FORMS
              v-spacer()
              v-layout.px-3(row, wrap, align-center)
                v-text-field.pr-6(v-model='searchFilter', label='Search Filter', :persistent-hint='true', append-icon="search")
            .autoVerticalScroll()
              .formTemplateGrid(v-if="$store.state.inspection.inspectionForm.currentIndex > -1")
                div(v-for="(item,index) in $store.getters['inspection/inspectionForm/getInspectionForms']" :key="index" style="height: 190px; width:320px;")
                  v-card(elevation="2" shaped
                  style="height: 160px; width:280px;")
                    v-card-title.titleCard.py-0.pr-1( )
                      .subtitle-1.font-weight-bold.boardTitle(@click="showDashboard(item)") {{item.name}}
                        span.font-weight-light.ml-2.grey--text() &ndash; v{{item.version}}

                      v-spacer()
                      v-menu()
                        template( v-slot:activator="{ on, attrs }")
                          v-btn(icon v-bind="attrs" v-on="on")
                            v-icon() expand_more
                        v-list(dense)
                          v-list-item-group()
                            v-list-item(@click="onCreateForm(item)")
                              v-list-item-icon()
                                v-icon(:size="20")  note_add
                              v-list-item-title() New Form
                            v-list-item(@click="showDashboard(item)")
                              v-list-item-icon()
                                v-icon(:size="20") dashboard
                              v-list-item-title() Dashboard
                            v-list-item(v-if="isAdministrator" @click="settingTemplate(item)")
                              v-list-item-icon()
                                v-icon(:size="20")  settings
                              v-list-item-title() Settings
                            v-list-item(v-if="isAdministrator" @click="editTemplate(item)")
                              v-list-item-icon()
                                v-icon(:size="20") edit
                              v-list-item-title() Edit Template

                    .d-flex()
                      template(v-if="item.thumbnail_id")
                        .ml-4(style="min-width:70px;min-height:70px")
                          image-thumbnail(:src="item.thumbnail_id" :width="70" :height="70")
                      .descriptionText.caption.mx-4.grey--text.text--darken-1(style="max-height:78px;" v-text="item.description")

                    .cartActions()
                      v-layout()
                        v-card-actions()
                          v-tooltip(bottom)
                            template(v-slot:activator="{ on, attrs }")
                              v-btn(icon @click="onCreateForm(item)" v-on="on" )
                                v-icon() note_add
                            span() Add New Record
                          v-tooltip(bottom)
                            template(v-slot:activator="{ on, attrs }")
                              v-btn(icon @click="showListLayout(item)"  v-on="on")
                                v-icon() list
                            span() Show Records
                      .subtitle-1() {{item.record_count}}




</template>

<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components:{
    ImageThumbnail
  },
  name: "FormTemplateGridLayout",
  data: () => ({
    searchFilter:null,
    height: null,
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ],
  }),

  created() {
    // stretch the elements to fill the height
    this.height = window.innerHeight - 115;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      // this.width = this.$refs.contentArea.getBoundingClientRect().width;
    });
  },

  destroyed() {
    window.removeEventListener('resize',this.onResize)
  },
  mounted() {

    if(this.$store.state.inspection.inspectionForm.currentIndex == -1 || this.$store.state.inspection.inspectionForm.inspectionForm_data.length == 1 ){
      this.$store.dispatch("inspection/inspectionForm/loadAllInspectionForms");
    }
    this.onResize();
  },

  computed:{
    isAdministrator: function(){
      return this.$store.state.profile.userInfo.is_administrator;
    },
  },

  methods: {
    onRefresh: function(){
      this.$store.dispatch("inspection/inspectionForm/loadAllInspectionForms");
    },

    onResize:function(){
      this.height = window.innerHeight - 240;
    },
    getContainerStyle: function() {
      let  obj = {height:`${this.height}px`,"overflow-y":"scroll"}
      return obj
    },
    newInspectionForm: function() {
      this.$store.dispatch("inspection/inspectionForm/addInspectionForm").then(()=>{
        let id = this.$store.getters["inspection/inspectionForm/getCurrentInspectionForm"].id
            this.$router.push({
                name: "inspectionFormDesigner",
                params: { inspectionID: id }
              }).catch(()=>{});
      })
    },

    onCreateForm: function(item) {
        this.$store.dispatch("inspection/addInspection", item.id).then(() => {
          // get the New Form
          let newInspection = this.$store.getters["inspection/getCurrentInspection"];

          // set the current form as selected
          this.$store.commit("inspection/inspectionForm/setCurrentIndexByID", item.id);

          this.$router.push({
            name: "inspectionNewLayout",
            params: {inspectionID: newInspection.id}
          }).catch(()=>{})
        })

    },

    showDashboard: function(item){
      this.$store.commit("inspection/inspectionForm/setCurrentIndexByID",item.id);
      this.$store.commit("inspection/reset")
      this.$router.push({
        name: "inspectionFormDashboard",
        params: { inspectionID: item.id }
      }).catch(()=>{});
    },

    showListLayout: function(item){
      this.$store.commit("inspection/inspectionForm/setCurrentIndexByID",item.id);
      this.$store.commit("inspection/reset")
      this.$router.push({
        name: "inspectionRecords",
        params: { inspectionID: item.id }
      }).catch(()=>{});
    },

    showOverview: function(item){
      this.$store.commit("inspection/formDesigner/resetFormBuilder");
      this.$store.commit("inspection/inspectionForm/setCurrentIndexByID",item.id);
      this.$router.push({
        name: "inspectionOverview",
        params: { inspectionID: item.id }
      }).catch(()=>{});
    },


    editTemplate: function(item){
      console.log(item)

      this.$store.commit("inspection/formDesigner/resetFormBuilder");
      this.$store.commit(
          "inspection/inspectionForm/setCurrentIndexByID",
          item.id
      );
      // save the latest scheme
      this.$store.commit(
          "inspection/formDesigner/setSchema",
          this._.clone(
              item.data[item.data.length - 1].schema
          )
      );
      this.$router.push({
        name: "inspectionFormDesigner",
        params: { inspectionID: item.id }
      }).catch(()=>{});
    },

    settingTemplate:function(item){
      this.$store.commit("inspection/inspectionForm/setCurrentIndexByID",item.id);
      this.$router.push({
        name: "inspectionSettings",
        params: { inspectionID: item.id }
      }).catch(()=>{});
    },

    deleteTemplate:function(item){
      confirm(
          `Deleting the Template ${item.name} will also Delete All Records associated with it.\n\nDo you want to continue`

      ) &&
      this.$store.dispatch("inspection/inspectionForm/deleteInspectionForm", item.id);
    },

    showDialog: function() {},

    closeDialog: function(value) {
      console.log(value);
    }
  },
}
</script>

<style scoped lang="stylus">

.heightWorkflowContent{
  height: calc(100% - 120px);
  background-color #FF0000;
}
#templateGridView{
  width 100%
  height 100%
}


.navBar{
  width: 300px;
}
.zlistContent{
  padding-left 150px;
}

.leftListPadding{
  padding-left 74px;
}

.formTemplateGrid{
  display: flex;
  flex-wrap: wrap;
  padding: 12px 12px;
}

.cartActions{
  position absolute;
  bottom 0px;
  width 100%;
  padding-right 16px;

  display flex;
  align-items center;
}

.boardTitle{
  text-decoration none;
  width 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.boardTitle:hover{
  text-decoration underline;
  cursor:pointer;
}

.workflowColumn{
  height: 100%;
}
.descriptionText{
  overflow hidden;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleCard{

  height 40px;
}

.autoVerticalScroll{
  height: 100%;
  overflow-y:auto;
}


</style>