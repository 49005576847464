<template lang="pug">
  div()
    v-text-field(v-model="theValue" :label="schema.label" :hint="schema.hint" persistent-hint )
</template>

<script>
import SETTINGS from "@/modules/workflow/formBuilder/store/formSettings";


export default {
  props: {
    schema: Object
  },
  data: () => ({
    theValue:null,
  }),
  computed: {
    computedValue: {
      get: function () {
        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id);
      },

      set: function (theValue) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }

  },
  mounted() {
    this.theValue = this.computed;
  }
}

</script>

<style scoped>

</style>