<template lang="pug">
  div()
    v-progress-linear(v-show="loading" slot='progress', height='2', :indeterminate='loading')
    v-data-table.elevation-1(
      v-model="selectedItems",
      :search="search",
      :custom-filter="itemModuleFilter"
      :headers="getHeaders()",
      :items="$store.state.inventory.inventory_data",
      item-key="id"
      :single-select="false"
      show-select
      :items-per-page="footerProps.pagnation.itemsPerPage",
      :footer-props="footerProps"
    )
      template(v-slot:item.vehicle="{item}")
        vehicle-column(v-if="item.vehicle" :vehicle="item.vehicle")

      template(v-slot:item.submitted="{item}")
        div(v-if="item.submitted")
          v-avatar(color="grey lighten-2" :size="30" ) {{item.submitted.first_name[0]}}{{item.submitted.last_name[0]}}

      template(v-slot:item.merchant="{item}")
        div(v-if="item.merchant")
          div() {{item.merchant.reference_id}} &ndash;  {{item.merchant.name}}

      template(v-slot:item.customer="{item}")
        div(v-if="item.customer")
          .customerText.pointer(@click.prevent="onClickCustomer(item.customer_id)") {{item.customer.reference_id}} &ndash; {{item.customer.name}}
          .caption.grey--text
            span.primary--text(v-if="item.customer.area")  {{item.customer.area}}
            span.caption.grey--text.text--darken-2()  &#35;{{item.tx_id}}


      template(v-slot:item.start_at="{item}")
        div()
          div.grey--text() Start:&nbsp;
            span.black--text(v-if="item.start_at") {{getFormattedTime(item.start_at)}}
          div.grey--text() End: &nbsp;
            span.primary--text(v-if="item.end_at") {{getFormattedTime(item.end_at)}}





      template( v-slot:item.action="{ item }")
        v-row.mr-7(justify="end")
          more-drop-down-menu(:items='menuItems', @onClick='onClick', :theObject='item')

      template( v-slot:item.credit="{ item }")
        .d-flex.align-start(v-if="item.credit")
          v-icon(color="green") arrow_drop_up
          .green--text.font-weight-bold() {{item.credit}} {{currentItem.measurement_unit != null ? currentItem.measurement_unit.name : 'units'}}
      template( v-slot:item.debit="{ item }")
        .d-flex.align-start(v-if="item.debit")
          v-icon(color="primary") arrow_drop_down
          .primary--text.font-weight-bold() {{item.debit}} {{currentItem.measurement_unit != null ? currentItem.measurement_unit.name : 'units'}}


</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import moment from "moment";
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import {searchMixin} from "@/mixin/searchListMixin";

export default {
  props:{
    search: String,
  },
  components: {
    MoreDropDownMenu,
    VehicleColumn
  },
  mixins: [searchMixin],
  data: () => ({
    loading:false,
    menuItems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ],
    footerProps: {
      "items-per-page-options": [5, 10, 20, 50, 100, 1000],
      pagnation: {itemsPerPage: 20}
    },
  }),
  computed: {
    currentItem: function () {
      return this.$store.getters["inventory/item/getCurrentItem"];
    },

    selectedItems: {
      get: function() {
        return this.$store.getters["inventory/getSelectedItems"]
      },
      set: function(value){
        this.$store.commit("inventory/setSelectedItems",value)
      }
    }
  },

  mounted() {

  },


  methods: {
    onClickCustomer: function(id){
      this.loading = true;
      if(this.$store.getters["customer/getCustomerByID"](id) == undefined){
        this.$store.dispatch("customer/loadAllCustomers",id).then(()=>{
          this.loading = false;
          this.$store.commit("customer/setCurrentIndexByID", id);
          console.log(id)
          this.$router.push({
            name: "customerInventories",
            params: {contactID: id}
          }).catch(()=>{});
        })
      }else{
        this.loading = false;
        this.$store.commit("customer/setCurrentIndexByID", id);
        console.log(id)
        this.$router.push({
          name: "customerInventories",
          params: {contactID: id}
        }).catch(()=>{});
      }
    },
    itemModuleFilter: function (value, search, item) {
      let isFound = false;
      let patt = new RegExp(search.toLowerCase(), "i");
      // Search the Object
      isFound = this.searchObject(item, patt, isFound);

      // Search Customer
      isFound = this.searchObject(item.customer, patt, isFound);

      // Search Customer
      isFound = this.searchObject(item.merchant, patt, isFound);

      return isFound;
    },
    getHeaders: function() {
      let headers = [
        {text: "Vehicle", value: "vehicle"},
        {text: "Customer", value: "customer"},
        {text: "Merchant", value: "merchant"},
        {text: "Submitted By", value: "submitted"},
        {text: "Duration", value: "start_at"},
        {text: "Credit", value: "credit"},

        {text: "Debit", value: "debit"},
      ];

      // insert new fields here
      if(this.currentItem && this.currentItem.item_details && this.currentItem.item_details.custom_fields){
        this.currentItem.item_details.custom_fields.forEach(element => {
          headers.push({text: element.label, value: `details.${element.label_id}`, align: "right"})
        })
      }


      headers.push({text: "Action", value: "action", align: "right"})
      return headers;
    },

    getFormattedTime: function (value) {
      return moment(value).format("ddd, Do MMM YYYY")
    },
    onClickRow: function (value) {
      console.log(value)

    },
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("inventory/item/setCurrentIndexByID", this.$route.params.itemID)
          if (value.theObject.credit != null) {
            this.$store.commit("inventory/setMode", "add");
          } else {
            this.$store.commit("inventory/setMode", "delete")
          }
          this.$store.commit("inventory/setCurrentIndexByID", value.theObject.id)
          this.$router.push({
            name: "inventoryForm",
            params: {itemID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          this.$store.dispatch("inventory/deleteInventory", value.theObject.id);
          break;
      }
    }
  },

}
</script>

<style scoped lang="stylus">
.customerText:hover{
  text-decoration underline
}
</style>