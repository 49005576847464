<template lang="pug">
  div
    v-autocomplete(
      v-model="fuelTypeID"
      :items="$store.state.settings.fuelTypeSettings.fueltype_data"
      :search-input.sync="search"
      item-text="name"
      item-value="id"
      label="Select Fuel Type"
      placeholder="Start typing to Search"
      chips
      @change="onChange"
    )
      template(slot="selection" slot-scope="data")
        v-chip.chip--select-multi(:input-value="data.selected" small )
          | {{data.item.name}}
</template>
<script>
export default {
  props: ["value"],
  data: () => ({
    descriptionLimit: 60,
    isLoading: false,
    fuelTypeID: null,
    search: null
  }),

  methods: {
    onChange: function(theValue) {
      this.$emit("input", theValue);
    }
  },

  mounted() {
    this.fuelTypeID = this.value;
    if (this.$store.state.settings.fuelTypeSettings.currentIndex === -1) {
      this.isLoading = true;
      this.$store
        .dispatch("settings/fuelTypeSettings/loadAllFuelTypes")
        .then(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
<style scoped lang="stylus"></style>
