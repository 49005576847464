<template lang="pug">
  v-dialog( v-model="dialog" width="600")
    template(v-slot:activator="{ on }")
      v-btn.ma-2(v-on="on" color="primary" @click="onDecode" small) Decode VIN
    v-card
      v-card-title.title.grey.lighten-2
        | Decoding the Vehicle VIN
      v-container(row)
        v-layout(align-center)
          v-layout.justify-center.align-center.column.mb-2( v-if="!isLoadedDecodedVIN" )
            .body-1.ma-2.mb-3 This should take afew seconds
            v-progress-circular( :size="50" :width="7" color="primary" indeterminate)
          .div.ml-8(v-else)
            .caption.ma-2.mb-3(v-if="items.length > 1") We found multiple vehicle styles. Please select one.
            v-layout.radioSelection()
              v-list(two-line)
                v-radio-group(v-model="radioGroup")
                  template( v-for="(vehicle,index) in items"  )
                    v-list-item(:key="vehicle.make" ripple @click="toggle(index)")
                      v-list-item-action
                        v-radio(:value="index")
                  
                      v-list-item-content
                        v-list-item-title {{vehicle.make | CapitalizeFirst}} - {{vehicle.model}} {{vehicle.series}} ({{vehicle.year}})
                        v-list-item-title {{vehicle.body_sub_type}} 
                        v-list-item-title {{vehicle.engine_displacement}}cc - {{vehicle.fuel_type}} - 
                          span(v-if="vehicle.engine_horse_power" ) {{vehicle.engine_horse_power}} hp
                        v-list-item-title {{vehicle.type | CapitalizeFirst}} 
                          span(v-if="vehicle.drive_train") - {{vehicle.drive_train}}
                    v-divider( v-if="index + 1 < items.length" :key="index")
      v-card-actions
        v-spacer
        v-btn(color="grey darken-3" text @click="dialog=false" ) Cancel
        v-btn(color="primary" @click="accept") OK
</template>
<script>
export default {
  props: {
    item: Object
  },
  data: () => ({
    dialog: false,
    isLoadedDecodedVIN: false,
    radioGroup: 0,
    selectedIndex: -1
  }),

  computed: {
    items: {
      get() {
        return this.$store.state.vehicles.decodedVehicleList;
      }
    }
  },

  filters: {
    CapitalizeFirst: function(value) {
      value = value.toLowerCase();
      return value.charAt(0).toUpperCase() + value.substr(1);
    }
  },

  methods: {
    toggle(index) {
      console.log(index);
      this.radioGroup = index;
    },

    onDecode: function() {
      this.$store.commit("vehicles/resetDecodedList");
      this.isLoadedDecodedVIN = false;
      this.$store.dispatch("vehicles/decode", this.item).then(msg => {
        console.log(`message is here: ${msg}`);
        this.isLoadedDecodedVIN = true;
        this.selectedIndex = 0;
      });
    },

    accept: function() {
      this.$store.commit(
        "vehicles/setCurrentItem",
        this.items[this.selectedIndex]
      );
      this.dialog = false;
    }
  }
};
</script>
<style scoped lang="stylus">
.radioSelection {
  border: 1px dotted #CCC;
}
</style>
