<template lang="pug">
  //div.blue.fill-height.fill-width() window height: {{$store.state.window_height}} window width: {{$store.state.window_width}} main Width: {{$store.state.main_width}}  main Height: {{$store.state.main_height}}
  div.fill-height(v-if="$store.state.place.currentIndex > -1")
    v-layout.pt-2.pr-3(align-center)
      v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")


      v-spacer
      v-layout.mx-4(justify-end,align-center)
        .mr-2(v-if="$store.state.place.place_data.length > 1")
          v-btn.mx-4(icon small @click="previous")
            v-icon() skip_previous
          v-btn.mx-4(icon small @click="next")
            v-icon() skip_next
        v-btn.mx-2(small, color='primary', outlined, dark, @click='cancel') Cancel
        v-btn.mx-2(small, color='primary', dark, @click='save') Save
        v-btn.mx-2(small, color='primary', dark, @click='saveAndClose') Save and Close
    v-container()
      v-tabs(v-model="tabID"  background-color="grey lighten-3" :height="35" :width="100")
        v-tab() Edit Location
        v-tab-item
          v-row()
            v-col.fill-height(cols="6" sm="12"  md="6")
              template(v-if="tabID == 0")
                .mx-2.mt-8()
                  location-search-text.mt-2(@input="onChange")
                  v-text-field.mt-0(v-model="name" label="Name" hint="Name of Place" persistent-hint)
                  v-textarea(v-model='description',  label='Description' rows="2" )
                  show-advanced(:showAdvanced="showAdvanced" @toggle="toggleShowAdvanced()")
                    div()


                      v-col(cols="12")
                        group-auto-complete(v-model="group_id")

                      //- v-col(cols="12")
                        contacts-auto-complete(v-model="contacts_id")
                      v-row()
                        v-col(cols="6")
                          customers-auto-complete(v-model="customers_id" small)
                        v-col(cols="6")
                          merchants-auto-complete(v-model="merchants_id" small)

                      v-row()
                        v-col(cols="12")
                          v-slider(v-model="sliderValue" :tick-labels="radiusLables" :step="getSliderSteps" tick-size="5" :thumb-size="40"  track-fill-color="accent" label="Geofence Radius" )
                            template(v-slot:thumb-label="{ value }")
                              | {{radiusLables[value/getSliderSteps]}}
                          .caption() The geofence radius is used to determine the location entries that are associated to this place. You can use this geofence to send an alert when a location entry or exit here.
                        v-col(cols="12")
                          v-row()
                            v-col(cols="6")
                              v-checkbox(v-model="is_hub" label="Location Hub")
                            v-col(cols="6")
                              v-checkbox(v-model="is_geofence" label="Make Geofence")







            v-col(cols="6" sm="12" md="6")
              div.fill-height(:style="{height:`${$store.state.main_height - 150}px`}")
                place-map(v-model="location" editable)
              .caption(v-text="`${location.lat}, ${location.lon}`")
        v-tab() Vehicle Stops [{{$store.state.place.stops == null?0:$store.state.place.stops.length}}]
        v-tab-item()
          place-visitation()



</template>
<script>
import {createHelpers} from "vuex-map-fields";
import LocationSearchText from "@/modules/global/components/forms/LocationSearchText";
import PlaceMap from "@/modules/places/components/ui/PlaceMap";
import ShowAdvanced from "@/modules/global/components/forms/ShowAdvanced";
import GroupsAutoComplete from "@/modules/global/components/forms/GroupsAutoComplete";
import GroupAutoComplete from "@/modules/global/components/forms/GroupAutoComplete";
import ContactsAutoComplete from "@/modules/global/components/forms/ContactsAutoComplete";
import MerchantsAutoComplete from "@/modules/global/components/forms/MerchantsAutoComplete";
import CustomersAutoComplete from "@/modules/global/components/forms/CustomersAutoComplete";
import PlaceVisitation from "@/modules/places/components/tables/PlaceVisitation";
const {mapFields} = createHelpers({
  getterType: "place/getPlace",
  mutationType: "place/updatePlace"
});
export default {
  components: {
    GroupsAutoComplete,
    GroupAutoComplete,
    ShowAdvanced,
    LocationSearchText,
    PlaceMap,
    ContactsAutoComplete,
    MerchantsAutoComplete,
    CustomersAutoComplete,
    PlaceVisitation
  },

  data: () => ({

    tempGroups: [],


    radiusLables: ["50m", "100m", "500m", "1Km", "5km"],
    tabID: 0,
    searchTerm: null,
    distances: [
      {
        text: "50 meters",
        value: 50
      },
      {
        text: "100 meters",
        value: 100
      },
      {
        text: "500 meters",
        value: 500
      },
      {
        text: "1000 meters",
        value: 1000
      }
    ],

  }),

  computed: {
    ...mapFields(["name", "description", "location", "radius", "group_id", "contacts_id","customers_id","merchants_id", "is_hub", "is_geofence"]),

    showAdvanced: {
      get: function(){
        return this.$store.getters["place/getShowAdvanced"]
      },


    },
    groups: {
      get: function () {
        return this.tempGroups
      },
      set: function (value) {
        console.log(`saved groups ${value}`)
        this.tempGroups = value
      }
    },


    sliderValue: {
      get: function () {
        let result = 0;
        switch (this.radius) {
          case 50:
            result = 0;
            break;
          case 100:
            result = 25;
            break;
          case 500:
            result = 50;
            break;
          case 1000:
            result = 75;
            break;
          case 5000:
            result = 100;
            break;
        }
        return result;
      },
      set: function (value) {
        // console.log(value);
        switch (value) {
          case 0:
            this.radius = 50;
            break;
          case 25:
            this.radius = 100;
            break;
          case 50:
            this.radius = 500;
            break;
          case 75:
            this.radius = 1000;
            break;
          case 100:
            this.radius = 5000;
            break;
        }
      }
    },


    getSliderSteps() {
      return 100 / (this.radiusLables.length - 1);
    }
  },
  mounted() {
    if (this.$store.state.place.currentIndex == -1) {
      // console.log(this.$route.params.placeID);
      this.$store.dispatch("place/fetchPlace", this.$route.params.placeID).then(()=>{
        this.$store.dispatch("place/getStops");
      });
    }else{
      this.$store.dispatch("place/getStops");
    }




  },
  methods: {


    toggleShowAdvanced: function () {
      console.log(this.$store.state.place.showAdvanced)
      this.$store.commit("place/showAdvanced", !this.$store.state.place.showAdvanced);
      console.log(this.$store.getters["place/getShowAdvanced"]);
    },

    onChange: function (value) {
      console.log(value.location);
      this.$store.commit("place/updatePlace", {path: "location", value: value.location})
      this.$store.commit("place/updatePlace", {path: "name", value: value.name})
    },

    getBreadCrumbItems() {
      var theArray = [];

      theArray.push({
        text: "Place List",
        disabled: false,
        to: {name: "places"}
      });
      theArray.push({
        text: this.name,
        disabled: true
      });
      return theArray;
    },

    next(){
      this.$store.commit("place/next");
      this.$store.dispatch("place/getStops");
    },
    previous() {
      this.$store.commit("place/previous");
      this.$store.dispatch("place/getStops");
    },
    async saveAndClose() {
      await this.$store.dispatch("place/savePlace").catch(() => {
        this.$store.dispatch("place/loadAllPlaces");
      });
      this.$router.push({
        name: "places"
      }).catch(()=>{})
    },

    async save() {
      await this.$store.dispatch("place/savePlace").then(() => {
        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Saved - ${this.name}`
        });
      });


    },

    async cancel() {
      await this.$store.dispatch("place/loadAllPlaces");
      this.$router.push({
        name: "places"
      }).catch(()=>{})
    }
  }
};
</script>
<style lang="stylus" scoped>
.mainFull {
  position relative,
  top 0px
  bottom 0px
  left 0px
  right 0px;
}
</style>
