<template lang="pug">
  div
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Form Builder Templates
        v-spacer
        .text-xs-center
          v-btn.mx-2(outlined small color="primary" @click="onRefresh" )
            v-icon.mr-2(small) sync
            | Refresh
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item(v-for='item in items', :key='item.title')
                v-list-item-title {{ item.title }}
        v-btn.ma-2.mr-5(small, color='primary', dark, @click='addForm') Add Form
    v-divider
    v-layout.mx-4.my-2(column)
      v-item-group
        v-item(v-for='toggleItem in toggleItems', :key='toggleItem')
          v-chip(small, :color="\
          active ? 'primary white--text elevation-0' : 'white primary--text'\
          ", slot-scope='{ active, toggle }', @click.native='toggle', :input-value='active')
            | {{ toggleItem }}
      v-layout.mt-3(align-center, style='max-width:900px;')
        v-layout.px-3(row, wrap, align-center)
          v-combobox(v-model='select1', :items='selectOptions1', label='Select a favourite activity or create a new one')
          v-text-field.pl-2(v-model='searchFilter', label='Search Filter', :persistent-hint='true', required)
          v-btn.mx-3(small, color='primary', dark) Search
          div
            span.primary--text 1 filter
            span.pl-1.grey--text.text--darken-1 applied
          a().px-3.grey--text.text--darken-1 clear all
    v-divider
    .grey.lighten-4.py-3
    v-divider
    form-template-table()
    v-divider
</template>

<script>
import FormTemplateTable from "@/modules/workflow/formBuilder/components/tables/FormTemplateTable";

export default {
  components: {
    FormTemplateTable,
  },
  data: () => ({
    toggleItems: ["Active", "In Active", "Processing"],
    select1: "All Forms",
    selectOptions1: [
      "All Forms",
      "Overdue Only",
      "Overdue + Due Soon",
      "Due Soon Only"
    ],
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ],

    vehiclesFilter: "",
    searchFilter: ""
  }),

  methods: {
    addForm: function() {
      this.$store.dispatch("workflow/addWorkflow").then(() => {
        let currentObject = this.$store.getters["workflow/getCurrentWorkflow"];
        this.$router.push({
          name: "templateBuilderForm",
          params: { workflowID: currentObject.id }
        }).catch(()=>{});
      });
    },

    showDialog: function() {},

    closeDialog: function(value) {
      console.log(value);
    },

    onRefresh: function(){
      this.$store.dispatch("workflow/loadAllWorkflows");
    }
  },

  mounted() {
    // this.$store.dispatch("workflow/loadAllWorkflows").then(() => {
    //   console.log(this.$store.state.workflow_data);
    // });
  }
};
</script>

<style scoped></style>
