<template lang="pug">
  div
    v-data-table.elevation-1(:headers='headers', :items='$store.state.users.user_data' :loading="loading"  show-select)
      template(v-slot:progress)
        v-progress-linear(slot="progress"  height="2" :indeterminate="loading")


      template(v-slot:header.name="{ header }")
        v-tooltip(bottom)
          template(#activator="{on}")
            span(v-on="on") {{header.text}}
          span {{header.tooltip}}

      template(v-slot:body="{ items }")
        tbody()
          tr(v-for="(item,index) in items" :key="index")
            td()
              v-checkbox.ma-0( primary hide-details)
            td(:style="{height:'72px'}")
              contact-column-by-id(v-if="item.contact_id" :contact_id="item.contact_id" :style="{opacity:item.active?1.0:0.4}")
            td()
              div(v-text="item.username" :style="{opacity:item.active?1.0:0.4}")
            td()
              .d-flex.justify-center()
                v-icon(color="grey" v-if="item.is_administrator") done
            td()
              .d-flex.justify-center( :style="{opacity:item.active?1.0:0.4}")
                v-icon(color="grey") {{item.active?'done':'cancel'}}
            td()
              div(v-if="item.login_count")
                .caption( :style="{opacity:item.active?1.0:0.4}")
                  | Last Login: {{getLoginTime(item.last_login_at)}}
                  .primary--text() {{item.login_count}} times
            td()
              div(v-if="item.role"  :style="{opacity:item.active?1.0:0.4}")
                | {{item.role.name}}
            td()
              v-layout(justify-end align-center)
                more-drop-down-menu.mr-2(:items="menuItems(item.active)" @onClick="onClick" :theObject="item" style="height:24px")

</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById";
import moment from "moment";

export default {
  components: {
    MoreDropDownMenu,
    ContactColumnById
  },
  name: "ContactsTable",
  data() {
    return {
      dialog: false,
      selectedObject: null,

      loading: false,


      headers: [
        {text: "Name", value: "name"},
        {text: "Username", value: "username"},
        {text: "Administrator", value: "is_administrator", align: "center"},
        {text: "Active", value: "active", align: "center"},
        {text: "Logins", value: "login_count", sortable: false},
        {text: "Role", value: "role"},
        {text: "Actions", value: "logins", align: "right", sortable: false}
      ]
    };
  },

  computed: {},

  methods: {

    getLoginTime(submitted_time) {
      return moment(submitted_time).format("h:mm a, ddd, Do MMM YYYY")
    },
    menuItems: function (isActive) {
      let menus = [];
      menus.push({title: "Edit", icon: "edit", action: "edit"});
      if (isActive) {
        menus.push({title: "De-Activate", icon: "cancel", action: "deactivate"});
      } else {
        menus.push({title: "Activate", icon: "done", action: "activate"});
      }

      menus.push({title: "Overview", icon: "dashboard", action: "overview"});
      menus.push({title: "Download", icon: "cloud_download", action: "download"})
      return menus;
    },
    onClick: function (value) {

      switch (value.action) {
        case "edit":
          console.log(value)
          this.$store.commit(
              "contacts/setCurrentIndexByID",
              value.theObject.contact_id
          );

          this.$router.push({
            name: "contactUserInfo",
            params: {contactID: value.theObject.contact_id}
          }).catch(()=>{});
          break;
        case "deactivate":
          this.$store.dispatch("users/deactivateUser", value.theObject.id);
          break;
        case "activate":
          this.$store.dispatch("users/activateUser", value.theObject.id);
          break;
        case "overview":
          this.$store.commit(
              "contacts/setCurrentIndexByID",
              value.theObject.contactID
          );
          this.$router.push({
            name: "contactDashboard",
            params: {contactTabID: 1}
          }).catch(()=>{});
          break;
      }
    }
  },

  mounted() {
    this.$store.dispatch("users/loadAllUsers").then(() => {
      console.log(this.$store.state.users.user_data);
    });
  }
};
</script>

<style scoped>
.tabs {
  text-transform: none;
  font-size: small;
}
</style>
