<template lang="pug">
v-data-table(
  :headers="headers" 
  :search="search" 
  :items="$store.state.reminders.service_reminder.service_reminder_data"
  item-key="id" 
  :loading="isLoading" 
  :single-select="false" 
  show-select
  )

  template(v-slot:progress)
    v-progress-linear(slot="progress"  height="2" :indeterminate="isLoading")

  template(v-slot:item.vehicles="{item}")
    div(v-for="vehicle,index in item.vehicles" :key="index")
      .font-italic() {{vehicle.name}}

  template(v-slot:item.service_types="{item}")
    div(v-for="serviceType,index in item.service_types" :key="index")
      .font-weight-bold() {{serviceType.name}}


  template(v-slot:item.job="{item}")
    div(v-if="item.time_reminder")
      .caption.grey--text() ID: {{item.job_id}} > {{item.job_threshold_id}}
      .primary--text(v-if="item.job") {{item.job.next_run_at | moment("dddd, Do MMM YYYY &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; hh:mm a")}}
  
  template(v-slot:item.due_odometer="{item}")
    div(v-if="item.odo_reminder")
      .primary--text() {{item.start_odometer + item.interval_odometer}} Km
      .grey--text() [{{((Math.ceil(((item.current_odometer/1000.0) - item.start_odometer) / item.interval_odometer) * item.interval_odometer)+item.start_odometer - (item.current_odometer/1000.0)).toFixed(0) }}]
  
  template(v-slot:item.comments="{item}")
    .caption() {{item.comments}}

  template(v-slot:item.contacts="{item}")
    .d-flex
      .grey.rounded-lg.px-3.my-2.mr-2.white--text.darken-1.caption(v-if="item.contacts.length > 0 && item.contacts" v-for="contact,index in item.contacts" :key="index") {{contact.first_name}} {{contact.last_name}}

  template( v-slot:item.action="{ item }")
      v-row.mr-5(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item')

</template>
<script>
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import { searchMixin } from "@/mixin/searchListMixin";
import Repository from "@/repository";

export default {
  components: {
    VehicleColumn,
    MoreDropDownMenu
  },
  props: ["search"],
  mixins: [searchMixin],

  data: () => ({
    isLoading: true,

    headers: [
      {
        text: "Vehicles",
        value: "vehicles"
      },
      { text: "Service Task & Schedule", value: "service_types" },
      { text: "Next Due (Time)", value: "job" },
      { text: "Next Due (Milage)", value: "due_odometer" },
      { text: "Subscribers", value: "contacts" },
      { text: "Comments", value: "comments", align: "left" },
      { text: "Action", value: "action", align: "right" }
    ],

    menuitems: [
      {
        title: "Send Notification",
        icon: "notifications",
        action: "notification"
      },
      { title: "Edit", icon: "edit", action: "edit" },
      { title: "Delete", icon: "delete", action: "delete" },
      { title: "View Full History", icon: "history", action: "history" }
    ]
  }),

  methods: {
    onClick: function(value) {
      console.log(value);
      switch (value.action) {
        case "edit":
          this.$store.commit(
            "reminders/service_reminder/setCurrentIndexByID",
            value.theObject.id
          );
          this.$router.push({
            name: "serviceReminderForm",
            params: { reminderID: value.theObject.id }
          }).catch(()=>{});

          break;
        case "delete":
          this.$store.dispatch(
            "reminders/service_reminder/deleteService_reminder",
            value.theObject.id
          );
          break;
        case "notification":
          console.log("notification");
          Repository.get(`/serviceReminder/alarm/${value.theObject.id}`).then(response => {
            console.log(response);
          });
          break;
        case "history":
          console.log("history");
          break;
      }
    },
  },

  mounted() {
    this.$store
      .dispatch("reminders/service_reminder/loadAllService_reminders")
      .then(() => {
        console.log("reminder Table");
        this.isLoading = false;
      });
  }
};
</script>
<style scoped lang="stylus">
.editButtons {
  display: flex;
}

.dottedUnderline {
  border-bottom: 1px dotted;
  text-decoration: none;
}
</style>
