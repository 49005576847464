import Repository from "@/repository";

let fileLink;
const downloadFile = function(url, headers,store) {
  Repository({
    method: "POST",
    url: url,
    data: headers.filter(item => item.visible == true),
    responseType: "blob"
  }).then(response => {
    var fileName = response.headers["file"];
    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    store.commit("reports/setDownloadState",true)
  });
};

const startDownload = function() {
  fileLink.click();
};

export { downloadFile, startDownload };
