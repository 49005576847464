<template lang="pug">
  v-switch(
    v-model="computedValue"
    color="primary"
    :label="schema.label"
    :hint="schema.hint"
    :persistent-hint="schema.hint != null"
    :disabled="disabled"
  )
</template>
<script>


export default {
  props: ["schema", "dataValue", "index","disabled"],

  data: () => ({
  }),
  computed:{

    computedValue:{
      get: function() {
        return this.dataValue;
      },
      set: function(theValue){
        console.log(`setting theValue: ${theValue}`);
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    },
  },
  methods:{
  }
};
</script>
<style scoped lang="stylus">
.signPlaceholder{
  border 2px solid #CCCCCC;
  display flex;
  justify-content center;
  align-items center;
  border-radius 10px;
  background-size contain;
  background-position center;
  width 240px;
  height 120px;

}
.signPlaceholder img{
  width 130px;
  height 100px;
}
</style>
