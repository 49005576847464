<template lang="pug">
  div()
    v-progress-linear( color="primary" :indeterminate="$store.state.vehicles.assignments.loading" height="3" )
    #scheduler()
      #schedulerList()
        scheduler-list(:rowHeight="rowHeight")
      #schedulerTable(ref="schedulerTable")
        scheduler-table(:rowHeight="rowHeight" :rowWidth="rowWidth" @openDialog="$emit('openDialog')")
</template>

<script>
import SchedulerTable from "@/modules/vehicles/components/scheduler/SchedulerTable";
import SchedulerList from "@/modules/vehicles/components/scheduler/SchedulerList";

export default {
  props: {
    zoomLevel: {
      type: Number,
      default: 1.0
    }
  },

  components: {
    SchedulerList,
    SchedulerTable
  },

  computed: {
    getTableWidth: function() {
      return (
        this.$refs.schedulerTable.getBoundingClientRect().width * this.zoomLevel
      );
    }
  },

  watch: {
    zoomLevel: function() {
      this.refreshSchedule();
    }
  },

  methods: {
    refreshSchedule: function() {
      // constarin width to be 1000px or more
      this.rowWidth =
        this.getTableWidth < 1000
          ? 1000 * this.zoomLevel
          : this.getTableWidth * this.zoomLevel;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.refreshSchedule();
    });
  },

  data: () => ({
    rowHeight: 70,
    rowWidth: 800
  })
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#scheduler {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  background-color: #f8f8f8;
}

#schedulerList {
  display: flex;
  flex-direction: column;
  width: 300px;
}

#schedulerTable {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  -webkit-box-shadow: 5px 0px 18px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 0px 18px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 0px 18px 0px rgba(0, 0, 0, 0.75);
}
</style>
