<template lang="pug">
  v-card(:height="clientHeight")
    keep-alive
      component(
            :is="item.i"
            :clientHeight="clientHeight"
            :resize="resize"
      )
    .vue-draggable-handle()
      .ml-1
        v-icon(color="grey lighten-2" :size="20") drag_indicator
    .close()
      v-icon(color="grey lighten-2" @click="onClose") close
</template>
<style>
#panel-bg {
  background-color: green;
}
.close {
  position: absolute;
  right: 5px;
  top: 0px;
  height: 20px;
  width: 20px;
  z-index: 998;
}
.vue-draggable-handle {
  position: absolute;
  z-index: 999;
  width: 10px;
  height: 10px;

  background-color: red;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
<script>
import BlankPanel from "@/modules/dashboard/components/panels/BlankPanel";
import BarChartPanel from "@/modules/dashboard/components/panels/BarChartPanel";
import TaskPanel from "@/modules/workflow/overview/DashboardLayout/TaskPanel";
import KanbanBoardPanelStat from "@/modules/workflow/overview/DashboardLayout/KanbanBoardPanelStat";
import UserRecordsPanelStat from "@/modules/workflow/overview/DashboardLayout/UserRecordsPanelStat";
import CompletedPiePanel from "@/modules/workflow/board/components/ui/CompletedPiePanel";
import DayRecordsPanel from "@/modules/workflow/overview/DashboardLayout/DayRecordsPanel";


export default {
  components: {
    BlankPanel,
    BarChartPanel,
    TaskPanel,
    KanbanBoardPanelStat,
    UserRecordsPanelStat,
    CompletedPiePanel,
    DayRecordsPanel
  },

  name: "CustomDragElement",

  props: {
    text: {
      type: String,
      default: "x"
    },
    clientHeight: {
      type: Number,
      required: true
    },
    item: Object,
    resize: Boolean
  },
  data: function() {
    return {};
  },
  mounted: function() {
    console.log("### " + this.item.i + " ready!");
  },

  methods: {
    onClose: function() {
      this.$emit("close", this.item);
    }
  }
};
</script>
