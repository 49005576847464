import { render, staticRenderFns } from "./GlobalTemplateButton.vue?vue&type=template&id=69bd5bac&scoped=true&lang=pug"
import script from "./GlobalTemplateButton.vue?vue&type=script&lang=js"
export * from "./GlobalTemplateButton.vue?vue&type=script&lang=js"
import style0 from "./GlobalTemplateButton.vue?vue&type=style&index=0&id=69bd5bac&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69bd5bac",
  null
  
)

export default component.exports