<template lang="pug">
  div()
    #staticImage.pt-2.pl-2(:style="getImageStyle")
      #centerMarker()
        v-icon.placeIcon(color="primary" :size="52") place
      v-btn(color="primary" @click="openDialog" :disabled="disabled") {{label}}
    v-dialog(v-model="dialog", max-width="640")
      v-card()
        v-card-title.title.primary.white--text()
          | {{description}}
        #mapSelect()
        v-btn.zoomExtends(fab color="primary" )
          v-icon(@click="getUserLocation") my_location
        v-btn.placeExtends(fab color="primary")
          v-icon(@click="placeExtends" ) place
        v-divider
        v-card-actions()
          v-btn(color="grey", outlined, @click="centerMarker") Center Pin to map
          v-spacer()
          v-btn(color="grey", text @click="cancel") Cancel
          v-btn(color="primary" @click="save") Save

</template>
<script>
import mapboxgl from "mapbox-gl";
export default {
  props:{
    value: Object,
    zoom: {
      type: Number,
      default: 17
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    label: {
      type: String,
      default: "Select Location"
    },
    description: {
      type: String,
      default: "Select your Location"
    },
    disabled: {
      type:Boolean,
      default:false
    }
  },
  data: () => ({
    dialog:false,
    center: { lng: 36.74372678308009, lat: -1.2878468314658136 },
    pZoom: 17,
  }),

  mounted() {
    this.mapSelect = null;
    this.mapSelectLoaded = false;

    if (this.value != null) {
      this.center.lng = this.value.lon;
      this.center.lat = this.value.lat;
      this.pZoom = this.zoom;
    }

  },

  beforeDestroy() {
    this.$nextTick(() => {
      if (this.mapSelect != null) {
        this.mapSelect.remove();
      }
    });
  },

  methods: {
    openDialog:function() {
      this.dialog = true;
      if(this.mapSelectLoaded == false){
        this.$nextTick(()=>{
          this.createMapSelect();
        })
      }

    },

    createMapSelect: function () {
      mapboxgl.accessToken =
          "pk.eyJ1IjoibXVnYW1iaWtpbWF0aGkiLCJhIjoiY2p0OWNjaGZxMDdsdjQ0cXZjZTRhZnA0aSJ9.3b3qa7uHHInxWL3TBiMsSQ";
      this.mapSelect = new mapboxgl.Map({
        container: "mapSelect",
        style: "mapbox://styles/mapbox/streets-v10",
        minzoom: 1.3,
        center: [this.center.lng, this.center.lat], // Nairobi
        zoom: 16
      });

      this.markerLocationSelect = new mapboxgl.Marker({
        color: this.$vuetify.theme.themes.light.primary,
        draggable: true
      })
      this.markerLocationSelect.setLngLat( [this.center.lng, this.center.lat]);
      this.markerLocationSelect.addTo(this.mapSelect);

      this.mapSelect.on("load",()=>{
        this.mapSelectLoaded = true;
        this.mapSelect.resize();
      })
    },

    centerMarker: function() {
      this.markerLocationSelect.setLngLat(this.mapSelect.getCenter());
      // console.log(this.mapSelect.getCenter())
    },

    placeExtends: function() {
      this.mapSelect.flyTo({
        center:[this.markerLocationSelect.getLngLat().lng,this.markerLocationSelect.getLngLat().lat],
        essential: true
      })
    },

    getUserLocation: function() {
      navigator.geolocation.getCurrentPosition(
          (position) => {
            this.markerLocationSelect.setLngLat( [position.coords.longitude, position.coords.latitude]);
            this.mapSelect.flyTo({
              center:[position.coords.longitude, position.coords.latitude],
              essential: true
            })
          },
          (error) => {
            console.log(error);
          }
      );
    },

    cancel:function() {
      this.dialog = false;
    },

    save: function() {
      this.dialog = false;
      this.$emit("input", {
        lon: this.markerLocationSelect.getLngLat().lng,
        lat: this.markerLocationSelect.getLngLat().lat
      });
      this.center.lng = this.markerLocationSelect.getLngLat().lng;
      this.center.lat = this.markerLocationSelect.getLngLat().lat;

    }
  },

  computed:{
    getImageStyle: function() {
      var styleObj = {
        width: `${this.width}px`,
        height: `${this.height}px`
      };
      console.log(`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${this.center.lng},${this.center.lat},${this.pZoom},0,0/${this.width}x${this.height}?access_token=pk.eyJ1IjoibXVnYW1iaWtpbWF0aGkiLCJhIjoiY2p0OWNjaGZxMDdsdjQ0cXZjZTRhZnA0aSJ9.3b3qa7uHHInxWL3TBiMsSQ&logo=false`)

      if (this.dialog) {
        styleObj["background-color"] = "#CCCCCC";
      } else {
        styleObj[
            "background-image"
            ] = `url("https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${this.center.lng},${this.center.lat},${this.pZoom},0,0/${this.width}x${this.height}?access_token=pk.eyJ1IjoibXVnYW1iaWtpbWF0aGkiLCJhIjoiY2p0OWNjaGZxMDdsdjQ0cXZjZTRhZnA0aSJ9.3b3qa7uHHInxWL3TBiMsSQ&logo=false")`;
      }
      return styleObj;
    }
  }


}
</script>
<style scoped lang="stylus">
#centerMarker {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -20px;
  // background-color red
  pointer-events: none;
}
#staticImage {
  position: relative;
}

#mapSelect {
  width: 640px;
  height: 420px;
}

.zoomExtends{
  position absolute;
  top 80px;
  right 30px;
}

.placeExtends{
  position absolute;
  top 150px;
  right 30px;
}

</style>
