let theStore = null;
const initMatrix =  async function(store,username,password) {
    theStore = store;

    console.log(`MATRIX username: ${username} password: ${password}`);
    console.log(theStore)
}

const closeMatrix = async function() {
    console.log("Closing Matrix");
}

const sendMessage = async function(to,theMessage){
    console.log(`Message sent to: ${to}\n Body: ${theMessage}`)
}

export {
    initMatrix,closeMatrix,sendMessage
}