import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const MESSAGE_API = "/mail";

// ----------------------- STATE ------------------------- //
const state = {
    mail_data: [],
    currentIndex: -1,
    selectedItems: [],
    unread:0,
    read:0,
    total:0,
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getMail: state => getField(state.mail_data[state.currentIndex]),

    getFilterByGroup: (state) => (group) => {
        let data = [];
        switch(group){
            case "Unread": data = state.mail_data.filter(element => element.status == 1); break;
            case "Read": data = state.mail_data.filter(element => element.status == 3); break;
            default: data = state.mail_data;
        }
        return data;
    },

    getCurrentMail: state => {
        return state.mail_data[state.currentIndex];
    },
    getLastMails: state => maxItems => {
        if (state.mail_data.length > maxItems - 1) {
            return state.mail_data.slice(0, maxItems);
        } else {
            return state.mail_data;
        }
    },

    unreadMails: state => {
        if (state.mail_data.length == 0) {
            return 0;
        }else{
            let read = 0;
            state.mail_data.forEach(element => {
                if (element.status == 3) {
                    read += 1;

                }
            })

            return state.mail_data.length - read;
        }

    }

};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateMail(state, payload) {
        updateField(state.mail_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.mail_data.findIndex(
            mail => mail.id == theID
        );
    },

    setNext(state){
        state.currentIndex += 1;
        if(state.mail_data[state.currentIndex].status != null){
            state.mail_data[state.currentIndex].status = 3;
        }
    },
    setPrevious(state){
        state.currentIndex -= 1;
        if(state.mail_data[state.currentIndex].status != null){
            state.mail_data[state.currentIndex].status = 3;
        }
    },

    setSelectedItems(state,payload){
      state.selectedItems = payload;
    },


    markCurrentAsRead(state){
        if(state.mail_data[state.currentIndex].status != null){
            state.mail_data[state.currentIndex].status = 3;
        }
    },

    loadAllMail(state, payload) {
        state.mail_data = payload.data;
        state.currentIndex = 0;
    },



    addMail(state, payload) {
        state.mail_data.unshift(payload.data);
        state.currentIndex = state.mail_data.length - 1;
    },

    saveContactMail(state, payload) {
        state.mail_data[state.currentIndex] = payload.data;
    },

    fetchMail(state, payload) {
        if (state.currentIndex == -1) {
            state.mail_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.mail_data[state.currentIndex] = payload.data;
        }
    },

    fetchMailListByReference(state, payload) {
        if (payload.data.length > 0) {
            state.currentIndex = 0;
        }
        state.mail_data = payload.data;
    },

    deleteMail(state, payload) {
        state.currentIndex = state.mail_data.findIndex(
            mail => mail.id == payload.data.id
        );
        state.mail_data.splice(state.currentIndex, 1);
        state.currentIndex = 0;
    },

    deleteManyMails(state,payload){
      let deletedIDs  = payload.data;

            deletedIDs.forEach(id => {
                for(let i=0; i<state.mail_data.length; i++) {
                    if(id === state.mail_data[i].id){
                        state.mail_data.splice(i, 1);
                    }
                }

            })
    },
    markManyAsRead(state,payload){
      let deletedIDs  = payload.data;
            deletedIDs.forEach(id => {
                for(let i=0; i<state.mail_data.length; i++) {
                    if(id === state.mail_data[i].id){
                        state.mail_data[i].status = 3;
                    }
                }

            })
    },

    reCalculateStatus(state) {
        state.unread = 0;
        state.total = 0;
        state.mail_data.forEach(element => {
            if (element.status == 1) {
                state.unread += 1;

            }
            state.total += 1;
        })

        state.read = state.total - state.unread;
    },

    ContactMailStatus(state, payload) {
        state.unread = payload.data.unread
        state.total = payload.data.total
        state.read = payload.data.read
    },
    reset(state) {
        state.mail_data = []
        state.currentIndex = -1
        state.selectedItems = []
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllContactMail({commit}) {
        try {
            commit("loadAllMail", await Repository.get(`${MESSAGE_API}/contact`));
        } catch (error) {
            console.log(error);
        }
    },

    async ContactMailStatus({commit}) {
        try {
            commit("ContactMailStatus", await Repository.get(`${MESSAGE_API}/contact/count`));
        } catch (error) {
            console.log(error);
        }
    },

    async loadAllMail({commit}) {
        try {
            commit("loadAllMail", await Repository.get(`${MESSAGE_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addMail({commit}, payload) {
        await Repository.post(`${MESSAGE_API}/new`, payload)
            .then(response => {
                commit("addMail", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveContactMail({state, commit}) {
        try {
            commit(
                "saveContactMail",
                await Repository.post(
                    `${MESSAGE_API}`,
                    state.mail_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchMail({commit}, payloadID) {
        try {
            commit(
                "fetchMail",
                await Repository.get(`${MESSAGE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchMailListByReference({commit}, referenceID) {
        try {
            console.log(`${MESSAGE_API}/reference/${referenceID}`);
            commit(
                "fetchMailListByReference",
                await Repository.get(`${MESSAGE_API}/reference/${referenceID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteManyMails({commit},payload) {
        try {
            commit(
                "deleteManyMails",
                await Repository.post(`${MESSAGE_API}/${payload.type}/delete`,payload.ids)
            );
        } catch (error) {
            console.log(error);
        }
    },
    async markManyAsRead({commit},payload) {
        try {
            commit(
                "markManyAsRead",
                await Repository.post(`${MESSAGE_API}/contact/markManyAsRead`,payload)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteMail({commit}, payloadID) {
        try {
            commit(
                "deleteMail",
                await Repository.delete(`${MESSAGE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchMails({commit}, searchTerm) {
        try {
            commit(
                "loadAllMails",
                await Repository.get(`${MESSAGE_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
