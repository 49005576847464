<template lang="pug">
.IssueSmallText.my-1
  .IssueName.primary--text() {{ issue.name }}
    span.labelTags(v-if="issue.tags" v-for="(tag,index) in issue.tags" :key="index" x-small )  {{tag.name}}
  div()
    .grey--text() 
      | Reported on: &nbsp;
      span.grey--text.text--darken-4.font-weight-bold() {{ issue.report_date_time | moment("ddd, Do MMM YYYY, h:mm a") }} &mdash; &nbsp;
      timeago.IssueSmallText(:datetime='issue.report_date_time')
    .grey--text(v-if="issue.due_date_time") 
      .grey--text.text--darken-4 Due Date: &nbsp;
        span.font-weight-bold() {{ issue.due_date_time | moment("Do MMM YYYY") }}  
    .grey--text(v-if="issue.service_date_time") 
      | Resolved Date: &nbsp;
      span.grey--text.text--darken-4.font-weight-bold() {{ issue.service_date_time | moment("Do MMM YYYY") }} 
    .grey--text(v-if="issue.closed_by") 
      | Closed Date: &nbsp;
      span.grey--text.text--darken-4.font-weight-bold() {{ issue.closed_date_time | moment("Do MMM YYYY") }} 
    .caption.grey--text.mt-2() Odometer:
      span.font-weight-bold() &nbsp; {{ issue.odometer }}
</template>
<script>
export default {
  props: ["issue"],
  data: () => ({})
};
</script>
<style scoped lang="stylus">
.IssueName {
  font-weight: bold;
}

.IssueSmallText {
  font-size: 11px;
}

.labelTags {
  background-color: #CCCCCC;
  padding: 3px 6px;
  margin: 4px 6px;
  border-radius: 4px;
  font-size: 9px;
}
</style>
