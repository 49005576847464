<template lang="pug">
div(:style="getStyle" @mouseover="showEdit=true" @mouseout="showEdit=false" )
  div()
    .safetySystems(v-if="item.lens == 'photor' && item.safety")
      no-seatbelt.safetyIcon.mx-1(:color="item.safety.occupants_have_seatbelt ?'rgba(0,0,0,0.2)':'yellow'" :linewidth="0.7")
      no-phone.safetyIcon.mr-2(:color="item.safety.talking_on_phone ?'yellow':'rgba(0,0,0,0.2)'" :linewidth="0.7")
      not-driving.safetyIcon(:color="item.safety.is_distracted?'yellow':'rgba(0,0,0,0.2)'" :linewidth="0.7")
  .captureMetaData()
    v-icon.mr-2(:size="16" color="white" ) {{item.lens=='photor' ||item.lens=='photof' ? 'photo_camera':'videocam'}}
    .captureStyle() {{formatTime(item.capture_time)}}
    v-btn( text icon color="primary"  @click="onClick" x-small )
      v-icon(small) download
</template>
<script>
import Repository from "@/repository";
import moment from "moment/moment";
import NoSeatbelt from "@/modules/vehicles/layouts/dashCam/icons/NoSeatbelt.vue";
import NoPhone from "@/modules/vehicles/layouts/dashCam/icons/NoPhone.vue";
import NotDriving from "@/modules/vehicles/layouts/dashCam/icons/NotDriving.vue";

export default {
  components: {
    NoSeatbelt,
    NoPhone,
    NotDriving,
  },
  props: {
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },

    item: {
      type: Object,
      default: null
    },

    route: {
      type: Object,
      default: null
    },

    radius: {
      type: Number,
      default: 6
    },

    canDownload:{
      type: Boolean,
      default: false,
    },

    useThumbnail:{
      type: Boolean,
      default: true,
    },

    position:{
      type:String,
      default: "center"
    }
  },
  data: () => ({
    showEdit: false,
    resultObject: null
  }),

  computed: {
    getStyle: function() {
      var styleObject = {
        border: "1px solid #E5E5E5",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-position": this.position,
        display: "flex",
        "flex-direction": "column",
        "justify-content": "space-between",
        "align-items": "end",
        "border-radius": `${this.radius}px`,
        width: this.width + "px",
        height: this.height + "px",
        "font-size": "10px"
      };
      if (this.resultObject) {
        // path string
        let path = `/media/${this.resultObject.account_id}/${this.resultObject.thumbnail}`;

        // set the background
        styleObject["background-image"] = 'url("' + path + '")';
        styleObject["border-style"] = "solid";
        styleObject["border-width"] = "1px";
      } else {
        styleObject["border-style"] = "dashed";
        styleObject["border-width"] = "2px";
      }

      return styleObject;
    }
  },

  watch: {
    src: function(newValue) {
      console.log(`Watched hahahaha!! ${newValue}`);
      if (newValue) {
        this.loadImage();
      } else {
        this.resultObject = null;
      }
    }
  },


  methods: {
    formatTime(value){
      return moment(value).format("h:mm:ss a - ddd, Do MMM");
    },

    loadImage: function() {
      Repository.get(`/upload/blob/${this.item.blob_id}`)
        .then(payload => {
          this.resultObject = payload.data;
        })
        .catch(payload => {
          console.log(payload.data);
        });
    },

    navigateTo: function() {
      if (this.route) {
        this.$router.push(this.route).catch(()=>{});
      }
    },

    showEditButton: function() {
      if (this.route && this.showEdit) {
        return true;
      } else {
        return false;
      }
    },

    onClick:function() {
      this.fileLink = document.createElement("a");

      // this.fileLink.href = fileURL;
      this.fileLink.href = `/media/${this.resultObject.account_id}/${this.resultObject.file_name}`;


      this.fileLink.setAttribute("download", this.resultObject.original_name);
      document.body.appendChild(this.fileLink);
      this.fileLink.click();
    },




  },

  mounted() {
    if (this.item) {
      this.loadImage();
    }
  }
};
</script>
<style scoped lang="stylus">
.captureStyle{
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 1px 1px 1px black;
}
  .captureMetaData{
    display: flex;
    align-items center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: space-between
    padding: 0px 5px;
    border-bottom-right-radius:6px;
    border-bottom-left-radius: 6px;
  }
    .safetyIcon{
      width 20px;
      height 20px;

    }

      .safetySystems{
        padding: 3px 4px;
        background-color: rgba(0, 0, 0, 0.3);
        border-top-right-radius: 4px;
        border-bottom-left-radius: 10px;
      }
</style>
