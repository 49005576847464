import Dashboard from "@/modules/vehicles/layouts/overview/Dashboard";
import VehicleNew from "@/modules/vehicles/layouts/VehicleNewLayout";
import VehicleOverview from "@/modules/vehicles/layouts/VehicleOverview";
import VehicleComments from "@/modules/vehicles/layouts/comments/VehicleComments";
import VehiclePhotos from "@/modules/vehicles/layouts/photos/VehiclePhotos";
import DashCam from "@/modules/vehicles/layouts/dashCam/DashCam.vue";
import VehicleDocuments from "@/modules/vehicles/layouts/documents/VehicleDocuments";
import VehicleInspections from "@/modules/vehicles/layouts/inspections/VehicleInspections";
import VehicleIssues from "@/modules/issues/components/ui/OverviewIssues";
import VehicleFaults from "@/modules/vehicles/layouts/faults/VehicleFaults";
import VehicleReminders from "@/modules/vehicles/layouts/reminders/VehicleReminders";
import VehicleServiceHistory from "@/modules/vehicles/layouts/serviceHistory/VehicleServiceHistory";
import VehicleFuelLog from "@/modules/vehicles/layouts/fuelLog/VehicleFuelLog";
import VehicleAssignmentHistory from "@/modules/vehicles/layouts/assignmentHistory/VehicleAssignmentHistory";
import VehicleOdometerHistory from "@/modules/vehicles/layouts/odometerHistory/VehicleOdometerHistory";
import VehicleLocationHistory from "@/modules/vehicles/layouts/locationHistory/VehicleLocationHistory";

import DeviceForm from "@/modules/vehicles/components/forms/DeviceForm";
import ExpenseForm from "@/modules/expense/components/forms/ExpenseForm";

// path sample: http://localhost:8080/main/vehicle/0/overview/dashboard
export const VEHICLE_ROUTES = [
  {
    path: "new",
    name: "vehicleNew",
    component: VehicleNew
  },
  {
    path: "overview",
    name: "vehicleOverview",
    component: VehicleOverview,
    children: [
      {
        path: "dashboard",
        name: "vehicleDashboard",
        component: Dashboard
      },
      {
        path: "autoEye",
        name: "autoEye",
        component: DashCam
      },
      {
        path: "photos",
        name: "vehiclePhotos",
        component: VehiclePhotos
      },
      {
        path: "documents",
        name: "vehicleDocuments",
        component: VehicleDocuments
      },
      {
        path: "comments",
        name: "vehicleComments",
        component: VehicleComments
      },
      {
        path: "inspections",
        name: "vehicleInspections",
        component: VehicleInspections
      },

      {
        path: "issues",
        name: "vehicleIssues",
        component: VehicleIssues
      },

      {
        path: "faults",
        name: "vehicleFaults",
        component: VehicleFaults
      },

      {
        path: "reminders",
        name: "vehicleReminders",
        component: VehicleReminders
      },

      {
        path: "serviceHistory",
        name: "vehicleServiceHistory",
        component: VehicleServiceHistory
      },

      {
        path: "fuelLog",
        name: "vehicleFuelLog",
        component: VehicleFuelLog
      },

      {
        path: "assignmentHistory",
        name: "vehicleAssignmentHistory",
        component: VehicleAssignmentHistory
      },

      {
        path: "odometerHistory",
        name: "vehicleOdometerHistory",
        component: VehicleOdometerHistory
      },

      {
        path: "locationHistory",
        name: "vehicleLocationHistory",
        component: VehicleLocationHistory
      }
    ]
  },
  {
    path: "deviceForm",
    name: "deviceForm",
    component: DeviceForm
  },
  {
    path: "expenseForm",
    name: "expenseForm",
    component: ExpenseForm
  }
];
