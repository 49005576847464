import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";
import moment from "moment";

const TRIP_API = "/trip";

// ----------------------- STATE ------------------------- //
const state = {
  trip_data: [],
  currentIndex: -1,
  pathArray: [
    [36.8371433, -1.32593],
    [36.8360433, -1.32593]
  ],
  pathData: null,
  isLoading: false,
  isLoadingTrips: true,
  currentVehicleID: null,
  dateRange: { start: "2020-01-23 12:00 am", end: "2020-01-27" },
  testChartData: null
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getTrip: state => getField(state.trip_data[state.currentIndex]),

  getCurrentTrip: state => {
    if (state.trip_data.length == 0) return null;
    return state.trip_data[state.currentIndex];
  },

  getCurrentIndex: state => {
    return state.currentIndex;
  },

  getCurrentStartLocation: state => {
    if (state.currentIndex < 0) {
      return [36.8371433, -1.32593];
    }
    return [
      state.trip_data[state.currentIndex].start_location.lon,
      state.trip_data[state.currentIndex].start_location.lat
    ];
  },

  getCurrentEndLocation: state => {
    if (state.currentIndex < 0) {
      return [36.8360433, -1.32593];
    }
    return [
      state.trip_data[state.currentIndex].end_location.lon,
      state.trip_data[state.currentIndex].end_location.lat
    ];
  },

  getPath: state => {
    if (state.currentIndex < 0) {
      return [
        [36.8371433, -1.32593],
        [36.8360433, -1.32593]
      ];
    }
    if (state.pathData == null) {
      return [
        [
          state.trip_data[state.currentIndex].start_location.lon,
          state.trip_data[state.currentIndex].start_location.lat
        ],
        [
          state.trip_data[state.currentIndex].end_location.lon,
          state.trip_data[state.currentIndex].end_location.lat
        ]
      ];
    } else {
      return state.pathArray;
    }
  },
  getDateRange: state => {
    return state.dateRange;
  },



};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  distanceInLast7Days(state, payload) {
    state.testChartData = payload;
  },

  updateTrip(state, payload) {
    updateField(state.trip_data[state.currentIndex], payload);
  },

  setCurrentIndexByID(state, theID) {
    state.currentIndex = state.trip_data.findIndex(trip => trip.id == theID);
  },

  loadTrips(state, payload) {
    state.trip_data = payload.data;
    state.isLoading = false;
    state.isLoadingTrips = false;
    if (payload.data.length == 0) {
      state.currentIndex = -1;
    }
  },


  setCurrentIndex(state, theIndex) {
    state.currentIndex = theIndex;
  },

  loadPath(state, payload) {
    state.pathData = payload.data;
    let temp = [];
    state.pathData.forEach(element => {
      temp.push([element.location.lon, element.location.lat]);
    });
    state.pathArray = temp;
    state.isLoading = false;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },

  setCurrentVehicleID(state, payload) {
    state.currentVehicleID = payload;
  },

  setDateRange(state, payload) {
    state.dateRange = payload;
  },

  reset(state){
    state.trip_data= []
    state.currentIndex= -1
    state.pathArray= [
      [36.8371433, -1.32593],
      [36.8360433, -1.32593]
    ]
    state.pathData= null
    state.isLoading= false
    state.currentVehicleID= null
    state.dateRange= { start: "2020-01-23 12:00 am", end: "2020-01-27" }
    state.testChartData= null
  }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async distanceInLast7Days({ commit }, payload) {
    await Repository.post(`${TRIP_API}/distanceInLast7Days`, payload).then(
      response => {
        console.log(response.data);
        commit("distanceInLast7Days", response.data);
      }
    );
  },

  async loadTrips({ state, commit }) {

    await commit("setIsLoading", true);
    try {
      let fromUTC = moment(
        state.dateRange.start,
        "YYYY-MM-DD h:mm a"
      ).toISOString();

      let toUTC;

      if (state.dateRange.end == null) {
        toUTC = moment(state.dateRange.start, "YYYY-MM-DD h:mm a")
          .add(23, "hours")
          .add(59, "minutes")
          .toISOString();
      } else {
        toUTC = moment(state.dateRange.end, "YYYY-MM-DD h:mm a").toISOString();
      }

      console.log(`trips.js>> LOADING TRIPS........ fromUTC: ${fromUTC} toUTC: ${toUTC}`)
      await commit(
        "loadTrips",
        await Repository.get(
          `${TRIP_API}/vehicleMovement?vehicle_id=${state.currentVehicleID}&from=${fromUTC}&to=${toUTC}`
        )
      );
    } catch (error) {
      console.log(error);
    }
  },


  async loadPath({ state, commit }) {
    await commit("setIsLoading", true);
    try {
      let currTrip = state.trip_data[state.currentIndex];
      let obj = {};
      if (typeof currTrip == "undefined"){
        commit("loadPath", {data:[]});
        return;
      }
      obj.vehicle_id = state.currentVehicleID;
      obj.fromUTC = currTrip.min_time;
      obj.toUTC = currTrip.max_time;
      await commit(
        "loadPath",
        await Repository.get(
          `${TRIP_API}/vehiclePath?vehicle_id=${obj.vehicle_id}&from=${obj.fromUTC}&to=${obj.toUTC}`
        )
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
