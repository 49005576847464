<template lang="pug">
  div(v-if="this.$store.state.expenses.currentIndex > -1")
    v-toolbar(dense, flat)
      v-toolbar-title Expense Form
      v-spacer
    
    v-container.py-2
      v-alert(v-if="error" :value="true" color="error" icon="warning") 
        div(v-for="e,index in errors" :key="index" v-text="e")

      v-layout(justify-end)
        v-btn.ma-2(small text color="grey" @click="cancel") cancel
        v-btn.ma-2(small outlined color="primary" @click="saveAdd") Save &amp; Add another
        v-btn.ma-2(small color="primary" @click="save") Save

    v-container
      v-card
        v-card-text
          v-container(grid-list-xl)
            form-divider EXPENSES ENTRY DETAILS
            v-row()
              v-col(cols="12" xs="12" md="6" xl="4")
                vehicle-auto-complete(v-model='vehicle_id')
              v-col(cols="12" xs="12" md="6" xl="4")
                auto-combo-box(v-model='expense_type_id'  label="Select Expense Type" module="settings" submodule="expenseTypeSettings"   actionPrefix="ExpenseType")
              v-col(cols="12" xs="12" md="6" xl="4")
                contact-auto-complete(v-model='reported_by_id')
              v-col(cols="12" xs="12" md="6" xl="4")
                auto-date-time-picker(v-model="date_time", labelDate="Start Date", labelTime="Start Time") 
              v-col(cols="12" xs="12" md="6" xl="4")
                v-text-field(v-model.number='total', label='Total', hint='Total   Amount', persistent-hint)
              v-col(cols="12" xs="12" md="6" xl="4")
                merchant-auto-complete(v-model='merchant_id')
              v-col(cols="12" xs="12" md="12")
                v-textarea(v-model='description', label='Description', hint='Description', persistent-hint, :rows="3")
              form-divider.fill-width.mx-2() Expense Items
              v-col(cols="12" xs="12" md="12")
                .d-flex.flex-column.grey.lighten-2.rounded-xl.pa-4.my-2( )
                  table(id="expenseTableInput")
                    tr()
                      th() UPC Barcode
                      th() Quantity
                      th() Item
                      th() Cost Per Item
                      th() Amount
                      th() Action
                    tr(v-for="(item,index) in expense_items" :key="index" )
                      td.upc.pr-4() {{item.upc}}
                      td.qty() {{item.quantity}}
                      td()
                        .item.d-flex()
                          .itemImage(v-if="typeof item.photo_id !== 'undefined'" :style="{'background-image': `url(http://app.jahazi.com/api/media?q=${item.photo_id})`}")
                          .itemText() {{item.item}}
                      td.costPerItem.pr-4() {{item.cost_per_item}}
                      td.amount.pr-4.primary--text.font-weight-bold() {{item.amount}}
                      td()
                        .more()
                          more-drop-down-menu(:items='menuItems', @onClick='onClick', :theObject="{name:item.item,theindex:index}" )




                  .d-flex.mt-4.justify-end()
                    v-btn.mx-2( color="primary" @click="addEntry") Add Entry

            form-divider Attachments
            v-row()
              v-col(cols="12"  xs="12")
                attach-fill-form( :schema="imagesSchema" :data="{value:photos_id}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" :moreMenu="additionalImageMenuItems")

              v-col(cols="12"  xs="12")
                attach-fill-form( :schema="documentsSchama" :data="{value:documents_id}" @onChange="saveDocuments"  @onMenuAction="onMenuActionDocuments")

      v-layout.my-4(justify-end)
        v-btn.ma-2(small text color="grey" @click="cancel") cancel
        v-btn.ma-2(small outlined color="primary" @click="saveAdd") Save &amp; Add another
        v-btn.ma-2(small color="primary" @click="save") Save
    v-dialog(v-model="dialog" width="800" )
      v-card()
        v-card-title.px-8.grey.lighten-2() Edit Entry
        v-card-text.mb-0.pb-0()
          template(v-if="selectedExpenseItemIndex > -1 && getSelectedExpenseItem")
            v-container.mt-2()
              v-row()
                v-col(cols="6")
                  v-col.d-flex.flex-column.align-center(cols="12" :key="dialogPhotoIndex")
                    template(v-if="typeof getSelectedExpenseItem.photo_id == 'undefined' || getSelectedExpenseItem.photo_id == null")
                      attach-fill-form( :schema="itemImageSchema" :data="{value:null}" @onChange="saveItemImage"  @onMenuAction="onMenuActionImages")
                    template(v-else)
                      .editPhoto(:style="{'background-image': `url(http://app.jahazi.com/api/media?q=${getSelectedExpenseItem.photo_id})`}")
                        .d-flex.justify-center.align-start()
                          v-btn.mx-2.mt-4(small @click="onClickImage") {{getSelectedExpenseItem.photo_id == null?"Add Photo":"Delete Photo"}}

                v-col(cols="6")
                  v-row()
                    v-col(cols="12")
                      v-text-field.mt-2(v-model.number='getSelectedExpenseItem.quantity', label='Quantity', hint='Quantity', persistent-hint)
                      v-text-field.my-2(v-model='getSelectedExpenseItem.item', label='Item', hint='Expense Item', persistent-hint)
                      v-text-field.my-2(v-model.number='getSelectedExpenseItem.cost_per_item', label='Cost Per Item', hint='Cost Per Item', persistent-hint)
                      v-text-field.my-2(v-model='getSelectedExpenseItem.amount', label='Amount', hint='Expense Amount', persistent-hint)
                      v-text-field.mb-2(v-model='getSelectedExpenseItem.upc', label='UPC', hint='Product Bar Code', persistent-hint)
          template(v-else)
            div() No Data Selected


        v-divider()
        v-card-actions()
          v-layout(justify-end)
            v-btn.ma-2(small @click="onCancelEntry") Cancel
            v-btn.ma-2(color="primary" small @click="onSaveEntry") Save

</template>
<script>
import VehicleAutoComplete from "@/modules/global/components/forms/VehicleAutoComplete";
import ContactAutoComplete from "@/modules/global/components/forms/ContactAutoComplete";
import MerchantAutoComplete from "@/modules/global/components/forms/MerchantAutoComplete";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import AutoComboBox from "@/modules/global/components/forms/AutoComboBox";
import AutoDateTimePicker from "@/modules/global/components/forms/AutoDateTimePicker";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail.vue";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu.vue";
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "expenses/getExpense",
  mutationType: "expenses/updateExpense"
});
export default {
  components: {
    VehicleAutoComplete,
    ContactAutoComplete,
    MerchantAutoComplete,
    FormDivider,
    AutoComboBox,
    AutoDateTimePicker,
    AttachFillForm,
    ImageThumbnail,
    MoreDropDownMenu
  },
  data: () => ({
    error: false,
    errors: [],
    dialog:false,
    dialogPhotoIndex:0,
    selectedExpenseItemIndex:-1,

    selectedItem:{
      quantity:0,
      item:null,
      cost_per_item:null,
      amount:null,
      photo_id:null,
      upc:null,
    },

    menuItems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ],
    expenseHeaders: [
      {text: "Quantity", value: "quantity"},
      {text: "Item", value: "item"},
      {text: "Cost Per Item", value: "cost_per_item", align: "center"},
      {text: "Amount", value: "amount"},
      {text: "UPC (Barcode)", value: "upc"},
      {text: "Action", value: "action", align: "right"}
    ],

    imagesSchema: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Vehicle Images",
      hint: null,
      type: "image",
      image_size: "small",
      multiple: true
    },

    itemImageSchema: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Expense Image",
      hint: null,
      type: "image",
      image_size: "small",
      multiple: true
    },

    additionalImageMenuItems: [
      { title: "Make Thumbnail", icon: "edit", action: "make_thumbnail" }
    ],

    documentsSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Vehicle Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true
    }
  }),
  methods: {
    cancel: function() {
      console.log("cancel");
    },

    onClick: function(value) {

      this.selectedExpenseItemIndex = value.theObject.theindex;



      switch(value.action){
        case "edit":
          this.dialog = true;
          break;
        case "delete":
          confirm(
              `Are you sure you want to delete the item`
          ) && this.expense_items.splice(this.selectedExpenseItemIndex,1)
          break;
      }

    },

    addEntry:function() {
      console.log(this.expense_items)
      if (this.expense_items == null){
        this.expense_items = [];
        this.$store.commit("expenses/initExpenseItems")
      }

      this.expense_items.push({
        quantity:1,
        item:null,
        cost_per_item:null,
        amount:null,
        photo_id:null,
        upc:null,
      })
      this.selectedExpenseItemIndex = this.expense_items.length - 1;
      this.dialog =true;
    },



    onCancelEntry: function() {
      this.dialog = false;
    },

    onSaveEntry: function() {
      this.dialog = false;
    },

    save: function() {
      console.log(this.$store.getters["expenses/getCurrentExpense"]);
      this.$store.dispatch("expenses/saveExpense").then(() => {
        this.$store.dispatch("expenses/loadAllExpenses");
        this.$router.push({ name: "vehicles", params: { tabID: 3 }}).catch(()=>{});
      });
    },

    saveAdd: function() {
      console.log("saveAdd");
    },

    saveImages: function(theObject) {
      this.$store.commit("expenses/updateExpense", {
        path: "photos_id",
        value: theObject.value
      });
    },

    saveItemImage:function(theObject) {
      this.getSelectedExpenseItem.photo_id = theObject.value[0]
      this.dialogPhotoIndex +=1;
    },

    saveDocuments: function(theObject) {
      this.$store.commit("expenses/updateExpense", {
        path: "documents_id",
        value: theObject.value
      });
    },
    onMenuActionImages: function(value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("expenses/deleteImage", value.theObject.id);
          break;
      }
    },

    onMenuActionDocuments: function(value) {
      console.log(value.theObject.id);
      switch (value.action) {
        case "delete":
          this.$store.commit("expenses/deleteDocument", value.theObject.id);
          break;
      }
    },

    onClickImage: function() {
      if (this.expense_items[this.selectedExpenseItemIndex].photo_id != null){
        this.expense_items[this.selectedExpenseItemIndex].photo_id = null;
      }else{
        console.log("Upload the file")
      }
    }
  },
  computed: {
    getSelectedExpenseItem() {
        return this.expense_items[this.selectedExpenseItemIndex];


    },

    ...mapFields([
      "vehicle_id",
      "reported_by_id",
      "total",
      "expense_type_id",
      "expense_items",
      "date_time",
      "merchant_id",
      "description",
      "photos_id",
      "documents_id"
    ])
  },
  mounted() {
    this.$store
      .dispatch("expenses/fetchExpense", this.$route.params.vehicleID)
      .then(() => {
        console.log(this.$store.getters["expenses/getCurrentExpense"]);
      });
  }
};
</script>
<style scoped lang="stylus">
.editPhoto{
  width: 100%;
  height: 370px;
  border-radius: 20px;
  background-color: #EEEEEE;
  background-size: cover;
}

  #expenseTableInput {
    background-color: #f9f9f9;
    border-collapse: collapse;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px
  }

#expenseTableInput th{
  border 1px solid #cacaca;
  background-color: #FFF;
  font-size: 14px;
  padding: 4px;
}
#expenseTableInput td {
  border 1px solid #ebebeb;
  color: #3f3f3f;
  padding: 4px;
}

#expenseTableInput .qty {
  text-align: center;
}
#expenseTableInput .itemImage{
  height: 60px;
  width: 60px;
  border-radius: 10px;
  margin-right: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#expenseTableInput .item{
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
}

#expenseTableInput .costPerItem{
  text-align: right;
}

#expenseTableInput .amount{
  text-align: right;
}

#expenseTableInput .upc{
  text-align: center;
}

#expenseTableInput .more{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


</style>
