<template lang="pug">
div
  v-divider
  v-container.ma-2(fluid)
    .d-flex.align-center.justify-space-between
      h2 Fuel Log
      v-spacer
      v-btn.mr-2(small, outlined color='primary' @click="refresh")
        v-icon(dark) refresh
        | Refresh
      div()
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn(small, outlined, color='primary', dark, v-on="on")
              | Actions
              v-icon(dark) keyboard_arrow_down
          v-list
            v-list-item-group(v-model="action" color="primary")
              v-list-item(v-for='item in items', :key='item.title' @click='onAction(item.value)')
                v-list-item-icon()
                  v-icon() {{item.icon}}
                v-list-item-title {{ item.title }}




      v-btn.mx-2(small, color='primary', dark, @click='openEdit') Add Fuel Entry
  v-divider
  v-progress-linear(v-if="isLoading" :indeterminate="isLoading")
  //- v-layout.mx-4.my-2(row, wrap, align-center)
    v-layout(align-center, style='max-width:900px;')
      v-menu.ma-2(ref='menu2', :close-on-content-click='false', v-model='menu2', :nudge-right='40', :return-value.sync='date',  transition='scale-transition', offset-y, , min-width='290px')
        template(#activator="{on}")
          v-text-field(v-on="on", v-model='date', label='Picker without buttons', prepend-icon='event', readonly)
        v-date-picker(v-model='date', @input='$refs.menu2.save(date)')
      v-text-field.pl-2(style='width:10px;', v-model='vehiclesFilter', label='Vehicles Filter', :persistent-hint='true', required)
      v-btn.ma-2(small, color='primary', dark) Search
      div
        span.primary--text 1 filter
        span.pl-1.grey--text.text--darken-1 applied
        span.px-3.grey--text.text--darken-1 clear all
  v-divider
  v-layout.mx-3.my-2.align-center(v-if="getMetrics" row, wrap)
    fuel-bar-chart.ml-4()
    .listHeaderMetric()
      .primary--text.font-weight-bold() {{$store.state.profile.userInfo.account.region_settings.currency}} {{getMetrics.month_price}}
      .grey--text.text--darken-2() {{getCurrentMonthName}} Cost
    .listHeaderMetric()
      .primary--text.font-weight-bold() {{getMetrics.month_volume}} L
      .grey--text.text--darken-2() {{getCurrentMonthName}} Litres
    fuel-pie-chart()
    form-header-total.mx-8(:value="`${$store.state.profile.userInfo.account.region_settings.currency} ${getMetrics.total_price}`") Total Cost
    form-header-total.mx-8(:value="`${getMetrics.total_volume}`") Total Litres
  //- v-divider
  //- v-layout.mx-4.my-1(row align-center)
    v-row()
      v-col(cols="12" xs="12" md="5" xl="3")
        .d-flex.align-center()
          v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :prepend-icon="'search'" )
          v-btn(v-if="search.length > 0" text outlined small @click="search=''") clear all
  v-divider
  .grey.lighten-4.py-3
  v-divider
  fuel-table( :loading="isLoading")
  v-divider
  v-dialog(v-model="dialog" width="500")
    select-vehicles(v-if="dialog" :showDialog="true" @close="onCloseDialog"  @save="onSaveDialog")
</template>

<script>
import FormHeaderTotal from "@/modules/global/components/forms/FormHeaderTotal";
import FuelTable from "@/modules/fuel/components/tables/FuelTable";
import FuelBarChart from "@/modules/fuel/components/tables/FuelBarChart.vue";
import moment from "moment";
import FuelPieChart from "@/modules/fuel/components/tables/FuelPieChart.vue";
import SelectVehicles from "@/modules/global/components/dialog/SelectVehicles.vue";

export default {
  components: {
    FuelPieChart,
    FormHeaderTotal,
    FuelTable,
    FuelBarChart,
    SelectVehicles
  },
  data: () => ({
    dialog: false,
    search: "",
    action: null,
    isLoading:true,
    items: [
      {
        title: "Export PDF",
        value: "export_pdf",
        icon: "download"
      },
      {
        title: "Export Excel",
        value: "export_xls",
        icon: "download"
      },
      {
        title: "Delete Selected",
        value: "delete_selected",
        icon: "delete"
      },
    ],

    vehiclesFilter: "",

    date: null,
    menu: false,
    modal: false,
    menu2: false
  }),

  computed:{
    getMetrics:function(){
      return this.$store.getters["fuel/getMetrics"];
    },
    getCurrentMonthName: function (){
      return moment().format("MMMM YYYY")
    }
  },

  methods: {
    openEdit: function() {
      this.$store.dispatch("fuel/addFuel").then(() => {
        let currentObject = this.$store.state.fuel.fuel_data[
          this.$store.state.fuel.currentIndex
        ];
        this.$router.push({
          name: "fuelDetailsForm",
          params: { fuelID: currentObject.id }
        }).catch(()=>{});
      });
    },

    showDialog: function() {},

    closeDialog: function() {},

    refresh: function(){
      this.isLoading = true;
      this.$store.dispatch("fuel/loadAllFuels").then(()=>{
        this.isLoading = false;
      })
    },

    onCloseDialog: function(){
      this.dialog = false;
      this.isLoading = false;
    },

    onSaveDialog: function(result){
      this.dialog = false;
      var vehicle_ids = "";

      result.selectedVehicles.forEach(vehicle => {
        vehicle_ids += "&vehicle_id="+vehicle.id
      })

        window.open(`/api/renderhtml?type=fuelReport&from=${result.from}&to=${result.to}&account_id=${this.$store.state.profile.userInfo.account.id}${vehicle_ids}`,"_blank")
    },

    onAction: function(value) {
      switch (value) {
        case "export_pdf":
          this.dialog = true;
          break;
        case "export_xls":
          var a = document.createElement("a");
          a.href=`/api/report/fuelExcel`;
          a.download = `fuel.xlsx`
          a.target='_blank';
          a.click();
          break;
        case "delete_selected":
          if (this.$store.state.fuel.selectedFuels.length == 0){
            this.$store.commit("showSnackBar", {
              color: "error",
              message: `No Checkbox Selected`
            });
            break;
          }
          confirm(`Are you sure you want to delete ${this.$store.state.fuel.selectedFuels.length} items?`) &&
          this.$store.dispatch("fuel/deleteSelectedFuels");
          break;
      }
    }
  },

  mounted() {
    // console.log(this.$vuetify.theme.primary);
    this.isLoading = true;
    if (this.$store.state.fuel.currentIndex == -1) {
      // load Data
      this.refresh();
    }else{
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.listHeaderMetric {
  border: 2px solid #CCC;
  background-color: #f5f5f5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  margin: 10px 30px;
}

</style>
