<template lang="pug">
  div(v-if="isLoaded")
    v-toolbar(dense flat)
      generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer()
      .d-flex.align-center()
        .mr-2(v-if="$store.state.workflow.workflowRecord.record_data.length > 1")
          v-btn.mx-4(icon small @click="previous")
            v-icon() skip_previous
          v-btn.mx-4(icon small @click="next")
            v-icon() skip_next
        .mr-2(v-else)
            v-btn(outlined color="primary" small @click="loadAll")
              v-icon() refresh
              | Load All
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn.ma-2(small, color='primary', dark, v-on="on")
              | Export
              v-icon(dark) keyboard_arrow_down
          v-list-item-group.white()
            v-list-item(v-for='item in items', :key='item.title' dense @click="onAction(item.value)")
              v-list-item-title
                v-layout(align-center)
                  v-icon.mr-2(:size="20") {{item.icon}}
                  .subtitle-2.mx-2() {{ item.title }}
        v-btn.mx-2(color="primary" small @click="editRecord") Edit Record
    v-divider()
    v-progress-linear(v-show="isReloading" :indeterminate="isReloading")
    v-container.mt-8.pa-8.white(fluid)
      v-row()
        v-col(md="6" sm="12")
          v-card(color="white")
            record-details-panel(:record="getCurrentRecord" :workflowTemplate="getCurrentWorkflowTemplate")

          v-card.mt-12(color="white")
            location-map-panel(v-if="getCurrentRecord.geotag" :coordinates="getCurrentRecord.geotag" )
        v-col(md="6" sm="12")
          v-card(color="white")
            record-items-panel(:schema="getRecordSchema()" :record="getCurrentRecord" :workflowTemplate="getCurrentWorkflowTemplate")


</template>

<script>
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import RecordDetailsPanel from "@/modules/workflow/records/components/ui/RecordDetailsPanel.vue";
import LocationMapPanel from "@/modules/global/components/panel/LocationMapPanel";
import RecordItemsPanel from "@/modules/workflow/records/components/ui/RecordItemsPanel.vue";
export default {
  components:{
    GenericBreadCrumbs,
    RecordDetailsPanel,
    LocationMapPanel,
    RecordItemsPanel
  },
  data:()=>({
    isLoaded:false,
    showExportDialog: false,
    fileExport: "",
    isReloading: false,
    items: [
      {
        title: "Export Pdf",
        icon: "cloud_download",
        value: "export_pdf"
      },
      {
        title: "Copy Link to Clipboard",
        icon: "content_copy",
        value: "copy_link"
      },
      {
        title: "Open Link",
        icon: "open_in_new",
        value: "open_link"
      }
    ],
    breadCrumbsItems: [
      {
        text: "Workflow",
        disabled: false,
        path: "workflowRecordsTable"
      },
      {
        text: "Record Detail",
        disabled: true
      },
    ],
  }),

  mounted() {

      if(this.$store.state.workflow.workflowRecord.currentIndex == -1){
        console.log(this.$route.params.workflowID)
        console.log("--------------workflowid--------------")
        this.$store.dispatch("workflow/fetchWorkflow",this.$route.params.workflowID).then(()=>{
          this.$store.dispatch("workflow/workflowRecord/fetchRecord",this.$route.params.workflowRecordID).then(()=>{
            this.isLoaded = true;
          })
        })

      }else{
        this.isLoaded = true;
      }


  },
  computed: {
    getCurrentRecord: function() {
      let currentRecord = this.$store.getters["workflow/workflowRecord/getCurrentRecord"];
      // console.log("current Record")
      // console.log(currentRecord)
      currentRecord = this._.clone(currentRecord)
      return currentRecord
    },
    getCurrentWorkflowTemplate: function() {
      return this.$store.getters["workflow/getCurrentWorkflow"]
    }
  },
  methods:{
    getRecordSchema: function () {
      return this.getCurrentWorkflowTemplate.data[this.getCurrentRecord.workflow_template_version - 1].schema;
    },

    editRecord: function(){
      this.$router.push({
        name: "workflowRecordForm",
        params: {workflowID:this.$route.params.workflowID}
      }).catch(()=>{});

    },
    onAction: function(value) {
      switch (value) {
        case "export_pdf":
          this.fileExport = "PDF";
          console.log("action PDF")
          window.open(`/api/renderhtml?type=workflowRecordPDF&id=${this.getCurrentRecord.id}`);
          break;
        case "copy_link":
          navigator.clipboard.writeText(`https://app.jahazi.com/api/renderhtml?type=workflowRecord&id=${this.getCurrentRecord.id}`);
          this.$store.commit("showSnackBar", {
            color: "accent",
            message: `Copied to Clipboard`,
          });
          break;
        case "open_link":
          window.open(`/api/renderhtml?type=workflowRecord&id=${this.getCurrentRecord.id}`);
          break;
      }
      this.showExportDialog = !this.showExportDialog;
    },


    next(){
      this.$store.commit("workflow/workflowRecord/next");
    },
    previous() {
      this.$store.commit("workflow/workflowRecord/previous");
    },

    loadAll() {
      this.isReloading = true;
      let tempID = this.getCurrentRecord.id


      this.$store.dispatch("workflow/workflowRecord/loadAllRecordsByID",this.$route.params.workflowID).then(()=>{
        this.$store.commit("workflow/workflowRecord/setCurrentIndexByID",tempID)
        console.log(tempID)
        this.isReloading = false;
      })
    }
  }
}
</script>

<style scoped lang="stylus">

</style>