<template lang="pug">
div(v-if="isLoaded")
  bar-chart(style="height:140px" :chartdata="chartData" :options="chartOption" :key="keyCounter")
  .d-flex.caption.justify-center()
    .mx-3(:class="['pointer grey px-2 rounded white--text']" @click="onClick" ) {{isMonths ? 'monthly':'weekly'}}
    //- .mx-3(:class="!isMonths ? ['primary--text font-weight-bold text-decoration-underline']:['pointer grey px-2 rounded white--text']" @click="onClick") weekly
</template>
<script>

import BarChart from "@/modules/global/components/charts/BarChart";
import moment from "moment";

export default {
  components:{
    BarChart
  },
  computed:{
    monthlyBars() {
      return this.$store.state.fuel.metrics.monthly_bars;
    },
    weeklyBars() {
      return this.$store.state.fuel.metrics.weekly_bars;
    }
  },
  data:()=>({
    isLoaded: false,
    keyCounter: 0,
    isMonths: true,
    chartOption : {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
              beginAtZero: true,
            },

          }
        ],
        xAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      },
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 10,
          bottom: 0
        }
      }
    },
    chartData: {
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May'
      ],
      datasets: [
        {
          label: 'Fuel Cost',
          backgroundColor: '#63c5c7',
          data: [40, 20, 12, 39, 30]
        }
      ]
    },
  }),
  methods:{

    loadData: async function() {
      this.isLoaded = false;
      let keys = [];
      let values = [];
      if (this.isMonths){
        this.monthlyBars.forEach(item => {
          keys.push(moment(item.unit).format("MMM"))
          values.push(item.price)
        })
      }else{
        this.weeklyBars.forEach(item => {
          keys.push(moment(item.unit).format("Do MMM"))
          values.push(item.price)
        })
      }

      this.chartData.labels = keys;
      this.chartData.datasets[0].data = values;
      this.isLoaded = true;
    },

    onClick: function(){
      this.isMonths = !this.isMonths;
      this.loadData();
      this.keyCounter += 1;
    },

  },

  mounted() {
    this.loadData();
    this.keyCounter += 1;
  }
}
</script>



<style scoped lang="stylus">

</style>