<template lang="pug">
  #blankcontent()
    .panelTitle() Vehicle Faults
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-space-around.align-center.fill-height()
          .d-flex.flex-column.align-center()
            .tickerTitle.secondary--text() {{VehicleOpenFaults}}
            .tickerCaption.grey--text.text--darken-1() Vehicle Open Faults
          .d-flex.flex-column.align-center()
            .tickerTitle.primary--text() {{VehiclePendingFaults}}
            .tickerCaption.grey--text.text--darken-1() Vehicle Pending Faults


</template>
<script>
export default {
  data: () => ({}),
  mounted() {},

  computed: {
    VehicleOpenFaults() {
      return this.$store.getters["dashboard/getVehicleOpenFaults"];
    },
    VehiclePendingFaults() {
      return this.$store.getters["dashboard/getVehiclePendingFaults"];
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.tickerTitle {
  font-size: 45px;
}

.tickerCaption {
  margin-top: -10px;
  font-size: 12px;
}
</style>
