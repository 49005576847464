<template lang="pug">
  .pa-3()
    v-layout(row wrap)
      v-flex.px-3()
        v-card.my-3(color="white")
          v-data-table.elevation-1(
            :headers="getHeaders()",
            :items="$store.state.customer.inventories",
            item-key="id"
            :single-select="false"
            show-select
            :items-per-page="footerProps.pagnation.itemsPerPage",
            :footer-props="footerProps"
            @click:row="onClickRow"
          )
            template(v-slot:item.item="{item}")
              div() {{item.item.name}}
            template(v-slot:item.vehicle="{item}")
              vehicle-column(v-if="item.vehicle" :vehicle="item.vehicle")
            template(v-slot:item.submitted="{item}")
              div(v-if="item.submitted")
                v-avatar(color="grey lighten-2" :size="30" ) {{item.submitted.first_name[0]}}{{item.submitted.last_name[0]}}
            template(v-slot:item.start_at="{item}")
              div()
                div.grey--text() Start:&nbsp;
                  span.black--text(v-if="item.start_at") {{getFormattedTime(item.start_at)}}
                div.grey--text() End: &nbsp;
                  span.primary--text(v-if="item.end_at") {{getFormattedTime(item.end_at)}}
            template( v-slot:item.action="{ item }")
              v-row.mr-7(justify="end")
                more-drop-down-menu(:items='menuItems', @onClick='onClick', :theObject='item')

            template( v-slot:item.credit="{ item }")
              .d-flex.align-start(v-if="item.credit")
                v-icon(color="green") arrow_drop_up
                .green--text.font-weight-bold() {{item.credit}} {{item.item.measurement_unit != null ? item.item.measurement_unit.name : 'units'}}
            template( v-slot:item.debit="{ item }")
              .d-flex.align-start(v-if="item.debit")
                v-icon(color="primary") arrow_drop_down
                .primary--text.font-weight-bold() {{item.debit}} {{item.item.measurement_unit != null ? item.item.measurement_unit.name : 'units'}}
            template( v-slot:item.details="{ item }")
              div(v-if="getDetails(item.details).length" )
                div(v-for="result in getDetails(item.details)" :key="result.label")
                  div.grey--text.text--darken-2() {{result.label}} {{result.value}}


</template>
<script>

import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import ItemColumnById from "@/modules/global/components/table/ItemColumnById";
import moment from "moment";
export default {
  components: {
    MoreDropDownMenu,
    VehicleColumn,
    ItemColumnById
  },
  data: () => ({
    items:[],
    menuItems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ],
    footerProps: {
      "items-per-page-options": [5, 10, 20, 50, 100, 1000],
      pagnation: {itemsPerPage: 20}
    },
  }),
  computed: {
    customer: function() {
      return this.$store.getters["customer/getCurrentCustomer"]
    },
  },

  methods:{
    getDetails: function(details){
      let result =[];
      const keys = Object.keys(details);
      keys.forEach((key) => {

        result.push({label:key,value:details[key]})
      })
      return result;

    },


    getHeaders: function() {
      let headers = [
        {text: "Item", value: "item"},
        {text: "Vehicle", value: "vehicle"},
        {text: "Submitted By", value: "submitted"},
        {text: "Duration", value: "start_at"},
        {text: "Credit", value: "credit"},
        {text: "Debit", value: "debit"},
        {text: "Detail", value: "details"},
      ];

      // insert new fields here
      if(this.currentItem && this.currentItem.item_details && this.currentItem.item_details.custom_fields){
        this.currentItem.item_details.custom_fields.forEach(element => {
          headers.push({text: element.label, value: `details.${element.label_id}`, align: "right"})
        })
      }


      headers.push({text: "Action", value: "action", align: "right"})
      return headers;
    },

    getFormattedTime: function (value) {
      return moment(value).format("ddd, Do MMM YYYY")
    },
    onClickRow: function (value) {
      console.log(value)

    },
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("inventory/item/setCurrentIndexByID", this.$route.params.itemID)
          if (value.theObject.credit != null) {
            this.$store.commit("inventory/setMode", "add");
          } else {
            this.$store.commit("inventory/setMode", "delete")
          }
          this.$store.commit("inventory/setCurrentIndexByID", value.theObject.id)
          this.$router.push({
            name: "inventoryForm",
            params: {itemID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          this.$store.dispatch("inventory/deleteInventory", value.theObject.id);
          break;
      }
    }

  },


};
</script>
<style scoped lang="stylus">

</style>
