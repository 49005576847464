<template lang="pug">
  div
    .waypointContainer(v-if="orderDetails")
      div(v-if="tspDetails")
        container(lock-axis='y' orientation='vertical' drag-handle-selector='.list-drag-handle' @drop='onListDrop')
          draggable.waypointStyle(v-for="(element,index) in orderDetails" :key="index")
            .rowContainer.d-flex(style="width:100%; height:100%"  @click="onSelectWaypoint(element)")
              .list-drag-handle.mr-2(v-if="!routeLocked")
                  v-icon.zeDragIcon(:size="20" color="white") unfold_more
              .waypointDiv()
                div(v-if="element.place" )
                  .body-2()
                    span.font-weight-bold(v-html="`${getIndex(element) + 1}: ${element.place.name}`")
                    span.grey-text(v-if="element.place.description" v-html="` &ndash; ${element.place.description}`")
                  .caption.grey--text.text--darken-3(v-if="element.place.street" v-html="`${element.place.street}`")

                  .verySmallText.primary--text.text--darken-1(v-if="element.place && element.place.customers_id")
                    span(v-for="(id,index) in element.place.customers_id" :key="index")
                      span(v-if="getCustomer(id)")
                        span(v-if="getCustomer(id).reference_id") {{getCustomer(id).reference_id }} &nbsp;
                        span() {{getCustomer(id).name}}, &nbsp;
                  .verySmallText.primary--text.text--darken-1(v-if="element.place && element.place.merchants_id")
                    span(v-for="(id,index) in element.place.merchants_id" :key="index")
                      span(v-if="getMerchant(id)") {{getMerchant(id).name}}, &nbsp;

                .betweenLegs(v-if="element.leg_distance && index > 0")
                  .font-weight-medium() {{(element.leg_distance/1000.0).toFixed(2)}} &nbsp;
                    span.caption() Km
                  .caption.font-weight-bold(style="margin-top:-3px" v-text="formatDurationTime(element.leg_duration)")

              div.pl-2(v-if="!routeLocked")
                template(v-if="index == 0 || index == orderDetails.length-1")
                template(v-if="index == 0 || index == orderDetails.length-1")
                  div()
                    v-icon(@click="onRemoveSelectedPlace(element)") close
                  div()
                    img.ml-1(src="/static/web/finish-flag.png" width="16" height="16")
                template(v-else)
                  v-icon(@click="onRemoveSelectedPlace(element)") close




</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";
import moment from "moment";

export default {
  components:{
    Container,
    Draggable
  },
computed:{

  data:()=>({
    durationScaleFactor: 1.2,
    legStopTimeInSeconds: 120,
    showDialog: true,
    dragging: false,

    list: [
      {name: "John", id: 0},
      {name: "Joao", id: 1},
      {name: "Jean", id: 2},
      {name: "Sam", id: 3},
      {name: "Peter", id: 4},
      {name: "Jane", id: 5},
      {name: "James", id: 5},
      {name: "Jayden", id: 6},
    ],
  }),

  orderDetails:{
    get:function(){
      this.$store.commit("routing/updateOrderDetailPlaces",this.$store.state.place.place_data);

      return this.$store.getters["routing/getCurrentRouting"].order_details;
    }
  },


  tspDetails:function(){
    return this.$store.getters["routing/getCurrentRouting"].tsp_details
  },




  routeLocked:function(){
    return this.$store.getters["routing/getCurrentRouting"].locked;
  }
},

  methods: {
    getGroup(groupID) {
      if (groupID == null){
        return null;
      }
      var result = this.$store.getters["settings/groupSettings/getGroupByID"](groupID)
      if (result == null) {
        return null
      }else{
        return result;
      }
    },

    getCustomer(customerID) {
      var result = this.$store.getters["customer/getCustomerByID"](customerID)
      if (result == null || result == undefined) {
        return null
      }else{
        return result;
      }
    },

    getPlaceById :function(placeID) {
      return this.$store.getters["place/getPlaceByID"](placeID)
    },

    placeExists: function(placeID){
      return  typeof this.$store.getters["place/getPlaceByID"](placeID) == "undefined"
    },

    getPlaceName: function(element){
      let place = this.getPlaceById(element.place_id)
      if (typeof(place) != "undefined" && place.name != null) {
        return place.name
      }else{
        return ""
      }
    },

    getStreetName: function(element){
      let place = this.getPlaceById(element.place_id)
      if (typeof(place) != "undefined" && place.name != null) {
        return place.street
      }else{
        return ""
      }
    },

    getPlaceDescription: function(element){
      let place = this.getPlaceById(element.place_id)
      if (typeof(place) != "undefined" && place.description == null) {
        return place.description
      }else{
        return ""
      }
    },

    getMerchant(merchantID) {
      var result = this.$store.getters["merchant/getCustomerByID"](merchantID)
      if (result == null || result == undefined) {
        return null
      }else{
        return result;
      }
    },


    getIndex:function(element){
      if(this.routeLocked){
        return typeof element.waypoint_index == "undefined" ? 0 : element.waypoint_index;
      }else{
        return element.order_id;
      }
    },

    onRemoveSelectedPlace:function(item){
      this.$store.commit("routing/deleteFromSelected",item.id);
      this.$emit("calculate")
    },

    formatDurationTime: function (durationInSeconds) {
      let theSeconds = moment
          .utc(moment.duration(durationInSeconds, "seconds").asMilliseconds())
          .format("HH:mm:ss");

      return theSeconds;
    },

    onSelectWaypoint:function(thePlace){

      if(this.routeLocked){
        this.$store.commit("routing/setSelectedWaypoint",thePlace.waypoint_index);
      }else{
        this.$store.commit("routing/setSelectedWaypoint",thePlace.order_id);
      }

    },

    onListDrop:function(value){
      console.log(value)

      this.$store.commit("routing/setNewOrderDetails",value);
      this.$emit("calculate");

    }
  },
}
</script>

<style scoped lang="stylus">
.smallText {
  font-size: 5px;
}

.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.waypointStyle {

  display: flex;
  align-items: flex-start;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin: 10px 10px 10px 10px;
  padding: 0px 10px;
  justify-content: center;


}
.waypointStyle:hover{
  background-color: #ECECEC;
  cursor: pointer;


}

.waypointContainer {
  overflow-y: auto;
  height: 100%;
}

.waypointDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.verySmallText {
  font-size: 10px;
  margin-top: -3px;
  margin-bottom: 2px;
}

.betweenLegs {
  min-width: 100px;
  background-color: #f5f5f5;
  height: 36px;
  margin-top: 0px;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  z-index 50;
}

.dragHolder{
  display flex
  background-color #cccccc
  border-radius 50%
}

.zeDragIcon{
  background-color #cccccc
  border-radius 50%;
  width 22px;
  height 22px;
}

</style>