<template lang="pug">
  .fill-width.pa-2()
    v-toolbar(dense flat)
      //v-toolbar-title Workflow Permissions
      v-spacer
      v-btn.mb-2.mx-2(small, color='primary',outlined, dark, @click='cancel') Cancel
      v-btn.mb-2.mx-2(small, color='primary', dark, @click='save') Save

    .mx-4(v-if="$store.state.workflow.workflow_data.length > 0")
      form-divider.mb-2 Workflow Permissions
      v-row.py-4()
        v-col(cols="12" xs="12" md="6" xl="6")
          roles-auto-complete(v-model="read_permissions_id" :multiple="true" label="Read Permissions")
        v-col(cols="12" xs="12" md="6" xl="6")
          roles-auto-complete(v-model="edit_permissions_id" :multiple="true" label="Edit Permissions")
      form-divider.mb-2 Workflow Approvals
      v-row.py-4.align-end()
        v-col(cols="12" xs="12" md="6" xl="6")
          contacts-auto-complete(v-model="contacts_approval" :multiple="true" label="Contacts Approval"  )
        v-col(cols="12" xs="12" md="6" xl="6")
          roles-auto-complete.mb-1(v-model="roles_approval" :multiple="true" label="Roles Approval")


</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import RolesAutoComplete from "@/modules/global/components/forms/RolesAutoComplete.vue";
import { createHelpers } from "vuex-map-fields";
import ContactsAutoComplete from "@/modules/global/components/forms/ContactsAutoComplete.vue";

const { mapFields } = createHelpers({
  getterType: "workflow/getWorkflow",
  mutationType: "workflow/updateWorkflow"
});
export default {
  data:()=>({
  }),
  components:{
    ContactsAutoComplete,
    RolesAutoComplete,
    FormDivider,
  },
  computed: {
    ...mapFields({
      read_permissions_id:"read_permissions_id",
      edit_permissions_id:"edit_permissions_id",
      contacts_approval:"contacts_approval",
      roles_approval:"roles_approval",
    }),

  },
  methods:{
    cancel:function(){

    },
    save:function (){
      this.$store.dispatch("workflow/saveWorkflow").then(()=>{
        this.$store.commit("showSnackBar", {
          color: "accent",
          message: `Updated Permissions`
        });
      })
    }
  }

}
</script>

<style scoped lang="stylus">

</style>