<template lang="pug">
  #blankcontent()
    .panelTitle() Contact Renewal Reminder
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-space-around.align-center.fill-height()
          .d-flex.flex-column.align-center()
            .tickerTitle.secondary--text() {{ContactReminderDueSoon}}
            .tickerCaption.grey--text.text--darken-1() Contact Renewal OverDue
          .d-flex.flex-column.align-center()
            .tickerTitle.primary--text() {{ContactReminderOverDue}}
            .tickerCaption.grey--text.text--darken-1() Contact Renewal Due Soon


</template>
<script>
export default {
  data: () => ({}),
  mounted() {},

  computed: {
    ContactReminderDueSoon() {
      return this.$store.getters["dashboard/getContactRenewalOverDue"];
    },
    ContactReminderOverDue() {
      return this.$store.getters["dashboard/getContactRenewalDueSoon"];
    }
  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.tickerTitle {
  font-size: 45px;
}

.tickerCaption {
  margin-top: -10px;
  font-size: 12px;
}
</style>
