import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";
import item from "@/modules/inventory/items/store/item";
import purchaseOrder from "@/modules/inventory/purchase_orders/store/purchaseOrder";

const INVENTORY_API = "/inventory";

// ----------------------- STATE ------------------------- //
const state = {
    inventory_data: [],
    currentIndex: -1,
    mode: "add", // can either be subtract or add
    selectedItems:[],
    selectedTimelineIndex:2,
    timelines: [
        {
            back: "0 month",
            forward: "2 month",
        },
        {
            back: "1 month",
            forward: "1 month",
        },
        {
            back: "1 month",
            forward: "4 months"
        },
        {
            back: "6 months",
            forward: "6 months"
        },


    ],
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getInventory: state => getField(state.inventory_data[state.currentIndex]),

    getCurrentInventory: state => {
        return state.inventory_data[state.currentIndex];
    },

    getInventoryById: state => theID => {
        return state.inventory_data.find(function (element) {
            return element.id == theID;
        });
    },

    getMode: state => {
        return state.mode;
    },

    getSelectedItems: state => {
        return state.selectedItems
    },

    getStartCalendarItems: state => calendarTime =>  {
        let theArray = state.inventory_data.filter(
            element =>
                new Date(element.start_at).getTime() > calendarTime.start && new Date(element.start_at).getTime() < calendarTime.end )

        return theArray.sort(function(a,b){
           return new Date(a.start_at) - new Date(b.start_at);
        });
    },

    getEndCalendarItems: state => calendarTime =>  {
        let theArray = state.inventory_data.filter(
            element =>
                new Date(element.end_at).getTime() > calendarTime.start && new Date(element.end_at).getTime() < calendarTime.end )

        return theArray.sort(function(a,b){
            return new Date(a.end_at) - new Date(b.end_at);
        });
    }


};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateInventory(state, payload) {
        console.log(payload);
        updateField(state.inventory_data[state.currentIndex], payload);
    },

    setMode(state, payload) {
        state.mode = payload;
    },

    setSelectedItems(state,payload){
        state.selectedItems = payload;
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.inventory_data.findIndex(inventory => inventory.id == theID);
    },

    loadAllInventorys(state, payload) {
        state.inventory_data = payload.data;
        state.currentIndex = 0;
    },

    addInventory(state, payload) {
        payload.data.inventoryDetail = {aisle: null, row: null, bin: null};
        state.inventory_data.push(payload.data);
        state.currentIndex = state.inventory_data.length - 1;
    },

    saveInventory(state, payload) {
        state.inventory_data[state.currentIndex] = payload.data;
    },

    fetchInventory(state, payload) {
        if (state.currentIndex == -1) {
            state.inventory_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.inventory_data[state.currentIndex] = payload.data;
        }
    },

    deleteInventory(state, payload) {
        state.currentIndex = state.inventory_data.findIndex(
            inventory => inventory.id == payload.data.id
        );
        state.inventory_data.splice(state.currentIndex, 1);
    },
    deleteSelectedInventory(state, payload){
        payload.data.forEach(element => {
            let theIndex = state.inventory_data.findIndex(
                (inventory) => inventory.id == element
            );
            state.inventory_data.splice(theIndex, 1);
        })
        state.selectedItems = [];
    },
    reset(state) {
        state.inventory_data = []
        state.currentIndex = -1
    },

    deleteImage(state, payload) {
        let index = state.inventory_data[state.currentIndex].photos_id.findIndex(
            inventory => inventory === payload
        );
        state.inventory_data[state.currentIndex].photos_id.splice(index, 1);
    },

    deleteDocument(state, payload) {
        let index = state.inventory_data[state.currentIndex].documents_id.findIndex(
            inventory => inventory === payload
        );
        state.inventory_data[state.currentIndex].documents_id.splice(index, 1);
    },

    setTimelineIndex(state,index){
        state.selectedTimelineIndex = index;
    }

};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllInventorys({commit}) {
        try {
            commit("loadAllInventorys", await Repository.get(`${INVENTORY_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async loadAllByItem({commit}, itemID) {
        try {
            commit("loadAllInventorys", await Repository.get(`${INVENTORY_API}/loadByItem/${itemID}?back=${state.timelines[state.selectedTimelineIndex].back}&forward=${state.timelines[state.selectedTimelineIndex].forward}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addInventory({commit}, itemID) {
        await Repository.get(`${INVENTORY_API}/new/${itemID}`)
            .then(response => {
                commit("addInventory", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveInventory({state, commit}) {
        try {
            let theInventory = state.inventory_data[state.currentIndex];
            commit("saveInventory", await Repository.post(`${INVENTORY_API}`, theInventory));
        } catch (error) {
            console.log(error);
        }
    },

    async fetchInventory({commit}, payloadID) {
        try {
            commit("fetchInventory", await Repository.get(`${INVENTORY_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteInventory({commit}, payloadID) {
        try {
            commit("deleteInventory", await Repository.delete(`${INVENTORY_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteSelectedInventories({ commit }, deleteSelectedIDs) {
        try {
            let idString = ''
            deleteSelectedIDs.forEach(element => {
                idString += "&id="+element
            })
            commit(
                "deleteSelectedInventory",
                await Repository.delete(`${INVENTORY_API}/deleteSelected?${idString}`)
            );
        } catch (error) {
            console.log(error);
            throw(error)
        }
    },



    async searchInventorys({commit}, searchTerm) {
        try {
            commit(
                "loadAllInventorys",
                await Repository.get(`${INVENTORY_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
        item,
        purchaseOrder
    }
};
