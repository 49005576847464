<template lang="pug">
  .autoVerticalScroll()
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Inspection History
        v-spacer
        .text-xs-center
          v-btn.ma-2(small, outlined, color='primary' @click="onRefresh")
            v-icon() sync
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item(v-for='item in actionItems', :key='item.title' @click='onAction(item.value)')
                v-list-item-title {{ item.title }}

        v-btn.ma-2.mr-5(small, color='primary', @click="newRecord")
          v-icon.mr-2() playlist_add
          | Start Inspection

    v-divider
    v-layout.mx-4.my-2(column)
      .d-flex.justify-space-between()
        .d-flex.align-center()
          .d-flex.align-center()
            v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" )
            v-btn(v-if="search.length > 0" text outlined small @click="search=''") clear all

          v-chip-group(v-model="statusIndex" @change="chipChange" column  active-class="primary--text")
            v-chip(v-for='toggleItem in toggleItems', :key='toggleItem' small v-html="toggleItem")

        .d-flex.align-center()
          .grey--text.text--darken-1.mr-4.pt-1() {{$store.getters["inspection/getInspectionByStatus"](this.status).length}} Records
          v-btn(style="text-transform: none !important;" color="accent"  @click="onOpenDialog" v-html="startDate + endDate")

    v-divider
    .grey.lighten-4.py-3
    v-divider
    template(v-if="isLoaded")
      inspections-table(:search="search" :status="status" )
    template(v-else)
      .d-flex.fill-width.py-8.justify-center()
        v-progress-circular(indeterminate color="primary")
    v-divider
    v-dialog(v-model="showNewFormDialog" width="500"  )
      v-card()
        .primary.px-4.py-4()
          .title.white--text() Select a Form
        .py-4()
          v-list-item-group(v-model="listGroup")
            v-list-item(two-line, v-for="(item,index) in $store.state.inspection.inspectionForm.inspectionForm_data" :key="index" @click="newInspectionRecord(item)")
              v-list-item-content.px-2
                v-list-item-title() {{item.name}}
                v-list-item-subtitle() {{item.description}}
    v-dialog(v-model="showDialog" width="500" height="500" persistent )
      v-card()

        .d-flex.justify-space-between.primary.pa-2()
          .d-flex.flex-column.white--text
            .caption() Select Date or Date Range
            .title(v-html="startDate + endDate")
        v-divider()
        vue-ctk-date-time-picker(
          v-model="thedate"
          inline
          :no-header="true"
          :right="false"
          :range="true"
          :button-color="$vuetify.theme.themes.light.primary"
          :color="$vuetify.theme.themes.light.primary"
          :custom-shortcuts="shortcuts"
        )
        v-divider()
        v-layout(justify-end )
          v-btn.mx-2.my-4.grey--text(@click="onCancelDialog"  outlined small) Cancel
          v-btn.mx-2.my-4(@click="onUpdateDate" color="primary" small) Update
</template>

<script>
import InspectionsTable from "@/modules/inspections/components/tables/InspectionsTable";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import moment from "moment/moment";

export default {
  components: {
    InspectionsTable,
    VueCtkDateTimePicker,
  },


  data: () => ({
    showDialog: false,
    thedate: { start: "2021-08-03 12:00 am", end: null },
    shortcuts: [
      { key: "today", label: "Today", value: 0 },
      { key: "yesterday", label: "Yesterday", value: "-day" },
      { key: "last7Days", label: "Last 7 days", value: 7 },
      { key: "last14Days", label: "Last 14 days", value: 14 },
      { key: "last30Days", label: "Last 30 days", value: 30 },
      { key: "last365Days", label: "Last 365 days", value: 365 }
    ],
    listGroup: null,
    showNewFormDialog: false,
    isLoaded: false,
    search: "",
    vehiclesID: [],
    toggleItems: ["All", "Submitted with Failure"],
    statusIndex: 0,
    actionItems: [

      // {
      //   value: "export_csv",
      //   title: "Export CSV"
      // },
      // {
      //   value: "export_excel",
      //   title: "Export Excel"
      // },
      // {
      //   value: "import_excel",
      //   title: "Import Excel"
      // },
      {
        value: "delete_selected",
        title: "Delete Selected"
      }
    ]
  }),

  computed: {
    startDate: function(){
      return moment(this.thedate.start,"YYYY-MM-DD h:mm a").format('ddd, Do MMM YYYY')
    },

    endDate: function(){
      if(this.thedate.end == null){
        return ""
      }else{
        return " - " + moment(this.thedate.end,"YYYY-MM-DD h:mm a").format('ddd, Do MMM YYYY')
      }

    },


    status: {
      get: function () {
        return this.toggleItems[this.statusIndex];
      },
      set: function (value) {
        this.statusIndex = this.toggleItems.findIndex(item => item === value)
      }
    }
  },

  methods: {

    onUpdateDate: function() {
      this.showDialog = false;
      this.loadAllInspections();
      //TODO: update stuff
    },
    onOpenDialog: function() {
      // this.thedate = JSON.parse(JSON.stringify(this.originalDate));
      this.showDialog = true;
    },

    onCancelDialog: function() {
      this.showDialog = false;
      // this.thedate = JSON.parse(JSON.stringify(this.originalDate));
    },



    onAction: function (value) {

      switch (value) {
        case "export_csv":
          // this.fileExport = "CSV";
          // this.downloadFile("/report/CSV/place_list");
          // this.showExportDialog = !this.showExportDialog;
          break;
        case "export_excel":
          // this.fileExport = "Excel";
          // this.downloadFile("/report/Excel/place_list");
          // this.showExportDialog = !this.showExportDialog;
          break;
        case "delete_selected":

          var deletedIds = [];
          this.$store.getters["inspection/getSelectedItems"].forEach((element) => {
            deletedIds.push(element.id);
          })
          confirm(`Are you sure you want to delete this ${deletedIds.length} item?`) &&
          this.$store.dispatch("inspection/deleteSelectedItems", deletedIds).then(()=>{
            this.isLoaded = false;
            this.$store.dispatch("inspection/loadAllInspections").then(() => {
              this.isLoaded = true;
            })

          });
          break;
      }

    },

    showFormTemplates: function(){
      this.$router.push({ params:{tabID:2}}).catch(()=>{})
    },

    newInspectionRecord: function (item) {
      this.$store.dispatch("inspection/addInspection", item.id).then(() => {
        // get the New Form
        let newInspection = this.$store.getters["inspection/getCurrentInspection"];

        // set the current form as selected
        this.$store.commit("inspection/inspectionForm/setCurrentIndexByID", newInspection.inspection_form_id);

        this.$router.push({
          name: "inspectionNewLayout",
          params: {inspectionID: newInspection.id}
        }).catch(()=>{})
      })
    },

    newRecord: function () {
      this.showNewFormDialog = false;
      if (this.$store.state.inspection.inspectionForm.currentIndex == -1) {
        this.$store.dispatch("inspection/inspectionForm/loadAllInspectionForms").then(() => {
          this.showNewFormDialog = true;
        })
      } else {
        this.showNewFormDialog = true;
      }

      // this.$router.push({name:"inspectionNewLayout",params: {inspectionID: 12345}}).catch(()=>{})
    },
    openEdit: function () {
      this.$store.dispatch("inspections/addInspection").then(() => {
        let currentObject = this.$store.state.inspection.inspection_data[
            this.$store.state.inspection.currentIndex
            ];
        this.$router.push({
          name: "inspectionDetailsForm",
          params: {inspectionID: currentObject.id}
        }).catch(()=>{});
      });
    },



    closeDialog: function (value) {
      console.log(value);
    },

    chipChange: function(value){
      console.log(value);
    },


    onRefresh() {
      this.thedate.start = moment().subtract(1, "month").startOf('month').format("YYYY-MM-DD h:mm a");
      this.thedate.end = moment().endOf("day").format("YYYY-MM-DD h:mm a")

      this.loadAllInspections();
    },

    loadAllInspections: function() {
      let startDate = moment(this.thedate.start,"YYYY-MM-DD h:mm a").toISOString()
      let endDate
      if(this.thedate.end == null){
        endDate  = moment(this.thedate.start,"YYYY-MM-DD h:mm a").endOf("day").toISOString()
      }else{
        endDate = moment(this.thedate.end,"YYYY-MM-DD h:mm a").toISOString()
      }

      this.$store.dispatch("inspection/loadAllInspections", {from: startDate, to    : endDate}).then(() => {
        this.isLoaded = true;
      })

    }
  },

  mounted() {
    this.thedate.start = moment().subtract(1, "month").startOf('month').format("YYYY-MM-DD h:mm a");
    this.thedate.end = moment().endOf("day").format("YYYY-MM-DD h:mm a")
    console.log(`vehiclesIndex: ${this.$store.state.vehicles.currentIndex} contactIndex: ${this.$store.state.contacts.currentIndex}`)

    if (this.$store.state.vehicles.currentIndex == -1 || this.$store.state.contacts.currentIndex == -1) {
      this.$store.dispatch("vehicles/loadAllItems").then(() => {
        this.$store.dispatch("contacts/loadAllContacts").then(() => {
          this.loadAllInspections();
        })
      })
    } else {
      this.loadAllInspections();
    }

  }
};
</script>

<style scoped>
.autoVerticalScroll{
  height: 100%;
  overflow-y:auto;
}
</style>
