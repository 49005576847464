<template lang="pug">
  div
    v-autocomplete(
      v-model="contacts"
      :items="$store.state.contacts.contact_data"
      :loading="isLoading" 
      :search-input.sync="search" 
      item-text="first_name"
      item-value="id" 
      :label="label"
      :hint="hint"
      placeholder="Start typing a Contact Name" 
      multiple
      chips
      clearable
      @change="onChange"
      :filter="onFilter"
      :rules="rules"
      :disabled="disabled"
      :persistent-hint="hint==null?false:true"
      ) 
      template(slot="selection" slot-scope="data")
        v-chip.chip--select-multi(:input-value="data.selected" close @click:close="onRemove(data.item.id)")
          v-avatar()
            image-thumbnail.mr-1(v-if="data.item.thumbnail"  :width="35" :height="26"  :src="data.item.thumbnail_id")
            span.white--text.font-weight-bold(v-else style="letter-spacing:-1px;margin-left: -2px") {{data.item.first_name[0]}} {{data.item.last_name[0]}}
          | {{data.item.first_name}} {{data.item.last_name}}
      
      template(slot="item" slot-scope="data")
        template(v-if="typeof data.item !== 'object'")
          v-list-item-content(v-text="data.item")
        template(v-else)
          image-thumbnail.mr-1(:width="30" :height="35"  :src="data.item.thumbnail_id")
          v-list-item-content
            v-list-item-title
              div {{data.item.first_name}} {{data.item.last_name}}
            v-list-item-title
              v-layout()
                div(v-if="data.item.group") {{data.item.group.name}}
                v-spacer
                .miniLabel.mx-1.grey.white--text(v-if="data.item.technician") Technician
                .miniLabel.mx-1.grey.white--text(v-if="data.item.operator") Operator   
                .miniLabel.mx-1.grey.white--text(v-if="data.item.employee") Employee 
</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components: {
    ImageThumbnail
  },

  props: {
    value:{
      type:Array,
    },
    rules:{
      type:Array,
    },
    label:{
      type:String,
      default: "Select Contacts"
    },
    hint:{
      type: String,
      default: null
    },
    disabled:{
      type:Boolean,
      default: false
    }
  },
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    contacts: null,
    search: null
  }),

  methods: {
    jointName: function(theItem) {
      return theItem.first_name + " " + theItem.last_name;
    },

    setData: function(theData) {
      this.$emit("input", theData);
    },

    onChange: function(theValue) {
      this.$emit("input", theValue);
    },

    onRemove: function(item) {
      let tempContact = this._.cloneDeep(this.contacts);
      const theIndex = tempContact.findIndex(value => value === item);
      tempContact.splice(theIndex, 1);
      this.contacts = tempContact;
      this.$emit('input',this.contacts)
    },

    onFilter: function(item, queryText) {
      const hasValue = val => (val != null ? val : "");
      const query = hasValue(queryText);
      const toSearchText =
        item.first_name + " " + item.middle_name + " " + item.last_name;
      return (
        toSearchText
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    }
  },

  mounted() {
    this.contacts = this.value;
    if (this.$store.state.contacts.currentIndex === -1) {
      this.isLoading = true;
      this.$store.dispatch("contacts/loadAllContacts").then(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>
<style scoped lang="stylus">
.imageCrop
  width: 60px
  height: 40px
  color red
  border-radius 5px
  background-color #FF0000
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

.miniLabel
  padding 2px 5px
  font-size 11px
  border-radius 5px
  margin-bottom 3px;
</style>
