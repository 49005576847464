<template lang="pug">
  #blankcontent()
    .panelTitle() Tasks
    v-divider.mx-2()
    .panelContent(style="overflow=none")
      .d-flex.justify-space-around.align-center.fill-height()
        .d-flex.flex-column.align-center(v-for="(item,index) in stats" :key="index")
          template(v-if="index%2==0")
            .tickerTitle.secondary--text() {{item.value}}
            .tickerCaption.grey--text.text--darken-1() {{item.key}}
          template(v-else)
            .tickerTitle.primary--text() {{item.value}}
            .tickerCaption.grey--text.text--darken-1() {{item.key}}



</template>
<script>


import axios from "axios";

export default {
  data: () => ({
    stats:[]
  }),
  mounted() {

    axios.get(`/api/inspectionForm/dashboard/stats/${this.$route.params.inspectionID}`).then(response => {
      this.stats = response.data

    });
    

    },

  computed: {

  }
};
</script>
<style scoped lang="stylus">
#blankcontent {
  width: 100%;
  height: 100%;
}

.tickerTitle {
  font-size: 45px;
}

.tickerCaption {
  margin-top: -10px;
  font-size: 12px;
}
</style>
