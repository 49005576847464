<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    
    v-text-field(v-model="selectHint" label="Description")
    
    v-checkbox(v-model="selectRequired" label="Required")
    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        showAdvancedProperties(:showAdvanced="showAdvanced" )
      div(v-show="showAdvanced")
        v-text-field(v-model="selectDefault" label="Default Value")
        v-text-field(v-model="selectMaxlength" label="Maximum Characters" mask="###")
        v-checkbox(v-model="selectedAutoGrow" label="Grow to fit content" )
        v-checkbox(v-model="selectFullWidth" label="full width" hint="Span vertical Line across entire document" :persistent-hint="true" )
        permissions-component()
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)

</template>
<script>
// var snakeCase = require('lodash.snakecase');
import SETTINGS_DEFAULT from "@/modules/workflow/formBuilder/store/formSettings";
import ShowAdvancedProperties from "@/modules/workflow/formBuilder/components/ui/ShowAdvancedProperties";
import PermissionsComponent from "@/modules/workflow/formBuilder/components/ui/PermissionsComponent.vue";
export default {
  components: {
    ShowAdvancedProperties,
    PermissionsComponent
  },

  data: () => ({
    properties: {},
    showAdvanced: false
  }),

  computed: {
    selectedFieldName: {
      get: function() {
        return this.properties.label;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "label",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value )
        });
      }
    },

    selectHint: {
      get: function() {
        return this.properties.hint;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "hint",
          value: value
        });
      }
    },

    selectDefault: {
      get: function() {
        return this.properties.default;
      },

      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "default",
          value: value
        });
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "value",
          value: value
        });
      }
    },

    selectRequired: {
      get: function() {
        return this.properties.required;
      },

      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "required",
          value: value
        });
      }
    },

    selectedAutoGrow: {
      get: function() {
        return this.properties.autoGrow;
      },

      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "auto_grow",
          value: value
        });
      }
    },

    selectID: {
      get: function() {
        return this.$store.getters[SETTINGS_DEFAULT.GET_PATH].id;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "id",
          value: this._.snakeCase(value)
        });
      }
    },

    selectFullWidth: {
      get: function() {
        return this.properties.is_full_width;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "is_full_width",
          value: value
        });
      }
    },

    selectMaxlength: {
      get: function() {
        return this.properties.maxlength;
      },

      set: function(value) {
        if (value != null) {
          let i = parseInt(value);
          // only execute is an integer and greater than 0
          if (i > 0) {
            this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
              field: "maxlength",
              value: value
            });
          } else {
            this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
              field: "maxlength",
              value: null
            });
          }
        }
      }
    }
  },

  mounted() {
    this.properties = this.$store.getters[SETTINGS_DEFAULT.GET_PATH];
    this.$refs.fieldName.focus();
  }
};
</script>
<style scoped lang="stylus">
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}
</style>
