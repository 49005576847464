import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const USERSETTINGS_API = "/user/settings";

// ----------------------- STATE ------------------------- //
const state = {
    usersettings_data: [],
    currentIndex: -1,
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getUserSettings: (state) =>
        getField(state.usersettings_data[state.currentIndex]),

    getCurrentUserSettings: (state) => {
        return state.usersettings_data[state.currentIndex];
    },
    getOpenIssues: () => 5,
    getClosedIssues: () => 3,
    getVehicleRenewalOverDue: () => 7,
    getVehicleRenewalDueSoon: () => 9,
    getContactRenewalOverDue: () => 5,
    getContactRenewalDueSoon: () => 7,
    getVehicleOpenFaults: () => 10,
    getVehiclePendingFaults: () => 1,
    getCurrentWeekfailureRate: () => 40,
    getLastWeekfailureRate: () => 30,
    getOpenCriticalFaults: () => 6,
    getPendingCriticalFaults: () => 1,
    getOverDueInspections: () => 0,
    getOfTotalScheduled: () => 0.0,

};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateUserSettings(state, payload) {
        updateField(state.usersettings_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.usersettings_data.findIndex(
            (usersettings) => usersettings.id == theID
        );
    },

    loadAllUserSettingss(state, payload) {
        state.usersettings_data = payload.data;
        state.currentIndex = 0;
    },

    addUserSettings(state, payload) {
        state.usersettings_data.push(payload.data);
        state.currentIndex = state.usersettings_data.length - 1;
    },



    saveUserSettings(state, payload) {
        state.usersettings_data[state.currentIndex] = payload.data;
    },

    fetchUserSettings(state, payload) {
        if (state.currentIndex == -1) {
            state.usersettings_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.usersettings_data[state.currentIndex] = payload.data;
        }
    },

    deleteUserSettings(state, payload) {
        state.currentIndex = state.usersettings_data.findIndex(
            (usersettings) => usersettings.id == payload.data.id
        );
        state.usersettings_data.splice(state.currentIndex, 1);
    },



    reset(state) {
        state.usersettings_data = []
        state.currentIndex = -1
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {

    async loadAllUserSettingss({commit}) {
        try {
            commit(
                "loadAllUserSettingss",
                await Repository.get(`${USERSETTINGS_API}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async addUserSettings({commit}) {
        await Repository.get(`${USERSETTINGS_API}/new`)
            .then((response) => {
                commit("addUserSettings", response);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    async saveUserSettings({state, commit}) {
        try {
            commit(
                "saveUserSettings",
                await Repository.post(
                    `${USERSETTINGS_API}`,
                    state.usersettings_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchUserSettings({commit}, payloadID) {
        try {
            commit(
                "fetchUserSettings",
                await Repository.get(`${USERSETTINGS_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteUserSettings({commit}, payloadID) {
        try {
            commit(
                "deleteUserSettings",
                await Repository.delete(`${USERSETTINGS_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchUserSettingss({commit}, searchTerm) {
        try {
            commit(
                "loadAllUserSettingss",
                await Repository.get(`${USERSETTINGS_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    },
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
