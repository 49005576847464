export const panelSchema = [
  // {
  //   name: "HorizontalBarChartPanel",
  //   title: "Horizontal Bar Chart Sample",
  //   description: "Sample Horizontal Bar Chart",
  //   w: 3,
  //   h: 2,
  //   visible: false
  // },
  // {
  //   name: "PieChartPanel",
  //   title: "Pie Chart Example",
  //   description: "Sample Pie Chart Panel",
  //   w: 2,
  //   h: 2,
  //   visible: false
  // },
  {
    name: "PlacesPanel",
    title: "Places",
    description: "Places Visited Today",
    w: 3,
    h: 2,
    visible: false
  },
  {
    name: "VehicleStatusPanel",
    title: "Vehicle Status",
    description: "Status of your Vehicle Fleet",
    w: 3,
    h: 2,
    visible: false
  },
  {
    name: "PlaceAuthorsPanel",
    title: "Place Contributors",
    description: "Authors of Pin Locations",
    w: 3,
    h: 2,
    visible: false
  },
  {
    name: "ActivityEventsPanel",
    title: "Event Activities",
    description: "Jahazi Activities",
    w: 3,
    h: 4,
    visible: false
  },
  {
    name: "UsersActivityPanel",
    title: "User Activities",
    description: "Activities of Users in the system",
    w: 3,
    h: 2,
    visible: false
  },
  {
    name: "WeatherPanel",
    title: "Weather Forecast",
    description: "Weather Forecast",
    w: 3,
    h: 2,
    visible: false
  },
  {
    name: "VehicleAssignmentPanel",
    title: "Vehicle Assignment",
    description: "Status of your Vehicle Fleet",
    w: 3,
    h: 1,
    visible: false
  },
  // {
  //   name: "BarChartPanel",
  //   title: "Bar Chart Sample",
  //   description: "Sample Bar Chart",
  //   w: 3,
  //   h: 2,
  //   visible: false
  // },
  {
    name: "DailyDistanceTraveled",
    title: "Daily Distance Traveled",
    description: "24 Hour total Distance in KM",
    w: 3,
    h: 2,
    visible: false
  },
  {
    name: "FormSubmissions",
    title: "Form Submissions",
    description: "Forms submitted",
    w: 3,
    h: 2,
    visible: false
  },
  // {
  //   name: "DonutChartPanel",
  //   title: "Donut Chart Sample",
  //   description: "Donut Chart",
  //   w: 3,
  //   h: 2,
  //   visible: false
  // },
  // {
  //   name: "LineChartPanel",
  //   title: "Line Chart Sample",
  //   description: "Sample Bar Chart",
  //   w: 3,
  //   h: 2,
  //   visible: false
  // },
  // {
  //   name: "CostPerKmPanel",
  //   title: "Cost Per Kilometer",
  //   description: "Cost Per Kilometer",
  //   w: 3,
  //   h: 2,
  //   visible: false
  // },

  {
    name: "FuelCostsPanel",
    title: "Fuel Costs",
    description: "Fuel Costs Per Week",
    w: 3,
    h: 2,
    visible: false
  },
  {
    name: "TotalCostTrendPanel",
    title: "Total Cost Trend",
    description: "Trend of Costs of Fleet",
    w: 3,
    h: 2,
    visible: false
  },
  {
    name: "ServiceReminderPanel",
    title: "Service Reminders",
    description: "Service Reminders",
    w: 3,
    h: 1,
    visible: false
  },
  {
    name: "RecentCommentsPanel",
    title: "Recent Comments",
    description: "Comments",
    w: 4,
    h: 2,
    visible: false
  },
  {
    name: "RecentNotificationsPanel",
    title: "Unread Notifications",
    description: "Notifications",
    w: 3,
    h: 2,
    visible: false
  },
  {
    name: "MapPanel",
    title: "Map Comments",
    description: "Comments",
    w: 4,
    h: 4,
    visible: false
  },
  {
    name: "ContactRenewalReminderPanel",
    title: "Contact Renewal",
    description: "Comments",
    w: 3,
    h: 1,
    visible: false
  },
  {
    name: "CriticalFaultsPanel",
    title: "Critical Faults",
    description: "Comments",
    w: 3,
    h: 1,
    visible: false
  },
  {
    name: "InspectionItemFailureRatePanel",
    title: "Inspection Item Failure Rate",
    description: "Comments",
    w: 3,
    h: 1,
    visible: false
  },
  {
    name: "InspectionSubmissionPanel",
    title: "Inspection Submissions",
    description: "Comments",
    w: 3,
    h: 1,
    visible: false
  },
  {
    name: "InspectionSummaryPanel",
    title: "Inspection Summery",
    description: "Comments",
    w: 3,
    h: 1,
    visible: false
  },
  {
    name: "OpenIssuesPanel",
    title: "Open Issues",
    description: "Comments",
    w: 3,
    h: 1,
    visible: false
  },
  {
    name: "KeyElementsPanel",
    title: "Data Statistics",
    description: "Shows Total Vehicles, Contacts, Places and Routes",
    w: 6,
    h: 1,
    visible: false
  },
  {
    name: "OverdueInspectionsPanel",
    title: "Overdue Inspections",
    description: "Comments",
    w: 3,
    h: 1,
    visible: false
  },
  {
    name: "ServiceCostPanel",
    title: "Service Costs",
    description: "Comments",
    w: 3,
    h: 2,
    visible: false
  },
  {
    name: "TotalCostPanel",
    title: "Total Costs",
    description: "Comments",
    w: 3,
    h: 2,
    visible: false
  },
  {
    name: "VehicleFaultsPanel",
    title: "Vehicle Faults",
    description: "Comments",
    w: 3,
    h: 1,
    visible: false
  },
  {
    name: "VehicleRenewalReminderPanel",
    title: "Vehicle Renewal Reminder",
    description: "Comments",
    w: 3,
    h: 1,
    visible: false
  },
  {
    name: "WorkflowsPanel",
    title: "Workflows",
    description: "Templates and Records counter",
    w: 3,
    h: 1,
    visible: false
  }
];

export const headerStatsFile = [
  {
    id: 0,
    icon: "directions_car",
    title: "Vehicles in Operation",
    value: "27",
    rateIncrease: -44
  },
  {
    id: 1,
    icon: "person",
    title: "Active Drivers",
    value: "33",
    rateIncrease: 5
  },
  {
    id: 2,
    icon: "error",
    title: "Open Issues",
    value: "7",
    rateIncrease: -5
  },
  {
    id: 3,
    icon: "local_gas_station",
    title: "Litres of Fuel",
    value: "821",
    rateIncrease: -11
  },
  {
    id: 4,
    icon: "directions",
    title: "Km Covered",
    value: "1077",
    rateIncrease: -11
  },
  {
    id: 5,
    icon: "access_time",
    title: "Total Hours",
    value: "612",
    rateIncrease: 4
  },
  {
    id: 6,
    icon: "done_all",
    title: "Tasks Accomplished",
    value: "52",
    rateIncrease: 32
  }
];

export const efficiencyMenuFile = [
  "Average Drivers Efficiency",
  "Average Vehicle Efficiency",
  "Amount Saved"
];

export const driverLeaderboardMenuFile = ["Top Drivers", "Bottom Drivers"];

export const driverRankListFile = [
  {
    rank: 1,
    name: "Marangi Matendechere",
    amount: 1.74,
    avatar: "/static/web/passport2.jpg"
  },
  {
    rank: 2,
    name: "Donald Trump",
    amount: 6.94,
    avatar: "passport3.jpg"
  },
  {
    rank: 3,
    name: "Jacob Mwnafunzi",
    amount: -0.95,
    avatar: "/static/web/passport2.jpg"
  },
  {
    rank: 4,
    name: "John Doe",
    amount: -2.5,
    avatar: "/static/web/passport2.jpg"
  }
];

export const heatmapMenuFile = ["Efficiency Zones"];

export const efficiencyMapFile = ["Efficieny Zones"];

export const vehicleLifeMenuFile = ["Vehicle Life", "Vehicle Destroy"];

export const alertReminderFile = ["Reminder & Alerts"];

export const vehicleLifeFile = [
  {
    title: "Longest Life",
    color: "primary",
    data: [
      {
        value: 40,
        registration: "KOB 100A",
        make: "Toyota Hino"
      },
      {
        value: 80,
        registration: "KOO 999B",
        make: "Mercedes Benz"
      },
      {
        value: 10,
        registration: "KDO 144J",
        make: "Nissan UD"
      }
    ]
  },
  {
    title: "Shortest Life",
    color: "orange",
    data: [
      {
        value: 40,
        registration: "KZO 444F",
        make: "Nissan Ralley"
      },
      {
        value: 30,
        registration: "KF0 999B",
        make: "VOVO XD"
      },
      {
        value: 20,
        registration: "KRO 745B",
        make: "Tesla"
      },
      {
        value: 10,
        registration: "KOO 999B",
        make: "Mercedes Benz"
      },
      {
        value: 25,
        registration: "KDO 144J",
        make: "Nissan UD"
      }
    ]
  }
];

export const reminderAlertFile = [
  {
    title: "Alerts",
    data: [
      {
        icon: "warning",
        title: "Unlimited Music",
        body:
          "Listen to your favourite artists and albums whenver you are online and offline",
        date: "22 May 2018",
        status: "Pending",
        url: "#"
      },
      {
        icon: "error",
        title: "Breakdown of KAM 002F",
        body:
          "Qo2 favourite artists and albums whenver you are online and offline",
        date: "12 Jun 2018",
        status: "Resolved",
        url: "#"
      },
      {
        icon: "add_alert",
        title: "Failure to Deliver goods",
        body: "Listen to your favourite artists and albums",
        date: "22 Jan 2018",
        status: "deffered",
        url: "#"
      }
    ]
  },

  {
    title: "Reminder",
    data: [
      {
        icon: "warning",
        title: "Unlimited Music",
        body:
          "Listen to your favourite artists and albums whenver you are online and offline",
        date: "22 May 2018",
        status: "Pending",
        url: "#"
      },
      {
        icon: "error",
        title: "Breakdown of KAM 002F",
        body:
          "Listen to your favourite artists and albums whenver you are online and offline",
        date: "12 June 2018",
        status: "Resolved",
        url: "#"
      },
      {
        icon: "home",
        title: "Failure to Deliver goods",
        body:
          "Listen to your favourite artists and albums whenver you are online and offline",
        date: "22 January 2018",
        status: "deffered",
        url: "#"
      }
    ]
  }
];
