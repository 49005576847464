<template lang="pug">
  contact-auto-complete(v-model="result" :label="element.label_id" )
</template>

<script>

import ContactAutoComplete from "@/modules/global/components/forms/ContactAutoComplete";

export default {
  components: {
    ContactAutoComplete,
  },
  props: ["value", "element", "mutationType"],
  watch: {
    result: function (newVal) {
      console.log(`newVal: ${newVal}`)
      this.$store.commit(this.mutationType, {path: `details.${this.element.label_id}`, value: newVal})
    }
  },
  data: () => ({
    result: null,
  }),
  methods: {},
  mounted() {
    this.result = this.value;
  }
}
</script>

<style scoped>

</style>