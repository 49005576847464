<template lang="pug">
  div
    v-container.my-0.pa-0(fluid)
      p.my-0 {{ schema.label }}
      v-radio-group.my-0(
        v-model="computedValue" 
        v-bind="bindValues" 
        :error-messages="errorMessages" 
        :hint="schema.hint" 
        :persistent-hint="schema.hint != null"
        :mandatory="schema.required"
        :rules="computeRules()"
        :disabled="disabled"
        )
        v-radio(v-for="(item,index) in schema.options" :key="index" :label="item" :value="item")
    
    

</template>
<script>
export default {
  props: ["schema", "dataValue", "index","disabled"],
  data: () => ({
    column: null,
    errorMessages: []
  }),

  computed: {
    computedValue: {
      get: function() {
        return this.dataValue;
      },

      set: function(theValue) {
        console.log(`setting theValue: ${theValue}`);
        this.$emit("onChange", {
          id: this.schema.id,
          value: theValue,
          index: this.index
        });
      }
    },

    bindValues: function() {
      let p = {};
      if (this.schema.orientation === "row") {
        p["row"] = true;
      }

      if (this.schema.orientation === "column") {
        p["column"] = true;
      }
      return p;
    }
  },

  methods: {
    computeRules: function() {
      let rulesArray = [];
      if (this.schema.required) {
        rulesArray.push(v => !!v || "option is required");
      }

      return rulesArray;
    }
  },
  mounted() {}
};
</script>
<style scoped lang="stylus"></style>
