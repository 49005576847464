import { getField, updateField } from "vuex-map-fields";
import Repository from "@/repository";

const USERSETTINGS_API = "/user/settings";

// ----------------------- STATE ------------------------- //
const state = {
  usersettings_data: null
};

// ----------------------- GETTERS ----------------------- //
const getters = {
  getUserSettings: state => getField(state.usersettings_data),
  getDashboardPanelIndexByName: state => name => {
    return state.usersettings_data.dashboard.findIndex(item => item.i === name);
  }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
  updateUserSettings(state, payload) {
    updateField(state.usersettings_data, payload);
  },

  saveUserSettings(state, payload) {
    console.log(payload.data);
    state.usersettings_data = payload.data;
  },

  // -- Dashboard Functions
  saveDashboard(state, payload) {
    state.usersettings_data.dashboard = payload;
  },
  addPanelDashboard(state, payload) {
    state.usersettings_data.dashboard.push(payload);
  },
  deletePanelDashboard(state, payload) {
    let index = state.usersettings_data.dashboard.findIndex(
      element => element.i === payload.i
    ); // get index
    state.usersettings_data.dashboard.splice(index, 1);
  },
  deletePanelByIndex(state, index) {
    state.usersettings_data.dashboard.splice(index, 1);
  },


};

// ----------------------- ACTIONS -------------------- //
const actions = {
  async loadUserSettings({ commit }) {
    try {
      commit("saveUserSettings", await Repository.get(`${USERSETTINGS_API}`));
    } catch (error) {
      console.log(error);
    }
  },

  async saveUserSettings({ state, commit }) {
    try {
      commit(
        "saveUserSettings",
        await Repository.post(`${USERSETTINGS_API}`, state.usersettings_data)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
