<template lang="pug">
  div()
    div() placeholder {{computedValue}}
    div() label:{{schema.label}} hint:{{schema.hint}}
    div() size: {{schema.image_size}}

</template>
<script>

import SETTINGS from "@/modules/workflow/formBuilder/store/formSettings";
import LocationSelect from "@/modules/global/components/forms/LocationSelect";

export default {
  components:{
    LocationSelect
  },

  props: {
    schema: Object
  },
  data: ()=>({

  }),
  computed:{

    computedValue: {
      get: function() {
        return this.$store.getters[SETTINGS.GET_SCHEMA_VALUE](this.schema.id);
      },

      set: function(theValue) {
        this.$store.commit(SETTINGS.SET_SCHEMA_VALUE, {
          schema_id: this.schema.id,
          value: theValue
        });
      }
    }
  },

  methods:{

  }
};
</script>
<style scoped lang="stylus">


</style>
