import Vue from "vue";
import Vuex from "vuex";
import contacts from "@/modules/contacts/store/contacts";
import users from "@/modules/contacts/store/users";
import dashboard from "@/modules/dashboard/store/dashboard";
import profile from "@/modules/login/store/profile";
import vehicles from "@/modules/vehicles/store/vehicles";
import expenses from "@/modules/expense/store/expenses";
import service from "@/modules/services/store/service";
import issues from "@/modules/issues/store/issues";
import settings from "@/modules/settings/store/settings";
import reminders from "@/modules/reminders/store/reminders";
import fuel from "@/modules/fuel/store/fuel";
import fuelCard from "@/modules/fuel/store/fuelCard";
import place from "@/modules/places/store/place";
import routing from "@/modules/places/store/routing";
import journey from "@/modules/places/store/journey";
import odometers from "@/modules/vehicles/store/odometers";
import comments from "@/modules/comments/store/comments";
import messages from "@/modules/messaging/store/messages";
import sms from "@/modules/messaging/store/sms";
import mail from "@/modules/messaging/store/mail";
import chat from "@/modules/messaging/store/chat";
import trips from "@/modules/maps/store/trips";
import timeline from "@/modules/maps/store/timeline";
import tracks from "@/modules/maps/store/track.js";
import historyMap from "@/modules/maps/store/HistoryMap";
import inventory from "@/modules/inventory/store/inventory";
import geofence from "@/modules/maps/store/geofence";
import cameraCaptures from "@/modules/vehicles/store/cameraCaptures";
import reports from "@/modules/reports/store/reports";
import login from "@/modules/login/store/login";
import uploads from "@/modules/uploads/store/uploads";
import merchant from "@/modules/contacts/merchants/store/merchant";
import workflow from "@/modules/workflow/store/Workflow";
import inspection from "@/modules/inspections/store/inspection";
import customer from "@/modules/contacts/customers/store/customers";
import repository from "@/modules/repository/store/repository";


Vue.use(Vuex);

const state = {
    global_snackbar: false,
    global_snackbar_color: "primary",
    global_snackbar_message: "",
    global_snackbar_duration: null,
    global_snackbar_pathObject: null,
    global_map_key:0,
    right_drawer: false,
    right_drawer_width: 600,
    right_drawer_title: null,
    right_drawer_id:null,
    right_drawer_key:0,
    right_drawer_component: null,
    window_height: 0,
    window_width: 0,
    main_height: 0,
    main_width: 0,
    map_coordinates: null
};

const mutations = {
    showSnackBar(state, payload) {
        state.global_snackbar = true;
        state.global_snackbar_color = payload.color;
        state.global_snackbar_message = payload.message;
        state.global_snackbar_duration = payload.duration == null ? 5000 : payload.duration;
        state.global_snackbar_pathObject = payload.pathObject;
    },
    hideSnackBar(state) {
        state.global_snackbar = false;
        state.global_snackbar_color = "primary";
        state.global_snackbar_message = "";
        state.global_snackbar_duration = 5000;
    },
    reset(state) {
        state.global_snackbar = false;
        state.global_snackbar_color = "primary";
        state.global_snackbar_message = ""
    },

    //this.$store.commit("showRightDrawer",{title:"Route Map",width:800,component:RightRouteMap})
    showRightDrawer(state, payload) {
        state.right_drawer_title = payload.title;
        state.right_drawer_width = payload.width;
        state.right_drawer_component = payload.component;
        state.right_drawer_id = payload.id;
        state.right_drawer_key += 1;
        state.right_drawer = true;
    },
    hideRightDrawer(state) {
        state.right_drawer = false;
    },

    setWidth(state, payload) {
        state.window_width = payload;
    },

    setHeight(state, payload) {
        state.window_height = payload
    },

    setMainWidth(state, payload) {
        state.main_width = payload
    },

    setMainHeight(state, payload) {
        state.main_height = payload
    },

    incrementMapKey(state){
        state.global_map_key+=1;
    },

    hideMap(state) {
        state.map_coordinates = null;
    },
    showMap(state, location) {
        state.map_coordinates = location;
    }

};

export default new Vuex.Store({
    strict: true,
    state,
    mutations,
    modules: {
        contacts,
        dashboard,
        profile,
        vehicles,
        trips,
        timeline,
        tracks,
        historyMap,
        service,
        issues,
        settings,
        reports,
        login,
        reminders,
        uploads,
        users,
        fuel,
        fuelCard,
        merchant,
        inventory,
        place,
        routing,
        journey,
        workflow,
        odometers,
        expenses,
        comments,
        messages,
        sms,
        mail,
        inspection,
        customer,
        geofence,
        chat,
        cameraCaptures,
        repository,
    },
});
