<template lang="pug">
  div
    // Tabs Selector
    .pt-2
      v-tabs.tabs(left height='32' slider-color='primary'  v-model="active")
        //v-tab.tabs
        //  | Workflow Diagrams
        //v-tab-item.fill-tab
        //  flow-layout

        v-tab.tabs
          | Grid Layouts
        v-tab-item.fill-tab
          form-template-grid-layout

        v-tab.tabs
          | List Layout
        v-tab-item.fill-tab
          template-forms-list-layout()

        v-tab.tabs
          | Record Table
        v-tab-item.fill-tab
          record-table-layout()

        v-tab.tabs
          | Data Tables
        v-tab-item.fill-tab
          data-tables-layout()












        

        


</template>
<!--v-tab.tabs-->
<!--| History-->
<!--v-tab-item-->
<!--inspection-history-layout()-->

<!--v-tab.tabs-->
<!--| Template Builder Forms-->
<!--v-tab-item-->
<!--template-builder-form()-->
<script>
// import InspectionHistoryLayout from "@/modules/workflow/inspections/layouts/InspectionHistoryLayout";
import TemplateFormsListLayout from "@/modules/workflow/formBuilder/layouts/TemplateFormsListLayout";
import TemplateGridLayout from "@/modules/workflow/formBuilder/layouts/TemplateGridLayout";
import DataTablesLayout from "@/modules/workflow/dataTables/layouts/DataTablesLayout";

import RecordTableLayout from "@/modules/workflow/records/layouts/RecordTableLayout";
import FormTemplateGridLayout from "@/modules/workflow/formBuilder/layouts/TemplateGridLayout";
import FlowLayout from "@/modules/workflow/flow/flowLayout";
export default {
  components: {
    FlowLayout,
    FormTemplateGridLayout,
    // InspectionHistoryLayout,
    TemplateFormsListLayout,
    DataTablesLayout,
    RecordTableLayout,
    TemplateGridLayout
  },

  computed: {
    active: {
      get: function() {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          tabIndex = tabIndex > 3 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function(value) {
        this.$route.params.tabID = value;
      }
    }
  }
};
</script>

<style scoped>



</style>
