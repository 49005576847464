<template lang="pug">
    v-card()
      v-card-title.grey.lighten-3()
        v-layout(justify-space-between)
          v-layout(column)
            .body.primary--text() Send Command
            .caption() {{item.name}}
          v-icon(@click="$emit('closeDialog')") close
      v-divider()
      v-card-text()
        
        v-layout(justify-start)
          v-flex(xs12)
            v-combobox(
              v-model="command"  
              :items="command_items" 
              :search-input.sync="search" 
              :hide-selected="false"  
              label="Command"   
              :multiple="false"   
              persistent-hint 
              :small-chips="true" 
              @change="commandChange"
               :clearable="true"
               :auto-select-first="true"
           
              )
        
        .responseStyle.py-2(v-if="responseString")
          ul.caption()
            li(v-for="(item,index) in responseString.split(' ')") {{item}}


      v-card-actions()
        v-layout(justify-center)
          v-btn.mb-4(color="primary" @click="sendCommand") Send Command
         
</template>
<script>
export default {
  props: ["item"],
  data: () => ({
    command: null,
    command_items: [
      "getinfo",
      "getver",
      "getstatus",
      "getgps",
      "readio",
      "cpureset",
      "countrecs",
      "faultcodes",
      "obdinfo",
      "cleardtc",
      "getvin"
    ],
    search: null,
    temp: null,
    responseString: null
  }),

  methods: {
    sendCommand() {
      console.log(`${this.command} sent to ${this.item.imei}`);
      this.$mqtt.publish(
        "teltonika/command",
        JSON.stringify({ imei: this.item.imei, command: this.command })
      );
    },

    commandChange() {}
  },

  mounted() {
    let commandTopic = `teltonika/commandResponse/${this.$store.state.profile.userInfo.account_id}/#`;
    this.$mqtt.subscribe(commandTopic);
  },

  mqtt: {
    "teltonika/commandResponse"(data, topic) {
      // this.buff = this.buff + data + "<br/>";
      console.log("TELTONIKA");
      console.log(topic);
      console.log(data);
      let theResponse = JSON.parse(data);
      this.responseString = theResponse.response;
    }
  }
};
</script>
<style scoped lang="stylus"></style>
