<template lang="pug">
  div
    v-text-field(v-model="selectedFieldName" ref="fieldName" label="field Name*" :rules="[value => !!value || 'Required.']")
    
    v-text-field(v-model="selectHint" label="Description")
    
    v-checkbox(v-model="selectRequired" label="Required")
    div
      div(@click.prevent="showAdvanced=!showAdvanced")
        showAdvancedProperties(:showAdvanced="showAdvanced" )
      div(v-show="showAdvanced")
        v-text-field(v-if="properties.default"  v-model="selectDefault" label="Default Value")
        v-text-field(v-model="selectMaxlength" label="Maximum Characters" mask="###")
        v-checkbox(v-model="selectedAutoGrow" label="Grow to fit content" )
        v-checkbox(v-model="selectFullWidth" label="full width" hint="Span vertical Line across entire document" :persistent-hint="true" )
        v-checkbox(v-model="selectCommentOnFail" label="Must comment when no input")
        v-text-field(v-model="selectID" label="Field ID" hint="Unique ID of the field" :persistent-hint="true" readonly)

</template>
<script>
// var snakeCase = require('lodash.snakecase');
import SETTINGS_DEFAULT from "@/modules/inspections/store/formSettings";

import {inspectionPropertiesMixin} from "@/modules/inspections/components/formDesigner/properties/inspectionPropertiesMixin";

export default {
  mixins:[inspectionPropertiesMixin],
  computed: {


    selectedAutoGrow: {
      get: function() {
        return this.properties.autoGrow;
      },

      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "auto_grow",
          value: value
        });
      }
    },


    selectFullWidth: {
      get: function() {
        return this.properties.is_full_width;
      },
      set: function(value) {
        this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
          field: "is_full_width",
          value: value
        });
      }
    },

    selectMaxlength: {
      get: function() {
        return this.properties.maxlength;
      },

      set: function(value) {
        if (value != null) {
          let i = parseInt(value);
          // only execute is an integer and greater than 0
          if (i > 0) {
            this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
              field: "maxlength",
              value: value
            });
          } else {
            this.$store.commit(SETTINGS_DEFAULT.SET_PATH, {
              field: "maxlength",
              value: null
            });
          }
        }
      }
    }
  },

};
</script>
<style scoped lang="stylus">
propertyWrapper {
  border-color #FF0000;
  color #F00l;
}
</style>
