<template lang="pug">
  div(v-if="geotag")
    template(v-if="street_name")
      a.streetLink(@click.prevent="clickLink") {{street_name}}
    template(v-else)
      a.streetLink(@click.prevent="clickLink") {{geotag.lat}} {{geotag.lon}}
</template>

<script>
import axios from "axios";
export default {
  name: "GeoTagRenderer",
  props:["geotag"],
  data:()=>({
    street_name:null
  }),
  watch:{
    geotag:function(){
      this.getStreetName();
    }
  },
  methods:{
    clickLink:function(){
      this.$emit("showMap",{location:this.geotag,street:this.street_name});
    },
    getStreetName:function(){
      axios.get(`/api/place/getStreet?lon=${this.geotag.lon}&lat=${this.geotag.lat}`).then((response)=>{
        this.street_name = response.data.street;
      })
    }
  },
  mounted() {
    this.getStreetName();
  }
}
</script>

<style scoped>
.streetLink {
  text-decoration: underline;
}
</style>